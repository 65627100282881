import { useState } from 'react';
import { CSVLink } from 'react-csv';
import { fomateDateUS } from '../../../common/utility';
import { allMentorTimeLogsQuery_allMentorTimeLogs_mentorTimeLogs } from '../Payouts/gql/__generated__/allMentorTimeLogsQuery';
import Download_CSV_Icon from '../../../img/download-csv.svg';

const headers = [
  { label: 'WORK TYPE', key: 'workType' },
  { label: 'ACTIVITY DURATION', key: 'activityDuration' },
  { label: 'STUDENT NAME', key: 'studentName' },
  { label: 'DATE', key: 'date' },
  { label: 'HOURLY RATE', key: 'hourlyRate' },
  { label: 'AMOUNT', key: 'amount' },
  { label: 'PAYMENT STATUS', key: 'paymentStatus' },
];

interface CSVDataItem {
  workType: string;
  activityDuration: string;
  studentName: string;
  date: string;
  amount: string;
  paymentStatus: string | false | null;
  hourlyRate: string;
}
const DownloadTimLogsInCsv = ({
  label = 'Download CSV',
  mentorTimeLogs,
}: {
  label?: string;
  mentorTimeLogs: (allMentorTimeLogsQuery_allMentorTimeLogs_mentorTimeLogs | null)[];
}) => {
  const [data, setData] = useState<CSVDataItem[] | null | []>([]);
  return (
    <CSVLink
      data={data && data.length ? data : []}
      headers={headers}
      onClick={() => {
        if (mentorTimeLogs && mentorTimeLogs.length) {
          let parsedData = mentorTimeLogs.map((item) => {
            return {
              workType: item ? item.workActivity : 'N/A',
              activityDuration: item?.activityDuration
                ? `${parseInt(`${item.activityDuration / 60}`)}:${parseInt(
                    `${item.activityDuration % 60}`
                  )} hrs`
                : 'N/A',
              studentName:
                item && item.student ? `${item.student.firstName}` : 'N/A',
              date: item ? `${fomateDateUS(new Date(item.activityAt))}` : 'N/A',
              hourlyRate:
                item &&
                item.mentorPaymentCategory &&
                item.mentorPaymentCategory.rate
                  ? `$${item.mentorPaymentCategory.rate.toFixed(2)}`
                  : 'N/A',
              amount:
                item && item?.isFullTime
                  ? '--'
                  : item && item.mentorPayout && item.mentorPayout.amount
                  ? `$${(item.mentorPayout.amount / 100).toFixed(2)}`
                  : item &&
                    item.activityDuration &&
                    item.mentorPaymentCategory &&
                    item.mentorPaymentCategory.rate
                  ? `$${(
                      (item.mentorPaymentCategory.rate *
                        item.activityDuration) /
                      60
                    ).toFixed(2)}`
                  : 'N/A',
              paymentStatus:
                item && item.mentorPayout
                  ? item.mentorPayout.status
                  : item
                  ? !item.MentorPayoutId && 'New'
                  : 'N/A',
            };
          });
          setData(parsedData);
        }
        return true;
      }}
    >
      <img src={Download_CSV_Icon} alt="csv" /> {label}
    </CSVLink>
  );
};

export default DownloadTimLogsInCsv;
