import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import fonts from '../../../common/fonts';
import Colors from '../../../common/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    approvedDetails: {
      paddingLeft: '35px !important',
      '& img': {
        marginRight: 7,
      },

      '& .approvedText, & .anchorText': {
        fontSize: 12,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.072px',
      },

      '& .approvedText': {
        color: '#000',
        fontWeight: 600,
      },

      '& .anchorText': {
        marginLeft: 11,
        fontWeight: 400,
        color: '#2F80ED',
        cursor: 'pointer',
        borderBottom: '1px solid #2F80ED',
      },

      '& .logActivity': {
        fontSize: 16,
        color: '#2F80ED',
        fontWeight: 400,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.176px',
        borderBottom: '1px solid #2F80ED',
      },
    },

    smallAvatar: {
      width: 19,
      height: 19,
      marginRight: 7,
    },

    smallAvatarMeeting: {
      width: 33,
      height: 33,
      margin: '6px 10px 0px 0px',
    },

    smallAvatarEmptyMeeting: {
      width: 33,
      height: 33,
      margin: '6px 0px 0px 0px',
      borderRadius: '50%',
      backgroundColor: '#d4d4d4',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& svg': {
        width: '90%',
        height: '90%',
        objectFit: 'contain',
      },
    },

    smallAvatarEmpty: {
      width: 19,
      height: 19,
      marginRight: 7,
      borderRadius: '50%',
      backgroundColor: '#d4d4d4',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& svg': {
        width: '90%',
        height: '90%',
        objectFit: 'contain',
      },
    },
    disabledBtn: {
      opacity: 0.4,
    },

    meetingDialogMain: {
      '& .MuiDialog-paper': {
        background: '#FFFFFF',
        border: '1px solid #D4D4D4',
        borderRadius: 25,
        maxWidth: 551,

        [theme.breakpoints.down('xs')]: {
          margin: '0px 10px !important',
        },
      },

      '& .MuiTypography-h6': {
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '-0.019em',
        color: '#272929',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .MuiTypography-body1': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
      },
    },

    meetingNotesDialog: {
      padding: '38px 42px 48px',

      '& .dialogHeading': {
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '-0.019em',
        color: '#272929',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .dialogSubtitle': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
      },
    },

    mobileMeetingsView: {
      padding: '14px 16px',
      borderRadius: 10,
      border: '1px solid #D4D4D4',
      marginBottom: 15,
      boxShadow: '0px 2px 7px rgba(68, 68, 68, 0.07)',

      '& .MuiAvatarGroup-root': {
        marginRight: 10,
      },

      '& .MuiTypography-h6, .MuiTypography-body1': {
        color: '#272929',
        fontFamily: fonts.INTER,
      },

      '& .advisorName': {
        fontSize: 16,
        color: '#272929',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.011em',
        wordBreak: 'break-all',
        whiteSpace: 'break-spaces',
      },

      '& .MuiTypography-h6': {
        fontSize: 18,
        fontWeight: 600,
        letterSpacing: '-0.014em',
      },

      '& .MuiTypography-body1': {
        fontSize: 16,
        letterSpacing: '-0.011em',
      },

      '& a': {
        fontSize: 16,
        color: '#2F80ED',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.011em',
        textDecorationLine: 'underline !important',
      },

      '& .linkSpacing': {
        marginBottom: 11,
      },

      '& .MuiIconButton-root': {
        padding: 5,
      },
    },

    addReason: {
      '&.MuiButton-root': {
        fontSize: 14,
        fontWeight: 400,
        padding: '4px',
        fontFamily: fonts.INTER,
        color: Colors.BLUE_SEVEN,
        letterSpacing: '-0.011em',
        textTransform: 'capitalize',
      },
    },
  })
);
