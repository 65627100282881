import {
  TableRow,
  TableCell,
  TableBody,
  Table,
  Typography,
  TableHead,
  Hidden,
  Grid,
  Box,
  Tooltip,
} from '@material-ui/core';

import { useStyles } from './MyStudentsStyle';

interface Parent {
  firstName: string;
  lastName: string;
  email: string;
  highSchool?: string;
  phoneNumber: string;
}
const ParentTable = ({
  title,
  parents,
}: {
  title: string;
  parents: [Parent] | null | any;
}) => {
  const classes = useStyles();

  function formatPhoneNumber(phoneNumberString: string) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumberString;
  }
  return (
    <>
      <Typography variant="h4" className={classes.studentSectionHeader}>
        {title}
      </Typography>

      <Table className={classes.guardianTable}>
        <TableHead>
          <TableRow>
            <TableCell width="20%"></TableCell>
            <TableCell width="20%"></TableCell>
            <TableCell width="40%"></TableCell>
            <TableCell width="20%"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Hidden only={['sm', 'xs']}>
            {parents && parents.length ? (
              parents.map((item: Parent, index: number) => (
                <TableRow key={index}>
                  <TableCell>
                    {item.firstName} {item.lastName}
                  </TableCell>
                  <TableCell>
                    <Tooltip title={item.phoneNumber} placement="top-start">
                      <Box>{item.phoneNumber}</Box>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <a href={`mailto:${item.email}`}>
                      <Tooltip title={item.email} placement="top-start">
                        <Typography noWrap className="emailWidth">
                          {item.email}
                        </Typography>
                      </Tooltip>
                    </a>
                  </TableCell>
                  <TableCell>
                    Status
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No record found!
                </TableCell>
              </TableRow>
            )}
          </Hidden>

          <Hidden only={['xl', 'lg', 'md']}>
            <TableRow>
              <TableCell colSpan={4}>
                <Grid className={classes.mobileTableBox}>
                  {parents && parents.length ? (
                    parents.map((item: Parent, index: number) => (
                      <Box key={index} pb={3}>
                        <Box>
                          {item.firstName} {item.lastName}
                        </Box>
                        <Box>{formatPhoneNumber(item.phoneNumber)}</Box>
                        <Box>
                          <a href="mailto:{item.email}">
                            <Tooltip title={item?.email || '--'}>
                              <Typography
                                variant="body2"
                                className="mobileEmailWidth"
                              >
                                {' '}
                                {item?.email || '--'}
                              </Typography>
                            </Tooltip>
                          </a>
                        </Box>
                        <Box>
                          Status:{' '}
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Box textAlign="center">No record found!</Box>
                  )}
                </Grid>
              </TableCell>
            </TableRow>
          </Hidden>
        </TableBody>
      </Table>
    </>
  );
};

export default ParentTable;
