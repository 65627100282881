import { FC, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import moment from 'moment';
import momentTz from 'moment-timezone';

import clsx from 'clsx';

import {
  Accordion,
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Hidden,
  InputBase,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';

import Header from '../../../components/layout/Header';
import Loader from '../../../components/Loader';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { AppContext } from '../../../contexts';
import AdvisorBookingModal, {
  IBoookingStudent,
} from './BookingMeeting/AdvisorBookingModal';
import {
  AllMentorStudentsListQuery,
  AllMentorStudentsListQueryVariables,
  AllMentorStudentsListQuery_allMentorStudentsList_students,
} from './__generated__/AllMentorStudentsListQuery';
import {
  VIDEO_CHAT_USAGE_FILTER,
  OFFLINE_COUNT_USAGE_FILTER,
  roundNumber,
  associationTypeOptions,
  bookingInfoStatuses,
  fetchGradYear,
} from '../../../constants';

import Calendar_Image from '../../../img/calendar-image.svg';
import Chat_Table_Icon from '../../../img/chat-table.svg';
import Email_Table_Icon from '../../../img/email-table.svg';
import Phone_Table_Icon from '../../../img/phone-table.svg';
import ASSIST_ICON from '../../../img/advisor-assist-icon.svg';
import Colors from '../../../common/colors';
import fonts from '../../../common/fonts';

import AlertDialog from '../../../components/AlertDialog';
import { useStyles, useStylesTable } from './MyStudentsStyle';

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    boxShadow:
      '0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24)',
  },
  tooltip: {
    borderRadius: 6,
    padding: '8px 10px',
    fontFamily: fonts.POPPINS_FONT,
    fontSize: 10,
    fontWeight: 600,
    letterSpacing: '0.018em',
    textTransform: 'uppercase',
    color: Colors.WHITE_ONE,
    boxShadow:
      '0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24)',
  },
}));

const ALL_MENTOR_STUDENTS_LIST_QUERY = gql`
  query AllMentorStudentsListQuery(
    $MentorId: Int
    $limit: Int
    $page: Int
    $searchQuery: String
    $graduated: Boolean
    $cancelledPlan: Boolean
    $graduationYear: String
    $premiumPackageType: String
    $videoChatUsage: String
    $offlineHoursUsage: String
    $isExtraMatch: Boolean
  ) {
    allMentorStudentsList(
      input: {
        MentorId: $MentorId
        limit: $limit
        page: $page
        searchQuery: $searchQuery
        graduated: $graduated
        cancelledPlan: $cancelledPlan
        graduationYear: $graduationYear
        premiumPackageType: $premiumPackageType
        videoChatUsage: $videoChatUsage
        offlineHoursUsage: $offlineHoursUsage
        isExtraMatch: $isExtraMatch
      }
    ) {
      nextRecord
      activeStudentsCount
      graduatedStudentsCount
      cancelledStudentsCount
      extraMatchStudentsCount
      graduatedStudents {
        student {
          id
          firstName
          lastName
        }
      }
      students {
        associateType
        upcomingMeetings {
          status
          startTime
          endTime
          zoomLink
        }
        StudentId
        student {
          firstName
          sendBirdChannelUrl
          lastName
          email
          gpa
          premiumPackageType
          phoneNumber
          pictureUrl
          email
          highSchoolYear
          sendbirdId
          premiumTotalEssayCount
          premiumTotalVideoCount
          PremiumAppCreditUsed
          premiumVideoCountUsed
          initialKickOffDate
          premiumTotalOfflineCount
          premiumOfflineCountUsed {
            essayCount
            offlineResearch
            MeetingWithAdvisor
            milestoneTeamTotal
            Other
            dressRehearsalCount
            correspondenceTotal
          }
          firstMeetingBookedDate {
            id
            startTime
            endTime
          }
          id
        }
      }
    }
  }
`;
interface TParams {
  id: string;
}
interface fetchResult {
  fetchMoreResult: AllMentorStudentsListQuery | null;
}
interface dropDownListsInterface {
  GRAD_YEARS_LIST: string[];
  PACKAGE_LIST: string[];
}

const StudentsPage: FC<RouteComponentProps<TParams>> = ({
  history,
}): JSX.Element => {
  const classes = useStyles();
  const tooltipclasses = useStylesBootstrap();
  const tableClasses = useStylesTable();

  const { user } = useContext(AppContext);
  const [searchQuery, setSearchQuery] = useState('');
  const [students, setStudents] = useState<
    | (AllMentorStudentsListQuery_allMentorStudentsList_students | null)[]
    | null
    | undefined
  >();
  const [grad, setGrad] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [additionalTab, setAdditionalTab] = useState(false);

  const [page, setPage] = useState(0);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [student, setStudent] = useState<IBoookingStudent | null>(null);
  const [premiumPackageType, setPremiumPackageType] = useState('');
  const [gradYear, setGradYear] = useState('');
  const [gradYearList, setGradYearList] = useState<string[] | undefined>();
  const [videoChatUsage, setVideoChatUsage] = useState<string>('');
  const [offlineHoursUsage, setOfflineHoursUsage] = useState<string>('');
  const [premiumPackageLists, setPremiumPackageLists] = useState<
    string[] | undefined
  >();

  const limit = 10;
  let timeoutId = 0;
  let MentorId: number | null = null;

  if (user && user.id) {
    MentorId = user.id;
  }
  useEffect(() => {
    setPage(0);
  }, [
    searchQuery,
    cancelled,
    grad,
    additionalTab,
    gradYear,
    premiumPackageType,
  ]);

  const getData = (page: number, fetchMore: Function) => {
    fetchMore({
      variables: {
        page: page + 1,
      },
      updateQuery: (prev: AllMentorStudentsListQuery, next: fetchResult) => {
        if (!next) return prev;
        const { fetchMoreResult } = next;
        if (
          !fetchMoreResult ||
          !fetchMoreResult.allMentorStudentsList ||
          !fetchMoreResult.allMentorStudentsList.students
        )
          return prev;
        return fetchMoreResult;
      },
    });
    setPage(page);
  };

  const noRecordText = () => {
    return `No ${
      !grad && !cancelled && !additionalTab
        ? `active `
        : grad
        ? `graduated `
        : cancelled
        ? `canceled `
        : additionalTab
        ? `additional `
        : ''
    }students to display`;
  };

  useEffect(() => {
    const getLists = async () => {
      if (!gradYear && !premiumPackageLists) {
        const getLists: dropDownListsInterface = await fetchGradYear(MentorId);
        setGradYearList(getLists.GRAD_YEARS_LIST);
        setPremiumPackageLists(getLists.PACKAGE_LIST);
      }
    };
    getLists();
  }, []);

  return (
    <>
      <Header />

      <Container maxWidth={false}>
        <Grid className={classes.listPageStyle}>
          <Typography variant="h2">My Students</Typography>

          <Box
            display={'flex'}
            justifyContent="left"
            alignItems="center"
            className={tableClasses.forMobileWrap}
          >
            <Grid
              className={clsx(
                classes.searchComponent,
                classes.searchComponentFilter
              )}
            >
              <InputBase
                placeholder="Search Students"
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  clearTimeout(timeoutId);
                  timeoutId = window.setTimeout(() => {
                    setSearchQuery(value);
                  }, 500);
                }}
              />
            </Grid>

            <Grid
              className={clsx(
                tableClasses.forDropdownArrow,
                tableClasses.studentGradYear
              )}
            >
              <Select
                onChange={(e) => {
                  const value = e.target.value as string;
                  setGradYear(value);
                }}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                variant="outlined"
                value={gradYear || 'Grad Year'}
                style={{ color: gradYear ? '#272929' : '#A9A9A9' }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
              >
                <MenuItem value={''} className="disableText">
                  Grad Year
                </MenuItem>
                {gradYearList?.map((year) => (
                  <MenuItem
                    style={{ display: year === 'Grad Year' ? 'none' : 'block' }}
                    value={`${year}`}
                  >
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid
              className={clsx(
                tableClasses.forDropdownArrow,
                tableClasses.studentPackageType
              )}
            >
              <Select
                onChange={(e) => {
                  const value = e.target.value as string;
                  setPremiumPackageType(value);
                }}
                displayEmpty
                defaultValue={'Package Type'}
                inputProps={{ 'aria-label': 'Without label' }}
                variant="outlined"
                value={premiumPackageType || 'Package Type'}
                style={{ color: premiumPackageType ? '#272929' : '#A9A9A9' }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
              >
                <MenuItem value={''} className="disableText">
                  Package Type
                </MenuItem>

                {premiumPackageLists?.map((packageName) => (
                  <MenuItem
                    style={{
                      display:
                        packageName === 'Package Type' ? 'none' : 'block',
                    }}
                    value={`${packageName}`}
                  >
                    {packageName}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid
              className={clsx(
                tableClasses.forDropdownArrow,
                tableClasses.studentPackageType
              )}
            >
              <Select
                onChange={(e) => {
                  const value = e.target.value as string;
                  setVideoChatUsage(value);
                }}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                variant="outlined"
                value={videoChatUsage || 'Video Chat Usage'}
                style={{ color: videoChatUsage ? '#272929' : '#A9A9A9' }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
              >
                <MenuItem value={''} className="disableText">
                  Video Chat Usage
                </MenuItem>
                {VIDEO_CHAT_USAGE_FILTER?.map((usage) => (
                  <MenuItem
                    style={{
                      display: usage === 'Video Chat Usage' ? 'none' : 'block',
                    }}
                    value={`${usage}`}
                  >
                    {usage}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid
              className={clsx(
                tableClasses.forDropdownArrow,
                tableClasses.studentPackageType
              )}
            >
              <Select
                onChange={(e) => {
                  const value = e.target.value as string;
                  setOfflineHoursUsage(value);
                }}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                variant="outlined"
                value={offlineHoursUsage || 'Offline Usage'}
                style={{ color: offlineHoursUsage ? '#272929' : '#A9A9A9' }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
              >
                <MenuItem value={''} className="disableText">
                  Offline Usage
                </MenuItem>
                {OFFLINE_COUNT_USAGE_FILTER?.map((usage) => (
                  <MenuItem
                    style={{
                      display: usage === 'Offline Usage' ? 'none' : 'block',
                    }}
                    value={`${usage}`}
                  >
                    {usage}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Box>

          <Query<
            AllMentorStudentsListQuery,
            AllMentorStudentsListQueryVariables
          >
            query={ALL_MENTOR_STUDENTS_LIST_QUERY}
            variables={{
              MentorId: MentorId,
              page: 1,
              limit,
              searchQuery,
              graduated: grad,
              cancelledPlan: cancelled,
              graduationYear: gradYear,
              isExtraMatch: additionalTab,
              videoChatUsage: videoChatUsage,
              offlineHoursUsage: offlineHoursUsage,
              premiumPackageType: premiumPackageType,
            }}
            fetchPolicy="cache-and-network"
          >
            {({ data, loading, fetchMore }) => {
              if (loading) {
                return <Loader />;
              }
              if (!data) return null;
              const { allMentorStudentsList }: AllMentorStudentsListQuery =
                data;
              setStudents(allMentorStudentsList?.students);

              return (
                <>
                  {user && student && (
                    <AdvisorBookingModal
                      setOpenCalendar={setOpenCalendar}
                      advisorId={user.id}
                      openCalendar={openCalendar}
                      student={student}
                    />
                  )}

                  <Box overflow="auto" className={classes.studentListTabs}>
                    <Button
                      variant="text"
                      onClick={() => {
                        setGrad(false);
                        setCancelled(false);
                        setAdditionalTab(false);
                      }}
                      className={
                        !grad && !cancelled && !additionalTab ? 'active' : ''
                      }
                    >
                      Active{' '}
                      {'(' + allMentorStudentsList?.activeStudentsCount + ')'}
                    </Button>
                    <Button
                      variant="text"
                      onClick={() => {
                        setGrad(true);
                        setCancelled(false);
                        setAdditionalTab(false);
                      }}
                      className={grad ? 'active' : ''}
                    >
                      Graduated{' '}
                      {'(' +
                        allMentorStudentsList?.graduatedStudentsCount +
                        ')'}
                    </Button>
                    <Button
                      variant="text"
                      onClick={() => {
                        setGrad(false);
                        setCancelled(false);
                        setAdditionalTab(true);
                      }}
                      className={additionalTab ? 'active' : ''}
                    >
                      {`Specialty (${allMentorStudentsList?.extraMatchStudentsCount})`}
                    </Button>
                    <Button
                      variant="text"
                      onClick={() => {
                        setGrad(false);
                        setCancelled(true);
                        setAdditionalTab(false);
                      }}
                      className={cancelled ? 'active' : ''}
                    >
                      Canceled
                      {'(' +
                        allMentorStudentsList?.cancelledStudentsCount +
                        ')'}
                    </Button>
                    {additionalTab && (
                      <AlertDialog
                        title="Viewing Additional Students"
                        message="These students are matched to you so that you may assist them through the lens of your unique skillset or experience (e.g., BSMD, LI, etc.). You will not receive automated match emails or communication regarding student entitlement usage."
                      />
                    )}
                    <Grid
                      className={clsx(
                        classes.commonTable,
                        classes.studentListTable
                      )}
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="left"
                              className="theadSingleAvatarSpacing"
                            >
                              Student
                            </TableCell>
                            <TableCell align="left">Contact</TableCell>
                            <TableCell align="left">
                              <Box>Grad Year </Box>
                            </TableCell>
                            <TableCell align="left">
                              <Box>Membership</Box>
                            </TableCell>

                            <TableCell align="center">VC Used</TableCell>
                            <TableCell align="center">Offline Used</TableCell>
                            <TableCell align="center"><img style={{ cursor: 'pointer', paddingRight: '3px', height: '15px' }} src={ASSIST_ICON} alt="info" />Apps</TableCell>
                            <TableCell align="center">Book</TableCell>

                            <TableCell align="left">
                              <Box>Upcoming meeting </Box>
                            </TableCell>
                            <TableCell align="left">Meeting link</TableCell>
                            {additionalTab ? (
                              <TableCell align="left">Pairing Type</TableCell>
                            ) : null}
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {loading ? (
                            <TableRow>
                              <TableCell>
                                <Loader />
                              </TableCell>
                            </TableRow>
                          ) : !!students?.length ? (
                            students?.map((item, index: number) => {
                              const firstName = item?.student?.firstName || '';
                              const lastName = item?.student?.lastName || '';
                              const grade =
                                item?.student?.highSchoolYear || '--';
                              const membership =
                                item?.student?.premiumPackageType || '';
                              const email = item?.student?.email || '';
                              const phoneNumber =
                                item?.student?.phoneNumber || '';
                              const picture = item?.student?.pictureUrl || '';
                              const sendbirdId =
                                item?.student?.sendBirdChannelUrl || '';
                              const premiumVideoCountUsed =
                                item?.student?.premiumVideoCountUsed || 0;
                              const premiumTotalVideoCount =
                                item?.student?.premiumTotalVideoCount || 0;
                              const PremiumAppCreditUsed =
                                item?.student?.PremiumAppCreditUsed || 0;
                              const premiumTotalEssayCount =
                                item?.student?.premiumTotalEssayCount || 0;                            
                              const isVideoChatDisabled =
                                premiumVideoCountUsed >=
                                  premiumTotalVideoCount ||
                                grad ||
                                cancelled ||
                                false;

                              const premiumTotalOfflineCount =
                                item?.student?.premiumTotalOfflineCount || 0;
                              const premiumOfflineCountUsed =
                                item?.student?.premiumOfflineCountUsed;
                              let offlineCountUsed = 0;

                              if (premiumOfflineCountUsed) {
                                const {
                                  MeetingWithAdvisor,
                                  Other,
                                  essayCount,
                                  milestoneTeamTotal,
                                  offlineResearch,
                                  dressRehearsalCount,
                                  correspondenceTotal,
                                } = premiumOfflineCountUsed;
                                offlineCountUsed =
                                  MeetingWithAdvisor +
                                  Other +
                                  essayCount +
                                  milestoneTeamTotal +
                                  offlineResearch +
                                  dressRehearsalCount +
                                  correspondenceTotal;
                              }

                              item?.upcomingMeetings?.sort(
                                (a: any, b: any) => a?.startTime - b?.startTime
                              );

                              let upcomingEvent =
                                item?.upcomingMeetings &&
                                item?.upcomingMeetings[0];

                              const pairingType =
                                associationTypeOptions.find(
                                  (option) =>
                                    option.value === item?.associateType
                                )?.label || '--';

                              let percentage = roundNumber(
                                (premiumVideoCountUsed /
                                  premiumTotalVideoCount) *
                                  100,
                                1
                              );

                              let appCreditUsedPercentage = roundNumber(
                                (PremiumAppCreditUsed /
                                  premiumTotalEssayCount) *
                                  100,
                                1
                              );                              

                              let percentageOffline = roundNumber(
                                (offlineCountUsed / premiumTotalOfflineCount) *
                                  100,
                                1
                              );
                              if (
                                premiumTotalVideoCount == 0 &&
                                premiumVideoCountUsed >= premiumTotalVideoCount
                              ) {
                                percentage = 100;
                              }
                              if (
                                premiumTotalOfflineCount == 0 &&
                                offlineCountUsed >= premiumTotalOfflineCount
                              ) {
                                percentageOffline = 100;
                              }
                              return (
                                <>
                                  <Hidden only={['sm', 'xs']}>
                                    <TableRow
                                      key={`currnetStudent_${index}`}
                                      className={classes.cursorPointer}
                                    >
                                      <TableCell
                                        onClick={() => {
                                          if (
                                            item?.StudentId &&
                                            item?.student
                                          ) {
                                            history.push(
                                              `/students/student-details/${item?.StudentId}?_t=info`
                                            );
                                          }
                                        }}
                                      >
                                        <Box
                                          display="flex"
                                          alignItems="center"
                                          className={classes.listAvatar}
                                        >
                                          <Box pr={2}>
                                            <Avatar
                                              src={picture || ''}
                                              alt={firstName}
                                            />
                                          </Box>
                                          {firstName + ' ' + lastName}
                                        </Box>
                                      </TableCell>
                                      <TableCell className="socialIcons">
                                        <Box display="flex" alignItems="center">
                                          {email ? (
                                            <a href={`mailto:${email}`}>
                                              <img
                                                src={Email_Table_Icon}
                                                alt="email"
                                              />
                                            </a>
                                          ) : (
                                            <img
                                              src={Email_Table_Icon}
                                              alt="email"
                                              className="opacityEmail"
                                            />
                                          )}
                                          <a href={`/chat/${sendbirdId} `}>
                                            <img
                                              src={Chat_Table_Icon}
                                              alt="chat"
                                            />
                                          </a>

                                          {phoneNumber ? (
                                            <Tooltip
                                              title={phoneNumber}
                                              classes={tooltipclasses}
                                              arrow
                                            >
                                              <a href={`tel:${phoneNumber}`}>
                                                <img
                                                  src={Phone_Table_Icon}
                                                  alt="phone"
                                                />
                                              </a>
                                            </Tooltip>
                                          ) : (
                                            <img
                                              src={Phone_Table_Icon}
                                              alt="phone"
                                              className="opacityPhone"
                                            />
                                          )}
                                        </Box>
                                      </TableCell>
                                      <TableCell
                                        className="sortingTdPadding"
                                        onClick={() => {
                                          if (
                                            item?.StudentId &&
                                            item?.student
                                          ) {
                                            history.push(
                                              `/students/student-details/${item?.StudentId}?_t=info`
                                            );
                                          }
                                        }}
                                      >
                                        {grade}
                                      </TableCell>
                                      <TableCell
                                        className="sortingTdPadding"
                                        onClick={() => {
                                          if (
                                            item?.StudentId &&
                                            item?.student
                                          ) {
                                            history.push(
                                              `/students/student-details/${item?.StudentId}?_t=info`
                                            );
                                          }
                                        }}
                                      >
                                        {membership}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        onClick={() => {
                                          if (
                                            item?.StudentId &&
                                            item?.student
                                          ) {
                                            history.push(
                                              `/students/student-details/${item?.StudentId}?_t=info`
                                            );
                                          }
                                        }}
                                      >
                                        <Tooltip
                                          title={`${premiumVideoCountUsed} / ${premiumTotalVideoCount} hours used`}
                                          arrow
                                          classes={tooltipclasses}
                                          placement="top"
                                        >
                                          <Box
                                            margin="0px auto"
                                            maxWidth="fit-content"
                                            padding="0px 11px"
                                            bgcolor={
                                              percentage >= 100 ||
                                              isNaN(percentage)
                                                ? '#FCE3E3'
                                                : percentage >= 75
                                                ? '#FFF8E4'
                                                : ''
                                            }
                                            borderRadius={5}
                                            textAlign="center"
                                          >
                                            {isNaN(percentage)
                                              ? '100%'
                                              : `${percentage}%`}
                                          </Box>
                                        </Tooltip>
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        onClick={() => {
                                          if (
                                            item?.StudentId &&
                                            item?.student
                                          ) {
                                            history.push(
                                              `/students/student-details/${item?.StudentId}?_t=info`
                                            );
                                          }
                                        }}
                                      >
                                        <Tooltip
                                          title={`${offlineCountUsed} / ${premiumTotalOfflineCount} hours used`}
                                          arrow
                                          classes={tooltipclasses}
                                          placement="top"
                                        >
                                          <Box
                                            bgcolor={
                                              percentageOffline >= 100 ||
                                              isNaN(percentageOffline)
                                                ? '#FCE3E3'
                                                : percentageOffline >= 75
                                                ? '#FFF8E4'
                                                : ''
                                            }
                                            borderRadius={5}
                                            textAlign="center"
                                            maxWidth="fit-content"
                                            padding="0px 11px"
                                            margin="0px auto"
                                          >
                                            {isNaN(percentageOffline)
                                              ? '100%'
                                              : `${percentageOffline}%`}
                                          </Box>
                                        </Tooltip>
                                      </TableCell>
                                      {/* start */}
                                      <TableCell
                                        align="center"
                                        onClick={() => {
                                          if (
                                            item?.StudentId &&
                                            item?.student
                                          ) {
                                            history.push(
                                              `/students/student-details/${item?.StudentId}?_t=info`
                                            );
                                          }
                                        }}
                                      >
                                          <Box
                                            bgcolor={
                                              appCreditUsedPercentage >= 100 ||
                                              isNaN(appCreditUsedPercentage)
                                                ? '#FCE3E3'
                                                : appCreditUsedPercentage >= 75
                                                ? '#FFF8E4'
                                                : ''
                                            }
                                            borderRadius={5}
                                            textAlign="center"
                                            maxWidth="fit-content"
                                            padding="0px 11px"
                                            margin="0px auto"
                                          >
                                            {PremiumAppCreditUsed} of {premiumTotalEssayCount}
                                          </Box>
                                      </TableCell>                                      
                                      {/* end */}
                                      <TableCell align="center">
                                        <Tooltip
                                          title={
                                            isVideoChatDisabled
                                              ? 'Video chat hours exhausted'
                                              : 'Available TimeSlots'
                                          }
                                          disableHoverListener={
                                            !user?.isActive ? true : false
                                          }
                                        >
                                          <img
                                            className={
                                              isVideoChatDisabled
                                                ? classes.disabledIcon
                                                : ''
                                            }
                                            style={
                                              !user?.isActive
                                                ? {
                                                    pointerEvents: 'none',
                                                    opacity: '60%',
                                                  }
                                                : {}
                                            }
                                            src={Calendar_Image}
                                            alt="calendar"
                                            width="16px"
                                            onClick={() => {
                                              if (!user?.isActive) {
                                                return;
                                              }
                                              if (
                                                item?.student &&
                                                !isVideoChatDisabled
                                              ) {
                                                setStudent({
                                                  id: item.student.id,
                                                  firstName:
                                                    item.student.firstName,
                                                  lastName:
                                                    item.student.lastName,
                                                  email: item.student.email,
                                                  premiumTotalVideoCount:
                                                    item.student
                                                      .premiumTotalVideoCount,
                                                  premiumVideoCountUsed:
                                                    item.student
                                                      .premiumVideoCountUsed,
                                                  initialKickOffDate:
                                                    item.student
                                                      .initialKickOffDate,
                                                  firstMeetingBookedDate:
                                                    item.student
                                                      .firstMeetingBookedDate,
                                                });
                                                setOpenCalendar(true);
                                              }
                                            }}
                                          />
                                        </Tooltip>
                                      </TableCell>

                                      {upcomingEvent?.startTime ? (
                                        <TableCell
                                          className="sortingTdPadding"
                                          onClick={() => {
                                            if (
                                              item?.StudentId &&
                                              item?.student
                                            ) {
                                              history.push(
                                                `/students/student-details/${item?.StudentId}?_t=info`
                                              );
                                            }
                                          }}
                                        >
                                          {upcomingEvent?.startTime &&
                                            `${moment
                                              .parseZone(
                                                upcomingEvent?.startTime &&
                                                  new Date(
                                                    upcomingEvent?.startTime *
                                                      1000
                                                  ).toString()
                                              )
                                              .local()
                                              .format(
                                                'ddd MMM DD, YYYY, h:mma '
                                              )}`}
                                          -
                                          {upcomingEvent?.endTime &&
                                            `${moment
                                              .parseZone(
                                                upcomingEvent?.endTime &&
                                                  new Date(
                                                    upcomingEvent?.endTime *
                                                      1000
                                                  ).toString()
                                              )
                                              .local()
                                              .format(' h:mma')} `}
                                          {`${momentTz(
                                            upcomingEvent?.startTime * 1000
                                          )
                                            .tz(momentTz.tz.guess())
                                            .format('z')}`}
                                        </TableCell>
                                      ) : (
                                        <TableCell
                                          className="sortingTdPadding"
                                          onClick={() => {
                                            if (
                                              item?.StudentId &&
                                              item?.student
                                            ) {
                                              history.push(
                                                `/students/student-details/${item?.StudentId}?_t=info`
                                              );
                                            }
                                          }}
                                        >
                                          --
                                        </TableCell>
                                      )}
                                      <TableCell
                                        onClick={() => {
                                          if (
                                            item?.StudentId &&
                                            item?.student
                                          ) {
                                            history.push(
                                              `/students/student-details/${item?.StudentId}?_t=info`
                                            );
                                          }
                                        }}
                                      >
                                        {upcomingEvent?.zoomLink &&
                                        upcomingEvent.status !==
                                          bookingInfoStatuses.DELETED ? (
                                          <a
                                            className="tableLinkText"
                                            href={upcomingEvent?.zoomLink}
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            Link
                                          </a>
                                        ) : (
                                          '--'
                                        )}
                                      </TableCell>
                                      {additionalTab ? (
                                        <TableCell>{pairingType}</TableCell>
                                      ) : null}
                                    </TableRow>
                                  </Hidden>

                                  <Hidden only={['xl', 'lg', 'md']}>
                                    <Accordion
                                      className={classes.mobileTableAccordion}
                                    >
                                      <Box
                                        style={{
                                          padding: '5px',
                                        }}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        width="100%"
                                        key={`currnetStudent_${index}`}
                                        onClick={() => {
                                          if (
                                            item?.StudentId &&
                                            item?.student
                                          ) {
                                            history.push(
                                              `/students/student-details/${item?.StudentId}?_t=info`
                                            );
                                          }
                                        }}
                                      >
                                        <Box>
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                            width="100%"
                                            className={classes.listAvatar}
                                          >
                                            <Box
                                              pr={2}
                                              onClick={(e) =>
                                                e.stopPropagation()
                                              }
                                            >
                                              <Avatar
                                                src={picture || ''}
                                                alt={firstName}
                                              />
                                            </Box>

                                            <Typography
                                              noWrap
                                              className="advisorName"
                                            >
                                              {firstName + ' ' + lastName}
                                            </Typography>
                                          </Box>
                                        </Box>
                                        <Box
                                          className="socialIcons"
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                          >
                                            {email ? (
                                              <Tooltip
                                                title={email}
                                                classes={tooltipclasses}
                                                arrow
                                              >
                                                <a href={`mailto:${email}`}>
                                                  <img
                                                    src={Email_Table_Icon}
                                                    alt="email"
                                                  />
                                                </a>
                                              </Tooltip>
                                            ) : (
                                              <img
                                                src={Email_Table_Icon}
                                                alt="email"
                                                className="opacityEmail"
                                              />
                                            )}
                                            <a href={`/chat/${sendbirdId}`}>
                                              <img
                                                src={Chat_Table_Icon}
                                                alt="chat"
                                              />
                                            </a>

                                            {phoneNumber ? (
                                              <Tooltip
                                                title={phoneNumber}
                                                classes={tooltipclasses}
                                                arrow
                                              >
                                                <a href={`tel:${phoneNumber}`}>
                                                  <img
                                                    src={Phone_Table_Icon}
                                                    alt="phone"
                                                  />
                                                </a>
                                              </Tooltip>
                                            ) : (
                                              <img
                                                src={Phone_Table_Icon}
                                                alt="phone"
                                                className="opacityPhone"
                                              />
                                            )}
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Accordion>
                                  </Hidden>
                                </>
                              );
                            })
                          ) : (
                            ''
                          )}

                          {!allMentorStudentsList?.students?.length && (
                            <TableRow
                              key={`currnetStudent`}
                              className={classes.tableEmptyRecord}
                            >
                              <TableCell colSpan={9}>
                                {noRecordText()}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>

                        {allMentorStudentsList?.activeStudentsCount ||
                        allMentorStudentsList?.graduatedStudentsCount ? (
                          <TableFooter>
                            <TableRow>
                              <TablePagination
                                rowsPerPageOptions={[2]}
                                colSpan={10}
                                count={
                                  grad
                                    ? allMentorStudentsList?.graduatedStudentsCount ||
                                      0
                                    : cancelled
                                    ? allMentorStudentsList?.cancelledStudentsCount ||
                                      0
                                    : additionalTab
                                    ? allMentorStudentsList?.extraMatchStudentsCount ||
                                      0
                                    : allMentorStudentsList.activeStudentsCount ||
                                      0
                                }
                                rowsPerPage={limit}
                                page={page}
                                onChangePage={(e, page) => {
                                  getData(page, fetchMore);
                                }}
                              />
                            </TableRow>
                          </TableFooter>
                        ) : (
                          ''
                        )}
                      </Table>
                    </Grid>
                  </Box>
                </>
              );
            }}
          </Query>
        </Grid>
      </Container>
    </>
  );
};

export default withRouter(StudentsPage);
