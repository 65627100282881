import { FC, useEffect } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';

import CollegeCard from './CollegeCard';

import { isValueBetween } from '../../../common/utility';
import { popularCollegesStyles } from '../style';
import { PopularCollegesProps } from '../../../common/interfaces';

let timeoutId: any = 0;
const PopularColleges: FC<PopularCollegesProps> = ({
  colleges,
  title,
  refetch,
  loadMore,
  studentId,
}) => {
  const { searchHeading } = popularCollegesStyles();

  const scrollHandler = () => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  };

  useEffect(scrollHandler, []);

  const handleScroll = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      const {
        documentElement: { offsetHeight },
        scrollingElement,
      } = document;
      const scrollHeight = scrollingElement ? scrollingElement.scrollTop : 0;
      if (
        isValueBetween(
          scrollHeight + window.innerHeight,
          offsetHeight - window.innerHeight,
          offsetHeight + 50
        ) &&
        loadMore
      ) {
        loadMore();
      }
    }, 200);
  };

  if (!colleges || !colleges.length)
    return <p className="text-center p-2">No Colleges Found</p>;

  return (
    <Box>
      {title && (
        <Typography variant="h3" component="h3" className={searchHeading}>
          {title}
        </Typography>
      )}

      <Grid container spacing={1}>
        {colleges.map((college, i) => {
          if (!college) return null;
          return (
            <CollegeCard
              key={i}
              college={college}
              refetch={refetch}
              studentId={studentId}
            />
          );
        })}
      </Grid>
    </Box>
  );
};

export default PopularColleges;
