import { createMuiTheme } from '@material-ui/core';

import Colors from '../common/colors';
import Fonts from '../common/fonts';

import SELECT_ARROW_ICON from '../img/select-arrow-icon.svg';
import SELECT_ARROW_DOWN_ICON from '../img/SelectArrowDownIcon.svg';

const theme = createMuiTheme();

const primaryMain = Colors.GREEN_EIGHT;
const primaryLight = Colors.GREEN_TEN;
const primaryDark = Colors.GREEN_NINE;
const primaryText = Colors.WHITE_ONE;

const secondaryMain = Colors.BLUE_SEVEN;
const secondaryLight = Colors.BLUE_EIGHT;
const secondaryDark = Colors.SKYBLUE_SIX;
const secondaryText = Colors.WHITE_ONE;

const textPrimary = Colors.BLACK_THIRTEEN;
const textSecondary = Colors.BLACK_TWELVE;

export const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: primaryMain,
      light: primaryLight,
      dark: primaryDark,
      contrastText: primaryText,
    },

    secondary: {
      main: secondaryMain,
      light: secondaryLight,
      dark: secondaryDark,
      contrastText: secondaryText,
    },

    text: {
      primary: textPrimary,
      secondary: textSecondary,
    },

    // TODO: colors to be updated in future
    info: {
      main: Colors.GREEN_EIGHT,
      light: Colors.GREEN_FOUR,
      dark: Colors.BLACK_ALPHA,
      contrastText: Colors.BLACK_EIGHT,
    },

    error: {
      main: Colors.RED_FIVE,
      light: Colors.RED_ONE,
      dark: Colors.RED_FOUR_APP,
      contrastText: Colors.RED_TWO,
    },
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          '& a': {
            '&:hover': {
              textDecoration: 'none',
            },
          },

          '& .cursorPointer': {
            cursor: 'pointer',
          },

          '& .PhoneInput': {
            '& .PhoneInputInput': {
              padding: '8px 40px 8px 16px !important',
              lineHeight: '22px',
              fontWeight: 'normal',
              letterSpacing: '-0.006em',
              fontFamily: Fonts.POPPINS_FONT,
              color: textSecondary,
              border: `1px solid ${Colors.WHITE_SEVEN}`,
              background: Colors.WHITE_ONE,
              boxSizing: 'border-box',
              borderRadius: 6,
              height: 40,
              fontSize: 14,

              '&::placeholder': {
                color: Colors.GRAY_SIX,
                textTransform: 'capitalize',
              },
            },
          },

          '& .select__control': {
            borderRadius: 6,
            minHeight: 40,
            fontSize: 10,
            boxShadow: 'none !important',
            borderColor: `${Colors.WHITE_SEVEN} !important`,

            '& .select__indicator-separator': {
              display: 'none',
            },

            '& .select__multi-value': {
              background: Colors.BLUE_THIRTEEN,
              borderRadius: 5,

              '& .select__multi-value__label': {
                fontSize: 12,
                fontWeight: 600,
                color: Colors.WHITE_ONE,
                fontFamily: Fonts.POPPINS_FONT,
              },

              '& .select__multi-value__remove': {
                color: Colors.WHITE_ONE,
                opacity: 0.4,
                cursor: 'pointer',

                '&:hover': {
                  background: 'none',
                },
              },
            },
          },

          '& .StripeElement': {
            padding: '10px 15px',
            boxShadow: 'none',
            transition: 'box-shadow 150ms ease',
            border: `1px solid ${Colors.WHITE_SEVEN}`,
            backgroundColor: Colors.WHITE_ONE,
            color: Colors.BLACK_SIX,
            height: 40,
            borderRadius: 6,

            '&.cardNumberElement': {
              paddingLeft: 34,
            },

            '&.cardError': {
              border: `1px solid ${Colors.RED_ONE} !important`,
            },
          },

          '& .inputError': {
            '& input': {
              borderColor: `${Colors.RED_ONE} !important`,

              '&::placeholder': {
                color: `${Colors.RED_SIX}`,
              },
            },

            '& .PhoneInputInput': {
              '&::placeholder': {
                color: `${Colors.RED_SIX} !important`,
              },
            },
          },

          '& .autoCompleteTimezone': {
            "& [class*='MuiOutlinedInput-root']": {
              padding: '0px 65px 0px 15px !important',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center right 20px',
              backgroundImage: `url(${SELECT_ARROW_ICON})`,
              backgroundSize: 10,
            },

            '& button': {
              display: 'none',
            },
          },

          "& [class*='MuiAutocomplete-popper']": {
            background: Colors.WHITE_ONE,
          },

          '& .billingInputError': {
            '& input': {
              borderColor: `${Colors.RED_ONE} !important`,
            },
          },

          '& .MuiDatePicker': {
            "& [class*='MuiPickersStaticWrapper-staticWrapperRoot']": {
              background: 'transparent',
            },

            "& [class*='MuiPickersCalendarHeader-iconButton']": {
              background: 'transparent',
              padding: 0,
            },

            "& [class*='MuiPickersCalendarHeader-switchHeader']": {
              maxWidth: '55%',
              margin: '0 auto',
              padding: '16px 0 25px 0',

              '& > div': {
                '& > p': {
                  fontFamily: Fonts.POPPINS_FONT,
                  color: Colors.BLACK_THIRTEEN,
                  fontSize: 14,
                  textTransform: 'uppercase',
                  fontWeight: 600,
                },
              },

              '& button': {
                '& span': {
                  '& svg': {
                    color: Colors.BLACK_THIRTEEN,
                    height: 27,
                    width: 27,
                  },
                },
              },
            },

            "& [class*='MuiPickersBasePicker-container']": {
              "& [class*='MuiPickersBasePicker-pickerView']": {
                maxWidth: '100%',
                minHeight: 'auto',
              },
            },

            "& [class*='MuiPickersCalendarHeader-daysHeader']": {
              borderBottom: `1px solid ${Colors.WHITE_SEVEN}`,
              paddingBottom: 15,
              justifyContent: 'space-between',
              marginBottom: 16,
            },

            "& [class*='MuiPickersCalendar-week']": {
              justifyContent: 'space-between',
              paddingBottom: 25,

              '& > div': {
                '& button': {
                  width: 29,
                  height: 29,

                  '&:disabled': {
                    textDecoration: 'line-through',
                  },

                  '& span p': {
                    fontFamily: Fonts.POPPINS_FONT,
                    color: Colors.BLACK_THIRTEEN,
                    fontSize: 14,
                    fontWeight: 600,
                  },
                },

                "& [class*='MuiPickersDay-daySelected']": {
                  background: Colors.BLUE_SEVEN,

                  '& span p': {
                    color: Colors.WHITE_ONE,
                  },
                },

                "& [class*='MuiPickersDay-current']": {
                  background: '#dcdcdc !important',
                  borderRadius: 0,

                  '& span p': {
                    color: '#525454',
                  },

                  "&[class*='MuiPickersDay-dayDisabled']": {
                    '& span p': {
                      textDecoration: 'none',
                    },
                  },
                },

                "& [class*='MuiPickersDay-dayDisabled']": {
                  '& span p': {
                    textDecoration: 'line-through',
                    color: Colors.GRAY_TWENTYTWO,
                  },
                },
              },

              "& [class*='MuiPickersCalendarHeader-dayLabel']": {
                fontFamily: Fonts.POPPINS_FONT,
                fontWeight: 600,
                fontSize: 12,
                color: Colors.BLACK_FOURTEEN,
                letterSpacing: '0.006em',
                textTransform: 'uppercase',
              },
            },

            "& [class*='MuiPickersCalendar-transitionContainer']": {
              marginTop: 0,
              minHeight: 325,
            },
          },

          '& .radio-container': {
            '& input[type=radio]': {
              display: 'none',

              '& + label': {
                width: '100%',
                border: `1px solid ${Colors.BLUE_SEVEN}`,
                lineHeight: '21px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                transition: 'all 0.3s ease-out',
                padding: '5px 12px',
                fontFamily: Fonts.POPPINS_FONT,
                color: Colors.BLUE_SEVEN,
                height: 40,
                borderRadius: 10,
                fontSize: 14,
                margin: 0,

                '&:hover': {
                  background: Colors.WHITE_EIGHT,
                },

                '& span': {
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  display: 'block',
                  whiteSpace: 'nowrap',
                },
              },

              '&:checked': {
                '& + label': {
                  background: `${Colors.BLUE_SEVEN} !important`,
                  color: Colors.WHITE_ONE,
                },
              },
            },
          },
        },
      },
    },

    MuiSelect: {
      root: {
        fontSize: 14,
        lineHeight: '24px',
        fontWeight: 400,
        fontFamily: Fonts.INTER,
        letterSpacing: '-0.006em',
        color: Colors.GRAY_TWENTYTWO,
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'transparent',
        background: Colors.WHITE_ONE,
        padding: '8px 40px 8px 16px !important',
        backgroundPosition: 'center right 15px',
        border: `1px solid ${Colors.WHITE_SEVEN}`,
        backgroundImage: `url( ${SELECT_ARROW_DOWN_ICON})`,
      },
      select: {
        '&:focus': {
          borderRadius: 6,
          backgroundColor: 'transparent',
        },
        '&:hover': {
          backgroundColor: Colors.GRAY_BOREDER,
        },
        '&:active': {
          backgroundColor: Colors.GRAY_EIGHT,
        },
        '&.Mui-disabled': {
          backgroundColor: Colors.GRAY_BOREDER,
        },
      },
      outlined: {
        borderRadius: 6,
      },
      icon: {
        display: 'none',
      },
    },

    MuiOutlinedInput: {
      root: {
        borderRadius: 6,
        fontFamily: Fonts.INTER,
        color: textSecondary,
        fontSize: 14,
        overflow: 'hidden',

        '&.Mui-error': {
          border: `1px solid ${Colors.RED_ONE} !important`,
          '& input': {
            borderColor: `${Colors.RED_ONE} !important`,

            '&::placeholder': {
              color: Colors.RED_ONE,
            },
          },
        },

        '& fieldset': {
          display: 'none',
        },
      },

      input: {
        background: Colors.WHITE_ONE,
        color: Colors.BLACK_SIX,
        fontFamily: Fonts.INTER,
        border: `1px solid ${Colors.WHITE_SEVEN}`,
        padding: '8px 16px',
        boxSizing: 'border-box',
        height: 40,
        borderRadius: 6,
      },

      inputMarginDense: {
        height: 32,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 5,
        paddingBottom: 5,
      },

      notchedOutline: {
        borderColor: `${Colors.WHITE_SEVEN} !important`,
      },
    },

    MuiFormHelperText: {
      root: {
        color: 'red !important',

        '&.Mui-error': {
          display: 'none',
        },
      },
    },

    MuiInputBase: {
      root: {
        borderRadius: 6,
      },
    },

    MuiListItem: {
      root: {
        fontFamily: Fonts.INTER,
        fontSize: '14px !important',
      },
    },

    MuiInputLabel: {
      outlined: {
        fontWeight: 'normal',
        fontSize: 20,
        color: textSecondary,
        fontFamily: Fonts.POPPINS_FONT,
        transform: 'translate(14px, 11px) scale(1) !important',
      },

      shrink: {
        transform: 'translate(0px, -22px) scale(0.75)',
      },
    },

    MuiButton: {
      root: {
        height: 40,
        minWidth: 120,
        fontWeight: 700,
        borderRadius: 12,
        textTransform: 'inherit',
        letterSpacing: '-0.006em',
        boxShadow: 'none !important',
        padding: '7px 16px !important',
        fontFamily: Fonts.POPPINS_FONT,

        '& svg, & img': {
          marginRight: 12,
        },

        '&:disabled': {
          opacity: 0.5,
          cursor: 'not-allowed',
        },

        '&.dangerButton': {
          backgroundColor: Colors.RED_SEVEN,

          '&:hover': {
            backgroundColor: `${Colors.RED_DARK_HOVER} !important`,
          },

          '&:disabled': {
            backgroundColor: `${Colors.RED_SEVEN} !important`,
          },
        },

        '&.warningButton': {
          color: Colors.WHITE_ONE,
          background: Colors.YELLOW_THREE,

          '&:hover': {
            backgroundColor: Colors.YELLOW_FOUR,
          },
        },
      },

      label: {
        fontSize: 14,

        '& img': {
          marginRight: 12,
        },
      },

      contained: {
        fontWeight: 700,
      },

      containedPrimary: {
        backgroundColor: Colors.BLUE_SEVEN,

        '&:hover': {
          backgroundColor: Colors.BLUE_SEVEN_DARK,
        },

        '&:disabled': {
          color: Colors.WHITE_ONE,
          backgroundColor: `${Colors.BLUE_SEVEN} !important`,
        },
      },

      containedSecondary: {
        backgroundColor: Colors.STANDARD,

        '&:disabled': {
          opacity: 0.5,
          color: Colors.WHITE_ONE,
          background: Colors.STANDARD,
        },

        '&:hover': {
          backgroundColor: Colors.GREEN_NINE,
        },
      },

      outlined: {
        fontWeight: 600,
        color: Colors.BLACK_TWELVE,
        backgroundColor: Colors.WHITE_ONE,
        border: `1px solid ${Colors.WHITE_SEVEN}`,

        '&:hover': {
          backgroundColor: Colors.GRAY_BOREDER,
          border: `1px solid ${Colors.WHITE_SEVEN}`,
        },

        '&:active': {
          backgroundColor: Colors.GRAY_EIGHT,
          border: `1px solid ${Colors.GRAY_EIGHT}`,
        },

        '&:focus': {
          backgroundColor: Colors.WHITE_ONE,
          border: `1px solid ${Colors.WHITE_SEVEN}`,
        },

        '&:disabled': {
          color: Colors.BLACK_TWELVE,
          backgroundColor: `${Colors.WHITE_ONE} !important`,
        },

        '& .spinner-border': {
          border: '0.25em solid #a9a9a9',
          borderRightColor: 'transparent',
        },
      },

      outlinedPrimary: {
        color: Colors.BLUE_SEVEN,
        border: `2px solid ${Colors.BLUE_SEVEN}`,
        backgroundColor: Colors.OUTLINED_TRANSPARENT_BUTTON,

        '&:hover': {
          color: Colors.WHITE_ONE,
          backgroundColor: Colors.BLUE_DARK,
          border: `2px solid ${Colors.BLUE_DARK}`,
        },

        '&:disabled': {
          color: Colors.BLACK_TWELVE,
          backgroundColor: `${Colors.OUTLINED_TRANSPARENT_HOVER_BUTTON} !important`,
        },

        '& svg': {
          marginRight: 12,
        },
      },

      outlinedSecondary: {
        background: 'transparent',
        color: Colors.BLACK_TWELVE,
        border: `1px solid ${Colors.WHITE_SEVEN}`,

        '&:hover': {
          border: `1px solid ${Colors.WHITE_SEVEN}`,
          background: `${Colors.GRAY_BOREDER} !important`,
        },

        '&:focus': {
          background: Colors.GRAY_EIGHT,
          border: `1px solid ${Colors.GRAY_EIGHT}`,
        },
      },

      text: {
        fontWeight: 700,
        textTransform: 'capitalize',
        fontFamily: Fonts.POPPINS_FONT,
      },

      textPrimary: {
        fontWeight: 600,
        color: Colors.BLUE_SEVEN,

        '&:hover': {
          backgroundColor: Colors.GRAY_BOREDER,
        },

        '&:active': {
          backgroundColor: Colors.GRAY_EIGHT,
        },
      },

      textSecondary: {},

      containedSizeSmall: {
        padding: '4px 19px',
        height: 32,
        borderRadius: 10,
        fontSize: 14,
      },
    },

    MuiTypography: {
      h1: {
        fontSize: 58,
        color: textSecondary,
        fontFamily: Fonts.POPPINS_FONT,
        lineHeight: '58px',
        fontWeight: 'bold',

        [theme.breakpoints.down('sm')]: {
          fontSize: 46,
          lineHeight: 'normal',
        },
      },

      h2: {
        fontSize: 46,
        color: textSecondary,
        fontFamily: Fonts.POPPINS_FONT,
        fontWeight: 'bold',
        lineHeight: '46px',

        [theme.breakpoints.down('sm')]: {
          fontSize: 36,
          lineHeight: 'normal',
        },
      },

      h3: {
        fontSize: 36,
        color: textSecondary,
        fontFamily: Fonts.POPPINS_FONT,
        fontWeight: 'bold',
        letterSpacing: '-0.022em',
        lineHeight: '42px',

        [theme.breakpoints.down('sm')]: {
          fontSize: 26,
          letterSpacing: 0,
          lineHeight: '34px',
        },
      },

      h4: {
        fontSize: 24,
        fontWeight: 600,
        color: textSecondary,
        fontFamily: Fonts.POPPINS_FONT,
        letterSpacing: '-0.019em',
        lineHeight: '32px',

        '& > span': {
          fontFamily: Fonts.INTER,
          fontSize: 18,
          fontWeight: 'normal',
        },

        '& img': {
          marginLeft: 20,
        },

        [theme.breakpoints.down('sm')]: {
          fontSize: 18,
          lineHeight: '24px',
          letterSpacing: 0,

          '& > span': {
            fontSize: 14,
          },
        },
      },

      h5: {
        fontSize: 18,
        fontWeight: 600,
        color: textSecondary,
        fontFamily: Fonts.INTER,
        lineHeight: '24px',
        letterSpacing: '-0.014em',

        [theme.breakpoints.down('sm')]: {
          fontSize: 16,
          lineHeight: 'normal',
          letterSpacing: 0,
        },

        '& img': {
          marginLeft: 20,
        },
      },

      h6: {
        fontSize: 14,
        fontWeight: 600,
        color: textSecondary,
        fontFamily: Fonts.POPPINS_FONT,
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        lineHeight: '24px',

        '& a': {
          color: Colors.BLUE_SEVEN,
          marginLeft: 11,
          fontWeight: 'normal',
        },
      },

      overline: {
        fontSize: 12,
        fontWeight: 600,
        color: textSecondary,
        fontFamily: Fonts.POPPINS_FONT,
        letterSpacing: '0.018em',
        textTransform: 'uppercase',
        lineHeight: '16px',
      },

      body1: {
        fontSize: 16,
        color: textSecondary,
        fontFamily: Fonts.INTER,
        fontWeight: 'normal',
        letterSpacing: '-0.011em',
        lineHeight: '24px',

        '& span': {
          paddingRight: 5,
          fontWeight: 600,
        },

        [theme.breakpoints.down('sm')]: {
          fontSize: 14,
          letterSpacing: 0,
          lineHeight: 'normal',
        },
      },

      body2: {
        fontSize: 14,
        color: Colors.BLACK_THIRTEEN,
        fontFamily: Fonts.INTER,
        fontWeight: 'normal',
        letterSpacing: '-0.006em',
        lineHeight: '20px',

        [theme.breakpoints.down('sm')]: {
          letterSpacing: 0,
          lineHeight: 'normal',
        },
      },

      caption: {
        fontSize: 12,
        color: textSecondary,
        fontFamily: Fonts.INTER,
        fontWeight: 'normal',
        letterSpacing: '-0.006em',
        lineHeight: '16px',

        '& span': {
          fontWeight: 600,
        },
      },
    },

    MuiContainer: {
      root: {
        [theme.breakpoints.up('lg')]: {
          maxWidth: 1328,
        },
      },
    },

    MuiAppBar: {
      root: {
        background: 'none',
        boxShadow: 'none',
      },

      colorDefault: {
        backgroundColor: 'transparent',
      },
    },

    MuiTabs: {
      root: {
        minHeight: 10,
        overflow: 'initial',
      },

      fixed: {
        overflow: 'initial !important',
      },

      flexContainer: {
        '& .tabComponentContainer': {
          color: Colors.BLACK_FOURTEEN,
          fontFamily: Fonts.POPPINS_FONT,
          textAlign: 'center',
          position: 'relative',
          fontWeight: 600,

          [theme.breakpoints.up('sm')]: {
            minWidth: 137,
            fontSize: 16,
          },

          [theme.breakpoints.down('xs')]: {
            fontSize: 12,
          },

          '& .tabInfoText': {
            position: 'absolute',
            width: '100%',
            textAlign: 'center',
            top: -19,
            fontSize: 12,
            color: primaryMain,
          },

          '&:not(:last-of-type)': {
            marginRight: 11,

            [theme.breakpoints.down('xs')]: {
              marginRight: 5,
            },
          },

          "& [class*='MuiTabs-indicator']": {
            display: 'none',
          },
        },

        '& .tabComponent': {
          borderRadius: 50,
          border: `1px solid ${Colors.BLACK_FOURTEEN}`,
          letterSpacing: '-0.011em',
          textTransform: 'none',
          padding: '5px 15px 4px',
          cursor: 'pointer',
          transition: 'all .3s ease',

          '&:hover': {
            background: Colors.WHITE_SEVEN,
            color: Colors.BLACK_ONE,
          },

          '&.selected': {
            background: Colors.BLACK_FOURTEEN,
            color: Colors.WHITE_ONE,
          },
        },
      },

      indicator: {
        background: Colors.BLACK_THIRTEEN,
      },
    },

    MuiTab: {
      root: {
        padding: 0,
        maxWidth: 'none',
        fontSize: 16,
        color: Colors.BLACK_THIRTEEN,
        letterSpacing: '-0.006em',
        textTransform: 'capitalize',
        fontFamily: Fonts.POPPINS_FONT,
        marginRight: 30,

        [theme.breakpoints.up('sm')]: {
          minWidth: 'auto',
        },
      },
    },

    MuiCard: {
      root: {
        boxShadow: `0px 4px 14px rgba(68, 68, 68, 0.14)`,
        backgroundColor: Colors.WHITE_ONE,
        border: `1px solid ${Colors.WHITE_SEVEN}`,
        borderRadius: 15,

        [theme.breakpoints.down('sm')]: {
          borderRadius: 15,
        },
      },
    },

    MuiAccordion: {
      root: {
        '&:not(:last-of-type)': {
          borderBottom: `1px solid ${Colors.WHITE_SEVEN}`,
        },

        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },

    MuiAccordionSummary: {
      root: {
        padding: theme.spacing(1.5, 0),
        width: 'max-content',

        '&.Mui-expanded': {
          minHeight: 'auto',
        },
      },

      content: {
        flexGrow: 'initial',
        margin: 0,

        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },

    MuiAccordionDetails: {
      root: {
        padding: 0,
      },
    },

    MuiPaper: {
      root: {
        backgroundColor: Colors.WHITE_ONE,
      },

      elevation1: {
        boxShadow: 'none',
      },
    },

    MuiTableBody: {
      root: {
        "& [class*='MuiTableRow-root']": {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },

    MuiTableCell: {
      root: {
        borderBottom: 'none',
        padding: 22,
      },
    },

    MuiFormControlLabel: {
      root: {
        marginRight: 0,
        marginBottom: 0,
      },

      label: {
        fontFamily: Fonts.INTER,
        fontSize: 12,
        color: Colors.BLACK_TEN,
        lineHeight: '20px',
        letterSpacing: '-0.006em',
      },
    },

    MuiIconButton: {
      label: {
        "& [class*='MuiSvgIcon-root']": {
          width: theme.spacing(4),
          height: theme.spacing(4),
          color: Colors.GRAY_TWENTYTWO,
        },

        "& [class*='MuiSvgIcon-fontSizeSmall']": {
          width: theme.spacing(2.8),
          height: theme.spacing(2.8),
          color: Colors.GRAY_TWENTYTWO,
        },
      },
    },

    MuiCircularProgress: {
      root: {
        '&.buttonProgress': {
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: -12,
          marginLeft: -12,
        },
      },
    },

    MuiDrawer: {
      paper: {
        border: '1px solid #D4D4D4',
        boxShadow: '0px 4px 14px rgb(68 68 68 / 14%)',
        borderRadius: '50px 50px 0px 0px',

        [theme.breakpoints.down('xs')]: {
          borderRadius: '25px 25px 0px 0px',
        },
      },
    },

    MuiDialog: {
      paper: {
        border: `1px solid ${Colors.WHITE_SEVEN}`,
        borderRadius: 25,
        background: Colors.WHITE_ONE,
        boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',
      },

      paperWidthMd: {
        maxWidth: 818,
        width: 818,

        [theme.breakpoints.down('sm')]: {
          width: '100%',
          maxWidth: '100%',
          margin: '0px 10px',
        },
      },

      paperWidthSm: {
        maxWidth: 551,
        width: 551,

        [theme.breakpoints.down('sm')]: {
          width: '97%',
          maxWidth: '97%',
          margin: '0px 10px',
          borderRadius: 15,
        },
      },
    },

    MuiDialogContent: {
      root: {
        padding: 0,
      },
    },

    MuiDialogActions: {
      root: {
        borderTop: `1px solid ${Colors.WHITE_SEVEN}`,
        padding: '26px 50px',

        [theme.breakpoints.down('sm')]: {
          padding: 15,
        },
      },
    },

    MuiStepper: {
      root: {
        padding: 0,
      },
    },

    MuiStepLabel: {
      label: {
        "&[class*='MuiStepLabel-alternativeLabel']": {
          marginTop: 10,
          fontSize: 12,
          color: Colors.GRAY_TWENTYTWO,
          lineHeight: 'normal',
        },
      },

      active: {
        "&[class*='MuiStepLabel-alternativeLabel']": {
          color: Colors.GREEN_EIGHT,
          fontWeight: 600,
        },
      },
    },

    MuiStepIcon: {
      root: {
        height: 24,
        width: 24,
        border: `2px solid ${Colors.GRAY_TWENTYTWO}`,
        borderRadius: '50%',

        '& circle': {
          fill: 'none',
        },
      },

      text: {
        fontFamily: Fonts.INTER,
        fontWeight: 600,
        fontSize: 14,
        fill: Colors.GRAY_TWENTYTWO,
      },

      active: {
        border: `2px solid ${Colors.GREEN_EIGHT}`,

        "& [class*='MuiStepIcon-text']": {
          fill: Colors.GREEN_EIGHT,
        },
      },

      completed: {
        border: 'none',
      },
    },
  },
});
