import { FC } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import Colors from '../common/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      margin: theme.spacing(1),
    },
  })
);

const LoaderMain = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${Colors.WHITE_ONE};
  opacity: 0.9;
  backdrop-filter: blur(5px);
  position: fixed;
  z-index: 9999;
  left: 0px;
  top: 0px;

  .MuiCircularProgress-colorPrimary {
    color: ${Colors.BLUE_SEVEN};
    width: 86px !important;
    height: 86px !important;
  }

  label {
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.3375px;
    text-transform: uppercase;
    color: ${Colors.BLUE_SEVEN};
    display: block;
    margin-top: 12px;
  }

  .MuiCircularProgress-circleIndeterminate {
    stroke-width: 3px;
`;

const Loader: FC = () => {
  const classes = useStyles();

  return (
    <LoaderMain>
      <CircularProgress className={classes.progress} />
      <label>Loading, PLEASE WAIT...</label>
    </LoaderMain>
  );
};

export default Loader;
