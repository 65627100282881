import React, { useState } from 'react';
import { AppBar, Grid, Tabs, Tab } from '@material-ui/core';
import MeetingsTable from './MeetingsTable';
import { bookingInfoStatuses } from '../../../constants';
import TabPanel from '../../../components/TabPanel';
import { meetingTabsStyles } from './styles';

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const MeetingTabs = ({ searchValue }: { searchValue: string }) => {
  const classes = meetingTabsStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid className={classes.meetingTabs}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Upcoming" {...a11yProps(0)} />
          <Tab label="Completed" {...a11yProps(1)} />
          <Tab label="Canceled" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <MeetingsTable status={bookingInfoStatuses.UPCOMING} searchValue={searchValue} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MeetingsTable status={bookingInfoStatuses.COMPLETED} searchValue={searchValue} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <MeetingsTable status={bookingInfoStatuses.CANCELLED} searchValue={searchValue} />
      </TabPanel>
    </Grid>
  );
};
export default MeetingTabs;
