import { Dialog, Box, IconButton, Typography, Table } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { FC, Key, useState, useEffect, Fragment } from 'react';
import client from '../../apollo';
import { MilestoneActivityDialogProps } from '../../common/interfaces';
import { useStylesMilestoneActivityDialog } from './styles';
import { GET_MILESTONE_ACTIVITY } from '../../graphql/queries/getMilestoneActivity';
import ModalCloseIcon from '../../img/dialog-close-updated.svg';
import { GetMilestoneActivity_getMilestoneActivity } from '../../graphql/queries/getMilestoneActivity/__generated__/GetMilestoneActivity';

const MilestoneActivityDialog: FC<MilestoneActivityDialogProps> = ({
  isOpen,
  onClose,
  userId,
  name,
  plan,
  isMBAGrad,
  isGraduateStudent,
}) => {
  const classes = useStylesMilestoneActivityDialog();
  const [loading, setLoading] = useState<boolean>(true);
  const [milestoneData, setMilestoneData] = useState<
    GetMilestoneActivity_getMilestoneActivity[]
  >([]);
  const [totalVCHours, setTotalVCHours] = useState<number>(0);
  const [totalOfflineHours, setTotalOfflineHours] = useState<number>(0);

  const getRoundedValueHours = (num: number) => {
    return Math.round((num / 60) * 10) / 10;
  };

  const fetchData = async () => {
    const {
      data: { getMilestoneActivity },
      loading,
    } = await client.query({
      query: GET_MILESTONE_ACTIVITY,
      variables: { studentId: userId },
      fetchPolicy: 'no-cache',
    });
    if (getMilestoneActivity) {
      setMilestoneData(getMilestoneActivity);
      let totalVC = 0;
      let totalOffline = 0;

      milestoneData.forEach(
        (item: { vc: number | null; offline: number | null }) => {
          totalVC += item.vc ?? 0;
          totalOffline += item.offline ?? 0;
        }
      );

      totalVC = getRoundedValueHours(totalVC);
      totalOffline = getRoundedValueHours(totalOffline);

      setTotalVCHours(totalVC);
      setTotalOfflineHours(totalOffline);
    }
    loading ? setLoading(true) : setLoading(false);
  };

  useEffect(() => {
    if (loading) fetchData();
  }, [milestoneData]);

  return (
    <Dialog open={isOpen} onClose={() => onClose()} maxWidth="md" fullWidth>
      <IconButton onClick={() => onClose()} className={classes.closeButton}>
        <img src={ModalCloseIcon} alt="close" />
      </IconButton>
      <Box className={classes.dialogBox}>
        {!loading ? (
          <>
            <Box className={classes.headingContainer}>
              <Typography className={classes.heading}>
                Milestone Team Activity
              </Typography>
              <Typography className={classes.subHeading}>
                {name} - {plan}
              </Typography>
            </Box>

            <TableContainer>
              <Table className={classes.tableHolder}>
                <TableRow>
                  <TableCell className={classes.emptyCell}></TableCell>
                  <TableCell className={classes.titleCell}>VC</TableCell>
                  <TableCell className={classes.titleCell}>Offline</TableCell>
                  <TableCell className={classes.titleCell}>
                    Last Activity
                  </TableCell>
                </TableRow>
                {milestoneData?.map(
                  (item: any, index: Key | null | undefined) => {
                    const vcHours = getRoundedValueHours(item.vc);
                    const offlineHours = getRoundedValueHours(item.offline);
                    return (
                      <TableRow key={index}>
                        {isMBAGrad &&
                        (item.milestoneName.includes(
                          'Interview Coaching Team, Undergrad'
                        ) ||
                          item.milestoneName.includes(
                            'Essay Review Team, Undergrad'
                          )) ? (
                          ''
                        ) : !isMBAGrad &&
                          (item.milestoneName.includes(
                            'Interview Coaching Team, Graduate'
                          ) ||
                            item.milestoneName.includes(
                              'Essay Review Team, Graduate'
                            ) ||
                            item.milestoneName.includes(
                              'Graduate Specialty Advisor Team'
                            )) ? (
                          ''
                        ) : (
                          <Fragment>
                            <TableCell className={classes.titleCell}>
                              {item.milestoneName}
                            </TableCell>

                            <TableCell className={classes.commonCell}>
                              {vcHours === 0 ? '--' : vcHours}
                            </TableCell>

                            <TableCell className={classes.commonCell}>
                              {offlineHours === 0 ? '--' : offlineHours}
                            </TableCell>

                            <TableCell className={classes.commonCell}>
                              {item.mentorId !== -1
                                ? `${item.lastActivity} by ${item.mentorName}`
                                : '--'}
                            </TableCell>
                          </Fragment>
                        )}
                      </TableRow>
                    );
                  }
                )}
                <TableRow>
                  <TableCell className={classes.emptyCellRight}>
                    Total Hours Used
                  </TableCell>
                  <TableCell className={classes.emptyCell}>
                    {totalVCHours}
                  </TableCell>
                  <TableCell className={classes.emptyCell}>
                    {totalOfflineHours}
                  </TableCell>
                  <TableCell className={classes.emptyCell}></TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </>
        ) : (
          ''
        )}
      </Box>
    </Dialog>
  );
};

export default MilestoneActivityDialog;
