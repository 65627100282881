import {
  Card,
  makeStyles,
  Box,
  Typography,
  Theme,
  createStyles,
  Grid,
} from '@material-ui/core';

import fonts from '../../../common/fonts';
import { roundNumber } from '../../../constants';
import { IOfflineUsage } from './VideoChatHours';
import { StudentDetailQuery_Student_premiumOfflineCountUsed } from './__generated__/StudentDetailQuery';
import colors from '../../../common/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hoursUsedCard: {
      margin: '35px 0px 7px',
      padding: '17px 29px',
      borderRadius: 15,
      backgroundColor: '#E94444',
      border: 0,
      boxShadow: '0px 2px 7px rgba(68, 68, 68, 0.14)',

      [theme.breakpoints.down('xs')]: {
        padding: 16,
      },

      '& .MuiTypography-root': {
        fontFamily: fonts.INTER,
      },

      '& .MuiTypography-h6': {
        fontWeight: 600,
        fontSize: 18,
        color: '#FFFFFF',
        letterSpacing: '-0.014em',
        marginBottom: 4,
        textTransform: 'initial',
      },

      '& .MuiTypography-body1': {
        fontWeight: 400,
        fontSize: 16,
        color: '#FFFFFF',
        letterSpacing: '-0.011em',
      },
      '& .MuiTypography-body2': {
        fontWeight: 400,
        fontSize: 16,
        color: colors.WHITE_ONE,
        letterSpacing: '-0.011em',
        textDecoration: 'underline', 
        cursor: 'pointer'
      },

      '& .percentageCircle': {
        width: 56,
        height: 56,
        borderRadius: '50%',
        marginRight: 20,
        background: '#ffffff',

        '& .MuiTypography-h4': {
          fontWeight: 600,
          fontSize: 18,
          color: '#E94444',
          letterSpacing: '-0.014em',
        },
      },

      '& .forMobileFlex': {
        [`@media only screen and (max-width: 375px)`]: {
          flexDirection: 'column',
        },
      },
    },

    hoursDetailText: {
      paddingTop: 19,
      marginTop: 23,
      borderTop: '1px solid #D4D4D4',

      '& .MuiBox-root': {
        marginBottom: 15,

        '&:last-child': {
          marginBottom: 0,
        },
      },

      '& .MuiTypography-body1': {
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#FFFFFF',
        fontFamily: fonts.INTER,
      },

      '& .MuiTypography-caption': {
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#FFFFFF',
        width: 75,
        paddingRight: 15,
        fontFamily: fonts.INTER,

        '& span': {
          fontWeight: 400,
          paddingLeft: 3,
        },
      },
    },
  })
);

export default function VideoChatHoursEnd({
  totalHours,
  hoursUsed,
  text,
  premiumOfflineCountUsed,
  hideDetail = false,
  toggleMilestoneDialog,
}: {
  totalHours: number;
  hoursUsed: number;
  text: string;
  premiumOfflineCountUsed?: StudentDetailQuery_Student_premiumOfflineCountUsed | null;
  hideDetail?: boolean;
  toggleMilestoneDialog: () => void;
}) {
  const classes = useStyles();

  let offlineUsage: (IOfflineUsage | null)[] = [];
  if (premiumOfflineCountUsed) {
    const {
      essayCount,
      offlineResearch,
      MeetingWithAdvisor,
      milestoneTeamTotal,
      Other,
      dressRehearsalCount,
      correspondenceTotal,
    } = premiumOfflineCountUsed;

    offlineUsage = [
      { key: 'Essay Editing/Review', value: essayCount, label: 'Essay Review' },
      {
        key: 'Offline Research',
        value: offlineResearch,
        label: 'Offline Research',
      },
      {
        key: 'Milestone Teams, Offline',
        value: milestoneTeamTotal,
        label: 'Milestone Teams, Offline',
      },
      {
        key: 'Dress Rehearsal',
        value: dressRehearsalCount,
        label: 'Dress Rehearsal',
      },
      {
        key: 'Meeting with another Advisor/AO',
        value: MeetingWithAdvisor,
        label: 'Meeting with another Advisor/AO',
      },
      {
        key: 'Correspondence with Family',
        value: correspondenceTotal,
        label: 'Correspondence related to account',
      },
      { key: 'Other', value: Other, label: 'Other' },
    ];
  }

  return (
    <Card className={classes.hoursUsedCard}>
      <Box display="flex" alignItems="center" className="forMobileFlex">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className="percentageCircle"
        >
          <Typography variant="h4">100%</Typography>
        </Box>

        <Box>
          <Typography variant="h6">
            {roundNumber(hoursUsed, 1)} of {totalHours} {text}
          </Typography>
          <Typography variant="body1">No hours remain.</Typography>
        </Box>
      </Box>
      {!hideDetail && premiumOfflineCountUsed && !!offlineUsage.length && (
        <>
          <Grid className={classes.hoursDetailText}>
            {offlineUsage?.map((item) => (
              <Box key={item?.key} display="flex" alignItems="center">
                <Typography variant="caption">
                  {item?.value} <span>hrs</span>{' '}
                </Typography>
                {item?.key === 'Milestone Teams, Offline' ? (
                  <Typography
                    variant="body2"
                    onClick={() => {
                      toggleMilestoneDialog();
                    }}
                  >
                    {item?.label}
                  </Typography>
                ) : (
                  <Typography variant="body1">{item?.label}</Typography>
                )}
              </Box>
            ))}
          </Grid>
        </>
      )}
    </Card>
  );
}
