import { FC, useEffect, useContext } from 'react';
import { Container } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import 'sendbird-uikit/dist/index.css';
import CustomizedChat from './CustomizedChat';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import Header from '../../../components/layout/Header';

import {
  UpdateStatusMutation,
  UpdateStatusMutationVariables,
} from './__generated__/UpdateStatusMutation';
import { AppContext } from '../../../contexts';

export const UPDATE_STATUS_MUATATION = gql`
  mutation UpdateStatusMutation($activeStatus: Boolean) {
    updateUserOnlineStatus(input: { activeStatus: $activeStatus }) {
      status
    }
  }
`;

function StatusUpdate({ updateUserOnlineStatus, user }: any) {
  useEffect(() => {
    if (user && !user.isOnline) {
      updateUserOnlineStatus({
        variables: {
          activeStatus: true,
        },
      });
    }
  }, []);

  return null;
}

const Chat: FC<RouteComponentProps> = ({ history }) => {
  const { user } = useContext(AppContext);

  return (
    <div>
      <Header navigateTo={history.push} />

      <Mutation<UpdateStatusMutation, UpdateStatusMutationVariables>
        mutation={UPDATE_STATUS_MUATATION}
      >
        {(updateUserOnlineStatus: Function) => {
          return (
            <StatusUpdate
              updateUserOnlineStatus={updateUserOnlineStatus}
              user={user}
            />
          );
        }}
      </Mutation>

      <Container>
        <CustomizedChat />
      </Container>
    </div>
  );
};

export default Chat;
