import gql from 'graphql-tag';

export const UPDATE_ADVISOR_CAPACITY_DATA = gql`
  mutation UpdateAdvisorCapacityData(
    $hours: Float
    $isOpenToTakeStudents: Boolean
    $matchStr: String
  ) {
    updateAdvisorCapacityData(
      hours: $hours
      isOpenToTakeStudents: $isOpenToTakeStudents
      matchStr: $matchStr
    ) {
      message
      success
      data {
        id
        hours
        isOpenToTakeStudents
        hoursLastUpdate
        matchText
      }
    }
  }
`;
