import { FC } from 'react';
import clsx from 'clsx';
import {
  Grid,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableFooter,
  Tooltip,
  Box,
} from '@material-ui/core';

import { useStyles } from './StyleActiveIncentives';
import { useStylesTable } from '../../pages/main/timeLogs/tableCommonStyle';
import { ActiveIncentivesTableProps } from '../../common/interfaces';
import { convertToUSD } from '../../constants';
import { Link } from 'react-router-dom';
import colors from '../../common/colors';
import {
  useCustomIncentiveImgTooltip,
  useCustomIncentiveTooltip,
} from '../../common/commonStyles';
import InfoButton from '../../img/info.png';

const ActiveIncentivesTable: FC<ActiveIncentivesTableProps> = ({
  students,
  totalAmount,
}) => {
  const classes = useStyles();
  const tableClasses = useStylesTable();
  const tooltipClasses = useCustomIncentiveTooltip();
  const tooltipImgClasses = useCustomIncentiveImgTooltip();

  return (
    <Grid
      className={clsx(tableClasses.commonTable, classes.activeIncentivesTable)}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="20%"></TableCell>

            <TableCell width="12.5%"></TableCell>

            <TableCell width="12.5%"></TableCell>
            <TableCell align="center" width="15%" className="data-cell">
              Decisions{' '}
              <Tooltip
                placement="top"
                title={'The amount of college results reported by the family.'}
                arrow
                classes={tooltipImgClasses}
              >
                <Box className={`${classes.tootltipItem} imgItem`}>
                  <img src={InfoButton} />
                </Box>
              </Tooltip>
            </TableCell>
            <TableCell align="center" width="15%" className="data-cell">
              Entitlements{' '}
              <Tooltip
                placement="top"
                title={
                  'Refers to the total fixed amount of applications covered by a student’s respective admissions package.'
                }
                arrow
                classes={tooltipImgClasses}
              >
                <Box className={`${classes.tootltipItem} imgItem`}>
                  <img src={InfoButton} />
                </Box>
              </Tooltip>
            </TableCell>

            <TableCell width="25%" align="center">
              YOUR BONUS
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {students &&
            !!students?.length &&
            totalAmount &&
            students.map((student) => {
              const {
                Id,
                StudentName,
                StudentPackage,
                incentiveBonus,
                GraduateYear,
                StudentId,
                entitlements,
                applicationList,
              } = student;

              const title = applicationList?.join('\n') || '';

              return (
                <TableRow key={Id}>
                  <TableCell>
                    <Link
                      to={`/students/student-details/${StudentId}?_t=info`}
                      style={{
                        textDecoration: 'none',
                        color: colors.BLACK_TWELVE,
                      }}
                    >
                      <Typography variant="body1">{StudentName}</Typography>
                    </Link>
                  </TableCell>
                  <TableCell>{GraduateYear}</TableCell>
                  <TableCell>{StudentPackage}</TableCell>
                  <TableCell align="center">
                    {applicationList?.length ? (
                      <Tooltip
                        placement="top"
                        title={title}
                        arrow
                        classes={tooltipClasses}
                      >
                        <Box
                          className={classes.tootltipItem}
                          // width={'fit-content'}
                          textAlign={'center'}
                        >
                          {applicationList.length}
                        </Box>
                      </Tooltip>
                    ) : (
                      '--'
                    )}
                  </TableCell>
                  <TableCell align="center" className = {classes.entitlementItem} color={colors.GRAY_TWENTYTWO}>
                    {entitlements}
                  </TableCell>
                  <TableCell align="center">
                    <b>{incentiveBonus ? `$${incentiveBonus}` : <>N / A</>}</b>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>
              <Typography variant="body1" className="labelText bodyText">
                Maximum Bonus Available:
              </Typography>
            </TableCell>
            {totalAmount ? (
              <TableCell align="center">
                <Typography variant="h6" className="priceText bodyText">
                  ${convertToUSD(totalAmount)}
                </Typography>
              </TableCell>
            ) : (
              <>N / A</>
            )}
          </TableRow>
        </TableFooter>
      </Table>
    </Grid>
  );
};

export default ActiveIncentivesTable;
