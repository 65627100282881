import { useState, FC } from 'react';
import {
  Box,
  Grid,
  Button,
  Dialog,
  Typography,
  IconButton,
  DialogContent,
} from '@material-ui/core';

import IncentiveBreakdownTable from './IncentiveBreakdownTable';
import IncentiveStudentPackageTable from './IncentiveStudentPackageTable';

import { useStyles } from './StyleActiveIncentives';

import ClipboardCheck from '../../img/clipboard-check.svg';
import CloseIcon from '../../img/dialog-close-updated.svg';
import { LINK_TO_DOCS_INCENTIVE_BONUS_DETAILS } from '../../constants';

export const ActiveIncentivesDialog: FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid>
      <Grid className={classes.incentiveDialogButton}>
        <Button variant="text" onClick={handleClickOpen}>
          <img src={ClipboardCheck} alt="check" />
          Open Incentive Breakdown
        </Button>
      </Grid>

      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        className={classes.incentiveBreakDownPackageDialog}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className="dialogHeader"
        >
          <Typography variant="h2" className="headingText">
            Incentive Breakdown
          </Typography>

          <IconButton onClick={handleClose}>
            <img src={CloseIcon} alt="close" />
          </IconButton>
        </Box>

        <DialogContent className="incentiveBreakdownDialog">
          <Typography variant="body1" className="descriptionText">
            Here you can find how you can qualify for each of these incentive
            opportunities. Please refer to the Advisor Incentive Program details
            document{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={LINK_TO_DOCS_INCENTIVE_BONUS_DETAILS}
            >
              here
            </a>{' '}
            for further information.
          </Typography>

          <Box pb={5.5}>
            <IncentiveBreakdownTable />
          </Box>

          <Typography variant="h5" className="packageStudentTableHeading">
            Max incentive values by student package
          </Typography>

          <IncentiveStudentPackageTable />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default ActiveIncentivesDialog;
