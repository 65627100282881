import { makeStyles, createStyles } from '@material-ui/styles';
import Colors from '../../common/colors';

export const useStyles = makeStyles(() =>
  createStyles({
    mainContainer: {
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      color: Colors.GRAY_TWO,

      '& > img': {
        maxWidth: 250,
        width: '100%',
      },
    },

    button: {
      background: `${Colors.BLUE_SEVEN} !important`,
    },
  })
);
