import { useContext } from 'react';
import { Query } from 'react-apollo';
import { Select, MenuItem } from '@material-ui/core';
import { AppContext } from '../../../contexts';
import { ADVISOR_TEAMS } from './gql';
import { advisorTeamsQuery } from './gql/__generated__/advisorTeamsQuery';

const SelectTeam = ({
  TeamName,
  setFieldValue,
  setTeamName,
  values,
}: {
  TeamName: string;
  setFieldValue: Function;
  setTeamName: Function;
  values: any;
}) => {
  const { user } = useContext(AppContext);
  const MentorId = user?.id;

  return (
    <>
      <Query<advisorTeamsQuery>
        query={ADVISOR_TEAMS}
        variables={{ MentorId }}
        fetchPolicy="network-only"
      >
        {({ data }) => {
          const res = data?.advisorTeams?.teams?.find(
            (item: any) => item?.id === values.teamId
          );

          !TeamName && setTeamName(res?.name);
          return (
            <>
              <Select
                id="demo-simple-select-outlined"
                value={TeamName}
                fullWidth
                placeholder="- Select Team -"
                onChange={(item: any) => {
                  const value = item.target.value;
                  setFieldValue('teamId', value.id);
                  setTeamName(value.name);
                }}
              >
                {data?.advisorTeams?.teams
                  ? data.advisorTeams.teams.map((item: any) => (
                      <MenuItem key={item?.id} value={item}>
                        {item?.name}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </>
          );
        }}
      </Query>
    </>
  );
};

export default SelectTeam;
