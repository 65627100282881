import { FC, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, Card, Badge, Box } from '@material-ui/core';

/* Custom components | functions | types */
import moment from 'moment';
import BlurContent from './BlurContent';
import { AppContext } from '../../../contexts';

// styles
import { useStyles } from './CardStyle';

// images
import images from '../../../img/img';
import { SavedIcon } from './assets/svg';
import { OpportunityCardProps } from '../../../common/interfaces';
const OpportunityCard: FC<OpportunityCardProps> = ({
  id,
  organizationName,
  organizationLogo,
  programEndDate,
  programStartDate,
  programDescription,
  programName,
  targetAge,
  city,
  state,
  isRemote,
  summerOpportunityCategory,
  history,
  sessionStart,
}) => {
  const classes = useStyles();
  const { user } = useContext(AppContext);
  const handleClick = (check: string, value: string) => {
    let host = '';
    if (window.location.hostname.includes('staging')) {
      host = 'https://staging.app.collegeadvisor.com';
    } else if (window.location.hostname.includes('dev')) {
      host = 'https://dev.app.collegeadvisor.com';
    } else {
      host = 'https://app.collegeadvisor.com';
    }
    check === 'card'
      ? (window.location.href = `${host}/opportunity-detail/${id}?adv=true`)
      : history.push(`/extracurricular-opportunities/find?category=${value}`);
  };
  return (
    <Grid className={classes.cardSavedOpportunity}>
      <Grid className="savedOpportunityButton">
        <Box className="savedOpportunityIcon">
          <SavedIcon />
        </Box>
      </Grid>

      <Card
        onClick={(e) => {
          e.preventDefault();
          handleClick('card', 'abc');
        }}
        className={classes.cardOpportunity}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="13px"
        >
          <Typography variant="body1" className="organizationTitle">
            {!user ? (
              <BlurContent />
            ) : organizationName ? (
              organizationName.length > 30 ? (
                organizationName
              ) : (
                organizationName
              )
            ) : (
              '--'
            )}
          </Typography>
        </Box>

        <Typography variant="h6" className="opportunityTitle">
          {!user ? (
            <BlurContent />
          ) : programName ? (
            programName.length > 30 ? (
              programName
            ) : (
              programName
            )
          ) : (
            '--'
          )}
        </Typography>

        {summerOpportunityCategory &&
          summerOpportunityCategory.map((item: any) => (
            <Badge
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                // handleClick('tag', item.name);
              }}
            >
              {item.name.length > 13
                ? item.name.substring(0, 10) + '...'
                : item.name}
            </Badge>
          ))}

        <Grid className={classes.basicInfo}>
          <Typography variant="body2">
            <img src={images.LOCATION_IMG} alt="logo" />

            {isRemote && isRemote ? (
              'Remote'
            ) : (
              <>
                {city ? `${city}` : null}
                {city && state && ', '}
                {!state && !city && '--'}
                {state ? `${state}` : null}
              </>
            )}
          </Typography>
          <Typography variant="body2">
            <img src={images.TIME_IMG} alt="logo" />

            {sessionStart?.length
              ? `Starts in ${sessionStart
                  .map((item) => item?.value)
                  .join(', ')}`
              : '--'}
            {/* {programStartDate
              ? `${moment(programStartDate).format('MM/DD/YYYY')}`
              : null}
            {programStartDate && programEndDate && ' - '}
            {programEndDate
              ? `${moment(programEndDate).format('MM/DD/YYYY')}`
              : null}
            {!programStartDate && !programEndDate && '--'} */}
          </Typography>

          <Typography variant="body2">
            <img src={images.STATUS_IMG} alt="logo" />
            {(targetAge?.length &&
              targetAge.map((item, index) => (
                <span>{`${item}${
                  targetAge && index === targetAge?.length - 1 ? 'th ' : 'th, '
                }`}</span>
              ))) ||
              '--'}
          </Typography>
        </Grid>
      </Card>
    </Grid>
  );
};

export default withRouter(OpportunityCard);
