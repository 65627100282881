import React, { FC, Fragment, useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Select,
  MenuItem,
  Typography,
  IconButton,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { ApolloConsumer } from 'react-apollo';

import SEARCH_DARK_IMG from '../../../img/search-dark.svg';
import DATEPICKER_IMG from '../../../img/logs-calendar.svg';
import fonts from '../../../common/fonts';
import colors from '../../../common/colors';
import StudentTimeLogsTable from './StudentTimeLogsTable';
import SELECT_ARROW_DOWN_ICON from '../../../img/SelectArrowDownIcon.svg';
import {
  mileStoneOptions,
  workActivityForStudentAdvising,
} from '../timeLogs/static';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { GET_ALL_STUDENT_TIME_LOGS_MENTORS } from './gql';
import {
  ALL_STUDENT_TIME_LOGS_MENTORS,
  ALL_STUDENT_TIME_LOGS_MENTORS_getAllStudentTimeLogsMentors as STUDENT_TIME_LOGS_MENTORS,
} from './gql/__generated__/ALL_STUDENT_TIME_LOGS_MENTORS';
import ApolloClient from 'apollo-client';
import client from '../../../apollo';
import Loader from '../../../components/Loader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filtersContainer: {
      paddingTop: 20,

      '& .MuiFormControl-root': {
        '@media only screen and (max-width: 991px)': {
          width: '100%',
        },
      },

      '& .MuiFormHelperText-root.Mui-error': {
        bottom: -25,
        fontSize: 11,
        whiteSpace: 'nowrap',
        position: 'absolute',
      },

      '& .MuiInput-underline:after': {
        display: 'none',
      },

      '& .timeLogs': {
        "& [class*='MuiTypography-body1']": {
          fontSize: 14,
          fontWeight: 400,
          color: '#A9A9A9',
          marginBottom: 48,
          fontFamily: fonts.INTER,
          letterSpacing: '-0.084px',

          '& b': {
            color: '#272929',
          },
        },
      },

      "& [class*='MuiTypography-caption']": {
        fontSize: 14,
        fontWeight: 400,
        color: '#272929',
        lineHeight: '30px',
        fontFamily: fonts.INTER,
      },

      "& [class*='MuiSelect-select']": {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center right 5px',
        backgroundImage: `url( ${SELECT_ARROW_DOWN_ICON})`,

        '&:focus': {
          backgroundColor: 'transparent',
        },
      },

      "& [class*='MuiSelect-icon']": {
        display: 'none',
      },

      '& .MuiFormLabel-root': {
        margin: 0,
        fontSize: 14,
        fontWeight: 400,
        color: '#272929',
        lineHeight: '11px',
        fontFamily: fonts.INTER,
        transform: 'translate(0px, -4px) scale(1)',

        '&.Mui-focused': {
          color: '#272929',
        },
      },

      '& .MuiInput-root': {
        border: '1px solid #D4D4D4',
        borderRadius: 5,
        fontSize: 14,
        fontWeight: 400,
        color: '#62636B',
        padding: '1px 10px 1px 33px',
        height: 40,
        fontFamily: fonts.INTER,
      },

      '& .timeLogSelect': {
        '& .MuiInput-root': {
          width: '100%',
          padding: '1px 10px',
          fontSize: 14,
          fontWeight: 400,
          height: 40,
        },

        '& .MuiMenuItem-root': {
          fontFamily: fonts.INTER,
        },

        '& .react-select__placeholder': {
          fontSize: 14,
          fontWeight: 400,
          color: '#A9A9A9',
          fontFamily: fonts.INTER,
          transform: 'translateY(-60%) !important',
        },

        '& .react-select__placeholder, & .react-select__value-container': {
          fontSize: 14,
          fontWeight: 400,
        },

        '& .react-select__control': {
          height: 40,
          minHeight: 40,
        },

        '& .react-select__single-value, & .react-select__placeholder': {
          fontSize: 14,
          fontWeight: 400,
          fontFamily: fonts.INTER,
        },

        '& .react-select__indicators': {
          height: 40,
        },

        '& .react-select__indicator-separator': {
          display: 'none',
        },

        '& .react-select__option': {
          fontSize: 12,
        },
      },

      '& .MuiInputAdornment-root': {
        position: 'absolute',
        left: 0,
      },

      '& .MuiIconButton-root': {
        width: 17,
        height: 17,
        padding: 0,
        borderRadius: 0,
        backgroundImage: `url(${DATEPICKER_IMG})`,
        backgroundSize: '16px',
        backgroundRepeat: 'no-repeat',

        '& .MuiSvgIcon-root': {
          display: 'none',
        },
      },

      '& .MuiInput-underline': {
        '&:before': {
          borderBottom: 0,
        },

        '&:hover': {
          '&:before': {
            borderBottom: 0,
          },
        },
      },
    },

    formControl: {
      minWidth: 120,

      '&:first-child': {
        marginRight: 15,
      },

      '@media only screen and (max-width: 767px)': {
        marginBottom: 15,
      },
    },

    filtersWrapper: {
      padding: '3px 59px 20px',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'space-between',

      [theme.breakpoints.down('sm')]: {
        padding: '20px 16px',
      },

      '& .MuiFormControlLabel-root': {
        margin: 0,
      },
    },

    inputSpanText: {
      fontWeight: 400,
      fontSize: 12,
      color: '#000000',
      letterSpacing: '-0.006em',
      margin: '16px 14px 0px',
    },

    searchStudentAdvisor: {
      marginLeft: 38,

      '@media only screen and (min-width: 992px) and (max-width: 1024px)': {
        marginLeft: 0,
        marginTop: 30,
      },

      [`@media only screen and (max-width: 767px)`]: {
        marginLeft: 0,
        width: '100%',
      },

      '& .MuiFormControl-root': {
        width: 356,
        backgroundImage: `url(${SEARCH_DARK_IMG})`,
        backgroundRepeat: 'no-repeat !important',
        backgroundSize: '8% 50%',
        backgroundPosition: 'left !important',
        marginTop: 8,
      },

      '& .MuiFormControl-marginNormal': {
        whiteSpace: 'nowrap',
        overFlow: 'hidden',
        textOverflow: 'ellipsis',
      },

      '& .MuiInputLabel-formControl': {
        fontWeight: 'normal',
        fontSize: '16px !important',
        color: '#62636B',
        opacity: '0.5',
        padding: '4px 0px 0px 35px',
        whiteSpace: 'nowrap',
      },

      '& .MuiInputBase-root': {
        paddingLeft: 35,
        fontSize: '16px !important',
      },

      '& .MuiInputLabel-shrink': {
        fontSize: '14px !important',
        fontQeight: '600 important',
        transform: 'translate(0px,-10px) scale(0.75) !important',
        opacity: 1,
        whiteSpace: 'nowrap',
      },
    },

    filterMobile: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    addButton: {
      "&[class*='MuiButton-root']": {
        minWidth: 140,
        height: 40,
        background: '#38A878',
        borderRadius: 12,
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 700,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: colors.WHITE_ONE,
        boxShadow: 'none',

        [theme.breakpoints.down('sm')]: {
          marginTop: 15,
        },

        '& .csvText': {
          textTransform: 'lowercase',
        },

        '& svg': {
          fontSize: 19,
        },
      },
    },

    resetButton: {
      top: 35,
      right: 17,
      bottom: 19,
      padding: 0,
      position: 'absolute',
      borderRadius: '50% !important',
      backgroundImage: 'none !important',

      '& .MuiSvgIcon-root': {
        width: 19,
        display: 'inline-block !important',
      },
    },
  })
);

interface Props {
  StudentId: number | '' | undefined;
  markedPremiumAt?: string | null | undefined;
}

const StudentTimeLogs: FC<Props> = ({ StudentId, markedPremiumAt }) => {
  const classes = useStyles();
  const [selectedAdvisorId, setSelectedAdvisorId] = useState<string | null>('');
  const [milestone, setMilestone] = useState<string | null>('');
  const [from, setFrom] = useState<Date>(new Date());
  const [to, setTo] = useState<Date>(new Date());
  const [searchQuery, setSearchQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [endOpen, setEndOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allStudentMentors, setAllStudentMentors] = useState<
    (STUDENT_TIME_LOGS_MENTORS | null)[] | null
  >([]);

  const fetchStudentMentors = async (client: ApolloClient<any>) => {
    try {
      setLoading(true);
      let query: any = {
        query: GET_ALL_STUDENT_TIME_LOGS_MENTORS,
        variables: {
          studentId: StudentId,
        },
        fetchPolicy: 'network-only',
      };

      const {
        data,
        data: { getAllStudentTimeLogsMentors },
      }: {
        data: ALL_STUDENT_TIME_LOGS_MENTORS;
      } = await client.query(query);
      if (data && data.getAllStudentTimeLogsMentors) {
        setAllStudentMentors(getAllStudentTimeLogsMentors);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      if (date > to) {
        setTo(date);
      }
      setFrom(date);
    }
    setOpen(false);
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      if (date < from) {
        setFrom(date);
      }
      setTo(date);
    }
    setEndOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<any>) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    if (StudentId) {
      fetchStudentMentors(client);
    }
  }, [StudentId, client]);

  useEffect(() => {
    if (markedPremiumAt) {
      setFrom(new Date(markedPremiumAt));
    } else {
      const defaultfrom = new Date();
      defaultfrom.setFullYear(defaultfrom.getFullYear() - 1);
      setFrom(defaultfrom);
    }
  }, [markedPremiumAt]);

  if (loading) return <Loader />;

  return (
    <Box>
      <ApolloConsumer>
        {(client) => (
          <Fragment>
            <Grid className={classes.filtersContainer}>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12} className="timeLogs">
                  <Typography variant="body1">
                    <b>Time Logs (Read-Only)</b> - View all time logs submitted
                    by advisors for this student.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ alignItems: 'flex-end' }}>
                <Grid item md={2} xs={12} className="timeLogSelect">
                  <Typography variant="caption">Activity Type</Typography>
                  <Box>
                    <Select
                      name="workActivity"
                      label="Activity Type"
                      value={searchQuery}
                      onChange={handleChange}
                      displayEmpty
                      style={{ color: searchQuery ? '#272929' : '#A9A9A9' }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'center',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'center',
                        },
                        getContentAnchorEl: null,
                        PaperProps: {
                          style: {
                            marginTop: '10px',
                          },
                        },
                      }}
                    >
                      <MenuItem value="" style={{ color: '#A9A9A9' }}>
                        Activity Type
                      </MenuItem>
                      <MenuItem value="Milestone Team">Milestone Team</MenuItem>
                      {workActivityForStudentAdvising.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Grid>

                <Grid item md={2} xs={12} className="timeLogSelect">
                  <Typography variant="caption">Milestone</Typography>
                  <Box>
                    <Select
                      name="milestone"
                      value={milestone}
                      label="Milestone"
                      displayEmpty
                      onChange={(
                        item: React.ChangeEvent<{ value: unknown }>
                      ) => {
                        setMilestone(item.target.value as string);
                      }}
                      style={{ color: milestone ? '#272929' : '#A9A9A9' }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'center',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'center',
                        },
                        getContentAnchorEl: null,
                        PaperProps: {
                          style: {
                            marginTop: '10px',
                          },
                        },
                      }}
                    >
                      <MenuItem value="" style={{ color: '#A9A9A9' }}>
                        Milestone
                      </MenuItem>
                      {mileStoneOptions.map((item) => (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Grid>

                <Grid item md={2} xs={12} className="timeLogSelect">
                  <Typography variant="caption">Advisor</Typography>
                  <Box>
                    <Select
                      name="advisorName"
                      value={selectedAdvisorId}
                      label="Advisor Name"
                      displayEmpty
                      onChange={(
                        item: React.ChangeEvent<{ value: unknown }>
                      ) => {
                        setSelectedAdvisorId(item.target.value as string);
                      }}
                      style={{
                        color: selectedAdvisorId ? '#272929' : '#A9A9A9',
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'center',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'center',
                        },
                        getContentAnchorEl: null,
                        PaperProps: {
                          style: {
                            marginTop: '10px',
                          },
                        },
                      }}
                    >
                      <MenuItem value="" style={{ color: '#A9A9A9' }}>
                        Advisor Name
                      </MenuItem>
                      {allStudentMentors?.map((item) => (
                        <MenuItem
                          value={item?.id}
                        >{`${item?.firstName} ${item?.lastName}`}<br />({item?.email})</MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Grid>

                <Grid item md={2} xs={12}></Grid>

                <Grid
                  item
                  md={2}
                  sm={6}
                  xs={12}
                  style={{
                    display: 'flex',
                    position: 'relative',
                  }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      label="Start Date"
                      id="date-picker-inline"
                      maxDate={new Date()}
                      value={from}
                      open={open}
                      onOpen={() => setOpen(true)}
                      onClose={() => setOpen(false)}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      inputProps={{
                        onKeyDown: (e) => {
                          e.preventDefault();
                        },
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid
                  item
                  md={2}
                  sm={6}
                  xs={12}
                  style={{
                    display: 'flex',
                    position: 'relative',
                  }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      label="End Date"
                      id="date-picker-inline"
                      maxDate={new Date()}
                      value={to}
                      open={endOpen}
                      onOpen={() => setEndOpen(true)}
                      onClose={() => setEndOpen(false)}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      inputProps={{
                        onKeyDown: (e) => {
                          e.preventDefault();
                        },
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </Grid>

            <StudentTimeLogsTable
              client={client}
              to={to}
              from={from}
              searchQuery={searchQuery}
              StudentId={StudentId}
              selectedAdvisorId={Number(selectedAdvisorId)}
              milestone={milestone}
            />
          </Fragment>
        )}
      </ApolloConsumer>
    </Box>
  );
};

export default StudentTimeLogs;
