import { FC, useState } from 'react';
import moment from 'moment';
import {
  Typography,
  Box,
  Collapse,
  IconButton,
  Grid,
  Hidden,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useStylesApplication } from './applicationStyle';
import { isSubstring } from '../../../common/utility';
import { Link } from 'react-router-dom';
import COLLEGE_DEFAULT_IMG from '../../../img/college-default-icon.svg';

const CollapseEssayPrompt: FC<{
  open: boolean;
  description: string;
}> = ({ open, description }) => {
  return (
    <Grid className="accordionContentGrid">
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Typography variant="h6">Prompt</Typography>
        <Typography variant="body1">{description}</Typography>
      </Collapse>
    </Grid>
  );
};

const ApplicationEssayTableRow: FC<any> = ({ essay, status }) => {
  const [open, setOpen] = useState(false);
  const applicationClasses = useStylesApplication();
  const collegeLogo = essay?.school?.parentMetaData
    ? essay?.school?.parentMetaData?.logo
    : essay?.school?.logo || COLLEGE_DEFAULT_IMG;
  const collegeName = essay?.school?.parentMetaData
    ? essay?.school?.parentMetaData?.name
    : essay?.school?.name;
  let maxWord = essay?.maxWord || '--';
  let re = /\d/;

  if (isSubstring(essay?.maxWord, 'words')) {
    essay?.maxWord?.split(' ').forEach((str: string) => {
      if (re.test(str)) {
        maxWord = str;
        return;
      }
    });
  }

  return (
    <>
      <Box
        width="100%"
        className={`${applicationClasses.accordionCollpaseParent} ${applicationClasses.forLinkText}`}
      >
        <Box
          key={essay.id}
          className={
            status === 'stuck'
              ? 'defaultStatus stuck'
              : status === 'reviewReady'
              ? 'defaultStatus reviewReady'
              : status === 'In Progress'
              ? 'defaultStatus inProgress'
              : status === 'notStarted'
              ? 'defaultStatus notStarted'
              : status === 'complete'
              ? 'defaultStatus complete'
              : ''
          }
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={applicationClasses.statusMobileDesign}
          >
            <Box className="childBox collegeAvatarGrid" width="25%">
              <Box display="flex" alignItems="center">
                <Link to={`/students/application/${essay?.applicationId}`}>
                  <img src={collegeLogo} alt="avatar" width="30px" />
                  <Typography variant="body2">{essay?.name}</Typography>
                </Link>
              </Box>
            </Box>

            <Box className="childBox schoolName" width="35%">
              <Link to={`/students/application/${essay?.applicationId}`}>
                {collegeName}
              </Link>
            </Box>
            <Hidden only={['sm', 'xs']}>
              <Box className="childBox childBoxMobileWidth maxWord" width="15%">
                <Link to={`/students/application/${essay?.applicationId}`}>
                  {maxWord}
                </Link>
              </Box>
            </Hidden>
            <Box className="childBox childBoxMobileWidth dateBox" width="15%">
              <Link to={`/students/application/${essay?.applicationId}`}>
                {essay?.dueDate
                  ? moment(essay?.dueDate).format('MM/DD/YYYY')
                  : '--'}
              </Link>
            </Box>
            <Hidden only={['xl', 'lg', 'md']}>
              <Box className="childBox childBoxMobileWidth maxWord" width="15%">
                <Link to={`/students/application/${essay?.applicationId}`}>
                  {maxWord}
                </Link>
              </Box>
            </Hidden>
            <Box
              className="childBox childBoxMobileWidth arrowButton"
              width="10%"
            >
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Box>
          </Box>

          <CollapseEssayPrompt open={open} description={essay?.description} />
        </Box>
      </Box>
    </>
  );
};

const ApplicationsEssayProgressTable: FC<any> = ({ AppEssays, status }) => {
  return (
    <Box width="100%">
      {AppEssays?.map((essay: any, index: any) => {
        return <ApplicationEssayTableRow essay={essay} status={status} />;
      })}
    </Box>
  );
};
export default ApplicationsEssayProgressTable;
