import { makeStyles, createStyles } from '@material-ui/core/styles';
import Colors from '../../../common/colors';

export const advisorModalStyles = makeStyles(() =>
  createStyles({
    fabClose: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      position: 'absolute',
      right: '10px',
      top: '10px',
      width: '40px',
      height: '40px',
      outline: '0px',

      '&:hover': {
        backgroundColor: 'transparent',
      },

      '&:focus': {
        outline: '0px',
      },
    },

    requestReviewSearch: {
      justifyContent: 'flex-end',
    },

    listContainer: {
      overflowY: 'auto',

      '& .mentor-list': {
        '& > div': {
          padding: '15px 0',
          borderBottom: `1px solid ${Colors.GRAY_NINE}`,
        },
      },
    },
  })
);

export const customizedHeaderStyles = makeStyles(() =>
  createStyles({
    chatHeader: {
      height: '64px',
      overflow: 'hidden',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    messageNotification: {
      height: 30,
      overflow: 'hidden',
      background: Colors.BLUE_SEVEN,
      textAlign: 'center',
      color: Colors.WHITE_ONE,
      width: 'calc(100% - 50px)',
      margin: '0 auto',
      paddingTop: 6,
      fontSize: 12,
      borderRadius: 8,
      cursor: 'pointer',
      position: 'absolute',
      top: 75,
      left: 25,
      zIndex: 999,
    },
  })
);
