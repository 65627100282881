import { useState, useEffect } from 'react';
import { Checkbox, Box, Typography } from '@material-ui/core';
import Mutation from 'react-apollo/Mutation';
import gql from 'graphql-tag';

import { useStyles } from './styles';
import Loader from '../../../components/Loader';
import client from '../../../apollo';

import { EXCLUDE_STUDENT_FROM_WEEKLY_REPORT } from '../../../graphql/mutations/StudentDetailPage';
import {
  excludeStudentFromWeeklyAdvisorReportMutation,
  excludeStudentFromWeeklyAdvisorReportMutationVariables,
} from '../../../graphql/mutations/StudentDetailPage/__generated__/excludeStudentFromWeeklyAdvisorReportMutation';

const EXCLUDE_STUDENT_FROM_WEEKLY_REPORT_QUERY = gql`
  query ExcludeStudentFromWeeklyReportQuery(
    $studentId: Int!
    $advisorId: Int!
  ) {
    Student(studentId: $studentId, advisorId: $advisorId) {
      id
      excludeFromWeeklyAdvisorReportForAllAdvisors
    }
  }
`;

const ExcludeStudentFromWeeklyAdvisorReportCheckbox = ({
  AdvisorId,
  StudentId,
}: {
  AdvisorId: number;
  StudentId: number;
}) => {
  const classes = useStyles();
  const [isChecked, setIsChecked] = useState(false);

  const mountEffect = async () => {
    try {
      const { data } = await client.query({
        query: EXCLUDE_STUDENT_FROM_WEEKLY_REPORT_QUERY,
        variables: { studentId: StudentId, advisorId: AdvisorId },
        fetchPolicy: 'network-only',
      });

      const checkVal =
        data?.Student?.excludeFromWeeklyAdvisorReportForAllAdvisors || false;

      setIsChecked(checkVal);
    } catch (error) {
      console.log('Error:::', error);
    }
  };

  useEffect(() => {
    mountEffect();
  }, []);

  return (
    <Box display="flex" alignItems={'center'}>
      <Mutation<
        excludeStudentFromWeeklyAdvisorReportMutation,
        excludeStudentFromWeeklyAdvisorReportMutationVariables
      >
        mutation={EXCLUDE_STUDENT_FROM_WEEKLY_REPORT}
      >
        {(excludeStudent, { loading }) => {
          if (loading) return <Loader />;

          return (
            <Checkbox
              className={classes.checkBox}
              checked={isChecked}
              onChange={async ({ target: { checked } }) => {
                try {
                  const result = await excludeStudent({
                    variables: {
                      StudentId,
                      value: checked,
                      AdvisorId,
                    },
                  });
                  if (result) {
                    const checkValue =
                      result?.data?.excludeStudentFromWeeklyAdvisorReport
                        ?.excludeFromWeeklyAdvisorReport || false;
                    setIsChecked(checkValue);
                  }
                } catch (err) {
                  console.log('Error::', err);
                }
              }}
              color="primary"
            />
          );
        }}
      </Mutation>

      <Typography className={classes.checkBoxText}>
        Exclude from Weekly Advisor Report
      </Typography>
    </Box>
  );
};
export default ExcludeStudentFromWeeklyAdvisorReportCheckbox;
