import Fonts from '../../../common/fonts';
import Colors from '../../../common/colors';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import fonts from '../../../common/fonts';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropStyleApplication: {
      '& .react-select__control': {
        minHeight: '40px !important',
        height: '40px !important',
        width: '267px !important',
      },

      '& .react-select__indicators': {
        height: '40px !important',
      },
    },
    
    collegeSubCategroryLogo: {
      width: 48,
      height: 48,
      backgroundColor: '#fff',
      borderRadius: '50%',
      border: '2px solid #2774ae',
      position: 'absolute',
      bottom: -13,
      right: -17,
      backgroundSize: 22,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',

      infoIcon: {
        cursor: 'pointer',
      },

      [theme.breakpoints.down('md')]: {
        width: 40,
        height: 40,
      },
    },
    breadCrumbCollege: {
      borderRadius: 0,
      boxShadow: 'none',
      padding: '15px 70px 0px',
      minHeight: 160,
      background: 'linear-gradient(266.17deg, #1E5298 0.2%, #0A2463 99.58%)',

      [theme.breakpoints.down('md')]: {
        padding: '20px 20px 0px',
      },

      [theme.breakpoints.down('xs')]: {
        padding: '16px 0px 0px',
        minHeight: 'auto',
      },

      '& .iconButtonArrow': {
        padding: 0,
      },

      '& .MuiTypography-h2': {
        fontWeight: 'bold',
        fontSize: 36,
        color: Colors.WHITE_ONE,
        letterSpacing: '-0.022em',
        fontFamily: Fonts.POPPINS_FONT,

        [theme.breakpoints.down('xs')]: {
          margin: '19px 0px 0px 0px',
        },
      },

      '& .MuiTypography-h6': {
        fontWeight: 600,
        fontSize: 14,
        color: Colors.WHITE_ONE,
        letterSpacing: '0.006em',
        fontFamily: fonts.POPPINS_FONT,
        textTransform: 'uppercase',

        [theme.breakpoints.down('md')]: {
          fontSize: 13,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          marginLeft: 21,
        },

        [`@media only screen and (max-width: 414px)`]: {
          width: '36ch !important',
        },

        [`@media only screen and (max-width: 375px)`]: {
          width: '31ch !important',
        },

        [`@media only screen and (max-width: 330px)`]: {
          width: '25ch !important',
        },
      },
    },
    containerRelative: {
      position: 'relative',

      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },

    collegeLogoAvatar: {
      width: 97,
      height: 97,
      position: 'relative',
      borderRadius: '50%',
      backgroundColor: Colors.WHITE_ONE,
      backgroundSize: 62,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      boxShadow: '0px 5px 17.5px rgba(68, 68, 68, 0.14)',

      [theme.breakpoints.down('xs')]: {
        width: 88,
        height: 88,
      },
    },

    mobilePaddingBreadcrumb: {
      [theme.breakpoints.down('xs')]: {
        padding: '0px 16px',
      },
    },

    collegeName: {
      display: 'flex',
      alignItems: 'center',
      padding: '0px 0px 0px 40px',

      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },

      [theme.breakpoints.down('sm')]: {
        padding: '19px 0px 40px',
        flexDirection: 'column',
        alignItems: 'flex-start',
      },

      '& .collegeNameSpacing': {
        paddingLeft: 44,

        [theme.breakpoints.down('sm')]: {
          marginTop: 15,
          paddingLeft: 0,
        },

        [theme.breakpoints.down('xs')]: {
          paddingLeft: 0,
          display: 'flex',
          flexDirection: 'column-reverse',
        },

        '& .MuiTypography-h6': {
          [theme.breakpoints.down('xs')]: {
            marginTop: 15,
          },
        },
      },
    },

    mobileFieldHeading: {
      fontWeight: 400,
      fontSize: 14,
      letterSpacing: '-0.006em',
      color: '#272929',
      marginBottom: 4,
      display: 'block',
    },
  })
);