import { createStyles, makeStyles, Theme } from '@material-ui/core';

import Colors from '../../../../../common/colors';
import fonts from '../../../../../common/fonts';

import CALENDAR_IMG from './../../../../../img/calendar-icon-opp.svg';
import DROPDOWN_ARROW_IMG from './../../../../../img/dropdown-arrow.svg';
import DropdownArrowErrorIcon from '../../../../../img/dropdown-arrow-error.svg';
import colors from '../../../../../common/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activityPerformed: {

      '& .MuiInputBase-fullWidth .MuiSelect-select.MuiSelect-selectMenu': {
        height: 34,
        padding: '6px 10px',
      },
    },

    logHoursCheckbox: {
      paddingLeft: 45,

      '& label': {
        fontSize: 14,
        margin: 0,
        fontWeight: 400,
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.084px',
        cursor: 'pointer',
      },

      '& svg path': {
        fill: '#7D7F7F',
      },
    },
    subSectionPadding: {
      paddingLeft: 56,

      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
      },
    },
    MuiErrorField: {
      '& .MuiInputBase-formControl.MuiInput-formControl input': {
        border: '1px solid #E94444 !important',
      },
    },

    addNewApplications: {
      padding: '0px 0px 0px 55px',

      '& button': {
        padding: 8,
        fontSize: 14,
        fontWeight: 600,
        color: '#2F80ED',
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        fontFamily: fonts.POPPINS_FONT,

        '& img': {
          marginRight: 12,
        },
      },
    },
    secondAdvisorApplication: {
      paddingLeft: 55,
      marginBottom: 17,

      '& .react-select__placeholder': {
        fontSize: 14,
        fontWeight: 400,
        color: '#A9A9A9',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
      },

      '& .react-select__multi-value': {
        borderRadius: 6,
        backgroundColor: '#A9A9A9',
      },

      '& .react-select__control': {
        borderRadius: 6,
        boxShadow: 'none',
        borderColor: '#d4d4d4',
      },

      '& .react-select__indicator-separator': {
        display: 'none',
      },

      '& .react-select__multi-value__label': {
        fontSize: 14,
        fontWeight: 600,
        color: '#FFFFFF',
        letterSpacing: '-0.006em',
      },

      '& .react-select__clear-indicator': {
        display: 'none',
      },

      '& .react-select__multi-value__remove': {
        backgroundColor: '#A9A9A9',
        borderRadius: '0px 6px 6px 0px',

        '&:hover': {
          cursor: 'pointer',
          backgroundColor: '#A9A9A9',
        },

        '& svg': {
          width: 21,
          height: 21,
          opacity: 0.7,
          color: '#fff',
        },
      },
    },

    studentApplicationsSelect: {
      '& .react-select__multi-value': {
        backgroundColor: '#A9A9A9 !important',
      },

      '& .react-select__multi-value__remove:hover': {
        cursor: 'pointer',
        backgroundColor: '#A9A9A9',
      },

      '& .react-select__dropdown-indicator': {
        width: '33px',
        height: '33px',
        backgroundSize: 12,
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'transparent',
        backgroundPosition: 'center right 12px',
        backgroundImage: `url(${DROPDOWN_ARROW_IMG})`,

        '& svg': {
          display: 'none',
        },
      },

      '& .react-select__placeholder': {
        fontSize: 14,
        fontWeight: 400,
        color: '#A9A9A9',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
      },
    },

    dateDuration: {
      '& .activityLog': {
        fontSize: 12,
        color: '#A9A9A9',
        fontWeight: 400,
        marginBottom: 6,
        fontFamily: fonts.INTER,
      },
    },

    editTimeDialog: {
      '& .closeIconButton': {
        top: 41,
        right: 41,
        position: 'absolute',

        [theme.breakpoints.down('xs')]: {
          right: 20,
        },
      },

      '& .MuiDialog-paperFullWidth': {
        width: '100%',
        borderRadius: 25,
        maxWidth: 1057,
        background: '#FFFFFF',
        boxShadow: '0px 4px 14px rgb(68 68 68 / 14%)',

        [theme.breakpoints.down('sm')]: {
          margin: 10,
        },

        '& .MuiButton-root': {
          fontFamily: 'Poppins',
        },
      },

      '& .MuiDialogContent-root': {
        padding: '0px 50px 41px',

        [theme.breakpoints.down('sm')]: {
          padding: '41px 20px',
        },
      },

      '& .MuiDialogActions-root': {
        maxWidth: 332,
        width: '100%',
        marginLeft: 'auto',

        '& .MuiButton-root': {
          width: '100%',
        },
      },

      '& .headingSubTitle': {
        fontSize: 18,
        fontWeight: 600,
        color: '#272929',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.014em',

        '& a': {
          fontSize: 12,
          marginLeft: 12,
          fontWeight: 400,
          color: '#2F80ED',
          letterSpacing: '-0.006em',

          '&:hover': {
            color: '#2F80ED',
          },
        },

        '& span': {
          fontWeight: 400,
        },
      },
    },
    disableTextFieldDate: {
      '& .MuiInputBase-root': {
        '& .MuiInput-input': {
          color: '#A9A9A9',
          backgroundColor: Colors.GRAY_BOREDER,
          border: '1px solid #D4D4D4 !important',
        },
      },
    },

    disableLabelText: {
      opacity: 0.5,
    },

    expectedPayment: {
      '& .MuiFormControlLabel-label': {
        fontWeight: 400,
        fontSize: 12,
        color: '#7D7F7F',
        lineHeight: '16px',
        fontFamily: fonts.INTER,
        maxWidth: 379,

        [theme.breakpoints.down('sm')]: {
          maxWidth: '100%',
        },
      },

      '& .MuiFormControlLabel-root': {
        marginBottom: 0,
        alignItems: 'flex-start',
      },

      '& svg path': {
        color: '#7D7F7F',
      },

      '& .MuiCheckbox-root': {
        position: 'relative',
        bottom: 9,
      },

      '& .MuiSvgIcon-root': {
        width: 20,
      },
    },

    editDialogHeader: {
      padding: '30px 41px',

      [theme.breakpoints.down('sm')]: {
        padding: '20px',
      },

      '& .MuiTypography-h5, .MuiButton-root, a': {
        fontFamily: 'Poppins',
      },

      '& .MuiTypography-h5': {
        fontSize: 24,
        fontWeight: 600,
        color: '#272929',
        letterSpacing: '-0.019em',

        [theme.breakpoints.down('sm')]: {
          fontSize: 18,
        },

        '& a': {
          paddingLeft: 16,
          fontWeight: 300,
          fontSize: '14px !important',
          color: '#2F80ED',

          [theme.breakpoints.down('sm')]: {
            paddingLeft: 5,
            fontSize: '12px !important',
          },
        },
      },

      '& .MuiDialogActions-root': {
        padding: 0,
      },

      '& .MuiButton-root': {
        fontWeight: 600,
        fontSize: 14,
        textTransform: 'uppercase',
        color: '#272929',

        [theme.breakpoints.down('sm')]: {
          fontSize: 12,
        },

        '& img': {
          marginRight: 8,
        },
      },
    },

    stripeTokenCard: {
      background: '#FFF8E4',
      borderRadius: 15,
      padding: '23px 20px',
      boxShadow: 'none',
      marginBottom: 43,

      '@media only screen and (max-width: 767px)': {
        padding: '15px',
        margin: 15,
      },

      '& img': {
        marginRight: 20,
        position: 'relative',
        bottom: 4,
      },

      '& .MuiTypography-h5, .MuiTypography-body1, .MuiButton-root': {
        fontFamily: 'Poppins',
        fontSize: 14,
        letterSpacing: '-0.006em',
      },

      '& .MuiTypography-h5': {
        fontWeight: 600,
        color: '#272929',
      },

      '& .MuiTypography-body1': {
        fontWeight: 'normal',
        color: '#62636B',
        margin: '4px 0px 12px',
      },

      '& .MuiButton-root': {
        background: '#2F80ED',
        borderRadius: 6,
        color: '#FFFFFF',
        padding: '8px 15px',
        textTransform: 'capitalize',
      },
    },

    formBoxCard: {},

    successCardBox: {
      '& .MuiTypography-h3': {
        fontSize: 36,
        fontWeight: 700,
        display: 'flex',
        color: '#272929',
        alignItems: 'center',
        padding: '0px 0px 16px',
        letterSpacing: '-0.792px',
        fontFamily: fonts.POPPINS_FONT,

        [theme.breakpoints.down('xs')]: {
          padding: '0px 0px 16px',
        },

        '& svg': {
          marginLeft: 14,
        },
      },

      '& .submittedText': {
        fontSize: 16,
        maxWidth: 691,
        fontWeight: 400,
        color: '#7D7F7F',
        marginBottom: 29,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.176px',

        '& a': {
          borderBottom: `1px solid ${colors.BLUE_SEVEN}`,
        },
      },

      '& .sectionDivider': {
        maxWidth: 400,
        marginBottom: 32,
        backgroundColor: '#D4D4D4',
      },
    },

    logHoursAlert: {
      '& .MuiAlert-root': {
        borderRadius: 15,
        maxWidth: 372,
        width: '100%',
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: 14,
        color: '#000000',
        padding: '18px 21px',
        marginTop: 20,

        '& .MuiTypography-h5': {
          fontFamily: 'Poppins',
          fontWeight: 600,
          fontSize: 14,
          color: '#000000',
        },

        '& .MuiAlert-icon': {
          display: 'none',
        },

        '& ul': {
          margin: '4px 0px 0px 0px',
          paddingLeft: 53,

          '& li': {
            fontWeight: 'normal',
            fontSize: 14,
            letterSpacing: '-0.006em',
            color: '#62636B',
          },
        },

        '& .MuiAlert-message': {
          padding: 0,
        },

        '& img': {
          marginRight: 20,
        },
      },

      '& .MuiAlert-standardError': {
        background: 'rgba(233, 68, 68, 0.15)',
      },

      '& .MuiAlert-standardWarning': {
        background: '#FFF8E4',
      },
    },

    formContainer: {
      '& .MuiFormControl-root': {
        width: '100%',
      },
      '& .MuiInputBase-root': {
        width: '100%',
      },

      '& .MuiRadio-colorSecondary.Mui-checked': {
        color: Colors.BLUE_SEVEN,
        background: 'none !important',
      },

      '& button': {
        borderRadius: 30,
        padding: '7px 22px',
        fontSize: 12,
      },

      '& .MuiButton-containedPrimary': {
        background: `${Colors.BLUE_SEVEN} !important`,
      },

      '& .MuiFormLabel-root.Mui-focused': {
        color: Colors.BLUE_SEVEN,
      },

      '& .MuiInput-underline:after': {
        borderBottom: `2px solid ${Colors.BLUE_SEVEN}`,
      },

      '& .onlineMeetingVerified': {
        fontSize: 12,
        fontWeight: 400,
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
        margin: '0px 10px 0px 12px',
      },

      '& .textActionButton': {
        padding: 0,
        fontSize: 12,
        fontWeight: 400,
        color: '#2F80ED',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
        textTransform: 'capitalize',
      },
    },

    formInner: {
      display: 'flex',

      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },

      '& .MuiFormHelperText-root.Mui-error': {
        lineHeight: '16px',
        fontFamily: fonts.INTER,
      },
    },

    formLeft: {
      maxWidth: 334,
      width: '100%',
      marginRight: 75,

      '@media only screen and (max-width: 767px)': {
        width: '100%',
      },

      [theme.breakpoints.down('md')]: {
        border: 'none',
        paddingRight: 0,
      },

      '& .MuiSelect-root.Mui-disabled': {
        backgroundColor: '#f1f1f1 !important',

      },

      '& .MuiSelect-root.Mui-disabled + .MuiSelect-nativeInput': {
        backgroundColor: '#f1f1f1 !important',
      },

      '& .MuiFormControlLabel-root': {
        marginTop: -17,
        marginBottom: 6,

        '& .MuiFormControlLabel-label': {
          fontWeight: 400,
          fontSize: 14,
          letterSpacing: '-0.011em',
          color: '#7D7F7F',
          fontFamily: fonts.INTER,
        },
      },

      '& .MuiCheckbox-colorPrimary': {
        color: '#7D7F7F !important',
      },
    },

    formRight: {
      width: '100%',
      display: 'flex',
      boxShadow: 'none',
      flexDirection: 'column',
      padding: '0px 0px 0px 67px',
      borderLeft: '1px solid #D4D4D4',
      justifyContent: 'space-between',

      [theme.breakpoints.down('sm')]: {
        padding: 0,
        borderLeft: '0',
      },

      '& .amountText': {
        fontSize: 24,
        fontWeight: 600,
        color: '#272929',
        letterSpacing: '-0.019em',
        fontFamily: fonts.POPPINS_FONT,
      },
    },

    textfieldDateDuration: {
      marginBottom: '0px !important',
    },

    textfield: {
      fontSize: 14,
      marginBottom: 17,

      '& > label': {
        fontSize: 12,
        fontWeight: 400,
        marginBottom: 4,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
        color: '#272929 !important',
      },

      '& .select-field > div > div': {
        boxShadow: '0px 0px 3px 0.5px rgba(0, 0, 0, 0.25)',
        borderRadius: 5,
        border: 'none',
        fontSize: 14,
        fontFamily: fonts.INTER,
      },

      '& .MuiInputBase-formControl.MuiInput-formControl input': {
        height: 32,
        fontSize: 14,
        borderRadius: 6,
        boxShadow: 'none',
        padding: '0px 10px',
        fontFamily: fonts.INTER,
        border: '1px solid #d4d4d4',

        '&::placeholder': {
          opacity: 1,
          color: '#A9A9A9',
        },
      },

      '& .MuiErrorField .MuiSelect-select.MuiSelect-selectMenu': {
        border: '1px solid #E94444 !important',
      },

      '& .MuiErrorField.MuiInputBase-root': {
        backgroundImage: `url(${DropdownArrowErrorIcon})`,
      },

      '& .MuiInputBase-fullWidth': {
        '& .MuiSelect-select.MuiSelect-selectMenu': {
          paddingLeft: 10,
          background: 'none',
          fontSize: 14,
          fontFamily: fonts.INTER,
          boxShadow: 'none',
          borderRadius: 6,
          paddingRight: 10,
          border: '1px solid #d4d4d4',
          boxSizing: 'border-box',
        },
      },

      '& .MuiInput-underline:after': {
        display: 'none',
      },

      '& .MuiInput-underline:before': {
        display: 'none',
      },

      '& .MuiCheckbox-colorPrimary.Mui-checked': {
        color: Colors.BLUE_SEVEN,
      },

      '& button.MuiButtonBase-root.MuiIconButton-root': {
        position: 'absolute',
        right: 15,
        padding: 0,
      },

      '&.with-textarea': {
        '& .MuiInputBase-fullWidth': {
          height: 'auto',
          border: '1px solid #d4d4d4',
          borderRadius: 6,
          fontSize: 14,

          '& textarea': {
            padding: '0 10px',
          },
        },
      },

      '&.with-topic': {
        '& > label': {
          width: 110,
          margin: 0,
          textAlign: 'right',
          paddingRight: 13,
        },
      },
      '&.hide-error': {
        '& div p': {
          display: 'none',
        },
      },
    },

    durationField: {
      '& .MuiInputBase-fullWidth': {
        boxShadow: 'none !important',
      },
    },

    missedCallCheckbox: {
      '& .MuiTypography-body1': {
        fontSize: 12,
        color: '#62636B',
        fontFamily: fonts.INTER,
      },
    },

    addButton: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      color: Colors.BLUE_SEVEN,
      marginBottom: 17,

      '& > span': {
        marginLeft: 14,
      },
      '&.disable': {
        color: Colors.BLUE_SEVEN,
        cursor: 'not-allowed',
        opacity: 0.6,
      },
    },

    submitButton: {
      width: 200,
      height: 40,
      background: '#45CE93',
      color: '#ffffff !important',
      borderRadius: '12px !important',
      letterSpacing: '-0.006em !important',
      fontFamily: `${fonts.POPPINS_FONT} !important`,

      '& .MuiButton-label': {
        fontSize: '14px !Important',
      },

      '&:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
      },

      '&:hover': {
        boxShadow: 'none',
        backgroundColor: Colors.GREEN_BUTTON_HOVER,
      },

      '@media only screen and (max-width: 767px)': {
        width: '100%',
      },
    },

    textFieldMaterial: {
      '& .MuiInputBase-root': {
        backgroundSize: 12,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center right 12px',
        backgroundImage: `url(${DROPDOWN_ARROW_IMG})`,

        '&.Mui-disabled': {
          borderColor: 'hsl(0, 0%, 90%)',
          backgroundColor: 'transparent',

          '& .MuiSelect-nativeInput': {
            backgroundColor: '#fff',
          },
        },

        '& .MuiSvgIcon-root': {
          display: 'none',
        },
      },

      '& .MuiSelect-nativeInput': {
        border: 0,
        paddingLeft: 10,
        fontFamily: fonts.INTER,
        fontSize: 14,
        fontWeight: 400,
        width: '90%',
        bottom: 6,
        left: 5,
        borderRadius: '6px 0px 0px 6px',

        '&::placeholder': {
          fontSize: 14,
          color: '#8F8F8F',
        },
      },
    },

    textFieldDate: {
      '& .MuiInputBase-root': {
        '& .MuiSvgIcon-root': {
          backgroundImage: `url(${CALENDAR_IMG})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '3px',

          '& path': {
            display: 'none',
          },
        },
      },
    },

    customDropdownField: {
      '& .css-1okebmr-indicatorSeparator': {
        backgroundColor: 'transparent',
      },

      '& .css-yk16xz-control': {
        border: 0,
      },
    },
    secondAdvisor: {
      paddingLeft: 55,
      marginBottom: 17,

      '& .react-select__dropdown-indicator': {
        width: '33px',
        height: '33px',
        backgroundSize: 12,
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'transparent',
        backgroundPosition: 'center right 12px',
        backgroundImage: `url(${DROPDOWN_ARROW_IMG})`,

        '& svg': {
          display: 'none',
        },
      },

      '& .react-select__placeholder': {
        fontSize: 14,
        fontWeight: 400,
        color: '#A9A9A9',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
      },

      '& .react-select__indicator': {
        padding: '0px 8px',
      },

      '& .react-select__control': {
        minHeight: 34,
        borderRadius: 6,
        boxShadow: 'none',
        borderColor: '#d4d4d4',
      },

      '& .react-select__indicator-separator': {
        display: 'none',
      },
    },

    timeLogData: {
      fontSize: 14,
      fontWeight: 400,
      color: '#A9A9A9',
      fontFamily: fonts.INTER,
      letterSpacing: '-0.084px',
      '& ul': {
        color: `${colors.BLACK_THIRTEEN} !important`,
        paddingLeft: '12px',
        marginBottom: '8px',
      },

      '& .valueText': {
        color: '#7D7F7F',

        '& b': {
          color: '#525454',
        },
        '&.note': {
          marginLeft: '12px',
        },
      },
    },
  })
);
