import { FC, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import { ButtonFiles } from '../../../styles/common';

import {
  UploadFileMutation,
  UploadFileMutationVariables,
} from './__generated__/UploadFileMutation';

import { Grid } from '@material-ui/core';
import Loader from '../../../components/Loader';

const UPLOAD_FILE_MUTATION = gql`
  mutation UploadFileMutation(
    $file: Upload!
    $isPublic: Boolean
    $directoryName: String
  ) {
    uploadFile(
      file: $file
      isPublic: $isPublic
      directoryName: $directoryName
    ) {
      source
      success
    }
  }
`;

interface Props {
  isPublic?: Boolean;
  response: Function;
  directoryName?: string;
  file?: any;
  setFile: Function;
}

const UploadDocForm: FC<Props> = ({
  response,
  isPublic = false,
  directoryName = '',
  file,
  setFile,
}) => {
  const [loading, setLoading] = useState(false);

  const submitForm = async (uploadFileMutation: Function) => {
    try {
      setLoading(true);
      const result = await uploadFileMutation({
        variables: { file, isPublic, directoryName: directoryName },
      });
      const {
        data: {
          uploadFile: { source, success },
        },
      } = result;
      if (!success) return alert('file not uplaoded');

      response(source);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setFile(null);
      setLoading(false);
    }
  };

  return (
    <Grid>
      {loading && <Loader />}

      {file && (
        <Mutation<UploadFileMutation, UploadFileMutationVariables>
          mutation={UPLOAD_FILE_MUTATION}
        >
          {(uploadFileMutation: Function) => (
            <ButtonFiles
              onClick={() => submitForm(uploadFileMutation)}
              className="ml-0"
            >
              Upload
            </ButtonFiles>
          )}
        </Mutation>
      )}
    </Grid>
  );
};

export default UploadDocForm;
