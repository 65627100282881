import { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import { Box, Container } from '@material-ui/core';
import { CURRENT_YEAR } from '../../constants';
import LOGO from '../../img/logo-header.svg';
import { footerNewStyles } from './FooterStyles';

const FooterNew: FC = () => {
  const classes = footerNewStyles();

  return (
    <Grid className={classes.footerWrapper}>
      <Container className={classes.containerMain}>
        <Hidden only={['sm', 'xs']}>
          <Grid container>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Box maxWidth={250}>
                <img
                  src={LOGO}
                  className={classes.footerLogo}
                  alt="footer main logo"
                />

                <Typography
                  variant="body1"
                  component="p"
                  className={classes.footerCopyRight}
                >
                  &copy; {CURRENT_YEAR} USN College Advisor, Inc.
                  <br />
                  All Rights Reserved
                </Typography>
              </Box>
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Typography
                variant="h4"
                component="h4"
                className={classes.footerLinksHeading}
              >
                HELP
              </Typography>
              <a
                className={classes.footerNavLinks}
                href="https://www.collegeadvisor.com/contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact
              </a>
              <a
                className={classes.footerNavLinks}
                href="https://collegeadvisor.com/pricing#faq"
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQ
              </a>

              <a
                href="javascript:void(0)"
                className={classes.footerNavLinks}
                onClick={() => {
                  if (window.Intercom) window.Intercom('show');
                }}
              >
                Help
              </a>
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12}>
              <Typography
                variant="h4"
                component="h4"
                className={classes.footerLinksHeading}
              >
                LEGAL
              </Typography>
              <a
                href="https://www.collegeadvisor.com/terms-and-conditions/"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.footerNavLinks}
              >
                Terms and Conditions
              </a>

              <a
                className={classes.footerNavLinks}
                href="https://www.collegeadvisor.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              <a
                href="https://www.collegeadvisor.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.footerNavLinks}
              >
                Privacy Policy and Cookie Notice
              </a>
              <a
                href="https://www.collegeadvisor.com/privacy-ccpa/"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.footerNavLinks}
              >
                California Privacy Notice
              </a>
            </Grid>

            <Grid item lg={1} md={1} sm={6} xs={12}>
              <Typography
                variant="h4"
                component="h4"
                className={classes.footerLinksHeading}
              >
                SOCIAL
              </Typography>
              <a
                className={classes.footerNavLinks}
                href="https://twitter.com/_collegeadvisor"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
              <a
                className={classes.footerNavLinks}
                href="https://www.youtube.com/channel/UCXAXoQUE8N-kS6h-cjf4DUg"
                target="_blank"
                rel="noopener noreferrer"
              >
                Youtube
              </a>
              <a
                className={classes.footerNavLinks}
                href="http://linkedin.com/company/joincollegeadvisor"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>
              <a
                className={classes.footerNavLinks}
                href="https://facebook.com/joincollegeadvisor"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
              <a
                className={classes.footerNavLinks}
                href="http://instagram.com/joincollegeadvisor"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </Grid>
          </Grid>
        </Hidden>

        <Hidden only={['xl', 'lg', 'md']}>
          <Grid container>
            <Grid
              item
              lg={3}
              md={3}
              sm={6}
              xs={12}
              className={classes.footerMobilePadding}
            >
              <Typography
                variant="h4"
                component="h4"
                className={classes.footerLinksHeading}
              >
                HELP
              </Typography>
              <a
                className={classes.footerNavLinks}
                href="javascript:void(0)"
                onClick={() => {
                  if (process.env.REACT_APP_ACTIVATE_ANALYTICS)
                    window.Intercom('show');
                }}
              >
                Contact
              </a>
              <a
                className={classes.footerNavLinks}
                href="https://collegeadvisor.com/pricing#faq"
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQ
              </a>
            </Grid>

            <Grid
              item
              lg={3}
              md={3}
              sm={6}
              xs={12}
              className={classes.footerMobilePadding}
            >
              <Typography
                variant="h4"
                component="h4"
                className={classes.footerLinksHeading}
              >
                LEGAL
              </Typography>
              <a
                href="https://www.collegeadvisor.com/terms-and-conditions/"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.footerNavLinks}
              >
                Terms and Conditions
              </a>

              <a
                className={classes.footerNavLinks}
                href="https://www.collegeadvisor.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              <a
                href="https://www.collegeadvisor.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.footerNavLinks}
              >
                Cookie Notice
              </a>
              <a
                href="https://www.collegeadvisor.com/privacy-ccpa/"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.footerNavLinks}
              >
                California Privacy Notice
              </a>
            </Grid>

            <Grid
              item
              lg={1}
              md={1}
              sm={6}
              xs={12}
              className={classes.footerMobilePadding}
            >
              <Typography
                variant="h4"
                component="h4"
                className={classes.footerLinksHeading}
              >
                SOCIAL
              </Typography>
              <a
                className={classes.footerNavLinks}
                href="https://twitter.com/_collegeadvisor"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
              <a
                className={classes.footerNavLinks}
                href="https://www.youtube.com/channel/UCXAXoQUE8N-kS6h-cjf4DUg"
                target="_blank"
                rel="noopener noreferrer"
              >
                Youtube
              </a>
              <a
                className={classes.footerNavLinks}
                href="http://linkedin.com/company/joincollegeadvisor"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>
              <a
                className={classes.footerNavLinks}
                href="https://facebook.com/joincollegeadvisor"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
              <a
                className={classes.footerNavLinks}
                href="http://instagram.com/joincollegeadvisor"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </Grid>

            <Grid
              item
              lg={5}
              md={5}
              sm={12}
              xs={12}
              className={classes.footerLogoMobile}
            >
              <Box maxWidth={250}>
                <img
                  src={LOGO}
                  className={classes.footerLogo}
                  alt="footer main logo"
                />

                <Typography
                  variant="body1"
                  component="p"
                  className={classes.footerCopyRight}
                >
                  &copy; {CURRENT_YEAR} USN College Advisor, Inc.
                  <br />
                  All Rights Reserved
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Hidden>
      </Container>
    </Grid>
  );
};

export default FooterNew;
