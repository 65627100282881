import { FC } from 'react';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
} from '@material-ui/core';

import { useStyles } from './StyleActiveIncentives';
import { INCENTIVE_BREAKDOWN_DATA } from '../../constants';

const IncentiveBreakdownTable: FC = () => {
  const classes = useStyles();

  return (
    <Table className={classes.incentiveBreakdownTable}>
      <TableHead>
        <TableRow>
          <TableCell width="30%"></TableCell>
          <TableCell width="70%"></TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {INCENTIVE_BREAKDOWN_DATA.map((item) => {
          const { heading, description, subHeading } = item;
          return (
            <TableRow>
              <TableCell>
                {heading}
                {subHeading && (
                  <>
                    <br />
                    {subHeading}
                  </>
                )}
              </TableCell>
              <TableCell>{description}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default IncentiveBreakdownTable;
