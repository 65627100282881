import { useState } from 'react';
import Header from '../../../components/layout/Header';
import SummerOpportunitiesSubmission from './SummerOpportunitiesSubmission';
import SummerOpportunitiesThankYou from './SummerOpportunitiesThankYou';

const SubmitSummerOppotunity = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  return (
    <>
      <Header />
      {!isSubmitted ? (
        <SummerOpportunitiesSubmission setIsSubmitted={setIsSubmitted} />
      ) : (
        <SummerOpportunitiesThankYou />
      )}
    </>
  );
};

export default SubmitSummerOppotunity;
