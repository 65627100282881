import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import moment from 'moment';

import fonts from '../../../common/fonts';

import { IPayoutHistory } from '../../../common/interfaces';
import { useStylesTable } from '../timeLogs/tableCommonStyle';
import DownloadPayoutsHistoryInCsv from './DownloadPayoutsHistoryInCsv';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    payoutHistoryTable: {
      '& .MuiTypography-h5': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '-0.019em',
        color: '#272929',
      },

      '& .MuiButton-text': {
        fontFamily: fonts.POPPINS_FONT,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#2F80ED',
        fontWeight: 'normal',
        textTransform: 'capitalize',
        padding: '8px 15px',

        '& img': {
          marginRight: 12,
        },
      },
    },
  })
);

const PayoutHistory = ({ advisorPaymentHistory }: IPayoutHistory) => {
  const classes = useStyles();

  const tableClasses = useStylesTable();

  return (
    <>
      <Grid className={classes.payoutHistoryTable}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pb={2}
        >
          <Typography variant="h5">Payout History</Typography>

          {advisorPaymentHistory?.length ? (
            <Box>
              <DownloadPayoutsHistoryInCsv
                label="Download CSV"
                payouts={advisorPaymentHistory}
              />
            </Box>
          ) : (
            <></>
          )}
        </Box>

        <Grid className={tableClasses.commonTable}>
          <Grid className="payoutScrollbar">
            <Table>
              <TableBody>
                {advisorPaymentHistory?.map((item: any) => {
                  return (
                    <TableRow>
                      <TableCell>
                        {item?.paidAt &&
                          `${moment
                            .parseZone(
                              item?.paidAt &&
                                new Date(item?.paidAt * 1000).toString()
                            )
                            .local()
                            .format('MMMM DD, YYYY')}`}
                      </TableCell>
                      <TableCell>
                        ${item?.amount && (item?.amount / 100).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PayoutHistory;
