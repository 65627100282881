import { ChangeEvent, FC, useState } from 'react';
import clsx from 'clsx';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Grid,
  Typography,
  FormControl,
  MenuItem,
  Select,
  IconButton,
  Checkbox,
} from '@material-ui/core';

import SearchColleges from '../../../components/SearchColleges';
import { OptionType } from '../../../components/multiSelect';
import {
  getAllpreviouslyWorkedCollegesQuery_previouslyWorkedColleges,
  getAllpreviouslyWorkedCollegesQuery_previouslyWorkedColleges_college,
} from './__generated__/getAllpreviouslyWorkedCollegesQuery';

import { useStylesTable } from './tableCommonStyle';

import EDIT_ICON from '../../../img/table-edit.svg';
import DELETE_ICON from '../../../img/table-delete.svg';
import CHECK_ICON from '../../../img/table-check.svg';
import CROSS_ICON from '../../../img/table-cross.svg';

// updated interface for new props addition
interface Props {
  WorkedAt: (getAllpreviouslyWorkedCollegesQuery_previouslyWorkedColleges | null)[];
  PreviousCollegeThemes: (JSX.Element)[];
  handleEdit: Function;
  handleDelete: Function;
}
interface TableRowProps {
  college: getAllpreviouslyWorkedCollegesQuery_previouslyWorkedColleges_college | null;
  Title: string;
  id: number | null;
  index: number;
  current: boolean | null;
  currentWorkingAt: (getAllpreviouslyWorkedCollegesQuery_previouslyWorkedColleges | null)[];
}

// updated new props in the function signature
const ListPreviouslyWorkedAt: FC<Props> = ({
  WorkedAt,
  PreviousCollegeThemes,
  handleEdit,
  handleDelete,
}) => {
  const tableClasses = useStylesTable();

  const PreviouslyWorkedRow: FC<TableRowProps> = ({
    id,
    college,
    Title,
    index,
    current,
    currentWorkingAt,
  }) => {
    const [collegeWorkedAt, setCollegeWorkedAt] = useState<OptionType>({
      label: college ? college.name : '',
      value: college ? college.id.toString() : '',
    });
    const [title, setTitle] = useState(Title);
    const [currentlyWorking, setCurrentlyWorking] = useState(current || false);
    const [editButton, setEditButton] = useState(false);
    const advisorTitles = [
      'Director of Admissions',
      'Assistant Director of Admissions',
      'Director of Financial Aid',
      'Former Admissions Officer',
      'Associate Director of Admissions',
      'Senior Assistant Dean of Admissions',
      'Associate Dean of Admissions',
      'Senior Associate Director of Admissions',
      'Senior Assistant Director of Admissions',
      'Graduate Admissions Officer',
      'Graduate Specialty Advisor',
    ];
    return (
      <TableRow key={id} className={tableClasses.addmissionOfficer}>
        <TableCell>{index}</TableCell>
        <TableCell className={tableClasses.commonSelectMenu}>
          <FormControl fullWidth>
            <Select
              disabled={!editButton}
              value={title}
              defaultValue={title}
              onChange={(e: ChangeEvent<{ value: unknown }>) => {
                setTitle(e.target.value as string);
                setEditButton(true);
              }}
              name="Title"
              inputProps={{
                name: 'Title',
                id: 'type-required',
              }}
              variant="outlined"
            >
              {advisorTitles.map((title) => (
                <MenuItem key={title} value={title}>
                  {title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell className={tableClasses.asyncSelectStyle}>
          <SearchColleges
            isMulti={false}
            label=""
            disabled={!editButton}
            values={
              collegeWorkedAt || {
                label: college ? college.name : '',
                value: college ? college.id.toString() : '',
              }
            }
            onSelect={(selected: OptionType) => {
              setCollegeWorkedAt(selected);
              setEditButton(true);
            }}
          />
        </TableCell>
        <TableCell className="tableSelectStyle">
          <Grid className={tableClasses.collegeNameSelect}>
            <Checkbox
              checked={currentlyWorking}
              disabled={!editButton}
              onChange={(e) => {
                setCurrentlyWorking(e.target.checked);
              }}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Grid>
        </TableCell>
        {/* table cell for theme radio button */}
        <TableCell>
          {
            PreviousCollegeThemes[index-1]
          }
        </TableCell>
        <TableCell>
          {editButton ? (
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              className={tableClasses.saveTextActions}
              width={200}
              marginLeft={-5}
            >
              <Typography variant="body2" className={tableClasses.saveText}>
                Save?
              </Typography>

              <IconButton
                onClick={() => {
                  handleEdit(
                    parseInt(collegeWorkedAt?.value || ''),
                    title,
                    currentlyWorking,
                    id
                  );
                }}
              >
                <img src={CHECK_ICON} alt="edit" />
              </IconButton>

              <IconButton
                onClick={() => {
                  setCurrentlyWorking(current || false);
                  setEditButton(false);
                }}
              >
                <img src={CROSS_ICON} alt="delete" />
              </IconButton>
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="flex-end"
              width={150}
              marginLeft={-5}
            >
              <IconButton
                onClick={() => {
                  setEditButton(true);
                }}
              >
                <img src={EDIT_ICON} alt="edit" />
              </IconButton>
              <IconButton onClick={() => handleDelete(id || 0)}>
                <img src={DELETE_ICON} alt="delete" />
              </IconButton>
            </Box>
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Box>
      <Grid className={tableClasses.formerAdmissionWrapper}>
        <Grid className={tableClasses.formerAdmissionOfficer}>
          <Typography variant="h6" className="educationHeading">
            Former Admissions Officer Roles
          </Typography>

          <Grid
            className={clsx(
              tableClasses.commonTable,
              tableClasses.commonTableEntitlements
            )}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="10%">Id</TableCell>
                  <TableCell width="30%">Title</TableCell>
                  <TableCell width="30%">College</TableCell>
                  <TableCell width="10%">Current</TableCell>
                  {/* added theme column */}
                  <TableCell width="30%">Theme</TableCell>
                  <TableCell width="30%"></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {WorkedAt &&
                  WorkedAt?.map((row, index) => {
                    if (!row) return null;
                    const { Title, college, id, CurrentlyWorking } = row;
                    const currentWorkingAt = WorkedAt.filter(
                      (item) => item?.CurrentlyWorking === true
                    );

                    return (
                      <PreviouslyWorkedRow
                        key={`${Title}-${college}`}
                        id={id}
                        index={index + 1}
                        Title={Title || ''}
                        college={college}
                        current={CurrentlyWorking}
                        currentWorkingAt={currentWorkingAt}
                      />
                    );
                  })}

                {WorkedAt.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} className="notFoundCell">
                      <Box className={tableClasses.emptyStates}>
                        <Typography variant="body1">
                          No Former Admissions Officer Roles found
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ListPreviouslyWorkedAt;
