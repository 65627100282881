import { createStyles, makeStyles, Theme } from '@material-ui/core';

import fonts from '../../../common/fonts';
import Colors from '../../../common/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    savedOpportunity: {
      paddingTop: 40,

      '& .MuiTypography-h5': {
        fontWeight: 600,
        letterSpacing: '-0.019em',
        color: Colors.BLACK_TWELVE,
        fontFamily: fonts.POPPINS_FONT,
      },

      '& p.savedOpportunitySubtitle': {
        fontSize: 14,
        fontWeight: 400,
        margin: '13px 0px 47px',
        letterSpacing: '-0.006em',
        color: Colors.BLACK_FOURTEEN,
        fontFamily: fonts.INTER,

        '& a': {
          fontWeight: 500,
          color: Colors.BLUE_SEVEN,
          borderBottom: `1px solid ${Colors.BLUE_SEVEN}`,
        },
      },
    },

    btnViewAll: {
      display: 'flex',
      justifyContent: 'center',

      '& .MuiButton-root': {
        borderRadius: 12,
        fontWeight: 'bold',
        fontSize: 14,
        boxShadow: 'none',
        minWidth: 220,
        height: 40,
        letterSpacing: '-0.006em',
        textTransform: 'capitalize',
        color: `${Colors.WHITE_ONE}`,
        fontFamily: fonts.POPPINS_FONT,
        background: `${Colors.BLUE_ALPHA}`,
      },
    },
  })
);
