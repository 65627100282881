import { Theme, makeStyles, createStyles } from '@material-ui/core';
import SEARCH_LIGHT_IMG from '../../../img/search-light.svg';
import fonts from '../../../common/fonts';
import Colors from '../../../common/colors';
import styled from 'styled-components';

export const useStyles = makeStyles((theme: Theme) => ({
  addNewEssayDialog: {
    '& .MuiDialog-paper': {
      maxWidth: 551,
      width: '100%',
      borderRadius: 25,
      margin: '0px 10px',
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

      [theme.breakpoints.down('xs')]: {
        margin: '0px 10px !important',
        borderRadius: 10,
      },

      '& .MuiDialogContent-root': {
        padding: '31px 34px 37px',

        [theme.breakpoints.down('xs')]: {
          padding: '20px 16px 45px',
        },
      },

      '& .MuiTypography-h4': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '-0.019em',
        color: '#000000',
      },

      '& .MuiTypography-body1': {
        fontFamily: fonts.INTER,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#A9A9A9',
        margin: '7px 0px 21px',
        maxWidth: 400,
      },

      '& .MuiInputBase-root': {
        padding: '11px 16px',
      },

      '& .fieldCaption': {
        fontFamily: fonts.INTER,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        display: 'block',
        marginBottom: 4,
      },
    },
  },
}));

export const addCollegeFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      fontFamily: fonts.POPPINS_FONT,
      backgroundColor: `${Colors.BLUE_SEVEN} !important`,
      fontWeight: 'bold',
      fontSize: '12px',
      height: '22px',
      position: 'absolute',
      right: '11px',
      top: '11px',
      color: Colors.WHITE_ONE,
      letterSpacing: '0.3375px',
      padding: '0px 12px',
      boxShadow: 'none',
    },

    addCollegeCard: {
      '& .Mui-disabled': {
        color: Colors.GRAY_TWELVE,
        backgroundColor: `${Colors.GRAY_EIGHT} !important`,
      },
    },
  })
);

export const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: `${Colors.BLACK_THREE}`,
    boxShadow: `0px 2px 10px ${Colors.BOX_SHADOW_THREE}`,
    borderRadius: '3px',
    padding: '10px',
    fontFamily: `${fonts.POPPINS_FONT}`,
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.3375px',
    color: `${Colors.WHITE_ONE}`,
  },
}));

export const addCollegeStyles = makeStyles((theme: Theme) =>
  createStyles({
    similarSchoolCard: {
      background: `${Colors.WHITE_ONE} !important`,
      position: 'relative',
      border: '1px solid #D4D4D4',
      borderRadius: 25,
      maxWidth: 298,
      marginBottom: 22,
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
      },
    },

    similarSchoolCardFooter: {
      background: 'rgba(244, 244, 244, 0.5)',
      borderRadius: '0px 0px 24px 24px',
      padding: '11px 17px 16px',
      boxShadow: 'none',
      position: 'relative',
      minHeight: 132,

      '& .MuiTypography-h5, .MuiTypography-h6, .MuiTypography-body1': {
        fontFamily: fonts.INTER,
      },

      '& .MuiTypography-h6': {
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#272929',
        margin: 0,
      },

      '& .MuiTypography-body1': {
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#525454',
        fontWeight: 400,
        textTransform: 'capitalize',
      },
    },

    cardTop: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 4,
      padding: '10px 0px',
      minHeight: 148,
    },

    cardImage: {
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      width: 100,
      height: 100,
    },

    avatarGrade: {
      width: 37,
      height: 37,
      borderRadius: '50%',
      background: '#45CE93',
      fontWeight: 'bold',
      fontSize: 20,
      color: Colors.WHITE_ONE,
      fontFamily: fonts.POPPINS_FONT,
      position: 'absolute',
      bottom: 18,
      right: 18,
    },
  })
);

export const customDrawerStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerPadding: {
      padding: '0px 119px',
      height: '630px',

      [theme.breakpoints.down('sm')]: {
        padding: '49px 27px 50px',
        height: 570,
      },

      [theme.breakpoints.down('xs')]: {
        padding: '49px 16px 50px',
      },
    },

    documentDrawer: {
      '& .MuiDrawer-paper': {
        border: '1px solid #D4D4D4',
        borderRadius: '50px 50px 0px 0px',
        boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

        [theme.breakpoints.down('sm')]: {
          borderRadius: '25px 25px 0px 0px',
        },
      },
    },
  })
);

export const allCollegesStyles = makeStyles((theme: Theme) =>
  createStyles({
    genericHeaderComponent: {
      padding: '65px 0px 53px',

      [theme.breakpoints.down('sm')]: {
        padding: '0px 0px 36px',
      },

      '& .MuiTypography-h3': {
        fontWeight: 'bold',
        fontSize: 36,
        color: '#272929',
        letterSpacing: '-0.022em',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .drawerHeader': {
        marginBottom: 53,

        [theme.breakpoints.down('sm')]: {
          marginBottom: 24,
        },
      },

      '& .drawerSearch': {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },

        '& input': {
          width: 440,
          fontWeight: 400,
          fontSize: 14,
          color: '#525454',
          height: 40,
          borderRadius: 6,
          boxSizing: 'border-box',
          letterSpacing: '-0.006em',
          padding: '11px 12px 11px 40px',
          backgroundRepeat: 'no-repeat',
          border: '1px solid #d4d4d4',
          backgroundPosition: 'center left 12px',
          backgroundImage: `url(${SEARCH_LIGHT_IMG})`,

          [theme.breakpoints.down('xs')]: {
            width: '100%',
          },

          '&::placeholder': {
            opacity: 1,
            color: '#A9A9A9',
            fontFamily: fonts.INTER,
          },

          '& .MuiOutlinedInput-root': {
            borderRadius: 6,
          },
        },

        '& .MuiFormControl-root': {
          [theme.breakpoints.down('xs')]: {
            width: '100%',
          },
        },
      },

      '& .drawerHeadingText': {
        fontWeight: 'bold',
        fontSize: 36,
        color: '#272929',
        letterSpacing: '-0.022em',
        fontFamily: fonts.POPPINS_FONT,

        [theme.breakpoints.down('sm')]: {
          fontSize: 24,
          fontWeight: 700,
        },
      },
    },
  })
);

export const otherCollegesStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: 0,
      width: '100%',

      '& a': {
        fontFamily: fonts.POPPINS_FONT,
      },
    },

    collegeIcon: {
      margin: '0px',
    },

    acceptedBadge: {
      width: 42,
      height: 42,
      backgroundColor: Colors.WHITE_ONE,
      border: `1px solid ${Colors.GRAY_NINE}`,
      borderRadius: '50px',
      padding: '0px',
      margin: '0px 15px 0px 0px',
      lineHeight: '39px',
      textAlign: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 30,
      backgroundPosition: 'center',
    },
  })
);

export const CollegeCategory = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0px;
  margin-right: 23px;

  @media only screen and (max-width: 767px) {
    flex: 1;
  }

  &:last-child {
    border-bottom: none;
  }

  svg {
    width: 27px !important;
    height: 27px !important;

    & path {
      fill: #e0e0e0;
    }
  }

  .Mui-checked {
    svg {
      & path {
        fill: #2f80ed;
      }
    }
  }

  label {
    margin: 0px;
    font-size: 16px;
    letter-spacing: 0.3375px;
    color: ${Colors.BLACK_ONE};
    text-align: left;
    line-height: 23px;
  }

  .MuiSvgIcon-root {
    font-size: 27px;
  }

  .MuiCheckbox-root {
    color: ${Colors.GRAY_NINE};
    margin-left: -12px;
  }

  .MuiCheckbox-colorPrimary.Mui-checked {
    color: ${Colors.BLUE_SEVEN};
  }
`;

export const CheckColleges = styled.div``;

export const UniversityAdd = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Colors.GRAY_EIGHT};
  padding-left: 15px;

  .addCollegesButton {
    visibility: hidden;
  }

  &:hover {
    .addCollegesButton {
      visibility: visible;
    }
  }

  @media only screen and (max-width: 767px) {
    button {
      padding: 0px !important;
      margin: 0;
      height: 32px;
      width: 32px;
      min-width: auto;

      .button-text {
        display: none;
      }

      img {
        margin: 0 !important;
      }
    }
  }
`;

export const popularCollegesStyles = makeStyles(() =>
  createStyles({
    searchHeading: {
      fontSize: '30px',
      lineHeight: '43px',
      letterSpacing: '-2px',
      color: Colors.BLUE_SEVEN,
      marginBottom: '24px',
      marginTop: '24px',
      fontFamily: fonts.POPPINS_FONT,
    },
  })
);
