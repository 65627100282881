import { useEffect, useState } from 'react';
import BookMeetings from './BookMeetings';
import MeetingBooked, { participants } from './MeetingBooked';
import { AllMentorStudentsListQuery_allMentorStudentsList_students_student_firstMeetingBookedDate } from '../__generated__/AllMentorStudentsListQuery';
import { StudentDetailQuery_Student_allStudentMentors } from '../__generated__/StudentDetailQuery';

interface meetingInfo {
  start_time: number;
  end_time: number;
  advisors: participants[];
  studentName: string;
  note: string;
}
export interface IBoookingStudent {
  id: number;
  firstName: string | null;
  lastName: string | null;
  email: string;
  premiumTotalVideoCount: number | null;
  premiumVideoCountUsed: number | null;
  initialKickOffDate: any | null;
  firstMeetingBookedDate: AllMentorStudentsListQuery_allMentorStudentsList_students_student_firstMeetingBookedDate | null;
  allStudentMentors?:
    | (StudentDetailQuery_Student_allStudentMentors | null)[]
    | null;
}
const AdvisorBookingModal = ({
  openCalendar,
  setOpenCalendar,
  advisorId,
  refetch,
  student,
}: {
  openCalendar: boolean;
  setOpenCalendar: Function;
  refetch?: Function;
  advisorId: number;
  student: IBoookingStudent;
}) => {
  const [openMeetingInfo, setOpenMeetingInfo] = useState(false);
  const [meetingInfo, setMeetingInfo] = useState<meetingInfo>({
    start_time: 0,
    end_time: 0,
    advisors: [],
    studentName: '',
    note: '',
  });
  const mountEffect = () => {
    if (openMeetingInfo) refetch && refetch();
  };
  useEffect(mountEffect, [openMeetingInfo]);

  return (
    <>
      {advisorId && (
        <BookMeetings
          openCalendar={openCalendar}
          setOpenCalendar={setOpenCalendar}
          setOpenMeetingInfo={setOpenMeetingInfo}
          setMeetingInfo={setMeetingInfo}
          advisorId={advisorId}
          student={student}
        />
      )}

      <MeetingBooked
        openMeetingInfo={openMeetingInfo}
        setOpenMeetingInfo={setOpenMeetingInfo}
        meetingInfo={meetingInfo}
      />
    </>
  );
};

export default AdvisorBookingModal;
