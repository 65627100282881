import gql from 'graphql-tag';
import { ApolloConsumer } from 'react-apollo';
import AsyncSelect from 'react-select/async';
import { Option } from 'react-select/src/filters';

const FETCH_ADVISORS = gql`
  query AllMentorsQuery(
    $searchQuery: String
    $excludeMentors: [Int]
    $allowInvisibleMentors: Boolean
    $isMBA: Boolean
  ) {
    allMentors(
      input: {
        searchQuery: $searchQuery
        excludeMentors: $excludeMentors
        isMBA: $isMBA
        allowInvisibleMentors: $allowInvisibleMentors
      }
    ) {
      mentors {
        id
        firstName
        lastName
        email
        pictureUrl
      }
    }
  }
`;

interface IOtherOptions {
  pictureUrl: boolean;
}

const AsyncAdvisorSelect = ({
  client,
  onChange: _onChange,
  value: _value,
  currentAdvisor,
  allowInvisibleMentors,
  isMulti,
  placeholder,
  otherOptions,
  isClearable,
}: {
  client: any;
  onChange?: Function;
  value?: Option[] | [];
  currentAdvisor?: number;
  allowInvisibleMentors?: boolean;
  isMulti: boolean;
  placeholder: string;
  otherOptions?: IOtherOptions;
  isClearable?: boolean;
}) => {
  const loadOptions: any = (
    inputValue: string,
    otherOptions?: IOtherOptions
  ) => {
    return new Promise((resolve) => {
      // API call
      client
        .query({
          query: FETCH_ADVISORS,
          variables: {
            allowInvisibleMentors,
            searchQuery: inputValue,
            excludeMentors: [currentAdvisor || null],
            isMBA: true,
          },
        })
        .then((res: any) => {
          if (res.data && res.data.allMentors && res.data.allMentors.mentors) {
            const options: Option[] = res.data.allMentors.mentors.map(
              (item: any) => ({
                value: `${item.id}`,
                label: `${item.firstName || ''} ${item.lastName || ''}\n(${
                  item.email
                })`,
                ...(otherOptions?.pictureUrl && {
                  pictureUrl: item?.pictureUrl || '',
                }),
              })
            );
            resolve(options);
          } else {
            resolve([]);
          }
        });
    });
  };

  const handleInputChange = (newValue: string) => {
    const inputValue = newValue.replace(/[^\w\s]/g, '');
    return inputValue;
  };

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={(e) => loadOptions(e, otherOptions)}
      isClearable={isClearable ?? false}
      isMulti={isMulti}
      defaultOptions
      value={_value}
      onChange={(selected) => {
        if (_onChange) {
          _onChange(selected);
        }
      }}
      onInputChange={handleInputChange}
      classNamePrefix={'react-select'}
      placeholder={placeholder}
    />
  );
};

const AdvisorSelect = ({
  onChange,
  value,
  advisorId,
  allowInvisibleMentors,
  isMulti = true,
  placeholder = 'Select...',
  otherOptions,
  isClearable = false,
}: {
  onChange?: Function;
  value?: Option[] | [];
  advisorId?: number;
  allowInvisibleMentors?: boolean;
  isMulti?: boolean;
  placeholder?: string;
  otherOptions?: IOtherOptions;
  isClearable?: boolean;
}): JSX.Element => {
  return (
    <ApolloConsumer>
      {(client: any) => (
        <AsyncAdvisorSelect
          client={client}
          onChange={onChange}
          value={value}
          currentAdvisor={advisorId}
          allowInvisibleMentors={allowInvisibleMentors}
          isMulti={isMulti}
          placeholder={placeholder}
          otherOptions={otherOptions}
          isClearable={isClearable}
        />
      )}
    </ApolloConsumer>
  );
};

export default AdvisorSelect;
