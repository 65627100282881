import { Box, Grid, Typography } from '@material-ui/core';

import { useStyles } from './SummerOpportunityStyle';

import CHECKED_ICON from '../../../img/checked-icon.svg';
import { Link } from 'react-router-dom';

const SummerOpportunitiesThankYou = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.thankYouCard}>
      <Box display="flex" alignItems="flex-start">
        <img src={CHECKED_ICON} alt="checked" />

        <Box className="contentPadding">
          <Typography variant="h3">
            Thank You! Your Opportunity has been submitted.
          </Typography>
          <Typography variant="body1">
            Your entry will be reviewed by an admin before it is displayed on
            the Student Portal.
          </Typography>
          <Link to="/">RETURN TO HOME</Link>
        </Box>
      </Box>
    </Grid>
  );
};

export default SummerOpportunitiesThankYou;
