import { useState } from 'react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import {
  Box,
  Button,
  ListItem,
  Popover,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  ListItemSecondaryAction,
  Avatar,
  Divider,
  List,
  ListItemAvatar,
  Grid,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import Caution_Img from '../../../img/caution-meeting.svg';

import client from '../../../apollo';
import {
  CREATE_TIME_SLOT,
  DELETE_TIME_SLOT_SERIES,
  TIME_SLOT_MEETINGS,
} from './gql';
import {
  getBookingsForTimeSlotQuery,
  getBookingsForTimeSlotQuery_getBookingsForTimeSlot_bookings,
} from './gql/__generated__/getBookingsForTimeSlotQuery';
import { useStyles } from './styles';

interface slotInterFace {
  day: string;
  deleted: true;
  endDate: Date;
  endTime: string;
  id: number;
  recurring: boolean;
  recurringLastDate: Date;
  startDate: Date;
  startTime: string;
}
const DeleteSeriesPopover = ({
  day,
  refetch,
  refetchAdvisorMonthlySlots,
  setAnchorEl,
  anchorEl,
  slot,
  handlePopUpNotification,
}: {
  day: string;
  refetch?: Function;
  refetchAdvisorMonthlySlots: Function;
  setAnchorEl: Function;
  anchorEl: HTMLButtonElement | HTMLLIElement | null;
  slot: [slotInterFace];
  handlePopUpNotification: Function;
}) => {
  const classes = useStyles();
  const [wait, setWait] = useState(false);
  const [loadingMeetings, setLoadingMeetings] = useState(false);
  const [showMeetingsPopOver, setShowMeetingsPopOver] = useState(false);
  const [radioValue, setRadioValue] = useState('');
  const [meetingsToBeDeleted, setMeetingsToBeDeleted] = useState<
    | (getBookingsForTimeSlotQuery_getBookingsForTimeSlot_bookings | null)[]
    | null
  >(null);
  const openPopover = Boolean(anchorEl);

  const handleTimeSlotSeriesDelete = async (
    recurringLastDate: Date,
    day: string,
    deleteBookings: boolean
  ) => {
    meetingsToBeDeleted?.length && setWait(true);
    try {
      await client.mutate({
        variables: {
          startDate: moment(day).subtract(1, 'day').toDate(),
          recurringLastDate,
          deleteBookings,
        },
        mutation: DELETE_TIME_SLOT_SERIES,
      });
      setAnchorEl(null);
      await refetchAdvisorMonthlySlots();
      refetch && refetch();
      setWait(false);
      setLoadingMeetings(false);
      handlePopUpNotification('Availability Series Deleted');
    } catch (error) {
      console.log(error);
    }
  };
  const handleClosePopOver = () => {
    setAnchorEl(null);
    setMeetingsToBeDeleted(null);
    setShowMeetingsPopOver(false);
    setLoadingMeetings(false);
    setRadioValue('');
  };

  const handleChangeRadioValue = (event: any) => {
    setRadioValue(event.target.value);
  };

  const handleTimeSlotDelete = async (item: any, day: any) => {
    meetingsToBeDeleted?.length && setWait(true);
    delete item.MentorId;
    delete item.__typename;
    delete item.startTime;
    delete item.endTime;
    delete item.recurring;
    delete item.timeSlotBookings;
    delete item.recurringLastDate;
    try {
      await client.mutate({
        variables: {
          timeSlots: [{ ...item, deleted: true }],
          meetingsToBeDelete: meetingsToBeDeleted?.length
            ? meetingsToBeDeleted?.map((item) => item?.id)
            : null,
        },
        mutation: CREATE_TIME_SLOT,
      });
      setAnchorEl(null);
      await refetchAdvisorMonthlySlots();
      refetch && refetch();
      setWait(false);
      setLoadingMeetings(false);
      handlePopUpNotification('Availability Deleted');
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTimeslotBookings = async (timeSlotId: number) => {
    setLoadingMeetings(true);
    const {
      data,
    }: {
      data: getBookingsForTimeSlotQuery;
    } = await client.query({
      query: TIME_SLOT_MEETINGS,
      variables: {
        timeSlotId,
        series: radioValue === 'series' ? true : false,
      },
      fetchPolicy: 'network-only',
    });
    if (data?.getBookingsForTimeSlot?.bookings?.length) {
      setMeetingsToBeDeleted(data?.getBookingsForTimeSlot?.bookings);
      setShowMeetingsPopOver(true);
      setLoadingMeetings(false);
    } else {
      setMeetingsToBeDeleted(null);
      radioValue === 'series'
        ? handleTimeSlotSeriesDelete(slot[0].recurringLastDate, day, true)
        : handleTimeSlotDelete(slot[0], day);
    }
  };

  return (
    <Popover
      id="delete-popover"
      open={openPopover}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {showMeetingsPopOver && (
        <Box className={classes.popOverBoxListing}>
          {meetingsToBeDeleted?.length ? (
            <>
              <Typography variant="h5">
                <img src={Caution_Img} alt="caution" />
                MEETING CONFLICT
              </Typography>
              <Typography variant="body1">
                You have {meetingsToBeDeleted?.length} meetings booked within
                this series.
              </Typography>
              <Divider />
              <List>
                {meetingsToBeDeleted.map((item) => {
                  const startDate = item?.startTime
                    ? moment.unix(item?.startTime).format('MM/DD/YYYY')
                    : 'N/A';
                  const startTime = item?.startTime
                    ? moment.unix(item?.startTime).format('LT')
                    : 'NA';
                  const endTime = item?.endTime
                    ? moment.unix(item?.endTime).format('LT')
                    : 'NA';

                  return (
                    <ListItem>
                      <ListItemAvatar>
                        {item?.student?.pictureUrl ? (
                          <Avatar>
                            <img src={item?.student?.pictureUrl} alt="avatar" />
                          </Avatar>
                        ) : (
                          <PersonIcon />
                        )}
                      </ListItemAvatar>
                      <ListItemText>
                        {item?.student?.firstName || ''}{' '}
                        {item?.student?.lastName || ''}
                      </ListItemText>
                      <ListItemSecondaryAction>
                        <ListItemText>
                          {`${startDate}, ${startTime} - ${endTime} ${momentTz
                            .tz(momentTz.tz.guess())
                            .format('z')}`}
                        </ListItemText>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
              <Divider />
            </>
          ) : (
            <Grid className={classes.confirmMessage}>
              <Typography variant="h5">CONFIRM MEETING DELETE?</Typography>
              <Typography variant="body1">
                This action cannot be undone.
              </Typography>
            </Grid>
          )}

          <Box textAlign="right">
            <Button variant="outlined" onClick={handleClosePopOver}>
              Keep All
            </Button>

            <Button
              variant="contained"
              disabled={wait}
              onClick={() =>
                radioValue === 'series'
                  ? handleTimeSlotSeriesDelete(
                      slot[0].recurringLastDate,
                      day,
                      true
                    )
                  : handleTimeSlotDelete(slot[0], day)
              }
            >
              {!wait &&
                `Cancel Meeting${
                  meetingsToBeDeleted && meetingsToBeDeleted?.length > 1
                    ? 's'
                    : ''
                } & Availability`}
              {wait && (
                <>
                  {'Canceling...'}
                  <CircularProgress color="inherit" size={17} />
                </>
              )}
            </Button>
          </Box>
        </Box>
      )}

      {!showMeetingsPopOver && (
        <Grid className={classes.popOverBox}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography variant="h5">Delete</Typography>

            <ListItem disableGutters>
              {slot && !wait && slot[0].startTime !== undefined && (
                <>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText
                    primary={`${slot[0].startTime} - ${
                      slot[0].endTime
                    } ${momentTz
                      .tz(momentTz.tz.guess())
                      .format('z')}            `}
                  />
                </>
              )}
            </ListItem>
          </Box>

          <RadioGroup value={radioValue} onChange={handleChangeRadioValue}>
            <FormControlLabel
              value="occurrence"
              control={<Radio color="primary" />}
              label="This occurrence"
            />

            <FormControlLabel
              value="series"
              control={<Radio color="primary" />}
              label="This and all future events in this series"
            />
          </RadioGroup>

          <Box textAlign="right" mt={2}>
            <Button variant="outlined" onClick={handleClosePopOver}>
              Cancel
            </Button>

            <Button
              variant="contained"
              disabled={!radioValue || loadingMeetings}
              onClick={() => fetchTimeslotBookings(slot[0]?.id)}
            >
              {!loadingMeetings && 'Delete'}
              {loadingMeetings && (
                <CircularProgress color="inherit" size={17} />
              )}
            </Button>
          </Box>
        </Grid>
      )}
    </Popover>
  );
};

export default DeleteSeriesPopover;
