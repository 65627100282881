import { FC, useContext } from 'react';
import moment from 'moment';
import {
  TableRow,
  TableCell,
  Box,
  IconButton,
  Typography,
} from '@material-ui/core';

import { AppContext } from '../../../../contexts';
import { notesCategories } from '../../../../common/utility';
import { GetStudentNotes_studentNotes_notes } from '../../../../graphql/queries/StudentNotes/__generated__/GetStudentNotes';

import DELETE_ICON from '../../../../img/delete-icon.svg';
import EDIT_ICON from '../../../../img/table-edit.svg';

interface IProps {
  onEdit: Function;
  onDelete: Function;
  noteData: GetStudentNotes_studentNotes_notes | null;
}

const StudentNotesTableRecord: FC<IProps> = ({
  onEdit,
  onDelete,
  noteData,
}) => {
  const { user } = useContext(AppContext);

  const refactorType = notesCategories.find(
    (category) => category.value === noteData?.type
  )?.name;

  const isMeetingNote = noteData?.type === 'timelog_note';
  const advisor = noteData?.advisor;
  const workActivity = noteData?.mentorTimeLog?.workActivity;
  const milestone = noteData?.mentorTimeLog?.milestone;
  const advisorBooking = noteData?.mentorTimeLog?.advisorBooking;
  const allParticipants = advisorBooking?.allParticipants;
  const otherAdvisors = allParticipants?.filter(
    (p) => p?.roles?.includes('Mentor') && p.id != advisor?.id
  );
  const otherAdvisorNames = otherAdvisors
    ?.map((adv) => `${adv?.firstName || ''} ${adv?.lastName || ''}`)
    .join(' and ');
  return (
    <TableRow>
      <TableCell className="textBaseline">
        {moment(noteData?.updatedAt).format('MM/DD/YYYY, LT')}{' '}
        {moment.tz(moment.tz.guess()).format('z')}
      </TableCell>
      <TableCell className="textBaseline textBreakSpaces">
        {refactorType} <br />
        {advisor && `(${advisor.firstName} ${advisor.lastName})`}
      </TableCell>
      <TableCell className="textBaseline">
        <Box whiteSpace="break-spaces" className="description">
          {noteData?.description}
        </Box>

        {isMeetingNote && (
          <Box mt={2} className="notesTypeDate">
            <Typography variant="body2">
              {workActivity} {otherAdvisorNames && `(w/ ${otherAdvisorNames})`}
            </Typography>
            <Typography variant="body2">
              {advisorBooking
                ? `${moment((advisorBooking?.startTime || 0) * 1000).format(
                    'MM/DD/YYYY, LT'
                  )} ${moment((advisorBooking?.startTime || 0) * 1000)
                    .tz(moment.tz.guess())
                    .format('z')}`
                : 'Meeting not linked to time log.'}
            </Typography>
            {milestone && (
              <Typography variant="body2">Milestone: {milestone}</Typography>
            )}
          </Box>
        )}
      </TableCell>
      <TableCell className="textBaseline">
        {advisor?.id === user?.id && !isMeetingNote && (
          <>
            <IconButton
              onClick={() => onEdit(noteData?.id, noteData?.description)}
            >
              <img src={EDIT_ICON} alt="delete" />
            </IconButton>
            <IconButton onClick={() => onDelete(noteData?.id)}>
              <img src={DELETE_ICON} alt="delete" />
            </IconButton>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};
export default StudentNotesTableRecord;
