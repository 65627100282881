import { useEffect, useState, FC } from 'react';
import client from '../../../apollo';
import { Query } from 'react-apollo';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Grid, Box, Typography } from '@material-ui/core';
import colors from '../../../common/colors';
import { useStyles } from './Findstyle';
import {
  SUMMER_OPPORTUNITY_CATEGORIES,
  ALL_OPPORTUNITIES,
} from './BookingMeeting/gql';
import { allSummerOpportunityCategoriesQuery_allSummerOpportunityCategories_summerOpportunityCategories } from './BookingMeeting/gql/__generated__/allSummerOpportunityCategoriesQuery';
import { allSummerOpportunityTypesQuery_allSummerOpportunityTypes_summerOpportunityTypes } from './BookingMeeting/gql/__generated__/allSummerOpportunityTypesQuery';

import Loader from '../../../components/Loader';
import { AllSummerOpportunitiesQuery } from './BookingMeeting/gql/__generated__/AllSummerOpportunitiesQuery';
import ListOpportunities from './ListOpportunities';

type ObjectType = {
  region: (number | null)[];
  type: allSummerOpportunityTypesQuery_allSummerOpportunityTypes_summerOpportunityTypes | null;
  category: allSummerOpportunityCategoriesQuery_allSummerOpportunityCategories_summerOpportunityCategories | null;
  state: (string | null)[];
} | null;

interface OppProps extends RouteComponentProps {
  StudentId: number;
  name: string | null;
}

const FindOpportunities: FC<OppProps> = ({ StudentId, name }) => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [valuesObject, setValuesObject] = useState<ObjectType>(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [count, setCount] = useState<number | null>(0);
  const [length, setLength] = useState<number | null>(0);
  const urlParams = new URLSearchParams(window.location.search);
  const cityFromUrl = urlParams.get('city');
  const stateFromUrl = urlParams.get('state');
  const categoryFromUrl = urlParams.get('category');
  const programCost = urlParams.get('cost');
  const stipendGranted = urlParams.get('stipend');
  const grade = urlParams.get('grade');
  const remote = urlParams.get('remote');
  const metroArea = urlParams.get('metroArea');
  const location = urlParams.get('location');
  const searchQuery = urlParams.get('searchQuery');

  let currentPage = 1;

  const mountEffect = () => {
    const apiCalling = async () => {
      let Cvalue = null,
        Tvalue = null;
      let Svalue: (string | null)[] = [];
      let Rvalue: (number | null)[] = [];
      if (categoryFromUrl) {
        const categoryApiCall = await client.query({
          query: SUMMER_OPPORTUNITY_CATEGORIES,
          variables: { name: categoryFromUrl },
        });
        Cvalue =
          categoryApiCall.data.allSummerOpportunityCategories
            .summerOpportunityCategories[0];
      }
      let obj = {
        region: Rvalue,
        category: Cvalue,
        type: Tvalue,
        state: Svalue,
      };
      if (!Rvalue.length && cityFromUrl) obj = { ...obj, region: [null] };
      JSON.stringify(valuesObject) !== JSON.stringify(obj) &&
        setValuesObject(obj);
    };
    apiCalling();
  };
  useEffect(mountEffect);

  useEffect(() => {
    return () => {
      currentPage = 1;
    };
  }, []);

  let host = '';
  if (window.location.hostname.includes('staging')) {
    host = 'https://staging.app.collegeadvisor.com';
  } else if (window.location.hostname.includes('dev')) {
    host = 'https://dev.app.collegeadvisor.com';
  } else {
    host = 'https://app.collegeadvisor.com';
  }

  return (
    <Grid className={classes.savedOpportunity}>
      <Grid>
        <Typography variant="h5" className="appTitle">
          Saved Opportunities {`(${count})`}
        </Typography>
        <Typography variant="body2" className="savedOpportunitySubtitle">
          Click {name}'s saved opportunities below to open opportunity details.
          Or{' '}
          <a href={`${host}/extracurricular-opportunities?adv=true`}>
            click here to view all extracurricular opportunities.
          </a>
        </Typography>
      </Grid>
      <Grid>
        <Query<AllSummerOpportunitiesQuery>
          query={ALL_OPPORTUNITIES}
          variables={{
            page: currentPage,
            limit: 12,
            state: stateFromUrl,
            metroArea: metroArea,
            city: cityFromUrl,
            category: valuesObject?.category?.name,
            cost: programCost,
            status: 'active',
            stipend: stipendGranted,
            remote: remote === 'true' ? true : null,
            grade: grade,
            searchQuery: searchQuery,
            location: location,
            searchFromSaved: true,
            StudentId: StudentId,
          }}
          fetchPolicy="network-only"
        >
          {({ data, loading, fetchMore }) => {
            const nextRecord = data?.allSummerOpportunities?.nextRecord;
            if (loading && !loadingMore) return <Loader />;
            loader === true && setLoader(false);
            if (
              data &&
              data?.allSummerOpportunities &&
              data?.allSummerOpportunities?.summerOpportunities
            ) {
              setCount(data?.allSummerOpportunities?.summerOpportunitiesCount);
              setLength(
                data?.allSummerOpportunities?.summerOpportunities?.length
              );
            }

            const handleLoadMore = async () => {
              setLoadingMore(true);
              currentPage++;
              await fetchMore({
                variables: {
                  page: currentPage,
                  limit: 12,
                  state: stateFromUrl,
                  metroArea: metroArea,
                  city: cityFromUrl,
                  category: valuesObject?.category?.name,
                  cost: programCost,
                  status: 'active',
                  stipend: stipendGranted,
                  remote: remote === 'true' ? true : null,
                  grade: grade,
                  searchQuery: searchQuery,
                  location: location,
                  searchFromSaved: true,
                },
                updateQuery: (prev: AllSummerOpportunitiesQuery, next: any) => {
                  if (!next) return prev;
                  const { fetchMoreResult } = next;
                  if (
                    !fetchMoreResult ||
                    !fetchMoreResult.allSummerOpportunities ||
                    !fetchMoreResult.allSummerOpportunities
                      .summerOpportunities ||
                    !fetchMoreResult.allSummerOpportunities.summerOpportunities
                      .length
                  )
                    return prev;

                  const { allSummerOpportunities } = prev;
                  const prevResult: any =
                    allSummerOpportunities &&
                    allSummerOpportunities.summerOpportunities;
                  const newResult =
                    fetchMoreResult.allSummerOpportunities.summerOpportunities;
                  fetchMoreResult.allSummerOpportunities.summerOpportunities = [
                    ...prevResult,
                    ...newResult,
                  ];
                  return fetchMoreResult;
                },
              });
              setLoadingMore(false);
            };
            return (
              <>
                {data &&
                  data.allSummerOpportunities &&
                  data.allSummerOpportunities.summerOpportunities &&
                  data.allSummerOpportunities.summerOpportunities.length ? (
                  <>
                    <ListOpportunities
                      opportunities={
                        data.allSummerOpportunities.summerOpportunities
                      }
                      loadMore={handleLoadMore}
                    />
                    {data.allSummerOpportunities.summerOpportunities.length %
                      12 ===
                      0 && (
                        <Box className={classes.btnViewAll}>
                          {nextRecord && loadingMore && (
                            <Box
                              pb={4}
                              fontSize={24}
                              textAlign="center"
                              color={colors.BLUE_SEVEN}
                            >
                              Loading...
                            </Box>
                          )}
                        </Box>
                      )}
                  </>
                ) : (
                  <Box component="h4" textAlign="center">
                    No Record Found
                  </Box>
                )}
              </>
            );
          }}
        </Query>
        {/* )} */}
      </Grid>
    </Grid >
  );
};

export default withRouter(FindOpportunities);
