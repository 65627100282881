import clsx from 'clsx';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { ApolloConsumer, Query } from 'react-apollo';
import { Link, RouteComponentProps } from 'react-router-dom';
import Header from '../../../components/layout/Header';
import Payouts from '../Payouts';

import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Tab,
  Tabs,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import fonts from '../../../common/fonts';
import Loader from '../../../components/Loader';
import PayoutHistory from './PayoutHistory';
import { AdvisorPaymentHistoryQuery } from './__generated__/AdvisorPaymentHistoryQuery';

import client from '../../../apollo';
import colors from '../../../common/colors';
import { TabPanelProps } from '../../../common/interfaces';
import { convertToUSD } from '../../../constants';
import { AppContext } from '../../../contexts';
import { ALL_STUDENTS_INCENTIVE_BONUSES_QUERY_AMOUNT_ONLY } from '../../../graphql/queries/IncentiveBonus';
import ADD_LOG_ICON from '../../../img/add-log.svg';
import ActiveIncentives from './ActiveIncentives';
import { ADVISOR_PAYMENT_HISTORY } from '../../../graphql/queries/payouts/queries';
import { MENTOR_PAYMENT_IN_TRANSIT } from '../Payouts/gql';
import { advisorInTransitPaymentQuery } from '../Payouts/gql/__generated__/advisorInTransitPaymentQuery'

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Grid>{children}</Grid>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    removeTabScroll: {
      paddingBottom: 30,

      '& .MuiTypography-h2': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 'bold',
        fontSize: 36,
        letterSpacing: '-0.022em',
        color: '#272929',
        padding: '65px 0px 42px',
      },
    },

    payoutTabs: {
      borderBottom: '1px solid #D4D4D4',
      paddingBottom: 29,
      marginBottom: 39,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },

      '& .MuiTabs-root': {
        minHeight: 28,
      },

      '& .addLogButton': {
        minWidth: 195,
        height: 40,
        borderRadius: 12,
        background: '#45CE93',
        fontWeight: 'bold',
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#FFFFFF',
        fontFamily: fonts.POPPINS_FONT,
        textTransform: 'capitalize',
        boxShadow: 'none',

        [theme.breakpoints.down('xs')]: {
          marginTop: 15,
        },

        '& img': {
          marginRight: 15,
        },
      },

      '& .MuiAppBar-colorPrimary': {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        width: '80%',

        [theme.breakpoints.between('sm', 'md')]: {
          width: '70% !important',
        },

        [theme.breakpoints.down('xs')]: {
          width: '100% !important',
        },
      },

      '& .MuiTab-root': {
        fontFamily: fonts.POPPINS_FONT,
        fontSize: 14,
        lineHeight: '24px',
        letterSpacing: '-0.006em',
        color: '#525454',
        textTransform: 'capitalize',
        fontWeight: 'normal',
        minWidth: 44,
        padding: 0,
        minHeight: 24,
        marginRight: 44,
      },

      '& .Mui-selected': {
        fontWeight: 600,
      },

      '& .MuiTabs-indicator': {
        backgroundColor: '#525454',
        height: 1,
      },
    },

    payoutCards: {
      borderRadius: 25,
      boxShadow: 'none',
      width: 700,

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },

      '& .MuiCardContent-root': {
        padding: '55px 45px',

        [theme.breakpoints.down('xs')]: {
          padding: 35,
        },

        '& .MuiTypography-root': {
          fontFamily: fonts.POPPINS_FONT,
          color: '#272929',
        },

        '& .MuiTypography-h6': {
          fontWeight: 600,
          fontSize: 24,
          letterSpacing: '-0.019em',
          marginBottom: 13,
          lineHeight: '32px',
        },

        '& .MuiTypography-h3': {
          fontWeight: 'bold',
          fontSize: 36,
          letterSpacing: '-0.022em',
          lineHeight: '42px',

          [theme.breakpoints.down('xs')]: {
            fontSize: 28,
          },
        },
      },
    },

    payoutCardsTotal: {
      background: '#EBF3FE',
    },

    payoutTransit: {
      border: '3px solid #C2DCFD',
      marginTop: 27,
    },

    transitSpacing: {
      paddingTop: 56,

      [theme.breakpoints.down('sm')]: {
        paddingTop: 0,
      },
    },
  })
);

const TimeLogs: FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();
  const { user } = useContext(AppContext);
  const [key, setKey] = useState(Date.now());
  const [value, setValue] = useState(0);
  const [totalAmount, setTotalAmount] = useState<number | null>(null);

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const mountEffect = async () => {
    try {
      const { data } = await client.query({
        query: ALL_STUDENTS_INCENTIVE_BONUSES_QUERY_AMOUNT_ONLY,
        fetchPolicy: 'network-only',
      });
      const getTotalAmount =
        data?.getStudentsIncentiveBonusDetail.totalAmount || 0;
      setTotalAmount(getTotalAmount);
    } catch (error) {
      console.log('TimeLogError:::', error);
    }
  };

  useEffect(() => {
    if (
      window.location.search.includes('requestedPayouts') &&
      !!window.location.search.split('?')[1].split('=')[1]
    ) {
      setValue(1);
    }
    if (
      window.location.search.includes('fromStudentPage') &&
      !!window.location.search.split('&')[1].split('=')[1]
    ) {
      setValue(2);
    }
    mountEffect();
  }, []);
  return (
    <>
      <Header />

      <Container>
        <Grid className={classes.removeTabScroll}>
          <Typography variant="h2">Payouts</Typography>

          <Grid className={classes.payoutTabs}>
            <AppBar position="static">
              <Tabs
                value={value}
                variant="scrollable"
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="Received" {...a11yProps(0)} />

                <Tab label="Requested - Hours Logged Only" {...a11yProps(1)} />

                {/* {totalAmount && (
                  <Tab
                    label={
                      <Box display="flex">
                        Active Incentives
                        <span
                          style={{ color: colors.BLUE_SEVEN, paddingLeft: 2 }}
                        >
                          (${convertToUSD(totalAmount)})
                        </span>
                      </Box>
                    }
                    {...a11yProps(1)}
                  />
                )} */}
              </Tabs>
            </AppBar>
            {user?.isActive && (
              <Link to="/time-log/add">
                <Button variant="contained" className="addLogButton">
                  <img src={ADD_LOG_ICON} alt="log" />
                  Log New Activity
                </Button>
              </Link>
            )}
          </Grid>

          <TabPanel value={value} index={0}>
            <ApolloConsumer key={key}>
              {(client) => (
                <Box>
                  <Query<AdvisorPaymentHistoryQuery>
                    query={ADVISOR_PAYMENT_HISTORY}
                    fetchPolicy="cache-and-network"
                  >
                    {({ data, loading }) => {
                      if (loading) {
                        return <Loader />;
                      }
                      const advisorPaymentHistory =
                        data?.advisorPaymentHistory || null;
                      if (data) {
                        const arrayOfAmmount = advisorPaymentHistory?.map(
                          (item) => {
                            return item?.amount;
                          }
                        );
                        const reducer = (
                          previousValue: any,
                          currentValue: any
                        ) => previousValue + currentValue;
                        const TotalReceivedPayouts = arrayOfAmmount?.reduce(
                          reducer,
                          0
                        );
                        return (
                          <>
                            <Grid container spacing={2}>
                              <Grid item lg={7} md={7} sm={12} xs={12}>
                                <Box className={classes.transitSpacing}>
                                  <Card
                                    className={clsx(
                                      classes.payoutCards,
                                      classes.payoutCardsTotal
                                    )}
                                  >
                                    <CardContent>
                                      <Typography variant="h6">
                                        Total Received Payouts
                                      </Typography>

                                      <Typography variant="h3">
                                        $
                                        {TotalReceivedPayouts
                                          ? (
                                              TotalReceivedPayouts / 100
                                            ).toFixed(2)
                                          : 0.0}
                                      </Typography>
                                    </CardContent>
                                  </Card>

                                  <Card
                                    className={clsx(
                                      classes.payoutCards,
                                      classes.payoutTransit
                                    )}
                                  >
                                    <Query<advisorInTransitPaymentQuery>
                                      query={MENTOR_PAYMENT_IN_TRANSIT}
                                      fetchPolicy="cache-and-network"
                                    >
                                      {({ data, loading }) => {
                                        if (loading) {
                                          return <Loader />;
                                        }
                                        if (data) {
                                          return (
                                            <>
                                              <CardContent>
                                                <Typography variant="h6">
                                                  Payouts in Transit
                                                </Typography>

                                                <Typography variant="h3">
                                                  $
                                                  {data?.advisorInTransitPayment
                                                    ?.InTransit &&
                                                    (
                                                      data
                                                        ?.advisorInTransitPayment
                                                        ?.InTransit / 100
                                                    )?.toFixed(2)}
                                                </Typography>
                                              </CardContent>
                                            </>
                                          );
                                        } else {
                                          return <></>;
                                        }
                                      }}
                                    </Query>
                                  </Card>
                                </Box>
                              </Grid>

                              <Grid item lg={5} md={5} sm={12} xs={12}>
                                <PayoutHistory
                                  advisorPaymentHistory={advisorPaymentHistory}
                                />
                              </Grid>
                            </Grid>
                          </>
                        );
                      } else {
                        return <></>;
                      }
                    }}
                  </Query>
                </Box>
              )}
            </ApolloConsumer>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <Payouts />
          </TabPanel>

          <TabPanel value={value} index={2}>
            <ActiveIncentives />
          </TabPanel>
        </Grid>
      </Container>
    </>
  );
};

export default TimeLogs;
