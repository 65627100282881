import { useState } from 'react';
import { CSVLink } from 'react-csv';
import { AdvisorPaymentHistoryQuery_advisorPaymentHistory } from './__generated__/AdvisorPaymentHistoryQuery';
import Download_CSV_Icon from '../../../img/download-csv.svg';
import moment from 'moment';

const headers = [
  { label: 'Payout Id', key: 'payoutId' },
  { label: 'Amount', key: 'amount' },
  { label: 'Paid At', key: 'createdAt' },
];

interface Payout {
  createdAt: Date | Number | String | any;
  status: string;
  amount: number;
}

const DownloadPayoutsHistoryInCsv = ({
  payouts,
  label,
}: {
  payouts: (AdvisorPaymentHistoryQuery_advisorPaymentHistory | null)[] | null;
  label: string;
}) => {
  const [csvData, setCsvData] = useState<[Payout] | []>([]);
  return (
    <CSVLink
      data={csvData}
      headers={headers}
      onClick={() => {
        let data: any =
          payouts?.length &&
          payouts.map((item) => ({
            payoutId: item && item.payoutId ? item.payoutId : 'N/A',
            createdAt:
              item?.paidAt &&
              `${moment
                .parseZone(
                  item?.paidAt && new Date(item?.paidAt * 1000).toString()
                )
                .local()
                .format('MMMM DD, YYYY')}`,
            amount: item?.amount && `$${(item?.amount / 100).toFixed(2)}`,
          }));
        setCsvData(data);
        return true;
      }}
    >
      <img src={Download_CSV_Icon} alt="csv" /> {label}
    </CSVLink>
  );
};

export default DownloadPayoutsHistoryInCsv;
