// imports
import { useState } from 'react';
import moment from 'moment-timezone';
import { Collapse, Typography, Grid, Box, IconButton } from '@material-ui/core';
// graphql
import { MENTOR_TIME_LOGS_allMentorTimeLogs_mentorTimeLogs } from './gql/__generated__/MENTOR_TIME_LOGS';
// styles
import { useStyles } from './styles';
// assets
import Collapse_Arrow_Img from '../../../img/collapse-arrow.svg';
import { getNewLabelForWorkActivity } from '../../../common/utility';

const StudentTableRow = ({
  item,
  index,
  mins,
}: {
  item: MENTOR_TIME_LOGS_allMentorTimeLogs_mentorTimeLogs;
  index: number;
  mins: number;
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const {
    milestoneTeamName,
    noteForNoOrientationCall,
    otherMeetingDescription,
    noteForExtendedMeeting,
  } = item.MentorTimeLogAssociations || {};

  return (
    <Box borderRadius={10} border="1px solid #D4D4D4" mb={1}>
      <Box
        role="checkbox"
        tabIndex={-1}
        display="flex"
        alignItems="center"
        key={`timelog_${index}`}
        justifyContent="space-between"
        onClick={() => setOpen(!open)}
        style={{ cursor: 'pointer' }}
      >
        <Box
          className="tableCellFirst tableCellBody"
          borderRadius="10px 0px 0px 10px"
        >
          {item?.activityAt
            ? moment(new Date(item.activityAt)).format('MM/DD/YYYY')
            : '--'}
        </Box>

        <Box className="tableCellSecond tableCellBody">
          {item.workType ? item.workType : '--'}
        </Box>

        <Box className="tableCellBody tableCellThird" fontWeight={700}>
          {item.workActivity
            ? `${getNewLabelForWorkActivity(item.workActivity)} ${
                milestoneTeamName ? `(${milestoneTeamName})` : ''
              }`
            : '--'}
        </Box>

        <Box className="timeText tableCellFour tableCellBody">
          {!item.activityDuration ? (
            '--'
          ) : (
            <>
              <span>{parseInt(`${item.activityDuration / 60}`)}</span>
              <small>hr</small>
              {', '}
            </>
          )}
          {mins ? (
            <>
              <span>{parseInt(`${mins}`)}</span>
              <small>min</small>
            </>
          ) : (
            ''
          )}
        </Box>

        <Box className="tableCellBody tableCellFive">
          {item.mentor
            ? item.mentor.firstName + ' ' + item.mentor.lastName
            : '--'}
        </Box>

        <Box className="tableCellBody tableCellSix">
          {item.missCall ? <i>Student Missed Call</i> : ''}
        </Box>

        <Box className="tableCellBody tableCellSeven">
          <IconButton
            size="small"
            className={classes.collapseButton}
            onClick={() => setOpen(!open)}
            style={{ opacity: 0.5 }}
          >
            {open ? (
              <img
                className={classes.collapseUp}
                src={Collapse_Arrow_Img}
                alt="collapse"
              />
            ) : (
              <img src={Collapse_Arrow_Img} alt="collapse" />
            )}
          </IconButton>
        </Box>
      </Box>

      <Collapse
        in={open}
        unmountOnExit
        timeout="auto"
        className={classes.collapseContent}
      >
        <Box p="0px 24px 28px 32px">
          {item?.note && (
            <Typography variant="body1" className="description">
              {item.note}
            </Typography>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Box display="flex">
                <Typography variant="body1" className="milestoneText">
                  <span>Milestone:</span>
                </Typography>

                <Typography variant="body1">
                  {item?.milestone || '--'}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={9}>
              {item?.lateLogReason && (
                <Box display="flex" mb={1}>
                  <Typography variant="body1" className="textHeading">
                    <span>Late Log:</span>
                  </Typography>

                  <Typography variant="body1">{item.lateLogReason}</Typography>
                </Box>
              )}
              {item?.duplicateOrientationCallReason && (
                <Box display="flex" mb={1}>
                  <Typography variant="body1" className="textHeading">
                    <span>Additional Orientation Call:</span>
                  </Typography>

                  <Typography variant="body1">{item.duplicateOrientationCallReason}</Typography>
                </Box>
              )}
              
              {item?.durationNote && (
                <Box display="flex" mb={1}>
                  <Typography variant="body1" className="textHeading">
                    <span>Excessive Time:</span>
                  </Typography>

                  <Typography variant="body1">{item.durationNote}</Typography>
                </Box>
              )}

              {noteForNoOrientationCall && (
                <Box display="flex" mb={1}>
                  <Typography variant="body1" className="textHeading">
                    <span>Pre-Orientation:</span>
                  </Typography>

                  <Typography variant="body1">
                    {noteForNoOrientationCall}
                  </Typography>
                </Box>
              )}

              {otherMeetingDescription && (
                <Box display="flex" mb={1}>
                  <Typography variant="body1" className="textHeading">
                    <span>No Meeting Associated:</span>{' '}
                  </Typography>

                  <Typography variant="body1">
                    {otherMeetingDescription}
                  </Typography>
                </Box>
              )}

              {noteForExtendedMeeting && (
                <Box display="flex" mb={1}>
                  <Typography variant="body1" className="textHeading">
                    <span>Duration Note:</span>
                  </Typography>

                  <Typography variant="body1">
                    {noteForExtendedMeeting}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Box>
  );
};

export default StudentTableRow;
