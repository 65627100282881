import gql from 'graphql-tag';

export const ALL_ADVISOR_BOOKINGS = gql`
  query allAdvisorBookingsQuery(
    $status: AdvisorMeetingStatus
    $bookingStartTime: String
    $bookingEndTime: String
    $searchQuery: String
    $limit: Int
    $page: Int
    $sortByDate: Boolean
    $studentId: Int
    $fromLogHours: Boolean
  ) {
    allAdvisorBookings(
      input: {
        status: $status
        bookingStartTime: $bookingStartTime
        bookingEndTime: $bookingEndTime
        searchQuery: $searchQuery
        limit: $limit
        page: $page
        sortByDate: $sortByDate
        studentId: $studentId
        fromLogHours: $fromLogHours
      }
    ) {
      advisorBookings {
        id
        AdvisorId
        startTime
        endTime
        StudentId
        event_id
        status
        title
        note
        zoomLink
        createdAt
        canceledReason
        otherReason
        isHourLogged
        HoursLoggedBy
        student {
          id
          firstName
          lastName
          email
          pictureUrl
          initialKickOffDate
        }
        allParticipants {
          id
          firstName
          lastName
          pictureUrl
          roles
          zoomLink
          email
        }
        cancelledByUser {
          id
          firstName
          lastName
        }
      }
      count
      nextRecord
    }
  }
`;
export const DELETE_BOOKING = gql`
  mutation deleteNylasEvent(
    $eventId: Int!
    $AdvisorId: Int!
    $reason: String
    $otherReason: String
  ) {
    deleteNylasEvent(
      input: {
        eventId: $eventId
        AdvisorId: $AdvisorId
        reason: $reason
        otherReason: $otherReason
      }
    ) {
      message
      success
    }
  }
`;

export const ADD_MEETING_CANCELLATION_REASON = gql`
  mutation updateCanceledMeetingReasonMutation(
    $id: Int!
    $reason: String!
    $participants: [Int]!
    $otherReason: String
  ) {
    updateCanceledMeetingReason(
      input: {
        id: $id
        reason: $reason
        participants: $participants
        otherReason: $otherReason
      }
    ) {
      status
      message
    }
  }
`;
