import { MenuItem, Tooltip, withStyles } from '@material-ui/core';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import colors from './colors';
import fonts from './fonts';
import INFO_ICON from '../img/info-dark.svg';
// import ASSIST_ICON from '../img/assist-icon.png';
import ASSIST_ICON from '../img/advisor-assist-icon.svg';
import { useCustomTooltipStyles } from './commonStyles';
import { FC } from 'react';
export const StyledMenu = withStyles({
  paper: {
    borderRadius: 12,
    background: colors.WHITE_ONE,
    border: `1px solid ${colors.WHITE_SEVEN}`,
    boxShadow: `0px 4px 14px ${colors.RGBA_COLOR_ONE}`,

    '& ul': {
      padding: '11px !important',
      width: '100% !important',
    },
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));
export const StyledMenuItem = withStyles(() => ({
  root: {
    borderRadius: 5,
    fontFamily: fonts.POPPINS_FONT,

    '& .MuiTypography-h6': {
      color: colors.GRAY_TWENTYTWO,
      fontWeight: 600,
      fontSize: 14,
      letterSpacing: '0.006em',
      fontFamily: fonts.POPPINS_FONT,
    },

    '& .menu-item-style': {
      minWidth: 17,
    },
  },
}))(MenuItem);

export const CustomTooltip: FC<{ title: string; className?: string }> = (
  props: any
) => {
  const classes = useCustomTooltipStyles();
  const { title } = props;
  return (
    <Tooltip arrow classes={classes} {...props} title={title} placement="top">
      <img style={{ cursor: 'pointer' }} src={INFO_ICON} alt="info" />
    </Tooltip>
  );
};

export const AdvisorIconTooltip: FC<{ title: string; className?: string }> = (
  props: any
) => {
  const classes = useCustomTooltipStyles();
  const { title } = props;
  return (
    <Tooltip arrow classes={classes} {...props} title={title} placement="top">
      <img style={{ cursor: 'pointer' }} src={ASSIST_ICON} alt="info" />
    </Tooltip>
  );
};

export const PLUS_ICON = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 0C5.26522 0 5.51957 0.105357 5.70711 0.292893C5.89464 0.48043 6 0.734784 6 1V4H9C9.26522 4 9.51957 4.10536 9.70711 4.29289C9.89464 4.48043 10 4.73478 10 5C10 5.26522 9.89464 5.51957 9.70711 5.70711C9.51957 5.89464 9.26522 6 9 6H6V9C6 9.26522 5.89464 9.51957 5.70711 9.70711C5.51957 9.89464 5.26522 10 5 10C4.73478 10 4.48043 9.89464 4.29289 9.70711C4.10536 9.51957 4 9.26522 4 9V6H1C0.734784 6 0.48043 5.89464 0.292893 5.70711C0.105357 5.51957 0 5.26522 0 5C0 4.73478 0.105357 4.48043 0.292893 4.29289C0.48043 4.10536 0.734784 4 1 4H4V1C4 0.734784 4.10536 0.48043 4.29289 0.292893C4.48043 0.105357 4.73478 0 5 0Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const PENCIL_IC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M0 12.6243V15.947H3.32275L13.1271 6.1427L9.80431 2.81995L0 12.6243Z"
        fill="#2F80ED"
      />
      <path
        d="M15.6878 2.32813L13.6188 0.259174C13.2732 -0.0863915 12.7105 -0.0863915 12.365 0.259174L10.7435 1.88068L14.0662 5.20342L15.6877 3.58192C16.0333 3.23636 16.0333 2.67369 15.6878 2.32813Z"
        fill="#2F80ED"
      />
    </svg>
  );
};
