import { Box, Container, Grid, Typography } from '@material-ui/core';

// components
import CapacityStats from '../../../components/capacity/CapacityStats';
import CapacityChart from '../../../components/capacity/Chart';
import Header from '../../../components/layout/Header';
import Loader from '../../../components/Loader';

// styles
import { useEffect, useState } from 'react';
import { IChartData } from '../../../common/interfaces';
import { graphApiCalling } from '../../../common/utility';
import Toast, { Color } from '../../../components/Toast';
import { useStyles } from './capacityStyles';

const Capacity = () => {
  const [chartData, setChartData] = useState<IChartData | null>(null);
  const [type, setType] = useState<Color>('error');
  const [message, setMessage] = useState('');
  const [openToast, setOpenToast] = useState(false);
  // loading state definition
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const classes = useStyles();

  const apiCall = async () => {
    try {
      // start loading
      setIsLoading(true);
      const data = await graphApiCalling();
      if (data) setChartData(data);
      // end loading
      setIsLoading(false);
    } catch (err: any) {
      setType('error');
      setOpenToast(true);
      setMessage(err.message);
      // end loading - in case of error
      setIsLoading(false);
    }
  };

  const mountEffect = () => {
    apiCall();
  };

  useEffect(mountEffect, []);

  const toastData = {
    open: openToast,
    close: () => {
      setOpenToast(false);
      setMessage('');
      setType('error');
    },
    message,
    type,
    autoHideDuration: 3000,
  };

  return (
    <>
      {
        // loader
        isLoading && <Loader />
      }

      <Header />

      <Container maxWidth={false}>
        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
          className={classes.pageHeader}
        >
          <Typography variant="h2" className="pageHeadingText">
            Capacity
          </Typography>
        </Box>

        <Grid container spacing={4}>
          <Grid item lg={4} xs={12} className={classes.capacityStats}>
            <CapacityStats chartData={chartData} refetchAPI={graphApiCalling} />
          </Grid>

          <Grid item lg={8} xs={12}>
            <CapacityChart chartData={chartData} />
          </Grid>
        </Grid>
      </Container>
      <Toast {...toastData} />
    </>
  );
};
export default Capacity;
