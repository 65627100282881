import { FC } from 'react';

import {
  IconButton,
  Typography,
  Button,
  Dialog,
  Box,
  DialogContent,
  makeStyles,
  createStyles,
  Theme,
  Grid,
} from '@material-ui/core';

import Close_Dialog_Icon from '../../../img/close-dialog.svg';
import fonts from '../../../common/fonts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    essayPromptDialog: {
      '& .MuiDialog-paper': {
        borderRadius: 25,
        padding: '50px 0px',
        maxWidth: 587,
        border: '1px solid #D4D4D4',
        boxShadow: '0px 4px 14px rgb(68 68 68 / 14%)',

        [theme.breakpoints.down('sm')]: {
          borderRadius: 15,
          margin: '0px 15px',
          padding: '60px 0px 30px',
        },

        '& .MuiDialogContent-root': {
          padding: 0,
        },

        '& .MuiIconButton-root': {
          position: 'absolute',
          top: 25,
          right: 35,

          [theme.breakpoints.down('xs')]: {
            top: 10,
            right: 20,
          },
        },

        '& .collegeLogoNameWrapper': {
          marginBottom: 28,
          padding: '0px 50px',

          [theme.breakpoints.down('sm')]: {
            padding: '0px 16px',
          },
        },

        '& .collegeLogoName': {
          paddingBottom: 23,
          borderBottom: '1px solid #D4D4D4',
          width: '100%',

          [theme.breakpoints.down('sm')]: {
            paddingBottom: 15,
          },
        },

        '& .collegeLogo': {
          width: 50,
          marginRight: 21,

          '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          },
        },

        '& .MuiTypography-h2': {
          fontFamily: fonts.POPPINS_FONT,
          fontWeight: 'bold',
          fontSize: 25,
          letterSpacing: '-0.022em',
          color: '#272929',

          [theme.breakpoints.down('sm')]: {
            fontSize: 22,
          },
        },

        '& .MuiTypography-h5': {
          fontFamily: fonts.INTER,
          fontWeight: 600,
          fontSize: 18,
          letterSpacing: '-0.014em',
          color: '#000000',
          marginBottom: 7,
        },

        '& .MuiTypography-h6': {
          fontFamily: fonts.POPPINS_FONT,
          fontWeight: 600,
          fontSize: 14,
          letterSpacing: '0.006em',
          color: '#525454',
          textTransform: 'uppercase',
          marginBottom: 25,
        },

        '& .MuiTypography-body1': {
          fontFamily: fonts.INTER,
          fontSize: 16,
          letterSpacing: '-0.011em',
          color: '#7D7F7F',
          marginBottom: 39,
        },

        '& .MuiButton-contained': {
          minWidth: 265,
          height: 40,
          background: '#2F80ED',
          borderRadius: 12,
          fontFamily: fonts.POPPINS_FONT,
          fontWeight: 'bold',
          fontSize: 14,
          letterSpacing: '-0.006em',
          color: '#FFFFFF',
          boxShadow: 'none',
          textTransform: 'initial',
        },

        '& .dialogContentScroll': {
          maxHeight: 272,
          overflow: 'auto',
          padding: '0px 50px',

          [theme.breakpoints.down('sm')]: {
            padding: '0px 16px',
          },
        },
      },
    },
  })
);

interface Props {
  open: boolean;
  logo: string | null;
  name: string | null;
  description: string[];
  essayName: string | null;
  url: string | null;
  lengthLimit: string;
  onClose: () => void;
}

const EssayPromptDialog: FC<Props> = ({
  logo,
  name,
  description,
  essayName,
  url,
  open,
  onClose,
  lengthLimit,
}) => {
  const classes = useStyles();

  const getOnlyCount = (limit: string) => {
    const numberArr: string[] = [];
    const splitLimit = limit.split(' ');
    if (splitLimit.length > 1) {
      splitLimit.map((item) => {
        if (parseInt(item)) numberArr.push(item);
      });

      if (numberArr.length > 1) {
        return numberArr.join('-');
      } else {
        return numberArr.join();
      }
    } else return limit;
  };
  const schoolUrl =
    !url?.includes('https') && !url?.includes('http') ? `https://${url}` : url;

  return (
    <Grid>
      <Dialog
        open={open}
        onClose={onClose}
        className={classes.essayPromptDialog}
      >
        <DialogContent>
          <IconButton onClick={onClose}>
            <img src={Close_Dialog_Icon} alt="close" />
          </IconButton>

          <Box
            display="flex"
            alignItems="center"
            className="collegeLogoNameWrapper"
          >
            <Box display="flex" alignItems="center" className="collegeLogoName">
              <Box className="collegeLogo">
                <img src={logo || ''} alt={name || ''} />
              </Box>
              <Typography variant="h2">{name}</Typography>
            </Box>
          </Box>

          <Grid className="dialogContentScroll">
            <Typography variant="h5">{essayName}</Typography>
            <Typography variant="h6">
              WORD Max: {getOnlyCount(lengthLimit)}
            </Typography>
            <Typography variant="body1">
              {description &&
                description.map((point, i) => <p key={i}>{point}</p>)}
            </Typography>
          </Grid>

          <Box textAlign="center" pt={5}>
            {url && (
              <a href={schoolUrl} target="_blank" rel="noopener noreferrer">
                <Button variant="contained">
                  View Prompt on School Website
                </Button>
              </a>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default EssayPromptDialog;
