import gql from 'graphql-tag';

export const APP_COLLEGE_QUERY = gql`
  query AppCollegeQuery {
    allStudentApplications {
      StudentApplications {
        id
        applicationRequirement {
          id
          college {
            id
            name
            logo
          }
        }
      }
    }
  }
`;

export const ALL_COLLEGES_QUERY = gql`
  query AllCollegesQuery(
    $limit: Int
    $page: Int
    $searchQuery: String
    $topHundred: Boolean
    $studentId: Int
  ) {
    allColleges(
      input: {
        limit: $limit
        page: $page
        searchQuery: $searchQuery
        topHundred: $topHundred
        studentId: $studentId
      }
    ) {
      nextRecord
      colleges {
        id
        name
        logo
        applicationCreated(studentId: $studentId)
        address
        topHundred
        studentApplication {
          id
        }
        applicationRequirement {
          id
          lastYearAdmissionRate
          gradeOverall
          acceptRate
        }
      }
    }

    allPopularColleges {
      id
      name
      logo
      applicationCreated
      address
      topHundred
      studentApplication {
        id
      }
      applicationRequirement {
        id
        lastYearAdmissionRate
        gradeOverall
        acceptRate
      }
    }
  }
`;

export const GET_STUDENT = gql`
  query getStudent($studentId: Int) {
    Student(studentId: $studentId) {
      id
      firstName
      lastName
      email
      highSchoolYear
      gapYear
      graduated
    }
  }
`;
