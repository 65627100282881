import { FC } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { Box, IconButton } from '@material-ui/core';
import CommentIcon from '@material-ui/icons/Comment';

import { AllMentorsListQuery_allMentors_mentors } from './__generated__/AllMentorsListQuery';

interface Props {
  setMentorId: Function;
  mentor: AllMentorsListQuery_allMentors_mentors;
}

const SENDBIRD_AVATAR = (
  <svg viewBox="0 0 56 56" width="36" radius="50%">
    <path fill="#A8A8A8" d="M0 0h56v56H0z"></path>
    <path
      fill="#FFF"
      fillOpacity="0.88"
      d="M34.667 31.333a8.334 8.334 0 018.325 7.972l.008.362V43a1.667 1.667 0 01-3.322.194L39.667 43v-3.333a5 5 0 00-4.707-4.992l-.293-.008H21.333a5 5 0 00-4.991 4.706l-.009.294V43a1.667 1.667 0 01-3.322.194L13 43v-3.333a8.333 8.333 0 017.972-8.326l.361-.008h13.334zm-6.667-20A8.333 8.333 0 1128 28a8.333 8.333 0 010-16.667zm0 3.334a5 5 0 100 10 5 5 0 000-10z"
    ></path>
  </svg>
);

const AdvisorCard: FC<Props> = ({ mentor, setMentorId }) => {
  const { id, firstName, lastName, pictureUrl, email } = mentor;

  return (
    <Box key={id} display="flex" alignItems="center">
      <Box display="flex" alignItems="center" flex={1}>
        <Box marginRight={1}>
          {pictureUrl ? (
            <Avatar alt={`Avatar n°${id + 1}`} src={pictureUrl} />
          ) : (
            <Box overflow="hidden" borderRadius="50%" width={36}>
              {SENDBIRD_AVATAR}
            </Box>
          )}
        </Box>

        <Box>
          <Box fontSize={14} fontWeight={500}>{`${firstName} ${lastName}`}</Box>

          <Box fontSize={12}>{email}</Box>
        </Box>
      </Box>

      <IconButton aria-label="comments" onClick={() => setMentorId(email)}>
        <CommentIcon />
      </IconButton>
    </Box>
  );
};

export default AdvisorCard;
