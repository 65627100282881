import { Grid, Box, Typography, IconButton } from '@material-ui/core';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import {
  getAdvisorStudentsAcceptedCollegesQuery,
  getAdvisorStudentsAcceptedCollegesQuery_getAdvisorStudentsAcceptedColleges_colleges,
} from './__generated__/getAdvisorStudentsAcceptedCollegesQuery';

import EDIT_PENCIL_ICON from '../../../img/pencil-edit.svg';
import EditCollegesDialog from './EditCollegesDialog';
import { useState } from 'react';

const ADVISOR_STUDENTS_ACCEPTED_COLLEGES = gql`
  query getAdvisorStudentsAcceptedCollegesQuery($userId: Int) {
    getAdvisorStudentsAcceptedColleges(input: { userId: $userId }) {
      colleges {
        name
        id
        styleConfig {
          bgDark
          bgLight
        }
      }
    }
  }
`;

const AdvisorStudentsSchool = () => {
  const [openCollegesDialog, setOpenCollegesDialog] = useState(false);
  const [advStudentAcceptedColleges, setAdvStudentAcceptedColleges] = useState<
    (getAdvisorStudentsAcceptedCollegesQuery_getAdvisorStudentsAcceptedColleges_colleges | null)[]
  >([]);
  const [refetchColleges, setRefetchColleges] = useState<
    Function | undefined
  >();

  const handleOpenCollegesDilaog = () => {
    setOpenCollegesDialog(true);
  };

  const handleCloseCollegesDilaog = () => {
    setOpenCollegesDialog(false);
  };

  return (
    <>
      <EditCollegesDialog
        open={openCollegesDialog}
        onClose={handleCloseCollegesDilaog}
        type={'advisorStudentsAcceptedTo'}
        advStudentAcceptedColleges={advStudentAcceptedColleges}
        refetch={refetchColleges}
      />
      <Grid>
        <Box display="flex" alignItems="center" className="headingSpacing">
          <Typography variant="h6" className="educationHeading">
            I’ve advised students who got into schools like...
          </Typography>

          <IconButton onClick={handleOpenCollegesDilaog} className="editButton">
            <img src={EDIT_PENCIL_ICON} alt="edit" />
          </IconButton>
        </Box>

        <Query<getAdvisorStudentsAcceptedCollegesQuery>
          query={ADVISOR_STUDENTS_ACCEPTED_COLLEGES}
          fetchPolicy="network-only"
          onCompleted={(data) => {
            const colleges = data.getAdvisorStudentsAcceptedColleges?.colleges;
            setAdvStudentAcceptedColleges(colleges?.length ? colleges : []);
          }}
        >
          {({ data, loading, refetch }) => {
            if (!refetchColleges) {
              setRefetchColleges(() => () => refetch());
            }

            if (loading) return <>Loading...</>;
            const colleges = data?.getAdvisorStudentsAcceptedColleges?.colleges;

            if (!colleges?.length) return <>--</>;
            return (
              <>
                <Box className="tagsWrapper">
                  {colleges?.map((item) => (
                    <Box key={item?.name || 'N/A'}>
                      <Box
                        className="tagsBadge"
                        style={{
                          backgroundColor:
                            item?.styleConfig?.bgDark || '#d4d4d4',
                        }}
                      >
                        {item?.name || 'N/A'}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </>
            );
          }}
        </Query>
      </Grid>
    </>
  );
};
export default AdvisorStudentsSchool;
