import { useContext } from 'react';
import { SendBirdProvider as SBProvider } from 'sendbird-uikit';
import { AppContext } from './contexts';
import { ApolloConsumer } from 'react-apollo';
import Pages from './pages';
import UpdateLastLoginAndLocation from './components/UpdateLastLoginAndLocation';

function AppContainer() {
  const { user } = useContext(AppContext);
  const firstName = user && user.firstName;
  const lastName = user && user.lastName;
  const nickName = `${firstName ? firstName : ''} ${lastName ? lastName : ''}`;
  const userId = user && (user.sendbirdId || user.email);
  const pictureUrl = user && user.pictureUrl;

  return user ? (
    <SBProvider
      appId={process.env.REACT_APP_SENDBIRD_APPLICATION_ID}
      userId={userId}
      nickname={nickName}
      profileUrl={pictureUrl}
    >
      {user && (
        <ApolloConsumer>
          {(client) => {
            return <UpdateLastLoginAndLocation client={client} />;
          }}
        </ApolloConsumer>
      )}
      <Pages />
    </SBProvider>
  ) : (
    <Pages />
  );
}

export default AppContainer;
