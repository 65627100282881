import { FC } from 'react';
import { Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import LOGO from '../../img/logo-header.png';
import ERROR from '../../img/sad-face.svg';

import { useStyles } from './style';

const Error404: FC = () => {
  const classes = useStyles();
  return (
    <>
      <Box maxWidth={250} position="absolute" left={30} top={20}>
        <img src={LOGO} alt="main logo" />
      </Box>

      <Box className={classes.mainContainer}>
        <img src={ERROR} alt="sad face" />

        <h2>404 - Page not found!</h2>

        <p>The page that you are looking for does not exists.</p>

        <Link to="/students">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Back to home
          </Button>
        </Link>
      </Box>
    </>
  );
};

export default Error404;
