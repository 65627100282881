import { useState } from 'react';
import {
  Box,
  Grid,
  InputBase,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';

import { HOURS_ARRAY, MINUTES_ARRAY } from '../../../constants';

const CustomTimePicker = ({
  time,
  type,
  placeHolder,
  setFieldValue,
}: {
  time: string;
  type: string;
  placeHolder: string;
  setFieldValue: Function;
}) => {
  const [anchorElPicker, setAnchorElPicker] = useState(null);

  const hour = time.split(' ')[0].split(':')[0];
  const minute = time.split(' ')[0].split(':')[1];
  const amOrpm = time.split(' ')[1];

  const handleClickPicker = (event: any) => {
    setAnchorElPicker(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElPicker(null);
  };
  return (
    <Grid item lg={6} md={6} sm={6} xs={6} className="datePickerCustomModule">
      <Typography variant="caption">{placeHolder}</Typography>

      <InputBase
        placeholder={placeHolder}
        onClick={handleClickPicker}
        value={time}
      />
      <Menu
        id="datePickerModule"
        anchorEl={anchorElPicker}
        keepMounted
        open={Boolean(anchorElPicker)}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid className="datePickerPopover">
          <Box display="flex" justifyContent="space-between">
            <Box className="scrollHours hoursFocus">
              {HOURS_ARRAY.map((item) => (
                <MenuItem
                  key={`${item}hour`}
                  className={hour === item ? 'selectedHours' : ''}
                  onClick={(e) => {
                    handleClose();
                    let value = `${e.currentTarget.value}`;
                    if (value.length < 2) {
                      value = `0${value}`;
                    }
                    let selectedTime = `${
                      amOrpm === 'PM' && value !== '12'
                        ? +value + 12
                        : amOrpm === 'AM' && value === '12'
                        ? '00'
                        : value
                    }:${minute}`;
                    setFieldValue(type, selectedTime);
                  }}
                  value={item}
                >
                  {item}
                </MenuItem>
              ))}
            </Box>
            <Box className="scrollHours minutesFocus">
              {MINUTES_ARRAY.map((item) => (
                <MenuItem
                  key={`${item}min`}
                  className={minute === item ? 'selectedMinutes' : ''}
                  onClick={(e) => {
                    handleClose();
                    let value = `${e.currentTarget.value}`;
                    if (value.length < 2) {
                      value = `0${value}`;
                    }
                    let selectedTime = `${
                      amOrpm === 'PM' && hour !== '12'
                        ? +hour + 12
                        : amOrpm === 'AM' && hour === '12'
                        ? '00'
                        : hour
                    }:${value}`;
                    setFieldValue(type, selectedTime);
                  }}
                  value={item}
                >
                  {item}
                </MenuItem>
              ))}
            </Box>
            <Box>
              <MenuItem
                className={amOrpm === 'PM' ? 'selectedTImeCycle' : ''}
                onClick={(e) => {
                  handleClose();
                  let selectedTime = `${
                    amOrpm === 'AM' && hour !== '12' ? +hour + 12 : hour
                  }:${minute}`;
                  setFieldValue(type, selectedTime);
                }}
              >
                PM
              </MenuItem>
              <MenuItem
                className={amOrpm === 'AM' ? 'selectedTImeCycle' : ''}
                onClick={(e) => {
                  handleClose();
                  let selectedTime = `${
                    amOrpm === 'PM' && hour === '12' ? '00' : hour
                  }:${minute}`;
                  setFieldValue(type, selectedTime);
                }}
              >
                AM
              </MenuItem>
            </Box>
          </Box>
        </Grid>
      </Menu>
    </Grid>
  );
};
export default CustomTimePicker;
