import { useState, FC } from 'react';

import { ApolloProvider } from 'react-apollo';
import client from './apollo';

import { User, AppContext, AppContextProps } from './contexts';
import { advisorLead } from './graphql/queries/advisorLead/__generated__/advisorLead';
import AppContainer from './AppContainer';

declare global {
  interface Window {
    Intercom: any;
    nylas: any
  }
}

const App: FC = () => {
  const [appContext, setAppContext] = useState<AppContextProps>({
    user: null,
    isLoggedIn: false,
    unreadMessagesCount: 0,
    setUser(user: User) {
      setAppContext({
        ...appContext,
        user,
      });
    },
    setIsLoggedIn(isLoggedIn: boolean) {
      setAppContext({
        ...appContext,
        isLoggedIn,
      });
    },
    setUnreadMessagesCount(data: any) {
      setAppContext({
        ...appContext,
        ...data,
      });
    },
    isCollegeUpdated: false,
    setIsCollegeUpdated(isCollegeUpdated: boolean){
      setAppContext({
        ...appContext,
        isCollegeUpdated,
      });
    },
    advisorLeadData: null,
    setAdvisorLeadData(advisorLeadData: advisorLead) {
        setAppContext(prevContext => ({
          ...prevContext,
          advisorLeadData,
        }));
      },
    },
  );
  return (
    <AppContext.Provider value={appContext}>
      <ApolloProvider client={client}>
        <AppContainer />
      </ApolloProvider>
    </AppContext.Provider>
  );
};

export default App;
