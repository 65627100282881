import { Box, Button, Card, Grid, Typography } from '@material-ui/core';

import Info_Blue_Img from './../img/blue-info.svg';
import Danger_Img from './../img/danger-error.svg';
import Warning_Img from './../img/unverified-token.svg';
import { useStyles } from './styles';

interface IProps {
  title: string;
  message: string;
  btnText?: string;
  btnOnClick?: () => void;
  btnDisabled?: boolean;
  type?: 'error' | 'info' | 'warning';
}

const AlertDialog = ({
  title,
  message,
  btnText,
  btnOnClick,
  btnDisabled,
  type = 'info',
}: IProps) => {
  const classes = useStyles();
  const cardClass =
    type === 'info'
      ? classes.infoCard
      : type === 'error'
      ? classes.errorCard
      : '';

  const icon =
    type === 'info'
      ? Info_Blue_Img
      : type === 'warning'
      ? Warning_Img
      : Danger_Img;

  return (
    <Card className={`${classes.card} ${cardClass}`}>
      <Box display="flex">
        <Grid>
          <img src={icon} alt="token" />
        </Grid>
        <Grid>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="body1" className='alert-description'>{message}</Typography>

          {btnText && btnOnClick && (
            <Button
              className={classes.button}
              variant="contained"
              onClick={btnOnClick}
              disabled={btnDisabled}
            >
              Begin
            </Button>
          )}
        </Grid>
      </Box>
    </Card>
  );
};
export default AlertDialog;
