import React, { useState, ChangeEvent, FC } from 'react';
import { Grid, FormControl, Box, Button, Checkbox } from '@material-ui/core';
import { Typography, Select, MenuItem, Collapse } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Toast from '../../../components/Toast';

import SearchColleges from '../../../components/SearchColleges';
import { OptionType } from '../../../components/multiSelect';

import { useStylesTable } from './tableCommonStyle';

import Add_New_Icon from '../../../img/add-new-circle.svg';

interface Props {
  handleSubmit: Function;
  totalCount: number;
  currentWorkingAt: boolean;
}
const CreatePreviouslyWorkedAt: FC<Props> = ({
  handleSubmit,
  totalCount,
  currentWorkingAt,
}) => {
  const tableClasses = useStylesTable();

  const [toast, setToast] = useState<any>({
    message: '',
    type: 'success',
  });
  const [openToast, setOpenToast] = useState(false);

  const inititialValues: {
    Title: string;
    college: OptionType | null;
    current: boolean;
  } = {
    Title: 'Director of Admissions',
    college: null,
    current: false,
  };

  const [open, setOpen] = React.useState(false);
  const FormSchema = Yup.object().shape({
    Title: Yup.string().required('Title is Required').nullable(),
    college: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .typeError('College is required')
      .required(),
    current: Yup.boolean(),
  });
  const advisorTitles = [
    'Director of Admissions',
    'Assistant Director of Admissions',
    'Director of Financial Aid',
    'Former Admissions Officer',
    'Associate Director of Admissions',
    'Senior Assistant Dean of Admissions',
    'Associate Dean of Admissions',
    'Senior Associate Director of Admissions',
    'Senior Assistant Director of Admissions',
    'Graduate Admissions Officer',
    'Graduate Specialty Advisor',
  ];
  return (
    <Grid>
      <Formik
        initialValues={inititialValues}
        validationSchema={FormSchema}
        onSubmit={async (variables, { resetForm }) => {
          try {
            const { Title, college, current } = variables;

            handleSubmit(parseInt(college?.value || '0'), Title, current);
            resetForm();
            setOpen(!open);
          } catch (error) {
            console.log('err', error);
          }
        }}
      >
        {({ setFieldValue, isSubmitting, values, errors, handleSubmit }) => {
          function handleChange(
            event: ChangeEvent<{ name?: string; value: unknown }>
          ) {
            const {
              target: { value },
            } = event;

            setFieldValue('Title', value);
          }

          return (
            <Form>
              <Grid className={tableClasses.addNewCollege}>
                {!open && (
                  <Button
                    variant="text"
                    className={tableClasses.iconTextButton}
                    onClick={() => {
                      if (totalCount < 3) setOpen(!open);
                      else {
                        setToast({
                          message: 'Cannot add more than 3',
                          type: 'error',
                        });
                        setOpenToast(true);
                      }
                    }}
                  >
                    <img src={Add_New_Icon} alt="add" />
                    ADD NEW
                    <span className="roleText">Role</span>
                  </Button>
                )}

                <Toast
                  open={openToast}
                  close={() => {
                    setOpenToast(false);
                  }}
                  {...toast}
                />

                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                      <Grid className={tableClasses.commonSelectMenu}>
                        <Typography
                          variant="body1"
                          className="textFieldCaption"
                        >
                          Title
                        </Typography>
                        <FormControl fullWidth>
                          <Select
                            value={values.Title || 'Director of Admissions'}
                            defaultValue="Director of Admissions"
                            onChange={handleChange}
                            name="Title"
                            inputProps={{
                              name: 'Title',
                              id: 'type-required',
                            }}
                            variant="outlined"
                          >
                            {advisorTitles.map((title) => (
                              <MenuItem key={title} value={title}>
                                {title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid item lg={4} md={6} sm={6} xs={12}>
                      <Grid className="genderDropdown timeZoneSelect acceptedToDropdown">
                        <Typography
                          variant="body1"
                          className="textFieldCaption"
                        >
                          College
                        </Typography>

                        <SearchColleges
                          isMulti={false}
                          label=""
                          values={values?.college || null}
                          onSelect={(college: OptionType) => {
                            setFieldValue('college', college);
                          }}
                        />
                        <Typography variant="caption" className="errorText">
                          {errors && errors.college}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                      <Grid className={tableClasses.collegeNameSelect}>
                        <Typography
                          variant="body1"
                          className="textFieldCaption"
                        >
                          Current
                        </Typography>
                        <Checkbox
                          color="primary"
                          checked={values.current}
                          disabled={currentWorkingAt}
                          onChange={(e) => {
                            setFieldValue('current', e.target.checked);
                          }}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        <Typography variant="caption" className="requiredText">
                          {errors && errors.current}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item lg={4} md={12} sm={12} xs={12}>
                      <Box
                        className={tableClasses.formActionButtons}
                        display="flex"
                        pt={3}
                      >
                        <Button
                          variant="outlined"
                          onClick={() => setOpen(!open)}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => handleSubmit()}
                          variant="contained"
                          disabled={isSubmitting}
                        >
                          Save
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
};

export default CreatePreviouslyWorkedAt;
