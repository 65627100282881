import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from 'chart.js';
import { FC } from 'react';
import { Chart } from 'react-chartjs-2';
import { ICapacityChart } from '../../common/interfaces';

import moment from 'moment';
import { toDecimalPoint, upcomingMonthsLabels } from '../../common/utility';
import { chartLabels } from '../../constants';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  LineController,
  BarController
);

const CapacityChart: FC<ICapacityChart> = ({ chartData }) => {
  if (!chartData) return null;

  const { max, last30Days, estimation } = chartData;

  console.log(
    'MAX_VAL:',
    max?.hours ? toDecimalPoint(Number(max.hours), 1) : null
  );

  const dataObj = {
    labels: upcomingMonthsLabels(),
    datasets: [
      {
        type: 'line' as const,
        label: 'Max',
        borderColor: '#2F80ED',
        backgroundColor: '#2F80ED',
        data: max?.hours
          ? new Array(8).fill(toDecimalPoint(Number(max.hours), 1))
          : null,
        fill: true,
      },
      {
        label: 'Last 30 Days',
        data: [last30Days.value ? toDecimalPoint(last30Days.value, 1) : null],
        type: 'line' as const,
        borderColor: '#FDBD4E',
        backgroundColor: '#FDBD4E',
        borderWidth: 6,
        fill: true,
      },

      {
        type: 'bar' as const,
        label: 'Estimation',
        backgroundColor: '#EDFBF5',
        data: estimation
          ? estimation.map((cData, ind) => {
              const dataItem = estimation.find((el) => el.order === ind);
              if (dataItem) {
                return [
                  toDecimalPoint(Math.max(dataItem.value - 3, 0), 1),
                  toDecimalPoint(dataItem.value + 3, 1),
                ];
              }
            })
          : (new Array(8).fill(0) as any),
        hoverBackgroundColor: ['#D0F2E4'],
        borderColor: '#A1E6C8',
        borderWidth: {
          bottom: 2,
          top: 2,
          left: 0,
          right: 0,
        },
      },
    ],
  };
  const options = {
    plugins: {
      tooltip: {
        footerFont: {
          weight: 'bold',
        },
        titleFont: {
          weight: 'bold',
          size: 16,
        },
        bodyFont: {
          size: 12,
        },
        backgroundColor: 'transparent',
        bodyColor: '#7D7F7F',
        caretSize: 0,
        cornerRadius: 0,
        callbacks: {
          label: function (context: any) {
            if (context.dataset.label === chartLabels.ESTIMATION) {
              const { raw } = context;
              const valueLabel = `${toDecimalPoint(
                raw[0],
                1
              )} - ${toDecimalPoint(raw[1], 1)}  hours/week`;
              return ' ' + valueLabel;
            } else if (context.dataset.label === chartLabels.MAX) {
              return (
                ' ' + toDecimalPoint(Number(max?.hours) || 0, 1) + ' hours/week'
              );
            } else if (context.dataset.label === chartLabels.LAST30DAYS) {
              return ' ' + toDecimalPoint(last30Days.value, 1) + ' hours/week';
            }
          },

          title: function (context: any) {
            const updatedContext = context[0];
            if (updatedContext.dataset.label === chartLabels.ESTIMATION) {
              const { label } = updatedContext;
              const month = label.slice(0, 3);
              const year = label.slice(-2);
              const date = new Date(month + ' 01 ' + year);
              const monthLabel = moment(date).format("MMM 'YY");
              return monthLabel;
            } else if (updatedContext.dataset.label === chartLabels.MAX) {
              return 'MAX';
            } else if (
              updatedContext.dataset.label === chartLabels.LAST30DAYS
            ) {
              return 'Last 30 Days';
            }
          },
        },
        titleColor: function (context: any) {
          const { tooltipItems } = context;
          if (!tooltipItems[0]) return '';
          const {
            dataset: { label },
          } = tooltipItems[0];
          if (label === chartLabels.ESTIMATION) {
            return '#45CE93';
          } else if (label === chartLabels.MAX) {
            return '#2F80ED';
          } else if (label === chartLabels.LAST30DAYS) {
            return '#FCA713';
          }
        },
      },
    },
    responsive: true,
  };

  return <Chart type="bar" data={dataObj} options={options} />;
};

export default CapacityChart;
