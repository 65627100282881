import colors from '../../../../common/colors';
import { calculatePercentageAndColorsToChoseProps } from '../../../../common/interfaces';

export const calculatePercentageAndColorsToChose = (
  hoursUsed: number,
  totalHours: number,
  activityPerformed: number
): calculatePercentageAndColorsToChoseProps => {
  const percentage = ((hoursUsed + activityPerformed) / totalHours) * 100;
  return percentage >= 75 && percentage < 100
    ? {
        percentage,
        HoursCalculatedColor: colors.ORANGE_TWO,
        InnerColor: colors.ORANGE_THREE,
        percentageValue: 75,
      }
    : percentage >= 100
    ? {
        percentage,
        HoursCalculatedColor: colors.RED_THREE,
        InnerColor: colors.RED_FOUR,
        percentageValue: 100,
      }
    : {
        percentage: null,
        HoursCalculatedColor: colors.GRAY_THIRTEEN,
        InnerColor: colors.BLUE_SEVEN,
        percentageValue: null,
      };
};
