import { FC, useState } from 'react';
import clsx from 'clsx';
import { Query } from 'react-apollo';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableFooter,
  TablePagination,
  Typography,
  Grid,
  Box,
  Hidden,
} from '@material-ui/core';
import { useStyles } from './MyStudentsStyle';
import MeetingTableRecord from './MeetingTableRecord';
import Loader from '../../../components/Loader';

import { ALL_ADVISOR_BOOKINGS } from '../meetings/gql';
import { allAdvisorBookingsQuery } from '../meetings/gql/__generated__/allAdvisorBookingsQuery';
import { bookingInfoStatuses } from '../../../constants';
import StudentMeetingsTabs from './StudentMeetingsTabs';
import { fetchResultProp, StudentMeetingsTableProps } from '../../../common/interfaces';
const MeetingsTable: FC<StudentMeetingsTableProps> = ({
  title,
  email,
  studentId,
}) => {
  const classes = useStyles();
  const limit = 10;
  const [upcomingPage, setUpcomingPage] = useState(0);
  const [pastPage, setPastPage] = useState(0);

  function getData(page: number, fetchMore: Function, status: string) {
    fetchMore({
      variables: {
        page: page + 1,
      },
      updateQuery: (prev: allAdvisorBookingsQuery, next: fetchResultProp) => {
        if (!next) return prev;
        const { fetchMoreResult } = next;
        if (
          !fetchMoreResult ||
          !fetchMoreResult.allAdvisorBookings ||
          !fetchMoreResult.allAdvisorBookings.advisorBookings
        )
          return prev;
        return fetchMoreResult;
      },
    });
    status === bookingInfoStatuses.UPCOMING ? setUpcomingPage(page) : setPastPage(page);
  }
  return (
    <>
      <Typography variant="h4" className={classes.studentSectionHeader} style={{ borderBottom: 'none' }}>
        {title}
      </Typography>

      <Query<allAdvisorBookingsQuery>
        query={ALL_ADVISOR_BOOKINGS}
        variables={{
          status: bookingInfoStatuses.UPCOMING,
          studentId,
          page: upcomingPage + 1,
          limit,
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading, fetchMore, refetch }) => {
          if (loading) return <Loader />;

          return data?.allAdvisorBookings?.advisorBookings?.length ? (
            <Grid className={classes.commonTable}>
              <Table>
                <Hidden only={['sm', 'xs']}>
                  <TableHead>
                    <TableRow>
                      <TableCell className="theadAvatarSpacing" width="30%">
                        Advisors
                      </TableCell>
                      <TableCell width="15%">Date</TableCell>
                      <TableCell width="25%">Time</TableCell>
                      <TableCell width="20%">Meeting Link</TableCell>
                      <TableCell width="5%"></TableCell>
                    </TableRow>
                  </TableHead>
                </Hidden>
                <TableBody>
                  {data?.allAdvisorBookings?.advisorBookings?.map(
                    (item, index) => (
                      <MeetingTableRecord
                        key={`${index}-upcoming`}
                        email={email}
                        item={item}
                        refetch={refetch}
                        status={bookingInfoStatuses.UPCOMING}
                      />
                    )
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={7}>
                      <TablePagination
                        className='tableBorder'
                        rowsPerPageOptions={[2]}
                        component="div"
                        count={data.allAdvisorBookings?.count || 0}
                        rowsPerPage={limit}
                        page={upcomingPage}
                        onChangePage={(e, page) => {
                          getData(page, fetchMore, 'upcoming');
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </Grid>
          ) : (
            <Box className={classes.emptyStates} borderBottom={'1px solid #D4D4D4'}>
              <Typography variant="body1">
                No upcoming meetings found
              </Typography>
            </Box>
          );
        }}
      </Query>

      <Grid className={classes.commonTable}>
        <Box
          className={clsx(classes.tableAccordionChild, classes.tableAccordion)}
        >
          <Typography className={`${classes.studentSectionHeader} ${classes.pastMeetings}`}>
            Past Meetings
          </Typography>
          <StudentMeetingsTabs studentId={studentId}/>
        </Box>
      </Grid>
    </>
  );
};

export default MeetingsTable;
