import { FC, useContext, Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  Grid,
  Container,
  Typography,
  Card,
  Box,
  Button,
} from '@material-ui/core';
import PaymentIcon from '@material-ui/icons/Payment';

import gql from 'graphql-tag';
import queryString from 'query-string';
import { Mutation } from 'react-apollo';
import { AppContext } from '../../../contexts';
import {
  CreateStripeConnectAccountTokenMutation,
  CreateStripeConnectAccountTokenMutationVariables,
} from './__generated__/CreateStripeConnectAccountTokenMutation';

import Header from '../../../components/layout/Header';
import AccountMenu from '../../../components/AccountMenu';
import FooterNew from '../../../components/layout/FooterNew';

import { useStylesAccount } from './accountStyle';

const CREATE_STRIPE_CONNECT_ACCOUNT_TOKEN_MUTATION = gql`
  mutation CreateStripeConnectAccountTokenMutation($code: String!) {
    createStripeConnectAccountToken(input: { code: $code }) {
      status
    }
  }
`;

let requestInProgress = false;

const BillingPage: FC<RouteComponentProps> = ({ history, match }) => {
  // TODO: Refactor
  const callCreateStripeConnect = async (
    createStripeConnectAccountToken: Function,
    code: string
  ) => {
    try {
      await createStripeConnectAccountToken({
        variables: {
          code,
        },
      });
      history.push('/account/billing');
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const classes = useStylesAccount();

  const { user, setUser } = useContext(AppContext);
  const userId = user ? user.id : null;

  const query = queryString.parse(history.location.search);
  const { code, state } = query;

  if (!user) return null;

  return (
    user && (
      <Fragment>
        <Header navigateTo={history.push} />

        <Container maxWidth={false} className="accountContainer">
          <Typography variant="h3" className="accountHeader">
            My Account
          </Typography>

          <Grid container spacing={2}>
            <Grid item md={3} sm={12} xs={12}>
              <AccountMenu />
            </Grid>

            <Grid item md={9} sm={12} xs={12}>
              <Card className={classes.accountInfoCard}>
                <Box className="infoCardHeader">
                  <Typography variant="h5">Billing</Typography>
                </Box>

                <Grid className="accountInfoBody">
                  {user.stripeConnectAccount ? (
                    <h4>Stripe account is connected!</h4>
                  ) : (
                    <>
                      <a
                        href={`https://connect.stripe.com/express/oauth/authorize?redirect_uri=${process.env.REACT_APP_CURRENT_DOMAIN}/account/billing&client_id=${process.env.REACT_APP_STRIPE_OAUTH_CLIENT_ID}&state=${userId}`}
                      >
                        <Button
                          variant="contained"
                          className={classes.connectStripe}
                        >
                          <PaymentIcon />
                          Connect with Stripe
                        </Button>
                      </a>

                      <Mutation<
                        CreateStripeConnectAccountTokenMutation,
                        CreateStripeConnectAccountTokenMutationVariables
                      >
                        mutation={CREATE_STRIPE_CONNECT_ACCOUNT_TOKEN_MUTATION}
                        onCompleted={() => {
                          console.log('on complete....');
                          setUser({
                            ...user,
                            stripeConnectAccount: true,
                          });
                        }}
                      >
                        {(createStripeConnectAccountToken) => {
                          if (userId == state && typeof code === 'string') {
                            if (!requestInProgress) {
                              requestInProgress = true;
                              callCreateStripeConnect(
                                createStripeConnectAccountToken,
                                code
                              );
                            }
                          }
                          return <Fragment />;
                        }}
                      </Mutation>
                    </>
                  )}
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Container>

        <FooterNew />
      </Fragment>
    )
  );
};

export default BillingPage;
