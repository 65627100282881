import clsx from 'clsx';
import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Dialog,
  IconButton,
  Typography,
  DialogContent,
  makeStyles,
} from '@material-ui/core';

import ResultPage from './ResultPage';
import TimeLogForm from './TimeLogForm';

import { useStyles } from './style/editDialogStyle';

import Close_Dialog_img from '../../../../img/dialog-new-icon.svg';

const useStylesDialog = makeStyles(() => ({
  editTimeDialogWidth: ({
    isDisabledState,
  }: {
    isDisabledState?: boolean;
  }) => ({
    '& .MuiDialog-paperFullWidth': {
      maxWidth: isDisabledState ? 462 : 1057,
    },

    '& .MuiDialogContent-root': {
      padding: isDisabledState ? '0px 76px 44px 49px' : '0px 50px 41px',
    },
  }),

  editDialogHeaderLogActivity: ({
    isDisabledState,
  }: {
    isDisabledState?: boolean;
  }) => ({
    padding: isDisabledState ? '44px 54px 28px 49px' : '30px 50px',
  }),
}));

const EditDialog = ({
  item,
  open,
  closeDialog,
  refetch,
  isDisabledState = false,
  fromMyMeetingsPage = false,
}: {
  item: any;
  open: boolean;
  closeDialog: Function;
  refetch: Function;
  isDisabledState?: boolean;
  fromMyMeetingsPage?: boolean;
}) => {
  const classes = useStyles();
  const dialogClasses = useStylesDialog({ isDisabledState });

  const allStudentApplications =
    item.MentorTimeLogAssociations?.studentApplications
      ?.filter(
        (item: {
          applicationRequirement: { college: { ParentCollegeId: number } };
        }) => !item?.applicationRequirement?.college?.ParentCollegeId
      )
      ?.map(
        (item: {
          id: any;
          applicationRequirement: { college: { name: string } };
        }) => ({
          value: item?.id,
          label: item?.applicationRequirement?.college?.name,
        })
      );

  const UCSubCampusApplications =
    item.MentorTimeLogAssociations?.studentApplications
      ?.filter(
        (item: {
          applicationRequirement: { college: { ParentCollegeId: number } };
        }) => item?.applicationRequirement?.college?.ParentCollegeId
      )
      ?.map(
        (application: {
          id: any;
          applicationRequirement: { college: { name: string } };
        }) => ({
          value: application?.id,
          label: application?.applicationRequirement?.college?.name,
        })
      );

  if (UCSubCampusApplications?.length) {
    allStudentApplications?.push({
      value: 0,
      label: 'University of California (any)',
    });
  }

  const initialValues = {
    StudentId: item?.student?.id,
    workType: item.workType,
    workActivity: item.workActivity,
    PremiumEntitlementId: item.PremiumEntitlementId,
    activityAt: item.activityAt,
    activityDuration: +item.activityDuration,
    activityDescription: item.activityDescription,
    PaymentCategoryId: item?.mentorPaymentCategory?.id || '',
    note: item.note,
    missCall: item.missCall,
    durationNote: item.durationNote,
    isCommonApp: item.isCommonApp,
    isCoalitionApp: item.isCoalitionApp,
    isOtherStrategy: item.isOtherStrategy,
    UCSubCampusApplications: UCSubCampusApplications,
    otherAdvisorIds: [],
    airtableSubmitted: item?.airtableSubmitted || false,
    ...(item?.milestone && {
      milestone: item?.milestone,
      serviceElement: item?.serviceElement,
    }),
    milestoneTeam: item.MentorTimeLogAssociations?.milestoneTeamName,
    entitlementType: item.workActivity,
    selectedStudentApplications: allStudentApplications,
    studentApplications:
      item.MentorTimeLogAssociations?.studentApplications?.map(
        (item: {
          id: number;
          applicationRequirement: { college: { name: string } };
        }) => ({
          value: item?.id,
          label: item?.applicationRequirement?.college?.name,
        })
      ) || [],
    advisorBookingId: item.MentorTimeLogAssociations?.advisorBookingId || 0,
    otherMeetingDescription:
      item.MentorTimeLogAssociations?.otherMeetingDescription || '',
    noteForExtendedMeeting:
      item.MentorTimeLogAssociations?.noteForExtendedMeeting || '',
    noteForNoOrientationCall:
      item.MentorTimeLogAssociations?.noteForNoOrientationCall || '',
    timeLogAssociationId: item.MentorTimeLogAssociations?.id,
    isProjectWork: item.isProjectWork,
  };
  const otherAdvisors = item.MentorTimeLogAssociations?.allAssociatedAdvisors
    ? item.MentorTimeLogAssociations?.allAssociatedAdvisors?.map(
        (advisor: { firstName: string; lastName: string }) =>
          `${advisor.firstName} ${advisor.lastName}`
      )
    : [];
  const [initialStateValues, setInitialValues] = useState<any>({
    ...initialValues,
  });
  const [advisorsNames, setAdvisorsNames] = useState([...otherAdvisors]);
  const [selectedStudent, setSelectedStudent] = useState({
    value: item?.student?.id,
    label: `${item?.student?.firstName} ${item?.student?.lastName} (${item?.student?.email})`,
    initialKickOffDate: item?.student?.initialKickOffDate,
  });
  const [expectedPayement, setExpectedPayment] = useState('');
  const [screen, setScreen] = useState('form');
  const onSubmitForm = () => {
    setScreen('result');
  };
  const [changeWorkType, setChangWorkType] = useState(false);
  const [workType, setWorkType] = useState(item.workType);
  const handleCloseDialog = () => {
    if (fromMyMeetingsPage) {
      setScreen('form');
    } else {
      setChangWorkType(false);
      screen === 'result' && refetch();
      screen === 'form' && setWorkType(item.workType);
      screen === 'form' && setInitialValues({ ...initialValues });
      screen === 'form' &&
        setSelectedStudent({
          value: item?.student?.id,
          label: `${item?.student?.firstName} ${item?.student?.lastName} (${item?.student?.email})`,
          initialKickOffDate: item?.student?.initialKickOffDate || null,
        });
    }
    closeDialog();
  };
  const onChangeWorkType = (val: string) => {
    setWorkType(val);
    setChangWorkType(false);
  };

  useEffect(() => {
    setSelectedStudent({
      value: item?.student?.id,
      label: `${item?.student?.firstName} ${item?.student?.lastName} (${item?.student?.email})`,
      initialKickOffDate: item?.student?.initialKickOffDate,
    });
    setWorkType(item.workType);
  }, [item]);

  return (
    <>
      <Dialog
        className={clsx(
          classes.editTimeDialog,
          dialogClasses.editTimeDialogWidth
        )}
        open={open}
        fullWidth
      >
        {screen === 'result' && (
          <IconButton onClick={handleCloseDialog} className="closeIconButton">
            <img src={Close_Dialog_img} alt="close" />
          </IconButton>
        )}

        {screen === 'form' && (
          <Grid
            className={clsx(
              classes.editDialogHeader,
              dialogClasses.editDialogHeaderLogActivity
            )}
          >
            <Box
              mb={1}
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5">
                {isDisabledState ? 'Logged Activity' : 'Modify Activity'}
              </Typography>

              <IconButton onClick={handleCloseDialog}>
                <img src={Close_Dialog_img} alt="close" />
              </IconButton>
            </Box>

            <Box>
              <Typography variant="h6" className="headingSubTitle">
                {!changeWorkType && screen === 'form' && workType}{' '}
                <span>Details</span>{' '}
                {!changeWorkType &&
                  screen === 'form' &&
                  !isDisabledState &&
                  initialValues.workActivity !==
                    'Video Chat Session, 2+ Advisors' &&
                  initialValues.workActivity !==
                    'Orientation/Kick-Off Call, 2+ Advisors' && (
                    <a onClick={() => setChangWorkType(!changeWorkType)}>
                      Change
                    </a>
                  )}{' '}
              </Typography>
            </Box>
          </Grid>
        )}

        <DialogContent
          className="editTimeDialogContent"
          style={{ paddingTop: screen === 'result' ? 41 : 0 }}
        >
          {screen === 'form' && (
            <TimeLogForm
              item={item}
              onBoarded={item.onBoarding.onBoarded}
              editId={item.id}
              workType={workType}
              onSubmit={onSubmitForm}
              studentValue={selectedStudent}
              setStudentValue={setSelectedStudent}
              setInitialValues={setInitialValues}
              setExpectedPayment={setExpectedPayment}
              initialValues={initialStateValues}
              changeWorkType={changeWorkType}
              setWorkType={onChangeWorkType}
              isDisabledState={isDisabledState}
              setAdvisorsNames={setAdvisorsNames}
              advisorsNames={advisorsNames}
            />
          )}
          {screen === 'result' && (
            <ResultPage
              initialValues={initialStateValues}
              studentValue={selectedStudent}
              workType={workType}
              expectedPayement={expectedPayement}
              advisorsNames={advisorsNames}
              handleCloseDialog={handleCloseDialog}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditDialog;
