import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Colors from '../../../../common/colors';
import fonts from '../../../../common/fonts';
import Fonts from '../../../../common/fonts';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addAdvisor: {
      padding: '29px 50px 35px',

      [theme.breakpoints.down('sm')]: {
        padding: 15,
      },
    },
    advisorScreenBottom: {
      borderTop: 'none !important',
      '& .buttonNext': {
        width: 141,
        height: 40,
        padding: '8px 16px',
        borderRadius: 12,
        textTransform: 'capitalize',
        fontSize: 14,
        fontFamily: fonts.POPPINS_FONT,
        backgroundColor: '#2F80ED',

        '&.Mui-disabled': {
          cursor: 'not-allowed',
          opacity: 0.5,
          color: 'rgba(0, 0, 0, 0.26)',
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
        },

        '&:hover': {
          background: '#2668C1',
        },

        '&:focus': {
          outline: '0px !important',
          boxShadow: 'none !important',
        },
      },
    },

    dialogPaperHeader: {
      padding: '26px 38px 26px 50px',
      borderBottom: `1px solid ${Colors.WHITE_SEVEN}`,

      [theme.breakpoints.down('sm')]: {
        padding: 15,
      },
    },

    advisingMeeting: {
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        padding: 0,
      },

      '& svg': {
        marginLeft: 10,
      },

      '& h4': {
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '-0.022em',
        color: '#272929',
        fontFamily: fonts.POPPINS_FONT,
        maxWidth: 300,

        [theme.breakpoints.down('sm')]: {
          fontSize: 18,
          lineHeight: '25px',
          paddingRight: 20,
        },

        '& img': {
          [theme.breakpoints.down('sm')]: {
            width: 18,
          },
        },
      },

      '& .valueTextBox': {
        width: 392,
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#272929',
        fontFamily: fonts.INTER,
        paddingLeft: 40,

        [theme.breakpoints.down('xs')]: {
          paddingLeft: '0px !important',
        },
      },
    },

    disabledLabel: {
      opacity: `0.5`,
      cursor: 'not-allowed !important',
      color: 'rgba(0, 0, 0, 0.26)',
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
    },

    advisorCard: {
      '& .advisorInfoCard': {
        boxShadow: 'none',
        border: `1px solid ${Colors.BLUE_SEVEN}`,
        borderRadius: 20,
        cursor: 'pointer',
        padding: '12px 21px',

        [theme.breakpoints.down('sm')]: {
          marginBottom: 20,
        },

        '& .advisorButton': {
          visibility: 'hidden',
          padding: 12,
          marginRight: -12,
        },

        '&:hover': {
          background: Colors.WHITE_EIGHT,

          '& .advisorButton': {
            visibility: 'visible',
          },
        },

        '& .advisorAvatar': {
          height: 47,
          width: 47,
          marginRight: 18,
        },
      },
    },

    datePickerWrapper: {
      padding: '49px 50px 10px',
      background: 'rgba(244, 244, 244, 0.5)',
      borderTop: `1px solid ${Colors.WHITE_SEVEN}`,

      [theme.breakpoints.down('sm')]: {
        padding: 15,
      },
    },

    radioGroupGrid: {
      position: 'relative',
      marginRight: 14,

      [theme.breakpoints.down('sm')]: {
        marginRight: 10,
        width: '46%',
      },
    },

    radioGroupSlots: {
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        width: '100%',
        padding: '0px 15px',
        overflowX: 'hidden',
      },
    },

    radioGroup: {
      display: 'flex',
      alignItems: 'center',

      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        alignItems: 'flex-start',
      },

      '& input[type="radio"]': {
        visibility: 'hidden',
        height: 34,
        width: '125px',
        position: 'absolute',
      },

      '& label': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 34,
        width: 125,
        fontSize: 14,
        fontWeight: 600,
        borderRadius: 12,
        cursor: 'pointer',
        color: Colors.BLACK_TWELVE,
        letterSpacing: '-0.006em',
        backgroundColor: Colors.WHITE_ONE,
        border: `1px solid ${Colors.WHITE_SEVEN}`,
        margin: 0,
        fontFamily: fonts.POPPINS_FONT,

        [theme.breakpoints.down('sm')]: {
          marginBottom: 15,
          width: '100%',
        },
      },

      '& input[type="radio"]:checked + label': {
        backgroundColor: Colors.BLUE_SEVEN,
        color: Colors.WHITE_ONE,
        border: `1px solid ${Colors.BLUE_SEVEN}`,
        transition: 'all .3s ease-out',
      },
    },

    meetingRadioContainer: {
      padding: '43px 50px',
      borderTop: `1px solid ${Colors.WHITE_SEVEN}`,

      '& .MuiTypography-h5': {
        fontFamily: `${fonts.POPPINS_FONT} !important`,
      },

      [theme.breakpoints.down('sm')]: {
        padding: 15,
      },
    },

    availableTimeSlots: {
      paddingLeft: '50px !important',
      margin: '65px 0px 0px 0',

      [theme.breakpoints.down('sm')]: {
        margin: 0,
        paddingLeft: '16px !important',
      },

      '& input[type="radio"]': {
        height: 40,
        width: '100%',
      },

      '& label': {
        width: '100%',
        height: 40,
        marginBottom: 13,
        fontFamily: fonts.POPPINS_FONT,
      },
    },

    radioNotFlex: {
      display: 'initial',
    },

    closeButton: {
      position: 'absolute',
      right: 45,
      top: 44,

      [theme.breakpoints.down('xs')]: {
        right: 10,
        top: 15,
      },
    },

    meetingBookedContainer: {
      padding: '58px 55px 58px  51px !important',

      [theme.breakpoints.down('xs')]: {
        padding: '30px 12px !important',
      },

      '& .MuiTypography-h6': {
        fontFamily: fonts.INTER,
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#A9A9A9',
      },

      '& .avatarBig': {
        width: 72,
        height: 72,
        marginLeft: 'auto',
        border: 0,

        [theme.breakpoints.down('xs')]: {
          marginLeft: 0,
        },
      },

      '& .avatarHeading': {
        [theme.breakpoints.down('sm')]: {
          paddingBottom: 30,
        },

        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
      },
    },

    scheduleDetail: {
      paddingBottom: 24,

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },

      '&:last-child': {
        paddingBottom: 0,
      },

      '& .headingBoxLeft': {
        width: 170,

        [theme.breakpoints.down('sm')]: {
          width: 170,
        },

        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },

      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
      },

      '& .valueText': {
        [theme.breakpoints.down('sm')]: {
          fontWeight: 'normal',
        },
      },

      '& .valueTextNote': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        lineHeight: '28px !important',
      },
    },

    bookAppointment: {
      '& .menu-button': {
        padding: 0,
        minWidth: 40,
        height: 40,
        borderRadius: '50%',

        '& img': {
          margin: 0,
        },
      },

      '& .booking-card': {
        marginBottom: 21,
        padding: '27px 32px',
        border: '1px solid #D4D4D4',
        overflow: 'hidden',
        boxShadow: '0px 4px 14px rgb(68 68 68 / 14%)',
        borderRadius: '15px',
        [theme.breakpoints.down('sm')]: {
          padding: 15,
        },

        '&:hover': {
          background: Colors.GRAY_BOREDER,
        },
      },

      '& .booking-card-disabled': {
        background: Colors.GRAY_BOREDER,
        cursor: 'not-allowed',
      },

      '& .avatar-group': {
        width: 100,

        [theme.breakpoints.down('sm')]: {
          width: 'auto',
          margin: '3px 10px 0px 0px',
        },
      },

      '& .past-meetings': {
        borderTop: `1px solid ${Colors.WHITE_SEVEN}`,
      },

      '& .box-spacing': {
        [theme.breakpoints.down('sm')]: {
          padding: '15px 0px',
        },
      },

      '& .boxSpacingTypo': {
        [theme.breakpoints.down('sm')]: {
          padding: '40px 0px 50px',
        },
      },

      '& .mobile-flex-main': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          width: '100%',
        },
      },

      '& .mobile-flex-direction': {
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          width: '100%',

          '&:first-child': {
            alignItems: 'flex-start',
          },
        },
      },

      '& .text-box-spacing': {
        [theme.breakpoints.down('sm')]: {
          margin: '10px 0px 0px 0px',
        },
      },

      '& .user-avatar': {
        width: 33,
        height: 33,
      },

      '& .avatar-disabled': {
        filter: 'grayscale(1)',
      },

      '& .avatarBigContainer': {
        justifyContent: 'flex-end',

        "& [class*='MuiAvatarGroup-avatar']": {
          width: 72,
          height: 72,
          fontSize: 36,
        },
      },

      '& .pagination-wrapper': {
        textAlign: 'right',
      },

      '& .pagination-spacing': {
        padding: 0,

        '& button': {
          padding: 5,
        },

        '& svg': {
          width: 25,
          height: 25,
        },
      },
    },
    meetingDialogMain: {
      '& .MuiDialog-paper': {
        background: '#FFFFFF',
        border: '1px solid #D4D4D4',
        borderRadius: 25,
        maxWidth: 551,

        [theme.breakpoints.down('xs')]: {
          margin: '0px 10px !important',
        },
      },
    },
    mobileAlignment: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
    },

    bookingTableRow: {
      border: '1px solid #D4D4D4',
      borderRadius: 15,
      boxShadow: '0px 2px 7px rgba(68, 68, 68, 0.14)',
    },

    mobileMeetingsView: {
      padding: '14px 16px',
      borderRadius: 10,
      border: '1px solid #D4D4D4',
      marginBottom: 15,
      boxShadow: '0px 2px 7px rgba(68, 68, 68, 0.07)',

      '& h6, p': {
        color: '#272929',
        fontFamily: Fonts.INTER,
      },

      '& h6': {
        fontSize: 18,
        fontWeight: 600,
        letterSpacing: '-0.014em',
        textTransform: 'capitalize',
      },

      '& p': {
        fontSize: 16,
        letterSpacing: '-0.011em',
      },

      '& a': {
        fontSize: 16,
        color: '#2F80ED',
        fontFamily: Fonts.INTER,
        letterSpacing: '-0.011em',
        textDecorationLine: 'underline !important',
      },

      '& .linkSpacing': {
        marginBottom: 11,
      },

      '& .MuiIconButton-root': {
        padding: 5,
      },
    },

    meetingNotesDialog: {
      padding: '38px 42px 48px',

      '& .dialogHeading': {
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '-0.019em',
        color: '#272929',
      },

      '& .dialogSubtitle': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
      },
    },

    meetingNoteArea: {
      padding: '30px 0px 0px',

      '& .meetingGrid': {
        width: '100%',
        borderTop: '1px solid #d4d4d4',
        paddingTop: 30,
        marginTop: 10,
      },

      '& .dialogHeading': {
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#272929',
        fontFamily: fonts.INTER,
        marginBottom: 10,
      },

      '& .dialogSubtitle': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
        marginTop: 5,
        fontFamily: fonts.INTER,
      },

      '& textarea': {
        border: '1px solid #D4D4D4',
        width: '100%',
        borderRadius: 5,
        padding: '8px 12px',
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        height: 82,
        overflowY: 'auto',
      },

      '& div': {
        width: '100%',
      },
    },

    dialogActionText: {
      justifyContent: 'space-between !important',

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-end',
      },

      '& button': {
        minWidth: 197,

        [theme.breakpoints.down('xs')]: {
          marginTop: 20,
          marginLeft: 'auto',
        },
      },

      '& .advisorTextGrid': {
        maxWidth: 334,

        [theme.breakpoints.down('xs')]: {
          maxWidth: '100%',
        },
      },

      '& .headingText': {
        textTransform: 'capitalize',
        fontFamily: fonts.INTER,
      },

      '& .subtitleText': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
      },
    },

    bookMeetingDialog: {
      '& .MuiDialog-paper': {
        border: '1px solid #D4D4D4',
        margin: 0,
        background: '#ffffff',
        overflowY: 'auto',
        borderRadius: 25,
        width: 818,
        maxWidth: 818,

        [theme.breakpoints.down('xs')]: {
          maxWidth: '95%',
        },

        '& .advisingMeeting': {
          [theme.breakpoints.down('xs')]: {
            alignItems: 'flex-start',
            paddingBottom: 20,
          },
        },

        '& .MuiTypography-h4': {
          color: '#272929',
          fontSize: 24,
          fontFamily: fonts.POPPINS_FONT,
          fontWeight: 600,
          letterSpacing: '-0.019em',
          paddingRight: 0,

          [theme.breakpoints.down('xs')]: {
            paddingRight: 0,
          },
        },

        '& .MuiDialogContent-root': {
          padding: 0,
        },

        '& .MuiTypography-h5': {
          color: '#272929',
          fontSize: 18,
          fontFamily: fonts.INTER,
          fontWeight: 600,
          letterSpacing: '-0.014em',
        },

        '& .advisorInfoCard': {
          '& .MuiTypography-body2': {
            fontFamily: fonts.INTER,
            fontSize: 14,
          },
        },

        "& .MuiDatePicker [class*='MuiPickersStaticWrapper-staticWrapperRoot']":
          {
            backgroundColor: 'transparent',
          },

        "& .MuiDatePicker [class*='MuiPickersCalendarHeader-switchHeader']": {
          margin: '0 auto',
          padding: '16px 0 25px 0px',
          maxWidth: '55%',
        },

        "& .MuiDatePicker [class*='MuiPickersCalendarHeader-iconButton']": {
          padding: 0,
          background: 'transparent',
        },

        "& .MuiDatePicker [class*='MuiPickersCalendarHeader-switchHeader'] > div > p":
          {
            color: '#525454',
            fontSize: 14,
            fontFamily: fonts.POPPINS_FONT,
            fontWeight: 600,
            textTransform: 'uppercase',
          },

        "& .MuiDatePicker [class*='MuiPickersCalendarHeader-daysHeader']": {
          borderBottom: '1px solid #D4D4D4',
          marginBottom: 16,
          paddingBottom: 15,
          justifyContent: 'space-between',
        },

        "& .MuiDatePicker [class*='MuiPickersCalendar-week']": {
          paddingBottom: 25,
          justifyContent: 'space-between',
        },

        "& .MuiDatePicker [class*='MuiPickersCalendar-transitionContainer']": {
          marginTop: 0,
          minHeight: 325,
        },

        "& .MuiDatePicker [class*='MuiPickersCalendar-week'] > div button": {
          width: 29,
          height: 29,
        },

        "& .MuiDatePicker [class*='MuiPickersCalendar-week'] > div [class*='MuiPickersDay-daySelected'] span p":
          {
            color: '#ffffff',
          },

        // "& .MuiDatePicker [class*='MuiPickersCalendar-week'] > div [class*='MuiPickersDay-current']": {
        // background: '#dcdcdc !important',
        // borderRadius: 0,
        // },

        '& .MuiDatePicker': {
          "& [class*='MuiPickersStaticWrapper-staticWrapperRoot']": {
            background: 'transparent',
          },

          "& [class*='MuiPickersCalendarHeader-iconButton']": {
            background: 'transparent',
            padding: 0,
          },

          "& [class*='MuiPickersCalendarHeader-switchHeader']": {
            maxWidth: '55%',
            margin: '0 auto',
            padding: '16px 0 25px 0',

            '& > div': {
              '& > p': {
                fontFamily: Fonts.POPPINS_FONT,
                color: '#525454',
                fontSize: 14,
                textTransform: 'uppercase',
                fontWeight: 600,
              },
            },

            '& button': {
              '& span': {
                '& svg': {
                  color: '#525454',
                  height: 27,
                  width: 27,
                },
              },
            },
          },

          "& [class*='MuiPickersBasePicker-container']": {
            "& [class*='MuiPickersBasePicker-pickerView']": {
              maxWidth: '100%',
              minHeight: 'auto',
            },
          },

          "& [class*='MuiPickersCalendarHeader-daysHeader']": {
            borderBottom: `1px solid ${Colors.WHITE_SEVEN}`,
            paddingBottom: 15,
            justifyContent: 'space-between',
            marginBottom: 16,
          },

          "& [class*='MuiPickersCalendar-week']": {
            justifyContent: 'space-between',
            paddingBottom: 25,

            '& > div': {
              '& button': {
                width: 29,
                height: 29,

                '&:disabled': {
                  textDecoration: 'line-through',
                },

                '& span p': {
                  fontFamily: fonts.POPPINS_FONT,
                  color: '#525454',
                  fontSize: 14,
                  fontWeight: 600,
                },
              },

              "& [class*='MuiPickersDay-daySelected']": {
                background: Colors.BLUE_SEVEN,

                '& span p': {
                  color: Colors.WHITE_ONE,
                },
              },

              "& [class*='MuiPickersDay-current']": {
                // background: '#dcdcdc !important',
                // borderRadius: 0,

                '& span p': {
                  color: '#525454',
                },

                "&[class*='MuiPickersDay-dayDisabled']": {
                  background: '#dcdcdc !important',
                  borderRadius: 0,

                  '& span p': {
                    textDecoration: 'none',
                  },
                },
              },

              "& [class*='MuiPickersDay-dayDisabled']": {
                '& span p': {
                  textDecoration: 'line-through',
                  color: Colors.GRAY_TWENTYTWO,
                },
              },
            },

            "& [class*='MuiPickersCalendarHeader-dayLabel']": {
              fontFamily: Fonts.POPPINS_FONT,
              fontWeight: 600,
              fontSize: 12,
              color: '#7d7f7f',
              letterSpacing: '0.006em',
              textTransform: 'uppercase',
            },
          },

          "& [class*='MuiPickersCalendar-transitionContainer']": {
            marginTop: 0,
            minHeight: 325,
          },
        },

        '& .MuiDialogActions-root': {
          flex: '0 0 auto',
          display: 'flex',
          padding: '26px 50px',
          borderTop: '1px solid #D4D4D4',
          alignItems: 'center',
          justifyContent: 'flex-end',

          '& .MuiButton-root.MuiButton-containedPrimary': {
            minWidth: 197,
            height: 40,
            borderRadius: 12,
            boxShadow: 'none',
            fontSize: 14,
            letterSpacing: '-0.006em',
            textTransform: 'capitalize',
            backgroundColor: '#45CE93 !important',

            '&.Mui-disabled': {
              cursor: 'not-allowed',
              opacity: 0.5,
              color: 'rgba(0, 0, 0, 0.26) !important',
              backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
            },

            '& .MuiCircularProgress-colorPrimary': {
              color: '#45CE93',
              position: 'absolute',
              zIndex: -1,
            },
          },
        },

        '& .addAdvisor': {
          fontSize: 14,
          fontFamily: fonts.POPPINS_FONT,
          fontWeight: 600,
          letterSpacing: '0.006em',
          textTransform: 'uppercase',
        },

        '& .cursorPointer': {
          cursor: 'pointer',
        },
      },
    },
  })
);
