import { SyntheticEvent } from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export type Color = 'success' | 'info' | 'warning' | 'error';

const Toast = ({
  open,
  close,
  message = 'Success!',
  type = 'success',
  autoHideDuration = 6000,
}: {
  open: boolean;
  close: Function;
  message?: string;
  autoHideDuration?: number;
  type?: Color;
}) => {
  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    close(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
