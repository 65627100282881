import clsx from 'clsx';
import { Query } from 'react-apollo';
import { useState, useEffect, FC, useContext } from 'react';
import {
  Link,
  RouteComponentProps,
  useLocation,
  withRouter,
} from 'react-router-dom';

import {
  Box,
  Typography,
  Grid,
  Card,
  Button,
  Container,
} from '@material-ui/core';

import client from '../../../apollo';
import { AppContext } from '../../../contexts';
import { fomateDateUS } from '../../../common/utility';
import {
  pendingMentorTimeLogQuery,
  pendingMentorTimeLogQuery_mentorPendingTimeLogs_pendingTimeLogs,
} from './gql/__generated__/pendingMentorTimeLogQuery';
import { initialValues as initialFormState } from './static';
import {
  CHECK_ONBOARDED,
  GET_MENTOR_PAYMENT_CATEGORIES,
  PENDING_MENTOR_TIMELOG,
} from './gql';
import { ALL_MENTOR_TIME_LOGS_QUERY } from './gql/__generated__/ALL_MENTOR_TIME_LOGS_QUERY';

import Loader from '../../../components/Loader';
import Header from '../../../components/layout/Header';
import TimeLogForm from './NewTimeLogDesign/TimeLogForm';

import Info_Blue_Img from '../../../img/blue-info.svg';
import Danger_Img from '../../../img/danger-error.svg';
import Dress_Rehearsal_Img from '../../../img/dress.svg';
import Stripe_Token_Img from '../../../img/unverified-token.svg';
import Advisor_Operations_Img from '../../../img/student-advising.png';
import Student_Advising_Img from '../../../img/advisor-operations.svg';
import Milestone_Team_Img from '../../../img/milestone-team-icon.svg';
import ResultPage from './NewTimeLogDesign/ResultPage';
import { useStyles } from './style/NewTimeLogPageStyle';
import { getMentorPaymentCategories_mentorPaymentCategories } from './gql/__generated__/getMentorPaymentCategories';

type TParams = { id?: string; type?: string };

const NewTimeLogPage: FC<RouteComponentProps<TParams>> = ({
  match,
  history,
}) => {
  const classes = useStyles();
  const { user } = useContext(AppContext);
  const [workType, setWorkType] = useState('');
  const [screen1, setScreen1] = useState(true);
  const [screen2, setScreen2] = useState(false);
  const [screen3, setScreen3] = useState(false);
  const [collegeName, setCollegeName] = useState('');
  const [advisorsNames, setAdvisorsNames] = useState([
    `${user?.firstName} ${user?.lastName}`,
  ]);
  const [TeamName, setTeamName] = useState('');
  const [isSubmittingPendingTimelog, setIsSubmittingPendingTimelog] =
    useState<boolean>(false);
  const [count, setCount] = useState(0);
  const [expectedPayement, setExpectedPayment] = useState('');
  const [initialValues, setInitialValues] = useState<any>({
    ...initialFormState,
  });
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState<any>({
    value: '',
    label: '',
    initialKickOffDate: '',
  });
  const [logsOnBoarded, setLogsOnBoarded] = useState(false);
  const [GroupId, setGroupId] = useState('');
  const [pendingTimelogs, setPendingTimelogs] =
    useState<
      (pendingMentorTimeLogQuery_mentorPendingTimeLogs_pendingTimeLogs | null)[]
    >();
  const [mentorPaymentCategories, setMentorPaymentCategories] = useState<
    (getMentorPaymentCategories_mentorPaymentCategories | null)[]
  >([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const meetingId = searchParams.get('meetingId');
  const studentId = searchParams.get('studentId');
  const otherAdv = searchParams.get('otherAdv') === 'true';
  const canceledMeeting = searchParams.get('canceledMeeting') === 'true';
  const studentName = searchParams.get('studentName');
  const studentEmail = searchParams.get('studentEmail');
  const meetingDuration = searchParams.get('meetingDuration');
  const meetingDate = searchParams.get('date');
  const initialKickOffDate = searchParams.get('initialKickOffDate');
  const otherAdvisors: any = location.state;

  const dressRehearsalForNoneAO =
    process.env.REACT_APP_DRESS_REHEARSAL_FOR_NONE_AO?.split(',') || [];
  const { id, type } = match.params;
  const rates = mentorPaymentCategories?.map((item) => item?.rate);
  const rateIsNotSet = rates?.every((number) => number === 0);

  const handlePendingTimelog = (
    item: pendingMentorTimeLogQuery_mentorPendingTimeLogs_pendingTimeLogs
  ) => {
    let PaymentCategoryId;
    if (mentorPaymentCategories)
      PaymentCategoryId =
        mentorPaymentCategories.find(
          (item: any) => item && item?.paymentCategory?.name === 'video'
        )?.id || null;

    setInitialValues({
      ...initialFormState,
      StudentId: item.StudentId,
      workType: item.workType,
      workActivity: item.workActivity,
      activityAt: item.activityAt,
      missCall: item.missCall,
      activityDuration: item?.activityDuration ? +item?.activityDuration : '',
      PaymentCategoryId,
      milestone: item.milestone,
      serviceElement: item.serviceElement,
      meetingId: item.MentorTimeLogAssociations?.advisorBookingId,
      note: item.note,
      durationNote: item.durationNote,
      otherMeetingDescription:
        item.MentorTimeLogAssociations?.otherMeetingDescription,
      noteForExtendedMeeting:
        item.MentorTimeLogAssociations?.noteForExtendedMeeting,
      noteForNoOrientationCall:
        item.MentorTimeLogAssociations?.noteForNoOrientationCall,
    });

    const allAssociatedAdvisors = item.MentorTimeLogAssociations
      ?.allAssociatedAdvisors
      ? item.MentorTimeLogAssociations?.allAssociatedAdvisors?.map(
          (advisor) => `${advisor?.firstName} ${advisor?.lastName}`
        )
      : [];

    setAdvisorsNames([...allAssociatedAdvisors]);
    setSelectedValue({
      value: `${item.StudentId}`,
      label: `${item.student?.firstName} ${item.student?.lastName} (${item.student?.email})`,
      initialKickOffDate: item?.student?.initialKickOffDate || null,
    });
    setWorkType(item.workType);
    setGroupId(item.GroupId || '');
    setIsSubmittingPendingTimelog(true);
    setScreen1(false);
    setScreen2(true);
    setLoading(false);
  };

  const mountEffect = () => {
    const checkOnBoarded = async () => {
      let query: any = {
        query: CHECK_ONBOARDED,
        variables: { limit: 1, MentorId: user?.id },
        fetchPolicy: 'network-only',
      };
      const { data }: { data: ALL_MENTOR_TIME_LOGS_QUERY } = await client.query(
        query
      );
      if (data.allMentorTimeLogs?.mentorTimeLogs) {
        setLogsOnBoarded(
          data.allMentorTimeLogs?.mentorTimeLogs[0]?.onBoarding?.onBoarded ||
            false
        );
      }
    };
    const fetchMentorPaymentCategories = async () => {
      const { data } = await client.query({
        query: GET_MENTOR_PAYMENT_CATEGORIES,
        fetchPolicy: 'network-only',
      });
      setMentorPaymentCategories(data?.mentorPaymentCategories || []);
    };
    checkOnBoarded();
    fetchMentorPaymentCategories();
  };

  useEffect(mountEffect, []);

  const onWorkTypeClick = (type: string) => {
    if (studentId && studentName) {
      setSelectedValue({
        label: `${studentName} (${studentEmail})`,
        value: studentId,
        initialKickOffDate: initialKickOffDate,
      });
    }

    setWorkType(type);
    setInitialValues({
      ...initialFormState,
      workType: type,
      ...(studentId && { StudentId: parseInt(studentId) }),
      ...(meetingId && { meetingId: parseInt(meetingId) }),
      ...(meetingId &&
        (otherAdv
          ? { workActivity: 'Video Chat Session, 2+ Advisors' }
          : { workActivity: 'Video Chat Session, Solo' })),
      ...(meetingDuration && { activityDuration: meetingDuration }),
      ...(meetingDuration && { actualMeetingTime: parseInt(meetingDuration) }),
      ...(meetingDate && { activityAt: new Date(meetingDate) }),
      ...(canceledMeeting && { missCall: true, activityDuration: 15 }),
      ...(otherAdvisors?.length && { otherAdvisorIds: otherAdvisors }),
      PaymentCategoryId:
        meetingId && mentorPaymentCategories
          ? mentorPaymentCategories.find(
              (item: any) => item && item?.paymentCategory?.name === 'video'
            )?.id
          : null,
    });
    setScreen1(false);
    setScreen2(true);
  };

  const onScreen2BackClick = () => {
    if (window.location.pathname !== '/time-log/add') {
      history.replace('/time-log/add');
    }
    setGroupId('');
    setScreen2(false);
    setScreen1(true);
    setSelectedValue({
      value: '',
      label: '',
      initialKickOffDate: null,
    });
  };
  const onSubmit = () => {
    setScreen2(false);
    setScreen3(true);
  };
  if (id && type) {
    count === 0 && loading === false && setLoading(true);
    count !== 1 && setCount(1);
  }

  useEffect(() => {
    if (studentId && (mentorPaymentCategories?.length || user?.isFullTime)) {
      onWorkTypeClick('Student Advising');
    }
  }, [meetingId, studentId, otherAdv, studentName, mentorPaymentCategories]);

  return (
    <>
      <Header />
      {loading && <Loader />}
      <Container className={classes.containerResponsiveDevice}>
        {screen1 && (
          <Box className={classes.formBoxCard}>
            <Grid className={classes.previousArrowHeading}>
              <Typography variant="h3" className={classes.headingSpacingLogs}>
                Log Hours
              </Typography>
            </Grid>

            <Query<pendingMentorTimeLogQuery>
              query={PENDING_MENTOR_TIMELOG}
              variables={{ AdvisorId: user?.id }}
              fetchPolicy="network-only"
            >
              {({ data, loading }) => {
                if (loading) return <Loader />;
                if (!data?.mentorPendingTimeLogs?.pendingTimeLogs?.length)
                  return <></>;
                setPendingTimelogs(data.mentorPendingTimeLogs.pendingTimeLogs);
                return (
                  <>
                    {data.mentorPendingTimeLogs.pendingTimeLogs.map((item) => {
                      if (!item) return <></>;
                      return (
                        <Card
                          className={clsx(
                            classes.stripeTokenCard,
                            classes.activityPendingCard
                          )}
                        >
                          <Box display="flex">
                            <Grid>
                              <img src={Info_Blue_Img} alt="token" />
                            </Grid>
                            <Grid>
                              <Typography variant="h5">
                                {`${fomateDateUS(
                                  new Date(item?.activityAt)
                                )} - Student Advising Activity Pending`}
                              </Typography>
                              <Typography variant="body1">
                                {`${item?.mentor?.firstName} ${item?.mentor?.lastName} has submitted hours for your meeting
                          together with `}
                                <b>{`${item?.student?.firstName} ${item?.student?.lastName} `}</b>
                                on
                                <b>{` ${fomateDateUS(
                                  new Date(item?.activityAt)
                                )}`}</b>
                                . Please submit your hours as well.
                              </Typography>

                              <Button
                                className={classes.activityPending}
                                variant="contained"
                                onClick={() => handlePendingTimelog(item)}
                                disabled={
                                  (!user?.stripeConnectAccount ||
                                    !mentorPaymentCategories?.length ||
                                    rateIsNotSet) &&
                                  !user?.isFullTime
                                }
                              >
                                Begin
                              </Button>
                            </Grid>
                          </Box>
                        </Card>
                      );
                    })}
                  </>
                );
              }}
            </Query>

            {!user?.stripeConnectAccount && !user?.isFullTime && (
              <Card className={classes.stripeTokenCard}>
                <Box display="flex">
                  <Grid>
                    <img src={Stripe_Token_Img} alt="token" />
                  </Grid>
                  <Grid>
                    <Typography variant="h5">
                      No Stripe Token Associated
                    </Typography>
                    <Typography variant="body1">
                      You may not log and get paid for advising hours
                      withoutfirst setting up your Stripe Payment Token,
                    </Typography>

                    <Link to="/account/billing">
                      <Button variant="contained">Set Up Token</Button>
                    </Link>
                  </Grid>
                </Box>
              </Card>
            )}

            {(!mentorPaymentCategories?.length || rateIsNotSet) &&
              !user?.isFullTime && (
                <Card
                  className={clsx(
                    classes.stripeTokenCard,
                    classes.unavailableAccount
                  )}
                >
                  <Box display="flex">
                    <Grid>
                      <img src={Danger_Img} alt="token" />
                    </Grid>

                    <Grid>
                      <Typography variant="h5">
                        Logging hours is currently unavailable for your account.
                      </Typography>
                      <Typography variant="body1">
                        Please contact your Advisor Manager to ensure you have
                        an advising rate applied to your account.
                      </Typography>
                    </Grid>
                  </Box>
                </Card>
              )}

            <Grid className={classes.logHoursResponsive}>
              <Box
                component="body"
                fontSize={16}
                marginBottom={5}
                className={
                  (!mentorPaymentCategories?.length ||
                    rateIsNotSet ||
                    !user?.stripeConnectAccount) &&
                  !user?.isFullTime
                    ? classes.stripeContentDisable
                    : ''
                }
              >
                What kind of work are you logging?
              </Box>

              <Box className={classes.logHoursButtons}>
                <Box
                  className={
                    (!mentorPaymentCategories?.length ||
                      rateIsNotSet ||
                      !user?.stripeConnectAccount) &&
                    !user?.isFullTime
                      ? classes.stripeContentDisable
                      : ''
                  }
                  onClick={() => {
                    if (user?.isFullTime) onWorkTypeClick('Student Advising');
                    else if (
                      mentorPaymentCategories?.length &&
                      !rateIsNotSet &&
                      user?.stripeConnectAccount
                    )
                      onWorkTypeClick('Student Advising');
                  }}
                >
                  <img src={Advisor_Operations_Img} alt="advising" />
                  <div>
                    <Typography variant="h5">Student Advising</Typography>
                    <Typography variant="subtitle1">
                      1-on-1 advising work involving a single student.
                    </Typography>
                  </div>
                </Box>

                <Box
                  className={
                    (((!mentorPaymentCategories?.length ||
                      rateIsNotSet ||
                      !user?.stripeConnectAccount) &&
                      !user?.isFullTime) ||
                      user?.advisorLevel !== 'Former Admissions Officer') &&
                    !dressRehearsalForNoneAO.includes(user?.email ?? '')
                      ? classes.stripeContentDisable
                      : ''
                  }
                  onClick={() => {
                    if (
                      (user?.isFullTime &&
                        user?.advisorLevel === 'Former Admissions Officer') ||
                      dressRehearsalForNoneAO.includes(user?.email ?? '')
                    )
                      onWorkTypeClick('Dress Rehearsal');
                    else if (
                      (mentorPaymentCategories?.length &&
                        !rateIsNotSet &&
                        user?.stripeConnectAccount &&
                        user?.advisorLevel === 'Former Admissions Officer') ||
                      dressRehearsalForNoneAO.includes(user?.email ?? '')
                    )
                      onWorkTypeClick('Dress Rehearsal');
                  }}
                >
                  <img src={Dress_Rehearsal_Img} alt="advising" />
                  <div>
                    <Typography variant="h5">Dress Rehearsal</Typography>
                    <Typography variant="subtitle1">
                      Full-length review of a student's final application and
                      essays.
                    </Typography>
                  </div>
                </Box>

                <Box
                  className={
                    (!mentorPaymentCategories?.length ||
                      rateIsNotSet ||
                      !user?.stripeConnectAccount) &&
                    !user?.isFullTime
                      ? classes.stripeContentDisable
                      : ''
                  }
                  onClick={() => {
                    if (user?.isFullTime) onWorkTypeClick('Advisor Operations');
                    else if (
                      mentorPaymentCategories?.length &&
                      !rateIsNotSet &&
                      user?.stripeConnectAccount
                    )
                      onWorkTypeClick('Advisor Operations');
                  }}
                >
                  <img src={Student_Advising_Img} alt="advising" />
                  <div>
                    <Typography variant="h5">Advisor Operations</Typography>
                    <Typography variant="subtitle1">
                      Ex: Training, Management, etc.
                    </Typography>
                  </div>
                </Box>

                {(user?.haveMilestoneTeamRates || user?.isFullTime) && (
                  <Box
                    className={
                      (!mentorPaymentCategories?.length ||
                        rateIsNotSet ||
                        !user?.stripeConnectAccount) &&
                      !user?.isFullTime
                        ? classes.stripeContentDisable
                        : ''
                    }
                    onClick={() => {
                      if (user?.isFullTime) onWorkTypeClick('Milestone Teams');
                      else if (
                        mentorPaymentCategories?.length &&
                        !rateIsNotSet &&
                        user?.stripeConnectAccount
                      )
                        onWorkTypeClick('Milestone Teams');
                    }}
                  >
                    <img src={Milestone_Team_Img} alt="milestone-teams" />
                    <div>
                      <Typography variant="h5">Milestone Teams</Typography>
                      <Typography variant="subtitle1">
                        e.g. Financial Aid, College List, Interview Prep, etc.
                      </Typography>
                    </div>
                  </Box>
                )}
                {/* <Box
                  className={
                    (!mentorPaymentCategories?.length ||
                      rateIsNotSet ||
                      !user?.stripeConnectAccount) &&
                    !user?.isFullTime
                      ? classes.stripeContentDisable
                      : ''
                  }
                  onClick={() => {
                    if (user?.isFullTime) onWorkTypeClick('Other');
                    else if (
                      mentorPaymentCategories?.length &&
                      !rateIsNotSet &&
                      user?.stripeConnectAccount
                    )
                      onWorkTypeClick('Other');
                  }}
                >
                  <Typography variant="h5">Other</Typography>
                </Box> */}
              </Box>
            </Grid>
          </Box>
        )}

        {screen2 && (
          <TimeLogForm
            onBoarded={logsOnBoarded}
            editId={id}
            backClick={onScreen2BackClick}
            onSubmit={onSubmit}
            studentValue={selectedValue}
            setStudentValue={setSelectedValue}
            paymentCategories={mentorPaymentCategories || null}
            workType={workType}
            setCollegeName={setCollegeName}
            setAdvisorsNames={setAdvisorsNames}
            advisorsNames={advisorsNames}
            setInitialValues={setInitialValues}
            initialValues={initialValues}
            setTeamName={setTeamName}
            TeamName={TeamName}
            setExpectedPayment={setExpectedPayment}
            GroupId={GroupId}
            setGroupId={setGroupId}
            pendingTimelogs={pendingTimelogs}
            beginPendingTimelog={handlePendingTimelog}
            isSubmittingPendingTimelog={isSubmittingPendingTimelog}
          />
        )}

        {screen3 && (
          <ResultPage
            initialValues={initialValues}
            studentValue={selectedValue}
            workType={workType}
            expectedPayement={expectedPayement}
            teamName={TeamName}
            advisorsNames={advisorsNames}
          />
        )}
      </Container>
    </>
  );
};

export default withRouter(NewTimeLogPage);
