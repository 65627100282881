import gql from 'graphql-tag';

export const MENTOR_TIME_SLOTS = gql`
  query mentorTimeSlotsQuery($startDate: Date, $endDate: Date, $mentorId: Int) {
    mentorTimeSlots(
      input: { startDate: $startDate, endDate: $endDate, mentorId: $mentorId }
    ) {
      timeSlots {
        key
        values {
          key
          values {
            id
            MentorId
            startDate
            endDate
            recurring
            recurringLastDate
            timeSlotBookings {
              id
              AdvisorId
              startTime
              endTime
              StudentId
              event_id
              status
              title
              zoomLink
              createdAt
              student {
                id
                firstName
                lastName
                pictureUrl
              }
              allParticipants {
                id
                firstName
                lastName
                pictureUrl
                roles
                zoomLink
              }
            }
          }
          bookingsCount
        }
      }
    }
  }
`;

export const CREATE_TIME_SLOT = gql`
  mutation CreateTimeSlotMutation(
    $timeSlots: [TimeSlotInput]!
    $meetingsToBeDelete: [Int]
  ) {
    createOrUpdateTimeSlots(
      input: { timeSlots: $timeSlots, meetingsToBeDelete: $meetingsToBeDelete }
    ) {
      message
      errors
      status
    }
  }
`;

export const DELETE_TIME_SLOT_SERIES = gql`
  mutation deleteTimeslotSeriesMutation(
    $startDate: Date!
    $recurringLastDate: Date!
    $deleteBookings: Boolean!
  ) {
    deleteTimeslotSeries(
      input: {
        startDate: $startDate
        recurringLastDate: $recurringLastDate
        deleteBookings: $deleteBookings
      }
    ) {
      success
      message
    }
  }
`;

export const TIME_SLOT_MEETINGS = gql`
  query getBookingsForTimeSlotQuery($timeSlotId: Int!, $series: Boolean!) {
    getBookingsForTimeSlot(
      input: { timeSlotId: $timeSlotId, series: $series }
    ) {
      bookings {
        id
        AdvisorId
        startTime
        endTime
        StudentId
        event_id
        status
        title
        zoomLink
        student {
          id
          firstName
          lastName
          pictureUrl
        }
        allParticipants {
          id
          firstName
          lastName
          pictureUrl
          roles
          zoomLink
        }
      }
      total
    }
  }
`;

export const GET_CONFLICTED_BOOKINGS_FOR_EDIT = gql`
  query getBookingsForEditTimeSlotMutation(
    $id: Int
    $startDate: Date
    $endDate: Date
    $series: Boolean
    $seriesEndDate: Date
  ) {
    getBookingsForEditTimeSlot(
      input: {
        id: $id
        startDate: $startDate
        endDate: $endDate
        series: $series
        seriesEndDate: $seriesEndDate
      }
    ) {
      bookings {
        id
        AdvisorId
        startTime
        endTime
        StudentId
        event_id
        status
        title
        zoomLink
        student {
          id
          firstName
          lastName
          pictureUrl
        }
        allParticipants {
          id
          firstName
          lastName
          pictureUrl
          roles
          zoomLink
        }
      }
    }
  }
`;

export const NEW_TIMESLOT_QUERY = gql`
  query getAdvisorMonthlyTImeSlotsQuery(
    $startDate: Date
    $endDate: Date
    $advisorId: Int!
    $offset: Float!
  ) {
    getAdvisorMonthlyTImeSlots(
      input: {
        startDate: $startDate
        endDate: $endDate
        advisorId: $advisorId
        offset: $offset
      }
    ) {
      timeslots {
        id
        MentorId
        startDate
        endDate
        recurring
        recurringLastDate
        timeSlotBookings {
          id
          AdvisorId
          startTime
          endTime
          StudentId
          event_id
          status
          title
          zoomLink
          createdAt
          student {
            id
            firstName
            lastName
            pictureUrl
          }
          allParticipants {
            id
            firstName
            lastName
            pictureUrl
            roles
            zoomLink
          }
        }
      }
      #   recurringDates {
      #     recurringStartDate
      #     recurringLastDate
      #   }
    }
  }
`;
