import { useState, useContext, useEffect, ChangeEvent } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Typography,
  FormHelperText,
  Grid,
  Card,
  Select as MaterialUISelect,
  MenuItem,
  Checkbox,
  FormControlLabel,
  makeStyles,
  OutlinedInput,
} from '@material-ui/core';
import Select, { OptionTypeBase, ValueType } from 'react-select';
import Alert from '@material-ui/lab/Alert';
import { Formik, Form, Field, ErrorMessage, FormikValues, FormikProps } from 'formik';
import * as Yup from 'yup';
import momentTz from 'moment-timezone';
import moment from 'moment';

import { useStyles } from './style/editDialogStyle';
import { TextField } from '../../../../../src/components/fields';
import {
  MilestoneWorkTypeOption,
  mileStoneOptions as initialMileStonOptions,
  mileStoneOptionsForProjectWork,
  timeLogAllWorkActivityOptions,
  workActivityForAdvisorOperations,
  workActivityForStudentAdvising,
  workTypeOptions,
} from './../static/index';
import AddIcon from '../../../../img/plus-circle.svg';
import {
  setWorkTypeForMilestoneTeamValues,
  setWorkTypeValue,
} from './../RadioField';
import {
  ALL_COLLEGE_APPLICATIONS,
  CREATE_STUDENT_APPLICATION_MUTATION,
  GET_MENTOR_PAYMENT_CATEGORIES,
  GET_STUDENT_ENTITLEMENTS_STAT,
  UPDATE_MENTOR_TIMELOG,
} from './../gql';
import client from '../../../../apollo';
import { ApolloConsumer } from 'react-apollo';
import Stripe_Token_Img from '../../../../img/unverified-token.svg';
import CHECK_CIRCLE from '../../../../img/check-circle.svg';

import Warning_Img from '../../../../img/warning-error.svg';
import formatDate from '../../../../common/utility';
import NativeTimeTextField from './../NativeTimeTextField';
import { AppContext } from '../../../../contexts';
import AdvisorSelect from '../../../../components/AdvisorSelect';
import CustomSearchSelect from '../CustomSearchSelect';
import { LinkOnlineMeetingDialog } from '../../../../components/time-logs/LinkOnlineMeetingDialog';
import CautionIcon from '../../../../img/caution-icon.svg';
import CheckedLightIcon from '../../../../img/checked-light-icon.svg';
import { OptionStudentApplication } from '../../../../common/interfaces';
import { MENTOR_TIMELOG_ASSOCIATION_TYPES } from '../../../../constants';
import fonts from '../../../../common/fonts';
import OtherApplications from '../NewTimeLogDesign/OtherApplications';
import { NumberInput } from '../../../../components/fields';
import colors from '../../../../common/colors';
import { GET_ALL_MILESTONE_TEAMS } from '../../../../graphql/queries/milestoneTeams';
import { getMentorPaymentCategories_mentorPaymentCategories } from '../gql/__generated__/getMentorPaymentCategories';
import Loader from '../../../../components/Loader';
import { PENCIL_IC } from '../../../../common/CommonComponents';

const useStylesDialog = makeStyles(() => ({
  formLeftSmallWidth: ({ isDisabledState }: { isDisabledState?: boolean }) => ({
    marginRight: isDisabledState ? 0 : 45,
    maxWidth: isDisabledState ? '100%' : 354,

    '& .submittedApprovedText': {
      '& .dateText': {
        color: '#000',
        fontSize: 14,
        fontWeight: 400,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.084px',
      },

      '& img': {
        marginLeft: 5,
      },
    },
  }),
}));

interface OptionType {
  label: string;
  value: string;
  initialKickOffDate?: string | null;
}

const TimeLogForm = ({
  onBoarded,
  changeWorkType,
  editId,
  workType,
  onSubmit,
  studentValue,
  setStudentValue,
  setInitialValues,
  setExpectedPayment,
  setWorkType,
  initialValues,
  item,
  isDisabledState,
  setAdvisorsNames,
  advisorsNames,
}: {
  onBoarded: boolean;
  advisorsNames: string[];
  changeWorkType: boolean;
  editId: string | undefined;
  workType: string;
  onSubmit: Function;
  setStudentValue: Function;
  setInitialValues: Function;
  setExpectedPayment: Function;
  setWorkType: Function;
  initialValues: any;
  studentValue: any;
  item: any;
  isDisabledState: boolean;
  setAdvisorsNames: Function;
}) => {
  const classes = useStyles();
  const dialogClasses = useStylesDialog({ isDisabledState });
  const { user } = useContext(AppContext);
  const [milestoneTeamsOptions, setMilestoneTeamsOptions] = useState<
    { value: number; label: string }[]
  >([]);
  const [addNote, setAddNote] = useState(initialValues.note ? true : false);
  const [mentorStudentIds, setMentorStudentIds] = useState<number[]>();
  const [amount, setAmount] = useState<number>();
  const [openDialogue, setOpenDialogue] = useState<boolean>(false);
  const [prevoiusActivityPerformed, setPrevoiusActivityPerformed] =
    useState('');
  const [noOfficialMeet, setNoOfficialMeet] = useState<boolean>(
    !initialValues.advisorBookingId &&
      !!initialValues.otherMeetingDescription &&
      workType !== 'Milestone Teams'
      ? true
      : false
  );
  const [selectedAdvisorBookingId, setSelectedAdvisorBookingId] =
    useState<number>(initialValues.advisorBookingId);
  const [UCSubCampusApplications, setUCSubCampusApplications] = useState<any>(
    []
  );
  const [isAddNewApplication, setIsAddNewApplication] = useState(false);
  const [showStudentApplications, setShowStudentApplications] = useState(false);
  const [allStudentApplications, setAllStudentApplications] = useState<any>([]);
  const [selectedStudentApplications, setSelectedStudentApplications] =
    useState(initialValues.selectedStudentApplications);

  const [studentApplicationIds, setStudentApplicationIds] = useState<string[]>(
    initialValues.studentApplications?.length
      ? initialValues.studentApplications?.map(
          (app: { value: number }) => app.value
        )
      : []
  );
  const [checked, setChecked] = useState<boolean>(false);
  const [collegesToExcludeIds, setcollegesToExcludeIds] = useState<
    (number | undefined)[] | undefined
  >([]);
  const [entitlementTypes, setEntitlementTypes] = useState<OptionType[]>([]);
  const [mileStoneOptions, setMileStoneOptions] = useState(
    initialValues?.isProjectWork ? mileStoneOptionsForProjectWork : initialMileStonOptions
  );


  const dressRehearsalForNoneAO =
    process.env.REACT_APP_DRESS_REHEARSAL_FOR_NONE_AO?.split(',') || [];

  // For user
  const [mentorPaymentCategories, setMentorPaymentCategories] = useState<
    (getMentorPaymentCategories_mentorPaymentCategories | null)[]
  >([]);

  // For specific mentor
  const [itemMentorPaymentCategories, setItemMentorPaymentCategories] =
    useState<(getMentorPaymentCategories_mentorPaymentCategories | null)[]>([]);
  const NewFormSchema = Yup.object().shape({
    workType: Yup.string().required('Work type must be selected.').nullable(),
    StudentId: Yup.number()
      .nullable()
      .when('workType', {
        is: (type: string) =>
          ['Student Advising', 'Speciality Team', 'Dress Rehearsal'].includes(
            type
          ),
        then: Yup.number().required('Student is required.').nullable(),
      }),
    activityDuration: Yup.number()
      .nullable()
      .when('workActivity', {
        is: (activity: string) =>
          activity !== 'Onboarding' && activity !== 'Dress Rehearsal',
        then: Yup.number()
          .moreThan(0)
          .lessThan(201, 'Duration must not exceed 200 min')
          .required('Time is required.'),
      }),
    workActivity: Yup.string()
      .nullable()
      .when('workType', {
        is: (type: string) =>
          ['Student Advising', 'Advisor Operations'].includes(type),
        then: Yup.string().required('Work activity must be selected.'),
      }),
    milestoneTeam: Yup.string()
      .nullable()
      .when('workType', {
        is: (type: string) => ['Milestone Teams'].includes(type),
        then: Yup.string().required('Milestone Team must be selected.'),
      }),
    entitlementType: Yup.string()
      .nullable()
      .when('workType', {
        is: (type: string) => ['Milestone Teams'].includes(type),
        then: Yup.string().required('Entitlement Types must be selected.'),
      }),
    ...(item?.milestone &&
      !noOfficialMeet && {
        milestone: Yup.string()
          .nullable()
          .when(['workType', 'workActivity', 'missCall'], {
            is: (type: string, activity: string, missCall: boolean) =>
              ['Student Advising'].includes(type) &&
              activity !== 'Meeting with other Advisor/AO' &&
              activity !== 'Partner - Leadership Initiatives' &&
              !missCall,
            then: Yup.string().required('Milestone must be selected.'),
          }),
      }),

    activityDescription: Yup.string(),
    activityAt: Yup.date().required('Date is required.').nullable(),
    PaymentCategoryId: Yup.number()
      .nullable()
      .when(['workType', 'workActivity'], {
        is: (type: string, activity: string) =>
          !user?.isFullTime &&
          ['Student Advising', 'Advisor Operations'].includes(type) &&
          activity !== 'Partner - Leadership Initiatives',
        then: Yup.number()
          .required('Payment Category must be selected, Please contact admin.')
          .typeError(
            'Payment Category must be selected, Please contact admin.'
          ),
      }),

    note: Yup.string()
      .nullable()
      .when(['workType'], {
        is: (workType: string) =>
          workType === 'Other' || workType === 'Advisor Operations',
        then: Yup.string().required('Note must be added.'),
      }),

    durationNote: Yup.string()
      .nullable()
      .when('activityDuration', {
        is: (duration: number) => duration > 90 && duration <= 200,
        then: Yup.string().required('Required'),
      }),
    missCall: Yup.boolean().nullable(),
    airtableSubmitted: Yup.boolean().when('workType', {
      is: (type: string) => type === 'Dress Rehearsal',
      then: Yup.boolean().required('Required').oneOf([true], 'Required'),
    }),

    otherAdvisorIds: Yup.array()
      .nullable()
      .when('workActivity', {
        is: (activity: string) =>
          (activity === 'Video Chat Session, 2+ Advisors' ||
            activity === 'Orientation/Kick-Off Call, 2+ Advisors') &&
          initialValues.workActivity !== 'Video Chat Session, 2+ Advisors' &&
          initialValues.workActivity !==
            'Orientation/Kick-Off Call, 2+ Advisors',
        then: Yup.array()
          .of(
            Yup.object().shape({
              label: Yup.string(),
              value: Yup.string(),
            })
          )
          .min(1),
      }),
    noteForExtendedMeeting: Yup.string()
      .nullable()
      .when(['actualMeetingTime', 'activityDuration', 'missCall'], {
        is: (meetingDuration: number, duration: number, missCall: boolean) =>
          ((duration >= meetingDuration + 5 && duration <= 90) ||
            duration <= meetingDuration - 15) &&
          selectedAdvisorBookingId &&
          !missCall,
        then: Yup.string().required('Required'),
      }),
    otherMeetingDescription: Yup.string()
      .nullable()
      .when('activityDuration', {
        is: (duration: number) => noOfficialMeet === true,
        then: Yup.string().required('Required'),
      }),
    noteForNoOrientationCall: Yup.string()
      .nullable()
      .when('workActivity', {
        is: (workActivity: string) =>
          !studentValue?.initialKickOffDate &&
          (workActivity === 'Video Chat Session, 2+ Advisors' ||
            workActivity === 'Video Chat Session, Solo'),
        then: Yup.string().required('Required'),
      }),
  });

  const handleSelectChange = (
    selectedOptions: ValueType<OptionTypeBase, true>,
    setFieldValue: Function,
    values: any
  ) => {
    const options = selectedOptions as OptionStudentApplication[];
    const ids = options.map((option) => option.value);
    setStudentApplicationIds(ids);
    if (!values.OtherApplicationsIds?.length) {
      setFieldValue('studentApplication', ids[0]);
    }
  };

  const expectedPayment = (paymentCategories: any, values: any) => {
    if (
      paymentCategories &&
      paymentCategories.length &&
      values.PaymentCategoryId
    ) {
      const res =
        paymentCategories.find(
          (item: any) => item.id === values.PaymentCategoryId
        ) || {};
      const { rate } = res;
      const exp_pay = ((values.activityDuration * rate) / 60).toFixed(2);
      setExpectedPayment(exp_pay);
      setAmount(parseInt(`${+exp_pay * 100}`));
      return exp_pay;
    } else if (workType !== 'Milestone Teams') {
      const { rate } = paymentCategories.find(
        (item: any) => item?.paymentCategory?.name === 'offline'
      );

      const exp_pay = ((values.activityDuration * rate) / 60).toFixed(2);
      setExpectedPayment(exp_pay);
      setAmount(parseInt(`${+exp_pay * 100}`));
      return exp_pay;
    } else {
      return `0`;
    }
  };
  const exp_payment_Onboarding = () => {
    setExpectedPayment(50);
    setAmount(50 * 100);
    return 50;
  };

  const loadCollegeApplicationsOptions = (inputValue: string) => {
    return new Promise<OptionType[]>((resolve) => {
      client
        .query({
          query: ALL_COLLEGE_APPLICATIONS,
          fetchPolicy: 'network-only',
          variables: {
            limit: 10,
            searchQuery: inputValue,
            collegesToExcludeIds: collegesToExcludeIds,
          },
        })
        .then((res: any) => {
          if (
            res.data &&
            res.data.allColleges &&
            res.data.allColleges.colleges.length
          ) {
            const options: OptionType[] = res.data.allColleges.colleges.map(
              (item: any) => ({
                value: item.id,
                label: `${item.name}`,
              })
            );
            resolve(options);
          } else {
            resolve([]);
          }
        });
    });
  };

  const mountEffect = async (studentId?: number) => {
    try {
      const { data } = await client.query({
        query: GET_STUDENT_ENTITLEMENTS_STAT,
        variables: {
          studentId: studentId || initialValues.StudentId,
          advisorId: user?.id,
        },
        fetchPolicy: 'network-only',
      });

      const { studentApplications } = data?.Student;
      if (studentApplications?.length) {
        const options = studentApplications
          ?.filter(
            (item: {
              applicationRequirement: { college: { ParentCollegeId: number } };
            }) => !item?.applicationRequirement?.college?.ParentCollegeId
          )
          ?.map(
            (item: {
              id: number;
              applicationRequirement: { college: { name: string } };
            }) => ({
              value: item?.id,
              label: item?.applicationRequirement?.college?.name,
            })
          );

        const _UCSubCampusApplications = studentApplications
          ?.filter(
            (item: {
              applicationRequirement: { college: { ParentCollegeId: number } };
            }) => item?.applicationRequirement?.college?.ParentCollegeId
          )
          ?.map(
            (application: {
              id: number;
              applicationRequirement: { college: { name: string } };
            }) => ({
              value: application?.id,
              label: application?.applicationRequirement?.college?.name,
            })
          );
        if (
          _UCSubCampusApplications?.length &&
          _UCSubCampusApplications?.length !== UCSubCampusApplications.length
        ) {
          setUCSubCampusApplications(_UCSubCampusApplications);
        }

        if (_UCSubCampusApplications?.length) {
          options?.push({
            value: 0,
            label: 'University of California (any)',
          });
        }

        const collegesToEclude = studentApplications?.map(
          (item: { applicationRequirement: { college: { id: number } } }) =>
            item?.applicationRequirement?.college?.id
        );

        if (collegesToEclude?.length) {
          setcollegesToExcludeIds(collegesToEclude);
        } else {
          setcollegesToExcludeIds([]);
        }

        if (allStudentApplications.length !== options?.length) {
          setAllStudentApplications(options);
        }
      }
    } catch (error) {
      console.log('Error:::', error);
    }
  };

  const handleProjectWorkChange = (
    event: ChangeEvent<HTMLInputElement>,
    setFieldValue: FormikProps<FormikValues>['setFieldValue'],
  ) => {
    const { target: { checked } }= event
    
    setFieldValue('isProjectWork', checked);
    setFieldValue('StudentId', null);
    setStudentValue(null);
    setFieldValue('entitlementType', checked ? 'Offline' : '');
    setFieldValue('milestone', '');
    setShowStudentApplications(false);

    if (checked) {
      setMileStoneOptions(mileStoneOptionsForProjectWork);
    } else {
      setMileStoneOptions(initialMileStonOptions);
    }
  };

  const getEntitlementTypes = (teamName: string) => {
    const mileStoneTeamsPaymentCategories = mentorPaymentCategories?.filter(
      (category) =>
        category?.paymentCategory?.name?.includes(teamName) && !!category?.rate
    );

    const entitlementTypesArray: OptionType[] = [];
    mileStoneTeamsPaymentCategories?.forEach((item) => {
      if (item?.paymentCategory?.name?.includes('Video')) {
        entitlementTypesArray.push({
          value: 'Video Chat',
          label: 'Video Chat',
        });
      } else if (item?.paymentCategory?.name?.includes('Offline Editing')) {
        entitlementTypesArray.push({
          value: 'Offline Editing',
          label: 'Offline Editing',
        });
      } else if (item?.paymentCategory?.name?.includes('Essay')) {
        entitlementTypesArray.push({
          value: 'Essay Editing',
          label: 'Essay Editing',
        });
      } else if (item?.paymentCategory?.name?.includes('Offline')) {
        entitlementTypesArray.push({
          value: 'Offline',
          label: 'Offline',
        });
      }
    });
    if (user?.isFullTime) {
      const entitlementTypeForFullTime = [];
      if (teamName?.includes('Essay Review Team')) {
        entitlementTypeForFullTime.push({
          value: 'Offline Editing',
          label: 'Offline Editing',
        });
      } else {
        entitlementTypeForFullTime.push(
          {
            value: 'Video Chat',
            label: 'Video Chat',
          },
          {
            value: 'Offline',
            label: 'Offline',
          }
        );
        if (teamName === 'Graduate Specialty Advisor') {
          entitlementTypeForFullTime.push({
            value: 'Essay Editing',
            label: 'Essay Editing',
          });
        }
      }
      setEntitlementTypes(entitlementTypeForFullTime);
    } else {
      setEntitlementTypes(entitlementTypesArray);
    }
  };

  const getMilestoneTeamsOption = async () => {
    try {
      const { data }: any = await client.query({
        query: GET_ALL_MILESTONE_TEAMS,
        fetchPolicy: 'network-only',
        variables: {
          mentorId: user?.id,
          isFullTime: user?.isFullTime,
        },
      });
      const allMilestoneTeams = data?.getMilestoneTeamOptions.map(
        (milestoneTeam: any) => {
          return {
            value: milestoneTeam?.value,
            label: milestoneTeam?.label,
          };
        }
      );
      setMilestoneTeamsOptions(allMilestoneTeams);
    } catch (error) {
      console.log('milestoneTeamsOption ->', error);
    }
  };

  const fetchMentorPaymentCategories = async () => {
    const { data } = await client.query({
      query: GET_MENTOR_PAYMENT_CATEGORIES,
      fetchPolicy: 'network-only',
    });
    const { data: itemMentorData } = await client.query({
      query: GET_MENTOR_PAYMENT_CATEGORIES,
      fetchPolicy: 'network-only',
      variables: { id: item?.mentor?.id },
    });
    if (data && data?.mentorPaymentCategories) {
      setMentorPaymentCategories(data.mentorPaymentCategories);
    }
    if (itemMentorData && itemMentorData?.mentorPaymentCategories) {
      setItemMentorPaymentCategories(
        itemMentorData?.mentorPaymentCategories || []
      );
    }
  };
  useEffect(() => {
    getMilestoneTeamsOption();
    fetchMentorPaymentCategories();
  }, []);
  useEffect(() => {
    if (mentorPaymentCategories)
      getEntitlementTypes(initialValues.milestoneTeam);
  }, [mentorPaymentCategories]);

  useEffect(() => {
    if (
      initialValues.StudentId &&
      (initialValues.milestone === 'Application Strategy' ||
        initialValues.milestone === 'Essay Writing & Editing' ||
        initialValues.milestone === 'Interview Prep')
    ) {
      mountEffect();
      setShowStudentApplications(true);
    }
  }, [initialValues.milestone]);

  return (
    <Box position="relative">
      <Box className={classes.formBoxCard}>
        <Formik
          enableReinitialize
          validationSchema={NewFormSchema}
          initialValues={initialValues}
          onSubmit={async (values) => {
            try {
              const MUTATION = UPDATE_MENTOR_TIMELOG;

              const milestoneTeamId =
                milestoneTeamsOptions.find(
                  (milestoneTeam) =>
                    milestoneTeam.label === values.milestoneTeam
                )?.value || 0;

              let variables: any = {
                ...values,
                MentorId: item.mentor.id,
                PaymentCategoryId: values.PaymentCategoryId || null,
                activityDuration: parseInt(values.activityDuration),
                isFullTime: user?.isFullTime,
                workActivity: !!values.entitlementType
                  ? values.entitlementType
                  : values.workActivity,
                amount: values.PaymentCategoryId ? amount : 0,
                ...(milestoneTeamId && { milestoneTeamId }),
                ...(selectedAdvisorBookingId && {
                  AdvisorsBookingId: selectedAdvisorBookingId,
                }),
                entityType:
                  MENTOR_TIMELOG_ASSOCIATION_TYPES.studentApplications,
                entityIds: studentApplicationIds,
              };
              setInitialValues(variables);
              if (parseInt(values.activityDuration) <= 90)
                variables['durationNote'] = '';
              if (editId) variables['id'] = +editId;
              if (workType === 'Other' || workType === 'Speciality Team')
                variables['workActivity'] = 'Other';
              if (values.otherAdvisorIds.length) {
                variables['otherAdvisorIds'] = values.otherAdvisorIds.map(
                  (item: OptionType) => +item.value
                );
                const otherAdvisorNames = values.otherAdvisorIds.map(
                  (item: OptionType) => item.label.split('(')[0].trim()
                );
                setAdvisorsNames([...advisorsNames, ...otherAdvisorNames]);
              }
              if (workType === 'Dress Rehearsal') {
                variables['note'] = '';
                variables['durationNote'] = '';
              }

              const mutationPromises = [
                client.mutate({
                  mutation: MUTATION,
                  variables,
                  fetchPolicy: 'no-cache',
                }),
                client.mutate({
                  mutation: CREATE_STUDENT_APPLICATION_MUTATION,
                  variables: {
                    collegeIds: values.OtherApplicationsIds
                      ? values.OtherApplicationsIds.map(
                          (application: OptionType) => application.value
                        )
                      : [],
                    StudentId: values.StudentId,
                    studentOtherApplication: true,
                    timeLogAssociationId: values.timeLogAssociationId,
                  },
                  fetchPolicy: 'no-cache',
                }),
              ];

              await Promise.all(mutationPromises);

              onSubmit();
            } catch (error) {
              console.log(error);
            }
          }}
        >
          {({
            isSubmitting,
            setFieldValue,
            values,
            errors,
            touched,
            setTouched,
            dirty,
            initialValues,
          }) => {
            const isApplicationNotAdded =
              showStudentApplications &&
              !studentApplicationIds.length &&
              values.milestone !== 'Application Strategy' &&
              !values.OtherApplicationsIds?.length &&
              !values.isCommonApp &&
              !values.isCoalitionApp &&
              !values.isOtherStrategy;
              const showProjectWorkCheckbox =
                workType === 'Milestone Teams' &&
                !values.milestoneTeam
                  ?.toLowerCase()
                  ?.includes('interview coaching team') &&
                entitlementTypes.some(
                  (type) => type.value.toLowerCase() === 'offline'
                );


            const disableEditing =
              initialValues.workActivity ===
                'Video Chat Session, 2+ Advisors' ||
              initialValues.workActivity ===
                'Orientation/Kick-Off Call, 2+ Advisors';
            const isError = Object.entries(errors).length > 0;

            const paymentCategories = itemMentorPaymentCategories;
            if (workType === 'Dress Rehearsal') {
              values.workActivity = 'Dress Rehearsal';
              values.activityDuration = 60;
            }
            if (values.workActivity === 'Onboarding') {
              values.activityAt = new Date();
              values.activityDuration = null;
            }
            if (!paymentCategories?.length) return <Loader />;
            return (
              <>
                {workType !== 'Dress Rehearsal' &&
                  values.StudentId &&
                  ((mentorStudentIds &&
                    !mentorStudentIds.includes(values.StudentId)) ||
                    (values.milestone === 'Other' &&
                      values.workType !== 'Milestone Teams')) && (
                    <Card className={classes.stripeTokenCard}>
                      <Box display="flex">
                        <Grid>
                          <img src={Stripe_Token_Img} alt="token" />
                        </Grid>
                        <Grid>
                          <Typography variant="body1" style={{ margin: 0 }}>
                            {values.milestone === 'Other' &&
                            values.workType !== 'Milestone Teams'
                              ? 'Note is Required.'
                              : 'This student is not officially matched with you. Please clarify the circumstances for working with them'}
                          </Typography>
                        </Grid>
                      </Box>
                    </Card>
                  )}
                {values.workActivity === 'Onboarding' && onBoarded && (
                  <Card className={classes.stripeTokenCard}>
                    <Box display="flex">
                      <Grid>
                        <img src={Stripe_Token_Img} alt="token" />
                      </Grid>
                      <Grid>
                        <Typography variant="body1" style={{ margin: 0 }}>
                          You have already submitted your time for Onboarding.
                        </Typography>
                      </Grid>
                    </Box>
                  </Card>
                )}
                <Form className={classes.formContainer}>
                  <Box className={classes.formInner}>
                    <Box
                      className={clsx(
                        classes.formLeft,
                        dialogClasses.formLeftSmallWidth
                      )}
                    >
                      {changeWorkType && (
                        <Box
                          className={clsx(
                            classes.textfield,
                            classes.textFieldMaterial
                          )}
                        >
                          <label>Type of Work</label>
                          <Field
                            name="workType"
                            label="WorkType"
                            fullWidth
                            component={({ form: { setFieldValue } }: any) => (
                              <>
                                <MaterialUISelect
                                  id="demo-simple-select-outlined"
                                  value={workType}
                                  placeholder="- Select Type Of Work -"
                                  fullWidth
                                  onChange={(item: any) => {
                                    setFieldValue('activityAt', null);
                                    setFieldValue('activityDuration', '');
                                    setFieldValue('milestone', '');
                                    setFieldValue('milestoneTeam', '');
                                    setFieldValue('entitlementType', '');
                                    setFieldValue('StudentId', null);
                                    setStudentValue(null);
                                    setFieldValue(
                                      'otherMeetingDescription',
                                      ''
                                    );
                                    setFieldValue('note', '');
                                    setChecked(false);
                                    setSelectedAdvisorBookingId(0);
                                    setNoOfficialMeet(false);
                                    setAllStudentApplications([]);
                                    setWorkType(item.target.value);
                                    setFieldValue(
                                      'workType',
                                      item.target.value
                                    );
                                    setFieldValue('missCall', null);
                                    setFieldValue('workActivity', '');
                                    if (
                                      item.target.value === 'Dress Rehearsal'
                                    ) {
                                      setWorkTypeValue(
                                        'Dress Rehearsal',
                                        setFieldValue,
                                        paymentCategories
                                      );
                                    }
                                    if (
                                      item.target.value === 'Milestone Teams'
                                    ) {
                                      setFieldValue('entitlementType', '');
                                      getMilestoneTeamsOption();
                                    }
                                  }}
                                >
                                  {(user?.haveMilestoneTeamRates ||
                                    user?.isFullTime) && (
                                    <MenuItem
                                      value={MilestoneWorkTypeOption.value}
                                    >
                                      {MilestoneWorkTypeOption.label}
                                    </MenuItem>
                                  )}
                                  {workTypeOptions
                                    .filter(
                                      (option) =>
                                        (user?.advisorLevel !==
                                          'Former Admissions Officer' &&
                                          option.value !== 'Dress Rehearsal') ||
                                        (user?.advisorLevel !==
                                          'Former Admissions Officer' &&
                                          dressRehearsalForNoneAO.includes(
                                            user?.email ?? ''
                                          ) &&
                                          option.value === 'Dress Rehearsal') ||
                                        user?.advisorLevel ===
                                          'Former Admissions Officer'
                                    )
                                    .map((item) => (
                                      <MenuItem value={item.value}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                </MaterialUISelect>
                              </>
                            )}
                          />
                        </Box>
                      )}
                      {isDisabledState && (
                        <Box className="submittedApprovedText">
                          <Box mb={1} className="dateText">
                            {`Submitted: ${moment(
                              new Date(item?.createdAt)
                            ).format('MMMM DD, YYYY, h:mma')} ${moment(
                              item?.createdAt
                            )
                              .tz(momentTz.tz.guess())
                              .format('z')}`}
                          </Box>

                          {(item?.isFullTime || item?.approvedDate) && (
                            <Box
                              display="flex"
                              alignItems="center"
                              mb={4}
                              className="dateText"
                            >
                              {`Approved: ${
                                item?.isFullTime
                                  ? `${moment(new Date(item?.createdAt)).format(
                                      'MMMM DD, YYYY, h:mma'
                                    )} ${moment(item?.createdAt)
                                      .tz(momentTz.tz.guess())
                                      .format('z')}`
                                  : item?.approvedDate
                                  ? `${moment(
                                      new Date(item?.approvedDate)
                                    ).format('MMMM DD, YYYY, h:mma')} ${moment(
                                      item?.approvedDate
                                    )
                                      .tz(momentTz.tz.guess())
                                      .format('z')}`
                                  : ''
                              }`}
                              <img src={CHECK_CIRCLE} alt="token" />
                            </Box>
                          )}
                        </Box>
                      )}
                      <div
                        style={
                          isDisabledState
                            ? { opacity: 0.5, pointerEvents: 'none' }
                            : {}
                        }
                      >
                        <Box>
                          {workType === 'Milestone Teams' && (
                            <>
                              <Box
                                className={clsx(
                                  classes.textfield,
                                  classes.textFieldMaterial
                                )}
                              >
                                <label>Milestone Team</label>

                                <Field
                                  name="milestoneTeam"
                                  label="Milestone Team"
                                  fullWidth
                                  component={({
                                    field,
                                    form: { touched, errors, setFieldValue },
                                  }: any) => (
                                    <>
                                      <MaterialUISelect
                                        id="demo-simple-select-outlined"
                                        value={values.milestoneTeam}
                                        placeholder="Select Milestone Team"
                                        fullWidth
                                        onChange={(item: any) => {
                                          getEntitlementTypes(
                                            item.target.value
                                          );
                                          setFieldValue(
                                            'milestoneTeam',
                                            item.target.value
                                          );
                                          setFieldValue('activityDuration', '');
                                          setFieldValue('StudentId', null);
                                          setStudentValue(null);
                                          setFieldValue('entitlementType', '');
                                          setFieldValue('isProjectWork', false);
                                          setTouched({
                                            ...touched,
                                            activityDuration: false,
                                          });
                                        }}
                                      >
                                        {milestoneTeamsOptions.map(
                                          (item: any) => (
                                            <MenuItem value={item.label}>
                                              {item.label}
                                            </MenuItem>
                                          )
                                        )}
                                      </MaterialUISelect>
                                      {touched[`${field.name}`] &&
                                        errors[`${field.name}`] && (
                                          <FormHelperText error>
                                            {errors[`${field.name}`]}
                                          </FormHelperText>
                                        )}
                                    </>
                                  )}
                                />
                              </Box>
                            </>
                          )}

                          {showProjectWorkCheckbox && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values.isProjectWork}
                                  disabled={!values.milestoneTeam}
                                  onChange={(e)=> handleProjectWorkChange(e, setFieldValue)}
                                  color="primary"
                                />
                              }
                              label="Project Work"
                            />
                          )}

                          {(workType === 'Student Advising' ||
                            workType === 'Speciality Team' ||
                            workType === 'Milestone Teams' ||
                            workType === 'Dress Rehearsal') &&
                            !values.isProjectWork && (
                            <Box className={classes.textfield}>
                              <label>Student’s Name</label>

                              <Box className="select-field">
                                <Grid className={classes.customDropdownField}>
                                  <Field
                                    name="StudentId"
                                    label="Add Student"
                                    fullWidth
                                    component={({
                                      field,
                                      form: { touched, errors, setFieldValue },
                                    }: any) => (
                                      <ApolloConsumer>
                                        {(client) => (
                                          <>
                                            <CustomSearchSelect
                                              milestoneTeam={
                                                values.milestoneTeam
                                              }
                                              disable={disableEditing}
                                              setMentorStudentIds={
                                                setMentorStudentIds
                                              }
                                              value={studentValue}
                                              setSelectedValue={(
                                                option: OptionType
                                              ) => {
                                                setFieldValue(
                                                  'isCommonApp',
                                                  false
                                                );
                                                setFieldValue(
                                                  'isCoalitionApp',
                                                  false
                                                );
                                                setFieldValue(
                                                  'isOtherStrategy',
                                                  false
                                                );
                                                setFieldValue(
                                                  'missCall',
                                                  false
                                                );
                                                setIsAddNewApplication(false);
                                                mountEffect(
                                                  parseInt(option.value)
                                                );
                                                setFieldValue(
                                                  'activityDuration',
                                                  ''
                                                );
                                                setFieldValue(
                                                  'activityAt',
                                                  null
                                                );
                                                setTouched({
                                                  ...touched,
                                                  activityDuration: false,
                                                });
                                                setShowStudentApplications(
                                                  false
                                                );
                                                setStudentApplicationIds([]);
                                                setFieldValue(
                                                  'UCSubCampusApplications',
                                                  []
                                                );
                                                setFieldValue('milestone', '');
                                                setChecked(false);
                                                setSelectedAdvisorBookingId(0);
                                                setNoOfficialMeet(false);
                                                setFieldValue(
                                                  'otherMeetingDescription',
                                                  ''
                                                );
                                                setAllStudentApplications([]);
                                                setStudentValue(option);
                                                setFieldValue(
                                                  field.name,
                                                  option.value
                                                );
                                                if (!option || !option.value) {
                                                  setFieldValue(
                                                    'PremiumEntitlementId',
                                                    null
                                                  );
                                                }
                                              }}
                                            />

                                            {touched[`${field.name}`] &&
                                              errors[`${field.name}`] && (
                                                <FormHelperText error>
                                                  {errors[`${field.name}`]}
                                                </FormHelperText>
                                              )}
                                          </>
                                        )}
                                      </ApolloConsumer>
                                    )}
                                  />
                                </Grid>
                              </Box>
                            </Box>
                          )}
                          {workType === 'Milestone Teams' && (
                            <>
                              <Box
                                className={clsx(
                                  classes.textfield,
                                  classes.textFieldMaterial
                                )}
                              >
                                <label>Entitlement Type</label>

                                <Field
                                  name="entitlementType"
                                  label="Entitlement Type"
                                  fullWidth
                                  component={({
                                    field,
                                    form: { touched, errors, setFieldValue },
                                  }: any) => (
                                    <>
                                      <MaterialUISelect
                                        id="demo-simple-select-outlined"
                                        value={values.entitlementType}
                                        placeholder="Select Entitlement Type"
                                        fullWidth
                                        disabled={
                                          !values.StudentId ? true : false
                                        }
                                        onChange={(item: any) => {
                                          setFieldValue(
                                            'entitlementType',
                                            item.target.value
                                          );
                                          setFieldValue('missCall', false);
                                          setFieldValue('activityDuration', '');
                                          setTouched({
                                            ...touched,
                                            activityDuration: false,
                                          });
                                          if (
                                            paymentCategories &&
                                            paymentCategories.length
                                          ) {
                                            setWorkTypeForMilestoneTeamValues(
                                              values.milestoneTeam,
                                              item.target.value,
                                              setFieldValue,
                                              paymentCategories
                                            );
                                          }
                                          console.log({
                                            milestoneTeam: values.milestoneTeam,
                                            entitlementType: item.target.value,
                                          });
                                        }}
                                      >
                                        {entitlementTypes.map((item) => (
                                          <MenuItem value={item.value}>
                                            {item.label}
                                          </MenuItem>
                                        ))}
                                      </MaterialUISelect>
                                      {touched[`${field.name}`] &&
                                        errors[`${field.name}`] && (
                                          <FormHelperText error>
                                            {errors[`${field.name}`]}
                                          </FormHelperText>
                                        )}
                                    </>
                                  )}
                                />
                              </Box>
                            </>
                          )}

                          {workType !== 'Other' &&
                            workType !== 'Speciality Team' &&
                            workType !== 'Milestone Teams' &&
                            workType !== 'Dress Rehearsal' && (
                              <Box
                                className={clsx(
                                  classes.textfield,
                                  classes.textFieldMaterial
                                )}
                              >
                                <label>Activity Performed</label>
                                <Box className={classes.activityPerformed}>
                                  <Field
                                    name="workActivity"
                                    label="Activity"
                                    fullWidth
                                    component={({
                                      field,
                                      form: { touched, errors, setFieldValue },
                                    }: any) => (
                                      <>
                                        <MaterialUISelect
                                          disabled={disableEditing}
                                          id="demo-simple-select-outlined"
                                          value={values.workActivity}
                                          placeholder="Select Activity"
                                          fullWidth
                                          native={false}
                                          displayEmpty={true}
                                          input={<OutlinedInput />}
                                          renderValue={(selected: any): React.ReactNode => {
                                            if (selected.length === 0) {
                                              return (
                                                <Box style={{ color: '#A9A9A9' }}>
                                                  Select Activity
                                                </Box>
                                              );
                                            }
                                            return (
                                              <div>
                                                {
                                                  timeLogAllWorkActivityOptions.find(
                                                    (option) =>
                                                      option.value === selected
                                                  )?.label
                                                }
                                              </div>
                                            );
                                          }}
                                          className={
                                            dirty &&
                                            !studentValue?.initialKickOffDate &&
                                            (values.workActivity ===
                                              'Video Chat Session, 2+ Advisors' ||
                                              values.workActivity ===
                                                'Video Chat Session, Solo')
                                              ? 'MuiErrorField'
                                              : ''
                                          }
                                          onChange={(item: any) => {
                                            setFieldValue('missCall', false);
                                            setFieldValue('entitlementType', '');
                                            setFieldValue('milestoneTeam', '');
                                            setFieldValue('milestone', '');
                                            setShowStudentApplications(false);
                                            setPrevoiusActivityPerformed(
                                              values.workActivity
                                            );
                                            setFieldValue(
                                              'workActivity',
                                              item.target.value
                                            );
                                            setFieldValue('activityDuration', '');
                                            setTouched({
                                              ...touched,
                                              activityDuration: false,
                                            });
                                            if (
                                              item.target.value !==
                                                'Video Chat Session, 2+ Advisors' ||
                                              item.target.value !==
                                                'Orientation/Kick-Off Call, 2+ Advisors'
                                            )
                                              setFieldValue(
                                                'otherAdvisorIds',
                                                []
                                              );
                                            if (
                                              paymentCategories &&
                                              paymentCategories.length &&
                                              !user?.isFullTime
                                            ) {
                                              setWorkTypeValue(
                                                item.target.value,
                                                setFieldValue,
                                                paymentCategories
                                              );
                                            }
                                            if (
                                              item.target.value ===
                                                'Video Chat Session, 2+ Advisors' ||
                                              item.target.value ===
                                                'Video Chat Session, Solo' ||
                                              item.target.value ===
                                                'Orientation/Kick-Off Call, Solo' ||
                                              item.target.value ===
                                                'Orientation/Kick-Off Call, 2+ Advisors'
                                            ) {
                                              setOpenDialogue(true);
                                            } else {
                                              setFieldValue('activityAt', null);
                                              setNoOfficialMeet(false);
                                              setFieldValue(
                                                'otherMeetingDescription',
                                                ''
                                              );
                                              setSelectedAdvisorBookingId(0);
                                            }
                                          }}
                                        >
                                          {workType === 'Advisor Operations'
                                            ? workActivityForAdvisorOperations.map(
                                                (item) => (
                                                  <MenuItem value={item.value}>
                                                    {item.label}
                                                  </MenuItem>
                                                )
                                              )
                                            : workActivityForStudentAdvising.map(
                                                (item) => (
                                                  <MenuItem value={item.value}>
                                                    {item.label}
                                                  </MenuItem>
                                                )
                                              )}
                                        </MaterialUISelect>
                                        {!studentValue?.initialKickOffDate &&
                                        (values.workActivity ===
                                          'Video Chat Session, 2+ Advisors' ||
                                          values.workActivity ===
                                            'Video Chat Session, Solo') ? (
                                          <FormHelperText error>
                                            {`An orientation call has not been logged for ${studentValue?.label}. Please change activity type to “Orientation Call” if appropriate.`}
                                          </FormHelperText>
                                        ) : (
                                          touched[`${field.name}`] &&
                                          errors[`${field.name}`] && (
                                            <FormHelperText error>
                                              {errors[`${field.name}`]}
                                            </FormHelperText>
                                          )
                                        )}
                                      </>
                                    )}
                                  />
                                </Box>
                              </Box>
                            )}

                          {!studentValue?.initialKickOffDate &&
                            (values.workActivity ===
                              'Video Chat Session, 2+ Advisors' ||
                              values.workActivity ===
                                'Video Chat Session, Solo') && (
                              <>
                                <Grid
                                  item
                                  lg={12}
                                  xs={12}
                                  className={classes.dateDuration}
                                >
                                  <Box
                                    className={`${clsx(
                                      classes.textfield,
                                      classes.subSectionPadding
                                    )} with-textarea `}
                                  >
                                    <Typography className="activityLog">
                                      Why is this meeting being logged before an
                                      Orientation call?
                                    </Typography>

                                    <Field
                                      placeholder="Required"
                                      name="noteForNoOrientationCall"
                                      component={TextField}
                                      multiline
                                      rows={4}
                                      fullWidth
                                    />
                                  </Box>
                                </Grid>
                              </>
                            )}

                          {openDialogue && (
                            <LinkOnlineMeetingDialog
                              openDialogue={openDialogue}
                              studentName={studentValue?.label}
                              studentId={studentValue?.value}
                              setFieldValue={setFieldValue}
                              prevoiusActivityPerformed={
                                prevoiusActivityPerformed
                              }
                              activityPerformed={values.workActivity}
                              setNoOfficialMeet={setNoOfficialMeet}
                              setSelectedAdvisorBookingId={
                                setSelectedAdvisorBookingId
                              }
                              selectedAdvisorBookingId={
                                selectedAdvisorBookingId
                              }
                              onClose={() => {
                                setOpenDialogue(false);
                                // if (values.activityAt.length) {
                                //   setIsDateSelected(true);
                                // }
                              }}
                            />
                          )}

                          {selectedAdvisorBookingId || noOfficialMeet ? (
                            <Box
                              display="flex"
                              alignItems="center"
                              mb={1.75}
                              pt={1.375}
                            >
                              {noOfficialMeet ? (
                                <img src={CautionIcon} alt="warning" />
                              ) : (
                                <img src={CheckedLightIcon} alt="checked" />
                              )}

                              <Typography
                                variant="body1"
                                className="onlineMeetingVerified"
                              >
                                {noOfficialMeet
                                  ? 'No Online Meeting Verified'
                                  : 'Online Meeting Verified'}
                              </Typography>

                              {noOfficialMeet ? (
                                <Button
                                  variant="text"
                                  onClick={() => {
                                    setOpenDialogue(true);
                                  }}
                                  className="textActionButton"
                                >
                                  Add Meeting
                                </Button>
                              ) : (
                                <Button
                                  variant="text"
                                  className="textActionButton"
                                  onClick={() => setOpenDialogue(true)}
                                >
                                  Change
                                </Button>
                              )}
                            </Box>
                          ) : (
                            ''
                          )}

                          {(values.workActivity ===
                            'Video Chat Session, 2+ Advisors' ||
                            values.workActivity ===
                              'Orientation/Kick-Off Call, 2+ Advisors') &&
                            !disableEditing && (
                              <Grid
                                className={clsx(
                                  classes.textfield,
                                  classes.secondAdvisorApplication
                                )}
                              >
                                <label>Other Advisors</label>
                                <Field
                                  name="otherAdvisorIds"
                                  label="Other Advisor"
                                  fullWidth
                                  value={values.otherAdvisorIds}
                                  component={({
                                    field,
                                    form: { touched, errors, setFieldValue },
                                  }: any) => (
                                    <>
                                      <AdvisorSelect
                                        value={values.otherAdvisorIds}
                                        advisorId={user?.id}
                                        onChange={(val: OptionType[]) => {
                                          setFieldValue('otherAdvisorIds', val);
                                        }}
                                        placeholder="Other Advisors"
                                      />
                                      {touched[`${field.name}`] &&
                                        errors[`${field.name}`] && (
                                          <FormHelperText error>
                                            {errors[`${field.name}`]}
                                          </FormHelperText>
                                        )}
                                    </>
                                  )}
                                />
                              </Grid>
                            )}

                          <Field name="PaymentCategoryId" type="hidden" />

                          {(workType === 'Student Advising' ||
                            workType === 'Milestone Teams') &&
                            (values.workActivity ===
                              'Video Chat Session, Solo' ||
                              values.workActivity ===
                                'Video Chat Session, 2+ Advisors' ||
                              values.workActivity.includes(
                                'Orientation/Kick-Off Call'
                              ) ||
                              values.entitlementType.includes('Video')) && (
                              <Grid
                                item
                                xs={8}
                                className={classes.dateDuration}
                              >
                                <Box
                                  className={clsx(
                                    classes.textfield,
                                    classes.missedCallCheckbox
                                  )}
                                  marginBottom="0px !important"
                                >
                                  <Field
                                    name="missCall"
                                    component={({
                                      form: { values, setFieldValue },
                                    }: any) => (
                                      <>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={values.missCall}
                                              disabled={disableEditing}
                                              onChange={({
                                                target: { checked },
                                              }) => {
                                                if (checked) {
                                                  setFieldValue(
                                                    'activityDuration',
                                                    15
                                                  );
                                                } else if (
                                                  values.actualMeetingTime
                                                ) {
                                                  setFieldValue(
                                                    'activityDuration',
                                                    values.actualMeetingTime
                                                  );
                                                }
                                                setFieldValue(
                                                  'missCall',
                                                  checked
                                                );
                                              }}
                                              color="primary"
                                            />
                                          }
                                          label={'Student Missed Call'}
                                        />
                                      </>
                                    )}
                                  />
                                </Box>
                              </Grid>
                            )}

                          <Grid container>
                            <Grid item lg={2} xs={12}></Grid>
                            <Grid
                              item
                              lg={6}
                              xs={12}
                              className={classes.dateDuration}
                            >
                              {values.workActivity !== 'Onboarding' && (
                                <Box
                                  className={clsx(
                                    classes.textfield,
                                    classes.textFieldDate,
                                    selectedAdvisorBookingId &&
                                      !noOfficialMeet &&
                                      classes.disableTextFieldDate
                                  )}
                                >
                                  <label
                                    {...(selectedAdvisorBookingId &&
                                      !noOfficialMeet && {
                                        style: {
                                          color: colors.BLACK_ALPHA,
                                        },
                                      })}
                                    className={
                                      selectedAdvisorBookingId &&
                                      !noOfficialMeet
                                        ? classes.disableLabelText
                                        : ''
                                    }
                                  >
                                    Date
                                  </label>

                                  <Field
                                    name="activityAt"
                                    fullWidth
                                    value={formatDate(initialValues.activityAt)}
                                    component={({ field, form }: any) => (
                                      <NativeTimeTextField
                                        disable={
                                          selectedAdvisorBookingId &&
                                          !noOfficialMeet
                                            ? true
                                            : false
                                        }
                                        field={field}
                                        form={form}
                                        maxDate={new Date()}
                                      />
                                    )}
                                  />
                                </Box>
                              )}
                            </Grid>
                            <Grid
                              item
                              lg={4}
                              xs={12}
                              className={classes.durationField}
                            >
                              {values.workActivity !== 'Onboarding' &&
                                workType !== 'Dress Rehearsal' && (
                                  <Box
                                    mb={0}
                                    className={`${clsx(
                                      classes.textfield,
                                      classes.textfieldDateDuration,
                                      (values.workActivity === 'Onboarding' &&
                                        onBoarded) ||
                                        (values.missCall &&
                                          classes.disableTextFieldDate)
                                    )} hide-error`}
                                  >
                                    <label
                                      className={
                                        (values.workActivity === 'Onboarding' &&
                                          onBoarded) ||
                                        values.missCall
                                          ? classes.disableLabelText
                                          : ''
                                      }
                                    >
                                      Duration (minutes)
                                    </label>

                                    <Field
                                      name="activityDuration"
                                      placeholder="mm"
                                      fullWidth
                                      component={NumberInput}
                                      disabled={
                                        (values.workActivity === 'Onboarding' &&
                                          onBoarded) ||
                                        values.missCall
                                      }
                                      className={
                                        errors.activityDuration &&
                                        touched.activityDuration
                                          ? classes.MuiErrorField
                                          : ''
                                      }
                                    />
                                    <span className="requiredText">
                                      <ErrorMessage
                                        name="activityDuration"
                                        className="requiredText"
                                      />
                                    </span>
                                  </Box>
                                )}
                            </Grid>
                            {noOfficialMeet && (
                              <Grid
                                item
                                xs={12}
                                className={classes.dateDuration}
                                style={{ padding: 0, margin: 0 }}
                              >
                                <Box
                                  className={`${clsx(
                                    classes.textfield,
                                    classes.subSectionPadding
                                  )} with-textarea`}
                                >
                                  <Typography
                                    variant="body1"
                                    className="activityLog"
                                  >
                                    Why is there no “My Meeting” associated with
                                    this activity log?
                                  </Typography>

                                  <Field
                                    name="otherMeetingDescription"
                                    placeholder="Required"
                                    component={TextField}
                                    multiline
                                    rows={4}
                                    fullWidth
                                  />
                                </Box>
                              </Grid>
                            )}
                            {values.actualMeetingTime &&
                            ((values.activityDuration >=
                              values.actualMeetingTime + 5 &&
                              values.activityDuration <= 90) ||
                              values.activityDuration <=
                                values.actualMeetingTime - 15) &&
                            !noOfficialMeet &&
                            !values.missCall ? (
                              <Grid
                                item
                                xs={12}
                                className={classes.dateDuration}
                                style={{ padding: 0, margin: 0 }}
                              >
                                <Box
                                  className={`${clsx(
                                    classes.textfield,
                                    classes.subSectionPadding
                                  )} with-textarea`}
                                >
                                  <Typography className="activityLog">
                                    Why doesn't the duration reflect the
                                    expected meeting time?
                                  </Typography>
                                  <Field
                                    name="noteForExtendedMeeting"
                                    component={TextField}
                                    multiline
                                    rows={4}
                                    placeholder="Required"
                                    fullWidth
                                  />
                                </Box>
                              </Grid>
                            ) : (
                              ''
                            )}
                            {values.activityDuration > 90 && (
                              <Grid
                                item
                                xs={12}
                                className={classes.dateDuration}
                                style={{ padding: 0 }}
                              >
                                <Box
                                  className={`${clsx(
                                    classes.textfield,
                                    classes.subSectionPadding
                                  )} with-textarea`}
                                >
                                  <Typography className="activityLog">
                                    Why did this activity exceed 90 minutes?*
                                  </Typography>

                                  <Field
                                    multiline
                                    rows={4}
                                    fullWidth
                                    name="durationNote"
                                    component={TextField}
                                    placeholder="Required"
                                  />
                                </Box>
                              </Grid>
                            )}{' '}
                          </Grid>

                          {(workType === 'Student Advising' ||
                            workType === 'Milestone Teams') &&
                            values.workActivity !==
                              'Partner - Leadership Initiatives' &&
                            !noOfficialMeet &&
                            !values.missCall && (
                              <>
                                <Box
                                  className={clsx(
                                    classes.textfield,
                                    classes.textFieldMaterial
                                  )}
                                >
                                  <label>Milestone</label>

                                  <Field
                                    name="milestone"
                                    label="Milestone"
                                    fullWidth
                                    component={({
                                      field,
                                      form: { touched, errors, setFieldValue },
                                    }: any) => (
                                      <>
                                        <MaterialUISelect
                                          id="demo-simple-select-outlined"
                                          value={values.milestone}
                                          label="Select Milestone"
                                          placeholder="Select Milestone"
                                          fullWidth
                                          disabled={
                                            (!values.workActivity &&
                                              !values.milestoneTeam) ||
                                            values.workActivity ===
                                              'Meeting with other Advisor/AO'
                                          }
                                          onChange={(item: any) => {
                                            setFieldValue('isCommonApp', false);
                                            setFieldValue(
                                              'isCoalitionApp',
                                              false
                                            );
                                            setFieldValue(
                                              'isOtherStrategy',
                                              false
                                            );
                                            if (item.target.value === 'Other') {
                                              setFieldValue(
                                                'serviceElement',
                                                ''
                                              );
                                            }
                                            if (
                                              item.target.value ===
                                                'Application Strategy' ||
                                              item.target.value ===
                                                'Essay Writing & Editing' ||
                                              item.target.value ===
                                                'Interview Prep'
                                            ) {
                                              setShowStudentApplications(true);
                                              if (
                                                !allStudentApplications.length
                                              ) {
                                                mountEffect();
                                              }
                                            } else {
                                              setShowStudentApplications(false);
                                              setStudentApplicationIds([]);
                                              setFieldValue(
                                                'OtherApplicationsIds',
                                                null
                                              );
                                            }
                                            setFieldValue(
                                              'milestone',
                                              item.target.value
                                            );
                                          }}
                                        >
                                          {mileStoneOptions.map((item) => (
                                            <MenuItem value={item.value}>
                                              {item.label}
                                            </MenuItem>
                                          ))}
                                        </MaterialUISelect>
                                        {touched[`${field.name}`] &&
                                          errors[`${field.name}`] && (
                                            <FormHelperText error>
                                              {errors[`${field.name}`]}
                                            </FormHelperText>
                                          )}
                                      </>
                                    )}
                                  />

                                  <Box mt={1}>
                                    {values.milestone ===
                                      'Essay Writing & Editing' && (
                                      <Grid
                                        className={classes.logHoursCheckbox}
                                      >
                                        <Grid>
                                          <label>
                                            <Field
                                              as={Checkbox}
                                              color="primary"
                                              type="checkbox"
                                              name="isCommonApp"
                                            />
                                            Common App
                                          </label>
                                          <label>
                                            <Field
                                              as={Checkbox}
                                              color="primary"
                                              type="checkbox"
                                              name="isCoalitionApp"
                                            />
                                            Coalition App
                                          </label>
                                        </Grid>

                                        <Grid>
                                          <label>
                                            <Field
                                              as={Checkbox}
                                              color="primary"
                                              type="checkbox"
                                              name="isOtherStrategy"
                                            />
                                            Other / General Strategy
                                          </label>
                                        </Grid>
                                      </Grid>
                                    )}
                                  </Box>

                                  {showStudentApplications ? (
                                    <>
                                      <Box
                                        mt={2.1}
                                        className={clsx(
                                          classes.textfield,
                                          classes.secondAdvisorApplication
                                        )}
                                      >
                                        <label>Student Application</label>

                                        <Grid
                                          className={
                                            classes.studentApplicationsSelect
                                          }
                                        >
                                          <Select
                                            menuPlacement="bottom"
                                            isMulti
                                            id="college1"
                                            placeholder={
                                              values.isCommonApp ||
                                              values.isCoalitionApp ||
                                              values.isOtherStrategy ||
                                              values.OtherApplicationsIds
                                                ?.length ||
                                              values.milestone ===
                                                'Application Strategy'
                                                ? 'Optional'
                                                : 'Required'
                                            }
                                            defaultValue={
                                              selectedStudentApplications
                                            }
                                            options={allStudentApplications}
                                            classNamePrefix="react-select"
                                            className="react-select-container"
                                            onChange={(selectedOptions) => {
                                              const selectedApplicationOptions =
                                                [...selectedOptions];
                                              const UCStudentApplicationIndex =
                                                selectedApplicationOptions.findIndex(
                                                  (option) =>
                                                    option.label ===
                                                    'University of California (any)'
                                                );

                                              if (
                                                UCStudentApplicationIndex >= 0
                                              ) {
                                                selectedApplicationOptions.splice(
                                                  UCStudentApplicationIndex,
                                                  1,
                                                  ...UCSubCampusApplications
                                                );
                                              }

                                              handleSelectChange(
                                                selectedApplicationOptions,
                                                setFieldValue,
                                                values
                                              );
                                            }}
                                          />
                                        </Grid>
                                      </Box>
                                      {isAddNewApplication ? (
                                        <Box
                                          mt={2.1}
                                          className={clsx(
                                            classes.textfield,
                                            classes.secondAdvisor
                                          )}
                                        >
                                          <OtherApplications
                                            loadOptions={
                                              loadCollegeApplicationsOptions
                                            }
                                            setFieldValue={setFieldValue}
                                          />
                                        </Box>
                                      ) : (
                                        <Box
                                          className={classes.addNewApplications}
                                        >
                                          {!isDisabledState && (
                                            <Button
                                              onClick={() => {
                                                setIsAddNewApplication(true);
                                              }}
                                              color="primary"
                                              variant="text"
                                            >
                                              <img src={AddIcon} alt="add" />
                                              Add New Application(s)
                                            </Button>
                                          )}
                                        </Box>
                                      )}
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </Box>
                              </>
                            )}

                          {workType === 'Dress Rehearsal' && (
                            <Box
                              className={clsx(
                                classes.textfield,
                                classes.missedCallCheckbox
                              )}
                              marginBottom="0px !important"
                            >
                              <Field
                                name="airtableSubmitted"
                                component={({
                                  form: { values, setFieldValue },
                                }: any) => (
                                  <>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={values.airtableSubmitted}
                                          onChange={({
                                            target: { checked },
                                          }) => {
                                            setFieldValue(
                                              'airtableSubmitted',
                                              checked
                                            );
                                          }}
                                          color="primary"
                                        />
                                      }
                                      label={'Airtable Form Submitted'}
                                    />
                                  </>
                                )}
                              />
                            </Box>
                          )}

                          {workType === 'Student Advising' ? (
                            <Box
                              marginTop={2}
                              className={classes.addButton}
                              onClick={() => setAddNote(!addNote)}
                            >
                              {PENCIL_IC()} <span>Add Note (Optional)</span>
                            </Box>
                          ) : workType === 'Milestone Teams' ? (
                            <Box
                              marginTop={2}
                              className={clsx(classes.addButton)}
                            >
                              <Button
                                onClick={() => setAddNote(!addNote)}
                                color="primary"
                                variant="text"
                                style={{
                                  padding: 0,
                                  margin: '17px 0px 0px -5px',
                                }}
                              >
                                <img src={AddIcon} alt="add" />
                                Add Note
                              </Button>
                            </Box>
                          ) : (
                            <></>
                          )}

                          {workType === 'Student Advising' ||
                          workType === 'Milestone Teams'
                            ? addNote && (
                                <Box
                                  className={`${classes.textfield} with-textarea`}
                                >
                                  <label>Notes</label>

                                  <Field
                                    name="note"
                                    fullWidth
                                    multiline
                                    placeholder="Notes"
                                    component={TextField}
                                  />
                                </Box>
                              )
                            : workType !== 'Dress Rehearsal' && (
                                <Box
                                  className={`${classes.textfield} with-textarea`}
                                >
                                  <label>
                                    Notes
                                    {workType !== 'Speciality Team' && (
                                      <>
                                        <sup>*</sup> (Required)
                                      </>
                                    )}
                                  </label>

                                  <Field
                                    name="note"
                                    component={TextField}
                                    multiline
                                    rows={4}
                                    fullWidth
                                  />
                                </Box>
                              )}
                        </Box>
                      </div>
                    </Box>

                    {!isDisabledState && (
                      <Box className={classes.formRight}>
                        <Box>
                          <Box display="flex" alignItems="center">
                            <Box fontSize={16} mr={2} fontFamily={fonts.INTER}>
                              Expected Payment:
                            </Box>

                            <Box>
                              {(workType === 'Other' ||
                                workType === 'Speciality Team' ||
                                workType === 'Dress Rehearsal' ||
                                workType === 'Milestone Teams' ||
                                values.workActivity) &&
                              values.activityDuration ? (
                                <Box className="amountText">
                                  {user?.isFullTime ||
                                  !values.PaymentCategoryId ||
                                  !!!paymentCategories.length
                                    ? '$0'
                                    : `$${expectedPayment(
                                        paymentCategories,
                                        values
                                      )}`}
                                </Box>
                              ) : values.workActivity === 'Onboarding' ? (
                                <Box className="amountText">
                                  {user?.isFullTime
                                    ? '$0'
                                    : `$${exp_payment_Onboarding()}`}
                                </Box>
                              ) : (
                                <Box className="amountText">--</Box>
                              )}
                            </Box>
                          </Box>

                          <Grid className={classes.logHoursAlert}>
                            {!errors['activityDuration'] &&
                              values.activityDuration > 90 && (
                                <>
                                  <Alert severity="warning">
                                    <Box display="flex">
                                      <img src={Warning_Img} alt="token" />
                                      <Typography variant="h5">
                                        Please double check the following:
                                      </Typography>
                                    </Box>
                                    <ul>
                                      <li>Duration (minutes)</li>
                                    </ul>
                                  </Alert>
                                </>
                              )}
                            <Box mt={2} className={classes.expectedPayment}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={checked}
                                    inputProps={{
                                      'aria-label': 'controlled',
                                    }}
                                    onChange={(event) =>
                                      setChecked(event.target.checked)
                                    }
                                    color="default"
                                  />
                                }
                                label="This certifies that this time log accurately represents the activity performed, the duration required to complete the activity and the student who is assessed (if Student Advising activity) is correct. This activity adheres to the honor code and advisor agreement that I signed with CollegeAdvisor.com."
                              />
                            </Box>
                          </Grid>
                        </Box>

                        <Box textAlign="right" pt={2}>
                          <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            disabled={
                              isError ||
                              isApplicationNotAdded ||
                              !checked ||
                              isSubmitting ||
                              (values.workActivity === 'Onboarding' &&
                                onBoarded)
                            }
                            className={classes.submitButton}
                            style={{
                              fontWeight: 700,
                              textTransform: 'capitalize',
                            }}
                          >
                            Update Activity
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  {errors.PaymentCategoryId && (
                    <span className="requiredText">
                      {errors.PaymentCategoryId}
                    </span>
                  )}
                </Form>
              </>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default TimeLogForm;
