import AddIcon from '@material-ui/icons/Add';
import {
  Box,
  IconButton,
  Grid,
  Typography,
  Card,
  Avatar,
  Collapse,
} from '@material-ui/core';

import Colors from '../../../../common/colors';
import { useStyles } from './BookingMeetingStyle';

import Close_Icon_Blue from '../../../../img/blue-close.svg';
import Add_Advisor_Img from '../../../../img/plus-circle.svg';
import { StudentDetailQuery_Student_allStudentMentors_mentor } from '../__generated__/StudentDetailQuery';
import { getAllStudentMentors_allStudentMentors } from './gql/__generated__/getAllStudentMentors';

const AdvisorsSelection = ({
  advisorId,
  allowAdvisorSelection,
  selectedAdvisors,
  setSelectedAdvisors,
  setMainAdvisor,
  setSelectedTimeslot,
  setAllowAdvisorSelection,
  allStudentMentors,
  noKickOffMeeting,
}: {
  advisorId: number;
  allowAdvisorSelection: boolean;
  selectedAdvisors: (
    | StudentDetailQuery_Student_allStudentMentors_mentor
    | null
    | undefined
  )[];
  setSelectedAdvisors: Function;
  setMainAdvisor: Function;
  setSelectedTimeslot: Function;
  setAllowAdvisorSelection: Function;
  allStudentMentors: (getAllStudentMentors_allStudentMentors | null)[] | null;
  noKickOffMeeting?: boolean;
}) => {
  const classes = useStyles();

  const userHasMoreMentors = !!allStudentMentors?.filter(
    (item) =>
      !Object.keys(
        selectedAdvisors?.find((mntr) => mntr?.id === item?.mentor?.id) || {}
      )?.length
  ).length;

  const canAddAdvisor = noKickOffMeeting
    ? true
    : selectedAdvisors?.length < 1; 

  return (
    <Box className={classes.addAdvisor}>
      <Box pb={!!selectedAdvisors.length ? 3 : 0}>
        <Typography variant="h5">Advisor</Typography>
      </Box>

      <Collapse in={!!selectedAdvisors.length}>
        <Grid className={classes.advisorCard} container spacing={2}>
          {selectedAdvisors?.map((advisor, index) => {
            return (
              <Grid item md={6} xs={12} key={index}>
                <Card className="advisorInfoCard">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center">
                      <Avatar
                        className="advisorAvatar"
                        src={advisor?.pictureUrl || ''}
                        alt={advisor?.firstName || ''}
                      />

                      <Box>
                        <Typography variant="h5" noWrap>
                          {advisor?.firstName} {advisor?.lastName}
                        </Typography>

                        <Box color={Colors.BLUE_SEVEN}>
                          <Typography variant="body2" noWrap color="inherit">
                            {advisor?.advisorLevel}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <IconButton
                      aria-label="close"
                      className="advisorButton"
                      onClick={() => {
                        const _selectedAvds = [...selectedAdvisors];
                        const _filterdAdvs = _selectedAvds.filter(
                          (_selcted) => _selcted?.id !== advisor?.id
                        );
                        setSelectedAdvisors([..._filterdAdvs]);
                        setMainAdvisor(_filterdAdvs?.[0]?.id || advisorId);
                        setSelectedTimeslot(null);
                      }}
                    >
                      <img src={Close_Icon_Blue} alt="close" />
                    </IconButton>
                  </Box>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Collapse>

      <Collapse in={userHasMoreMentors && canAddAdvisor}>
        <Box
          display="flex"
          color={Colors.BLUE_SEVEN}
          className="cursorPointer"
          pt={3}
          onClick={() => setAllowAdvisorSelection(!allowAdvisorSelection)}
        >
          <Box mr={1}>
            <img src={Add_Advisor_Img} alt="" />
          </Box>

          <Typography
            className="addAdvisor"
            variant="h6"
            color="inherit"
          >{`ADD ${
            !!selectedAdvisors.length ? 'ANOTHER' : ''
          } ADVISOR`}</Typography>
        </Box>
      </Collapse>

      <Collapse in={allowAdvisorSelection && canAddAdvisor}>
        <Box pt={3}>
          <Grid className={classes.advisorCard} container spacing={2}>
            {allStudentMentors
              ?.filter(
                (item) =>
                  !Object.keys(
                    selectedAdvisors?.find(
                      (mntr) => mntr?.id === item?.mentor?.id
                    ) || {}
                  )?.length
              )
              ?.map((adv, index) => {
                return (
                  <Grid item md={6} xs={12} key={adv?.mentor?.id || index}>
                    <Card
                      className="advisorInfoCard"
                      onClick={() => {
                        const _mentor = selectedAdvisors?.find(
                          (x) => x?.id === adv?.mentor?.id
                        );
                        if (!_mentor || !Object?.keys(_mentor)?.length) {
                          const _selectedAvds = [...selectedAdvisors];
                          if (adv?.mentor) {
                            _selectedAvds.push(adv?.mentor);
                          }
                          setSelectedAdvisors([..._selectedAvds]);
                          setMainAdvisor(_selectedAvds?.[0]?.id || advisorId);
                          setSelectedTimeslot(null);
                        }
                      }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box display="flex" alignItems="center">
                          {adv?.mentor?.pictureUrl ? (
                            <Avatar
                              className="advisorAvatar"
                              src={adv?.mentor?.pictureUrl}
                            />
                          ) : (
                            <Avatar className="advisorAvatar" src={''} />
                          )}
                          <Box>
                            <Typography variant="h5">
                              {adv?.mentor?.firstName} {adv?.mentor?.lastName}
                            </Typography>

                            <Box color={Colors.BLUE_SEVEN}>
                              <Typography
                                variant="body2"
                                noWrap
                                color="inherit"
                              >
                                {adv?.mentor?.advisorLevel}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>

                        <IconButton aria-label="add" className="advisorButton">
                          <AddIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </Collapse>
    </Box>
  );
};
export default AdvisorsSelection;
