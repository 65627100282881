import { FC, Fragment, useState } from 'react';
import { Mutation } from 'react-apollo';
import {
  DeleteAppMutation,
  DeleteAppMutationVariables,
} from '../../../graphql/mutations/StudentDetailPage/__generated__/DeleteAppMutation';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { customTheme } from '../../MaterializeCss';

import { useStylesApplication } from './applicationStyle';
import { DELETE_APP_MUTATION } from '../../../graphql/mutations/StudentDetailPage';
import Toast from '../../../components/Toast';
import { Tostcongigs } from '../account/AccountInformation';

interface Props {
  open: boolean;
  onClose: () => void;
  id: number | undefined;
  studentId: number;
  collegeName: string | undefined;
  refetch: Function;
}

const DeleteApplicationModal: FC<Props> = ({
  open,
  onClose,
  id,
  refetch,
  studentId,
  collegeName,
}) => {
  const [toast, setToast] = useState<Tostcongigs>({
    message: '',
    type: undefined,
  });
  const [openToast, setOpenToast] = useState(false);
  const applicationClasses = useStylesApplication();

  return (
    <Fragment>
      <Toast
        open={openToast}
        close={() => {
          setOpenToast(false);
        }}
        {...toast}
      />
      <Dialog
        open={open}
        onClose={onClose}
        className={applicationClasses.deleteApplicationDialog}
      >
        <Typography variant="h5">Delete {collegeName} Application?</Typography>
        <DialogContent>
          <Typography variant="body1">
            This action cannot be undone. Please make sure this is okay with
            your student.
          </Typography>

          <ul>
            <li>
              <Box display="flex">
                <Box>All essay reviews for {collegeName} will be deleted</Box>
              </Box>
            </li>
          </ul>
        </DialogContent>

        <DialogActions>
          <ThemeProvider theme={customTheme}>
            <Mutation<DeleteAppMutation, DeleteAppMutationVariables>
              mutation={DELETE_APP_MUTATION}
            >
              {(deleteAppMutation: Function, isSubmitting) => {
                console.log('isSubmitting', isSubmitting);
                return (
                  <Button
                    onClick={async () => {
                      try {
                        const res = await deleteAppMutation({
                          variables: { id, studentId },
                        });
                        onClose();
                        setToast({
                          message: 'Application Deleted!',
                          type: 'success',
                        });
                        setOpenToast(true);
                        setTimeout(() => {
                          refetch();
                        }, 800);
                      } catch (error) {
                        setToast({
                          message: 'Something went wrong, Please try again',
                          type: 'error',
                        });
                        setOpenToast(true);
                        console.log('error: ', error);
                      }
                    }}
                    variant="contained"
                    color="secondary"
                    className="dangerButton"
                    disabled={isSubmitting.loading}
                  >
                    {isSubmitting.loading ? 'Deleting...' : 'Delete'}
                  </Button>
                );
              }}
            </Mutation>

            <Button variant="outlined" onClick={onClose}>
              Keep Application
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default DeleteApplicationModal;
