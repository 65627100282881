import { Grid, Typography, Box } from '@material-ui/core';

import { Query } from 'react-apollo';
import { CHECK_STUDENT_PROFILE } from './graphql/queries/studentProfilePage';
import { getProfileData } from './graphql/queries/studentProfilePage/__generated__/getProfileData';
import Loader from '../../../components/Loader';

import { useStyles } from './StudentProfileStyle';
import StudentProfileHeader from '../../../components/layout/StudentProfileHeader';

const MBAStudentProfile = ({ studentId }: { studentId: number | null }) => {
  const classes = useStyles();

  function renderText() {
    return (
      <Box>
        <Typography variant="h5" component="h5" className={classes.noAccount}>
          no data found
        </Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={1} className={classes.personalInfo}>
      <Query<getProfileData>
        query={CHECK_STUDENT_PROFILE}
        fetchPolicy={'network-only'}
        variables={{ userId: studentId }}
      >
        {({ data, loading }) => {
          if (loading) return <Loader />;
          if (!data) return <Box marginBottom={3}>{renderText()}</Box>;

          return (
            <Grid container spacing={1} className={classes.personalInfo}>
              <Grid item md={5} sm={12} xs={12}>
                <StudentProfileHeader title="Undergraduate Education" />
                <Box mb="58px">
                  <Typography variant="body2" className="secondaryText">
                    {`${data.getProfileData?.undergraduateCollege || '--'}`}
                    <br />
                    <br />
                    {`Class of ${data.getProfileData?.undergraduateGradYear}`}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          );
        }}
      </Query>
    </Grid>
  );
};

export default MBAStudentProfile;
