import { FC, ComponentType, useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { AppContext } from '../contexts';

interface PrivateRouteProps extends RouteProps {
  component: ComponentType<any>;
}
const PrivateRoute: FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}): JSX.Element => {
  const { user } = useContext(AppContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        user || window.localStorage.token ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
