import { ApolloLink, ApolloClient, InMemoryCache } from 'apollo-boost';
import { APP_VERSION, TOKEN } from '../constants';
import { createUploadLink } from 'apollo-upload-client';

const authMiddleware = new ApolloLink((operation: any, forward: any) => {
  const token = localStorage.getItem(TOKEN) || null;
  operation.setContext({
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  return forward(operation).map((response: any) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    //  for setting new token
    if (headers) {
      const newToken = headers.get('New-Token');
      if (newToken !== null) {
        localStorage.removeItem(TOKEN);
        localStorage.setItem(TOKEN, newToken);
      }
    }

    if (headers) {
      const app_version = headers.get('Version');

      if (
        (localStorage.getItem(TOKEN) && !localStorage.getItem(APP_VERSION)) ||
        (localStorage.getItem(APP_VERSION) &&
          localStorage.getItem(APP_VERSION) !== app_version)
      ) {
        localStorage.setItem(APP_VERSION, app_version);
        window.location.reload();
      }

      if (!localStorage.getItem(TOKEN) && !localStorage.getItem(APP_VERSION)) {
        localStorage.setItem(APP_VERSION, app_version);
      }
    }
    return response;
  });
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  connectToDevTools: true,
  link: ApolloLink.from([
    authMiddleware,
    createUploadLink({
      uri: process.env.REACT_APP_GRAPHQL_URI,
    }),
  ]),
});

export default client;
