import { useContext, FC, useEffect } from 'react';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import client from '../../../apollo';
import { GET_ADVISOR_LEAD } from '../../../graphql/queries/advisorLead';

import { AppContext } from '../../../contexts';
import Loader from '../../../components/Loader';

import { Maintenance } from '../../errorPages';
import { convertTimeZoneForFE } from '../../../common/utility';

export const CURRENT_USER_QUERY = gql`
  query CurrentUserQuery {
    currentUser {
      id
      firstName
      lastName
      phoneNumber
      email
      personalEmail
      pictureUrl
      stripeConnectAccount
      intakeYear
      graduateSchoolGradYear
      major
      haveMilestoneTeamRates
      degree
      gpa
      bio
      isAcceptedTo
      isActive
      isOnline
      isFullTime
      gender
      advisorLevel
      sendbirdId
      pronoun
      internationalStudentExperience
      # nylas_access_token
      # nylas_edit_page_token
      nylas_grant_id
      nylas_edit_page_token
      zoomLink
      userMajors {
        id
        educationType
        degreeType
        MajorId
        majorName
      }
      premiumPackageType
      colleges {
        id
        name
        styleConfig {
          bgDark
          bgLight
        }
      }

      unreadMessagesCount
      timezone
      isPersonalCalendarConnected
      Theme
    }
  }
`;

const MainLayout: FC = ({ children }): JSX.Element => {
  const {
    user,
    setUser,
    isLoggedIn,
    setUnreadMessagesCount,
    unreadMessagesCount,
    advisorLeadData,
    setAdvisorLeadData
  } = useContext(AppContext);

  const getAdvisorLeadAPI = async () => {
    try {
      const { data } = await client.query({
        query: GET_ADVISOR_LEAD,
        fetchPolicy: 'no-cache',
      });

      if(data){
        setAdvisorLeadData(data);
      }
    } catch (error) {
      console.log(error);
    }
  }; 

  useEffect(()=>{
    if(!advisorLeadData && user){
      getAdvisorLeadAPI();
    }
  }, [advisorLeadData, user]);
  

  return (
    <Query<any>
      query={CURRENT_USER_QUERY}
      fetchPolicy="network-only"
      onCompleted={(data) => {
        if (data) {
          setUser(
            data?.currentUser
              ? {
                  ...data?.currentUser,
                  timezone: convertTimeZoneForFE(data?.currentUser.timezone),
                }
              : null
          );

          if (!unreadMessagesCount && data?.currentUser?.unreadMessagesCount)
            setUnreadMessagesCount({
              user,
              isLoggedIn,
              unreadMessagesCount: data?.currentUser?.unreadMessagesCount,
            });
        }
      }}
    >
      {({ loading, data, error, refetch }) => {
        if (loading) {
          return <Loader />;
        }

        if (error && window.location.pathname !== '/maintenance') {
          return <Maintenance />;
        }

        if (
          isLoggedIn &&
          (window.location.pathname === '/login' ||
            window.location.pathname.includes('/reset-password') ||
            window.location.pathname.includes('/invite') ||
            window.location.pathname === '/register')
        ) {
          refetch();
        }

        return <div>{children}</div>;
      }}
    </Query>
  );
};

export default MainLayout;
