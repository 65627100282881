import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { Field, FieldArray, Form, Formik } from 'formik';
import gql from 'graphql-tag';
import { useContext, useEffect, useState } from 'react';
import { Mutation } from 'react-apollo';
import * as Yup from 'yup';

import { useStylesAccount } from './accountStyle';

import Select from 'react-select';
import { handleMutationError } from '../../../common/utility';
import SearchColleges from '../../../components/SearchColleges';
import Toast from '../../../components/Toast';
import { TextField } from '../../../components/fields';
import { OptionType } from '../../../components/multiSelect';
import { AppContext } from '../../../contexts';
import { Tostcongigs } from './ProfilePage';

import client from '../../../apollo';
import {
  UpdateMentorEducation,
  UpdateMentorEducationVariables,
} from './__generated__/UpdateMentorEducation';

import DeleteIcon from '../../../img/delete-payouts.svg';
import AddIcon from '../../../img/plus-circle.svg';
import { getCurrentCollege_currentCollege } from '../../../graphql/queries/Colleges/__generated__/getCurrentCollege';
import {getGraduateSchool_graduateSchool} from '../../../graphql/queries/Colleges/__generated__/getGraduateSchool';

const UPDATE_MENTOR_EDUCATION = gql`
  mutation UpdateMentorEducation(
    $id: Int!
    $intakeYear: String
    $graduateSchoolGradYear: String
    $major: String
    $degree: String
    $CollegeId: Int
    $graduateCollegeId: Int
    $switchThemeTo: String
    $userMajorData: [userMajorDataInput]
    $educationType: String
  ) {
    updateMentor(
      input: {
        id: $id
        intakeYear: $intakeYear
        graduateSchoolGradYear: $graduateSchoolGradYear
        major: $major
        degree: $degree
        CollegeId: $CollegeId
        graduateCollegeId: $graduateCollegeId
        switchThemeTo: $switchThemeTo
        userMajorData: $userMajorData
        educationType: $educationType
      }
    ) {
      id
      intakeYear
      graduateSchoolGradYear
      major
      degree
      Theme
      userMajors {
        id
        educationType
        degreeType
        MajorId
        majorName
      }
      graduateSchool {
        id
        name
        allMajors {
          id
          name
          otherMajor
          majorCategory {
            id
            name
            displayName
          }
        }
      }
      currentCollege {
        id
        name
        allMajors {
          id
          name
          otherMajor
          majorCategory {
            id
            name
            displayName
          }
        }
      }
      colleges {
        name
        id
        styleConfig {
          bgDark
          bgLight
        }
      }
    }
  }
`;

export const MAJOR_CATEGORY_QUERY = gql`
  query getMajorCategoriesQuery(
    $searchQuery: String
    $limit: Int
    $exclude: [Int]
  ) {
    getMajorCategories(
      searchQuery: $searchQuery
      limit: $limit
      exclude: $exclude
    ) {
      id
      name
    }
  }
`;

export const DELETE_USER_MAJOR_MUTATION = gql`
  mutation deleteUserMajorMutation($id: Int) {
    deleteUserMajor(id: $id) {
      success
      message
    }
  }
`;

export const CHANGE_USER_MAJOR_COLLEGE_MUTATION = gql`
  mutation majorschangeCollegeMutation(
    $userId: Int
    $collegeId: Int
    $educationType: String
  ) {
    majorschangeCollege(
      input: {
        userId: $userId
        collegeId: $collegeId
        educationType: $educationType
      }
    ) {
      success
      message
    }
  }
`;

const EditEducation = ({
  open,
  info,
  onClose,
  userCurrentCollege,
  setCurrentCollege,
  graduateSchool,
  setGraduateSchool,
}: {
  open: boolean;
  info: 'graduation' | 'underGraduation';
  onClose: () => void;
  userCurrentCollege: getCurrentCollege_currentCollege | null;
  setCurrentCollege:React.Dispatch<React.SetStateAction<getCurrentCollege_currentCollege | null>>
  graduateSchool: getGraduateSchool_graduateSchool | null;
  setGraduateSchool: React.Dispatch<React.SetStateAction<getGraduateSchool_graduateSchool | null>>
}) => {
  const classes = useStylesAccount();
  const { user, setUser, setIsCollegeUpdated, isCollegeUpdated } = useContext(AppContext);
  const [collegeMajors, setCollegeMajors] = useState<any>([
    {
      value: 0,
      label: 'Other',
      areaOfInterest: null,
    },
  ]);
  const [allAreaOfInterest, setAllAreaOfInterest] = useState<any>(null);
  const [collegeId, setCollegeId] = useState<null | number>(null);
  const [values, setValues] = useState<any>([]);

  const [toast, setToast] = useState<Tostcongigs>({
    message: '',
    type: undefined,
  });
  const [openToast, setOpenToast] = useState(false);

  const gradYearValidation = Yup.number()
    .typeError(`Enter a valid Grad Year`)
    .integer(`Enter a valid Grad Year`)
    .positive(`Enter a valid Grad Year`)
    .test(
      'maxDigits',
      'Grad Year must have exactly 4 digits',
      (number) => String(number).length === 4 || number === undefined
    )
    .min(1950, 'Grad Year should be greater than 1950')
    .nullable();

  const FormSchema = Yup.object().shape({
    userMajorData: Yup.array().of(
      Yup.object().shape({
        MajorId: Yup.number().when('isOtherMajor', {
          is: false,
          then: Yup.number().required('Major is required'),
          otherwise: Yup.number().nullable(),
        }),
        otherMajor: Yup.string().when('isOtherMajor', {
          is: true,
          then: Yup.string().required('Major is required'),
          otherwise: Yup.string(),
        }),
        otherMajorCategoryId: Yup.number().required(
          'Area of interest is required'
        ),
      })
    ),
    ...(info === 'graduation'
      ? {
          graduateSchool: Yup.object().required('College is Required'),
          graduateSchoolGradYear: gradYearValidation,
        }
      : {
          currentCollege: Yup.object().required('College is Required'),
          intakeYear: gradYearValidation,
        }),
  });

  const handleClose = (resetForm: Function) => {
    resetForm();
    onClose();
  };

  const mountEffect = async () => {
    try {
      const { data } = await client.query({
        query: MAJOR_CATEGORY_QUERY,
        variables: { limit: 100 },
        fetchPolicy: 'network-only',
      });
      const allMajorCategories = data?.getMajorCategories?.map(
        (item: { id: any; name: string }) => {
          return {
            value: item.id,
            label: item.name,
          };
        }
      );
      setAllAreaOfInterest(allMajorCategories);
    } catch (error) {
      console.log('Error:::', error);
    }
  };

  const deleteMajor = async (userMajorId: number) => {
    const result = await client.mutate({
      mutation: DELETE_USER_MAJOR_MUTATION,
      variables: {
        id: userMajorId,
      },
    });
    if (result.data.deleteUserMajor?.success) {
      const updatedUserMajors = user?.userMajors?.filter(
        (_userMajor) => _userMajor?.id !== userMajorId
      );

      if (user && updatedUserMajors) {
        setUser({ ...user, userMajors: updatedUserMajors });
      }
    }

    return result.data.deleteUserMajor?.success;
  };

  useEffect(() => {
    mountEffect();
  }, []);

  const getMajor = (majorId: any) => {
    const userCollege =
      info == 'graduation' ? graduateSchool : userCurrentCollege;

    return userCollege?.allMajors
      ? userCollege?.allMajors?.find((item) => item?.id == majorId)
      : null;
  };

  const groupMajorsByCategory = (data: any) => {
    const groupedOptions: {
      label: string;
      options: { value: number; label: string; areaOfInterest: any }[];
    }[] = [];

    data?.forEach((item: { majorCategory: any; id: number; name: string }) => {
      const category = item.majorCategory;
      const existingGroup = groupedOptions.find(
        (group) => group.label === category.name
      );

      if (existingGroup) {
        existingGroup.options.push({
          value: item.id,
          label: item.name,
          areaOfInterest: item?.majorCategory,
        });
      } else {
        groupedOptions.push({
          label: category.name,
          options: [
            {
              value: item.id,
              label: item.name,
              areaOfInterest: item?.majorCategory,
            },
          ],
        });
      }
    });

    groupedOptions.sort((a, b) => a.label.localeCompare(b.label));

    return groupedOptions;
  };

  useEffect(() => {
    const userCollege =
      info == 'graduation' ? graduateSchool : userCurrentCollege;
    const allMajors = groupMajorsByCategory(userCollege?.allMajors);
    allMajors?.unshift({
      label: '',
      options: [
        {
          value: 0,
          label: 'Other',
          areaOfInterest: {},
        },
      ],
    });
    setCollegeMajors(allMajors);

    const newArr =
      info === 'underGraduation'
        ? user?.userMajors?.filter(
            (major) => major?.educationType === 'underGraduation'
          ).length
          ? user?.userMajors
              ?.filter((major) => major?.educationType === 'underGraduation')
              .map((item) => {
                const currentMajor = getMajor(item?.MajorId);
                return {
                  MajorId: item?.MajorId,
                  degreeType: item?.MajorId ? item?.degreeType : '',
                  id: item?.id,
                  educationType: info,
                  otherMajor: currentMajor?.otherMajor
                    ? currentMajor?.name
                    : '',
                  otherMajorCategoryId: currentMajor?.majorCategory?.id,
                  isOtherMajor: currentMajor?.otherMajor ? true : false,
                };
              })
          : [
              {
                MajorId: null,
                degreeType: '',
                id: null,
                educationType: info,
                otherMajor: '',
                otherMajorCategoryId: null,
                isOtherMajor: false,
              },
            ]
        : user?.userMajors?.filter(
            (major) => major?.educationType === 'graduation'
          ).length
        ? user?.userMajors
            ?.filter((major) => major?.educationType === 'graduation')
            .map((item) => {
              const currentMajor = getMajor(item?.MajorId);
              return {
                MajorId: item?.MajorId,
                degreeType: item?.MajorId ? item?.degreeType : '',
                id: item?.id,
                educationType: info,
                otherMajor: currentMajor?.otherMajor ? currentMajor?.name : '',
                otherMajorCategoryId: currentMajor?.majorCategory?.id,
                isOtherMajor: true,
              };
            })
        : [
            {
              MajorId: null,
              degreeType: '',
              id: null,
              educationType: info,
              otherMajor: '',
              otherMajorCategoryId: null,
              isOtherMajor: true,
            },
          ];

    setValues(newArr);
  }, [user, info]);

  const customStyles = {
    option: (provided: any) => ({
      ...provided,
      fontWeight: 'normal',
    }),
    groupHeading: (provided: any) => ({
      ...provided,
      fontWeight: 600,
      color: '#272929',
    }),
  };

  return (
    <>
      <Toast
        open={openToast}
        close={() => {
          setOpenToast(false);
        }}
        {...toast}
      />
      <Mutation<UpdateMentorEducation, UpdateMentorEducationVariables>
        mutation={UPDATE_MENTOR_EDUCATION}
      >
        {(updateMentor: Function) => {
          return (
            <Formik
              initialValues={{
                id: user?.id,
                ...(info === 'underGraduation' && {
                  currentCollege: userCurrentCollege,
                  intakeYear: user?.intakeYear,
                  major: user?.major,
                  ...(!user?.Theme && {
                    switchThemeTo: 'underGraduation',
                  }),
                }),
                ...(info === 'graduation' && {
                  degree: user?.degree,
                  graduateSchool: graduateSchool,
                  graduateSchoolGradYear: user?.graduateSchoolGradYear,
                  ...(!user?.Theme && {
                    switchThemeTo: 'graduation',
                  }),
                }),
                CollegeId: userCurrentCollege?.id,
                graduateCollegeId: graduateSchool?.id,
                userMajorData: values,
              }}
              enableReinitialize
              validationSchema={FormSchema}
              onSubmit={async (variables, { setSubmitting }) => {
                try {
                  const { data } = await updateMentor({
                    variables: {
                      ...variables,
                    },
                  });

                  setUser({
                    ...user,
                    ...data?.updateMentor,
                  });
                  setCurrentCollege(data?.updateMentor?.currentCollege);
                  setGraduateSchool(data?.updateMentor?.graduateSchool);
                  setIsCollegeUpdated(!isCollegeUpdated);
                  setToast({
                    message: 'User Updated!',
                    type: 'success',
                  });
                } catch (error: any) {
                  let errorMessage = 'Changes not saved!';

                  if (error?.message) {
                    handleMutationError(error, ({ message }) => {
                      if (message) errorMessage = message;
                    });
                  }
                  setToast({
                    message: errorMessage,
                    type: 'error',
                  });
                } finally {
                  setSubmitting(false);
                  setOpenToast(true);
                  onClose();
                }
              }}
            >
              {({
                isSubmitting,
                setFieldValue,
                handleSubmit,
                values,
                errors,
                resetForm,
                dirty,
              }) => {
                const isError = !!Object.keys(errors).length;
                const currentCollege =
                  info === 'graduation'
                    ? values.graduateSchool
                    : values.currentCollege;

                return (
                  <Form>
                    <Dialog
                      open={open}
                      onClose={() => handleClose(resetForm)}
                      className={clsx(
                        classes.educationDialog,
                        classes.undergraduateEducation
                      )}
                    >
                      <DialogContent className="undergraduateEducationDialog">
                        <Box className="dialogHeader">
                          <Typography variant="h4">
                            {info === 'graduation'
                              ? 'Graduate Education'
                              : 'Undergraduate Education'}{' '}
                          </Typography>
                        </Box>

                        <Box className="dialogScroll">
                          <Grid
                            container
                            spacing={2}
                            className={classes.accountInformationForm}
                          >
                            <Grid item lg={8} md={8} xs={12}>
                              <Box>
                                <Typography
                                  variant="body1"
                                  className="textFieldCaption"
                                >
                                  College
                                </Typography>

                                <Box className="genderDropdown timeZoneSelect acceptedToDropdown fieldsBoxSpacing">
                                  <SearchColleges
                                    label=""
                                    isMulti={false}
                                    values={
                                      currentCollege
                                        ? {
                                            label: currentCollege?.name,
                                            value: currentCollege?.id,
                                          }
                                        : null
                                    }
                                    onSelect={(college: OptionType) => {
                                      const allMajors = groupMajorsByCategory(
                                        college?.allMajors
                                      );

                                      allMajors?.unshift({
                                        label: '',
                                        options: [
                                          {
                                            value: 0,
                                            label: 'Other',
                                            areaOfInterest: {},
                                          },
                                        ],
                                      });
                                      setCollegeMajors(allMajors);
                                      setFieldValue(
                                        info === 'graduation'
                                          ? 'graduateSchool'
                                          : 'currentCollege',
                                        {
                                          id: parseInt(college.value),
                                          name: college.label,
                                        }
                                      );
                                      setFieldValue(
                                        info === 'graduation'
                                          ? 'graduateCollegeId'
                                          : 'CollegeId',
                                        parseInt(college.value)
                                      );

                                      for (
                                        let index = 0;
                                        index < values.userMajorData.length;
                                        index++
                                      ) {
                                        setFieldValue(
                                          `userMajorData.${index}.MajorId`,
                                          null
                                        );
                                        setFieldValue(
                                          `userMajorData.${index}.otherMajorCategoryId`,
                                          null
                                        );
                                        setFieldValue(
                                          `userMajorData.${index}.otherMajor`,
                                          ''
                                        );
                                      }
                                      setCollegeId(parseInt(college.value));
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item lg={4} md={4} xs={12}>
                              <Box className="styleTextField fieldsBoxSpacing gradYear">
                                <Typography
                                  variant="body1"
                                  className="textFieldCaption"
                                >
                                  Grad Year (Optional)
                                </Typography>

                                <Field
                                  name={
                                    info === 'graduation'
                                      ? 'graduateSchoolGradYear'
                                      : 'intakeYear'
                                  }
                                  component={TextField}
                                  label=""
                                  placeholder="YYYY"
                                />
                              </Box>
                            </Grid>

                            <FieldArray name="userMajorData">
                              {(arrayHelpers) => (
                                <Box width="100%">
                                  {values.userMajorData?.map(
                                    (item: any, index: number) => (
                                      <>
                                        <Grid
                                          container
                                          spacing={2}
                                          style={{ margin: 0 }}
                                        >
                                          <Grid
                                            item
                                            lg={3}
                                            md={3}
                                            xs={12}
                                            style={{ paddingBottom: 0 }}
                                          >
                                            <Box className="styleTextField fieldsBoxSpacing styleTextFieldDialog">
                                              <Typography
                                                variant="body1"
                                                className="textFieldCaption textFieldCaptionDialog"
                                              >
                                                Degree Type
                                              </Typography>
                                              <Field
                                                name={`userMajorData.${index}.degreeType`}
                                                component={TextField}
                                                label=""
                                                placeholder="Degree Type"
                                              />
                                              <Typography
                                                variant="body2"
                                                className="textFieldSubtitleDialog"
                                              >
                                                “B.S.”, “B.A”, etc.
                                              </Typography>
                                            </Box>
                                          </Grid>

                                          <Grid
                                            item
                                            lg={8}
                                            md={8}
                                            xs={9}
                                            style={{ paddingBottom: 0 }}
                                          >
                                            <Box
                                              className={`${classes.advisorSelectDropDown} styleTextField fieldsBoxSpacing styleTextFieldDialog`}
                                            >
                                              <Typography
                                                variant="body1"
                                                className="textFieldCaption textFieldCaptionDialog"
                                              >
                                                Major
                                              </Typography>

                                              <Select
                                                menuPlacement="top"
                                                styles={customStyles}
                                                isDisabled={
                                                  !currentCollege?.id ||
                                                  info === 'graduation'
                                                }
                                                placeholder="Select"
                                                searchable={true}
                                                options={collegeMajors}
                                                value={
                                                  !values.userMajorData[index]
                                                    .isOtherMajor
                                                    ? collegeMajors.flatMap(
                                                        (group: {
                                                          options: any[];
                                                        }) =>
                                                          group.options.find(
                                                            (option: {
                                                              value: any;
                                                            }) =>
                                                              values?.userMajorData &&
                                                              values
                                                                ?.userMajorData[
                                                                index
                                                              ]?.MajorId ==
                                                                option.value
                                                          )
                                                      )
                                                    : collegeMajors[0]
                                                        ?.options[0]
                                                }
                                                name="colors"
                                                classNamePrefix={'react-select'}
                                                className="react-select-container"
                                                onChange={(value: any) => {
                                                  if (!value?.value) {
                                                    setFieldValue(
                                                      `userMajorData.${index}.isOtherMajor`,
                                                      true
                                                    );
                                                    setFieldValue(
                                                      `userMajorData.${index}.MajorId`,
                                                      null
                                                    );
                                                  } else {
                                                    setFieldValue(
                                                      `userMajorData.${index}.MajorId`,
                                                      value?.value
                                                    );
                                                    setFieldValue(
                                                      `userMajorData.${index}.isOtherMajor`,
                                                      false
                                                    );
                                                    setFieldValue(
                                                      `userMajorData.${index}.otherMajor`,
                                                      ''
                                                    );
                                                    setFieldValue(
                                                      `userMajorData.${index}.otherMajorCategoryId`,
                                                      value?.areaOfInterest?.id
                                                    );
                                                  }
                                                }}
                                              ></Select>
                                            </Box>
                                          </Grid>

                                          <Grid
                                            item
                                            lg={1}
                                            md={1}
                                            xs={3}
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              paddingBottom: 0,
                                            }}
                                          >
                                            {values.userMajorData &&
                                              values.userMajorData?.length >=
                                                2 && (
                                                <IconButton
                                                  onClick={async () => {
                                                    arrayHelpers.remove(index);
                                                    if (item?.id) {
                                                      await deleteMajor(
                                                        item?.id
                                                      );
                                                    }
                                                  }}
                                                  className="deleteButton"
                                                >
                                                  <img
                                                    src={DeleteIcon}
                                                    alt="delete"
                                                  />
                                                </IconButton>
                                              )}
                                          </Grid>

                                          {values.userMajorData[index]
                                            .isOtherMajor && (
                                            <Grid
                                              item
                                              lg={3}
                                              md={3}
                                              xs={12}
                                              style={{ padding: 0 }}
                                            ></Grid>
                                          )}

                                          {values.userMajorData[index]
                                            .isOtherMajor && (
                                            <Grid
                                              item
                                              lg={8}
                                              md={8}
                                              xs={12}
                                              style={{ paddingTop: 0 }}
                                            >
                                              <Box className="styleTextField fieldsBoxSpacing styleTextFieldDialog">
                                                <Typography
                                                  variant="body1"
                                                  className="textFieldCaption textFieldCaptionDialog"
                                                >
                                                  Name
                                                </Typography>
                                                <Field
                                                  name={`userMajorData.${index}.otherMajor`}
                                                  component={TextField}
                                                  label=""
                                                  placeholder="Major"
                                                />
                                              </Box>
                                            </Grid>
                                          )}

                                          {values.userMajorData[index]
                                            .isOtherMajor && (
                                            <Grid
                                              item
                                              lg={1}
                                              md={1}
                                              xs={12}
                                              style={{ padding: 0 }}
                                            ></Grid>
                                          )}

                                          <Grid
                                            item
                                            lg={3}
                                            md={3}
                                            xs={12}
                                            style={{ padding: 0 }}
                                          ></Grid>

                                          <Grid item lg={7} md={7} xs={12}>
                                            <Box
                                              className={`${classes.advisorSelectDropDown} styleTextField fieldsBoxSpacing styleTextFieldDialog`}
                                            >
                                              <Typography
                                                variant="body1"
                                                className="textFieldCaption textFieldCaptionDialog"
                                              >
                                                Area of Interest
                                              </Typography>

                                              <FormControl fullWidth={true}>
                                                <Select
                                                  menuPlacement="top"
                                                  placeholder="Select"
                                                  displayEmpty
                                                  options={allAreaOfInterest}
                                                  isDisabled={
                                                    !values.userMajorData[index]
                                                      .isOtherMajor
                                                  }
                                                  value={
                                                    values.userMajorData[index]
                                                      .otherMajorCategoryId
                                                      ? allAreaOfInterest.find(
                                                          (item: {
                                                            value: number;
                                                          }) =>
                                                            values.userMajorData &&
                                                            values
                                                              .userMajorData[
                                                              index
                                                            ]
                                                              .otherMajorCategoryId ==
                                                              item?.value
                                                        )
                                                      : null
                                                  }
                                                  onChange={(value) => {
                                                    setFieldValue(
                                                      `userMajorData.${index}.otherMajorCategoryId`,
                                                      value?.value
                                                    );
                                                  }}
                                                  classNamePrefix={
                                                    'react-select'
                                                  }
                                                  className="react-select-container"
                                                ></Select>
                                              </FormControl>
                                            </Box>
                                          </Grid>

                                          <Grid
                                            item
                                            lg={3}
                                            xs={12}
                                            style={{ padding: 0 }}
                                          ></Grid>
                                        </Grid>

                                        <>
                                          <Divider className="sectionDivider" />

                                          {values?.userMajorData &&
                                            values?.userMajorData?.length <
                                              2 && (
                                              <Button
                                                onClick={() => {
                                                  arrayHelpers.push({
                                                    educationType: info,
                                                    MajorId: null,
                                                    degreeType: '',
                                                    otherMajor: '',
                                                    otherMajorCategoryId: null,
                                                    isOtherMajor:
                                                      info === 'graduation'
                                                        ? true
                                                        : false,
                                                  });
                                                }}
                                                color="primary"
                                                variant="text"
                                              >
                                                <img src={AddIcon} alt="add" />
                                                {`Add New ${
                                                  info === 'underGraduation'
                                                    ? 'Undergraduate'
                                                    : 'Graduate'
                                                }
                                                Program`.toUpperCase()}
                                              </Button>
                                            )}
                                        </>
                                      </>
                                    )
                                  )}
                                </Box>
                              )}
                            </FieldArray>
                          </Grid>
                        </Box>

                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                          className="dialogAction"
                        >
                          <Button
                            variant="outlined"
                            onClick={() => handleClose(resetForm)}
                          >
                            Cancel
                          </Button>

                          <Button
                            variant="contained"
                            disabled={isSubmitting || isError || !dirty}
                            onClick={() => handleSubmit()}
                          >
                            {isSubmitting ? 'Saving...' : 'Save'}
                          </Button>
                        </Box>
                      </DialogContent>
                    </Dialog>
                  </Form>
                );
              }}
            </Formik>
          );
        }}
      </Mutation>
    </>
  );
};

export default EditEducation;
