import gql from 'graphql-tag';

export const GET_PERSONAL_EMAIL_CALENDARS = gql`
  query fetchPersonalEmailCalendarsQuery($email: String!) {
    fetchPersonalEmailCalendars(input: { email: $email }) {
      calendarList {
        name
        calendarId
      }
      emailAddress
    }
  }
`;

export const SET_PERSONAL_AUTH_TOKEN = gql`
  mutation setNylasPersonalAuthToken($code: String!) {
    setNylasPersonalCalendarAuthToken(input: { code: $code }) {
      success
      message
      calendarList {
        name
        calendarId
        isPrimary
      }
      emailAddress
    }
  }
`;
export interface emailCalendars {
  calendarId: String;
  calendarName: String;
  email_address: String;
  isChecked: Boolean;
}

export const SYNC_EMAIL_CALENDAR = gql`
  mutation syncEmailCalendarIdsMutation($calendars: [emailCalendars]) {
    syncEmailCalendarIds(input: { calendars: $calendars }) {
      success
      message
    }
  }
`;

export const GET_SYNCED_PERSONAL_CALENARS = gql`
  query fetchSyncedPersonalEmailCalendarsQuery {
    fetchSyncedPersonalEmailCalendars(input: {}) {
      connectedAcounts {
        email
        provider
        connectedCalendars {
          calendarId
          calendarName
          isPrimary
        }
        nonConnectedCalendars {
          calendarId
          calendarName
          isPrimary
        }
      }
    }
  }
`;

export const DELETE_PERSONAL_ACCOUNT = gql`
  mutation DeletePersonalAccountMutation($email_address: String) {
    DeletePersonalAccount(input: { email_address: $email_address }) {
      success
      message
    }
  }
`;

export const SET_AUTH_TOKEN = gql`
  mutation setNylasAuthToken($code: String!) {
    setNylasAuthToken(input: { code: $code }) {
      success
      message
    }
  }
`;