import gql from 'graphql-tag';

export const EXCLUDE_STUDENT_FROM_WEEKLY_REPORT = gql`
  mutation excludeStudentFromWeeklyAdvisorReportMutation(
    $AdvisorId: Int
    $StudentId: Int!
    $value: Boolean!
  ) {
    excludeStudentFromWeeklyAdvisorReport(
      input: { AdvisorId: $AdvisorId, StudentId: $StudentId, value: $value }
    ) {
      StudentId
      AdvisorId
      excludeFromWeeklyAdvisorReport
    }
  }
`;

export const CREATE_APPLICATION_ACCEPTANCE_SURVEY = gql`
  mutation createApplicationSurveyMutation(
    $id: Int!
    $decision: [Decisions]
    $decisionCampuses: [DecisionsCampuses]
    $autoSaveAt: Date
  ) {
    createApplicationSurvey(
      input: {
        id: $id
        decision: $decision
        decisionCampuses: $decisionCampuses
        autoSaveAt: $autoSaveAt
      }
    ) {
      success
      message
    }
  }
`;

export const DELETE_APP_MUTATION = gql`
  mutation DeleteAppMutation($id: Int!, $studentId: Int) {
    deleteStudentApplication(input: { id: $id, studentId: $studentId }) {
      status
    }
  }
`;

export const UPDATE_APPLICATION_DECISION_MUTATION = gql`
  mutation UpdateApplicationDecisionMutation(
    $studentApplicationId: Int!
    $decisionStatus: DecisionStatus!
    $decisionDeadline: Date
    $studentId: Int
  ) {
    updateApplicationDecisionStatus(
      input: {
        studentApplicationId: $studentApplicationId
        decisionStatus: $decisionStatus
        decisionDeadline: $decisionDeadline
        studentId: $studentId
      }
    ) {
      status
    }
  }
`;
