import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Box, Typography, IconButton } from '@material-ui/core';
import { useState } from 'react';
import EditTagsDialog from './EditTagsDialog';

import EDIT_PENCIL_ICON from '../../../img/pencil-edit.svg';

import {
  getAdvisorTagsQuery,
  getAdvisorTagsQueryVariables,
  getAdvisorTagsQuery_getAdvisorTags_tags,
} from './__generated__/getAdvisorTagsQuery';

const ADVISOR_TAGS = gql`
  query getAdvisorTagsQuery($userId: Int) {
    getAdvisorTags(input: { userId: $userId }) {
      tags {
        name
        id
      }
    }
  }
`;

const AdvisorSpecialities = () => {
  const [openTagsDialog, setOpenTagsDialog] = useState(false);
  const [refetchTags, setRefetchTags] = useState<Function | undefined>();
  const [tags, setTags] = useState<
    (getAdvisorTagsQuery_getAdvisorTags_tags | null)[]
  >([]);

  const handleOpenTagsDialog = () => {
    setOpenTagsDialog(true);
  };

  const handleCloseTagsDilaog = () => {
    setOpenTagsDialog(false);
  };
  return (
    <>
      <EditTagsDialog
        open={openTagsDialog}
        onClose={handleCloseTagsDilaog}
        tags={tags}
        refetch={refetchTags}
      />

      <Box display="flex" alignItems="center" className="headingSpacing">
        <Typography variant="h6" className="educationHeading">
          Specialties
        </Typography>

        <IconButton className="editButton" onClick={handleOpenTagsDialog}>
          <img src={EDIT_PENCIL_ICON} alt="edit" />
        </IconButton>
      </Box>

      <Query<getAdvisorTagsQuery, getAdvisorTagsQueryVariables>
        query={ADVISOR_TAGS}
        fetchPolicy="network-only"
        onCompleted={(data) => {
          setTags(data.getAdvisorTags?.tags || []);
        }}
      >
        {({ data, loading, refetch }) => {
          if (!refetchTags) setRefetchTags(() => () => refetch());
          if (loading) return <>Loading...</>;
          if (!data?.getAdvisorTags?.tags.length) return <>--</>;
          return (
            <Box className="tagsWrapper">
              {data.getAdvisorTags.tags.map((item) => (
                <Box key={item?.name}>
                  <Box
                    className="tagsBadge"
                    style={{
                      backgroundColor: '#2F80ED',
                    }}
                  >
                    {item?.name}
                  </Box>
                </Box>
              ))}
            </Box>
          );
        }}
      </Query>
    </>
  );
};

export default AdvisorSpecialities;
