import { FC, useEffect } from 'react';
import { Dialog, Box, Typography, Button } from '@material-ui/core';

import AsyncSelect from 'react-select/async';
import { useStylesAccount } from '../account/accountStyle';
import momentTz from 'moment-timezone';
import { canceledReason, loadReasonOptions } from '../../../common/utility';
import { ReasonProps } from '../../../common/interfaces';
import OtherReason from './OtherReason';

const AddReasonsModel: FC<ReasonProps> = ({
  title,
  firstButtonText,
  secondButtonText,
  open,
  onClose,
  loading,
  handleAddReasonBtn,
  startTime,
  startDate,
  student,
  reasonValue,
  isEdit,
  reason,
  setReason,
  setOtherReason,
  otherReason,
}) => {
  const classes = useStylesAccount();
  const handleInputChange = (selectedValue: any) => {
    setReason(selectedValue.value);
  };

  const { firstName, lastName } = student;
  useEffect(() => {
    if (isEdit) {
      setReason(canceledReason(reasonValue)?.value || reason);
    }
  }, [isEdit]);

  const regex = new RegExp('other')
  const isOther = regex.test(reason);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      className={`${classes.confirmationDialog}  ${classes.muiPaperOverflowY}`}
    >
      <Box>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="body1" className={classes.meetTime}>
          {`${firstName} ${lastName} - ${startDate} - ${startTime}`}{' '}
          {momentTz(startDate).tz(momentTz.tz.guess()).format('z')}
        </Typography>

        <Box className={classes.accountInformationForm} pt="21px">
          <Box className={classes.advisorSelectDropDown} pb="22px">
            <AsyncSelect
              cacheOptions
              placeholder="Select Cancelation Reason"
              defaultOptions
              defaultValue={canceledReason(reasonValue||'')}
              loadOptions={loadReasonOptions}
              isClearable={true}
              isSearchable={true}
              isDisabled={isEdit && isOther}
              classes={classes}
              onChange={handleInputChange}
              classNamePrefix={'react-select'}
              className="react-select-container"
            />
          </Box>
        </Box>
        {reason === 'other'  ?
          <OtherReason title='Note' setOtherReason={setOtherReason} otherReason={otherReason} />
          : ''}
        <Box
          display="flex"
          justifyContent="flex-end"
          className={classes.addReasonModel}
        >
          <Button variant="outlined" color="secondary" onClick={onClose}>
            {firstButtonText}
          </Button>

          {secondButtonText && (
            <Button
              onClick={() => handleAddReasonBtn && handleAddReasonBtn(reason, otherReason)}
              variant="contained"
              color="primary"
              disabled={loading}
              className="dangerButton"
            >
              {loading
                ? secondButtonText === 'Save'
                  ? `saving...`
                  : ''
                : secondButtonText}
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default AddReasonsModel;
