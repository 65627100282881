import gql from 'graphql-tag';

export const ALL_MENTOR_TIME_LOGS = gql`
  query allMentorTimeLogsQuery(
    $searchQuery: String
    $MentorId: Int
    $limit: Int
    $page: Int
    $sortBy: String
    $sortType: String
    $portal: PortalType
  ) {
    allMentorTimeLogs(
      input: {
        searchQuery: $searchQuery
        MentorId: $MentorId
        page: $page
        limit: $limit
        sortBy: $sortBy
        sortType: $sortType
        portal: $portal
      }
    ) {
      mentorTimeLogs {
        id
        activityAt
        activityDescription
        workType
        note
        activityDuration
        MentorPayoutId
        workActivity
        amount
        airtableSubmitted
        durationNote
        missCall
        isCommonApp
        isCoalitionApp
        isOtherStrategy
        isFullTime
        approvedDate
        createdAt
        MentorTimeLogAssociations {
          id
          advisorBookingId
          otherMeetingDescription
          noteForExtendedMeeting
          noteForNoOrientationCall
          milestoneTeamName
          allAssociatedAdvisors {
            firstName
            lastName
          }
          studentApplications {
            id
            applicationRequirement {
              id
              college {
                id
                name
                ParentCollegeId
              }
            }
          }
        }
        premiumEntitlement {
          admissionPackageElement {
            packageElement {
              name
            }
            admissionPackage {
              name
            }
          }
        }
        mentorPaymentCategory {
          id
          rate
        }
        student {
          id
          firstName
          lastName
          initialKickOffDate
          email
        }
        mentorPayout {
          status
          amount
          arrivalDate
        }
        mentor {
          id
          firstName
          lastName
          stripeAccountId
        }
        onBoarding {
          onBoarded
        }
        milestone
        serviceElement
        isProjectWork
      }
      nextRecord
      mentorTimeLogsCount
    }
  }
`;

export const MENTOR_PAYMENT_IN_TRANSIT = gql`
  query advisorInTransitPaymentQuery {
    advisorInTransitPayment {
      InTransit
    }
  }
`;
