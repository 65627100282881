import { useState } from 'react';
import Select from 'react-select';

import {
  Box,
  Grid,
  IconButton,
  InputBase,
  Typography,
  Button,
  Dialog,
} from '@material-ui/core';

import { useStyles } from '../../timeLogs/StudentFeedbackDialog/styleStudentFeedbackDialog';
import { useStylesAccount } from '../../account/accountStyle';

import CloseIcon from '../../../../img/close-dialog-updated.svg';
import { GET_STUDENT_TRIAL, UPDATE_USER_TRIAL_MUTATION } from '../gql';
import client from '../../../../apollo';
import { topicsDiscussedInMeeting } from '../../../../constants';
import { GetStudentTrialQuery_getStudentTrial } from '../gql/__generated__/GetStudentTrialQuery';

const StudentFeedbackDialog = ({
  open,
  setOpen,
  userTrialData,
  setFeedBackNotSubmitted,
  studentId,
  setUserTrialData,
}: {
  open: boolean;
  setOpen: Function;
  userTrialData: GetStudentTrialQuery_getStudentTrial;
  setFeedBackNotSubmitted: Function;
  studentId: number | null;
  setUserTrialData: Function;
}) => {
  const dialogClasses = useStyles();
  const classes = useStylesAccount();

  const [isParentAttendedMeeting, setIsParentAttendedMeeting] = useState({
    label: userTrialData?.studentTrial?.isParentAttendedMeeting,
    value: userTrialData?.studentTrial?.isParentAttendedMeeting,
  });
  const [studentAreasOfInterest, setStudentAreasOfInterest] = useState(
    userTrialData?.studentTrial?.studentAreasOfInterest?.map((topic) => ({
      value: topic,
      label: topic,
    }))
  );
  const [topicsCoverdInMeeting, setTopicsCoverdInMeeting] = useState(
    userTrialData?.studentTrial?.topicsCoverdInMeeting?.map((topic) => ({
      value: topic,
      label: topic,
    }))
  );
  const [adviceForStudent, setAdviceForStudent] = useState(
    userTrialData?.studentTrial?.adviceForStudent
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const getStudentTrial = async () => {
    try {
      const response = await client.query({
        query: GET_STUDENT_TRIAL,
        variables: { studentId },
        fetchPolicy: 'no-cache',
      });
      if (response.data.getStudentTrial?.studentTrial?.trialStartedAt) {
        setUserTrialData(response.data.getStudentTrial);
      } else {
        setUserTrialData(null);
      }
    } catch (error: any) {
      console.log('error:::', error.message);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const variables = {
        trialId: userTrialData?.studentTrial?.id,
        studentId: userTrialData?.student?.id,
        isParentAttendedMeeting: isParentAttendedMeeting.value,
        studentAreasOfInterest: studentAreasOfInterest?.map(
          (topic) => topic.value
        ),
        topicsCoverdInMeeting: topicsCoverdInMeeting?.map(
          (topic) => topic.value
        ),
        adviceForStudent: adviceForStudent,
      };
      const mutation = UPDATE_USER_TRIAL_MUTATION;
      await client.mutate({
        mutation,
        variables,
      });
      getStudentTrial();
      setFeedBackNotSubmitted(false);
      setIsSubmitting(false);
      handleClose();
    } catch (er) {
      console.log('error', er);
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={`${dialogClasses.feedbackDialog} feedbackDialogScroll`}
      disableBackdropClick
    >
      <Box className="customizeScroll">
        <Box
          mb={3.125}
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="h4">Student Feedback</Typography>
            <Typography variant="body1">
              Leadership Initiatives Meeting
            </Typography>
          </Box>

          <IconButton onClick={handleClose}>
            <img src={CloseIcon} alt="close" />
          </IconButton>
        </Box>

        <Box className="dialogCOntent">
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Typography
                variant="body2"
                className="inputCaption inputCaptionOpacity"
              >
                Student First Name
              </Typography>

              <InputBase
                placeholder=""
                value={userTrialData?.student?.firstName}
                disabled
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <Typography
                variant="body2"
                className="inputCaption inputCaptionOpacity"
              >
                Student Last Name
              </Typography>

              <InputBase
                placeholder=""
                value={userTrialData?.student?.lastName}
                disabled
              />
            </Grid>

            <Grid item md={9} xs={12}>
              <Typography
                variant="body2"
                className="inputCaption inputCaptionOpacity"
              >
                Student Email
              </Typography>

              <InputBase
                disabled
                placeholder=""
                value={userTrialData?.student?.email}
              />
            </Grid>

            <Grid item md={3} xs={12}>
              <Typography
                variant="body2"
                className="inputCaption inputCaptionOpacity"
              >
                Grad Year
              </Typography>

              <InputBase
                placeholder=""
                value={userTrialData?.student?.highSchoolYear}
                disabled
              />
            </Grid>

            <Box height={108}></Box>
          </Grid>

          <Grid className={classes.accountInformationForm}>
            <Typography variant="body2" className="inputCaption">
              What topics did you cover with the student?
            </Typography>
            <Box className="genderDropdown timeZoneSelect acceptedToDropdown fieldsBoxSpacing">
              <Select
                value={topicsCoverdInMeeting}
                isMulti={true}
                name="colors"
                options={topicsDiscussedInMeeting}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(value) => {
                  const topics = value.map((item) => item);
                  setTopicsCoverdInMeeting(topics);
                }}
              />
            </Box>
          </Grid>

          <Grid className={classes.accountInformationForm}>
            <Typography variant="body2" className="inputCaption">
              What are three areas the student should learn more about?
            </Typography>
            <Typography variant="body2" className="inputCaptionLight">
              Select (3) items
            </Typography>

            <Box className="genderDropdown timeZoneSelect acceptedToDropdown fieldsBoxSpacing">
              <Select
                value={studentAreasOfInterest}
                isMulti={true}
                name="colors"
                options={topicsDiscussedInMeeting}
                className="basic-multi-select"
                isOptionDisabled={() =>
                  studentAreasOfInterest?.map((topic) => topic?.value) &&
                  studentAreasOfInterest?.map((topic) => topic?.value)
                    ?.length >= 3
                    ? true
                    : false
                }
                classNamePrefix="select"
                onChange={(value) => {
                  const subjects = value.map((item) => item);
                  setStudentAreasOfInterest(subjects);
                }}
              />
            </Box>
          </Grid>

          <Grid>
            <Typography variant="body2" className="inputCaption">
              Advice for Student
            </Typography>
            <Typography variant="body2" className="inputCaptionLight">
              Please write two paragraphs of advice for the student on the
              topics that you discussed.
            </Typography>

            <InputBase
              multiline
              rowsMax={4}
              rows={4}
              fullWidth
              value={adviceForStudent}
              onChange={(e) => setAdviceForStudent(e.target.value)}
            />
          </Grid>

          <Box display="flex" justifyContent="flex-start" mt={4}>
            <Button
              variant="contained"
              color="secondary"
              disabled={
                isSubmitting ||
                !!!userTrialData ||
                !!!topicsCoverdInMeeting?.length ||
                !!!studentAreasOfInterest?.length ||
                !!!adviceForStudent
              }
              onClick={handleSubmit}
              style={
                isSubmitting ||
                !!!userTrialData ||
                !!!topicsCoverdInMeeting?.length ||
                !!!studentAreasOfInterest?.length ||
                !!!adviceForStudent
                  ? {
                      backgroundColor: '#dddddd',
                      color: '#999999',
                    }
                  : { color: '#fff' }
              }
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default StudentFeedbackDialog;
