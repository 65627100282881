import { FC } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';

import {
  Grid,
  Container,
  Typography,
  Card,
  Box,
  Button,
} from '@material-ui/core';

import {
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables,
} from './__generated__/UpdatePasswordMutation';

import { TextField } from '../../../components/fields';
import Header from '../../../components/layout/Header';
import AccountMenu from '../../../components/AccountMenu';
import FooterNew from '../../../components/layout/FooterNew';

import { useStylesAccount } from './accountStyle';

const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePasswordMutation(
    $oldPassword: String!
    $newPassword: String!
  ) {
    updatePassword(
      input: { oldPassword: $oldPassword, newPassword: $newPassword }
    ) {
      status
      message
    }
  }
`;

const ChangePasswordPage: FC = () => {
  const initialValues = {
    newPassword: '',
    oldPassword: '',
  };
  const FormSchema = Yup.object().shape({
    newPassword: Yup.string().required('Required'),
    oldPassword: Yup.string().required('Required'),
  });

  const classes = useStylesAccount();

  return (
    <>
      <Header />

      <Container maxWidth={false} className="accountContainer">
        <Typography variant="h3" className="accountHeader">
          My Account
        </Typography>

        <Grid container spacing={2}>
          <Grid item md={3} sm={12} xs={12}>
            <AccountMenu />
          </Grid>

          <Grid item md={9} sm={12} xs={12}>
            <Card className={classes.accountInfoCard}>
              <Box className="infoCardHeader">
                <Typography variant="h5">Login & Password</Typography>
              </Box>

              <Mutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>
                mutation={UPDATE_PASSWORD_MUTATION}
              >
                {(updatePasswordMutation: Function) => {
                  return (
                    <Formik
                      initialValues={initialValues}
                      validationSchema={FormSchema}
                      onSubmit={async (variables, { setSubmitting }) => {
                        try {
                          const result = await updatePasswordMutation({
                            variables,
                          });
                          const {
                            data: {
                              updatePassword: { status, message },
                            },
                          } = result;
                          console.log('status: ', status);

                          if (message) alert(message);
                        } catch (error) {
                          alert('Something went wrong, Please try again');
                          console.log('error: ', error);
                        }
                        setSubmitting(false);
                      }}
                    >
                      {({ isSubmitting }) => (
                        <Form className={classes.accountInformationForm}>
                          <Grid className="accountInfoBody">
                            <Box className="fieldsBoxSpacing styleTextField emailField">
                              <Typography
                                variant="body1"
                                className="textFieldCaption"
                              >
                                Old Password
                              </Typography>
                              <Field
                                type="password"
                                name="oldPassword"
                                component={TextField}
                                label=""
                              />
                            </Box>

                            <Box className="fieldsBoxSpacing styleTextField emailField">
                              <Typography
                                variant="body1"
                                className="textFieldCaption"
                              >
                                New Password
                              </Typography>
                              <Field
                                type="password"
                                name="newPassword"
                                component={TextField}
                                label=""
                              />
                              <Typography variant="body2" className="infoText">
                                Must be at least 6 characters
                              </Typography>
                            </Box>

                            <Button
                              disabled={isSubmitting}
                              type="submit"
                              variant="contained"
                              className="saveChanges"
                            >
                              Save Changes
                            </Button>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  );
                }}
              </Mutation>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <FooterNew />
    </>
  );
};

export default ChangePasswordPage;
