import { FC } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';

import {
  StudentApplicationDetailNewQuery_StudentApplication_applicationRequirement_college_Campuses,
  StudentApplicationDetailNewQuery_StudentApplication_studentApplicationCampuses,
} from './graphql/queries/applicationsPage/__generated__/StudentApplicationDetailNewQuery';

interface CampusCheckBoxProps {
  Campuses: (StudentApplicationDetailNewQuery_StudentApplication_applicationRequirement_college_Campuses | null)[];
  ApplicationCreatedForCampuses:
    | (StudentApplicationDetailNewQuery_StudentApplication_studentApplicationCampuses | null)[]
    | null;
}
const CampusesCheckBoxes: FC<CampusCheckBoxProps> = ({
  Campuses,
  ApplicationCreatedForCampuses,
}) => {
  return (
    <>
      <FormGroup row>
        {Campuses?.map((campus: any) => (
          <>
            <FormControlLabel
              disabled
              control={
                <Checkbox
                  checked={
                    Boolean(
                      ApplicationCreatedForCampuses?.find(
                        (item: any) => item.id === campus.id
                      )
                    ) || false
                  }
                  name={campus.name}
                />
              }
              label={campus?.name.split('-')[1]}
            />
          </>
        ))}
      </FormGroup>
    </>
  );
};

export default CampusesCheckBoxes;
