import {
  Card,
  makeStyles,
  createStyles,
  Theme,
  Box,
  Typography,
  Grid,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import fonts from '../../../common/fonts';
import { StudentDetailQuery_Student_premiumOfflineCountUsed } from './__generated__/StudentDetailQuery';

import MONITOR_ICON from '../../../img/offlineHoursUsed.svg';
import VIDEO_ICON from '../../../img/videoIcon.svg';
import { roundNumber } from '../../../constants';
import colors from '../../../common/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hoursUsedCard: {
      margin: '35px 0px 7px',
      padding: '17px 29px',
      borderRadius: 15,
      border: '1px solid #D4D4D4',
      boxShadow: '0px 2px 7px rgba(68, 68, 68, 0.14)',

      [theme.breakpoints.down('xs')]: {
        padding: 16,
      },

      '& .MuiTypography-h6': {
        fontFamily: fonts.INTER,
        fontWeight: 600,
        fontSize: '18px !important',
        color: '#2F80ED',
        letterSpacing: '-0.014em',
        marginBottom: 4,
        textTransform: 'initial',

        [theme.breakpoints.down('xs')]: {
          fontSize: 15,
          lineHeight: '19px',
        },
      },

      '& .MuiTypography-body1': {
        fontFamily: fonts.INTER,
        fontWeight: 400,
        fontSize: 16,
        color: '#A9A9A9',
        letterSpacing: '-0.011em',
      },
      '& .MuiTypography-body2': {
        fontFamily: fonts.INTER,
        fontWeight: 400,
        fontSize: 16,
        color: colors.BLUE_SEVEN,
        letterSpacing: '-0.011em',
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },

    hoursDetailText: {
      paddingTop: 19,
      marginTop: 23,
      borderTop: '1px solid #D4D4D4',

      '& .MuiBox-root': {
        marginBottom: 15,

        '&:last-child': {
          marginBottom: 0,
        },
      },

      '& .MuiTypography-body1': {
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
      },

      '& .MuiTypography-caption': {
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#A9A9A9',
        minWidth: 75,
        paddingRight: 15,
        fontFamily: fonts.INTER,

        '& span': {
          fontWeight: 400,
          paddingLeft: 3,
        },
      },
    },
  })
);

const useStylesFacebook = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      marginRight: 20,
      display: 'flex',
      alignItems: 'center',
    },

    bottom: {
      color: '#EAEAEA',
    },

    top: {
      color: '#2F80ED',
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },

    circle: {
      strokeLinecap: 'round',
    },

    forIconPosition: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& img': {
        position: 'absolute',
        margin: '0px auto',
      },
    },
  })
);

export interface IOfflineUsage {
  key: string;
  value: number;
  label?: string;
}

function FacebookCircularProgress({
  progress,
  isOfflineCard,
}: {
  progress: number;
  isOfflineCard: boolean;
}) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <Grid className={classes.forIconPosition}>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={56}
          thickness={4}
          value={100}
        />

        <img src={isOfflineCard ? MONITOR_ICON : VIDEO_ICON} alt="monitor" />
      </Grid>

      <CircularProgress
        variant="determinate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={56}
        thickness={4}
        value={progress}
      />
    </div>
  );
}

export default function VideoChatHours({
  totalHours,
  hoursUsed,
  text,
  premiumOfflineCountUsed,
  hideDetail = false,
  totalMilestoneVideoChatUsed,
  toggleMilestoneDialog,
}: {
  totalHours: number;
  hoursUsed: number;
  text: string;
  premiumOfflineCountUsed?: StudentDetailQuery_Student_premiumOfflineCountUsed | null;
  hideDetail?: boolean;
  totalMilestoneVideoChatUsed?: number | null;
  toggleMilestoneDialog: () => void;
}) {
  const classes = useStyles();
  const progress = (hoursUsed / totalHours) * 100;
  const remainingHours = roundNumber(totalHours - hoursUsed, 1);
  const remainingHoursText = `${remainingHours} hour${
    remainingHours > 1 ? 's' : ''
  } remaining`;

  let offlineUsage: (IOfflineUsage | null)[] = [];
  if (premiumOfflineCountUsed) {
    const {
      essayCount,
      MeetingWithAdvisor,
      offlineResearch,
      Other,
      dressRehearsalCount,
      milestoneTeamTotal,
      correspondenceTotal,
    } = premiumOfflineCountUsed;

    offlineUsage = [
      { key: 'Essay Editing/Review', value: essayCount, label: 'Essay Review' },
      {
        key: 'Offline Research',
        value: offlineResearch,
        label: 'Offline Research',
      },
      {
        key: 'Milestone Teams, Offline',
        value: milestoneTeamTotal,
        label: 'Milestone Teams, Offline',
      },
      {
        key: 'Dress Rehearsal',
        value: dressRehearsalCount,
        label: 'Dress Rehearsal',
      },
      {
        key: 'Meeting with another Advisor/AO',
        value: MeetingWithAdvisor,
        label: 'Meeting with another Advisor/AO',
      },
      {
        key: 'Correspondence with Family',
        value: correspondenceTotal,
        label: 'Correspondence related to account',
      },
      { key: 'Other', value: Other, label: 'Other' },
    ];
  }

  return (
    <Card className={classes.hoursUsedCard}>
      <Box display="flex" alignItems="center">
        <FacebookCircularProgress
          progress={progress}
          isOfflineCard={premiumOfflineCountUsed ? true : false}
        />

        <Box>
          <Typography variant="h6">
            {roundNumber(hoursUsed, 1)} of {totalHours} {text}
          </Typography>
          <Typography variant="body1">{remainingHoursText}</Typography>
        </Box>
      </Box>
      {!hideDetail && premiumOfflineCountUsed && !!offlineUsage.length && (
        <>
          <Grid className={classes.hoursDetailText}>
            {offlineUsage?.map((item) => {
              if (item?.key === 'Milestone Teams, Offline') {
                return (
                  <Box key={item?.key} display="flex" alignItems="center">
                    <Typography variant="caption">
                      {item?.value} <span>hrs</span>
                    </Typography>
                    <Typography
                      variant="body2"
                      onClick={() => {
                        toggleMilestoneDialog();
                      }}
                    >
                        {item?.label}
                    </Typography>
                  </Box>
                );
              }
              return (
                <Box key={item?.key} display="flex" alignItems="center">
                  <Typography variant="caption">
                    {item?.value} <span>hrs</span>{' '}
                  </Typography>
                  <Typography variant="body1">{item?.label}</Typography>
                </Box>
              );
            })}
          </Grid>
        </>
      )}

      {!premiumOfflineCountUsed && (
        <Grid className={classes.hoursDetailText}>
          <Box display="flex" alignItems="center">
            <Typography variant="caption">
              {roundNumber(totalMilestoneVideoChatUsed || 0, 1)}
              <span>hrs</span>
            </Typography>
            <Typography
              variant="body2"
              onClick={() => {
                toggleMilestoneDialog();
              }}
            >
              {'Milestone Teams, Video'}
            </Typography>
          </Box>
        </Grid>
      )}
    </Card>
  );
}
