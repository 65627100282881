import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import fonts from '../../common/fonts';
import colors from '../../common/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      fontSize: 12,
      fontWeight: 600,
      borderRadius: 50,
      padding: '4px 32px',
    },

    profileBadge: {
      width: 37,
      height: 37,
      cursor: 'pointer',
      borderRadius: '50%',
      textAlign: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center top',
      backgroundColor: colors.GRAY_TWELVE,
    },

    userProfile: {
      minWidth: 39,
      height: 39,
      marginRight: 15,
      borderRadius: '50px',
      color: colors.WHITE_ONE,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundColor: colors.GRAY_TWELVE,
      border: `1px solid ${colors.GRAY_TWELVE}`,
    },

    userName: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: '32px',
      letterSpacing: '-0.019em',
      color: colors.BLACK_TWELVE,
      fontFamily: fonts.POPPINS_FONT,
    },

    myTeamBox: {
      padding: '21px 24px 26px',
      background: colors.BUTTON_BORDER,

      [`@media only screen and (max-width: 991px)`]: {
        width: 'calc(100% - -40px)',
        margin: '0px 0px 25px -20px',
      },

      '& .MuiTypography-h6': {
        fontSize: 14,
        fontWeight: 600,
        color: colors.GRAY_TAB_BORDER,
        marginBottom: 2,
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .MuiTypography-body1': {
        fontWeight: 600,
        color: colors.GRAY_TAB_BORDER,
        letterSpacing: '-0.011em',
      },

      '& .MuiTypography-body2': {
        fontSize: 12,
        color: colors.GRAY_CHECKBOX_BG,
        letterSpacing: '-0.006em',
      },

      '& a.emailPhoneText': {
        display: 'block',
        fontFamily: `${fonts.INTER} !important`,
        fontSize: '12px !important',
        letterSpacing: '-0.006em !important',
        color: `${colors.BLUE_SEVEN} !important`,
      },
    },

    popoverSignOut: {
      fontSize: '14px !important',
      letterSpacing: '0.006em',
      textTransform: 'uppercase',
      color: `${colors.BLUE_SEVEN} !important`,

      '&:hover': {
        textDecoration: 'none',
      },
    },

    profilePopover: {
      width: 351,
      borderRadius: 15,
      background: colors.WHITE_ONE,
      border: `1px solid ${colors.WHITE_SEVEN}`,
      boxShadow: `0px 2px 10px ${colors.POPOVER_BOX_SHADOWN}`,

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    profileCard: {
      padding: '30px 25px 19px',
      borderBottom: `1px solid ${colors.GRAY_BOREDER}`,

      '& .MuiTypography-body1': {
        fontFamily: fonts.INTER,
        letterSpacing: '-0.011em',
        color: colors.GRAY_TAB_BORDER,
      },
    },

    logoutProfileRight: {
      display: 'flex',
      alignItems: 'center',

      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },

    mentorPortal: {
      display: 'flex',
      alignItems: 'center',
    },

    navbarBrand: {
      display: 'flex',
      marginRight: '0px',
      alignItems: 'center',
    },

    profileBadgeContainer: {
      '&:hover': {
        '& #profileBadgeMenu': {
          display: 'block',
        },
      },
    },

    positionRelativeForMenu: {
      position: 'relative',
    },

    profileBadgeMenu: {
      display: 'none',
      textAlign: 'left',
      cursor: 'auto',
      right: 0,
      zIndex: 9999,
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
      borderRadius: '15px',
    },

    headerWrapper: {
      padding: '14px 0px !important',
      boxShadow: `0px 4px 20px ${colors.BOX_SHADOW_THREE}`,

      '& .nav-item': {
        '&:not(:last-child)': {
          margin: '0px 40px 0px 0px',

          [`@media only screen and (min-width: 992px) and (max-width: 1150px)`]:
            {
              margin: '0px 30px 0px 0px',
            },

          '@media only screen and (max-width: 991px)': {
            margin: '0px 20px 0px 0px',

            '& > a': {
              fontSize: 16,
            },
          },
        },

        '&  a': {
          fontWeight: 400,
          fontSize: 16,
          color: colors.BLACK_TWELVE,
          fontFamily: fonts.POPPINS_FONT,

          [`@media only screen and (min-width: 992px) and (max-width: 1150px)`]:
            {
              fontSize: 13,
            },

          '&:hover': {
            transition: '0.5s',
            color: `${colors.BLACK_TWELVE}`,
          },

          '& .MuiBadge-root': {
            display: 'flex',
            marginTop: 1,
          },
        },
      },

      '& .main-nav': {
        flex: 1,
        paddingLeft: 63,

        [`@media only screen and (min-width: 992px) and (max-width: 1150px)`]: {
          paddingLeft: 40,
        },
      },
    },

    hamburgerMenu: {
      display: 'none',

      '@media only screen and (max-width: 991px)': {
        display: 'block',
      },
    },

    drawerStyle: {
      width: 300,
      padding: '20px',

      '& .navbar-brand': {
        margin: '0 0 20px 0',
      },

      '& .nav-item': {
        marginBottom: 15,

        '& a': {
          fontSize: 16,
          fontWeight: 400,
          color: colors.BLACK_TWELVE,
          fontFamily: fonts.POPPINS_FONT,

          '&:hover': {
            opacity: 0.5,
            transition: '0.5s',
          },
        },
      },
    },

    chatButton: {
      marginLeft: 27,
    },

    hideMobile: {
      display: 'flex',
      alignItems: 'center',

      '@media only screen and (max-width: 991px)': {
        display: 'none',
      },
    },

    profileAccount: {
      margin: '0 !important',
    },

    linkMenuItem: {
      '@media only screen and (min-width: 992px)': {
        display: 'block',
        marginBottom: 14,
        lineHeight: '24px',
        letterSpacing: '-0.011em',

        '&:hover': {
          textDecoration: 'none',
          color: `${colors.BLACK_TWELVE} !important`,
        },
      },
    },

    dropDownContainer: {
      top: 11,
      left: -15,
      width: 300,
      zIndex: 9999,
      paddingTop: 20,
      display: 'none',
      position: 'absolute',

      '& ul': {
        left: 0,
        margin: 0,
        borderRadius: 15,
        listStyle: 'none',
        padding: '18px 20px',
        background: colors.WHITE_ONE,
        boxShadow: `0px 2px 8px ${colors.BOX_SHADOW_TWO}`,

        '& li': {
          marginBottom: 8,
        },

        '& a': {
          margin: 0,
          fontWeight: 400,
          fontSize: 16,
          color: colors.BLACK_TWELVE,
          fontFamily: fonts.POPPINS_FONT,
        },
      },
    },
    dropDownLink: {
      position: 'relative',

      '& a': {
        cursor: 'pointer',
      },

      '&:hover .dropdown-container': {
        display: 'block',
      },

      '& .dropdownText': {
        fontSize: 16,
        fontWeight: 400,
        color: colors.BLACK_TWELVE,
        fontFamily: fonts.POPPINS_FONT,

        [`@media only screen and (min-width: 992px) and (max-width: 1150px)`]: {
          fontSize: 13,
        },
      },
    },

    navLink: {
      color: `${colors.BLACK_ONE} !important`,
      fontFamily: fonts.POPPINS_FONT,
      fontSize: 18,

      [theme.breakpoints.down('md')]: {
        fontSize: 15,
      },

      '& .MuiBadge-root': {
        display: 'flex',
      },
    },

    logHoursButton: {
      minWidth: 119,
      height: 40,
      background: colors.GREEN_EIGHT,
      borderRadius: 12,
      marginRight: 29,
      fontWeight: 700,
      fontSize: '14px !important',
      letterSpacing: '-0.006em',
      color: `${colors.WHITE_ONE} !important`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: '0.5s',

      '&:hover': {
        color: colors.WHITE_ONE,
      },
    },

    pageLinks: {
      padding: '20px 25px 19px',
    },
  })
);
