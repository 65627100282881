import { Box, Card, Grid, Tooltip, Typography } from '@material-ui/core';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import AddCollegeForm from './AddCollegeForm';

import { collegeAddress } from '../../../common/utility';
import COLLEGE_DEFAULT_IMG from '../../../img/college-default-icon.svg';
import { Query } from 'react-apollo';
import { GET_STUDENT } from '../graphql/queries/addStudentApplication';
import { getStudent } from '../graphql/queries/addStudentApplication/__generated__/getStudent';
import { AddCollegeProps } from '../../../common/interfaces';
import { addCollegeStyles, useTooltipStyles } from '../style';

const AddCollege: FC<AddCollegeProps> = ({
  college: {
    id,
    logo,
    name,
    applicationCreated,
    applicationRequirement,
    address,
  },
  refetch,
  studentId,
}) => {
  const collegeLink = `colleges/${id}`;
  const backgroundImage = `url( ${logo || COLLEGE_DEFAULT_IMG} )`;

  const { gradeOverall = '--', acceptRate } = applicationRequirement || {};
  const classes = addCollegeStyles();
  const tooltipClasses = useTooltipStyles();

  const parsedAddress = JSON.parse(address || '""');
  const { address_city, address_state } = parsedAddress || {};

  return (
    <Grid item lg={3} md={4} sm={6} xs={12}>
      <Box className={classes.similarSchoolCard}>
        <Box className={classes.cardTop}>
          <Link to={collegeLink}>
            <Box className={classes.cardImage} style={{ backgroundImage }} />
          </Link>
        </Box>

        {refetch && (
          <Query<getStudent>
            query={GET_STUDENT}
            variables={{ studentId }}
            fetchPolicy="network-only"
          >
            {({ data, loading }) => {
              let student = null;
              if (data) {
                student = data.Student;
              }
              return (
                <>
                  <AddCollegeForm
                    collegeId={id}
                    applicationCreated={!!applicationCreated}
                    refetch={refetch}
                    student={student}
                  />
                  ;
                </>
              );
            }}
          </Query>
        )}

        <Card className={classes.similarSchoolCardFooter}>
          <Box>
            <Tooltip
              title={name}
              classes={tooltipClasses}
              placement="top-start"
            >
              <Link to={collegeLink}>
                <Typography variant="h6" className="collegeNameClamp">
                  {name}
                </Typography>
              </Link>
            </Tooltip>

            <Typography variant="body1">
              {collegeAddress(address_city, address_state) || '--'}
            </Typography>
            <Typography variant="body1">
              {!!acceptRate && acceptRate ? (
                <>{Math.round(acceptRate * 100)}% Acceptance Rate</>
              ) : (
                '--'
              )}
            </Typography>
          </Box>

          {gradeOverall && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              className={classes.avatarGrade}
            >
              {gradeOverall}
            </Box>
          )}
        </Card>
      </Box>
    </Grid>
  );
};

export default AddCollege;
