import moment from 'moment';
import { StudentDetailQuery_Student_studentApplications } from '../__generated__/StudentDetailQuery';

type sortColumnInterFace = {
  column: string;
  sortDesc: boolean | 'default';
};

export const returnDeadlineDate = (item: any, type: string) => {
  return Date.parse(item?.decisionDeadline) || '';
};

export const customApplicationTableSorting = (
  applications:
    | (StudentDetailQuery_Student_studentApplications | null)[]
    | null,

  sortColumn: sortColumnInterFace
) => {
  if (
    sortColumn?.column &&
    sortColumn?.sortDesc !== 'default' &&
    applications?.length
  ) {
    if (sortColumn?.column === 'acceptance') {
      applications?.sort(
        (
          a: StudentDetailQuery_Student_studentApplications | null,
          b: StudentDetailQuery_Student_studentApplications | null
        ) => {
          return sortColumn?.sortDesc
            ? (b?.applicationRequirement?.acceptRate || 0) * 100 -
                (a?.applicationRequirement?.acceptRate || 0) * 100
            : (a?.applicationRequirement?.acceptRate || 0) * 100 -
                (b?.applicationRequirement?.acceptRate || 0) * 100;
        }
      );
    } else if (sortColumn?.column === 'dueDate') {
      applications?.sort((a, b) => {
        const dateA = returnDeadlineDate(a, a?.decisionStatus || '')
          ? new Date(returnDeadlineDate(a, a?.decisionStatus || '')).getTime()
          : 9999999999999;
        const dateB = returnDeadlineDate(b, b?.decisionStatus || '')
          ? new Date(returnDeadlineDate(b, b?.decisionStatus || '')).getTime()
          : 9999999999999;

        if (
          moment(dateA).format('DD-MM-YYYY') ===
          moment(dateB).format('DD-MM-YYYY')
        ) {
          if (a?.completedAt) return 1;
          else if (b?.completedAt) return -1;
        }

        return sortColumn?.sortDesc ? dateB - dateA : dateA - dateB;
      });
    } else if (sortColumn?.column === 'status') {
      applications?.sort((a, b) => {
        const AcompletedAt = a?.completedAt
          ? new Date(a.completedAt).getTime()
          : 0;
        const BcompletedAt = b?.completedAt
          ? new Date(b.completedAt).getTime()
          : 0;

        return sortColumn?.sortDesc
          ? BcompletedAt - AcompletedAt
          : AcompletedAt - BcompletedAt;
      });
    }
  } else {
    applications?.sort((a, b) => {
      const dateA = returnDeadlineDate(a, a?.decisionStatus || '')
        ? new Date(returnDeadlineDate(a, a?.decisionStatus || '')).getTime()
        : 9999999999999;
      const dateB = returnDeadlineDate(b, b?.decisionStatus || '')
        ? new Date(returnDeadlineDate(b, b?.decisionStatus || '')).getTime()
        : 9999999999999;

      if (
        moment(dateA).format('DD-MM-YYYY') ===
        moment(dateB).format('DD-MM-YYYY')
      ) {
        if (a?.completedAt) return 1;
        else if (b?.completedAt) return -1;
      }

      return dateA - dateB;
    });
  }
};
