import gql from 'graphql-tag';

export const GET_CURRENT_COLLEGE = gql`
  query getCurrentCollege {
    currentCollege {
      id
      name
      allMajors {
        id
        name
        otherMajor
        majorCategory {
          id
          name
          displayName
        }
      }
    }
  }
`;

export const GET_GRADUATE_SCHOOL = gql`
  query getGraduateSchool {
    graduateSchool {
      id
      name
      allMajors {
        id
        name
        otherMajor
        majorCategory {
          id
          name
          displayName
        }
      }
    }
  }
`;

// This query is for header, previously all the majors were also being retrieved which was definitely wrong
export const GET_ADVISORS_COLLEGES = gql`
  query GetAdvisorsColleges {
    advisorsColleges {
      currentCollege {
        id
        name
      }
      graduateSchool {
        id
        name
      }
    }
  }
`;
