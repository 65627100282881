import Colors from '../../common/colors';

export const DOWN_ARROW = () => {
  return (
    <svg
      width="9"
      height="8"
      viewBox="0 0 9 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.36886 7.22861C4.95097 7.95242 3.90624 7.95242 3.48835 7.22861L0.667581 2.34289C0.24969 1.61908 0.772053 0.71432 1.60784 0.71432L7.24937 0.714321C8.08516 0.714321 8.60752 1.61908 8.18963 2.34289L5.36886 7.22861Z"
        fill={Colors.BLACK_TWELVE}
      />
    </svg>
  );
};

export const EX_LINK = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.25">
        <path
          d="M14.3039 0H8.58958C8.1951 0 7.87529 0.31981 7.87529 0.714294C7.87529 1.10878 8.1951 1.42859 8.58958 1.42859H12.5794L5.22741 8.78067C4.94845 9.05963 4.94845 9.51184 5.22741 9.7908C5.36684 9.93027 5.54964 10 5.73244 10C5.91524 10 6.09808 9.9303 6.23754 9.79077L13.5896 2.43872V6.42859C13.5896 6.82307 13.9094 7.14288 14.3039 7.14288C14.6984 7.14288 15.0182 6.82307 15.0182 6.42859V0.714294C15.0182 0.31981 14.6983 0 14.3039 0Z"
          fill={Colors.BLACK_TWELVE}
        />
        <path
          d="M11.5909 6.8182C11.2144 6.8182 10.9091 7.12347 10.9091 7.50002V13.6364H1.36362V4.09092H7.50001C7.87656 4.09092 8.18183 3.78565 8.18183 3.40909C8.18183 3.03254 7.87656 2.72729 7.50001 2.72729H0.681827C0.305274 2.72729 0 3.03257 0 3.40912V14.3182C0 14.6948 0.305274 15 0.681827 15H11.5909C11.9675 15 12.2728 14.6948 12.2728 14.3182V7.50002C12.2727 7.12347 11.9675 6.8182 11.5909 6.8182Z"
          fill={Colors.BLACK_TWELVE}
        />
      </g>
    </svg>
  );
};
