import { FC } from 'react';
import { Query } from 'react-apollo';

import {
  Dialog,
  Box,
  IconButton,
  Typography,
  LinearProgress,
} from '@material-ui/core';

import { AdvisorRecordTables } from './AdvisorRecordTables';

import { LinkOnlineMeetingDialogProps } from '../../common/interfaces';
import { ALL_ADVISOR_BOOKINGS } from '../../pages/main/meetings/gql';
import { allAdvisorBookingsQuery } from '../../pages/main/meetings/gql/__generated__/allAdvisorBookingsQuery';

import { useStyles } from '../styles';

import CLOSE_ICON from '../../img/close-dialog-updated.svg';

export const LinkOnlineMeetingDialog: FC<
  LinkOnlineMeetingDialogProps
> = ({
  studentId,
  setFieldValue,
  onClose,
  openDialogue,
  setNoOfficialMeet,
  setSelectedAdvisorBookingId,
  selectedAdvisorBookingId,
  studentName,
  prevoiusActivityPerformed,
  activityPerformed,
}) => {
  const classes = useStyles();
  return (
    <Query<allAdvisorBookingsQuery>
      query={ALL_ADVISOR_BOOKINGS}
      fetchPolicy={'network-only'}
      variables={{
        status: 'completed',
        ...(studentId && { studentId }),
        fromLogHours: true,
      }}
    >
      {({ data, loading, refetch }) => {
        if (loading) {
          return (
            <Box mb={2}>
              <LinearProgress />
            </Box>
          );
        }
        if (!data) return null;
        const { allAdvisorBookings } = data;
        return (
          <Dialog
            open={openDialogue}
            disableBackdropClick
            className={classes.linkOnlineMeeting}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5">Link online meeting</Typography>

              <IconButton
                onClick={() => {
                  if (prevoiusActivityPerformed.length) {
                    setFieldValue('workActivity', prevoiusActivityPerformed);
                  } else {
                    setFieldValue('workActivity', '');
                  }
                  onClose();
                }}
              >
                <img src={CLOSE_ICON} alt="close-icon" />
              </IconButton>
            </Box>

            <Typography variant="h6" className="myMeetings">
              My Meetings - {studentName.split('(')[0]}
            </Typography>

            {data && (
              <AdvisorRecordTables
                data={allAdvisorBookings}
                selected={selectedAdvisorBookingId}
                setSelected={setSelectedAdvisorBookingId}
                refetch={refetch}
                setFieldValue={setFieldValue}
                onClose={onClose}
                setNoOfficialMeet={setNoOfficialMeet}
                activityPerformed={activityPerformed}
              />
            )}
          </Dialog>
        );
      }}
    </Query>
  );
};
