import { useState, ChangeEvent } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { bookingInfoStatuses } from '../../../constants';
import { useStyles } from './MyStudentsStyle';
import { FC } from 'react';
import StudentPastMeetingsList from './StudentPastMeetingsList';
import TabPanel from '../../../components/TabPanel';

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StudentMeetingsTabs: FC<{ studentId: any }> = ({ studentId }) => {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        className={classes.studentMeetingsTabs}
      >
        <Tab label="Completed" {...a11yProps(0)} />
        <Tab label="Canceled" {...a11yProps(1)} />
      </Tabs>

      <TabPanel value={value} index={0}>
        <StudentPastMeetingsList
          status={bookingInfoStatuses.COMPLETED}
          studentId={studentId}
        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <StudentPastMeetingsList
          status={bookingInfoStatuses.CANCELLED}
          studentId={studentId}
        />
      </TabPanel>
    </>
  );
};
export default StudentMeetingsTabs;
