import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import fonts from '../../../common/fonts';

export const useStylesTable = makeStyles((theme: Theme) =>
  createStyles({
    fieldMobileResponsive: {
      flexDirection: 'row',
      alignItems: 'center',
      '& .MuiTypography-h6': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: '0.018em',
        color: '#272929',
        textTransform: 'uppercase',
      },

      '& .MuiTypography-body1': {
        fontFamily: fonts.INTER,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#252C32',
      },

      '& .MuiFormControlLabel-root': {
        marginBottom: 0,
      },

      [theme.breakpoints.down('xs')]: {
        marginTop: 5,
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },

    collegeAvatarName: {
      '& .MuiTypography-body1': {
        fontFamily: fonts.INTER,
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#525454',

        [theme.breakpoints.down('xs')]: {
          lineHeight: 'initial',
        },
      },

      '& .collegeLogo': {
        width: 33,
        height: 33,
        marginRight: 28,

        [theme.breakpoints.down('sm')]: {
          marginRight: 10,
        },
      },
    },

    previousPage: {
      padding: 0,
    },
  })
);
