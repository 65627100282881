import { useContext } from 'react';

import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../../contexts';
import { CheckedCircleIcon } from '../../students/assets/svg';
import { useStyles } from '../style/NewTimeLogPageStyle';
import moment from 'moment';
import { getNewLabelForWorkActivity } from '../../../../common/utility';

const ResultPage = ({
  workType,
  initialValues,
  studentValue,
  expectedPayement,
  teamName,
  advisorsNames,
}: {
  advisorsNames: string[];
  workType: string;
  expectedPayement: string;
  teamName?: string;
  initialValues: any;
  studentValue: any;
}) => {
  const classes = useStyles();
  const { user } = useContext(AppContext);

  return (
    <>
      <Grid className={classes.successCardBox}>
        <Typography variant="h3">
          Success! <CheckedCircleIcon />
        </Typography>

        <Typography variant="body1" className="submittedText">
          Your activity has been submitted. You may edit details for this log
          before it is approved in your{' '}
          <Link to="/time-logs?requestedPayouts=true">
            Payouts - Requested page
          </Link>
          . Thank you for your dedication and for making a difference!
        </Typography>

        <Divider className="sectionDivider" />
      </Grid>

      <Box maxWidth={400} width="100%" className={classes.timeLogData}>
        <Box display="flex" mb={2.5}>
          <Box width="30%">Activity Type:</Box>
          <Box width="70%" className="valueText">
            {initialValues.workType}
          </Box>
        </Box>

        {workType !== 'Dress Rehearsal' && workType !== 'Other' && (
          <Box display="flex" mb={2.5}>
            <Box width="30%">
              {!!initialValues.workActivity && workType !== 'Milestone Teams'
                ? 'Activity:'
                : 'Milestone Team:'}
            </Box>
            <Box width="70%" className="valueText">
              {!!initialValues.workActivity
                ? getNewLabelForWorkActivity(initialValues.workActivity)
                : initialValues.milestoneTeam}
            </Box>
          </Box>
        )}

        {initialValues.milestone && (
          <Box display="flex" mb={2.5}>
            <Box width="30%">Milestone:</Box>
            <Box width="70%" className="valueText">
              {initialValues.milestone}
            </Box>
          </Box>
        )}

        {!initialValues.workActivity && workType === 'Milestone Teams' && (
          <Box display="flex" mb={2.5}>
            <Box width="30%">Entitlement Type:</Box>
            <Box width="70%" className="valueText">
              {initialValues.entitlementType}
            </Box>
          </Box>
        )}

        <Box display="flex" mb={0.25}>
          <Box width="30%">Date:</Box>
          <Box width="70%" className="valueText">
            {moment(new Date(initialValues.activityAt)).format('MMMM DD, YYYY')}
          </Box>
        </Box>

        <Box display="flex" mb={3}>
          <Box width="30%">Duration:</Box>
          <Box width="70%" className="valueText">
            {initialValues.workActivity === 'Onboarding'
              ? `_ _`
              : `${initialValues.activityDuration} minutes`}
          </Box>
        </Box>

        {(workType === 'Student Advising' ||
          workType === 'Speciality Team' ||
          workType === 'Dress Rehearsal') && (
          <Box display="flex" mb={2.5}>
            <Box width="30%">Student:</Box>
            <Box width="70%" className="valueText">
              <b>{studentValue.label.split('(')[0]}</b>
              <div style={{ color: '#A9A9A9' }}>
                {studentValue.label.match(/\((.*?)\)/)[0]}
              </div>
            </Box>
          </Box>
        )}

        <Box display="flex" mb={2.75}>
          <Box width="30%">Advisor(s):</Box>
          <Box width="70%" className="valueText">
            {advisorsNames.map((advisorName) => (
              <div>{advisorName}</div>
            ))}
          </Box>
        </Box>

        <Box display="flex" mb={2.75}>
          <Box width="30%">Expected Pay:</Box>
          <Box width="70%" className="valueText">
            {user?.isFullTime ? '$0' : `$${expectedPayement}`}
          </Box>
        </Box>

        {workType === 'Speciality Team' && (
          <Box display="flex" mb={2.75}>
            <Box width="30%">Team Name:</Box>
            <Box width="70%" className="valueText">
              {teamName}
            </Box>
          </Box>
        )}

        {initialValues?.note && (
          <Box mb={2.75}>
            <ul>
              <li>
                <b>Activity Note</b>
              </li>
            </ul>
            <Box className="valueText note">{initialValues?.note}</Box>
          </Box>
        )}
        {initialValues?.lateLogReason && (
          <Box mb={2.75}>
            <ul>
              <li>
                <b>Late Log</b>
              </li>
            </ul>
            <Box className="valueText note">{initialValues?.lateLogReason}</Box>
          </Box>
        )}
        {initialValues?.duplicateOrientationCallReason && (
          <Box mb={2.75}>
            <ul>
              <li>
                <b>Additional Orientation call </b>
              </li>
            </ul>
            <Box className="valueText note">{initialValues?.duplicateOrientationCallReason}</Box>
          </Box>
        )}
        {initialValues?.durationNote && (
          <Box mb={2.75}>
            <ul>
              <li>
                <b>Why did this activity exceed 90 minutes?</b>
              </li>
            </ul>
            <Box className="valueText note">{initialValues?.durationNote}</Box>
          </Box>
        )}
        {initialValues?.noteForExtendedMeeting && (
          <Box mb={2.75}>
            <ul>
              <li>
                <b>
                  Why doesn't the duration reflect the expected meeting time?
                </b>
              </li>
            </ul>
            <Box className="valueText note">
              {initialValues?.noteForExtendedMeeting}
            </Box>
          </Box>
        )}
        {initialValues?.noteForNoOrientationCall && (
          <Box mb={2.75}>
            <ul>
              <li>
                <b>
                  Why is this meeting being logged before an Orientation call?
                </b>
              </li>
            </ul>
            <Box className="valueText note">
              {initialValues?.noteForNoOrientationCall}
            </Box>
          </Box>
        )}
        {initialValues?.otherMeetingDescription && (
          <Box mb={2.75}>
            <ul>
              <li>
                <b>
                  Why is there no “My Meeting” associated with this activity
                  log?
                </b>
              </li>
            </ul>
            <Box className="valueText note">
              {initialValues?.otherMeetingDescription}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ResultPage;
