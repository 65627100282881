import {
  Box,
  Card,
  Grid,
  Theme,
  makeStyles,
  Typography,
  createStyles,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { StudentDetailQuery_Student_premiumOfflineCountUsed } from '../../students/__generated__/StudentDetailQuery';

import fonts from '../../../../common/fonts';
import VIDEO_ICON from '../../../../img/videoIcon.svg';
import MONITOR_ICON from '../../../../img/offlineHoursUsed.svg';
import Colors from '../../../../common/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hoursUsedCard: {
      boxShadow: 'none',

      '& .MuiTypography-h6': {
        fontSize: 17,
        fontWeight: 600,
        fontFamily: fonts.INTER,
        textTransform: 'initial',
        letterSpacing: '-0.014em',

        [theme.breakpoints.down('xs')]: {
          fontSize: 13,
          lineHeight: '19px',
        },
      },

      '& .MuiTypography-body1': {
        fontFamily: fonts.INTER,
        fontWeight: 400,
        fontSize: 16,
        color: '#A9A9A9',
        letterSpacing: '-0.011em',
      },

      '& .infoButton': {
        padding: 4,
        marginLeft: 10,
      },
    },
  })
);

const useStylesFacebook = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      marginRight: 10,
      display: 'flex',
      alignItems: 'center',
    },

    bottom: {
      color: Colors.GRAY_EIGHT,
    },

    forIconPosition: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& img': {
        position: 'absolute',
        margin: '0px auto',
      },
    },
  })
);

function FacebookCircularProgress({
  progress,
  isOfflineCard,
  durationExpected,
  progressColor,
  durationExpectedColor,
}: {
  progress: number;
  isOfflineCard: boolean;
  durationExpected?: number;
  progressColor?: string;
  durationExpectedColor?: string;
}) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <Grid className={classes.forIconPosition}>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={56}
          thickness={4}
          value={100}
        />
        {durationExpected && durationExpectedColor && (
          <CircularProgress
            variant="determinate"
            style={{
              position: 'absolute',
              color: durationExpectedColor,
            }}
            size={56}
            thickness={4}
            value={
              progress >= 100
                ? 0
                : durationExpected < 100
                ? durationExpected
                : 100
            }
            color="inherit"
          />
        )}
        {progressColor && (
          <>
            <CircularProgress
              variant="determinate"
              style={{
                position: 'absolute',
                color: '#FFFFFF',
              }}
              color="inherit"
              size={56}
              thickness={4}
              value={progress}
              disableShrink
            />

            <CircularProgress
              variant="determinate"
              style={{
                position: 'absolute',
                color: progressColor,
              }}
              color="inherit"
              size={56}
              thickness={4}
              value={progress}
              disableShrink
            />
          </>
        )}

        <img src={isOfflineCard ? VIDEO_ICON : MONITOR_ICON} alt="monitor" />
      </Grid>
    </div>
  );
}

export default function StudentVideoChatHours({
  text,
  hoursUsed,
  totalHours,
  premiumOfflineCountUsed,
  durationExpected,
  durationExpectedColor,
  progressColor,
  isSubmittingPendingTimelog,
}: {
  text: string;
  hoursUsed: number;
  totalHours: number;
  premiumOfflineCountUsed?: StudentDetailQuery_Student_premiumOfflineCountUsed | null;
  durationExpected?: number;
  progressColor?: string;
  durationExpectedColor?: string;
  isSubmittingPendingTimelog: boolean;
}) {
  const classes = useStyles();
  let progress = (hoursUsed / totalHours) * 100;
  progress = progress > 100 ? 100 : progress;
  const remainingHours = isSubmittingPendingTimelog
    ? Number((totalHours - hoursUsed).toFixed(1))
    : Number((totalHours - (hoursUsed + (durationExpected || 0))).toFixed(1));
  const remainingHoursText =
    remainingHours >= 0
      ? `${remainingHours} hour${remainingHours > 1 ? 's' : ''} ${
          durationExpected ? 'will remain' : 'remaining'
        }`
      : `${Math.abs(remainingHours)} hours over capacity`;
  const durationExpectedValue =
    ((hoursUsed + (durationExpected || 0)) / totalHours) * 100;

  return (
    <Card className={classes.hoursUsedCard}>
      <Box display="flex" alignItems="center">
        <FacebookCircularProgress
          progress={progress}
          isOfflineCard={premiumOfflineCountUsed ? true : false}
          {...(durationExpected && {
            durationExpected: durationExpectedValue,
          })}
          {...(progressColor && { progressColor })}
          {...(durationExpectedColor && { durationExpectedColor })}
        />

        <Box>
          <Typography
            {...{
              variant: 'h6',
            }}
            style={{
              color: Colors.GRAY_TWENTYTWO,
              width: '280px',
            }}
          >
            <span
              style={{
                color:
                  durationExpectedColor && durationExpected
                    ? durationExpectedColor
                    : Colors.GRAY_TWENTYTWO,
              }}
            >
              {isSubmittingPendingTimelog
                ? hoursUsed.toFixed(1)
                : (hoursUsed + (durationExpected || 0)).toFixed(1)}
            </span>{' '}
            of {totalHours} {text}
          </Typography>

          <Box display="flex" alignItems="center">
            <Typography
              variant="body1"
              style={{
                color:
                  durationExpectedColor && durationExpected
                    ? durationExpectedColor
                    : '#A9A9A9',
              }}
            >
              {remainingHoursText}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
