import { makeStyles, createStyles } from '@material-ui/styles';
import fonts from '../../../common/fonts';

import DROPDOWN_ARROW_IMG from '../../../img/dropdown-arrow.svg';

export const useStyleSelect = makeStyles(() =>
  createStyles({
    customSearchSelect: {
      boxShadow: 'none !important',

      '& .MuiInputBase-input': {
        height: 32,
        fontSize: 14,
        width: '100%',
        borderRadius: 6,
        fontWeight: 400,
        color: '#272929',
        cursor: 'pointer',
        backgroundSize: 12,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontFamily: fonts.INTER,
        textTransform: 'initial',
        textOverflow: 'ellipsis',
        letterSpacing: '-0.006em',
        border: '1px solid #d4d4d4',
        padding: '0px 30px 0px 12px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center right 12px',
        backgroundImage: `url(${DROPDOWN_ARROW_IMG})`,
      },
    },

    iconClear: {
      position: 'absolute',
      right: 34,
      padding: 5,
      width: 25,
      height: 25,
      top: 8,

      '& .MuiSvgIcon-root': {
        width: 14,
      },
    },
  })
);
