import { ChangeEvent, useContext, useEffect, useState } from 'react';
import {
  Dialog,
  Box,
  Typography,
  TextareaAutosize,
  InputLabel,
  Button,
  IconButton,
} from '@material-ui/core';
import { Spinner } from 'react-bootstrap';

import { useStyles } from './StudentNotesStyle';

import { AppContext } from '../../../../contexts';
import client from '../../../../apollo';
import Dialog_Cross_Img from '../../../../img/close-dialog-updated.svg';

import { UPDATE_OR_SAVE_STUDENT_NOTES } from '../../../../graphql/mutations/StudentNotes';

const AddStudentNoteDialog = ({
  StudentId,
  openDialog,
  handleClose,
  refetch,
  description,
  NoteId,
}: {
  StudentId: number;
  openDialog: boolean;
  handleClose: Function;
  refetch: Function;
  description?: string;
  NoteId: number | null;
}) => {
  const classes = useStyles();
  const { user } = useContext(AppContext);
  const [noteValue, setNoteValue] = useState('');
  const [loading, setLoading] = useState(false);

  const handleTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNoteValue(e.target.value);
  };

  const onDialogClose = () => {
    handleClose();
    setNoteValue('');
  };

  const handleAddNote = async () => {
    try {
      setLoading(true);
      const variables = {
        StudentId,
        type: 'advising_note',
        description: noteValue,
        AdvisorId: user?.id,
        ...(NoteId && { NoteId }),
      };
      await client.mutate({
        mutation: UPDATE_OR_SAVE_STUDENT_NOTES,
        variables,
      });
      await refetch();
      onDialogClose();
    } catch (error) {
      console.log('Error:::', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (NoteId && description) {
      setNoteValue(description);
    }
  }, [NoteId, description]);

  return (
    <Dialog open={openDialog} maxWidth="sm" className={classes.addNoteDialog}>
      <Box>
        <Box
          display={'flex'}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box>
            <Typography variant="h4">Advising Note</Typography>
            <Typography variant="body1">{`${user?.firstName} ${user?.lastName}`}</Typography>
          </Box>

          <IconButton onClick={onDialogClose}>
            <img src={Dialog_Cross_Img} alt="cross" width="12px" />
          </IconButton>
        </Box>

        <Box mt={4}>
          <InputLabel>Description</InputLabel>

          <TextareaAutosize
            className="textArea"
            onChange={handleTextArea}
            rowsMin={5}
            aria-label="note textarea"
            placeholder="Write your note here"
            value={noteValue}
          />
        </Box>

        <Box mt={3}>
          <Button
            className={classes.addNoteBtn}
            variant="contained"
            disabled={!noteValue || loading}
            onClick={handleAddNote}
            startIcon={loading && <Spinner animation="border" size="sm" />}
          >
            {NoteId ? 'Save' : 'Add Note'}
          </Button>
          {NoteId && (
            <Button
              className={classes.cancelNoteBtn}
              variant="contained"
              onClick={onDialogClose}
            >
              Cancel
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};
export default AddStudentNoteDialog;
