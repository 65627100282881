import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core';

import fonts from '../../../common/fonts';
import Colors from '../../../common/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardSavedOpportunity: {
      position: 'relative',

      '& .savedOpportunityButton': {
        top: 25,
        right: 25,
        zIndex: 999999,
        position: 'absolute',
      },

      '& .savedOpportunityIcon': {
        '& svg': {
          '& path': {
            fill: Colors.BLUE_BETA,
            stroke: Colors.BLUE_BETA,
          },
        },
      },

      '& svg': {
        width: 24,
        height: 24,

        '& path': {
          fill: Colors.WHITE_ONE,
          stroke: Colors.WHITE_SEVEN,

          '&:hover': {
            fill: Colors.BLUE_OPACITY,
            stroke: Colors.BLUE_OPACITY,
          },
        },
      },
    },

    cardOpportunity: {
      minHeight: 320,
      borderRadius: 20,
      padding: '29px 26px 35px 33px',
      border: `1px solid ${Colors.WHITE_SEVEN}`,
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

      [`@media only screen and (max-width: 767px)`]: {
        minHeight: 10,
      },

      '& .MuiTypography-h6': {
        fontSize: 18,
        fontWeight: 600,
        marginBottom: 17,
        lineHeight: '24px',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.252px',
        color: Colors.BLACK_TWELVE,
      },

      '& .MuiTypography-body1': {
        fontSize: 12,
        maxWidth: 220,
        fontWeight: 600,
        color: '#A9A9A9',
        lineHeight: '16px',
        letterSpacing: '0.018em',
        textTransform: 'uppercase',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .MuiBadge-root': {
        fontSize: 14,
        fontWeight: 600,
        borderRadius: 6,
        color: '#96BFF5',
        padding: '1px 12px',
        fontFamily: fonts.INTER,
        textTransform: 'initial',
        background: 'transparent',
        letterSpacing: '-0.006em',
        border: '1px solid #96BFF5',
        margin: '0px 10px 10px 0px',

        '&:last-child': {
          marginRight: 0,
        },

        '&:hover': {
          color: `${Colors.WHITE_ONE}`,
          background: `${Colors.BLUE_SEVEN}`,
        },
      },

      '& .MuiButton-root': {
        borderRadius: 20,
        marginRight: 5,
        fontWeight: 'normal',
        fontSize: 12,
        padding: '4px 11px',
        textTransform: 'capitalize',
        color: `${Colors.WHITE_ONE}`,

        '&:last-child': {
          marginRight: 0,
        },
      },

      '& .MuiButton-containedPrimary': {
        background: `${Colors.COLOR_PRIMARY} !important`,
      },

      '& .MuiButton-containedSecondary': {
        background: `${Colors.COLOR_SECONDARY} !important`,
      },
    },

    basicInfo: {
      marginTop: 14,

      '& .MuiTypography-body2': {
        fontWeight: 400,
        fontSize: 16,
        color: '#7D7F7F',
        marginBottom: 14,
        letterSpacing: '-0.011em',
        fontFamily: fonts.INTER,

        '&:last-child': {
          margin: 0,
        },

        '& img': {
          marginRight: 12,
        },
      },

      '& .MuiTypography-body1': {
        fontWeight: 'normal',
        fontSize: 16,
        lineHeight: '19px',
        color: `${Colors.COLOR_RGBA}`,
        margin: '16px 0px 14px',
      },
    },

    areaBlur: {
      filter: 'blur(6px)',
      width: '100%',
      height: 20,
      borderRadius: 20,
      padding: '3px 10px',
    },
  })
);
