import gql from 'graphql-tag';

export const ALL_STUDENTS_INCENTIVE_BONUSES_QUERY = gql`
  query getStudentsIncentiveBonusDetailQuery($MentorId: Int) {
    getStudentsIncentiveBonusDetail(input: { MentorId: $MentorId }) {
      students {
        Id
        StudentId
        StudentName
        StudentPackage
        incentiveBonus
        GraduateYear
        entitlements
        applicationList
      }
      totalAmount
    }
  }
`;

export const ALL_STUDENTS_INCENTIVE_BONUSES_QUERY_AMOUNT_ONLY = gql`
  query getStudentsIncentiveBonusDetailTotalAmountOnlyQuery($MentorId: Int) {
    getStudentsIncentiveBonusDetail(input: { MentorId: $MentorId }) {
      totalAmount
    }
  }
`;
