import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Colors from '../../../common/colors';
import fonts from '../../../common/fonts';

export const calendarAccountDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    confirmationDialog: {
      '& .MuiDialog-paper': {
        width: 532,
        maxWidth: 532,
        border: '1px solid #D4D4D4',
        borderRadius: 25,
        padding: '42px 42px 38px',

        [theme.breakpoints.down('xs')]: {
          padding: 16,
          margin: 0,
          width: '95%',
          maxWidth: '95%',
        },
      },

      '& .MuiTypography-h6': {
        color: '#272929',
        fontSize: 24,
        fontWeight: 600,
        fontFamily: fonts.POPPINS_FONT,
        letterSpacing: '-0.011em',
      },

      '& .closeButton': {
        position: 'absolute',
        top: 42,
        right: 46,

        [theme.breakpoints.down('xs')]: {
          top: 15,
          right: 15,
        },
      },

      '& .MuiTypography-body1': {
        fontFamily: fonts.INTER,
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#7D7F7F',
      },

      '& .subtitleGutter': {
        marginBottom: 32,
      },

      '& .MuiTypography-body2': {
        fontFamily: fonts.INTER,
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#272929',
        marginBottom: 9,
      },

      '& .availabilityButton': {
        textTransform: 'capitalize',
        height: 40,
        borderRadius: 12,
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 700,
        fontSize: 14,
        letterSpacing: '-0.006em',
        backgroundColor: '#2F80ED',
        width: 240,
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 32,

        [theme.breakpoints.down('xs')]: {
          width: '90%',
        },
      },

      '& .saveButton': {
        width: 151,
      },
    },

    checkboxComponent: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: -10,

      '& .checkboxLeftGutter': {
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
      },

      '& .checkboxText': {
        fontFamily: fonts.INTER,
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.011em',
        color: '#7D7F7F',
        margin: '0px 0px 0px 4px',
        lineHeight: '18px',
      },

      '& .checkboxInput': {
        transform: 'scale(1.4)',
        marginLeft: '2px',
      },

      '& .MuiIconButton-root': {
        margin: 0,
      },

      '& .MuiCheckbox-colorSecondary': {
        color: '#A9A9A9',
      },

      '& .MuiCheckbox-colorSecondary.Mui-checked': {
        color: '#7D7F7F',

        '&:hover': {
          backgroundColor: 'rgba(127, 127, 127, 0.2)',
        },
      },
    },
  })
);

export const connectCalendarStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountStyle: {
      '& .MuiTypography-h3': {
        fontWeight: 700,
        fontSize: 36,
        letterSpacing: '-0.022em',
        color: '#272929',
        fontFamily: fonts.POPPINS_FONT,
        padding: '65px 0px 43px',
      },
    },

    calendarConnected: {
      '&.MuiButton-text': {
        minWidth: '100%',
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },

      '& .calendarConnectedSpan': {
        [`@media only screen and (max-width: 320px)`]: {
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          width: 200,
        },
      },
    },

    accountRightContainer: {
      padding: '43px 55px 55px',
      border: '1px solid #D4D4D4',
      borderRadius: 5,

      [theme.breakpoints.down('sm')]: {
        padding: '20px 16px',
      },

      '& .headerBox': {
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
      },

      '& .MuiButton-root': {
        borderRadius: 12,
        fontSize: 14,
        fontWeight: 700,
        minWidth: 279,
        height: 40,
        letterSpacing: '-0.006em',
        textTransform: 'capitalize',
        boxShadow: 'none',
        padding: 10,
      },

      '& .MuiButton-contained': {
        color: Colors.WHITE_ONE,
        backgroundColor: Colors.BLUE_SEVEN,

        '&:hover': {
          backgroundColor: '#2668C1',
        },
      },

      '& .MuiTypography-h4': {
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '-0.019em',
        color: '#272929',
        fontFamily: fonts.POPPINS_FONT,

        [theme.breakpoints.down('sm')]: {
          fontSize: 18,
        },
      },

      '& .MuiBadge-root': {
        minWidth: 74,
        height: 16,
        background: '#45CE93',
        borderRadius: 4,
        marginLeft: 25,
        fontFamily: fonts.INTER,
        fontWeight: 600,
        fontSize: 12,
        color: '#EDFBF5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.down('sm')]: {
          margin: '0px ',
        },
      },

      '& .MuiTypography-body1': {
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
      },

      '& .conflictsText': {
        margin: '6px 0px 26px',
      },

      '& .MuiDivider-root': {
        margin: '45px 0px 34px',
        background: '#D4D4D4',
      },

      '& .MuiTypography-h6': {
        fontFamily: fonts.INTER,
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#272929',
        marginBottom: 6,
      },

      '& .MuiButton-outlined': {
        border: '1px solid #D4D4D4',
        color: '#272929',
        marginTop: 26,
      },

      '& .MuiButton-text': {
        color: '#2F80ED',
        fontWeight: 600,

        '& img': {
          marginRight: 12,
        },
      },
    },

    removeConnectionGutter: {
      marginTop: 26,

      '& .removeConnection': {
        marginBottom: 8,

        [theme.breakpoints.down('xs')]: {},

        '&:last-child': {
          marginBottom: 0,
        },

        '& .MuiTypography-body2': {
          fontWeight: 400,
          fontSize: 16,
          letterSpacing: '-0.011em',
          color: '#7D7F7F',

          [theme.breakpoints.down('xs')]: {
            width: 240,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        },
      },

      '& .MuiIconButton-root': {
        marginLeft: 16,
        padding: 8,

        [theme.breakpoints.down('xs')]: {
          marginLeft: 0,
        },
      },

      '& .MuiDivider-root': {
        maxWidth: 322,
        backgroundColor: '#D4D4D4',
        margin: '12px 0px 20px',
      },
    },

    checkboxComponent: {
      display: 'flex',
      alignItems: 'center',

      '& .checkboxLeftGutter': {
        paddingLeft: 16,
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
      },

      '& .checkboxText': {
        fontFamily: fonts.INTER,
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.011em',
        color: '#7D7F7F',
        margin: '0px 0px 0px 4px',
        lineHeight: '18px',
        width: 'unset !important',
      },

      '& .checkboxInput': {
        transform: 'scale(1.4)',
        marginLeft: '2px',
      },

      '& .MuiIconButton-root': {
        margin: 0,
      },

      '& .MuiCheckbox-colorSecondary': {
        color: '#A9A9A9',
      },

      '& .MuiCheckbox-colorSecondary.Mui-checked': {
        color: '#7D7F7F',

        '&:hover': {
          backgroundColor: 'rgba(127, 127, 127, 0.2)',
        },
      },
    },

    connectCalendarButton: {
      '&.MuiButton-root': {
        [theme.breakpoints.down('xs')]: {
          minWidth: '100%',
          fontSize: 12,
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },
      },
    },
  })
);

export const removePersonalAccountStyle = makeStyles((theme: Theme) =>
  createStyles({
    confirmationDialog: {
      '& .MuiDialog-paper': {
        width: 551,
        maxWidth: 551,
        border: '1px solid #D4D4D4',
        borderRadius: 25,
        padding: '50px 34px 43px 50px',
        margin: 10,

        [theme.breakpoints.down('xs')]: {
          padding: 16,
        },
      },

      '& .MuiTypography-h4': {
        maxWidth: 406,
        color: '#272929',
        fontSize: 24,
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        letterSpacing: '-0.019em',
        lineHeight: '32px',
        marginBottom: 22,
      },

      '& .MuiTypography-body1': {
        color: '#525454',
        fontSize: 16,
        fontWeight: 400,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.011em',
      },

      '& ul': {
        margin: '10px 0px 34px',
        padding: '0px 0px 0px 20px',
      },

      '& .MuiButton-root': {
        textTransform: 'capitalize',
        height: 40,
        borderRadius: 12,
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '-0.006em',
      },

      '& .MuiButton-root.MuiButton-containedPrimary': {
        backgroundColor: '#E94444 !important',
        minWidth: 151,
        marginRight: 16,

        [theme.breakpoints.down('xs')]: {
          minWidth: 120,
        },
      },

      '& .MuiButton-outlined': {
        minWidth: 194,
        color: '#272929',
        border: '1px solid #D4D4D4',

        [theme.breakpoints.down('xs')]: {
          minWidth: 140,
        },
      },
    },
  })
);
