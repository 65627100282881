import { FC, useState, useEffect, memo } from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import momentTz from 'moment-timezone';
import { Typography, Popover, Box, Button } from '@material-ui/core';
import AsyncSelect from 'react-select/async';
import { useStylesAccount } from '../account/accountStyle';
import { loadReasonOptions } from '../../../common/utility';
import { Spinner } from 'react-bootstrap';
import { ICMPProps } from '../../../common/interfaces';
import OtherReason from '../meetings/OtherReason';

const CancelMeetingPopover: FC<ICMPProps> = ({
  anchorEl,
  handleDelete,
  handleClosePopOver,
  wait,
  bookingDetail,
  reason,
  title,
  setReason,
  setOtherReason,
  otherReason,
}) => {
  const classes = useStylesAccount();
  const openPopover = Boolean(anchorEl);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1600;

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
  }, []);

  const handleInputChange = (selectedValue: any) => {
    setReason(selectedValue.value);
  };
  const startDate = bookingDetail?.startTime
    ? moment.unix(bookingDetail?.startTime).format('ddd MMM DD, YYYY')
    : 'N/A';
  const start = bookingDetail?.startTime
    ? moment.unix(bookingDetail?.startTime).format('LT')
    : 'NA';

  return (
    <Popover
      id="delete-popover"
      open={openPopover}
      anchorEl={anchorEl}
      className={classes.popOverModel}
      anchorOrigin={
        width < breakpoint
          ? { vertical: 'bottom', horizontal: 'center' }
          : { vertical: 'top', horizontal: 'center' }
      }
      transformOrigin={
        width < breakpoint
          ? { vertical: 'bottom', horizontal: 'center' }
          : { vertical: 'top', horizontal: 'center' }
      }
    >
      <Box
        className={clsx(classes.popOverBoxListing, classes.confirmationDialog)}
      >
        <Typography variant="h4">{title}</Typography>
        <Typography variant="body1">
          {`${startDate} - ${start}`}{' '}
          {momentTz(startDate).tz(momentTz.tz.guess()).format('z')}
        </Typography>
        <Box className={classes.accountInformationForm} pt="21px">
          <Typography variant="body1" className="fieldCaption">
            Cancelation Reason
          </Typography>

          <Box className={classes.advisorSelectDropDown} pb="22px">
            <AsyncSelect
              cacheOptions
              placeholder="Select Cancelation Reason"
              defaultOptions
              loadOptions={loadReasonOptions}
              isClearable={true}
              isSearchable={true}
              classes={classes}
              onChange={handleInputChange}
              classNamePrefix={'react-select'}
              className="react-select-container"
            />
          </Box>
        </Box>
        {reason === 'other' ? (
          <OtherReason title="Note" setOtherReason={setOtherReason} />
        ) : (
          ''
        )}
        <Box textAlign="right">
          <Button variant="outlined" onClick={() => handleClosePopOver()}>
            Keep
          </Button>

          <Button
            color="primary"
            variant="contained"
            onClick={() => handleDelete(reason, otherReason)}
            disabled={wait || !reason || (reason === 'other' && !otherReason)}
            className="dangerButton"
            startIcon={wait && <Spinner animation="border" size="sm" />}
          >
            {!wait && `Cancel Meeting`}
            {wait && <>{'Canceling...'}</>}
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};
export default memo(CancelMeetingPopover);
