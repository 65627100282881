import { createStyles, makeStyles, Theme } from '@material-ui/core';

import Fonts from '../common/fonts';
import Colors from '../common/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      boxShadow: 'none',

      [theme.breakpoints.down('sm')]: {
        overflowX: 'auto',
      },
    },

    tableHead: {
      fontFamily: `${Fonts.POPPINS_FONT}`,
      fontSize: '14px',
      lineHeight: '25px',
      fontWeight: 600,
      letterSpacing: '0.3375px',
      color: '#272929',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      padding: '15px 8px 15px 0px',

      '&.tableHeadBg': {
        background: '#ffffff',
        borderBottom: '1px solid #A9A9A9',
      },
    },

    calendarGrid: {
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },

    timeSlotTable: {
      marginBottom: 24,

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },

      '& .MuiTypography-h4': {
        fontFamily: Fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '-0.019em',
        color: '#272929',
        marginRight: 20,
      },

      '& .MuiIconButton-root': {
        width: 30,
        height: 30,
      },

      '& .MuiButton-contained': {
        minWidth: 240,
        height: 40,
        background: Colors.BLUE_SEVEN,
        borderRadius: 12,
        color: Colors.WHITE_FIVE,
        fontFamily: Fonts.POPPINS_FONT,
        fontWeight: 'bold',
        fontSize: 14,
        letterSpacing: '-0.006em',
        textTransform: 'capitalize',

        [theme.breakpoints.down('xs')]: {
          marginTop: 20,
        },
      },
    },

    calendarMeeting: {
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },

    statusBadge: {
      width: 10,
      height: 10,
      background: '#45CE93',
      borderRadius: 2,
      marginRight: 10,
    },

    bookingsCount: {
      fontSize: '12px',
      letterSpacing: '-0.006em',
      color: '#7D7F7F',
      padding: '6px 0px',
      fontFamily: Fonts.INTER,
    },
  })
);
