import AsyncSelect from 'react-select/async';

const SimpleDropDown = ({
  loadOptions,
  setValue,
  placeHolder,
  component,
}: {
  loadOptions: any;
  setValue: Function;
  placeHolder: string;
  component?: string | null;
}) => {
  return (
    <AsyncSelect
      isClearable={true}
      cacheOptions
      loadOptions={loadOptions}
      defaultOptions={true}
      onChange={(value) => {
        if (!value) {
          setValue({ value: '', label: '' });
        } else {
          setValue(value);
        }
      }}
      isSearchable
      placeholder={placeHolder}
      className="basic-single"
      classNamePrefix="select"
      maxMenuHeight={200}
    />
  );
};
export default SimpleDropDown;
