import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Fonts from '../../../common/fonts';
import Colors from '../../../common/colors';
import Arrow_Date_Icon from '../../../img/arrow-down.svg';

export const meetingStyles = makeStyles((theme: Theme) =>
  createStyles({
    meetingWrapper: {
      paddingTop: 30,

      '& .MuiButton-text': {
        padding: 5,
      },

      '& .MuiTypography-caption': {
        fontFamily: Fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: '0.018em',
        textTransform: 'uppercase',
        color: '#525454',
        margin: '0px 8px',
      },

      '& .MuiTypography-h3': {
        fontFamily: Fonts.POPPINS_FONT,
        fontWeight: 'bold',
        fontSize: 36,
        letterSpacing: '-0.022em',
        color: '#272929',
        margin: '17px 0px 50px',
      },
    },

    connectCalendar: {
      '&.MuiButton-root': {
        borderRadius: 12,
        fontSize: 14,
        fontWeight: 'bold',
        minWidth: 180,
        padding: '12px 13px',
        textTransform: 'capitalize',
        color: Colors.WHITE_ONE,
        backgroundColor: Colors.BLUE_SEVEN,
      },
    },

    meetingFilter: {
      marginBottom: 50,
      width: '100%',

      [theme.breakpoints.down('xs')]: {
        alignItems: 'flex-start',
        flexDirection: 'column',
      },

      '& select': {
        border: '1px solid #D4D4D4',
        height: 40,
        borderRadius: 6,
        width: 280,
        padding: '0px 12px',
        fontFamily: Fonts.INTER,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#A9A9A9',
        backgroundPosition: 'center right 12px',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${Arrow_Date_Icon})`,
      },
    },

    meetingFilterSearch: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: 400,
      height: 40,
      border: '1px solid #D4D4D4',
      borderRadius: 6,
      width: '100%',

      [theme.breakpoints.down('xs')]: {
        margin: '20px 0px 0px 0px',
      },

      '& .MuiInputBase-root': {
        width: '100%',

        '& input': {
          fontFamily: Fonts.INTER,
          fontSize: 14,
          letterSpacing: '-0.006em',
          color: '#A9A9A9',
        },
      },
    },

    iconButton: {
      width: 30,
      height: 30,
      margin: 12,
      color: '#D4D4D4',
    },
  })
);

export const meetingTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    meetingTable: {
      borderSpacing: '0px 9px',
      borderCollapse: 'separate',

      '& .MuiTableCell-head, .MuiTableCell-body': {
        whiteSpace: 'nowrap',
      },

      '& .cursor': {
        cursor: 'pointer',
      },
      '& tbody': {
        '& .MuiTableRow-root': {
          boxShadow: '0px 2px 7px rgba(68, 68, 68, 0.14)',
          borderRadius: 15,
        },

        '& a': {
          fontSize: 16,
          color: '#2F80ED',
          fontFamily: Fonts.INTER,
          letterSpacing: '-0.011em',
        },
      },

      '& .MuiTableCell-head': {
        border: 0,
        fontFamily: Fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: '0.018em',
        textTransform: 'uppercase',
        color: '#272929',
        padding: '4px 15px',
      },

      '& tr': {
        '& td': {
          '&:nth-child(2)': {
            fontSize: 16,
          },
        },
      },

      '& .MuiTableCell-body': {
        border: 0,
        fontFamily: Fonts.INTER,
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.011em',
        color: '#272929',
        borderTop: '1px solid #D4D4D4',
        borderBottom: '1px solid #D4D4D4',
        padding: '4px 15px',
        verticalAlign: 'middle',
        height: 52,

        '&:first-child': {
          borderRadius: '15px 0px 0px 15px',
          borderLeft: '1px solid #D4D4D4',
          fontSize: 16,
        },

        '&:last-child': {
          borderRadius: '0px 15px 15px 0px',
          borderRight: '1px solid #D4D4D4',
        },
      },

      '& .MuiIconButton-root': {
        width: 30,
        height: 30,
        padding: 3,
      },
    },

    emptyStates: {
      background: '#F4F4F4',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      padding: '32px 45px',
      width: '100%',

      '& .MuiTypography-body1': {
        fontSize: '16px',
        letterSpacing: '-0.011em',
        color: '#A9A9A9',
      },
    },

    emptyStatesRow: {
      padding: '0px !important',
      border: '0px !important',
      boxShadow: 'none !Important',
    },
  })
);

export const meetingTabsStyles = makeStyles((theme: Theme) => ({
    meetingTabs: {
      paddingBottom: 50,
  
      '& .MuiAppBar-colorPrimary': {
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
  
      '& .MuiTabs-indicator': {
        height: 2,
        backgroundColor: '#525454',
        minWidth: 84,
      },
  
      '& .MuiTab-textColorInherit': {
        fontFamily: Fonts.POPPINS_FONT,
        fontSize: 16,
        letterSpacing: '-0.006em',
        color: '#525454',
        textTransform: 'capitalize',
        padding: 0,
        minWidth: 84,
        marginRight: 30,
  
        '&.Mui-selected': {
          fontWeight: 600,
        },
      },
    },
  }));
