import moment from 'moment';
import React, { useContext, useState } from 'react';
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Typography,
  Box,
} from '@material-ui/core';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import TableActions from '../timeLogs/TableActions';

import { ALL_MENTOR_TIME_LOGS } from './gql';
import client from '../../../apollo';
import { allMentorTimeLogsQuery_allMentorTimeLogs_mentorTimeLogs } from './gql/__generated__/allMentorTimeLogsQuery';

import { useStylesTable } from '../timeLogs/tableCommonStyle';
import { AppContext } from '../../../contexts';
import { getNewLabelForWorkActivity } from '../../../common/utility';

const TableRecord = ({
  refetch,
  item,
  setMentorTimeLogs,
  setNote,
  SetSearchQuery,
}: {
  refetch: Function;
  setMentorTimeLogs: Function;
  setNote: Function;
  SetSearchQuery: Function;
  item: allMentorTimeLogsQuery_allMentorTimeLogs_mentorTimeLogs | null;
}) => {
  const { user } = useContext(AppContext);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(Date.now());
  const toggleKey = () => setKey(Date.now());

  const fetchMentorTimeLogs = async () => {
    try {
      setLoading(true);
      let query: any = {
        query: ALL_MENTOR_TIME_LOGS,
        fetchPolicy: 'network-only',
      };
      const {
        data: { allMentorTimeLogs },
      } = await client.query(query);
      SetSearchQuery('');
      if (allMentorTimeLogs?.mentorTimeLogs) {
        setMentorTimeLogs(allMentorTimeLogs?.mentorTimeLogs);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const tableClasses = useStylesTable();

  return (
    <>
      <TableRow
        className={
          item?.mentorPayout?.status === 'reversed'
            ? tableClasses.disablePayoutRow
            : ''
        }
      >
        <TableCell className="fontWeight textGray textLineThrough" align="left">
          {item?.activityAt &&
            `${moment
              .parseZone(item?.activityAt)
              .local()
              .format('MM/DD/YYYY')} `}
        </TableCell>
        <TableCell className="fontWeight textGray textLineThrough" align="left">
          {getNewLabelForWorkActivity(item?.workActivity)}
        </TableCell>
        <TableCell className="textGray textLineThrough" align="left">
          {item?.student?.firstName || item?.student?.lastName
            ? `${item?.student?.firstName} ${item?.student?.lastName}`
            : '--'}
        </TableCell>
        <TableCell className="textGray textLineThrough" align="left">
          {item?.amount ? '$' + (item?.amount / 100).toFixed(2) : '--'}
        </TableCell>
        <TableCell className="textGray" align="left">
          {item?.MentorPayoutId || item?.isFullTime
            ? 'Approved'
            : item?.mentorPayout?.status || 'Pending'}
        </TableCell>
        <TableCell>
          <TableActions
            timeLog={item}
            refetch={refetch}
            fetchMentorTimeLogs={fetchMentorTimeLogs}
            toggleKey={toggleKey}
            payoutId={
              item &&
              (item?.MentorPayoutId || (item?.isFullTime && !user?.isFullTime))
                ? true
                : false
            }
          />
        </TableCell>
        <TableCell>
          {(item?.note || item?.milestone || item?.serviceElement) && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setOpen(!open);
                setNote(item?.note || '');
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <TableRow className="collapseTableRow">
        <TableCell colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {item?.note && (
              <Box display="flex" alignItems="flex-start">
                <Typography variant="h6">NOTE: </Typography>
                <Typography variant="body1">{item?.note}</Typography>
              </Box>
            )}
            {item?.milestone && (
              <Box display="flex" alignItems="flex-start">
                <Typography variant="h6">MILESTONE: </Typography>
                <Typography variant="body1">{item?.milestone}</Typography>
              </Box>
            )}
            {item?.serviceElement && (
              <Box display="flex" alignItems="flex-start">
                <Typography variant="h6">SERVICE ELEMENT: </Typography>
                <Typography variant="body1">{item?.serviceElement}</Typography>
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TableRecord;
