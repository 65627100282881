import gql from 'graphql-tag';

export const GET_ALL_MILESTONE_TEAMS = gql`
  query getMilestoneTeamOptions($mentorId: Int, $isFullTime: Boolean) {
    getMilestoneTeamOptions(
      input: { mentorId: $mentorId, isFullTime: $isFullTime }
    ) {
      value
      label
    }
  }
`;
