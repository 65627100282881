import gql from 'graphql-tag';

export const CREATE_STUDENT_APPLICATION_MUTATION = gql`
  mutation CreateStudentApplicationMutation(
    $collegeIds: [Int!]!
    $intakeToken: String
    $decision: String
    $decisionDate: String
    $userToken: String
    $StudentId: Int
  ) {
    createStudentApplication(
      input: {
        collegeIds: $collegeIds
        intakeToken: $intakeToken
        decision: $decision
        decisionDate: $decisionDate
        userToken: $userToken
        StudentId: $StudentId
      }
    ) {
      status
      applicationId
      isCampusAdded
      collegeId
      studentApplication {
        id
        createdInSurvey
      }
    }
  }
`;
