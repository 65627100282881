import { FC } from 'react';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import CLOSE_GRAY_IMG from '../img/alert_cross.svg';

import Fonts from '../common/fonts';
import Colors from '../common/colors';
import { Typography } from '@material-ui/core';

interface PropsType {
  variant?: string;
  message: string;
  toggleAlert: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fabClose: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      width: '35px',
      height: '30px',
      position: 'absolute',
      right: '2px',
      top: '5px',

      '& img': {
        margin: '0px !important',
      },

      '&:hover': {
        backgroundColor: 'transparent',
      },

      '&:focus': {
        outline: 'none',
      },
    },

    alertBox: {
      padding: '12px',
      position: 'relative',
      marginBottom: '10px',
      borderRadius: '4px',
      textAlign: 'left',
    },

    alertMessage: {
      color: `${Colors.WHITE_ONE}`,
      fontFamily: `${Fonts.POPPINS_FONT}`,
      paddingRight: '20px',
    },
  })
);

const CustomAlert: FC<PropsType> = ({
  variant,
  message,
  toggleAlert,
}) => {
  const classes = useStyles();

  const showAlert = !!(variant && message);
  const variantColor = variant === 'success' ? '#27AE60' : '#eb5757';
  return (
    <>
      {showAlert && (
        <Grid
          className={classes.alertBox}
          style={{ backgroundColor: variantColor }}
        >
          <Fab onClick={toggleAlert} className={classes.fabClose}>
            <img src={CLOSE_GRAY_IMG} alt="close" />
          </Fab>

          <Typography
            variant="body1"
            component="p"
            className={classes.alertMessage}
          >
            {message}
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default CustomAlert;
