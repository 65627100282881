import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import fonts from '../../../common/fonts';

import SELECT_ARROW_IMG from '../../../img/select-arrow.svg';
import CALENDAR_ICON from '../../../img/calendar-icon-opp.svg';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leadFormHeader: {
      '& .MuiTypography-h2': {
        margin: '26px 0px 58px',
        fontWeight: 'bold',
        fontSize: 36,
        letterSpacing: '-0.022em',
        color: '#0A2463',
        fontFamily: fonts.POPPINS_FONT,

        [theme.breakpoints.down('xs')]: {
          margin: '26px 0px',
          fontSize: 24,
        },
      },
    },

    salesLeadForm: {
      maxWidth: 818,
      padding: '36px 33px',
      margin: '42px auto',
      borderRadius: 25,
      border: '1px solid #D4D4D4',
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

      [theme.breakpoints.down('sm')]: {
        maxWidth: '90%',
        padding: '36px 15px',
      },
    },

    summerOpportunityForm: {
      '& .secondaryHeading': {
        fontFamily: fonts.INTER,
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#272929',
        marginBottom: 36,
      },

      '& .fieldCaption, & .MuiInputLabel-formControl': {
        fontFamily: fonts.INTER,
        fontWeight: 400,
        fontSize: '14px !important',
        letterSpacing: '-0.006em',
        color: '#272929',
        marginBottom: 4,
      },

      '& .MuiInputLabel-formControl': {
        transform: 'translate(0, 1.5px) scale(1)',
      },

      '& .fieldHelperText': {
        fontFamily: fonts.INTER,
        fontWeight: 400,
        fontSize: 12,
        color: '#A9A9A9',
        marginTop: 4,
        display: 'block',
      },

      '& input, & textarea': {
        width: '100%',
        borderRadius: 6,
        fontWeight: 400,
        fontSize: 14,
        color: '#272929',
        padding: '9px 16px',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
        border: '1px solid #D4D4D4',

        '&::placeholder': {
          color: '#A9A9A9',
          fontWeight: 400,
          fontSize: 14,
        },
      },

      '& .MuiInput-underline': {
        '& .MuiSelect-selectMenu': {
          width: 107,
          borderRadius: 6,
          fontWeight: 400,
          fontSize: 14,
          color: '#272929',
          padding: '10px 16px',
          fontFamily: fonts.INTER,
          letterSpacing: '-0.006em',
          boxSizing: 'border-box',
          border: '1px solid #D4D4D4',
          backgroundImage: `url(${SELECT_ARROW_IMG})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center right 18px',

          [theme.breakpoints.down('xs')]: {
            width: '100%',
          },
        },

        '& .MuiSvgIcon-root': {
          display: 'none',
        },
      },

      '& .MuiInputBase-root': {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },

      '& .dateField': {
        '& .MuiInputBase-input': {
          padding: '11.2px 16px',
          backgroundImage: `url(${CALENDAR_ICON})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center right 15px',
        },
      },

      '& .MuiInput-underline:after, & .MuiInput-underline:before': {
        display: 'none',
      },

      '& .betweenSpacing': {
        marginBottom: 31,
      },

      '& .dividerSpacing': {
        '& .MuiDivider-root': {
          backgroundColor: '#D4D4D4',
          margin: '75px 0px 35px',
        },
      },

      '& .dividerSpacingLocation': {
        '& .MuiDivider-root': {
          margin: '32px 0px 0px !important',
        },
      },

      '& .checkboxSectionSpacing': {
        fontFamily: fonts.INTER,
        fontWeight: 400,
        fontSize: 14,
        color: '#272929',
        letterSpacing: '-0.006em',
        marginBottom: 21,
      },

      '& .checkboxGroup': {
        paddingBottom: 35,

        '& .MuiFormGroup-row': {
          '& .MuiFormControlLabel-label': {
            fontFamily: fonts.INTER,
            fontWeight: 400,
            fontSize: 16,
            color: '#525454',
            letterSpacing: '-0.011em',
          },
        },

        '& .MuiFormControlLabel-root': {
          marginBottom: 0,
        },

        '& .MuiCheckbox-root.Mui-checked': {
          color: '#7D7f7f',
        },

        '& .MuiIconButton-colorSecondary:hover': {
          backgroundColor: 'rgba(125, 127, 127, 0.1)',
        },
      },

      '& .locationDateSection': {
        '& .select__control': {
          fontFamily: fonts.INTER,
          fontSize: 14,
          fontWeight: 400,
        },

        '& .select__option': {
          fontFamily: fonts.INTER,
          fontSize: 14,
        },

        '& .select__placeholder': {
          color: '#A9A9A9',
          fontSize: 14,
          fontWeight: 400,
        },

        '& .remoteOpportunity': {
          marginBottom: 16,
        },

        '& .checkboxGroup': {
          paddingBottom: 0,
        },

        '& .secondaryHeading': {
          marginBottom: 0,
        },

        '& .betweenSpacing': {
          marginBottom: 21,
        },
      },

      '& .formDatePickerMobile': {
        [theme.breakpoints.down('xs')]: {
          marginTop: 31,
        },
      },

      '& .formDatePicker': {
        '& .MuiFormControl-root': {
          [theme.breakpoints.down('xs')]: {
            width: '100%',
          },
        },

        '& input': {
          border: 0,
          borderRadius: 0,
        },

        '& .MuiInputBase-root': {
          border: '1px solid #D4D4D4',
          borderRadius: 6,
          height: 41,
        },

        '& .MuiIconButton-root': {
          padding: 0,
          width: 30,
          height: 30,
          marginRight: 1,
          backgroundPosition: 'center',
          backgroundImage: `url(${CALENDAR_ICON})`,
          backgroundRepeat: 'no-repeat',
        },
      },

      '& .MuiCollapse-entered': {
        paddingBottom: 25,
      },
    },

    addNewLocation: {
      fontFamily: fonts.POPPINS_FONT,
      fontWeight: 600,
      fontSize: 12,
      letterSpacing: '0.018em',
      padding: '8px 10px !important',

      '& img': {
        width: 16,
        marginRight: 15,
      },
    },

    formFooterDivider: {
      marginTop: 36,
      paddingTop: 44,
      borderTop: '1px solid #D4D4D4',
      textAlign: 'center',

      '& .MuiButton-contained': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 'bold',
        fontSize: 14,
        letterSpacing: '0.018em',
        minWidth: 185,
        height: 40,
        background: '#2F80ED',
        borderRadius: 12,
        textTransform: 'capitalize',
      },
    },

    thankYouCard: {
      maxWidth: 818,
      padding: '39px 26px 21px 44px',
      margin: '42px auto',
      borderRadius: 25,
      border: '1px solid #D4D4D4',
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

      [theme.breakpoints.down('sm')]: {
        maxWidth: '90%',
        padding: '36px 15px',
      },

      '& .MuiTypography-h3': {
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '-0.019em',
        color: '#272929',
        marginBottom: 16,
        fontFamily: fonts.POPPINS_FONT,

        [theme.breakpoints.down('xs')]: {
          fontSize: 16,
        },
      },

      '& .MuiTypography-body1': {
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#7D7f7f',
        marginBottom: 16,
        fontFamily: fonts.INTER,

        [theme.breakpoints.down('xs')]: {
          fontSize: 14,
        },
      },

      '& a': {
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '0.006em',
        color: '#2F80ED',
        fontFamily: fonts.POPPINS_FONT,
        padding: '8px 0px',
      },

      '& .contentPadding': {
        paddingLeft: 14,
      },
    },

    opportunityType: {
      width: 280,

      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },

      '& .MuiSelect-select.MuiSelect-select': {
        width: '100% !important',
      },
    },

    deadlineType: {
      width: 213,

      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },

      '& .MuiSelect-select.MuiSelect-select': {
        width: '100% !important',
      },
    },

    metroArea: {
      width: 481,

      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },

      '& .MuiSelect-select.MuiSelect-select': {
        width: '100% !important',
      },
    },
  })
);
