import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box, Container } from '@material-ui/core';

import Colors from '../../common/colors';
import Fonts from '../../common/fonts';

import TWITTER from '../../img/twitter.svg';
import FACEBOOK from '../../img/facebook.svg';
import INSTAGRAM from '../../img/instagram.svg';
import YOUTUBE from '../../img/youtube.svg';
import LINKEDIN from '../../img/linkedin.svg';

import { CURRENT_YEAR } from '../../constants';
import { footerStyles } from './FooterStyles';

const SOCIAL_LINKS = [
  {
    image: TWITTER,
    alt: 'twitter icon',
    link: 'https://twitter.com/_collegeadvisor',
  },
  {
    image: FACEBOOK,
    alt: 'facebook icon',
    link: 'https://facebook.com/joincollegeadvisor',
  },
  {
    image: INSTAGRAM,
    alt: 'instagram icon',
    link: 'http://instagram.com/joincollegeadvisor',
  },
  {
    image: YOUTUBE,
    alt: 'youtube icon',
    link: 'https://www.youtube.com/channel/UCXAXoQUE8N-kS6h-cjf4DUg',
  },
  {
    image: LINKEDIN,
    alt: 'linkedin icon',
    link: 'http://linkedin.com/company/joincollegeadvisor',
  },
];

const Footer: FC = () => {
  const classes = footerStyles();

  return (
    <footer className={classes.footerWrapper}>
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="15px 0px"
          className={classes.footerContainer}
        >
          <Box
            color={Colors.SKYBLUE_TWO}
            fontFamily={Fonts.POPPINS_FONT}
            maxWidth={250}
          >
            &copy; {CURRENT_YEAR} USN College Advisor, Inc.
            <br />
            All Rights Reserved
          </Box>

          <Box
            display="flex"
            flex={1}
            justifyContent="center"
            className={classes.footerLinks}
          >
            <div
              onClick={() => {
                if (window.Intercom) window.Intercom('show');
              }}
            >
              CONTACT
            </div>

            <Link to="/help">HELP</Link>
            <a
              href="https://www.collegeadvisor.com/terms-and-conditions/"
              target="_blank"
              rel="noreferrer"
            >
              Terms and Conditions
            </a>
            <a
              href="https://www.collegeadvisor.com/privacy-policy/"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            className={classes.socialIcons}
          >
            {SOCIAL_LINKS.map((item) => (
              <a
                href={item.link}
                target="_blank"
                rel="noreferrer"
                key={item.alt}
              >
                <img src={item.image} alt={item.alt} />
              </a>
            ))}
          </Box>
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;
