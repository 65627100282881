import { TablePagination, Box } from '@material-ui/core';
import React, { useEffect, useState, FC, Fragment } from 'react';

import {
  MENTOR_TIME_LOGS as MENTOR_TIME_LOG_RESPONSE,
  MENTOR_TIME_LOGS_allMentorTimeLogs_mentorTimeLogs,
} from '../students/gql/__generated__/MENTOR_TIME_LOGS';

import { useStyles } from './styles';
import { STUDENT_MENTOR_TIME_LOGS } from './gql';
import StudentTableRow from './StudentTableRow';
import moment from 'moment';

interface Props {
  client: any;
  searchQuery?: string;
  to?: Date | null;
  from?: Date | null;
  StudentId?: number | '' | undefined;
  mentorId?: number;
  selectedAdvisorId?: number;
  milestone?: string | null;
}

const StudentTimeLogsTable: FC<Props> = ({
  client,
  searchQuery,
  to,
  from,
  StudentId,
  selectedAdvisorId,
  milestone,
}) => {
  const classes = useStyles();
  const [mentorTimeLogs, setMentorTimeLogs] = useState<
    (MENTOR_TIME_LOGS_allMentorTimeLogs_mentorTimeLogs | null)[] | null
  >(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [mentorTimeLogsCount, setMentorTimeLogsCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchMentorTimeLogs = async () => {
    try {
      setLoading(true);
      let query: any = {
        query: STUDENT_MENTOR_TIME_LOGS,
        variables: {
          page: page + 1,
          limit: rowsPerPage,
          fromActivityAt: moment().format('YYYY-MM-DD'),
          sortBy: 'activityAt',
          sortType: 'DESC'
        },
        fetchPolicy: 'network-only',
      };

      if (StudentId) {
        query.variables = { ...query.variables, StudentId: StudentId };
      }
      if (to) {
        query.variables = { ...query.variables, toActivityAt: moment(to).format('YYYY-MM-DD') };
      }
      if (from) {
        query.variables = { ...query.variables, fromActivityAt: moment(from).format('YYYY-MM-DD') };
      }
      if (selectedAdvisorId) {
        query.variables = {
          ...query.variables,
          MentorId: selectedAdvisorId,
        };
      }
      if (searchQuery) {
        query.variables = { ...query.variables, searchQuery: searchQuery };
      }
      if (milestone) {
        query.variables = {
          ...query.variables,
          milestone: milestone,
        };
      }

      const {
        data,
        data: { allMentorTimeLogs },
      }: {
        data: MENTOR_TIME_LOG_RESPONSE;
      } = await client.query(query);
      if (
        data &&
        data.allMentorTimeLogs &&
        data.allMentorTimeLogs.mentorTimeLogs
      ) {
        setMentorTimeLogs(data.allMentorTimeLogs.mentorTimeLogs);
      }
      setMentorTimeLogsCount(
        allMentorTimeLogs && allMentorTimeLogs.mentorTimeLogsCount
          ? allMentorTimeLogs.mentorTimeLogsCount
          : 0
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const mountEffect = () => {
    fetchMentorTimeLogs();
  };

  useEffect(mountEffect, [
    page,
    searchQuery,
    to,
    from,
    StudentId,
    selectedAdvisorId,
    milestone,
  ]);

  useEffect(() => {
    setPage(0);
  }, [searchQuery, to, from]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box style={{ overflowX: 'auto' }} width="100%">
      <Box className={classes.timeLogsTable}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box className="tableCellHead tableCellFirst">ACTIVITY DATE</Box>

          <Box className="tableCellHead tableCellSecond">Work Type</Box>

          <Box className="tableCellHead tableCellThird">Activity </Box>

          <Box className="tableCellHead tableCellFour">Duration</Box>

          <Box className="tableCellHead tableCellFive">Advisor</Box>

          <Box
            className="tableCellHead tableCellSix"
            paddingLeft="0px !important"
          ></Box>

          <Box className="tableCellHead tableCellSeven"></Box>
        </Box>

        <Box>
          {loading ? (
            <Box textAlign="center" className={classes.tableEmptyGrid}>
              Loading...
            </Box>
          ) : (
            ''
          )}

          {!loading && mentorTimeLogs && mentorTimeLogs.length ? (
            mentorTimeLogs.map((item: any, index: any) => {
              let mins = item.activityDuration % 60;
              return (
                <StudentTableRow
                  key={index}
                  index={index}
                  item={item}
                  mins={mins}
                />
              );
            })
          ) : (
            <>
              {!loading && (!mentorTimeLogs || !mentorTimeLogs.length) && (
                <Box textAlign="center" className={classes.tableEmptyGrid}>
                  No Record found!
                </Box>
              )}
            </>
          )}

          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={mentorTimeLogsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default StudentTimeLogsTable;
