import { useState } from 'react';
import { Mutation } from 'react-apollo';
import { IconButton, CircularProgress, Box } from '@material-ui/core';
import { DELETE_TIME_LOG } from './gql';
import {
  DeleteTimeLog,
  DeleteTimeLogVariables,
} from './gql/__generated__/DeleteTimeLog';
import EDIT_PAYOUT_ICON from '../../../img/edit-payouts.svg';
import DELETE_PAYOUT_ICON from '../../../img/delete-payouts.svg';
import ConfirmationDialog from './ConfirmationDialog';
import EditDialog from './editTimelogModal/EditDialog';

const TableActions = ({
  refetch,
  toggleKey,
  timeLog,
  payoutId,
}: {
  fetchMentorTimeLogs: Function;
  toggleKey: Function;
  refetch: Function;
  timeLog: any;
  payoutId: boolean;
}) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const handleEditDialog = () => {
    setOpenEditDialog(!openEditDialog);
  };
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Mutation<DeleteTimeLog, DeleteTimeLogVariables>
          mutation={DELETE_TIME_LOG}
        >
          {(deleteTimeLog: Function, { loading: waiting }: any) => {
            const handleDelete = async () => {
              await deleteTimeLog({
                variables: {
                  id: timeLog.id,
                },
              });
              refetch();
              toggleKey();
            };
            return (
              <>
                <ConfirmationDialog
                  open={open}
                  toggleOpen={toggleOpen}
                  handleDelete={handleDelete}
                />
                <IconButton
                  hidden={payoutId}
                  aria-label="delete"
                  onClick={toggleOpen}
                >
                  {waiting ? (
                    <CircularProgress size={23} />
                  ) : (
                    <img src={DELETE_PAYOUT_ICON} alt="delete" />
                  )}
                </IconButton>
              </>
            );
          }}
        </Mutation>
        <IconButton
          aria-label="edit"
          onClick={handleEditDialog}
        >
          <img src={EDIT_PAYOUT_ICON} alt="edit" />{' '}
        </IconButton>
      </Box>
      <EditDialog
        open={openEditDialog}
        item={timeLog}
        closeDialog={handleEditDialog}
        refetch={refetch}
        isDisabledState={payoutId}
      />
    </>
  );
};

export default TableActions;
