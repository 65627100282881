import { createStyles, makeStyles, Theme } from '@material-ui/core';

import fonts from '../../../common/fonts';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bodyWrapper: {
      minHeight: 'calc(100vh - 484px)',

      '& .MuiTypography-h5': {
        fontSize: 24,
        lineHeight: '32px',
        color: '#272929',
        fontWeight: 600,
        letterSpacing: '-0.019em',
        fontFamily: fonts.POPPINS_FONT,
      },
    },
  })
);
