import gql from 'graphql-tag';

export const ADVISOR_PAYMENT_HISTORY = gql`
  query AdvisorPaymentHistoryQuery {
    advisorPaymentHistory {
      paidAt
      payoutId
      amount
    }
  }
`;
