import { Box, Grid } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useStyles } from './ListStyle';
import OpportunityCard from './OpportunityCard';
import {
  AllSummerOpportunitiesQuery_allSummerOpportunities_summerOpportunities,
  AllSummerOpportunitiesQuery_allSummerOpportunities_summerOpportunities_summerOpportunityCategory,
  AllSummerOpportunitiesQuery_allSummerOpportunities_summerOpportunities_summerOpportunityType,
} from './BookingMeeting/gql/__generated__/AllSummerOpportunitiesQuery';
import { LocationInterface } from '../../../common/interfaces';
import {
  UserActivityActions,
  UserActivityType,
  isValueBetween,
  logUserActivity,
} from '../../../constants';
import client from '../../../apollo';
import { SUMMER_OPPORTUNITY_CATEGORIES } from './BookingMeeting/gql';

interface Props {
  opportunities:
    | (AllSummerOpportunitiesQuery_allSummerOpportunities_summerOpportunities | null)[]
    | null;
  title?: string;
  isSearching?: boolean;
  refetch?: Function;
  loadMore?: Function | false;
}

type ObjectType = {
  region: (number | null)[];
  type: AllSummerOpportunitiesQuery_allSummerOpportunities_summerOpportunities_summerOpportunityType | null;
  category: AllSummerOpportunitiesQuery_allSummerOpportunities_summerOpportunities_summerOpportunityCategory | null;
  state: (string | null)[];
} | null;

const ListOpportunities: FC<Props> = ({
  opportunities,
  title,
  refetch,
  loadMore,
}) => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [valuesObject, setValuesObject] = useState<ObjectType>(null);
  const urlParams = new URLSearchParams(window.location.search);
  const categoryFromUrl = urlParams.get('category');
  const cityFromUrl = urlParams.get('city');
  const pathname = window.location.pathname;
  const mountEffect = () => {
    const apiCalling = async () => {
      let Cvalue = null,
        Tvalue = null;
      let Svalue: (string | null)[] = [];
      let Rvalue: (number | null)[] = [];
      if (categoryFromUrl) {
        const categoryApiCall = await client.query({
          query: SUMMER_OPPORTUNITY_CATEGORIES,
          variables: { name: categoryFromUrl },
        });
        Cvalue =
          categoryApiCall.data.allSummerOpportunityCategories
            .summerOpportunityCategories[0];
      }
      let obj = {
        region: Rvalue,
        category: Cvalue,
        type: Tvalue,
        state: Svalue,
      };
      if (!Rvalue.length && cityFromUrl) obj = { ...obj, region: [null] };
      JSON.stringify(valuesObject) !== JSON.stringify(obj) &&
        setValuesObject(obj);
    };
    apiCalling();
  };
  useEffect(mountEffect);

  const { search, state }: LocationInterface = useLocation();

  useEffect(() => {
    loader === false && setLoader(true);
    const query = new URLSearchParams(search);
    const origin = query.get('origin');
    const payload = {
      pageName: pathname,
      action: UserActivityActions.SEARCH,
      type: UserActivityType.SUMMER_OP,
      origin: origin || state?.from || null,
    };
    logUserActivity(payload);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const scrollHandler = () => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  };

  let timeoutId: any = 0;

  const handleScroll = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      const {
        documentElement: { offsetHeight },
        scrollingElement,
      } = document;
      const scrollHeight = scrollingElement ? scrollingElement.scrollTop : 0;
      if (
        isValueBetween(
          scrollHeight + window.innerHeight,
          offsetHeight - window.innerHeight,
          offsetHeight + 50
        ) &&
        loadMore
      ) {
        loadMore();
      }
    }, 200);
  };

  useEffect(scrollHandler, []);

  return (
    <Grid className={classes.bodyWrapper}>
      <>
        {opportunities && opportunities.length ? (
          <>
            <Grid container spacing={2}>
              {opportunities.map(
                (item) =>
                  item && (
                    <Grid
                      item
                      lg={3}
                      md={4}
                      sm={6}
                      xs={12}
                      key={item.id ? item.id : Math.random()}
                      style={{ cursor: 'pointer' }}
                    >
                      <OpportunityCard
                        organizationLogo={item?.organizationLogo || ''}
                        programStartDate={item?.programStartDate || ''}
                        summerOpportunityCategory={
                          item?.summerOpportunityCategory || null
                        }
                        isRemote={item?.isRemote}
                        programEndDate={item?.programEndDate || ''}
                        id={item.id}
                        organizationName={item.organizationName || ''}
                        programName={item.programName || ''}
                        programDescription={item.programDescription || ''}
                        applicationDeadLine={item.applicationDeadLine || ''}
                        targetAge={item?.targetGrade || null}
                        city={item?.city || ''}
                        state={item?.state || ''}
                        isBookMarked={item?.isBookMarked || false}
                        sessionStart={item?.summerOpportunitySessionStart}
                        summerOpportunitySessionStart={
                          item?.summerOpportunitySessionStart
                        }
                      />
                    </Grid>
                  )
              )}
            </Grid>
          </>
        ) : (
          <Box component="h4" textAlign="center">
            No Record Found
          </Box>
        )}
      </>
    </Grid>
  );
};

export default ListOpportunities;
