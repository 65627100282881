import { FC } from 'react';
import { customStyles } from '../../../styles/baseStyle';
import { appStatusPrevValue } from '../../../common/utility';
import AsyncSelect from 'react-select/async';
import client from '../../../apollo';
import { useCommonStyles } from '../../../common/commonStyles';
import { CAStatusProps } from '../../../common/interfaces';
import { optionsDecision } from '../../../constants';
import { CREATE_APPLICATION_ACCEPTANCE_SURVEY } from '../../../graphql/mutations/StudentDetailPage';
type LabelValueObject = {
  value: string,
  label: string,
}
const ChangeApplicationStatus: FC<CAStatusProps> = ({
  userId,
  collegeId,
  refetch,
  campusesRow,
  appId,
  appDecision,
  setLoading,
  loading,
  handleNotificationPopup
}) => {
  const dropStyle = useCommonStyles();
  const loadOptions: any = () => {
    const options = optionsDecision;
    return new Promise((resolve) => {
      resolve(options);
    });
  };
  const campuseDecisionFilter = campusesRow?.length > 0 && campusesRow?.find((item: { id: number }) => item.id === collegeId);
  const campusDecision = campuseDecisionFilter?.decision;
  const handleInputChange = async (selectedValue: LabelValueObject | null) => {
    try {
      if (!collegeId) {
        const decisionObj = [
          {
            applicationId: appId,
            decision: selectedValue?.value,
          },
        ];
        setLoading(!loading);
        await client.mutate({
          mutation: CREATE_APPLICATION_ACCEPTANCE_SURVEY,
          variables: {
            id: userId && userId,
            decision: decisionObj,
            autoSaveAt: new Date(),
          },
        });
      } else {
        const decisionObj = [
          {
            applicationId: appId,
            collegeId: collegeId && collegeId,
            decision: selectedValue?.value,
          },
        ];
        setLoading(!loading);
        await client.mutate({
          mutation: CREATE_APPLICATION_ACCEPTANCE_SURVEY,
          variables: { id: userId && userId, decisionCampuses: collegeId && decisionObj, autoSaveAt: new Date() },
        });
      }
      refetch && (await refetch());
      handleNotificationPopup("Status updated successfully")
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(loading);
    }
  };
  return (
    <>
      <div className={dropStyle.decisionSelect}>
        <AsyncSelect
          styles={customStyles}
          cacheOptions
          defaultOptions
          isMulti={false}
          isSearchable={false}
          defaultValue={appStatusPrevValue(appDecision || campusDecision)}
          placeholder="Select Decision"
          classNamePrefix={'react-select'}
          loadOptions={loadOptions}
          className="react-select-container"
          onChange={handleInputChange}
        />
      </div>
    </>
  );
};

export default ChangeApplicationStatus;
