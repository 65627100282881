// imports
import { FC, ReactNode } from 'react';
import { Box, Typography } from '@material-ui/core';
// styles
import { useStyles } from './styles';
// images
import WarningAlertIcon from '../img/exc_vector.svg';

const AccountLockedAlert: FC<{ title: string; subTitle: ReactNode }> = ({
  title,
  subTitle,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.accountLockedAlert}>
      <img src={WarningAlertIcon} alt="alert" />

      <Box width="100%" display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h4">{title}</Typography>
        </Box>

        <Typography variant="body1">{subTitle}</Typography>
      </Box>
    </Box>
  );
};

export default AccountLockedAlert;
