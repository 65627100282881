import React, { useEffect, useContext, useState, useRef } from 'react';
import { Query } from 'react-apollo';

import {
  Grid,
  MenuItem,
  InputBase,
  Menu,
  IconButton,
  Box,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import { useStyleSelect } from './customSelectStyle';
import { AppContext } from '../../../contexts';

import { MENTOR_ADVISOR_STUDENTS_QUERY } from './gql';
import { MentorAdvisingStudentsQuery } from './gql/__generated__/MentorAdvisingStudentsQuery';

interface OptionType {
  value: number;
  label: string;
  initialKickOffDate: string | null;
}

const CustomSearchSelect = ({
  value,
  setSelectedValue,
  setMentorStudentIds,
  disable,
  isClearable,
  placeholder,
  milestoneTeam = '',
}: {
  setSelectedValue: Function;
  setMentorStudentIds?: Function;
  value?: OptionType;
  disable?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  milestoneTeam?: string;
}) => {
  const inputRef = useRef<any>(null);

  const selectClasses = useStyleSelect();
  const { user } = useContext(AppContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [advisorStudents, setAdvisorStudents] = useState<OptionType[]>();
  const [otherStudents, setOtherStudents] = useState<OptionType[]>();

  const [selectedStudent, setSelectedStudent] = useState<{
    value: number | null;
    label: string;
    initialKickOffDate: string | null;
  }>();
  const [searchValue, setSearchValue] = useState('');
  const [associatedStudentIds, setAssociatedStudentIds] = useState<
    (number | null)[]
  >([]);
  let timeoutId: any = 0;

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleSelect = (e: any) => {
    setAnchorEl(null);
    if (e.target.value) {
      const value =
        advisorStudents?.find((item) => item.value === e.target.value) ||
        otherStudents?.find((item) => item.value === e.target.value);
      setSelectedStudent({
        value: e.target.value,
        label: value?.label || '',
        initialKickOffDate: value?.initialKickOffDate || null,
      });
      setSelectedValue({
        value: e.target.value,
        label: value?.label || '',
        initialKickOffDate: value?.initialKickOffDate,
      });
      if (associatedStudentIds?.length && setMentorStudentIds) {
        setMentorStudentIds(associatedStudentIds);
      }
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = () => {
    setAdvisorStudents([]);
    setOtherStudents([]);
  };

  const handleClear = () => {
    setSelectedValue({ value: '', label: '', initialKickOffDate: null });
    setSelectedStudent({ value: null, label: '', initialKickOffDate: null });
  };

  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.firstChild?.focus();
    }
  }, [anchorEl]);

  return (
    <Query<MentorAdvisingStudentsQuery>
      query={MENTOR_ADVISOR_STUDENTS_QUERY}
      variables={{
        searchQuery: searchValue,
        MentorId: user?.id || null,
        milestoneTeam,
      }}
      fetchPolicy="network-only"
      onCompleted={(data) => {
        if (data && data?.mentorAdvisingStudents?.students?.length) {
          let options: OptionType[] =
            data?.mentorAdvisingStudents?.students.map((item) => ({
              value: item?.id || 0,
              label:
                `${item?.firstName} ${item?.lastName} (${item?.email || ''})` ||
                '',
              initialKickOffDate: item?.initialKickOffDate || null,
            }));

          const ids = data.mentorAdvisingStudents?.mentorStudentIds;
          const advStds = options?.filter((item) => ids?.includes(item?.value));
          const otherStds = options?.filter(
            (item) => !ids?.includes(item?.value)
          );

          if (ids?.length) setAssociatedStudentIds(ids);
          setAdvisorStudents(advStds);
          setOtherStudents(otherStds);
        }
      }}
    >
      {({ loading }) => {
        return (
          <Grid className={selectClasses.customSearchSelect}>
            <Box position="relative">
              <InputBase
                disabled={disable}
                placeholder={placeholder || 'Select Student'}
                onClick={(e) => !disable && handleClick(e)}
                value={value?.label || selectedStudent?.label}
                onFocus={(event) => event?.target.setSelectionRange(0, 0)}
              />
              {isClearable && (value?.label || selectedStudent?.label) && (
                <IconButton
                  className={selectClasses.iconClear}
                  onClick={handleClear}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </Box>
            <Menu
              id="searchStudent"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              elevation={0}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <MenuItem className="inputWrapper">
                <InputBase
                  ref={inputRef}
                  placeholder="Search All Students"
                  onChange={(e) => {
                    handleSearch()
                    clearTimeout(timeoutId)
                    timeoutId = setTimeout(() => {
                      setSearchValue(e.target.value)
                    }, 500);
                  }}
                  className="seachInputFilter"
                />
              </MenuItem>
              <Grid className="searchScrollContent">
                {!!advisorStudents?.length && (
                  <>
                    <MenuItem className="sectionHeading">My Students</MenuItem>
                    {advisorStudents.map((item) => (
                      <MenuItem
                        onClick={handleSelect}
                        className={
                          (value?.value || selectedStudent?.value) ===
                          item.value
                            ? 'sectionItems active'
                            : 'sectionItems'
                        }
                        value={item.value}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </>
                )}
                {!!otherStudents?.length && searchValue && (
                  <>
                    <MenuItem className="sectionHeading">All Students</MenuItem>
                    {otherStudents.map((item) => (
                      <MenuItem
                        onClick={handleSelect}
                        className={
                          value?.value === item.value
                            ? 'sectionItems active'
                            : 'sectionItems'
                        }
                        value={item.value}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </>
                )}
                {!!!advisorStudents?.length &&
                  !!!otherStudents?.length &&
                  !loading && (
                    <MenuItem
                      onClick={handleClose}
                      className="sectionItems"
                      disabled
                    >
                      No Record
                    </MenuItem>
                  )}
                {loading && (
                  <MenuItem
                    onClick={handleClose}
                    className="sectionItems"
                    disabled
                  >
                    Loading ...
                  </MenuItem>
                )}
              </Grid>
            </Menu>
          </Grid>
        );
      }}
    </Query>
  );
};

export default CustomSearchSelect;
