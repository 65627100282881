import { FC, useState } from 'react';
import {
  Grid,
  Typography,
  Tooltip,
  Box,
  Button,
  MenuItem,
  FormControl,
  Select,
  Container,
  Divider,
} from '@material-ui/core';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Colors from '../../../common/colors';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import Header from '../../../components/layout/Header';
import USER_ICON from '../../../img/empty-badge.svg';
import { StudentDetailQueryIntake } from './__generated__/StudentDetailQueryIntake';

import Chat_Icon from '../../../img/chat-icon-white.svg';
import Breadcrumb_Arrow_Icon from '../../../img/breadcrumb_arrow.svg';

import { useStyles } from './MyStudentsStyle';
import moment from 'moment';
import { useTooltipStyles } from '../../../components/CustomTooltip';

export const STUDENT_DETAIL_QUERY = gql`
  query StudentDetailQueryIntake($studentId: Int!) {
    Student(studentId: $studentId) {
      id
      firstName
      lastName
      email
      pictureUrl
      highSchool
      highSchoolYear
      gpa
      roles
      sat
      act
      phoneNumber
      isPremium
      sendBirdChannelUrl
      applyingYear
      premiumPackageType
      premiumTotalEssayCount
      premiumEssayCountUsed
      premiumTotalVideoCount
      premiumVideoCountUsed
      intakeResponse
      intakeFormToken
      premiumEntitlements {
        id
        entitlementType
        totalEntitlement
        entitlementCountUsed
        startedAt
        completedAt
      }

      parents {
        id
        firstName
        lastName
        roles
        email
        phoneNumber
        highSchool
        intakeResponse
        intakeFormToken
      }

      allStudentMentors {
        mentor {
          firstName
          lastName
          email
          phoneNumber
          advisorLevel
        }
      }

      studentApplications {
        decisionStatus
        applicationRequirement {
          college {
            id
            name
            admissionPageUrl
            logo
            styleConfig {
              bgDark
              bgLight
            }
          }
          regularDecisionDeadline
          year
        }
        completionPercentage

        studentApplicationEssays {
          id
          name
          status
          documentUrl
          completedAt
          essayTemplate {
            id
            required
            commonEssay
            lengthLimit
            specificSchool
            requirementNote
            longDescription
            shortDescription
            templateYear
          }
        }
      }
      intakeFormQuestions {
        intakeFormQuestion {
          title
        }
        response
      }
    }
  }
`;

type TParams = { id: string; formToken: string };
const IntakeFormQA: FC<RouteComponentProps<TParams>> = ({ match }) => {
  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();
  const {
    params: { id, formToken },
  } = match;
  const studentId = parseInt(id);

  const renderText = (text: string | null, title: string) => (
    <Box marginBottom={1} color={Colors.WHITE_ONE}>
      <strong>{title}</strong>: {text}
    </Box>
  );
  const history = useHistory();
  const [name, setName] = useState('');

  const handleChange = (event: any) => {
    setName(event.target.value);
  };

  return (
    <Query<StudentDetailQueryIntake>
      query={STUDENT_DETAIL_QUERY}
      variables={{ studentId }}
      fetchPolicy="no-cache"
    >
      {({ data }) => {
        if (!data || !data.Student) return null;
        const { Student } = data;

        const {
          firstName,
          lastName,
          pictureUrl,
          highSchoolYear,
          roles,
          sendBirdChannelUrl,
          intakeResponse,
          intakeFormToken,
          parents,
        } = Student;
        const studentName = `${firstName || ''} ${lastName || ''}`;
        let IntakeForm;
        if (formToken === intakeFormToken) {
          IntakeForm = intakeResponse && JSON.parse(intakeResponse);
          setName(Student?.firstName || '');
        } else {
          parents?.forEach((parent) => {
            if (
              parent?.intakeResponse &&
              parent.intakeFormToken === formToken
            ) {
              IntakeForm =
                parent?.intakeResponse && JSON.parse(parent?.intakeResponse);
              setName(parent?.firstName || '');
            }
          });
        }

        if (IntakeForm?.step) delete IntakeForm?.step;
        if (IntakeForm?.createdAt) delete IntakeForm?.createdAt;
        if (IntakeForm?.intakeReminderEmailSent)
          delete IntakeForm?.intakeReminderEmailSent;
        const IntakeFormArray = IntakeForm && Object.entries(IntakeForm);

        const Activities = ({ data }: { data: any }) => {
          if (data) {
            data = JSON.parse(data);
          }

          return (
            <>
              {data &&
                data.length > 0 &&
                data.map((data: any) => {
                  return (
                    <Grid className="extracurricularsScrollGrid">
                      <Grid>
                        <Box
                          display="flex"
                          alignItems="flex-start"
                          justifyContent="space-between"
                        >
                          <Box>
                            <Typography variant="h5">
                              {' '}
                              {data?.title || '--'}
                            </Typography>
                            <Typography variant="h6">
                              Category: {data?.category || '--'}
                            </Typography>
                            <Typography variant="h6">
                              {`${
                                data?.startedAt
                                  ? moment(data?.startedAt).format('MM/YYYY')
                                  : '--'
                              } - ${
                                data?.endedAt
                                  ? moment(data?.endedAt).format('MM/YYYY')
                                  : data?.currentlyActive && data?.startedAt
                                  ? 'Present'
                                  : '--'
                              } `}
                            </Typography>
                            {data?.leadershipExperience && data?.role && (
                              <Typography variant="h6">
                                {`Leadership (${data?.role})`}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                        {data?.details && (
                          <Typography variant="body1">
                            {data?.details}
                          </Typography>
                        )}
                      </Grid>

                      <Divider className="activitiesDivider" />
                    </Grid>
                  );
                })}
            </>
          );
        };

        return (
          <>
            <Header />

            <Grid className={classes.genericBreadcrumb}>
              <Container maxWidth={false}>
                <Grid>
                  <Link to={`/students/student-details/${Student.id}?_t=info`}>
                    <img src={Breadcrumb_Arrow_Icon} alt="arrow" />
                  </Link>
                </Grid>

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  className="breadcrumbResponsive"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    className="studentInformation"
                  >
                    <Grid>
                      {pictureUrl ? (
                        <Grid
                          className="studentAvatar"
                          style={{
                            backgroundImage: 'url(' + pictureUrl + ')',
                          }}
                        />
                      ) : (
                        <Grid
                          className="studentAvatar"
                          style={{
                            backgroundImage: 'url(' + USER_ICON + ')',
                          }}
                        />
                      )}
                    </Grid>

                    <Box>
                      <Tooltip classes={tooltipClasses} title={studentName}>
                        <Typography variant="h3" noWrap>
                          {studentName}
                        </Typography>
                      </Tooltip>

                      <Typography variant="h6">
                        {renderText(highSchoolYear || '--', 'Class of ')}
                      </Typography>
                    </Box>
                  </Box>

                  <Link to={`/chat/${sendBirdChannelUrl}`}>
                    <Button variant="contained">
                      <img src={Chat_Icon} alt="chat" />
                      Chat Now
                    </Button>
                  </Link>
                </Box>
              </Container>
            </Grid>

            <Grid>
              <Container maxWidth={false}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  className={classes.intakeFormHeader}
                >
                  <Typography variant="h2">Intake Responses</Typography>

                  <Box
                    display="flex"
                    alignItems="center"
                    className="selectViewMenu"
                  >
                    <Typography variant="caption">View :</Typography>
                    <FormControl variant="outlined">
                      <Select value={name} onChange={handleChange}>
                        <MenuItem
                          selected={true}
                          disabled={
                            !(
                              intakeResponse &&
                              JSON.parse(intakeResponse).step >= 1
                            )
                          }
                          value={Student?.firstName || name}
                          onClick={() =>
                            history.replace(
                              `/students/student-details/${Student.id}/intakeForm/${Student?.intakeFormToken}`
                            )
                          }
                        >
                          {studentName} ({roles})
                        </MenuItem>
                        {parents?.map((item) => (
                          <MenuItem
                            disabled={
                              !(
                                item?.intakeResponse &&
                                JSON.parse(item?.intakeResponse).step >= 1
                              )
                            }
                            value={item?.firstName || name}
                            onClick={() => {
                              history.replace(
                                `/students/student-details/${Student.id}/intakeForm/${item?.intakeFormToken}`
                              );
                            }}
                          >
                            {item?.firstName} {item?.lastName} ({item?.roles})
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>

                {IntakeFormArray ? (
                  IntakeFormArray.map((IntakeQsAns: any[]) => {
                    return (
                      <>
                        <Grid className={classes.intakeQuestions}>
                          <Typography variant="h5">{IntakeQsAns[0]}</Typography>

                          {IntakeQsAns[0] ===
                          'What Extracurricular Activites are you involved in? (eg Clubs, Volunteering, Internships, etc)' ? (
                            <Activities data={IntakeQsAns[1]} />
                          ) : !Array.isArray(IntakeQsAns[1]) ? (
                            <Typography variant="body1">
                              {IntakeQsAns[1]}
                            </Typography>
                          ) : (
                            IntakeQsAns[1].map((IntakeSubQsAns: any) => {
                              return (
                                <>
                                  <Typography variant="body1">
                                    {IntakeQsAns[0] ===
                                      'Please list any AP classes you have taken, or are currently taking' ||
                                    IntakeQsAns[0] ===
                                      'Please list any IB classes you have taken, or are currently taking'
                                      ? IntakeSubQsAns.label
                                      : IntakeSubQsAns.value}
                                  </Typography>
                                </>
                              );
                            })
                          )}
                        </Grid>
                      </>
                    );
                  })
                ) : (
                  <h2>Data not provided</h2>
                )}
              </Container>
            </Grid>
          </>
        );
      }}
    </Query>
  );
};

export default IntakeFormQA;
