import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { FormHelperText } from '@material-ui/core';

const NativeTimeTextField = ({
  field: { name, value },
  form: { setFieldValue, touched, errors },
  minDate,
  maxDate,
  label,
  disable,
  isLateLog,
  setIsLateLog
}: any) => {
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          label={label || ''}
          disabled={disable}
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          maxDate={maxDate || undefined}
          minDate={minDate || undefined}
          value={value}
          onChange={(date: Date | null) => {
            setFieldValue(name, date);
            if (date) {
              // Ensure date is not null
              const currentDate = new Date();
              const tenDaysInMilliseconds = 10 * 24 * 60 * 60 * 1000; // 10 days in milliseconds
              const differenceInMilliseconds =
                currentDate.getTime() - date.getTime(); // Difference in milliseconds

              if (differenceInMilliseconds > tenDaysInMilliseconds) {
                setIsLateLog(true); //"Greater than 10 days"
              } else {
                setIsLateLog(false); //Less than or equal to 10 days
              }
            }
          }}
          placeholder="mm/dd/yyyy"
        />
      </MuiPickersUtilsProvider>
      {touched[name] && errors[name] && errors[name] === 'Requried' && (
        <FormHelperText error>Required</FormHelperText>
      )}
    </>
  );
};

export default NativeTimeTextField;
