import { useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import clsx from 'clsx';
import { Mutation } from 'react-apollo';
import { Formik, Form } from 'formik';
import gql from 'graphql-tag';
import * as Yup from 'yup';

import { useStylesAccount } from './accountStyle';

import { Tostcongigs } from './ProfilePage';
import Toast from '../../../components/Toast';
import SearchTags from '../../../components/SearchTags';
import { OptionType } from '../../../components/multiSelect';
import { handleMutationError } from '../../../common/utility';

import {
  UpdateAdvisorTagsMutation,
  UpdateAdvisorTagsMutationVariables,
} from './__generated__/UpdateAdvisorTagsMutation';

import { getAdvisorTagsQuery_getAdvisorTags_tags } from './__generated__/getAdvisorTagsQuery';

const UPDATE_ADVISOR_TAGS = gql`
  mutation UpdateAdvisorTagsMutation($tagIds: [Int]!) {
    updateAdvisorTags(input: { tagIds: $tagIds }) {
      success
      message
    }
  }
`;

const EditTagsDialog = ({
  open,
  onClose,
  tags,
  refetch,
}: {
  open: boolean;
  onClose: () => void;
  tags: (getAdvisorTagsQuery_getAdvisorTags_tags | null)[];
  refetch?: Function;
}) => {
  const classes = useStylesAccount();

  const [toast, setToast] = useState<Tostcongigs>({
    message: '',
    type: undefined,
  });
  const [openToast, setOpenToast] = useState(false);

  const FormSchema = Yup.object().shape({
    tags: Yup.array(),
    tagIds: Yup.array(),
  });

  const handleClose = async (resetForm: Function) => {
    resetForm();
    onClose();
  };

  return (
    <>
      <Toast
        open={openToast}
        close={() => {
          setOpenToast(false);
        }}
        {...toast}
      />
      <Mutation<UpdateAdvisorTagsMutation, UpdateAdvisorTagsMutationVariables>
        mutation={UPDATE_ADVISOR_TAGS}
      >
        {(updateMentor: Function) => {
          return (
            <Formik
              initialValues={{
                tags,
                tagIds: [],
              }}
              enableReinitialize
              validationSchema={FormSchema}
              onSubmit={async (variables, { setSubmitting }) => {
                try {
                  await updateMentor({
                    variables: {
                      tagIds: variables.tagIds,
                    },
                  });

                  refetch && (await refetch());
                  setToast({
                    message: 'User Updated!',
                    type: 'success',
                  });
                } catch (error: any) {
                  let errorMessage = 'Changes not saved!';

                  if (error?.message) {
                    handleMutationError(error, ({ message }) => {
                      if (message) errorMessage = message;
                    });
                  }
                  setToast({
                    message: errorMessage,
                    type: 'error',
                  });
                } finally {
                  setSubmitting(false);
                  setOpenToast(true);
                  onClose();
                }
              }}
            >
              {({
                isSubmitting,
                values,
                handleSubmit,
                errors,
                resetForm,
                setFieldValue,
                dirty,
              }) => {
                const isError = !!Object.keys(errors).length;
                return (
                  <Form>
                    <Dialog
                      open={open}
                      onClose={() => handleClose(resetForm)}
                      className={clsx(
                        classes.educationDialog,
                        classes.advisedCollegesDialog
                      )}
                    >
                      <DialogContent>
                        <Typography variant="h4">Specialties</Typography>
                        <Typography variant="body2" className="">
                          List any experiences or strengths you may bring to an
                          advising experience. This can be outside your field of
                          study.
                        </Typography>

                        <Grid className={classes.accountInformationForm}>
                          <Box className="genderDropdown timeZoneSelect acceptedToDropdown fieldsBoxSpacing">
                            <SearchTags
                              isMulti
                              label=""
                              placeholder="Search"
                              values={values?.tags?.map((c) => ({
                                label: c?.name || '',
                                value: c?.id || '',
                              }))}
                              onSelect={(tags: [OptionType]) => {
                                const MentorTagsIds = tags
                                  ? tags?.map((tag) => parseInt(tag.value))
                                  : [];
                                setFieldValue('tagIds', MentorTagsIds);
                                setFieldValue(
                                  'tags',
                                  tags?.map((c) => ({
                                    id: parseInt(c.value) || '',
                                    name: c.label || '',
                                  }))
                                );
                              }}
                            />
                          </Box>
                        </Grid>

                        <Box
                          pt={2.5}
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <Button
                            variant="outlined"
                            onClick={() => handleClose(resetForm)}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            disabled={isSubmitting || isError || !dirty}
                            onClick={() => handleSubmit()}
                          >
                            {isSubmitting ? 'Saving...' : 'Save'}
                          </Button>
                        </Box>
                      </DialogContent>
                    </Dialog>
                  </Form>
                );
              }}
            </Formik>
          );
        }}
      </Mutation>
    </>
  );
};

export default EditTagsDialog;
