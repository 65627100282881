import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../../../common/colors';
import fonts from '../../../../common/fonts';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notesTableContainer: {
      width: '100%',
      overflowX: 'auto',

      '& .firstCellPadding': {
        paddingLeft: '42px !important',
      },

      '& .notesTypeDate': {
        "& [class*='MuiTypography-body2']": {
          fontSize: 14,
          fontWeight: 400,
          marginBottom: 3,
          color: '#7D7F7F',
          fontFamily: fonts.INTER,
          letterSpacing: '-0.084px',
        },
      },
    },

    addNoteBtn: {
      '&.MuiButton-root': {
        minWidth: 159,
        height: 40,
        background: '#2F80ED',
        borderRadius: 12,
        fontWeight: 700,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#FFFFFF',
        fontFamily: fonts.POPPINS_FONT,
        padding: '8px 16px',
        textTransform: 'none',
        '&.mainBtn': {
          minWidth: 130,
        },

        '&:hover': {
          backgroundColor: '#2668C1',
        },

        '& img': {
          marginRight: 5,
        },
      },
    },

    cancelNoteBtn: {
      '&.MuiButton-root': {
        width: '114px',
        height: '40px',
        background: '#FFFFFF',
        borderRadius: 12,
        border: '1px solid #D4D4D4',
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        fontFamily: fonts.POPPINS_FONT,
        padding: '8px 16px',
        textTransform: 'none',
        marginLeft: 13,
      },
    },

    notesTable: {
      borderSpacing: '0px 9px',
      borderCollapse: 'separate',

      '& td.textBaseline': {
        '& .description': {
          textTransform: 'none',
          width: 528,
        },
        verticalAlign: 'baseline',
      },

      '& td.textBreakSpaces': {
        whiteSpace: 'break-spaces',
      },

      '& .noRecord': {
        borderRadius: '10px 10px 10px 10px !important',
        borderLeft: '1px solid #D4D4D4',
        textAlign: 'center',
      },

      '& .MuiTableCell-head, .MuiTableCell-body': {
        whiteSpace: 'nowrap',
      },

      '& .cursor': {
        cursor: 'pointer',
      },
      '& tbody': {
        '& .MuiTableRow-root': {
          boxShadow: '0px 2px 7px rgba(68, 68, 68, 0.14)',
          borderRadius: 10,
        },

        '& a': {
          fontSize: 16,
          color: '#2F80ED',
          fontFamily: fonts.INTER,
          letterSpacing: '-0.011em',
        },
      },

      '& .MuiTableCell-head': {
        border: 0,
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: '0.018em',
        textTransform: 'uppercase',
        color: '#272929',
        padding: '8px 15px',
      },

      '& tr': {
        '& td': {
          '&:nth-child(2)': {
            fontSize: 16,
          },
        },
      },

      '& .MuiTableCell-body': {
        border: 0,
        fontFamily: fonts.INTER,
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.011em',
        textTransform: 'capitalize',
        color: '#272929',
        borderTop: '1px solid #D4D4D4',
        borderBottom: '1px solid #D4D4D4',
        paddingTop: '18px',
        verticalAlign: 'middle',
        height: 52,
        lineHeight: '24px',

        '&:first-child': {
          borderRadius: '10px 0px 0px 10px',
          borderLeft: '1px solid #D4D4D4',
          fontSize: 16,
          paddingLeft: '42px',
        },

        '&:last-child': {
          borderRadius: '0px 10px 10px 0px',
          borderRight: '1px solid #D4D4D4',
          paddingRight: '43px',
        },
      },

      '& .MuiIconButton-root': {
        width: 30,
        height: 30,
        padding: 3,
      },
    },

    addNoteDialog: {
      '& .MuiDialog-paper': {
        width: 551,
        maxWidth: 551,
        border: '1px solid #D4D4D4',
        borderRadius: 25,
        padding: '31px 38px 37px 31px',
        margin: 10,
      },

      '& .MuiTypography-h4': {
        fontWeight: 600,
      },
      '& .MuiTypography-body1': {
        fontFamily: fonts.INTER,
        color: '#7D7F7F',
      },
      '& .MuiInputLabel-root': {
        fontFamily: fonts.INTER,
        color: '#272929',
        fontSize: '14px',
      },

      '& .textArea': {
        width: '479px',
        height: '143px',
        padding: '10px',
        background: '#FFFFFF',
        border: '1px solid #D4D4D4',
        outline: 'none !important',
        borderRadius: '6px',
        fontFamily: fonts.INTER,
        fontSize: '14px',
        fontWeight: 400,
        color: colors.GRAY_TAB_BORDER,
        letterSpacing: '-0.006em',
        '&::placeholder': {
          color: colors.GRAY_TWENTYTWO,
        },
      },
    },
  })
);
