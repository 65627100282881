import { CSSProperties, HTMLAttributes } from 'react';
import clsx from 'clsx';
import AsyncSelect from 'react-select/async';
import {
  createStyles,
  emphasize,
  makeStyles,
  useTheme,
  Theme,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';
import { MultiValueProps } from 'react-select/src/components/MultiValue';
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import gql from 'graphql-tag';
import fonts from '../../common/fonts';

const ALL_TAGS_QUERY = gql`
  query AllTagsQuery($limit: Int, $page: Int, $searchQuery: String) {
    allTags(input: { limit: $limit, page: $page, searchQuery: $searchQuery }) {
      nextRecord
      count
      tags {
        id
        name
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      display: 'flex',
      padding: 0,
      height: 'auto',
    },

    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },

    chip: {
      margin: theme.spacing(0.5, 0.25),
      height: 24,
      background: '#2F80ED',
      borderRadius: 6,
      fontWeight: 600,
      fontSize: 14,
      letterSpacing: '-0.006em',
      color: '#EBF3FE',
      fontFamily: fonts.INTER,
    },

    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light'
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
        0.08
      ),
    },

    noOptionsMessage: {
      padding: theme.spacing(1, 2),
    },

    singleValue: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      flex: 1,
      color: '#272929 !important',
      fontSize: '14px !important',
      fontFamily: `${fonts.INTER} !important`,
      letterSpacing: '-0.006em !important',
      margin: '0px !important',
      paddingRight: 20,
    },

    placeholder: {
      position: 'absolute',
      left: 17,
      bottom: 9,
      fontWeight: 400,
      fontSize: 14,
      letterSpacing: '-0.006em',
      color: '#A9A9A9',
      fontFamily: fonts.INTER,
      margin: '0px !important',
    },

    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
  })
);

function NoOptionsMessage(props: any) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
} as any;

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> &
  HTMLAttributes<HTMLDivElement>;

function inputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
} as any;

function Control(props: any) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
} as any;

function Option(props: any) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
} as any;

function Placeholder(props: any) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
} as any;

function SingleValue(props: SingleValueProps<OptionType>) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
} as any;

function ValueContainer(props: any) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
} as any;

function MultiValue(props: MultiValueProps<OptionType>) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
} as any;

function Menu(props: any) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
} as any;

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

interface OptionType {
  value: string;
  label: string;
}
const SearchTagsAsync = ({
  client,
  setSelectedValue,
  values,
  isMulit,
  label = 'Select College',
  isClearable,
  disabled,
  className,
  classNamePrefix,
  placeholder,
}: {
  label?: string;
  client: any;
  isClearable?: boolean;
  setSelectedValue: Function;
  values: OptionType[] | [] | any;
  isMulit?: boolean;
  disabled?: boolean;
  className?: string;
  classNamePrefix?: string;
  placeholder?: string;
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const loadOptions: any = (inputValue: string) => {
    return new Promise((resolve) => {
      // API call
      client
        .query({
          query: ALL_TAGS_QUERY,
          variables: {
            limit: 25,
            searchQuery: inputValue,
          },
        })
        .then((res: any) => {
          if (res?.data?.allTags?.tags?.length) {
            const options: OptionType[] = res?.data?.allTags?.tags.map(
              (item: any) => ({
                value: item.id,
                label: `${item.name}`,
              })
            );
            resolve(options);
          } else {
            resolve([]);
          }
        });
    });
  };

  const handleInputChange = (newValue: string) => newValue;

  const selectStyles: any = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  return (
    <AsyncSelect
      placeholder={placeholder}
      classes={classes}
      classNamePrefix={'react-select'}
      className="react-select-container"
      styles={selectStyles}
      inputId="react-select-multiple"
      TextFieldProps={{
        label,
        InputLabelProps: {
          htmlFor: 'react-select-multiple',
          shrink: true,
        },
      }}
      isDisabled={disabled}
      isMulti={isMulit ? true : false}
      isClearable={isClearable ? true : false}
      cacheOptions
      loadOptions={loadOptions}
      defaultOptions
      value={values}
      components={components}
      onChange={(selectedValues: any) => {
        if (selectedValues) {
          setSelectedValue(selectedValues);
        } else {
          setSelectedValue([]);
        }
      }}
      onInputChange={handleInputChange}
    />
  );
};

export default SearchTagsAsync;
