import gql from 'graphql-tag';

export const GET_ADVISOR_LEAD = gql`
  query advisorLead {
    advisorLead {
      ATL {
        firstName
        lastName
        advisorLevel
        email
        phoneNumber
        pictureUrl
      }
    }
  }
`;