import { ApolloConsumer } from 'react-apollo';
import { Box } from '@material-ui/core';

import PayoutsTable from './PayoutsTable';

const Payouts = () => {
  return (
    <ApolloConsumer>
      {(client) => (
        <Box>
          <PayoutsTable client={client} />
        </Box>
      )}
    </ApolloConsumer>
  );
};

export default Payouts;
