import { useContext, useState } from 'react';
import { ApolloConsumer } from 'react-apollo';

import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Avatar,
  Card,
} from '@material-ui/core';

import Info_Blue_Img from '../../../img/blue-info.svg';
import TimeSlotsTable from './TimeSlotsTable';
import Header from '../../../components/layout/Header';
import FooterNew from '../../../components/layout/FooterNew';
import { useStyles } from '../students/styles';
import { AppContext } from '../../../contexts';
import { generateConnectURL } from '../../../common/nylas';
import AdvisorSelect from '../../../components/AdvisorSelect';

import LINK_ICON from '../../../img/link-copy.png';
import LINK_COPIED_ICON from '../../../img/link-copied-icon.png';
interface OptionType {
  label: string;
  value: string;
  pictureUrl: string;
}

const TimeSlots = () => {
  const classes = useStyles();
  const { user } = useContext(AppContext);
  const [isCopied, setIsCopied] = useState(false);
  const [selectedAdvisor, setSelectedAdvisor] = useState<OptionType | null>(
    null
  );
  const params = {
    client_id: process.env.REACT_APP_NYLAS_V3_CLIENT_ID_PRD || '',
    redirect_uri:
      process.env.REACT_APP_REDIRECT_URI ||
      'https://staging.advisors.collegeadvisor.com/callback',
    login_hint: user?.email ?? '',
    response_type: 'code',
    scopes: 'calendar',
    access_type: 'offline',
  };

  return (
    <>
      <Header />

      <Container maxWidth="xl" className={classes.timeslotsContainer}>
        <Box
          className="content-box-height-empty"
          paddingTop={5.5}
          paddingBottom={2}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item sm={3}>
              <Grid container>
                <Grid item sm={12}>
                  <Typography variant="h3">
                    {!selectedAdvisor
                      ? 'My Availability'
                      : 'Advisor Availability'}
                  </Typography>
                </Grid>
                <Grid item sm={12}>
                  {user?.nylas_grant_id && (
                    <Box className="advisorSelectScroll">
                      <Box
                        className={
                          !selectedAdvisor ? classes.advisorSelectDropDown : ''
                        }
                      >
                        {!selectedAdvisor ? (
                          <AdvisorSelect
                            placeholder="Search another advisor"
                            allowInvisibleMentors={true}
                            advisorId={user?.id}
                            isMulti={false}
                            onChange={(val: OptionType) => {
                              setSelectedAdvisor(val);
                            }}
                            otherOptions={{ pictureUrl: true }}
                          />
                        ) : (
                          <Box
                            className={classes.selectAdvisor}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Box display="flex" alignItems="center">
                              <Avatar
                                alt={selectedAdvisor.label}
                                src={selectedAdvisor.pictureUrl}
                              />
                              <Box>
                                <Typography noWrap variant="body1">
                                  {selectedAdvisor.label.split('(')?.[0] || ''}
                                </Typography>
                              </Box>
                              <button onClick={() => setSelectedAdvisor(null)}>
                                Remove
                              </button>
                            </Box>

                            <Box className={classes.bookingBtn}>
                              <Button
                                variant="contained"
                                color={isCopied ? 'inherit' : 'primary'}
                                disabled={isCopied}
                                className={
                                  isCopied
                                    ? classes.copyBookingLinkDark
                                    : classes.copyBookingLink
                                }
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    user?.id
                                      ? `${
                                          process.env
                                            .REACT_APP_STUDENT_PORTAL_URL || ''
                                        }/home?adv1=${
                                          selectedAdvisor?.value
                                        }&kickOff=true`
                                      : ''
                                  );
                                  setIsCopied(true);
                                  setTimeout(() => {
                                    setIsCopied(false);
                                  }, 5000);
                                }}
                              >
                                {isCopied ? (
                                  <>
                                    <img src={LINK_ICON} alt="copied" /> Link
                                    Copied
                                  </>
                                ) : (
                                  <>
                                    <img src={LINK_COPIED_ICON} alt="copied" />{' '}
                                    Copy {selectedAdvisor.label.split(' ')[0]}'s
                                    Booking Link
                                  </>
                                )}
                              </Button>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={1}></Grid>
            <Grid item sm={!user?.nylas_grant_id ? 6 : 8}>
              <Card className={classes.alertInfoCard}>
                <Box display="flex">
                  <Grid>
                    <img src={Info_Blue_Img} alt="token" />
                  </Grid>
                  <Grid>
                    <Typography variant="h5">
                      Setting recurring availability through the upcoming time
                      change
                    </Typography>
                    <Typography variant="body1">
                      As Standard Time Change is approaching on{' '}
                      <strong>November 3, 2024 at 2am</strong> (local), please
                      note that recurring time slots are not able to be
                      scheduled through this date as the change takes effect.
                      The system will only allow series of time slots to be
                      scheduled both before and after this date.
                    </Typography>
                  </Grid>
                </Box>
              </Card>
            </Grid>
            {!user?.nylas_grant_id && (
              <Grid item sm={2}>
                <Button
                  href={generateConnectURL(params)}
                  rel="noopener noreferrer"
                  color="primary"
                  className={classes.connectCalendar}
                >
                  Connect to calendar
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>

        <ApolloConsumer>
          {(client) => (
            <TimeSlotsTable
              selectedAdvisorId={
                selectedAdvisor?.value ? +selectedAdvisor.value : null
              }
            />
          )}
        </ApolloConsumer>
      </Container>

      <FooterNew />
    </>
  );
};

export default TimeSlots;
