import { FC } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';

interface PropsType {
  label: string;
  value: string;
  values: string[];
  id: string;
  onSelect: Function;
}

const FormikDropdown: FC<PropsType> = ({
  id,
  label,
  value,
  onSelect,
  values,
}) => {
  return (
    <FormControl fullWidth={true}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Select
        value={value}
        onChange={({ target: { value } }) => onSelect(id, value)}
        name={id}
        inputProps={{ id }}
      >
        <MenuItem disabled>{label}</MenuItem>
        {values.map((item) => (
          <MenuItem value={item} key={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
      {false && <FormHelperText>Select</FormHelperText>}
    </FormControl>
  );
};

export default FormikDropdown;
