import { useState, useContext, useCallback } from 'react';
import { useParams, withRouter } from 'react-router';
import {
  withSendBird,
  Channel as SBConversation,
  ChannelList as SBChannelList,
  sendBirdSelectors,
} from 'sendbird-uikit';
import { Box, Button } from '@material-ui/core';

import CustomizedMessageInput from './CustomizedMessageInput';
import CustomizedHeader from './CustomizedHeader';
import AdvisorModal from './AdvisorModal';

import { AppContext } from '../../../contexts';

type TParams = {
  sdUrl: string;
  sendBirdId: string;
};

const CustomizedChat = ({ createChannel, sdk, history }: any) => {
  const { sdUrl, sendBirdId: params_advisor_sendBirdId } = useParams<TParams>();

  const { user } = useContext(AppContext);
  const [isNewMessageSend, setIsNewMessageSend] = useState(false);
  const [currentChannelUrl, setCurrentChannelUrl] = useState('');
  const [queries] = useState({
    channelListQuery: {
      includeEmpty: true,
      limit: 50,
      order: 'latest_last_message',
    },

    applicationUserListQuery: {
      limit: 50,
    },
  });

  const [openModal, setOpenModal] = useState(false);

  const currentUserId = user && (user.sendbirdId || user.email);

  async function setMentorId(id: string) {
    let params = new sdk.GroupChannelParams();
    params.isPublic = false;
    params.isEphemeral = false;
    params.isDistinct = true;
    params.addUserIds([id, currentUserId]);

    try {
      const result = await createChannel(params);

      if (result && !params_advisor_sendBirdId) {
        toggleModal();
      }
      if (params_advisor_sendBirdId) {
        history.replace(`/chat/${sdUrl}`);
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  function toggleModal() {
    if (openModal) window.scrollTo(0, 0);
    setOpenModal(!openModal);
  }

  const currentUser = user && user.id;

  const channelSort = useCallback(
    (channels) => {
      if (isNewMessageSend) return channels;

      if (channels.length === 0) {
        return channels;
      }

      const channel = channels.find((c: any) => c.url === sdUrl);

      if (!channel) {
        return channels;
      }

      const otherChannels = channels.filter((c: any) => c.url !== channel.url);

      return [channel, ...otherChannels];
    },
    [isNewMessageSend]
  );
  if (params_advisor_sendBirdId && currentChannelUrl) {
    setMentorId(params_advisor_sendBirdId);
  }

  return (
    <Box className="customized-app" maxWidth="100vw" overflow="auto">
      <Box textAlign="right" padding="20px 0">
        <Button color="primary" variant="contained" onClick={toggleModal}>
          Chat with Advisor
        </Button>
      </Box>

      <div className="sendbird-app__wrap">
        <div className="sendbird-app__channellist-wrap">
          <div className="sendbird-channel-list">
            <AdvisorModal
              setMentorId={setMentorId}
              open={openModal}
              onClose={toggleModal}
              currentUser={currentUser}
            />

            <SBChannelList
              sortChannelList={channelSort}
              onChannelSelect={(channel: any) => {
                if (channel && channel.url) {
                  setCurrentChannelUrl(channel.url);
                }
              }}
              queries={queries}
            />
          </div>
        </div>
        <div className="sendbird-app__conversation-wrap">
          <SBConversation
            channelUrl={currentChannelUrl}
            renderMessageInput={({ channel }: any) => {
              return (
                <CustomizedMessageInput
                  channel={channel}
                  isNewMessageSend={setIsNewMessageSend}
                />
              );
            }}
            renderChatHeader={({ channel, user }: any) => (
              <CustomizedHeader
                userId={user.userId}
                channel={channel}
                sdk={sdk}
              />
            )}
          />
        </div>
      </div>
    </Box>
  );
};

const mapStoreToProps = (state: any) => {
  const createChannel = sendBirdSelectors.getCreateChannel(state);
  const sdk = sendBirdSelectors.getSdk(state);
  return {
    createChannel,
    sdk,
  };
};

export default withRouter(withSendBird(CustomizedChat, mapStoreToProps));
