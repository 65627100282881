import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import fonts from '../../../common/fonts';
import colors from '../../../common/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageHeader: {
      padding: '66px 0px 62px',

      [theme.breakpoints.down('md')]: {
        padding: '36px 0px',
      },

      '& .pageHeadingText': {
        fontSize: 36,
        fontWeight: 700,
        letterSpacing: '-0.792px',
        color: colors.BLACK_TWELVE,
        fontFamily: fonts.POPPINS_FONT,

        [theme.breakpoints.down('sm')]: {
          fontSize: 24,
        },
      },

      '& .MuiTypography-body1': {
        fontSize: 16,
        fontWeight: 400,
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.176px',
      },
    },

    capacityStats: {
      '& .statsSectionHeading': {
        fontSize: 14,
        fontWeight: 600,
        color: '#272929',
        paddingBottom: 10,
        letterSpacing: '0.084px',
        textTransform: 'uppercase',
        fontFamily: fonts.POPPINS_FONT,
        '&.light': {
          opacity: 0.25,
        },
      },

      '& .editInput': {
        border: 0,
        padding: 0,
        width: 125,
        maxWidth: 135,
        color: '#2F80ED',
        background: 'transparent',

        '&::placeholder': {
          color: '#2F80ED',
        },
      },

      '& .sectionDivider': {
        margin: '36px 0px 26px',
        backgroundColor: '#D4D4D4',
      },

      '& .descriptionText': {
        fontSize: 12,
        fontWeight: 400,
        color: '#7D7F7F',
        margin: '11px 0px 10px',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.072px',
      },

      '& .checkboxComponent': {
        margin: 0,

        '& .MuiTypography-body1': {
          fontSize: 14,
          marginLeft: 15,
          fontWeight: 400,
          color: '#272929',
          fontFamily: fonts.INTER,
          letterSpacing: '-0.084px',
        },

        '& svg path': {
          fill: '#A9A9A9',
        },

        '& .MuiCheckbox-root': {
          padding: 0,
        },
      },
      '& .disableCheckboxComponent': {
        opacity: 0.33,
      },
      '& .disabledText': {
        marginLeft: '40px',
        opacity: 0.33,
        maxWidth: '350px',
        fontSize: '12px',
        marginTop: '10px',
      },

      '& .hoursEditable': {
        minWidth: 125,
        fontSize: 14,
        fontWeight: 600,
        borderRadius: 6,
        padding: '7px 10px',
        display: 'inline-block',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.084px',
      },

      '& .infoButton': {
        color: '#2F80ED',
        background: '#EBF3FE',
        border: '1px solid #2F80ED',
      },

      '& .warningButton': {
        color: '#FCA713',
        background: '#FFF9EE',
        border: '1px solid #FDBD4E',
      },

      '& .warningButtonDark': {
        marginLeft: 14,
        color: '#ffffff',
        background: '#FDBD4E',
        border: '1px solid #FDBD4E',

        [theme.breakpoints.down('xs')]: {
          marginLeft: 0,
        },
      },

      '& .successButton': {
        color: '#45CE93',
        background: '#EDFBF5',
        border: '1px solid #45CE93',
      },

      '& .highestLowestText': {
        marginTop: '0px',
        '& .MuiTypography-body1': {
          fontSize: 12,
          fontWeight: 400,
          color: '#7D7F7F',
          paddingBottom: 2,
          fontFamily: fonts.INTER,
          letterSpacing: '-0.084px',

          '& span': {
            paddingLeft: 20,
            color: '#A9A9A9',
            fontSize: 12,
          },
        },
      },

      '& .editButtonHours': {
        padding: 5,
        marginLeft: 13,
      },
      '& .badge': {
        color: '#F4F4F4',
        backgroundColor: colors.GRAY_TWENTYTWO,
        fontFamily: fonts.INTER,
        padding: '0px 3px',
        '&+div': {
          opacity: 0.5,
          color: '#525454',
          marginTop: '10px',
          '& span': {
            fontFamily: fonts.INTER,
          },
          '& p': {
            fontFamily: fonts.INTER,
            '&.excluded': {
              fontSize: '12px',
            },
          },
        },
      },
      '& .excludedContainer': {
        marginTop: '10px',
        '& .excluded': {
          fontFamily: fonts.INTER,
          color: colors.BLACK_FOURTEEN,
          fontSize: '12px',
          marginTop: '2px',
        },
      },
    },
  })
);
