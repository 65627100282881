import { createStyles, makeStyles, Theme } from '@material-ui/core';
import colors from './colors';
import fonts from './fonts';

export const useCommonStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropStyleTable: {
      '& .react-select__control': {
        width: '174px !important',
      },
    },
    decisionSelect: {
      '& .react-select__option': {
        color: colors.GRAY_TAB_BORDER,
        '&--is-focused, &--is-selected': {
          backgroundColor: colors.SELECT_OPTION_ACTIVE,
        },
        '&:hover': {
          cursor: 'pointer',
          background: colors.GRAY_BOREDER,
        },
      },
      '& .react-select__control': {
        borderRadius: 6,
        width: 197,
        minHeight: 32,
        height: 32,
        '&--is-focused, &:hover':{
          borderColor: `${colors.WHITE_SEVEN} !important`,
          boxShadow: 'none'
        }
      },

      '& .react-select__value-container': {
        height: 32,
        padding: '0px 8px',
      },

      '& .react-select__single-value': {
        color: '#272929',
      },

      '& .react-select__single-value, & .react-select__placeholder': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        fontFamily: fonts.INTER,
        top: 14,
      },

      '& .react-select__placeholder': {
        color: '#A9A9A9',
      },

      '& .react-select__indicator-separator, & .react-select__loading-indicator, & .react-select__clear-indicator':
        {
          display: 'none',
        },

      '& .react-select__indicators': {
        height: 28,
      },

      '& .react-select__indicator': {
        '& svg': {
          color: '#7D7F7F',
          width: 15,
        },
      },

      '& .react-select__multi-value': {
        background: '#63A0F2',
        borderRadius: 4,

        '& svg': {
          color: '#FFFFFF',
          opacity: 0.6,
        },

        '& .react-select__multi-value__remove': {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },

      '& .react-select__multi-value__label': {
        fontWeight: 600,
        fontSize: 12,
        color: '#FFFFFF',
        fontFamily: fonts.INTER,
      },

      '& .react-select__value-container.react-select__value-container--is-multi.react-select__value-container--has-value':
        {
          padding: 10,
        },
    },
    campusesDropdown: {
      position: 'relative',
      top: '38px',
    },
    campusesDropDownSpacing: {
      '&:not(:first-child)': {
        paddingTop: 6
      }
    },
    campusesStatusSpacing:{
      '&:not(:first-child)': {
        paddingTop: 16
      }
    },
  })
);
export const useCustomTooltipStyles = makeStyles((theme: Theme) =>
  createStyles({
    arrow: {
      color: '#fff',
    },
    tooltip: {
      maxWidth: 320,
      fontFamily: fonts.INTER,
      padding: '11px 13px',
      fontWeight: 400,
      fontSize: 12,
      letterSpacing: '-0.006em',
      color: '#7D7F7F',
      background: '#FFFFFF',
      border: '1px solid #F6F8F9',
      borderRadius: 6,
      boxShadow: '0px 0px 10px -3px rgba(68, 68, 68, 0.6) !important',
    },
  })
);

export const useCustomIncentiveTooltip = makeStyles((theme: Theme) =>
  createStyles({
    arrow: {
      color: '#fff',
    },
    tooltip: {
      maxWidth: 320,
      fontFamily: fonts.INTER,
      padding: '11px 28px',
      fontWeight: 400,
      fontSize: 12,
      letterSpacing: '-0.006em',
      color: '#525454',
      background: '#FFFFFF',
      border: '1px solid #F6F8F9',
      borderRadius: 6,
      boxShadow: '0px 0px 10px -3px rgba(68, 68, 68, 0.6) !important',
      textAlign:'center'
    },
  })
);

export const useCustomIncentiveImgTooltip = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      maxWidth: 320,
      fontFamily: fonts.INTER,
      padding: '11px 28px',
      fontWeight: 400,
      fontSize: 12,
      letterSpacing: '-0.006em',
      borderRadius: 6,
      textAlign:'center'
    },
  })
);