import { FC, useContext } from 'react';
import { Container } from '@material-ui/core';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import styled from 'styled-components';
import Colors from '../../common/colors';
import Fonts from '../../common/fonts';
import { GlobalStyle } from '../../styles/baseStyle';
import TUTORS_BG from '../../img/tutors_desktop.png';
import { Redirect } from 'react-router-dom';
import { AppContext } from '../../contexts';

const WrapperHome = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;

  h5 {
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.3375px;
    color: ${Colors.BLACK_THREE};
    opacity: 0.6;
    margin: 0px;
    line-height: 26px;
  }

  @media only screen and (max-width: 991px) {
    flex-direction: column;
  }
`;

const AppLandingLeft = styled.div`
  width: 550px;
  max-width: 550px;
  padding-right: 1%;
  padding-top: 70px;
  padding-bottom: 70px;

  @media only screen and (max-width: 1200px) {
    width: 100%;
    text-align: center;
    padding-top: 0px;
  }

  h1 {
    font-size: 50px;
    font-weight: 600;
    color: ${Colors.BLACK_THREE};
    letter-spacing: -0.05em;
    margin: 25px 0px;
  }

  p {
    font-family: ${Fonts.POPPINS_FONT};
  }

  button {
    padding: 0px;
    width: 120px;
    height: 44px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 700;
    font-family: ${Fonts.POPPINS_FONT};
    background: ${Colors.BLUE_TWO};
    margin-top: 15px;
    color: ${Colors.WHITE_ONE};

    &:hover {
      background-color: ${Colors.SKYBLUE_FOUR};
    }
  }
`;

const AppLandingRight = styled.div`
  width: calc(100% - 550px);
  padding-left: 1%;
  text-align: center;

  @media only screen and (max-width: 1200px) {
    width: 100%;
    max-width: 700px;
  }

  img {
    max-width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
`;

const HomePage: FC = () => {
  const { user } = useContext(AppContext);
  return (
    <>
      {user ? <Redirect to="/meetings" /> : <Redirect to="/login" />}
      <Header />

      <GlobalStyle>
        <Container className="content-box-height">
          <WrapperHome>
            <AppLandingLeft>
              <h1>Level Up Your College Applications</h1>
              <h5>
                National Collegiate Scouting Association, LLC provides high
                school students the competitive edge they need to get accepted
                by their target schools.
              </h5>
            </AppLandingLeft>
            <AppLandingRight>
              <img src={TUTORS_BG} alt="banner" />
            </AppLandingRight>
          </WrapperHome>
        </Container>
      </GlobalStyle>

      <Footer />
    </>
  );
};

export default HomePage;
