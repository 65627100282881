import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import client from '../../../apollo';
import { CURRENT_USER_QUERY } from '../layouts/MainLayout';
import { AppContext } from '../../../contexts';
import { CurrentUserQuery } from '../layouts/__generated__/CurrentUserQuery';
import { SET_AUTH_TOKEN } from './gql';

const CalendarCallback = () => {
  const history = useHistory();
  const { setUser } = useContext(AppContext);
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');

  useEffect(() => {
    if (!code) {
      history.push('/account/connect-with-calendar');
    } else {
      const getAuthToken = async () => {
        try {
          const { data } = await client.mutate({
            mutation: SET_AUTH_TOKEN,
            variables: {
              code,
            },
            fetchPolicy: 'no-cache',
          });
          if (data && !data?.setNylasAuthToken?.success) {
            history.push('/');
          } else {
            history.push('/calendar');
            const { data }: { data: CurrentUserQuery } = await client.query({
              query: CURRENT_USER_QUERY,
              fetchPolicy: 'network-only',
            });
            if (data) {
              setUser(data.currentUser || null);
            }
          }
        } catch (error) {
          console.log(error);
        }
      };
      getAuthToken();
    }
  }, []);
  return <div>Loading...</div>;
};

export default CalendarCallback;
