import AsyncSelect from 'react-select/async';
import { ILocation } from './SummerOpportunitiesSubmission';

const CityDropdownLocation = ({
  loadOptions,
  setValue,
  placeHolder,
  locationCountArray,
  index,
}: {
  loadOptions: any;
  setValue: Function;
  placeHolder: string;
  locationCountArray: ILocation[];
  index: number;
}) => {
  return (
    <AsyncSelect
      isClearable={true}
      cacheOptions
      loadOptions={loadOptions}
      defaultOptions={true}
      onChange={(value) => {
        if (!value) {
          locationCountArray[index].city = '';
          setValue(locationCountArray);
        } else {
          locationCountArray[index].city = value.value;
          setValue(locationCountArray);
        }
      }}
      isSearchable
      placeholder={placeHolder}
      className="basic-single"
      classNamePrefix="select"
      maxMenuHeight={200}
    />
  );
};
export default CityDropdownLocation;
