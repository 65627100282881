import gql from 'graphql-tag';

export const CHECK_STUDENT_PROFILE = gql`
  query getProfileData($userId: Int!) {
    getProfileData(userId: $userId) {
      highSchoolName
      act
      apCourses
      IbCourses
      sat
      gpa
      gpaWeighted
      gpaNonWeighted
      highSchoolYear
      highSchoolState
      areasOfInterest
      country
      city
      state
      zipCode
      undergraduateGradYear
      undergraduateCollege
      studentExtraCurricularActivities {
        id
        StudentId
        details
        title
        startedAt
        endedAt
        currentlyActive
        leadershipExperience
        role
        extraCurricularActivity
      }
    }
  }
`;