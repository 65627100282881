import { ChangeEvent, useState } from 'react';
import gql from 'graphql-tag';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Mutation, Query } from 'react-apollo';
import {
  Box,
  Typography,
  Card,
  Checkbox,
  Select as MaterialSelect,
  MenuItem,
  Grid,
  TextareaAutosize,
  Divider,
  FormControlLabel,
  FormGroup,
  Button,
  IconButton,
  Hidden,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as Yup from 'yup';
import {
  createSummerOpportunityMutation,
  createSummerOpportunityMutationVariables,
} from './__generated__/createSummerOpportunityMutation';

import { useStyles } from './SummerOpportunityStyle';
import DELETE_PAYOUT_ICON from '../../../img/delete-payouts.svg';
import PLUS_ICON from '../../../img/plus-icon.svg';
import LOGO_LEAD_IMG from '../../../img/logo-lead-form.svg';
import { allSummerOpportunitiesTypesQuery } from './__generated__/allSummerOpportunitiesTypesQuery';
import { allSummerOpportunitiesMetroAreasQuery } from './__generated__/allSummerOpportunitiesMetroAreasQuery';
import { allSummerOpportunitiesCategoriesQuery } from './__generated__/allSummerOpportunitiesCategoriesQuery';
import { states as STATES } from '../../../common/USStates';

import Select from 'react-select';
import client from '../../../apollo';
import SimpleDropDown from './SimpleDropDown';
import CityDropdownLocation from './CityDropdownLocation';

export const ALL_US_CITIES = gql`
  query allUSCitiesQuery($name: String, $limit: Int) {
    allUSCities(input: { name: $name, limit: $limit }) {
      cities
      success
    }
  }
`;

export const ALL_TYPES = gql`
  query allSummerOpportunitiesTypesQuery {
    allSummerOpportunitiesTypes {
      success
      allTypes {
        name
      }
    }
  }
`;

export const ALL_CATEGORIES = gql`
  query allSummerOpportunitiesCategoriesQuery($name: String, $slug: String) {
    allSummerOpportunityCategories(input: { name: $name, slug: $slug }) {
      summerOpportunityCategories {
        name
      }
    }
  }
`;

export const ALL_METRO_AREAS = gql`
  query allSummerOpportunitiesMetroAreasQuery {
    allSummerOpportunitiesMetroAreas {
      success
      allMetroAreas {
        name
      }
    }
  }
`;

const grades = ['7', '8', '9', '10', '11', '12'];

const STEMCategories = [
  'Science',
  'Technology/Computer Science',
  'Engineering',
  'Math',
  'Pre-med (Biology/Health Sciences)',
];

export const CREATE_SUMMER_OPPORTUNITY_MUTATION = gql`
  mutation createSummerOpportunityMutation(
    $programName: String
    $organizationName: String
    $landingPageUrl: String
    $programDescription: String
    $programCost: String
    $stipendGranted: String
    $deadlineType: String
    $applicationDeadLine: String
    $targetGrade: [String]
    $subjectsCategories: [String]
    $isRemote: Boolean
    $city: String
    $forIntlStudents: Boolean
    $state: String
    $zipCode: String
    $type: String
    $programStartDate: String
    $programEndDate: String
    $metroArea: String
    $locationInfo: [LocationType]
  ) {
    createSummerOpportunity(
      input: {
        programName: $programName
        organizationName: $organizationName
        landingPageUrl: $landingPageUrl
        programDescription: $programDescription
        programCost: $programCost
        stipendGranted: $stipendGranted
        deadlineType: $deadlineType
        applicationDeadLine: $applicationDeadLine
        targetGrade: $targetGrade
        subjectsCategories: $subjectsCategories
        isRemote: $isRemote
        forIntlStudents: $forIntlStudents
        city: $city
        state: $state
        zipCode: $zipCode
        type: $type
        programStartDate: $programStartDate
        programEndDate: $programEndDate
        metroArea: $metroArea
        locationInfo: $locationInfo
      }
    ) {
      status
      message
      summerOpportunity {
        programName
        organizationName
        landingPageUrl
        programDescription
        programCost
        stipendGranted
        deadlineType
        applicationDeadLine
        targetGrade
        isRemote
        zipCode
        type
        city
        state
        summerOpportunityCategory {
          name
        }
        summerOpportunityType {
          name
        }
      }
    }
  }
`;

interface IOptionType {
  value: string;
  label: string;
}

let found: boolean = false;

export interface ILocation {
  state?: any | null;
  city?: any | null;
  zipCode?: string | null;
  metroArea?: any | null;
  programStartDate: Date | null;
  programEndDate: Date | null;
}

const SummerOpportunitiesSubmission = ({
  setIsSubmitted,
}: {
  setIsSubmitted: Function;
}) => {
  const [programCost, setProgramCost] = useState('');
  const [stipendGranted, setStipendGranted] = useState('');
  const [deadlineType, setDeadlineType] = useState('');
  const [description, setDesctiption] = useState('');
  const [state, setState] = useState<any>('');
  const [city, setCity] = useState<any>('');
  const [type, setType] = useState('');
  const [metroArea, setMetroArea] = useState<any>('');
  const [targetGradeArray, setTargetGradeArray] = useState<string[]>();
  const [isRemote, setIsRemote] = useState<Boolean>();
  const [forIntlStudents, setForIntlStudents] = useState<Boolean>();
  const [subjectsCategories, setSubjectsCategories] = useState<string[]>();
  const [indexTrack, setIndexTrack] = useState<number>(0);
  const [locationCountArray, setlocationCountArray] = useState<ILocation[]>([]);
  const [rerender, setRerender] = useState('');

  const initialValues = {
    programName: '',
    organizationName: '',
    landingPageUrl: '',
    programDescription: '',
    programCost: '',
    stipendGranted: '',
    deadlineType: '',
    city: '',
    type: '',
    zipCode: '',
  };
  const classes = useStyles();

  const onClickGrades = (grade: string) => {
    if (!targetGradeArray?.includes(grade)) {
      setTargetGradeArray(
        targetGradeArray?.length ? [...targetGradeArray, grade] : [grade]
      );
    } else if (targetGradeArray?.includes(grade)) {
      setTargetGradeArray(
        targetGradeArray?.length
          ? targetGradeArray.filter((item) => item !== grade)
          : targetGradeArray
      );
    }
  };

  const optionsStates: any[] = STATES.map((item: string) => {
    return {
      value: item,
      label: item,
    };
  });

  found = STEMCategories?.some((item) => subjectsCategories?.includes(item));

  const onClickSubjects = (subject: string) => {
    if (!subjectsCategories?.includes(subject)) {
      setSubjectsCategories(
        subjectsCategories?.length
          ? [...subjectsCategories, subject]
          : [subject]
      );
    } else if (subjectsCategories?.includes(subject)) {
      setSubjectsCategories(
        subjectsCategories?.length
          ? subjectsCategories.filter((item) => item !== subject)
          : subjectsCategories
      );
    }
  };

  const handleMetroAreaChange = (value: any) => {
    setMetroArea(value);
  };

  const loadOptions = (inputValue: string) => {
    return new Promise((resolve) => {
      // API call
      client
        .query({
          query: ALL_US_CITIES,
          variables: { name: inputValue, limit: 50 },
        })
        .then((res) => {
          if (res.data && res.data.allUSCities && res.data.allUSCities.cities) {
            let cities: string[] = [];
            res.data.allUSCities.cities?.map((item: string) => {
              if (item && !cities.includes(item)) {
                item && cities.push(item);
              }
            });
            let options: any[] = cities.map((item: string) => {
              return {
                value: item,
                label: item,
              };
            });
            resolve(options);
          } else {
            resolve([]);
          }
        });
    });
  };

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };

  const handleInputOnChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    let obj = locationCountArray[index];
    if (name === 'city')
      obj = {
        ...obj,
        city: event.target.value,
      };
    else if (name === 'zipCode')
      obj = {
        ...obj,
        zipCode: event.target.value,
      };

    const newArray = [
      ...locationCountArray.slice(0, index),
      obj,
      ...locationCountArray.slice(index + 1),
    ];
    setlocationCountArray(newArray);
  };
  const regexNoSpace = /^[a-zA-Z&'!?()\\.,/\-"]+( [a-zA-Z&'!?()\\.,/\-"]+)*$/;
  const regexNoSpaceWithoutSpecialCharachters = /^(?!\s)(?!.*\s$)/; // Only checks for leading and trailing space
  const regexValidUrl = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  const FormSchema = Yup.object().shape({
    programName: Yup.string()
      .matches(
        regexNoSpaceWithoutSpecialCharachters,
        'Program Name Should be valid'
      )
      .required('Required'),
    organizationName: Yup.string()
      .matches(regexNoSpace, 'Organization Name Should be valid')
      .required('Required'),
    landingPageUrl: Yup.string()
      .matches(regexValidUrl, 'Enter a Valid url')
      .required('Required'),
    programDescription: Yup.string()
      .matches(
        regexNoSpaceWithoutSpecialCharachters,
        'There should be no trailing or leading space in Program description'
      )
      .required('Required'),
  });
  return (
    <>
      <Mutation<
        createSummerOpportunityMutation,
        createSummerOpportunityMutationVariables
      >
        mutation={CREATE_SUMMER_OPPORTUNITY_MUTATION}
      >
        {(createSummerOpportunity: Function, { loading }) => {
          return (
            <Card className={classes.salesLeadForm}>
              <Box textAlign="center" className={classes.leadFormHeader}>
                <img src={LOGO_LEAD_IMG} alt="logo" />
                <Typography variant="h2">
                  Extracurricular Opportunities Submission
                </Typography>
              </Box>

              <Grid className={classes.summerOpportunityForm}>
                <Typography variant="h6" className="secondaryHeading">
                  General
                </Typography>
                <Formik
                  validationSchema={FormSchema}
                  initialValues={initialValues}
                  onSubmit={async (values) => {
                    try {
                      if (
                        STEMCategories?.some((item) =>
                          subjectsCategories?.includes(item)
                        )
                      ) {
                        subjectsCategories?.push('STEM');
                      }
                      await createSummerOpportunity({
                        variables: {
                          ...values,
                          programCost,
                          stipendGranted,
                          deadlineType,
                          programDescription: description,
                          targetGrade: targetGradeArray,
                          subjectsCategories: subjectsCategories,
                          isRemote,
                          city: city.value,
                          forIntlStudents,
                          state: state.value,
                          applicationDeadLine: selectedDate,
                          type,
                          metroArea: metroArea.value,
                          locationInfo: locationCountArray,
                          programEndDate: endDate,
                          programStartDate: startDate,
                        },
                      });
                      setIsSubmitted(true);
                    } catch (error) {
                      console.log('error', error);
                    }
                  }}
                >
                  {({ errors, isSubmitting, values, setFieldValue }) => (
                    <Form>
                      <Grid container className="fullWidthField">
                        <Grid item md={4} sm={12} xs={12}>
                          <Grid className="betweenSpacing">
                            <Query<allSummerOpportunitiesTypesQuery>
                              query={ALL_TYPES}
                              fetchPolicy="no-cache"
                            >
                              {({ data }) => {
                                if (!data) return null;
                                if (data) {
                                  let allTypes: string[] = [];
                                  data?.allSummerOpportunitiesTypes?.allTypes
                                    ?.length &&
                                    data?.allSummerOpportunitiesTypes?.allTypes?.map(
                                      (item) => {
                                        if (
                                          item?.name &&
                                          !allTypes.includes(item?.name)
                                        ) {
                                          item?.name &&
                                            allTypes.push(item.name);
                                        }
                                      }
                                    );

                                  return (
                                    <>
                                      <Typography
                                        variant="body1"
                                        className="fieldCaption"
                                      >
                                        Opportunity Type
                                      </Typography>
                                      <MaterialSelect
                                        required
                                        name="type"
                                        value={type}
                                        label="Opportunity Type"
                                        placeholder="Select"
                                        onChange={(val) => {
                                          const value: string = val.target
                                            .value as string;
                                          setType(value);
                                        }}
                                        className={classes.opportunityType}
                                      >
                                        {allTypes.length &&
                                          allTypes
                                            ?.filter((item) => item !== 'N/A')
                                            .map((item) => (
                                              <MenuItem value={item}>
                                                {item}
                                              </MenuItem>
                                            ))}
                                      </MaterialSelect>
                                    </>
                                  );
                                }
                              }}
                            </Query>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={8} sm={12} xs={12}>
                          <Grid className="betweenSpacing">
                            <Typography
                              variant="body1"
                              className="fieldCaption"
                            >
                              Opportunity Title
                            </Typography>
                            <Field
                              required
                              name="programName"
                              placeholder="Opportunity Title"
                            />
                            <span className="requiredText">
                              <ErrorMessage name="programName" />
                            </span>
                            <Typography
                              variant="caption"
                              className="fieldHelperText"
                            >
                              (e.g. Summer at Columbia, American red Cross
                              Internship, etc.)
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item md={8} sm={12} xs={12}>
                          <Grid className="betweenSpacing">
                            <Typography
                              variant="body1"
                              className="fieldCaption"
                            >
                              Organization
                            </Typography>
                            <Field
                              required
                              name="organizationName"
                              placeholder="Organization"
                            />
                            <span className="requiredText">
                              <ErrorMessage name="organizationName" />
                            </span>
                            <Typography
                              variant="caption"
                              className="fieldHelperText"
                            >
                              (e.g. American Red Cross, Columbia University,
                              Women in Engineering, etc.)
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item md={12} sm={12} xs={12}>
                          <Grid className="betweenSpacing">
                            <Typography
                              variant="body1"
                              className="fieldCaption"
                            >
                              Website URL to Program Details/Application
                            </Typography>
                            <Field
                              required
                              name="landingPageUrl"
                              placeholder="Website URL to Program Details/Application"
                            />
                            <span className="requiredText">
                              <ErrorMessage name="landingPageUrl" />
                            </span>
                          </Grid>
                        </Grid>

                        <Grid item md={12} sm={12} xs={12}>
                          <Grid className="betweenSpacing">
                            <Typography
                              variant="body1"
                              className="fieldCaption"
                            >
                              Description
                            </Typography>

                            <Typography
                              variant="caption"
                              className="fieldHelperText"
                            >
                              Provide program details, benefits, age/demographic
                              requirements, etc. Please do not copy and paste
                              the original posting.
                            </Typography>

                            <TextareaAutosize
                              required
                              name="programDescription"
                              placeholder="Description"
                              aria-label="empty textarea"
                              rowsMin={3}
                              onChange={(val) => {
                                const value: string = val.target
                                  .value as string;
                                setDesctiption(value);
                                setFieldValue('programDescription', value);
                              }}
                            />

                            <Typography
                              variant="caption"
                              className="requiredText"
                            >
                              {errors && errors.programDescription}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item md={4} sm={12} xs={12}>
                          <Grid className="betweenSpacing">
                            <Typography
                              variant="body1"
                              className="fieldCaption"
                            >
                              Is there a fee to participate?
                            </Typography>
                            <MaterialSelect
                              required
                              value={programCost}
                              name="programCost"
                              onChange={(val) => {
                                const value: string = val.target
                                  .value as string;
                                setProgramCost(value);
                              }}
                              displayEmpty
                            >
                              <MenuItem disabled value={''}>
                                Select
                              </MenuItem>
                              <MenuItem value={'Free'}>Free</MenuItem>
                              <MenuItem value={'Paid'}>Paid</MenuItem>
                            </MaterialSelect>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container className="betweenSpacing">
                        <Grid item md={4} sm={12} xs={12}>
                          <Grid>
                            <Typography
                              variant="body1"
                              className="fieldCaption"
                            >
                              Is there a stipend offered?
                            </Typography>
                            <MaterialSelect
                              required
                              value={stipendGranted}
                              name="stipendGranted"
                              onChange={(val) => {
                                const value: string = val.target
                                  .value as string;
                                setStipendGranted(value);
                              }}
                              displayEmpty
                            >
                              <MenuItem disabled value={''}>
                                Select
                              </MenuItem>
                              <MenuItem value={'Yes'}>Yes</MenuItem>
                              <MenuItem value={'No'}>No</MenuItem>
                            </MaterialSelect>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container className="fullWidthField">
                        <Grid item md={4} sm={12} xs={12}>
                          <Grid>
                            <Typography
                              variant="body1"
                              className="fieldCaption"
                            >
                              Application Deadline Type
                            </Typography>
                            <MaterialSelect
                              required
                              value={deadlineType}
                              name="deadlineType"
                              onChange={(val) => {
                                const value: string = val.target
                                  .value as string;
                                setDeadlineType(value);
                              }}
                              displayEmpty
                              className={classes.deadlineType}
                            >
                              <MenuItem disabled value={''}>
                                Type
                              </MenuItem>
                              <MenuItem value={'SingleDate'}>
                                Single Date
                              </MenuItem>
                              <MenuItem value={'RollingAdmissions'}>
                                Rolling Admissions
                              </MenuItem>
                              <MenuItem value={'TBD'}>TBD</MenuItem>
                            </MaterialSelect>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          md={3}
                          sm={12}
                          xs={12}
                          className="formDatePicker formDatePickerMobile"
                        >
                          {deadlineType === 'SingleDate' ? (
                            <>
                              <Typography
                                variant="body1"
                                className="fieldCaption"
                              >
                                Date
                              </Typography>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  required={
                                    deadlineType === 'SingleDate' ? true : false
                                  }
                                  disableToolbar
                                  variant="inline"
                                  format="MM/dd/yyyy"
                                  id="date-picker-inline"
                                  label=""
                                  placeholder="MM/DD/YYYY"
                                  value={selectedDate}
                                  onChange={handleDateChange}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                  autoOk={true}
                                />
                              </MuiPickersUtilsProvider>
                            </>
                          ) : null}
                        </Grid>

                        <Grid
                          item
                          md={12}
                          sm={12}
                          xs={12}
                          className="dividerSpacing"
                        >
                          <Divider />
                        </Grid>
                      </Grid>

                      <>
                        <Grid className="checkboxGroup">
                          <Typography variant="h6" className="secondaryHeading">
                            Target Audience
                          </Typography>

                          <FormGroup row>
                            <FormControlLabel
                              color="primary"
                              control={
                                <Checkbox
                                  onClick={() =>
                                    setForIntlStudents(!forIntlStudents)
                                  }
                                />
                              }
                              label="Open to International Students"
                            />
                          </FormGroup>
                        </Grid>
                        <Grid className="checkboxGroup">
                          <Typography
                            variant="body1"
                            className="checkboxSectionSpacing"
                          >
                            Grades
                          </Typography>

                          <FormGroup row>
                            {grades.map((item) => (
                              <FormControlLabel
                                color="primary"
                                control={
                                  <Checkbox
                                    required={
                                      !targetGradeArray?.length ? true : false
                                    }
                                    onClick={() => onClickGrades(item)}
                                  />
                                }
                                label={`${item}th`}
                              />
                            ))}
                          </FormGroup>
                        </Grid>

                        <Grid className="checkboxGroup">
                          <Typography
                            variant="body1"
                            className="checkboxSectionSpacing"
                          >
                            What areas of interest does the opportunity apply
                            to?
                          </Typography>

                          <Grid container>
                            <Query<allSummerOpportunitiesCategoriesQuery>
                              query={ALL_CATEGORIES}
                              fetchPolicy="no-cache"
                            >
                              {({ data }) => {
                                if (!data) return null;
                                if (data) {
                                  let allCategories: string[] = [];
                                  data?.allSummerOpportunityCategories
                                    ?.summerOpportunityCategories &&
                                    data?.allSummerOpportunityCategories?.summerOpportunityCategories?.map(
                                      (item) => {
                                        if (
                                          item?.name &&
                                          !allCategories.includes(item?.name)
                                        ) {
                                          item?.name &&
                                            allCategories.push(item.name);
                                        }
                                      }
                                    );

                                  return (
                                    <>
                                      <Grid item md={5} sm={6} xs={12}>
                                        <FormGroup>
                                          {allCategories.length &&
                                            allCategories
                                              .filter(
                                                (item) =>
                                                  !STEMCategories.includes(
                                                    item
                                                  ) &&
                                                  item !== 'STEM' &&
                                                  item !== 'Other' &&
                                                  item !== 'Health Sciences'
                                              )
                                              .map((item) => (
                                                <FormControlLabel
                                                  color="primary"
                                                  control={
                                                    <Checkbox
                                                      required={
                                                        !subjectsCategories?.length
                                                          ? true
                                                          : false
                                                      }
                                                      onClick={() =>
                                                        onClickSubjects(item)
                                                      }
                                                    />
                                                  }
                                                  label={item}
                                                />
                                              ))}
                                        </FormGroup>
                                      </Grid>
                                    </>
                                  );
                                }
                              }}
                            </Query>

                            <Grid item md={7} sm={6} xs={12}>
                              <FormGroup>
                                <FormControlLabel
                                  color="primary"
                                  control={
                                    <Checkbox
                                      checked={
                                        !subjectsCategories?.includes('STEM')
                                          ? found
                                          : true
                                      }
                                      required={
                                        !subjectsCategories?.length
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        if (!e.target.checked) {
                                          if (subjectsCategories?.length) {
                                            const filtered = subjectsCategories?.filter(
                                              (item: string) =>
                                                !STEMCategories.includes(
                                                  item
                                                ) && item !== 'STEM'
                                            );
                                            setSubjectsCategories(filtered);
                                          }
                                        } else {
                                          onClickSubjects('STEM');
                                        }
                                      }}
                                    />
                                  }
                                  label="STEM"
                                />
                              </FormGroup>

                              <Box pl={3}>
                                <FormGroup>
                                  {STEMCategories.map((item) => (
                                    <FormControlLabel
                                      color="primary"
                                      control={
                                        <Checkbox
                                          checked={
                                            subjectsCategories &&
                                            subjectsCategories.includes(item)
                                              ? true
                                              : false
                                          }
                                          required={
                                            !subjectsCategories?.length
                                              ? true
                                              : false
                                          }
                                          onClick={() => onClickSubjects(item)}
                                        />
                                      }
                                      label={item}
                                    />
                                  ))}
                                </FormGroup>
                              </Box>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            md={12}
                            sm={12}
                            xs={12}
                            className="dividerSpacing dividerSpacingLocation"
                          >
                            <Divider />
                          </Grid>
                        </Grid>
                      </>

                      <Grid className="locationDateSection betweenSpacing">
                        <Typography variant="h6" className="secondaryHeading">
                          Location and Date
                        </Typography>
                        <Grid className="checkboxGroup remoteOpportunity">
                          <FormGroup row>
                            <FormControlLabel
                              name="isRemote"
                              color="primary"
                              control={
                                <Checkbox
                                  onClick={() => {
                                    setIsRemote(!isRemote);
                                    setlocationCountArray([]);
                                    setState('');
                                    setMetroArea('');
                                    setStartDate(null);
                                    setEndDate(null);
                                  }}
                                />
                              }
                              label="This is a remote opportunity"
                            />
                          </FormGroup>
                        </Grid>
                        {isRemote ? (
                          <Grid
                            container
                            spacing={3}
                            className="betweenSpacing formDatePicker"
                          >
                            <Grid item md={3} sm={12} xs={12}>
                              <Typography
                                variant="body1"
                                className="fieldCaption"
                              >
                                Start Date
                              </Typography>

                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  required
                                  disableToolbar
                                  variant="inline"
                                  format="MM/dd/yyyy"
                                  id="date-picker-inline"
                                  label=""
                                  placeholder="MM/DD/YYYY"
                                  value={startDate}
                                  onChange={handleStartDateChange}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                  autoOk={true}
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item md={3} sm={12} xs={12}>
                              <Typography
                                variant="body1"
                                className="fieldCaption"
                              >
                                End Date
                              </Typography>

                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  required
                                  disableToolbar
                                  variant="inline"
                                  format="MM/dd/yyyy"
                                  id="date-picker-inline"
                                  label=""
                                  placeholder="MM/DD/YYYY"
                                  value={endDate}
                                  onChange={handleEndDateChange}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                  autoOk={true}
                                  minDate={startDate}
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                          </Grid>
                        ) : null}

                        {!isRemote && (
                          <>
                            <Grid
                              container
                              spacing={2}
                              className="betweenSpacing"
                            >
                              <Grid item md={6} sm={12} xs={12}>
                                <Typography
                                  variant="body1"
                                  className="fieldCaption"
                                >
                                  City{' '}
                                </Typography>
                                <SimpleDropDown
                                  loadOptions={loadOptions}
                                  setValue={setCity}
                                  placeHolder="Select"
                                />
                              </Grid>

                              <Grid item md={3} sm={12} xs={12}>
                                <Typography
                                  variant="body1"
                                  className="fieldCaption"
                                >
                                  State
                                </Typography>
                                <Select
                                  placeholder="Select"
                                  searchable={true}
                                  options={optionsStates}
                                  isClearable
                                  required
                                  value={state}
                                  name="state"
                                  onChange={(val) => {
                                    setState(val);
                                  }}
                                  className="basic-single"
                                  classNamePrefix="select"
                                >
                                  {STATES?.map((item) => (
                                    <MenuItem value={item}>{item}</MenuItem>
                                  ))}
                                </Select>
                              </Grid>

                              <Grid item md={3} sm={12} xs={12}>
                                <Typography
                                  variant="body1"
                                  className="fieldCaption"
                                >
                                  Zip Code{' '}
                                </Typography>
                                <Field
                                  required
                                  name="zipCode"
                                  placeholder="Zip Code"
                                />
                              </Grid>
                            </Grid>

                            <Grid container className="fullWidthField">
                              <Query<allSummerOpportunitiesMetroAreasQuery>
                                query={ALL_METRO_AREAS}
                                fetchPolicy="no-cache"
                              >
                                {({ data, loading }) => {
                                  if (!data) return null;
                                  if (data) {
                                    let metroAreas: IOptionType[] = [];
                                    data?.allSummerOpportunitiesMetroAreas
                                      ?.allMetroAreas?.length &&
                                      data?.allSummerOpportunitiesMetroAreas?.allMetroAreas?.map(
                                        (item) => {
                                          if (item?.name) {
                                            item?.name &&
                                              metroAreas.push({
                                                value: item.name,
                                                label: item?.name,
                                              });
                                          }
                                        }
                                      );

                                    return (
                                      <Grid item md={4} sm={12} xs={12}>
                                        <Grid className="betweenSpacing">
                                          <Typography
                                            variant="body1"
                                            className="fieldCaption"
                                          >
                                            Metro Area (Optional)
                                          </Typography>
                                          <Select
                                            value={metroArea}
                                            onChange={handleMetroAreaChange}
                                            name="metro-area"
                                            placeholder="Select"
                                            options={metroAreas}
                                            className="basic-single"
                                            classNamePrefix="select"
                                          >
                                          </Select>
                                        </Grid>
                                      </Grid>
                                    );
                                  }
                                }}
                              </Query>
                            </Grid>

                            <Grid
                              container
                              spacing={3}
                              className="betweenSpacing formDatePicker"
                            >
                              <Grid item md={3} sm={12} xs={12}>
                                <Typography
                                  variant="body1"
                                  className="fieldCaption"
                                >
                                  Start Date
                                </Typography>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    required
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    id="date-picker-inline"
                                    label=""
                                    placeholder="MM/DD/YYYY"
                                    value={startDate}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                    }}
                                    autoOk={true}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>

                              <Grid item md={3} sm={12} xs={12}>
                                <Typography
                                  variant="body1"
                                  className="fieldCaption"
                                >
                                  End Date
                                </Typography>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    required
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    id="date-picker-inline"
                                    label=""
                                    placeholder="MM/DD/YYYY"
                                    value={endDate}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                    }}
                                    autoOk={true}
                                    minDate={startDate}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                            </Grid>

                            {locationCountArray.map((item, index) => (
                              <>
                                <Divider style={{ marginBottom: '30px' }} />
                                <Grid
                                  container
                                  spacing={2}
                                  className="betweenSpacing"
                                >
                                  <Grid item md={5} sm={12} xs={12}>
                                    <Typography
                                      variant="body1"
                                      className="fieldCaption"
                                    >
                                      City{' '}
                                    </Typography>
                                    <CityDropdownLocation
                                      loadOptions={loadOptions}
                                      setValue={setlocationCountArray}
                                      locationCountArray={locationCountArray}
                                      index={index}
                                      placeHolder="Select"
                                    />
                                  </Grid>

                                  <Grid item md={2} sm={12} xs={12}>
                                    <Typography
                                      variant="body1"
                                      className="fieldCaption"
                                    >
                                      State
                                    </Typography>
                                    <Select
                                      placeholder="Select"
                                      searchable={true}
                                      options={optionsStates}
                                      isClearable
                                      required
                                      value={
                                        locationCountArray[index]?.state?.value
                                      }
                                      name="state"
                                      onChange={(val) => {
                                        const value: string = val?.value as string;
                                        locationCountArray[index].state = value;
                                        setRerender(value);
                                        setlocationCountArray(
                                          locationCountArray
                                        );
                                      }}
                                    >
                                      {STATES?.map((item) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                      ))}
                                    </Select>
                                  </Grid>

                                  <Grid item md={3} sm={12} xs={12}>
                                    <Typography
                                      variant="body1"
                                      className="fieldCaption"
                                    >
                                      Zip Code{' '}
                                    </Typography>
                                    <input
                                      required
                                      type="text"
                                      value={item?.zipCode || ''}
                                      onChange={(event) =>
                                        handleInputOnChange(
                                          index,
                                          event,
                                          'zipCode'
                                        )
                                      }
                                      placeholder="Zip Code"
                                    />
                                  </Grid>
                                  <Hidden only={['sm', 'xs']}>
                                    <Box
                                      style={{
                                        position: 'relative',
                                        top: '30px',
                                        left: '30px',
                                      }}
                                    >
                                      <IconButton
                                        aria-label="delete"
                                        onClick={() => {
                                          locationCountArray.splice(index, 1);
                                          setlocationCountArray(
                                            locationCountArray
                                          );
                                          setIndexTrack(indexTrack - 1);
                                        }}
                                      >
                                        <img
                                          src={DELETE_PAYOUT_ICON}
                                          alt="delete"
                                        />
                                      </IconButton>
                                    </Box>
                                  </Hidden>
                                </Grid>
                                <Grid container className="fullWidthField">
                                  <Query<allSummerOpportunitiesMetroAreasQuery>
                                    query={ALL_METRO_AREAS}
                                    fetchPolicy="no-cache"
                                  >
                                    {({ data }) => {
                                      if (!data) return null;
                                      if (data) {
                                        let metroAreas: IOptionType[] = [];
                                        data?.allSummerOpportunitiesMetroAreas
                                          ?.allMetroAreas?.length &&
                                          data?.allSummerOpportunitiesMetroAreas?.allMetroAreas?.map(
                                            (item) => {
                                              if (item?.name) {
                                                metroAreas.push({
                                                  value: item.name,
                                                  label: item?.name,
                                                });
                                              }
                                            }
                                          );

                                        return (
                                          <Grid item md={4} sm={12} xs={12}>
                                            <Grid className="betweenSpacing">
                                              <Typography
                                                variant="body1"
                                                className="fieldCaption"
                                              >
                                                Metro Area (Optional)
                                              </Typography>
                                              <Select
                                                onChange={(value) => {
                                                  locationCountArray[
                                                    index
                                                  ].metroArea = value?.value;
                                                  setRerender(value?.value);
                                                  setlocationCountArray(
                                                    locationCountArray
                                                  );
                                                }}
                                                placeholder="Select"
                                                options={metroAreas}
                                                value={{
                                                  value:
                                                    locationCountArray[index]
                                                      .metroArea,
                                                  label:
                                                    locationCountArray[index]
                                                      .metroArea,
                                                }}
                                                className={classes.metroArea}
                                              />
                                            </Grid>
                                          </Grid>
                                        );
                                      }
                                    }}
                                  </Query>
                                </Grid>

                                <Grid
                                  container
                                  spacing={3}
                                  className="betweenSpacing formDatePicker"
                                >
                                  <Grid item md={3} sm={12} xs={12}>
                                    <Typography
                                      variant="body1"
                                      className="fieldCaption"
                                    >
                                      Start Date
                                    </Typography>

                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <KeyboardDatePicker
                                        required
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        label=""
                                        placeholder="MM/DD/YYYY"
                                        value={item.programStartDate}
                                        onChange={(date, value) => {
                                          let obj = locationCountArray[index];
                                          obj = {
                                            ...obj,
                                            programStartDate: date || null,
                                          };

                                          const newArray = [
                                            ...locationCountArray.slice(
                                              0,
                                              index
                                            ),
                                            obj,
                                            ...locationCountArray.slice(
                                              index + 1
                                            ),
                                          ];
                                          setlocationCountArray(newArray);
                                        }}
                                        KeyboardButtonProps={{
                                          'aria-label': 'change date',
                                        }}
                                        autoOk={true}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </Grid>

                                  <Grid item md={3} sm={12} xs={12}>
                                    <Typography
                                      variant="body1"
                                      className="fieldCaption"
                                    >
                                      End Date
                                    </Typography>

                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <KeyboardDatePicker
                                        required
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        label=""
                                        placeholder="MM/DD/YYYY"
                                        value={item.programEndDate}
                                        onChange={(date, value) => {
                                          let obj = locationCountArray[index];
                                          obj = {
                                            ...obj,
                                            programEndDate: date || null,
                                          };

                                          const newArray = [
                                            ...locationCountArray.slice(
                                              0,
                                              index
                                            ),
                                            obj,
                                            ...locationCountArray.slice(
                                              index + 1
                                            ),
                                          ];
                                          setlocationCountArray(newArray);
                                        }}
                                        KeyboardButtonProps={{
                                          'aria-label': 'change date',
                                        }}
                                        autoOk={true}
                                        minDate={item.programStartDate}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </Grid>
                                </Grid>
                                <Hidden only={['xl', 'lg', 'md']}>
                                  <Box
                                    style={{
                                      position: 'relative',
                                      left: '20px',
                                    }}
                                  >
                                    <IconButton
                                      aria-label="delete"
                                      onClick={() => {
                                        locationCountArray.splice(index, 1);
                                        setlocationCountArray(
                                          locationCountArray
                                        );
                                        setIndexTrack(indexTrack - 1);
                                      }}
                                    >
                                      <img
                                        src={DELETE_PAYOUT_ICON}
                                        alt="delete"
                                      />
                                    </IconButton>
                                  </Box>
                                </Hidden>
                              </>
                            ))}

                            <Grid>
                              <Button
                                variant="text"
                                color="primary"
                                className={classes.addNewLocation}
                                onClick={() => {
                                  locationCountArray.splice(indexTrack, 0, {
                                    city: '',
                                    state: '',
                                    zipCode: '',
                                    metroArea: '',
                                    programStartDate: null,
                                    programEndDate: null,
                                  });
                                  setlocationCountArray(locationCountArray);
                                  setIndexTrack(indexTrack + 1);
                                }}
                              >
                                <img src={PLUS_ICON} alt="add" /> ADD NEW
                                LOCATION AND DATE
                              </Button>
                            </Grid>
                          </>
                        )}
                      </Grid>

                      <Grid className={classes.formFooterDivider}>
                        <Button
                          disabled={isSubmitting}
                          type="submit"
                          color="primary"
                          variant="contained"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Card>
          );
        }}
      </Mutation>
    </>
  );
};

export default SummerOpportunitiesSubmission;
