import { ChangeEvent, useState } from 'react';
import clsx from 'clsx';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Box,
  TableFooter,
  TablePagination,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Hidden,
  Card,
  InputBase,
  Button,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import FILE_ICON from '../../../img/file.svg';
import DRIVE_ICON from '../../../img/drive.png';
import EssayPromptDialog from './EssayPromptDialog';

import { useStyles } from './MyStudentsStyle';
import {
  StudentDetailQuery_Student_studentApplications,
  StudentDetailQuery_Student_studentApplications_studentApplicationEssays,
  StudentDetailQuery_Student_studentApplications_applicationRequirement_college,
  StudentDetailQuery_Student_studentDocuments,
} from './__generated__/StudentDetailQuery';

const ROWS_PER_PAGE = 5;

const StudentEssaysTable = ({
  applications,
  title,
  studentDocuments,
}: {
  applications: (StudentDetailQuery_Student_studentApplications | null)[];
  studentDocuments:
    | (StudentDetailQuery_Student_studentDocuments | null)[]
    | null;
  title: string;
}) => {
  const classes = useStyles();
  const [openModel, toggleModel] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const updateModel = () => {
    toggleModel(!openModel);
  };
  const [searchQuery, setSearchQuery] = useState('');

  let essaysObjects: any = {};
  let allEssays = Object.values(essaysObjects);
  applications.forEach((application) => {
    const essays = application && application.studentApplicationEssays;
    const college =
      application &&
      application.applicationRequirement &&
      application.applicationRequirement.college;

    if (essays) {
      let count = 1;
      essays?.map((essay) => {
        if (essay?.essayTemplate && !essay?.essayTemplate?.commonEssay) {
          allEssays.push({
            application,
            essay: {
              ...essay,

              prompt: `Supplemental ${count}`,
            },
            college,
          });

          count++;
        }
      });
    }
  });

  const bodyTableCell = (name: string | null) => {
    return <TableCell>{name || '--'}</TableCell>;
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const readMoreModalDefaultValue = {
    logo: '',
    name: '',
    description: [''],
    essayName: '',
    wordCount: 0,
    url: '',
    bgDark: '',
    bgLight: '',
    lengthLimit: '',
  };
  const [readMoreModalProps, setReadMoreModalProps] = useState(
    readMoreModalDefaultValue
  );
  type documentEssay = {
    createdAt: String;
    updatedAt: String;
    category: String;
    documentName: String;
    documentUrl: String;
    prompt: String;
  };
  const renderTableRows = (
    key: string,
    essay: StudentDetailQuery_Student_studentApplications_studentApplicationEssays &
      documentEssay,
    college: StudentDetailQuery_Student_studentApplications_applicationRequirement_college,
    year: string | null,
    application: StudentDetailQuery_Student_studentApplications | null
  ) => {
    return (
      <TableRow key={key}>
        <TableCell className={classes.categoryThPadding}>
          <Box maxWidth={220}>
            {essay.category ? essay.category : 'Application Essay'}
          </Box>
        </TableCell>

        <TableCell>
          <Box maxWidth={367}>
            <Typography noWrap>{(college && college.name) || '--'}</Typography>
          </Box>
        </TableCell>

        <TableCell>
          {essay?.essayTemplate ? (
            <>
              <Box
                display="flex"
                alignItems="center"
                className={classes.essayPromptDialog}
                onClick={() => {
                  if (!college || !essay.essayTemplate) return false;
                  const { name, styleConfig } = college;
                  const bgDark = (styleConfig && styleConfig.bgDark) || '';

                  const bgLight = (styleConfig && styleConfig.bgLight) || '';
                  let longDescription: string[] = [];

                  if (essay.essayTemplate?.shortDescription) {
                    longDescription.push(essay.essayTemplate?.shortDescription);
                    if (essay?.essayTemplate?.longDescription) {
                      essay?.essayTemplate?.longDescription
                        .split('#')
                        .forEach((element: string) => {
                          if (element) {
                            longDescription.push(element);
                          }
                        });
                    }
                  }
                  setReadMoreModalProps({
                    logo: college.logo || '',
                    name,
                    description: longDescription,
                    essayName: (essay?.prompt as string) || '',
                    wordCount: 0,
                    url: college.admissionPageUrl || '',
                    bgDark,
                    bgLight,
                    lengthLimit: essay.essayTemplate.lengthLimit || '',
                  });
                  updateModel();
                }}
              >
                {essay?.prompt}
              </Box>
            </>
          ) : essay?.prompt ? (
            essay?.prompt
          ) : (
            '--'
          )}
        </TableCell>

        <TableCell>
          {essay?.documentUrl?.length ? (
            <a
              href={essay.documentUrl || ''}
              target="_blank"
              rel="noopener noreferrer"
            >
              {essay.documentUrl.includes('google') ? (
                <img src={DRIVE_ICON} alt="drive" style={{ width: '23px' }} />
              ) : (
                <img src={FILE_ICON} alt="file" />
              )}
            </a>
          ) : (
            <Box component="span">--</Box>
          )}
        </TableCell>

        {bodyTableCell(
          essay?.updatedAt || essay?.completedAt
            ? new Date(
                essay?.updatedAt || essay?.completedAt
              ).toLocaleDateString()
            : '--'
        )}
      </TableRow>
    );
  };

  const renderTableRowsMobile = (
    key: string,
    essay: StudentDetailQuery_Student_studentApplications_studentApplicationEssays &
      documentEssay,
    college: StudentDetailQuery_Student_studentApplications_applicationRequirement_college,
    year: string | null,
    application: StudentDetailQuery_Student_studentApplications | null
  ) => {
    return (
      <Card key={key}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="body1" className="universityName" noWrap>
            {(college && college.name) || '--'}
          </Typography>

          <Box>
            <Typography variant="body1">
              {essay?.updatedAt || essay?.completedAt
                ? new Date(
                    essay?.updatedAt || essay?.completedAt
                  ).toLocaleDateString()
                : '--'}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            {essay?.essayTemplate ? (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.essayPromptDialog}
                  onClick={() => {
                    if (!college || !essay.essayTemplate) return false;
                    const { name, styleConfig } = college;
                    const bgDark = (styleConfig && styleConfig.bgDark) || '';

                    const bgLight = (styleConfig && styleConfig.bgLight) || '';
                    let longDescription: string[] = [];

                    if (essay.essayTemplate?.shortDescription) {
                      longDescription.push(
                        essay.essayTemplate?.shortDescription
                      );
                      if (essay?.essayTemplate?.longDescription) {
                        essay?.essayTemplate?.longDescription
                          .split('#')
                          .forEach((element: string) => {
                            if (element) {
                              longDescription.push(element);
                            }
                          });
                      }
                    }
                    setReadMoreModalProps({
                      logo: college.logo || '',
                      name,
                      description: longDescription,
                      essayName: (essay?.prompt as string) || '',
                      wordCount: 0,
                      url: college.admissionPageUrl || '',
                      bgDark,
                      bgLight,
                      lengthLimit: essay.essayTemplate.lengthLimit || '',
                    });
                    updateModel();
                  }}
                >
                  {essay?.prompt}
                </Box>
              </>
            ) : essay?.prompt ? (
              essay.prompt
            ) : (
              '--'
            )}
          </Box>

          <Box>
            {essay?.documentUrl?.length ? (
              <>
                <a
                  href={essay.documentUrl || ''}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {essay.documentUrl.includes('google') ? (
                    <img src={DRIVE_ICON} alt="drive" style={{ width: 22 }} />
                  ) : (
                    <img src={FILE_ICON} alt="file" />
                  )}
                </a>
                <Typography variant="caption" className="fileOpenText">
                  Open
                </Typography>
              </>
            ) : (
              <Box component="span">--</Box>
            )}
          </Box>
        </Box>
      </Card>
    );
  };

  const documents = studentDocuments
    ?.filter((item) => item?.documentUrl?.length)
    ?.map((document) => {
      const essay = {
        updatedAt: document?.updatedAt || '',
        createdAt: document?.createdAt || '',
        category: document?.category || '',
        documentName: document?.documentName || '',
        documentUrl: document?.documentUrl || '',
        prompt: document?.documentName
          ? document?.documentName
          : document?.essayTitle || '--',
      };
      const college = document?.College || {};
      return { application: {}, essay, college };
    });
  const filteredAllEssays = allEssays.filter(
    (item: any) => item?.essay?.documentUrl != null
  );

  const allDocuments = [...filteredAllEssays, ...(documents || [])];

  const [status, setStatus] = useState('ApplicationEssays');

  let studentApplicationDocuments: any = filteredAllEssays;
  if (status === 'OtherDocuments') {
    studentApplicationDocuments = documents?.filter(
      (item: any) =>
        !item?.essay?.category?.includes('Extracurricular') &&
        !item?.essay?.category.includes('Scholarship') &&
        item?.essay?.category
          .toLocaleLowerCase()
          .includes(searchQuery.toLocaleLowerCase())
    );
  }
  if (status === 'Extracurriculars') {
    studentApplicationDocuments = documents?.filter(
      (item: any) =>
        item?.essay?.category?.includes('Extracurricular') &&
        item?.essay?.category
          .toLocaleLowerCase()
          .includes(searchQuery.toLocaleLowerCase())
    );
  }
  if (status === 'Scholarship') {
    studentApplicationDocuments = documents?.filter(
      (item: any) =>
        item?.essay?.category?.includes('Scholarship') &&
        item?.essay?.category
          .toLocaleLowerCase()
          .includes(searchQuery.toLocaleLowerCase())
    );
  }
  if (status === 'ApplicationEssays') {
    studentApplicationDocuments = filteredAllEssays?.filter((item: any) =>
      item?.college?.name
        .toLocaleLowerCase()
        .includes(searchQuery.toLocaleLowerCase())
    );
  }

  const handleSubmit = (event: any) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Grid className={classes.commonTable}>
      <Accordion className={classes.tableAccordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="accordionTitle">{`${title} (${allDocuments.length})`}</Typography>
        </AccordionSummary>

        <AccordionDetails style={{ flexDirection: 'column' }}>
          <Box
            className={clsx(
              classes.searchComponent,
              classes.studentDocumentsSearch
            )}
          >
            <InputBase
              placeholder="Search Documents"
              onKeyPress={(e) => e.key === 'Enter' && handleSubmit(e)}
            />
          </Box>

          <Box overflow="auto" className={classes.studentListTabs}>
            <Box className={classes.studentDocumentsTab}>
              <Button
                variant="text"
                onClick={() => {
                  setStatus('ApplicationEssays');
                  setPage(0);
                  setSearchQuery('');
                }}
                className={status === 'ApplicationEssays' ? 'active' : ''}
              >
                Application Essays
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  setStatus('Extracurriculars');
                  setPage(0);
                  setSearchQuery('');
                }}
                className={status === 'Extracurriculars' ? 'active' : ''}
              >
                {'Extracurriculars & Awards'}
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  setStatus('Scholarship');
                  setPage(0);
                  setSearchQuery('');
                }}
                className={status === 'Scholarship' ? 'active' : ''}
              >
                {'Scholarship Essays'}
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  setStatus('OtherDocuments');
                  setPage(0);
                  setSearchQuery('');
                }}
                className={status === 'OtherDocuments' ? 'active' : ''}
              >
                Other Documents
              </Button>
            </Box>

            <Hidden only={['sm', 'xs']}>
              {studentApplicationDocuments?.length > 0 ? (
                <Table
                  aria-labelledby="mentorTimeLogs"
                  size="medium"
                  aria-label="enhanced table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.categoryThPadding}
                        width="20%"
                      >
                        Category
                      </TableCell>

                      <TableCell width="35%">College</TableCell>

                      <TableCell width="20%">Essay Prompt</TableCell>

                      <TableCell width="10%">File</TableCell>

                      <TableCell width="15%">Upload Date</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {studentApplicationDocuments &&
                    studentApplicationDocuments.length ? (
                      studentApplicationDocuments
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map(
                          (
                            { essay, college, year, application }: any,
                            i: any
                          ) => {
                            return renderTableRows(
                              `${i}`,
                              essay,
                              college,
                              year,
                              application
                            );
                          }
                        )
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={6}
                          className="noRecordFound"
                          align="center"
                        >
                          No document's found!
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>

                  {studentApplicationDocuments?.length > 0 && (
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={8}>
                          <TablePagination
                            rowsPerPageOptions={[ROWS_PER_PAGE]}
                            component="div"
                            count={studentApplicationDocuments.length || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                          />
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  )}
                </Table>
              ) : (
                <Box className={classes.emptyStates}>
                  <Typography variant="body1">
                    No{' '}
                    {status === 'ApplicationEssays'
                      ? 'application essays'
                      : ` ${status} essays`}{' '}
                    found
                  </Typography>
                </Box>
              )}
            </Hidden>
          </Box>
        </AccordionDetails>

        <Hidden only={['xl', 'lg', 'md']}>
          <AccordionDetails className={classes.studentDocumentMobileView}>
            {studentApplicationDocuments &&
            studentApplicationDocuments.length ? (
              studentApplicationDocuments
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(({ essay, college, year, application }: any, i: any) => {
                  return renderTableRowsMobile(
                    `${i}`,
                    essay,
                    college,
                    year,
                    application
                  );
                })
            ) : (
              <Box
                className={classes.noRecordFoundBox}
                textAlign="center"
                width="100%"
              >
                No document's found!
              </Box>
            )}

            {studentApplicationDocuments.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[ROWS_PER_PAGE]}
                component="div"
                count={studentApplicationDocuments.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
          </AccordionDetails>
        </Hidden>
      </Accordion>

      <EssayPromptDialog
        {...readMoreModalProps}
        onClose={updateModel}
        open={openModel}
      />
    </Grid>
  );
};

export default StudentEssaysTable;
