import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { useStylesTable } from '../timeLogs/tableCommonStyle';

import ARROW_DOWN from '../../../img/ArrowDown.svg';
import ARROW_UP from '../../../img/ArrowUp.svg';
import MULTIPLE_SORTING_ICON from '../../../img/multiple-sorting.svg';
import { ALL_MENTOR_TIME_LOGS } from './gql';
import { allMentorTimeLogsQuery_allMentorTimeLogs } from './gql/__generated__/allMentorTimeLogsQuery';
import { useStyles } from '../students/MyStudentsStyle';
import CustomSearchSelect from '../timeLogs/CustomSearchSelect';
import DownloadTimLogsInCsv from '../timeLogs/DonwloadTimeLogsInCsv';
import TableRecord from './TableRecord';

interface OptionType {
  value: number;
  label: string;
  initialKickOffDate?: string | null;
}

const PayoutsTable = ({ client }: { client: any }) => {
  const classes = useStyles();

  const [searchQuery, SetSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const tableClasses = useStylesTable();

  const [mentorTimeLogs, setMentorTimeLogs] =
    useState<allMentorTimeLogsQuery_allMentorTimeLogs | null>();
  const [note, setNote] = React.useState('');
  const [sortEntity, setSortEntity] = useState('');
  const [sortType, setSortType] = useState('');
  const [studentNames, setStudentNames] = useState<string[]>([]);
  const [refetchQuery, setRefetchQuery] = useState<boolean>(false);

  const fetchMentorTimeLogs = async () => {
    try {
      setLoading(true);
      let query: any = {
        query: ALL_MENTOR_TIME_LOGS,
        fetchPolicy: 'network-only',
        variables: {
          page: 1,
          limit: 50,
          searchQuery: searchQuery,
          sortBy: sortEntity,
          sortType: sortType?.toUpperCase(),
          portal: 'Advisor',
        },
      };

      const {
        data: { allMentorTimeLogs },
      } = await client.query(query);
      if (allMentorTimeLogs?.mentorTimeLogs) {
        setMentorTimeLogs(allMentorTimeLogs);
      }
      setLoading(false);

      if (!studentNames.length) {
        let arrayOfNames: string[] = [];
        allMentorTimeLogs?.mentorTimeLogs?.map((item: any) => {
          if (
            !arrayOfNames.includes(
              item?.student?.firstName + ' ' + item?.student?.lastName
            )
          ) {
            item?.student?.firstName &&
              arrayOfNames.push(
                item?.student.firstName + ' ' + item?.student?.lastName
              );
          }
        });
        setStudentNames(arrayOfNames);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchMentorTimeLogs();
    setPage(0);
  }, [refetchQuery, searchQuery]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSort = (sortEn: string) => {
    if (sortEn !== sortEntity) {
      setSortEntity(sortEn);
      setSortType('asc');
    } else {
      switch (sortType) {
        case '':
          setSortType('asc');
          break;
        case 'asc':
          setSortType('desc');
          break;
        case 'desc':
          setSortType('');
          break;
        default:
          setSortType('');
      }
    }
    setRefetchQuery(!refetchQuery);
  };
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pb={3}
        className={tableClasses.payoutFilter}
      >
        <Grid className={tableClasses.forDropdownArrow}>
          <Grid className={tableClasses.studentSearchPayout}>
            <CustomSearchSelect
              placeholder="- Student Name -"
              isClearable={true}
              setSelectedValue={(option: OptionType) => {
                SetSearchQuery(option.label.split('(')[0].trim() || '');
              }}
            />
          </Grid>
        </Grid>

        {mentorTimeLogs && mentorTimeLogs?.mentorTimeLogs?.length ? (
          <DownloadTimLogsInCsv
            mentorTimeLogs={mentorTimeLogs?.mentorTimeLogs}
          />
        ) : (
          <></>
        )}
      </Box>

      <Grid className={tableClasses.commonTable}>
        <Table
          aria-labelledby="Payouts"
          size={'medium'}
          aria-label="enhanced table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Box
                  className={
                    sortEntity === 'activityAt' && sortType != ''
                      ? `${classes.selectedColumnSorting} sortingButton dateWidth`
                      : 'sortingButton dateWidth'
                  }
                  onClick={() => handleSort('activityAt')}
                >
                  Date
                  {sortType === 'asc' && sortEntity === 'activityAt' && (
                    <img src={ARROW_UP} alt="multiple" />
                  )}
                  {sortType === 'desc' && sortEntity === 'activityAt' && (
                    <img src={ARROW_DOWN} alt="multiple" />
                  )}
                  {sortEntity !== 'activityAt' && (
                    <img src={MULTIPLE_SORTING_ICON} alt="multiple" />
                  )}
                  {sortEntity === 'activityAt' && sortType === '' && (
                    <img src={MULTIPLE_SORTING_ICON} alt="multiple" />
                  )}
                </Box>
              </TableCell>

              <TableCell align="left">
                <Box
                  className={
                    sortEntity === 'workActivity' && sortType != ''
                      ? `${classes.selectedColumnSorting} sortingButton`
                      : 'sortingButton'
                  }
                  onClick={() => handleSort('workActivity')}
                >
                  ACTIVITY{' '}
                  {sortType === 'asc' && sortEntity === 'workActivity' && (
                    <img src={ARROW_UP} alt="multiple" />
                  )}
                  {sortType === 'desc' && sortEntity === 'workActivity' && (
                    <img src={ARROW_DOWN} alt="multiple" />
                  )}
                  {sortEntity !== 'workActivity' && (
                    <img src={MULTIPLE_SORTING_ICON} alt="multiple" />
                  )}
                  {sortEntity === 'workActivity' && sortType === '' && (
                    <img src={MULTIPLE_SORTING_ICON} alt="multiple" />
                  )}
                </Box>
              </TableCell>

              <TableCell align="left">STUDENT NAME</TableCell>

              <TableCell align="left" onClick={() => handleSort('amount')}>
                <Box
                  className={
                    sortEntity === 'amount' && sortType != ''
                      ? `${classes.selectedColumnSorting} sortingButton`
                      : 'sortingButton'
                  }
                >
                  AMOUNT
                  {sortType === 'asc' && sortEntity === 'amount' && (
                    <img src={ARROW_UP} alt="multiple" />
                  )}
                  {sortType === 'desc' && sortEntity === 'amount' && (
                    <img src={ARROW_DOWN} alt="multiple" />
                  )}
                  {sortEntity !== 'amount' && (
                    <img src={MULTIPLE_SORTING_ICON} alt="multiple" />
                  )}
                  {sortEntity === 'amount' && sortType === '' && (
                    <img src={MULTIPLE_SORTING_ICON} alt="multiple" />
                  )}
                </Box>
              </TableCell>

              <TableCell>Status</TableCell>

              <TableCell></TableCell>

              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell className="noRecordFound fontWeight" colSpan={9}>
                  loading...
                </TableCell>
              </TableRow>
            ) : mentorTimeLogs?.mentorTimeLogs?.length ? (
              mentorTimeLogs?.mentorTimeLogs
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((item, index) => {
                  return (
                    <>
                      <TableRecord
                        key={index}
                        SetSearchQuery={SetSearchQuery}
                        item={item}
                        refetch={fetchMentorTimeLogs}
                        setMentorTimeLogs={setMentorTimeLogs}
                        setNote={setNote}
                      />
                    </>
                  );
                })
            ) : (
              <TableRow>
                <TableCell className="noRecordFound fontWeight" colSpan={9}>
                  No Record Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>

          {mentorTimeLogs?.mentorTimeLogs?.length ? (
            <TableFooter>
              <TableRow>
                <TableCell colSpan={7} className={tableClasses.paginationCell}>
                  <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={
                      mentorTimeLogs?.mentorTimeLogsCount &&
                      mentorTimeLogs?.mentorTimeLogsCount > 50
                        ? 50
                        : mentorTimeLogs?.mentorTimeLogsCount || 0
                    }
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          ) : null}
        </Table>
      </Grid>
    </>
  );
};

export default PayoutsTable;
