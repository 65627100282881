import { FC, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  ThemeProvider,
  Typography,
} from '@material-ui/core';

import {
  getStudentApplicationDeadline,
  selectColor,
} from '../../../common/utility';
import CLOSE_ICON from '../../../img/dialog-close-gray.svg';
import { MenuProps } from '../../../styles/common';
import { customTheme } from '../../../pages/MaterializeCss';
import { AddApplicationProps } from '../../../common/interfaces';
import { useStyles } from '../style';

const AddApplicationDialog: FC<AddApplicationProps> = ({
  open,
  onClose,
  onSubmit,
  deadlines,
  student,
}) => {
  const [decisionStatus, setDecisionStatus] = useState<any>(
    deadlines[0]?.key || null
  );
  const classes = useStyles();
  return (
    <>
      <Dialog
        open={open}
        onClose={() => onClose(false)}
        className={classes.addNewEssayDialog}
      >
        <DialogContent>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h4">Start New Application</Typography>

            <IconButton onClick={() => onClose(false)}>
              <img src={CLOSE_ICON} alt="close" />
            </IconButton>
          </Box>

          <Typography variant="body1">
            Select your application deadline to begin your application. We
            recommend starting out with regular decision and making adjustments
            as needed.
          </Typography>

          <Grid container style={{ alignItems: 'flex-end' }}>
            {deadlines?.length > 0 && (
              <Grid item md={8} sm={12} xs={12}>
                <Typography variant="caption" className="fieldCaption">
                  Deadline
                </Typography>

                <FormControl>
                  <ThemeProvider theme={customTheme}>
                    <Select
                      variant="outlined"
                      MenuProps={MenuProps}
                      value={decisionStatus}
                      onChange={(event) =>
                        setDecisionStatus(event.target.value)
                      }
                      className={`selectLarge ${selectColor(decisionStatus)}
                      `}
                    >
                      {deadlines &&
                        deadlines?.map((deadline: any) => (
                          <MenuItem
                            key={deadline?.key}
                            value={deadline?.key || 0}
                          >
                            <Box display="flex">
                              <span> {deadline?.display}</span>-
                              <span>
                                {getStudentApplicationDeadline(
                                  student?.highSchoolYear,
                                  deadline?.value,
                                  student?.graduated,
                                  student?.gapYear
                                )}
                              </span>
                            </Box>
                          </MenuItem>
                        ))}
                    </Select>
                  </ThemeProvider>
                </FormControl>
              </Grid>
            )}

            <Grid item md={4} sm={12} xs={12}>
              <ThemeProvider theme={customTheme}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => onSubmit(decisionStatus)}
                >
                  Start
                </Button>
              </ThemeProvider>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddApplicationDialog;
