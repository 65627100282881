import { ChangeEvent, useContext, useEffect, useState } from 'react';
import {
  Dialog,
  Box,
  Typography,
  TextareaAutosize,
  InputLabel,
  Button,
  IconButton,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Spinner } from 'react-bootstrap';

import { useStyles } from './StudentNotesStyle';

import { AppContext } from '../../contexts';
import client from '../../apollo';
import Dialog_Cross_Img from '../../img/close-dialog-updated.svg';

import { UPDATE_OR_SAVE_STUDENT_NOTES } from '../../graphql/mutations/StudentNotes';
import { argsToArgsConfig } from 'graphql/type/definition';

const OtherApplicationDialogBox = ({
  StudentId,
  openDialog,
  handleClose,
  // refetch,
  description,
  applicationType,
  quantity,
  saveData,
  
  // NoteId,
}: {
  StudentId: number;
  openDialog: boolean;
  handleClose: Function;
  saveData: Function;
  // refetch: Function;
  description?: any;
  applicationType: string;
  quantity:number;
  // NoteId: number | null;
}) => {
  const classes = useStyles();
  const { user } = useContext(AppContext);
  const [noteValue, setNoteValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [appCount, setAppCount] = useState(0);


  const changeQuantity = (selectedValue:any) => {
    setAppCount(selectedValue.target.value);
  }

  const handleTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNoteValue(e.target.value);
  };

  const onDialogClose = () => {
    handleClose();
    setNoteValue('');
    setAppCount(0);
  };

  const saveToDB = () => {
    saveData(applicationType, Number(appCount), noteValue);
    setNoteValue('');
    setAppCount(0);
    handleClose();
  }

  useEffect (() => {
    setNoteValue(description);
    setAppCount(quantity);
  },[applicationType]);

  return (
    <Dialog open={openDialog} maxWidth="sm" className={classes.addNoteDialog}>
      <Box>
        <Box
          display={'flex'}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box>
            <Typography variant="h4" className={classes.popUpHeader}>{applicationType === 'extraCurrApplication' ? 'Extracurricular Application' : 'Scholarship Application'}</Typography>
          </Box>

          <IconButton onClick={onDialogClose}>
            <img src={Dialog_Cross_Img} alt="cross" width="12px" />
          </IconButton>
        </Box>

        <Box>
          <Typography variant="body1" className={classes.textFieldCaption}>Quantity</Typography>
          <Select className={classes.dropDownQuantity} value={appCount} defaultValue="select" name="Title" onChange={(e) => changeQuantity(e)}>
          <MenuItem key="0" value="0">select</MenuItem>
            <MenuItem key="1" value="1">1</MenuItem>
            <MenuItem key="2" value="2">2</MenuItem>
            <MenuItem key="3" value="3">3</MenuItem>
            <MenuItem key="4" value="4">4</MenuItem>
            <MenuItem key="5" value="5">5</MenuItem>
          </Select>
        </Box>

        <Box mt={4} className={classes.descField}>
          <InputLabel>Description</InputLabel>

          <TextareaAutosize
            className="textArea"
            onChange={handleTextArea}
            rowsMin={5}
            aria-label="note textarea"
            placeholder="Write your note here"
            value={noteValue}
          />
        </Box>

        <Box mt={3} className={classes.buttonRight}>
            <Button
              className={classes.cancelNoteBtn}
              variant="contained"
              onClick={onDialogClose}
            >
              Cancel
            </Button>
            <Button
            className={classes.addNoteBtn}
            variant="contained"
            // disabled={!noteValue || loading}
            // onClick={handleAddNote}
            onClick={saveToDB}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
export default OtherApplicationDialogBox;
