import gql from 'graphql-tag';

export const GET_STUDENT_NOTES = gql`
  query GetStudentNotes(
    $StudentId: Int!
    $limit: Int
    $page: Int
    $type: String
  ) {
    studentNotes(
      input: { StudentId: $StudentId, limit: $limit, page: $page, type: $type }
    ) {
      notes {
        type
        description
        updatedAt
        id
        advisor {
          id
          firstName
          lastName
        }
        mentorTimeLog {
          id
          workActivity
          milestone
          advisorBooking {
            id
            startTime
            allParticipants {
              id
              firstName
              lastName
              roles
            }
          }
        }
      }
      count
    }
  }
`;

export const LIST_GRAD_YEARS = gql`
  query ListGradYearAndPremium($MentorId: Int!) {
    listGradYearAndPremium(MentorId: $MentorId) {
      student {
        premiumPackageType
        highSchoolYear
      }
    }
  }
`;
