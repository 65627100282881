import { FC } from 'react';

import { Box } from '@material-ui/core';
import LOGO_HEADER_IMG from '../../img/logo-header.png';

const Logo: FC = () => {
  return (
    <Box maxWidth={170}>
      <img alt="logo" src={LOGO_HEADER_IMG} />
    </Box>
  );
};

export default Logo;
