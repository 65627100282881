import { Grid, Box } from '@material-ui/core';
import { TabPanelProps } from '../common/interfaces';

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box paddingTop={4}>{children}</Box>}
    </Grid>
  );
};

export default TabPanel;
