import { FC } from 'react';
import { Grid, Button, Card, Typography } from '@material-ui/core';
import { RouteComponentProps, Link } from 'react-router-dom';
import clsx from 'clsx';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import {
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
} from './__generated__/ResetPasswordMutation';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import { TextField } from '../../components/fields';
import { useStyles } from './styles';

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPasswordMutation($token: String!, $password: String!) {
    resetPassword(input: { token: $token, password: $password }) {
      status
    }
  }
`;

type TParams = { token: string };
const ResetPasswordPage: FC<RouteComponentProps<TParams>> = ({
  history,
  match: { params },
}) => {
  const initialValues = { password: '', confirmPassword: '' };
  const LoginSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    confirmPassword: Yup.string()
      .min(6, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
  });
  const classes = useStyles();
  return (
    <Grid>
      <Header />

      <Grid className={classes.contentBoxHeight}>
        <Grid className={classes.formContainer}>
          <Card className={classes.card}>
            <Mutation<ResetPasswordMutation, ResetPasswordMutationVariables>
              mutation={RESET_PASSWORD_MUTATION}
            >
              {(resetPasswordMutation: Function) => {
                return (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={LoginSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        const { password, confirmPassword } = values;
                        if (password !== confirmPassword) {
                          alert('Password and Confirm Password not same');
                          return setSubmitting(false);
                        }
                        const { token } = params;
                        const result = await resetPasswordMutation({
                          variables: { password, token },
                        });
                        const {
                          data: {
                            resetPassword: { status },
                          },
                        } = result;
                        if (status) {
                          alert('Password updated,');
                          history.push('/login');
                        } else {
                          alert(
                            'Unable to reset password, may be password reset token expired, request another one.'
                          );
                          history.push('/forgot-password');
                        }
                      } catch (error) {
                        alert('Something went wrong, Please try again');
                        console.log('error: ', error);
                      }
                      setSubmitting(false);
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <h5>Reset Password</h5>

                        <Field
                          type="password"
                          name="password"
                          component={TextField}
                          label="Password"
                          fullWidth
                          className={classes.textfield}
                        />

                        <Field
                          type="password"
                          name="confirmPassword"
                          component={TextField}
                          label="Confirm Password"
                          fullWidth
                          className={classes.textfield}
                        />

                        <Button
                          variant="contained"
                          type="submit"
                          disabled={isSubmitting}
                          className={classes.signButton}
                        >
                          Reset Password
                        </Button>
                      </Form>
                    )}
                  </Formik>
                );
              }}
            </Mutation>
          </Card>

          <Grid className={classes.FormBottomSection}>
            <Typography variant="body1" className={classes.FormBottomText}>
              Remembered your password?
            </Typography>

            <Link to="/login" className={classes.FormBottomLink}>
              <Button
                variant="contained"
                style={{ textTransform: 'capitalize' }}
                className={clsx(classes.signButton, classes.signNavigateButton)}
              >
                Back to Sign In
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>

      <Footer />
    </Grid>
  );
};

export default ResetPasswordPage;
