import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Colors from '../../common/colors';
import Fonts from '../../common/fonts';


export const footerStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerWrapper: {
      background: Colors.BLUE_FIVE,
      borderTop: `2px solid ${Colors.BLUE_EIGHT}`,
      fontSize: 14,

      [theme.breakpoints.down('sm')]: {
        '& br': {
          display: 'none',
        },
      },
    },

    footerContainer: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },

    footerLinks: {
      '& a, & > div': {
        fontWeight: 600,
        letterSpacing: 2,
        color: Colors.SKYBLUE_TWO,
        margin: '0 15px',
        cursor: 'pointer',

        '&:hover': {
          textDecoration: 'none',
          opacity: 0.5,
          transition: 'all .4s ease',
        },
      },

      [theme.breakpoints.down('sm')]: {
        margin: '15px 0',
      },

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        textAlign: 'center',
      },
    },

    socialIcons: {
      '& a': {
        '&:not(:last-child)': {
          marginRight: 30,
        },

        '&:hover': {
          opacity: 0.5,
          transition: 'all .4s ease',
        },

        '& img': {
          height: 20,
        },
      },
    },
  })
);

export const footerNewStyles = makeStyles((theme: Theme) =>
createStyles({
  containerMain: {
    [theme.breakpoints.up('sm')]: {
      padding: '0px',
    },

    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },

  footerWrapper: {
    background: `${Colors.WHITE_ONE}`,
    borderTop: `2px solid ${Colors.BLUE_SEVEN}`,
    padding: '61px 0px',

    [theme.breakpoints.down('sm')]: {
      padding: '24px 0px 0px',
    },
  },

  footerCopyRight: {
    fontSize: '16px',
    lineHeight: '20px',
    margin: '0px',
    letterSpacing: '0.3375px',
    color: `${Colors.BLACK_TWO}`,
    fontFamily: `${Fonts.POPPINS_FONT}`,

    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },

  footerLinksHeading: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '25px',
    letterSpacing: '0.3375px',
    textTransform: 'uppercase',
    color: `${Colors.BLACK_ONE}`,
    fontFamily: `${Fonts.POPPINS_FONT}`,
    marginBottom: '14px',
  },

  footerMobilePadding: {
    paddingLeft: '30px',
  },

  footerNavLinks: {
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.3375px',
    color: `${Colors.BLUE_SEVEN}`,
    fontFamily: `${Fonts.POPPINS_FONT}`,
    marginBottom: '20px',
    display: 'block',

    '&:hover': {
      color: `${Colors.BLUE_SEVEN}`,
      textDecoration: 'none',
      opacity: 0.8,
      transition: '0.5s',
    },
  },

  footerLogoMobile: {
    textAlign: 'center',
    padding: '32px 0px 30px',
    borderTop: `1px solid ${Colors.GRAY_EIGHT}`,
  },

  footerLogo: {
    maxWidth: 150,
    marginBottom: 20,
  },
})
);
