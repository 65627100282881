import React from 'react';
import {
  CustomTooltipComponent,
} from '../../../components/CustomTooltip';
import { useStyles } from './CardStyle';

const BlurContent = ({ title = 'Login to View More' }: { title?: string }) => {
  const classes = useStyles();
  return (
    <CustomTooltipComponent title="Sign In to View">
      <span className={classes.areaBlur}>{title}</span>
    </CustomTooltipComponent>
  );
};

export default BlurContent;
