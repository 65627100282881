import moment from 'moment';
import { US_Timezones_Obj } from '../common/utility';

export const zones = [
  'Hawaii Time (USA)',
  'West-Alaska Time (USA)',
  'Alaska Time (USA)',
  'Pacific Time (USA & Canada)',
  'Mountain Time (USA & Canada)',
  'Arizona Time (USA)',
  'Central Time (USA & Canada)',
  'Eastern Time (USA & Canada)',
  'Atlantic Time (USA & Canada)',
  'Africa/Abidjan (Greenwich Mean Time)',
  'Africa/Accra (Ghana Mean Time)',
  'Africa/Addis_Ababa',
  'Africa/Algiers',
  'Africa/Asmara',
  'Africa/Asmera',
  'Africa/Bamako (Greenwich Mean Time)',
  'Africa/Bangui (Western African Time)',
  'Africa/Banjul (Greenwich Mean Time)',
  'Africa/Bissau (Greenwich Mean Time)',
  'Africa/Blantyre',
  'Africa/Brazzaville (Western African Time)',
  'Africa/Bujumbura',
  'Africa/Cairo',
  'Africa/Casablanca (Western European Time)',
  'Africa/Ceuta',
  'Africa/Conakry (Greenwich Mean Time)',
  'Africa/Dakar (Greenwich Mean Time)',
  'Africa/Dar_es_Salaam',
  'Africa/Djibouti',
  'Africa/Douala (Western African Time)',
  'Africa/El_Aaiun (Western European Time)',
  'Africa/Freetown (Greenwich Mean Time)',
  'Africa/Gaborone',
  'Africa/Harare',
  'Africa/Johannesburg (South Africa Standard Time)',
  'Africa/Kampala',
  'Africa/Khartoum',
  'Africa/Kigali',
  'Africa/Kinshasa (Western African Time)',
  'Africa/Lagos (Western African Time)',
  'Africa/Libreville (Western African Time)',
  'Africa/Lome (Greenwich Mean Time)',
  'Africa/Luanda (Western African Time)',
  'Africa/Lubumbashi',
  'Africa/Lusaka',
  'Africa/Malabo (Western African Time)',
  'Africa/Maputo',
  'Africa/Maseru (South Africa Standard Time)',
  'Africa/Mbabane (South Africa Standard Time)',
  'Africa/Mogadishu',
  'Africa/Monrovia (Greenwich Mean Time)',
  'Africa/Nairobi',
  'Africa/Ndjamena (Western African Time)',
  'Africa/Niamey (Western African Time)',
  'Africa/Nouakchott (Greenwich Mean Time)',
  'Africa/Ouagadougou (Greenwich Mean Time)',
  'Africa/Porto-Novo (Western African Time)',
  'Africa/Sao_Tome (Greenwich Mean Time)',
  'Africa/Timbuktu (Greenwich Mean Time)',
  'Africa/Tripoli',
  'Africa/Tunis',
  'Africa/Windhoek (Western African Time)',
  'America/Araguaina (Brasilia Time)',
  'America/Asuncion (Paraguay Time)',
  'America/Bahia (Brasilia Time)',
  'America/Belem (Brasilia Time)',
  'America/Boa_Vista (Amazon Time)',
  'America/Bogota (Colombia Time)',
  'America/Buenos_Aires (Argentine Time)',
  'America/Campo_Grande (Amazon Time)',
  'America/Caracas (Venezuela Time)',
  'America/Catamarca (Argentine Time)',
  'America/Cayenne (French Guiana Time)',
  'America/Cordoba (Argentine Time)',
  'America/Cuiaba (Amazon Time)',
  'America/Danmarkshavn (Greenwich Mean Time)',
  'America/Eirunepe (Amazon Time)',
  'America/Fortaleza (Brasilia Time)',
  'America/Godthab (Western Greenland Time)',
  'America/Guayaquil (Ecuador Time)',
  'America/Guyana (Guyana Time)',
  'America/Havana (Cuba Standard Time)',
  'America/Jujuy (Argentine Time)',
  'America/La_Paz (Bolivia Time)',
  'America/Lima (Peru Time)',
  'America/Maceio (Brasilia Time)',
  'America/Manaus (Amazon Time)',
  'America/Mendoza (Argentine Time)',
  'America/Miquelon (Pierre & Miquelon Standard Time)',
  'America/Montevideo (Uruguay Time)',
  'America/Noronha (Fernando de Noronha Time)',
  'America/Paramaribo (Suriname Time)',
  'America/Porto_Acre (Amazon Time)',
  'America/Porto_Velho (Amazon Time)',
  'America/Recife (Brasilia Time)',
  'America/Rio_Branco (Amazon Time)',
  'America/Rosario (Argentine Time)',
  'America/Santarem (Brasilia Time)',
  'America/Santiago (Chile Time)',
  'America/Sao_Paulo (Brasilia Time)',
  'America/Scoresbysund',
  'America/St_Johns (Newfoundland Standard Time)',
  "Antarctica/DumontDUrville (Dumont-d'Urville Time)",
  'Antarctica/Casey (Western Standard Time (Australia))',
  'Antarctica/Davis (Davis Time)',
  'Antarctica/Mawson (Mawson Time)',
  'Antarctica/McMurdo (New Zealand Standard Time)',
  'Antarctica/Palmer (Chile Time)',
  'Antarctica/Rothera (Rothera Time)',
  'Antarctica/South_Pole (New Zealand Standard Time)',
  'Antarctica/Syowa (Syowa Time)',
  'Antarctica/Vostok (Vostok Time)',
  'Arctic/Longyearbyen',
  'Asia/Aden (Arabia Standard Time)',
  'Asia/Almaty (Alma-Ata Time)',
  'Asia/Amman',
  'Asia/Anadyr (Anadyr Time)',
  'Asia/Aqtau (Aqtau Time)',
  'Asia/Aqtobe (Aqtobe Time)',
  'Asia/Ashgabat (Turkmenistan Time)',
  'Asia/Ashkhabad (Turkmenistan Time)',
  'Asia/Baghdad (Arabia Standard Time)',
  'Asia/Bahrain (Arabia Standard Time)',
  'Asia/Baku (Azerbaijan Time)',
  'Asia/Bangkok (Indochina Time)',
  'Asia/Beirut',
  'Asia/Bishkek (Kirgizstan Time)',
  'Asia/Brunei (Brunei Time)',
  'Asia/Calcutta (India Standard Time)',
  'Asia/Choibalsan (Choibalsan Time)',
  'Asia/Chongqing (China Standard Time)',
  'Asia/Chungking (China Standard Time)',
  'Asia/Colombo (India Standard Time)',
  'Asia/Dacca (Bangladesh Time)',
  'Asia/Damascus',
  'Asia/Dhaka (Bangladesh Time)',
  'Asia/Dili (Timor-Leste Time)',
  'Asia/Dubai (Gulf Standard Time)',
  'Asia/Dushanbe (Tajikistan Time)',
  'Asia/Gaza',
  'Asia/Harbin (China Standard Time)',
  'Asia/Ho_Chi_Minh (Indochina Time)',
  'Asia/Hong_Kong (Hong Kong Time)',
  'Asia/Hovd (Hovd Time)',
  'Asia/Irkutsk (Irkutsk Time)',
  'Asia/Istanbul',
  'Asia/Jakarta (West Indonesia Time)',
  'Asia/Jayapura (East Indonesia Time)',
  'Asia/Jerusalem (Israel Standard Time)',
  'Asia/Kabul (Afghanistan Time)',
  'Asia/Kamchatka (Petropavlovsk-Kamchatski Time)',
  'Asia/Karachi (Pakistan Time)',
  'Asia/Kashgar (China Standard Time)',
  'Asia/Katmandu (Nepal Time)',
  'Asia/Kolkata (India Standard Time)',
  'Asia/Krasnoyarsk (Krasnoyarsk Time)',
  'Asia/Kuala_Lumpur (Malaysia Time)',
  'Asia/Kuching (Malaysia Time)',
  'Asia/Kuwait (Arabia Standard Time)',
  'Asia/Macao (China Standard Time)',
  'Asia/Macau (China Standard Time)',
  'Asia/Magadan (Magadan Time)',
  'Asia/Makassar',
  'Asia/Manila (Philippines Time)',
  'Asia/Muscat (Gulf Standard Time)',
  'Asia/Nicosia',
  'Asia/Novosibirsk (Novosibirsk Time)',
  'Asia/Omsk (Omsk Time)',
  'Asia/Oral (Oral Time)',
  'Asia/Phnom_Penh (Indochina Time)',
  'Asia/Pontianak (West Indonesia Time)',
  'Asia/Pyongyang (Korea Standard Time)',
  'Asia/Qatar (Arabia Standard Time)',
  'Asia/Qyzylorda (Qyzylorda Time)',
  'Asia/Rangoon (Myanmar Time)',
  'Asia/Saigon (Indochina Time)',
  'Asia/Sakhalin (Sakhalin Time)',
  'Asia/Samarkand (Uzbekistan Time)',
  'Asia/Seoul (Korea Standard Time)',
  'Asia/Shanghai (China Standard Time)',
  'Asia/Singapore (Singapore Time)',
  'Asia/Taipei (China Standard Time)',
  'Asia/Tashkent (Uzbekistan Time)',
  'Asia/Tbilisi (Georgia Time)',
  'Asia/Tehran (Iran Standard Time)',
  'Asia/Tel_Aviv (Israel Standard Time)',
  'Asia/Thimbu (Bhutan Time)',
  'Asia/Thimphu (Bhutan Time)',
  'Asia/Tokyo (Japan Standard Time)',
  'Asia/Ujung_Pandang',
  'Asia/Ulaanbaatar (Ulaanbaatar Time)',
  'Asia/Ulan_Bator (Ulaanbaatar Time)',
  'Asia/Urumqi (China Standard Time)',
  'Asia/Vientiane (Indochina Time)',
  'Asia/Vladivostok (Vladivostok Time)',
  'Asia/Yakutsk (Yakutsk Time)',
  'Asia/Yekaterinburg (Yekaterinburg Time)',
  'Asia/Yerevan (Armenia Time)',
  'Atlantic/Azores (Azores Time)',
  'Atlantic/Canary (Western European Time)',
  'Atlantic/Cape_Verde (Cape Verde Time)',
  'Atlantic/Faeroe (Western European Time)',
  'Atlantic/Faroe (Western European Time)',
  'Atlantic/Jan_Mayen',
  'Atlantic/Madeira (Western European Time)',
  'Atlantic/Reykjavik (Greenwich Mean Time)',
  'Atlantic/South_Georgia (South Georgia Standard Time)',
  'Atlantic/St_Helena (Greenwich Mean Time)',
  'Australia/ACT',
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Broken_Hill',
  'Australia/Canberra',
  'Australia/Currie',
  'Australia/Darwin',
  'Australia/Eucla',
  'Australia/Hobart',
  'Australia/LHI (Lord Howe Standard Time)',
  'Australia/Lindeman',
  'Australia/Lord_Howe (Lord Howe Standard Time)',
  'Australia/Melbourne',
  'Australia/NSW',
  'Australia/North',
  'Australia/Perth (Western Standard Time (Australia))',
  'Australia/Queensland',
  'Australia/South',
  'Australia/Sydney',
  'Australia/Tasmania',
  'Australia/Victoria',
  'Australia/West (Western Standard Time (Australia))',
  'Australia/Yancowinna',
  'Brazil/Acre (Amazon Time)',
  'Brazil/DeNoronha (Fernando de Noronha Time)',
  'Brazil/East (Brasilia Time)',
  'Brazil/West (Amazon Time)',
  'Canada/Newfoundland (Newfoundland Standard Time)',
  'Chile/Continental (Chile Time)',
  'Europe/Amsterdam',
  'Europe/Andorra',
  'Europe/Athens',
  'Europe/Belfast (Greenwich Mean Time)',
  'Europe/Belgrade',
  'Europe/Berlin',
  'Europe/Bratislava',
  'Europe/Brussels',
  'Europe/Bucharest',
  'Europe/Budapest',
  'Europe/Chisinau',
  'Europe/Copenhagen',
  'Europe/Dublin (Greenwich Mean Time)',
  'Europe/Gibraltar',
  'Europe/Guernsey (Greenwich Mean Time)',
  'Europe/Helsinki',
  'Europe/Isle_of_Man (Greenwich Mean Time)',
  'Europe/Istanbul',
  'Europe/Jersey (Greenwich Mean Time)',
  'Europe/Kaliningrad',
  'Europe/Kiev',
  'Europe/Lisbon (Western European Time)',
  'Europe/Ljubljana',
  'Europe/London (Greenwich Mean Time)',
  'Europe/Luxembourg',
  'Europe/Madrid',
  'Europe/Malta',
  'Europe/Mariehamn',
  'Europe/Minsk',
  'Europe/Monaco',
  'Europe/Moscow (Moscow Standard Time)',
  'Europe/Nicosia',
  'Europe/Oslo',
  'Europe/Paris',
  'Europe/Podgorica',
  'Europe/Prague',
  'Europe/Riga',
  'Europe/Rome',
  'Europe/Samara (Samara Time)',
  'Europe/San_Marino',
  'Europe/Sarajevo',
  'Europe/Simferopol',
  'Europe/Skopje',
  'Europe/Sofia',
  'Europe/Stockholm',
  'Europe/Tallinn',
  'Europe/Tirane',
  'Europe/Tiraspol',
  'Europe/Uzhgorod',
  'Europe/Vaduz',
  'Europe/Vatican',
  'Europe/Vienna',
  'Europe/Vilnius',
  'Europe/Volgograd (Volgograd Time)',
  'Europe/Warsaw',
  'Europe/Zagreb',
  'Europe/Zaporozhye',
  'Europe/Zurich',
  'Indian/Antananarivo',
  'Indian/Chagos (Indian Ocean Territory Time)',
  'Indian/Christmas (Christmas Island Time)',
  'Indian/Cocos (Cocos Islands Time)',
  'Indian/Comoro',
  'Indian/Kerguelen (French Southern & Antarctic Lands Time)',
  'Indian/Mahe (Seychelles Time)',
  'Indian/Maldives (Maldives Time)',
  'Indian/Mauritius (Mauritius Time)',
  'Indian/Mayotte',
  'Indian/Reunion (Reunion Time)',
].map((zone) => {
  const parseUSZone = (timezone: string): string => {
    const city = timezone.split(' ')[0];

    const USZone =
      US_Timezones_Obj?.[
        city as
          | 'Hawaii'
          | 'Alaska'
          | 'Pacific'
          | 'Mountain'
          | 'Arizona'
          | 'Central'
          | 'Eastern'
          | 'Atlantic'
      ];

    return USZone
      ? `(GMT${moment.tz(USZone).format('Z')}) ${timezone}`
      : `(GMT${moment.tz(city).format('Z')}) ${city}`;
  };
  return parseUSZone(zone);
});