import {
  Box,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { Query } from 'react-apollo';

import OtherColleges from './OtherColleges';
import PopularColleges from './PopularColleges';

import { AllCollegesQuery } from '../graphql/queries/addStudentApplication/__generated__/AllCollegesQuery';
import { ALL_COLLEGES_QUERY } from '../graphql/queries/addStudentApplication';

import Loader from '../../Loader';

import Colors from '../../../common/colors';

import DRAWER_CLOSE_ICON from '../../../img/drawer_close.svg';
import { allCollegesStyles } from '../style';
import { AllCollegesProps } from '../../../common/interfaces';

let timeoutId: any = 0;
let currentPage = 1;

const AllColleges: FC<AllCollegesProps> = ({
  variation,
  isCollegeAdded,
  setPopularColleges,
  isDrawerOpen,
  onCloseDrawer,
  studentId,
}) => {
  useEffect(() => {
    currentPage = 1;

    return () => {
      currentPage = 1;
    };
  }, []);

  const [searchQuery, setSearchQuery] = useState('');
  const classes = allCollegesStyles();
  const isDrawer = variation === 'drawer';
  const isOnboard = variation === 'onboard';
  const isPage = variation === 'page';

  const [loadingMore, setLoadingMore] = useState(false);

  return (
    <>
      <Grid className={classes.genericHeaderComponent}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className="drawerHeader"
        >
          <Typography variant="h3" className="drawerHeadingText">
            {isPage ? 'Colleges' : 'Add Colleges'}
          </Typography>
          {isDrawer && (
            <IconButton
              onClick={() => {
                onCloseDrawer && onCloseDrawer();
              }}
            >
              <img src={DRAWER_CLOSE_ICON} alt="close" />
            </IconButton>
          )}
        </Box>

        <Grid className="drawerSearch">
          <TextField
            variant="outlined"
            placeholder="Search Colleges"
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              clearTimeout(timeoutId);
              timeoutId = setTimeout(() => {
                setSearchQuery(value);
              }, 500);
            }}
          />
        </Grid>
      </Grid>

      <Grid>
        <Query<AllCollegesQuery>
          query={ALL_COLLEGES_QUERY}
          variables={{ studentId, page: 1, limit: 12, searchQuery }}
          onCompleted={(data) => {
            if (!data || !data.allColleges) return true;
            const { allColleges } = data;

            if (setPopularColleges && allColleges)
              setPopularColleges(allColleges.colleges);
          }}
          fetchPolicy="network-only"
        >
          {({ data, fetchMore, refetch, loading }) => {
            if (loading) return <Loader />;
            if (!data || !data.allColleges) return null;
            const {
              allPopularColleges,
              allColleges: { colleges, nextRecord },
            } = data;
            const AllColleges = colleges || [];

            const loadMore = () => {
              setLoadingMore(true);
              currentPage++;

              fetchMore({
                variables: {
                  page: currentPage,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  setLoadingMore(false);
                  if (
                    !fetchMoreResult ||
                    !fetchMoreResult.allColleges ||
                    !fetchMoreResult.allColleges.colleges
                  )
                    return prev;

                  const prevColleges =
                    (prev.allColleges && prev.allColleges.colleges) || [];

                  const {
                    allColleges: { colleges, nextRecord },
                  } = fetchMoreResult;
                  return Object.assign({}, prev, {
                    allColleges: {
                      colleges: [...prevColleges, ...colleges],
                      nextRecord,
                      __typename: 'allCollegesPayload',
                    },
                  });
                },
              });
            };

            return (
              <Grid>
                {isPage && (
                  <PopularColleges
                    isSearching={!!searchQuery}
                    colleges={AllColleges}
                    refetch={refetch}
                    loadMore={loadMore}
                    studentId={studentId}
                  />
                )}

                {isOnboard && (
                  <PopularColleges
                    isSearching={!!searchQuery}
                    colleges={searchQuery ? AllColleges : allPopularColleges}
                    refetch={() => {
                      if (setPopularColleges) setPopularColleges(AllColleges);
                    }}
                    loadMore={false}
                    studentId={studentId}
                  />
                )}

                {isDrawer && (
                  <OtherColleges
                    isCollegeAdded={isCollegeAdded}
                    isPage={isPage}
                    colleges={AllColleges}
                    isSearching={!!searchQuery}
                    refetch={refetch}
                    isDrawerOpen={isDrawerOpen}
                    studentId={studentId}
                  />
                )}

                {nextRecord && loadingMore && (
                  <Box
                    textAlign="center"
                    padding="2px 0px 32px"
                    fontSize={24}
                    color={Colors.BLUE_SEVEN}
                  >
                    Loading...
                  </Box>
                )}
              </Grid>
            );
          }}
        </Query>
      </Grid>
    </>
  );
};

AllColleges.defaultProps = {
  variation: 'page',
};

export default AllColleges;
