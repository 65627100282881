import { useEffect, useState } from 'react';
import {
  Dialog,
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
  Checkbox,
} from '@material-ui/core';
import { Spinner } from 'react-bootstrap';

import { IAllCalendarList } from '.';

import DIALOG_CLOSE_ICON from '../../../img/close-icon-dialog.svg';
import client from '../../../apollo';
import { SYNC_EMAIL_CALENDAR } from './gql';
import { Link } from 'react-router-dom';
import { calendarAccountDialogStyles } from './styles';

interface ICheckedCalendarList {
  checked: boolean;
  calendarId: string;
  name: string;
}

const CalendarAccountDialog = ({
  allPersonalCalendarList,
  setOpen,
  open,
  refetch,
  connectedEmailAddress,
  isRefetchMoreCalendars,
}: {
  allPersonalCalendarList: IAllCalendarList[];
  setOpen: Function;
  open: boolean;
  refetch: Function;
  connectedEmailAddress: string;
  isRefetchMoreCalendars: boolean;
}) => {
  const classes = calendarAccountDialogStyles();

  const [checkedCalendars, setCheckedCalendars] = useState<
    ICheckedCalendarList[]
  >([]);

  const [loading, setLoading] = useState(false);
  const [successScreen, setSuccessScreen] = useState(false);

  const handleClose = async () => {
    setOpen(false);
    setSuccessScreen(false);
    await refetch();
  };

  const isCalendarSelectionUpdated = () => {
    const checkedCal = checkedCalendars?.filter((item) => item.checked);
    return !!checkedCal.length;
  };

  const handleSaveBtn = async () => {
    try {
      if (isCalendarSelectionUpdated()) {
        setLoading(true);
        await client.mutate({
          mutation: SYNC_EMAIL_CALENDAR,
          variables: {
            calendars: checkedCalendars
              ?.filter((item) => item.checked)
              ?.map((calendar) => ({
                calendarId: calendar.calendarId,
                calendarName: calendar.name,
                isChecked: true,
                email_address: connectedEmailAddress,
              })),
          },
        });
        setSuccessScreen(true);
      }
    } catch (err) {
      console.log('Error::', err);
    } finally {
      setLoading(false);
    }
  };

  const handleCheck = (checked: boolean, calendarId: string, name: string) => {
    const changedCalendarIdIndex = checkedCalendars.findIndex(
      (cal) => cal.calendarId === calendarId
    );
    if (changedCalendarIdIndex >= 0) {
      const copyCheckedCalendars = [...checkedCalendars];
      copyCheckedCalendars[changedCalendarIdIndex].checked = checked;
      setCheckedCalendars(copyCheckedCalendars);
    } else
      setCheckedCalendars([...checkedCalendars, { checked, calendarId, name }]);
  };

  useEffect(() => {
    if (allPersonalCalendarList.length) {
      setCheckedCalendars(
        allPersonalCalendarList?.map((list) => ({
          checked: list.isPrimary,
          calendarId: list.calendarId,
          name: list.name,
        }))
      );
    }
  }, [allPersonalCalendarList?.length]);

  return (
    <Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        className={classes.confirmationDialog}
      >
        <IconButton onClick={handleClose} className="closeButton">
          <img src={DIALOG_CLOSE_ICON} alt="close" />
        </IconButton>

        <Box>
          {successScreen && (
            <>
              <Typography variant="h6">Success!</Typography>
              <Typography variant="body1" className="subtitleGutter">
                Your new calendar is now connected.
              </Typography>
              <Link to={'/calendar'} className="availabilityButton">
                Go to My Availability Page
              </Link>
            </>
          )}

          {!successScreen && (
            <>
              <Typography variant="h6">Calendar Account</Typography>
              <Typography variant="body1" className="subtitleGutter">
                {connectedEmailAddress || ''}
              </Typography>

              {isRefetchMoreCalendars ? (
                <Box>
                  <Spinner animation="border" size="sm" /> Loading
                  sub-calendars. Please wait...
                </Box>
              ) : (
                <>
                  <Typography variant="body2">
                    Choose which calendars to sync:
                  </Typography>
                  <Grid>
                    {allPersonalCalendarList?.map((list) => (
                      <Grid className={classes.checkboxComponent}>
                        <div className="checkboxLeftGutter">
                          <Checkbox
                            color="default"
                            checked={
                              checkedCalendars?.find(
                                (cal) => cal.calendarId === list.calendarId
                              )?.checked
                            }
                            onClick={(e: any) => {
                              handleCheck(
                                e.target.checked,
                                list.calendarId,
                                list.name
                              );
                            }}
                          />
                          <label className="checkboxText">{list.name}</label>
                        </div>
                      </Grid>
                    ))}
                  </Grid>

                  <Button
                    disabled={
                      loading ||
                      !checkedCalendars.filter((cal) => cal.checked)?.length
                    }
                    variant="contained"
                    color="primary"
                    onClick={handleSaveBtn}
                    startIcon={
                      loading && <Spinner animation="border" size="sm" />
                    }
                    className="availabilityButton saveButton"
                  >
                    Save
                  </Button>
                </>
              )}
            </>
          )}
        </Box>
      </Dialog>
    </Grid>
  );
};

export default CalendarAccountDialog;
