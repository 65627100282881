import {
  ClickAwayListener,
  Box,
  Typography,
  IconButton,
  ListItemIcon,
} from '@material-ui/core';
import MENU_CIRCLE from '../../../img/menu-dark-icon.svg';
import { useState, MouseEvent, FC } from 'react';
import DELETE_ICON from '../../../img/delete-icon.svg';

import { MenuOptionsProps } from '../../../common/interfaces';
import { StyledMenu, StyledMenuItem } from '../../../common/CommonComponents';


const MenuOptions: FC<MenuOptionsProps> = ({
  openDeleteModal,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  function closeMenu() {
    setAnchorEl(null);
  }
  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }
  return (
    <ClickAwayListener onClickAway={closeMenu}>
      <Box className="d-flex justify-content-end mr-5">
        <IconButton onClick={handleClick}>
          <img src={MENU_CIRCLE} alt="menu" />
        </IconButton>

        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeMenu}
        >
          <StyledMenuItem onClick={() => openDeleteModal()}>
            <ListItemIcon className="menu-item-style">
              <img src={DELETE_ICON} alt="delete" />
            </ListItemIcon>
            <Typography variant="h6">THIS MEETING DID NOT OCCUR</Typography>
          </StyledMenuItem>
        </StyledMenu>
      </Box>
    </ClickAwayListener>
  );
};
export default MenuOptions;
