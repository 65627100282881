import { FC, useEffect } from 'react';
import { Box } from '@material-ui/core';

import MentorCard from './AdvisorCard';
import { isValueBetween } from '../../../common/utility';

interface Props {
  setMentorId: Function;
  loadMore?: Function;
  mentors: any;
}

let timeoutId = 0;
const AdvisorsList: FC<Props> = ({ mentors, setMentorId, loadMore }) => {
  const useEffectCallBack = () => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  };

  useEffect(useEffectCallBack, []);

  const handleScroll = () => {
    clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      const {
        documentElement: { offsetHeight },
        scrollingElement,
      } = document;
      const scrollHeight = scrollingElement ? scrollingElement.scrollTop : 0;
      if (
        isValueBetween(
          scrollHeight + window.innerHeight,
          offsetHeight - window.innerHeight,
          offsetHeight + 50
        ) &&
        loadMore
      ) {
        loadMore();
      }
    }, 200);
  };

  return (
    <Box className="mentor-list">
      {mentors &&
        mentors
          .filter(
            (mentor: any, i: number, arr: any) =>
              arr.findIndex(
                (obj: any) => !!obj && !!mentor && obj.id === mentor.id
              ) === i
          )
          .map((mentor: any) => {
            if (!mentor) return null;

            return (
              <MentorCard
                key={mentor.id}
                mentor={mentor}
                setMentorId={setMentorId}
              />
            );
          })}
    </Box>
  );
};

export default AdvisorsList;
