import { FC, Fragment } from 'react';
import {  Grid, Typography, Box } from '@material-ui/core';

import { useStylesApplication } from './applicationStyle';
import CheckListUpdateToDo from './CheckListUpdateTodo';

const StudentApplicationTodos: FC<any> = ({ studentApplicationChecklist }) => {
  const applicationClasses = useStylesApplication();

  return (
    <>
      {studentApplicationChecklist && (
        <Box pb={7}>
          <Typography
            variant="h5"
            className={applicationClasses.applicationEssayHeading}
          >
            To-Do’s
          </Typography>

          <Grid className={applicationClasses.applicationToDoList}>
            <CheckListUpdateToDo
              completionResponse={
                studentApplicationChecklist.accountCreatedCompletedAt
              }
            />
            <Box className={applicationClasses.editDeleteBox}>
              <Typography variant="h6">
                Create Account on School Website
              </Typography>

              <Grid>
                {studentApplicationChecklist.accountCreated && (
                  <Typography variant="body1" className="addNote noteAdded">
                    {studentApplicationChecklist.accountCreated.substring(
                      0,
                      150
                    )}
                    {studentApplicationChecklist.accountCreated.length > 150 &&
                      '...'}
                  </Typography>
                )}
              </Grid>
            </Box>
          </Grid>

          <Grid className={applicationClasses.applicationToDoList}>
            <CheckListUpdateToDo
              completionResponse={
                studentApplicationChecklist.testScoresSubmittedCompletedAt
              }
            />
            <Box className={applicationClasses.editDeleteBox}>
              <Typography variant="h6">
                Test Scores Submitted to College
              </Typography>

              <Grid>
                {studentApplicationChecklist.testScoresSubmitted && (
                  <Typography variant="body1" className="addNote noteAdded">
                    {studentApplicationChecklist.testScoresSubmitted.substring(
                      0,
                      150
                    )}
                    {studentApplicationChecklist.testScoresSubmitted.length >
                      150 && '...'}
                  </Typography>
                )}
              </Grid>
            </Box>
          </Grid>

          <Grid className={applicationClasses.applicationToDoList}>
            <CheckListUpdateToDo
              completionResponse={
                studentApplicationChecklist.transcriptRequestedCompletedAt
              }
            />
            <Box className={applicationClasses.editDeleteBox}>
              <Typography variant="h6">
                Transcript Requested for College
              </Typography>

              <Grid>
                {studentApplicationChecklist.transcriptRequested && (
                  <Typography variant="body1" className="addNote noteAdded">
                    {studentApplicationChecklist.transcriptRequested.substring(
                      0,
                      150
                    )}
                    {studentApplicationChecklist.transcriptRequested.length >
                      150 && '...'}
                  </Typography>
                )}
              </Grid>
            </Box>
          </Grid>

          <Grid className={applicationClasses.applicationToDoList}>
            <CheckListUpdateToDo
              completionResponse={
                studentApplicationChecklist.recommendationLetterRequestedCompletedAt
              }
            />
            <Box className={applicationClasses.editDeleteBox}>
              <Typography variant="h6">
                Request Letter of Recommendation
              </Typography>

              <Grid>
                {studentApplicationChecklist.recommendationLetterRequested && (
                  <Typography variant="body1" className="addNote noteAdded">
                    {studentApplicationChecklist.recommendationLetterRequested.substring(
                      0,
                      150
                    )}
                    {studentApplicationChecklist.recommendationLetterRequested
                      .length > 150 && '...'}
                  </Typography>
                )}
              </Grid>
            </Box>
          </Grid>

          <Grid className={applicationClasses.applicationToDoList}>
            <CheckListUpdateToDo
              completionResponse={
                studentApplicationChecklist.otherNotesCompletedAt
              }
            />
            <Box className={applicationClasses.editDeleteBox}>
              <Typography variant="h6">Other Notes</Typography>

              <Grid>
                {studentApplicationChecklist.otherNotes && (
                  <Typography variant="body1" className="addNote noteAdded">
                    {studentApplicationChecklist.otherNotes.substring(0, 150)}
                    {studentApplicationChecklist.otherNotes.length > 150 &&
                      '...'}
                  </Typography>
                )}
              </Grid>
            </Box>
          </Grid>
        </Box>
      )}
    </>
  );
};
export default StudentApplicationTodos;
