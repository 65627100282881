import React from 'react';
import { TextField } from '@material-ui/core';
import { NumberInputProps } from '../../common/interfaces';

const NumberInput: React.FC<NumberInputProps> = ({ field, form, ...props }) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const sanitizedValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    form.setFieldValue(field.name, sanitizedValue);
  };

  return (
    <TextField {...field} {...props} type="text" onChange={handleInputChange} />
  );
};

export default NumberInput;
