import {
  FC,
  KeyboardEvent,
  MouseEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Query } from 'react-apollo';

import { Link } from 'react-router-dom';

import { Nav, Navbar } from 'react-bootstrap';

import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Hidden,
  IconButton,
  SwipeableDrawer,
  Typography,
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';

import { TOKEN } from '../../constants';
import { AppContext } from '../../contexts';

import Logo from '../../components/layout/Logo';

import { useStyles } from './HeaderStyles';

import client from '../../apollo';
import { GET_ADVISORS_COLLEGES } from '../../graphql/queries/Colleges';
import {
  GetAdvisorsColleges_advisorsColleges_currentCollege,
  GetAdvisorsColleges_advisorsColleges_graduateSchool,
} from '../../graphql/queries/Colleges/__generated__/GetAdvisorsColleges';
import DefaultImg from '../../img/empty-badge.svg';
import CHAT_IMG from '../../img/header-chat.svg';
import { themeSchoolImageSelection } from '../../styles/common';
import { DOWN_ARROW, EX_LINK } from './HeaderIcons';

interface Props {
  navigateTo?: (path: string) => void;
}

const Header: FC<Props> = ({ navigateTo }) => {
  const { user, setUser, isCollegeUpdated, advisorLeadData} = useContext(AppContext);
  const { unreadMessagesCount } = useContext(AppContext);
  const [drawer, setDrawer] = useState(false);
  const classes = useStyles();

  const [currentCollege, setCurrentCollege] =
    useState<GetAdvisorsColleges_advisorsColleges_currentCollege>();
  const [graduateSchool, setGraduateSchool] =
    useState<GetAdvisorsColleges_advisorsColleges_graduateSchool>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await client.query({
          query: GET_ADVISORS_COLLEGES,
          fetchPolicy: 'no-cache',
        });

        if (data?.currentCollege) {
          setCurrentCollege(data?.currentCollege);
        }
        if (data?.graduateSchool) {
          setGraduateSchool(data?.graduateSchool);
        }
      } catch (error) {
        console.log('Error in getting colleges:::', error);
      }
    };
    if (user) fetchData();
  }, [isCollegeUpdated]);

  const toggleDrawer = () => (event: KeyboardEvent | MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as KeyboardEvent).key === 'Tab' ||
        (event as KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawer(!drawer);
  };

  const renderMenu = () => {
    return (
      <>
        {MAIN_MENU.map((item) => {
          if (
            item.title === 'My Meetings' ||
            item.title === 'My Availability' ||
            (item.title !== 'My Meetings' && item.title !== 'My Availability')
          ) {
            return (
              <Nav.Item key={`top-${item.title}`}>
                <Link to={item.link}>{item.title}</Link>
              </Nav.Item>
            );
          }
        })}

        <Hidden only={['sm', 'xs']}>
          {user && (
            <Box className={classes.dropDownLink}>
              <a className={classes.navLink}>
                <Box
                  display="flex"
                  alignItems="center"
                  className="dropdownText"
                >
                  Resources <Box marginLeft={1}>{DOWN_ARROW()}</Box>
                </Box>
              </a>

              <Box
                className={`${classes.dropDownContainer} dropdown-container`}
              >
                <ul>
                  {RESOURCE_MENU.map((item, i) => (
                    <li key={item.title}>
                      {item.hasIcon ? (
                        <a
                          href={item.link}
                          className={classes.navLink}
                          target="_blank"
                        >
                          <Box display="flex" alignItems="center">
                            {item.title}

                            <Box marginLeft={1.4}>{EX_LINK()}</Box>
                          </Box>
                        </a>
                      ) : (
                        <Link to={item.link} className={classes.navLink}>
                          {item.title}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </Box>
            </Box>
          )}
        </Hidden>

        <Hidden only={['xl', 'lg', 'md']}>
          {user && (
            <Box className={classes.dropDownLink}>
              {RESOURCE_MENU.map((item, i) => (
                <div key={item.title} className="nav-item">
                  {item.hasIcon ? (
                    <a
                      href={item.link}
                      className={classes.navLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Box display="flex" alignItems="center">
                        {item.title}

                        <Box marginLeft={1.4}>{EX_LINK()}</Box>
                      </Box>
                    </a>
                  ) : (
                    <Link to={item.link} className={classes.navLink}>
                      {item.title}
                    </Link>
                  )}
                </div>
              ))}
            </Box>
          )}
        </Hidden>
      </>
    );
  };

  const MAIN_MENU = [
    {
      title: 'My Meetings',
      link: '/meetings',
    },

    {
      title: 'My Students',
      link: '/students',
    },

    {
      title: 'My Availability',
      link: '/calendar',
    },

    {
      title: 'Payouts',
      link: '/time-logs?payouts=true',
    },
    {
      title: 'Capacity',
      link: '/capacity',
    },
  ];
  let host = '';
  if (window.location.hostname.includes('staging')) {
    host = 'https://staging.app.collegeadvisor.com';
  } else if (window.location.hostname.includes('dev')) {
    host = 'https://dev.app.collegeadvisor.com';
  } else {
    host = 'https://app.collegeadvisor.com';
  }

  const RESOURCE_MENU = [
    {
      title: 'Notion Page',
      link: 'https://www.notion.so/collegeadvisor/Advising-35dc2c8b08d044e18e28484b2f1869ea',
      hasIcon: true,
    },
    {
      title: 'Milestone Teams',
      link: 'https://www.notion.so/collegeadvisor/142eef8ef7eb4d26905f5cc0defaff78?v=155e1cc027ff44d4a2705ac1f29f4e21&pvs=4',
      hasIcon: true,
    },
    {
      title: 'Articles',
      link: 'https://www.collegeadvisor.com/resources/',
      hasIcon: true,
    },
    {
      title: 'Webinars',
      link: `${host}/webinars?adv=true`,
      hasIcon: true,
    },
    {
      title: 'Colleges',
      link: `${host}/colleges`,
      hasIcon: true,
    },
    {
      title: 'Extracurricular Opportunities',
      link: `${host}/extracurricular-opportunities?adv=true`,
      hasIcon: true,
    },
    {
      title: 'Open Support Ticket',
      link: `https://airtable.com/shr7DBFUvVrJsjGgw`,
      hasIcon: true,
    },
  ];

  const advisorCollege = themeSchoolImageSelection(
    user?.Theme,
    graduateSchool?.name,
    currentCollege?.name
  );

  return (
    <header className={classes.headerWrapper}>
      <Container className={classes.positionRelativeForMenu} maxWidth={false}>
        <Navbar expand="md" className="p-0">
          <Grid className={classes.navbarBrand}>
            {user ? (
              <Link to="/meetings" className={classes.mentorPortal}>
                <Logo />
              </Link>
            ) : (
              <a
                href="https://www.collegeadvisor.com"
                className={classes.mentorPortal}
              >
                <Logo />
              </a>
            )}
          </Grid>

          <Box marginLeft="auto" className={classes.hamburgerMenu}>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer()}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Nav navbar className={`main-nav ${classes.hideMobile}`}>
            {user && renderMenu()}
          </Nav>

          <Nav className={classes.hideMobile}>
            {user == null && (
              <Link to="/login">
                <Button
                  variant="outlined"
                  color="default"
                  type="submit"
                  className={classes.button}
                  style={{ textTransform: 'capitalize' }}
                >
                  Sign In
                </Button>
              </Link>
            )}

            {user && (
              <>
                <Grid className={classes.logoutProfileRight}>
                  <Hidden only={['sm', 'xs']}>
                    {user.isActive && (
                      <>
                        {
                          <Link
                            to="/time-log/add"
                            className={classes.logHoursButton}
                          >
                            Log Hours
                          </Link>
                        }
                      </>
                    )}

                    <Nav.Item className={classes.profileAccount}>
                      <Box
                        position="relative"
                        height={57}
                        top={10}
                        className={classes.profileBadgeContainer}
                      >
                        {user.pictureUrl && (
                          <Box
                            className={classes.profileBadge}
                            style={{
                              backgroundImage: 'url(' + user.pictureUrl + ')',
                            }}
                          />
                        )}

                        {!user.pictureUrl && (
                          <Grid className={classes.profileBadge}>
                            <PersonIcon />
                          </Grid>
                        )}

                        <Box
                          position="absolute"
                          top={40}
                          zIndex={9}
                          className={classes.profileBadgeMenu}
                          id="profileBadgeMenu"
                        >
                          <Grid className={classes.profilePopover}>
                            <Grid className={classes.profileCard}>
                              <Grid>
                                <Typography
                                  variant="h4"
                                  className={classes.userName}
                                >
                                  {`${user.firstName || ''} ${
                                    user.lastName || ''
                                  }`}
                                </Typography>
                                <Typography variant="body1">
                                  {advisorCollege || currentCollege?.name
                                    ? (advisorCollege || currentCollege?.name) +
                                      ', '
                                    : ''}{' '}
                                  {user?.advisorLevel || ''}
                                </Typography>
                              </Grid>
                            </Grid>


                            {advisorLeadData && (
                              advisorLeadData?.advisorLead?.ATL?.length ? (
                                <Grid className={classes.myTeamBox}>
                                  <Typography variant="h6">
                                    My Team
                                  </Typography>

                                  {advisorLeadData?.advisorLead?.ATL?.map((item) => {
                                    return (
                                      <Box
                                        display="flex"
                                        alignItems="flex-start"
                                        mt="14px"
                                      >
                                        <Grid
                                          className={classes.userProfile}
                                          style={{
                                            backgroundImage:
                                              'url(' + item?.pictureUrl ||
                                              (
                                                <img
                                                  src={DefaultImg}
                                                  alt={`${
                                                    item?.firstName || ''
                                                  } ${item?.lastName || ''}`}
                                                />
                                              ) + ')',
                                          }}
                                        />

                                        <Box>
                                          <Typography variant="body1">
                                            {`${item?.firstName || '--'} ${
                                              item?.lastName || '--'
                                            }`}
                                          </Typography>
                                          <Typography variant="body2">
                                            {item?.advisorLevel
                                              ? item?.advisorLevel + ','
                                              : '--'}{' '}
                                            Service Contact
                                          </Typography>
                                          <a
                                            href={`mailto:${item?.email}`}
                                            className="emailPhoneText"
                                          >
                                            {item?.email || '--'}
                                          </a>
                                          <a
                                            href={`tel:${item?.phoneNumber}`}
                                            className="emailPhoneText"
                                          >
                                            {item?.phoneNumber || '--'}
                                          </a>
                                        </Box>
                                      </Box>
                                    );
                                  })}
                                </Grid>
                              ) : null
                            )}

                            <Box
                              textAlign="right"
                              className={classes.pageLinks}
                            >
                              <Link
                                to="/account/information"
                                className={classes.linkMenuItem}
                              >
                                My Account
                              </Link>

                              <Link
                                to="/meetings"
                                className={classes.linkMenuItem}
                              >
                                My Meetings
                              </Link>

                              <Link
                                to="/students"
                                className={classes.linkMenuItem}
                              >
                                My Students
                              </Link>

                              {window.location.hostname !==
                              'app.collegeadvisor.com' ? (
                                <Link
                                  to="/login"
                                  className={classes.popoverSignOut}
                                  onClick={() => {
                                    localStorage.removeItem(TOKEN);
                                    setUser(null);
                                  }}
                                  style={{ fontWeight: 600 }}
                                >
                                  SIGN OUT
                                </Link>
                              ) : (
                                <a
                                  href="https://www.collegeadvisor.com"
                                  className={classes.popoverSignOut}
                                  onClick={(e) => {
                                    localStorage.removeItem(TOKEN);
                                    setUser(null);
                                  }}
                                >
                                  SIGN OUT
                                </a>
                              )}
                            </Box>
                          </Grid>
                        </Box>
                      </Box>
                    </Nav.Item>
                  </Hidden>
                </Grid>

                <Link to={'/chat'} className={classes.chatButton}>
                  <Badge badgeContent={unreadMessagesCount} color="primary">
                    <img src={CHAT_IMG} alt="chat" />
                  </Badge>
                </Link>
              </>
            )}
          </Nav>
        </Navbar>
      </Container>

      <SwipeableDrawer
        anchor="left"
        open={drawer}
        onClose={toggleDrawer()}
        onOpen={toggleDrawer()}
      >
        <div
          role="presentation"
          onClick={toggleDrawer()}
          onKeyDown={toggleDrawer()}
          className={classes.drawerStyle}
        >
          <Navbar.Brand>
            <Link to="/meetings">
              <Logo />
            </Link>
          </Navbar.Brand>

          <Link to={'/chat'} className={classes.chatButton}>
            <Badge badgeContent={unreadMessagesCount} color="primary">
              <img src={CHAT_IMG} alt="chat" />
            </Badge>
          </Link>

          {advisorLeadData && (
            advisorLeadData.advisorLead?.ATL?.length ? (
              <Grid className={classes.myTeamBox}>
                <Typography variant="h6">My Team</Typography>

                {advisorLeadData?.advisorLead?.ATL?.map((item) => {
                  return (
                    <Box display="flex" alignItems="flex-start" mt="14px">
                      <Grid
                        className={classes.userProfile}
                        style={{
                          backgroundImage:
                            'url(' + item?.pictureUrl ||
                            (
                              <img
                                src={DefaultImg}
                                alt={`${item?.firstName || ''} ${
                                  item?.lastName || ''
                                }`}
                              />
                            ) + ')',
                        }}
                      />

                      <Box>
                        <Typography variant="body1">
                          {`${item?.firstName || '--'} ${
                            item?.lastName || '--'
                          }`}
                        </Typography>
                        <Typography variant="body2">
                          {item?.advisorLevel
                            ? item?.advisorLevel + ','
                            : '--'}{' '}
                          Service Contact
                        </Typography>
                        <a
                          href={`mailto:${item?.email}`}
                          className="emailPhoneText"
                        >
                          {item?.email || '--'}
                        </a>
                        <a
                          href={`tel:${item?.phoneNumber}`}
                          className="emailPhoneText"
                        >
                          {item?.phoneNumber || '--'}
                        </a>
                      </Box>
                    </Box>
                  );
                })}
              </Grid>
            ) : null
          )}

          {user == null && (
            <Link to="/login">
              <Button
                variant="outlined"
                color="default"
                type="submit"
                className={classes.button}
                style={{ textTransform: 'capitalize' }}
              >
                Sign In
              </Button>
            </Link>
          )}

          {user && renderMenu()}

          {user && (
            <>
              <Nav.Item>
                <Link to="/account/information">My Account</Link>
              </Nav.Item>

              {user.isActive && (
                <Nav.Item>
                  {
                    <Link
                      to="/time-log/add"
                      className={classes.logHoursButton}
                      style={{ fontWeight: 700 }}
                    >
                      Log Hours
                    </Link>
                  }
                </Nav.Item>
              )}

              <Nav.Item>
                {window.location.hostname !== 'app.collegeadvisor.com' ? (
                  <Link
                    to="/login"
                    className={classes.popoverSignOut}
                    onClick={() => {
                      localStorage.removeItem(TOKEN);
                      setUser(null);
                    }}
                  >
                    SIGN OUT
                  </Link>
                ) : (
                  <a
                    href="https://www.collegeadvisor.com"
                    className={classes.popoverSignOut}
                    onClick={(e) => {
                      localStorage.removeItem(TOKEN);
                      setUser(null);
                    }}
                  >
                    SIGN OUT
                  </a>
                )}
              </Nav.Item>
            </>
          )}
        </div>
      </SwipeableDrawer>
    </header>
  );
};

export default Header;
