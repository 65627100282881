import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Fonts from '../../../common/fonts';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popOverBoxListing: {
      border: '1px solid #D4D4D4',
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',
      borderRadius: 12,
      width: 500,
      padding: '21px 20px 25px',

      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },

      '& .MuiTypography-body1': {
        fontSize: 12,
        letterSpacing: '-0.006em',
        color: '#525454',
      },

      '& .MuiTypography-h5': {
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        color: '#FFCD4B',
        fontFamily: Fonts.POPPINS_FONT,
        marginBottom: 5,

        '& img': {
          marginRight: 10,
        },
      },

      '& .MuiDivider-root': {
        margin: '19px 0px 10px',
      },

      '& .MuiListItem-root': {
        padding: 0,
        marginBottom: 14,

        '&:last-child': {
          marginBottom: 0,
        },
      },

      '& .MuiListItemText-root': {
        [theme.breakpoints.down('xs')]: {
          width: 100,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
      },

      '& .MuiList-root': {
        paddingBottom: 0,
      },

      '& .MuiListItemAvatar-root': {
        minWidth: 30,
      },

      '& .MuiAvatar-root': {
        width: 19,
        height: 19,
        marginRight: 7,
      },

      '& .MuiBox-root': {
        marginTop: 20,

        '& .MuiButton-contained': {
          background: '#2F80ED',
          color: '#FFFFFF',
          marginLeft: 10,
          padding: '8px 15px',
        },

        '& .MuiButton-outlined': {
          border: '1px solid #D4D4D4',
          color: '#272929',
        },

        '& .MuiButtonBase-root': {
          fontWeight: 'bold',
          fontSize: 14,
          letterSpacing: '-0.006em',
          height: 32,
          borderRadius: 12,
          textTransform: 'capitalize',
        },
      },
    },

    popOverBox: {
      border: '1px solid #D4D4D4',
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',
      borderRadius: 12,
      width: 342,
      padding: '21px 20px 25px',

      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },

      '& .MuiTypography-body1': {
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#525454',
        fontFamily: Fonts.INTER,
      },

      '& .MuiListItemIcon-root': {
        width: 16,
        height: 16,
        backgroundColor: '#45CE93',
        borderRadius: 2,
        marginRight: 15,
      },

      '& .MuiListItem-root': {
        padding: 0,
        justifyContent: 'flex-end',

        '& .MuiTypography-body1': {
          fontSize: 12,
        },
      },

      '& .MuiListItemText-root': {
        flex: 'inherit',
        margin: 0,
      },

      '& .MuiFormControlLabel-root': {
        marginBottom: 0,
      },

      '& .MuiTypography-h5': {
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        color: '#272929',
        fontFamily: Fonts.POPPINS_FONT,
      },

      '& .MuiBox-root': {
        '& .MuiButton-contained': {
          background: '#2F80ED',
          color: '#FFFFFF',
          marginLeft: 10,
        },

        '& .MuiButton-outlined': {
          border: '1px solid #D4D4D4',
          color: '#272929',
        },

        '& .MuiButtonBase-root': {
          fontWeight: 'bold',
          fontSize: 14,
          letterSpacing: '-0.006em',
          width: 133,
          height: 32,
          borderRadius: 12,
          textTransform: 'capitalize',
        },
      },
    },

    confirmMessage: {
      '& .MuiTypography-h5': {
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        color: '#272929',
        marginBottom: 3,
        fontFamily: Fonts.POPPINS_FONT,
      },
    },
  })
);
