import { useEffect, useState } from 'react';
import {
  Card,
  makeStyles,
  createStyles,
  Theme,
  Box,
  Typography,
  Grid,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import gql from 'graphql-tag';
import client from '../../../apollo';
import fonts from '../../../common/fonts';
import { StudentDetailQuery_Student_premiumOfflineCountUsed } from './__generated__/StudentDetailQuery';

import EDIT_ICON from '../../../img/pencil-edit.svg';
import APPLICATION_ICON from '../../img/applicationIcon.svg'
import APPLICATION_FULL_ICON from '../../img/applicationFullIcon.svg'
import { roundNumber } from '../../../constants';
import colors from '../../../common/colors';

export const SET_ESSAY_COUNT = gql`
  mutation setEssayCountUsedMutation($studentId: Int!, $appCreditUsed: Int!) {
    setEssayCountUsed(studentId: $studentId, appCreditUsed: $appCreditUsed) {
      success
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hoursUsedCard: {
      margin: '35px 0px 7px',
      padding: '17px 29px',
      borderRadius: 15,
      border: '1px solid #D4D4D4',
      boxShadow: '0px 2px 7px rgba(68, 68, 68, 0.14)',

      [theme.breakpoints.down('xs')]: {
        padding: 16,
      },

      '& .MuiTypography-h6': {
        fontFamily: fonts.INTER,
        fontWeight: 600,
        fontSize: '18px !important',
        color: '#2F80ED',
        letterSpacing: '-0.014em',
        marginBottom: 4,
        textTransform: 'initial',

        [theme.breakpoints.down('xs')]: {
          fontSize: 15,
          lineHeight: '19px',
        },
      },

      '& .MuiTypography-body1': {
        fontFamily: fonts.INTER,
        fontWeight: 400,
        fontSize: 16,
        color: '#A9A9A9',
        letterSpacing: '-0.011em',
      },
      '& .MuiTypography-body2': {
        fontFamily: fonts.INTER,
        fontWeight: 400,
        fontSize: 16,
        color: colors.BLUE_SEVEN,
        letterSpacing: '-0.011em',
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },

    hoursUsedCardFull: {
      margin: '35px 0px 7px',
      padding: '17px 29px',
      borderRadius: 15,
      border: '1px solid #D4D4D4',
      boxShadow: '0px 2px 7px rgba(68, 68, 68, 0.14)',

      [theme.breakpoints.down('xs')]: {
        padding: 16,
      },

      '& .MuiTypography-h6': {
        fontFamily: fonts.INTER,
        fontWeight: 600,
        fontSize: '18px !important',
        color: '#E94444',
        letterSpacing: '-0.014em',
        marginBottom: 4,
        textTransform: 'initial',

        [theme.breakpoints.down('xs')]: {
          fontSize: 15,
          lineHeight: '19px',
        },
      },

      '& .MuiTypography-body1': {
        fontFamily: fonts.INTER,
        fontWeight: 400,
        fontSize: 16,
        color: '#A9A9A9',
        letterSpacing: '-0.011em',
      },
      '& .MuiTypography-body2': {
        fontFamily: fonts.INTER,
        fontWeight: 400,
        fontSize: 16,
        color: colors.BLUE_SEVEN,
        letterSpacing: '-0.011em',
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },

    myAppLink: {
      color: '1px solid ##2F80ED',
    },

    otherAppDesc: {
      color: '#A9A9A9',
    },

    editIconClass: {
      cursor: 'pointer',
    },

    otherAppDescWidth: {
      width: '70%',
    },

    hoursDetailText: {
      paddingTop: 19,
      marginTop: 23,
      borderTop: '1px solid #D4D4D4',

      '& .MuiBox-root': {
        marginBottom: 15,

        '&:last-child': {
          marginBottom: 0,
        },
      },

      '& .MuiTypography-body1': {
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
      },

      '& .MuiTypography-caption': {
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#A9A9A9',
        minWidth: 75,
        paddingRight: 15,
        fontFamily: fonts.INTER,

        '& span': {
          fontWeight: 400,
          paddingLeft: 3,
        },
      },
    },
  })
);

const useStylesFacebook = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      marginRight: 20,
      display: 'flex',
      alignItems: 'center',
    },

    bottom: {
      color: '#EAEAEA',
    },

    top: {
      color: '#2F80ED',
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },

    bottomFullUtilize: {
      color: '#E94444',
    },

    topFullUtilize: {
      color: '#E94444',
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },

    circle: {
      strokeLinecap: 'round',
    },

    forIconPosition: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& img': {
        position: 'absolute',
        margin: '0px auto',
      },
    },
  })
);

export interface IOfflineUsage {
  key: string;
  value: string;
  label?: string;
  type: string;
  description: string;
}

function FacebookCircularProgress({
  progress,
  isOfflineCard,
  progressColor,
}: {
  progress: number;
  isOfflineCard: boolean;
  progressColor: boolean,
}) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <Grid className={classes.forIconPosition}>
        <CircularProgress
          variant="determinate"
          className={progressColor ? classes.bottomFullUtilize: classes.bottom}
          size={56}
          thickness={4}
          value={100}
        />
        <img src={progressColor ? APPLICATION_FULL_ICON : APPLICATION_ICON} alt="monitor" />
      </Grid>

      <CircularProgress
        variant="determinate"
        disableShrink
        className={progressColor ? classes.topFullUtilize: classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={56}
        thickness={4}
        value={progress}
      />
    </div>
  );
}

export default function ApplicationEntitlement({
  studentId,
  extraApplicationCount,
  extraApplicationDesc,
  scholarshipApplicationCount,
  scholarshipApplicationDesc,
  totalHours,
  hoursUsed,
  text,
  studentApplications,
  premiumOfflineCountUsed,
  hideDetail = false,
  totalMilestoneVideoChatUsed,
  toggleOtherApplicationDialog,
  tabNavigation,
  myApplicationCount,
}: {
  studentId: number,
  extraApplicationCount: string;
  extraApplicationDesc: string;
  scholarshipApplicationCount: string;
  scholarshipApplicationDesc: string;
  totalHours: number;
  hoursUsed: number;
  text: string;
  studentApplications: any;
  premiumOfflineCountUsed?: StudentDetailQuery_Student_premiumOfflineCountUsed | null;
  hideDetail?: boolean;
  totalMilestoneVideoChatUsed?: number | null;
  toggleOtherApplicationDialog: (args:string) => void;
  tabNavigation: () => void;
  myApplicationCount: number;
}) {

  const [extraApplicationCountValue, setExtraApplicationCountValue] = useState<any>(0);
  const [extraApplicationDescValue, setExtraApplicationDescValue] = useState<string>('');
  const [scholarshipApplicationCountValue, setScholarshipExtraApplicationCountValue] = useState<any>(0);
  const [scholarshipApplicationDescValue, setScholarshipApplicationDescValue] = useState<string>('');

  const classes = useStyles();
  const bottomText = studentApplications.includes('University of California*(') || studentApplications.includes('(BSMD)')
  const progress = (hoursUsed / totalHours) * 100;
  const progressColor = hoursUsed >= totalHours;
  const remainingHours = roundNumber(totalHours - hoursUsed, 1);
  const remainingHoursText = `${remainingHours > 0 ? remainingHours : 0} application${
    remainingHours > 1 ? 's' : ''
  } remaining`;

  const handleEssayCountUsed = async (
    appCreditUsed: number | 0,
  ) => {
    try {
      const response = await client.mutate({
        mutation: SET_ESSAY_COUNT,
        variables: {
          studentId,
          appCreditUsed,
        },
      });

      if (response) {
        console.log('handleEssayCountUsed service call success', response);
      }
    } catch (error) {
      console.log('SET_OTHER_APPLICATION error', error);
    }
  }

  let offlineUsage: (IOfflineUsage | null)[] = [];
  if (premiumOfflineCountUsed) {
    const {
      essayCount,
      MeetingWithAdvisor,
      offlineResearch,
      Other,
      dressRehearsalCount,
      milestoneTeamTotal,
      correspondenceTotal,
    } = premiumOfflineCountUsed;

    offlineUsage = [
      {
        key: 'My Applications',
        value: myApplicationCount,
        label: 'My Applications',
        type: 'my_application',
        description: 'Description',
      },
      {
        key: 'Extracurricular Application',
        value: extraApplicationCountValue,
        label: 'Extracurricular Application',
        type: 'extraCurrApplication',
        description: extraApplicationDescValue,
      },
      {
        key: 'Scholarship Application',
        value: scholarshipApplicationCountValue,
        label: 'Scholarship Application',
        type: 'scholarshipApplication',
        description: scholarshipApplicationDescValue,
      }
    ];
  }

  useEffect(() => {
    setExtraApplicationCountValue(extraApplicationCount);
    setExtraApplicationDescValue(extraApplicationDesc);
    setScholarshipApplicationDescValue(scholarshipApplicationDesc);
    setScholarshipExtraApplicationCountValue(scholarshipApplicationCount);
  },[extraApplicationCount,extraApplicationDesc,scholarshipApplicationCount,scholarshipApplicationDesc]);

  useEffect(() => {
    handleEssayCountUsed(hoursUsed);
  },[hoursUsed]);

  return (
    <Card className={progressColor ? classes.hoursUsedCardFull : classes.hoursUsedCard}>
      <Box display="flex" alignItems="center">
        <FacebookCircularProgress
          progress={progress}
          isOfflineCard={premiumOfflineCountUsed ? true : false}
          progressColor={progressColor}
        />
        <Box>
          <Typography variant="h6">
            {roundNumber(hoursUsed, 1)} of {totalHours} {text}
          </Typography>
          <Typography variant="body1">{remainingHoursText}</Typography>
        </Box>
      </Box>
      {!hideDetail && premiumOfflineCountUsed && !!offlineUsage.length && (
        <>
          <Grid className={classes.hoursDetailText}>
            {offlineUsage?.map((item) => {
              const typeArg:any = item && item.type ? item.type : '';
              if (item?.key === 'My Applications') {
                return (
                    <Box key={item?.key} display="flex" alignItems="flex-start">
                      <Typography variant="caption">
                        {item?.value} 
                      </Typography>
                      <Box alignItems="center" className={classes.myAppLink}>
                        <Typography
                        variant="body2"
                        onClick={() => {
                          tabNavigation();
                        }}
                      >
                          {item?.label}
                        </Typography>
                        <Typography className={classes.otherAppDesc} variant="subtitle2">{studentApplications}</Typography>
                      </Box>
                    </Box>
                );
              }
              return (
                <Box key={item?.key} display="flex" alignItems="flex-start">
                  <Typography variant="caption">
                    {item?.value} {' '}
                  </Typography>
                  <Box alignItems="center" className={classes.otherAppDescWidth}>
                    <Typography variant="body1">{item?.label}</Typography>
                    <Typography className={classes.otherAppDesc} variant="subtitle2">{item?.description}</Typography>
                  </Box>
                  <Box alignItems="right">
                    <Typography
                      onClick={() => {
                        toggleOtherApplicationDialog(typeArg);
                        }}>
                      <img className={classes.editIconClass} src={EDIT_ICON} alt="edit" />
                    </Typography>
                  </Box>
                </Box>
              );
            })}
              {bottomText ? <Typography className={classes.otherAppDesc} variant="subtitle2">*A single University of California app, as well as each BS/MD program count as 2 applications.</Typography> : null}
          </Grid>
        </>
      )}
    </Card>
  );
}
