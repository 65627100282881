import { FC, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { ValueType } from 'react-select';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from '../style/NewTimeLogPageStyle';

type OptionType = {
  value: string;
  label: string;
};

type MultiSelectAsyncProps = {
  loadOptions: (inputValue: string) => Promise<OptionType[]>;
  setFieldValue: Function;
};

const OtherApplications: FC<MultiSelectAsyncProps> = ({
  loadOptions,
  setFieldValue,
}) => {
  const [applicationsCount, setApplicationsCount] = useState(0);
  const handleInputChange = (inputValue: string) => {
    return loadOptions(inputValue);
  };
  const classes = useStyles();

  const handleOptionChange = (selected: ValueType<OptionType, true>) => {
    setFieldValue('OtherApplicationsIds', selected);
    setApplicationsCount(selected.length);
  };

  return (
    <Grid className={classes.newApplications}>
      <label className="labelText">New Applications</label>
      <AsyncSelect
        isMulti
        loadOptions={handleInputChange}
        onChange={handleOptionChange}
        isClearable
        isSearchable
        cacheOptions
        defaultOptions
        placeholder="New Applications"
        classNamePrefix="react-select"
        className="react-select-container"
      />
      <Typography variant="body1" className={classes.applicationText}>
        ({applicationsCount}) Application will be added to student’s{' '}
        <i>My Applications</i> list on the Student Portal upon submission.
      </Typography>
    </Grid>
  );
};

export default OtherApplications;
