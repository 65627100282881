import gql from 'graphql-tag';

export const UPDATE_OR_SAVE_STUDENT_NOTES = gql`
  mutation UpdateOrSaveStudentNotes(
    $StudentId: Int!
    $type: String!
    $description: String!
    $NoteId: Int
    $AdvisorId: Int
  ) {
    updateOrSaveStudentNotes(
      input: {
        StudentId: $StudentId
        type: $type
        description: $description
        NoteId: $NoteId
        AdvisorId: $AdvisorId
      }
    ) {
      message
      success
    }
  }
`;

export const DELETE_STUDENT_NOTE = gql`
  mutation DeleteStudentNote($NoteId: Int!) {
    deleteStudentNote(NoteId: $NoteId) {
      message
      success
    }
  }
`;
