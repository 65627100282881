import { useState, FC } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Button, Card, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Formik, Form, Field } from 'formik';
import { TextField } from '../../components/fields';
import * as Yup from 'yup';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import {
  InitResetPasswordMuation,
  InitResetPasswordMuationVariables,
} from './__generated__/InitResetPasswordMuation';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import CustomAlert from '../../components/CustomAlert';
import { useStyles } from './styles';

export const INIT_RESET_PWD_MUTATION = gql`
  mutation InitResetPasswordMuation($email: String!, $portal: String!) {
    initResetPassword(input: { email: $email, portal: $portal }) {
      status
    }
  }
`;

const ForgotPasswordPage: FC = () => {
  const initialValues: any = { email: '' };

  const FormSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
  });

  const [variant, setVariant] = useState('');
  const [message, setMessage] = useState('');
  function toggleAlert(variant?: string, message?: string) {
    setVariant(variant || '');
    setMessage(message || '');
  }

  const classes = useStyles();
  return (
    <Grid>
      <Header />

      <Grid className={classes.contentBoxHeight}>
        <Grid className={classes.formContainer}>
          <Card className={classes.card}>
            <CustomAlert
              variant={variant}
              message={message}
              toggleAlert={toggleAlert}
            />

            <Mutation<
              InitResetPasswordMuation,
              InitResetPasswordMuationVariables
            >
              mutation={INIT_RESET_PWD_MUTATION}
            >
              {(resetPwdMutation: Function) => {
                return (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={FormSchema}
                    onSubmit={async (
                      variables,
                      { setSubmitting, resetForm }
                    ) => {
                      try {
                        const _variables = { ...variables, portal: 'Mentor' };
                        await resetPwdMutation({ variables: _variables });

                        const variant = 'success';
                        const message =
                          'Email with instructions sent to your email address.';
                        toggleAlert(variant, message);
                        resetForm(initialValues);
                        setSubmitting(false);
                      } catch (error) {
                        console.log('error: ', error);
                        setSubmitting(false);
                        const variant = 'error';
                        const message =
                          'Something went wrong, Please try again';
                        toggleAlert(variant, message);
                      }
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.formHeading}
                        >
                          Restore Password
                        </Typography>

                        <Field
                          type="email"
                          name="email"
                          component={TextField}
                          label="Email"
                          fullWidth
                          className={classes.textfield}
                        />

                        <Button
                          variant="contained"
                          type="submit"
                          disabled={isSubmitting}
                          className={classes.signButton}
                          style={{ textTransform: 'capitalize' }}
                        >
                          Send password reset link
                        </Button>
                      </Form>
                    )}
                  </Formik>
                );
              }}
            </Mutation>
          </Card>

          <Grid className={classes.FormBottomSection}>
            <Typography variant="body1" className={classes.FormBottomText}>
              Remembered your password?
            </Typography>

            <Link to="/login" className={classes.FormBottomLink}>
              <Button
                variant="contained"
                style={{ textTransform: 'capitalize' }}
                className={clsx(classes.signButton, classes.signNavigateButton)}
              >
                Back to Sign In
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>

      <Footer />
    </Grid>
  );
};

export default ForgotPasswordPage;
