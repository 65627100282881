import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { FC } from 'react';
import { SnackBarProps } from './interfaces';

const SnackbarComponent: FC<SnackBarProps> = ({
  open,
  handleClose,
  message,
  anchorOrigin,
  autoHideDuration,
  severity,
  styles,
}) => {
  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      style={styles ? { ...styles } : {}}
    >
      <Alert severity={severity} onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};
export default SnackbarComponent;
