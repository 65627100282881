import styled from 'styled-components';
import Colors from '../common/colors';

export const GlobalStyle = styled.div`
  @media only screen and (max-width: 767px) {
    h2 {
      width: 100% !important;
      font-size: 26px !important;
      line-height: 30px !important;
      letter-spacing: 0px !important;
      margin-bottom: 22px;
    }

    .makeStyles-paper-2 {
      padding: 15px;
      flex-direction: column;

      h2 {
        font-size: 30px !important;
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    h2 {
      font-size: 26px !important;
      letter-spacing: 0px !important;
      line-height: 30px !important;
    }
  }

  .flex-direction-column {
    @media only screen and (max-width: 991px) {
      flex-direction: column;
    }
  }

  .flex-direction-column-xs {
    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .mentors_col {
      padding: 0px;
    }
  }

  .mb-xs-2 {
    @media only screen and (max-width: 991px) {
      margin-bottom: 16px;
    }
  }

  button:focus,
  input:focus {
    outline: 0px !important;
  }

  a:hover {
    text-decoration: none;
  }

  @media only screen and (min-width: 768px) {
    .container {
      padding: 0px;
    }
  }

  .content-box-height-empty {
    min-height: calc(100vh - 139px);
  }

  .content-box-height {
    min-height: calc(100vh - 130px);
  }

  .content-box-height-forgot {
    min-height: calc(100vh - 132px);
  }

  @media only screen and (min-height: 1000px) {
    .content-box-height {
      height: calc(99vh - 142px);
    }
  }

  .card-header-bg-harvard {
    background: linear-gradient(
      97.59deg,
      ${Colors.RED_THREE_APP} 0%,
      ${Colors.RED_FOUR_APP} 100%
    );
  }

  .card-header-bg-ny {
    background: linear-gradient(
      90deg,
      ${Colors.BLUE_NINE_APP} 0%,
      ${Colors.BLUE_TEN_APP} 100%
    );
  }

  h2 {
    font-weight: 600;
    font-size: 56px;
    letter-spacing: -3px;
    color: ${Colors.BLACK_THREE};
    line-height: 82px;
  }

  .onboardSearch {
    padding: 0px;
  }

  .onboardBorder {
    border-bottom: 0px;
  }

  .checkcollegesPadding {
    padding: 0px;
  }

  .badge:empty {
    display: block;
  }

  .application-card {
    .MuiCard-root {
      height: 260px;
    }

    p {
      height: 70px;
    }
  }

  .disable-college-card {
    background: ${Colors.GRAY_NINE};
    cursor: not-allowed;
    opacity: 0.5;
  }

  .your-applications-box-height {
    min-height: calc(100vh - 538px);
  }
`;


export const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: 14,
    }),

    placeholder: (provided, state) => ({
      ...provided,
      color: '#A9A9A9',
    }),
  };