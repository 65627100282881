
import { Box, Card, Typography, Button, Grid } from '@material-ui/core';
// icons
import Info_Blue_Img from '../img/blue-info.svg';
// styles
import { useStyles } from './styles';

const AlertInfoCard = () => {
  const classes = useStyles();

  return (
    <Card className={classes.alertInfoCard}>
      <Box display="flex">
        <Grid>
          <img src={Info_Blue_Img} alt="token" />
        </Grid>
        <Grid>
          <Typography variant="h5">Entitlements Exhausted</Typography>
          <Typography variant="body1">
            Currently, one or both of your students' entitlements is exhausted.
            Please either obtain permission from your student to move hours from
            VC to Offline or vice-versa or have them purchase additional hours
            for their account.{' '}
            <b>
              You are not allowed to continue to log hours for this account in
              the entitlement category where there are overages.
            </b>
          </Typography>

          <a
            href="https://airtable.com/appXJCNAr6BfsfzYc/shr7DBFUvVrJsjGgw"
            target="_blank"
          >
            <Button variant="contained">Submit Support Ticket</Button>
          </a>
        </Grid>
      </Box>
    </Card>
  );
};

export default AlertInfoCard;