import { createStyles, makeStyles, Theme } from '@material-ui/core';

import fonts from '../../common/fonts';
import colors from '../../common/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activeIncentives: {
      '& .headingText': {
        fontSize: 36,
        fontWeight: 700,
        marginBottom: 21,
        letterSpacing: '-0.792px',
        color: colors.BLACK_TWELVE,
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .descriptionText': {
        marginBottom: 28,
        color: colors.BLACK_FOURTEEN,
      },

      '& .bodyText': {
        fontSize: 16,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.176px',
      },
    },

    availableBonusBox: {
      display: 'flex',
      borderRadius: 15,
      flexWrap: 'wrap',
      boxShadow: 'none',
      marginLeft: ' auto',
      alignItems: 'center',
      padding: '26px 28px',
      justifyContent: 'space-between',
      background: colors.GRAY_BOREDER,

      '& .labelText, & .priceText': {
        color: colors.BLACK_TWELVE,
      },

      '& .priceText': {
        fontWeight: 700,
      },
    },

    activeIncentivesTable: {
      width: '100%',

      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },

      '& .labelText': {
        color: colors.BLACK_FOURTEEN,
      },

      '& .priceText': {
        fontWeight: 700,
        color: colors.GRAY_TAB_BORDER,
      },

      '& .bodyText': {
        fontSize: 16,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.176px',
      },

      '& .MuiTable-root': {
        borderSpacing: '0px 13px',
      },

      '& .MuiTable-root thead tr th': {
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: '0.084px',
        color: colors.GREEN_EIGHT,
        textTransform: 'uppercase',
        padding: '5px 10px !important',
        fontFamily: fonts.POPPINS_FONT,
        '&.data-cell': {
          color: colors.BLACK_FOURTEEN,
        },
      },

      '& .MuiTable-root tbody tr': {
        borderRadius: 14,
        boxShadow: `0px 2px 7px 0px ${colors.RGBA_COLOR_ONE}`,

        '& td': {
          fontSize: 16,
          color: colors.BLACK_TWELVE,
          letterSpacing: '-0.176px',
          padding: '5px 10px !important',
          fontWeight: '400 !important' as const,

          '&:first-of-type': {
            paddingLeft: '53px !important',
            borderRadius: '14px 0px 0px 14px',
          },

          '&:last-of-type': {
            borderRadius: '0px 14px 14px 0px',
          },
        },
      },

      '& .MuiTableRow-footer .MuiTableCell-footer': {
        border: 0,
        whiteSpace: 'nowrap',
        padding: '20px 10px 5px !important',
        borderTop: `1px solid ${colors.WHITE_SEVEN}`,
      },
    },

    incentiveBreakDownPackageDialog: {
      '& .MuiDialog-paper': {
        width: '100%',
        maxWidth: 908,
        borderRadius: 25,
        padding: '46px 52px 61px',
        boxShadow: `0px 4px 14px 0px ${colors.RGBA_COLOR_ONE}`,

        [theme.breakpoints.down('xs')]: {
          padding: 20,
          margin: '0px 10px',
        },
      },

      '& .incentiveBreakdownDialog': {
        '& .packageStudentTableHeading': {
          fontSize: 18,
          fontWeight: 600,
          fontFamily: fonts.INTER,
          letterSpacing: '-0.252px',
          color: colors.BLACK_TWELVE,
        },
      },

      '& .dialogHeader': {
        marginBottom: 12,

        '& .headingText': {
          fontSize: 36,
          fontWeight: 700,
          color: colors.BLUE_SEVEN,
          letterSpacing: '-0.792px',
          fontFamily: fonts.POPPINS_FONT,

          [theme.breakpoints.down('xs')]: {
            fontSize: 24,
          },
        },
      },

      '& .MuiDialogContent-root': {
        padding: '0px 20px 0px 0px',

        [theme.breakpoints.down('xs')]: {
          padding: '0px 10px 0px 0px',
        },

        '& .descriptionText': {
          fontSize: 16,
          color: colors.BLACK_FOURTEEN,
          marginBottom: 39,
          fontFamily: fonts.INTER,
          letterSpacing: '-0.176px',

          '& a': {
            color: colors.BLUE_SEVEN,
            borderBottom: `1px solid ${colors.BLUE_SEVEN}`,
          },
        },
      },
    },

    incentiveBreakdownTable: {
      '& thead tr th': {
        padding: 0,
      },

      '& tbody tr td': {
        fontFamily: fonts.INTER,
        border: `1px solid ${colors.WHITE_SEVEN}`,

        '&:first-of-type': {
          fontSize: 16,
          borderRight: 0,
          fontWeight: 600,
          color: colors.BLACK_TWELVE,
          padding: '19px 36px',
          verticalAlign: 'baseline',
          letterSpacing: '-0.176px',
          backgroundColor: colors.GRAY_BOREDER,
        },

        '&:last-of-type': {
          fontSize: 12,
          borderLeft: 0,
          color: colors.BLACK_FOURTEEN,
          padding: '17px 23px',
          verticalAlign: 'bottom',
          letterSpacing: '-0.072px',
        },
      },
    },

    studentPackageTable: {
      '& .bonusHeader th': {
        border: 0,
        fontSize: 14,
        fontWeight: 600,
        padding: '0px 10px',
        letterSpacing: '0.084px',
        color: colors.GREEN_EIGHT,
        textTransform: 'uppercase',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .tableHeader th': {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '20px',
        padding: '5px 10px',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.084px',
        color: colors.BLACK_TWELVE,
        border: `1px solid ${colors.WHITE_SEVEN}`,

        '&:first-of-type': {
          border: 0,
        },
      },

      '& .lightGray': {
        backgroundColor: colors.GRAY_BOREDER,
      },

      '& th.lightGreen': {
        backgroundColor: colors.LIGHT_GREEN,
        borderLeft: `2px solid ${colors.WHITE_SEVEN}`,
      },

      '& td.lightGreen': {
        backgroundColor: colors.LIGHT_GREEN_ONE,
        borderLeft: `2px solid ${colors.WHITE_SEVEN}`,
      },

      '& .darkGray': {
        backgroundColor: colors.GRAY_EIGHT,
      },

      '& tbody tr': {
        '& td': {
          padding: 12,
          fontSize: 16,
          whiteSpace: 'nowrap',
          fontFamily: fonts.INTER,
          letterSpacing: '-0.176px',
          color: colors.BLACK_TWELVE,
          border: `1px solid ${colors.WHITE_SEVEN}`,

          '&:first-of-type': {
            fontWeight: 600,
            paddingLeft: 36,
            backgroundColor: colors.GRAY_BOREDER,

            [theme.breakpoints.down('xs')]: {
              paddingLeft: 12,
            },
          },
        },

        '& .bonusDivider': {
          borderLeft: `2px solid ${colors.WHITE_SEVEN}`,
        },
      },
    },

    studentPackageTableOverflow: {
      overflow: 'auto',

      [theme.breakpoints.down('sm')]: {
        paddingBottom: 20,
      },
    },

    incentiveDialogButton: {
      '& button': {
        padding: 8,
        fontSize: 16,
        fontWeight: 400,
        fontFamily: fonts.INTER,
        color: colors.BLUE_SEVEN,
        letterSpacing: '-0.176px',
        textTransform: 'capitalize',

        '& img': {
          marginRight: 6,
        },
      },
    },
    tootltipItem: {
      cursor: 'pointer',
      '&.imgItem': {
        display: 'inline-block',
      },
    },
    entitlementItem: {
      color: `${colors.GRAY_TWENTYTWO} !important`,
    },
  })
);
