import { FC } from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Box, Typography } from '@material-ui/core';
import { OtherReasonProps } from '../../../common/interfaces';
import { useStylesAccount } from '../account/accountStyle';

const OtherReason: FC<OtherReasonProps> = ({ setOtherReason, title, otherReason }) => {
    const classes = useStylesAccount();
    const handleOtherReason = (event: any) => {
        setOtherReason(event.target.value);
    }
    return (
        <>
            <Box className={classes.accountInformationForm}>
                <Typography variant='body1' className='fieldCaption'>
                    {title}
                </Typography>
                <TextareaAutosize rowsMin={3} className={classes.otherReason} value={otherReason} placeholder='Enter details here.' onChange={handleOtherReason} />

            </Box>
        </>
    )
}

export default OtherReason;