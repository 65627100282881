import { MouseEvent, FC, useState } from 'react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import {
  Grid,
  Box,
  Card,
  Typography,
  Avatar,
  Button,
  withStyles,
  ListItemIcon,
  MenuItem,
  TableCell,
  TableRow,
  IconButton,
  Hidden,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import Delete_Img from '../../../img/delete-icon.svg';
import CLOSE_ICON from '../../../img/dialog-close-icon.svg';
import TOGGLE_IMG from '../../../img/menu-toggle-meeting.svg';
import NOTE from '../../../img/note.svg';
import CLOSE_IMG from '../../../img/close-dialog.svg';
import colors from '../../../common/colors';
import fonts from '../../../common/fonts';
import { bookingInfoStatuses } from '../../../constants';
import CustomTooltip from '../../../components/CustomTooltip';
import { canceledReason } from '../../../common/utility';
import { useStyles } from './BookingMeeting/BookingMeetingStyle';
import MenuOptions from '../meetings/MenuOptions';
import CancelMeetingModal from '../meetings/CancelMeetingModal';
import client from '../../../apollo';
import { allAdvisorBookingsQuery_allAdvisorBookings_advisorBookings } from '../meetings/gql/__generated__/allAdvisorBookingsQuery';
import Toast, { Color } from '../../../components/Toast';
import { DELETE_BOOKING } from '../meetings/gql';
import { StudentPastMeetingsTableProps } from '../../../common/interfaces';
const StyledMenu = withStyles({
  paper: {
    borderRadius: 12,
    background: colors.WHITE_ONE,
    border: `1px solid ${colors.WHITE_SEVEN}`,
    boxShadow: `0px 4px 14px ${colors.RGBA_COLOR_ONE}`,

    '& ul': {
      padding: 11,
    },
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {
    borderRadius: 5,
    fontFamily: fonts.POPPINS_FONT,

    '& .menu-item-style': {
      minWidth: 34,
    },
  },
}))(MenuItem);

const StudentsPastMeetingsTable: FC<StudentPastMeetingsTableProps> = ({
  bookingInfo,
  refetch,
  status,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedRow, setSelectedRow] =
    useState<allAdvisorBookingsQuery_allAdvisorBookings_advisorBookings | null>(
      null
    );
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [reason, setReason] = useState<string>('');
  const [otherReason, setOtherReason] = useState<string>(
    bookingInfo?.otherReason || ''
  );
  const [openToast, setOpenToast] = useState(false);
  const [toast, setToast] = useState({ message: '' });
  const [type, setType] = useState<Color>('success');
  const disabled = true;
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCloseDialoag = () => {
    setOpen(false);
  };
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const participantNames = bookingInfo?.allParticipants
    ?.filter((item: any) => !item?.roles?.includes('Student'))
    ?.map((x: any) => `${x?.firstName} ${x?.lastName}`)
    .join(', ');
  const allAdvisors = bookingInfo?.allParticipants?.filter(
    (item: any) => !item?.roles?.includes('Student')
  );

  const mainAdv = allAdvisors?.find(
    (item: any) => item?.id === bookingInfo?.AdvisorId
  );
  const note = bookingInfo?.note;
  const startDate = bookingInfo?.startTime
    ? moment.unix(bookingInfo?.startTime).format('ddd MMM DD, YYYY')
    : 'N/A';
  const startTime = bookingInfo?.startTime
    ? moment.unix(bookingInfo?.startTime).format('LT')
    : 'NA';

  const handlePopUpNotification = (message: string, type: Color) => {
    setToast({ message });
    setOpenToast(true);
    setType(type);
  };
  const handleCancelMeeting = async (reason: String, other: string) => {
    try {
      if (reason === 'other' && !other) {
        handlePopUpNotification("Reason can't be empty", 'error');
      } else {
        setLoading(true);
        if (selectedRow?.id && selectedRow.AdvisorId) {
          await client.mutate({
            mutation: DELETE_BOOKING,
            variables: {
              eventId: selectedRow.id,
              AdvisorId: selectedRow.AdvisorId,
              reason: reason,
              ...(other && {
                otherReason: other,
              }),
            },
          });
          setOpenDeleteConfirmation(false);
          setSelectedRow(null);
          setReason('');
          setOtherReason('');
          handlePopUpNotification('Meeting Canceled', 'success');
          refetch && refetch();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Toast
        open={openToast}
        close={() => {
          setOpenToast(false);
        }}
        {...toast}
        autoHideDuration={3000}
        type={type}
      />
      <CancelMeetingModal
        open={openDeleteConfirmation}
        onClose={() => {
          setOpenDeleteConfirmation(!openDeleteConfirmation);
          setReason('');
          setOtherReason('');
          setIsComplete(false);
        }}
        title="Cancel Meeting"
        firstButtonText="Cancel Meeting"
        secondButtonText="Keep"
        handleCancelBtn={handleCancelMeeting}
        loading={loading}
        startTime={startTime}
        startDate={startDate}
        reason={reason}
        setReason={setReason}
        setOtherReason={setOtherReason}
        otherReason={otherReason}
        isComplete={isComplete}
      />
      <Hidden only={['sm', 'xs']}>
        <TableRow className={classes.bookingTableRow}>
          <TableCell>
            <AvatarGroup max={2} className="avatar-group">
              {allAdvisors?.length ? (
                allAdvisors.map((item: any) => {
                  if (item?.pictureUrl)
                    return (
                      <Avatar
                        src={item.pictureUrl}
                        className={`user-avatar ${
                          disabled && 'avatar-disabled'
                        }`}
                      />
                    );
                  return <Avatar className="user-avatar" />;
                })
              ) : (
                <Avatar className="user-avatar" />
              )}
            </AvatarGroup>
          </TableCell>
          <TableCell>
            <Typography variant="h6" className="participantName">
              {participantNames}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">
              {bookingInfo?.startTime && bookingInfo?.endTime
                ? `${moment
                    .unix(bookingInfo?.startTime)
                    .format('ddd MMM DD, YYYY')}`
                : '_ _'}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2">
              {bookingInfo?.startTime && bookingInfo?.endTime
                ? `${moment
                    .unix(bookingInfo?.startTime)
                    .format('LT')} - ${moment
                    .unix(bookingInfo?.endTime)
                    .format('LT')} ( ${momentTz(bookingInfo?.startTime * 1000)
                    .tz(momentTz.tz.guess())
                    .format('z')} )`
                : '_ _'}
            </Typography>
          </TableCell>
          {status === bookingInfoStatuses.COMPLETED && (
            <TableCell>
              <MenuOptions
                openDeleteModal={() => {
                  setOpenDeleteConfirmation(!openDeleteConfirmation);
                  setIsComplete(!isComplete);
                  setSelectedRow(bookingInfo);
                }}
              />
            </TableCell>
          )}
          {(bookingInfo.status === bookingInfoStatuses.DELETED ||
            bookingInfo.endTime > new Date().getTime() / 1000) && (
            <>
              <TableCell>
                {note?.length ? (
                  <>
                    <div>
                      <IconButton onClick={handleClickOpen}>
                        <img src={NOTE} alt="note" />
                      </IconButton>
                      <Dialog
                        open={open}
                        onClose={handleCloseDialoag}
                        className={classes.meetingDialogMain}
                      >
                        <DialogContent className={classes.meetingNotesDialog}>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            marginBottom={1}
                          >
                            <Typography variant="h6" className="dialogHeading">
                              Meeting Note
                            </Typography>

                            <IconButton onClick={handleCloseDialoag}>
                              <img src={CLOSE_ICON} alt="close" />
                            </IconButton>
                          </Box>

                          <Typography
                            variant="body1"
                            className="dialogSubtitle"
                          >
                            {note}
                          </Typography>
                        </DialogContent>
                      </Dialog>
                    </div>
                  </>
                ) : (
                  <Box style={{ padding: 12 }}>--</Box>
                )}
              </TableCell>
              {bookingInfo.status === bookingInfoStatuses.DELETED && (
                <TableCell>
                  <Typography variant="body2">
                    {bookingInfo?.cancelledByUser
                      ? `${bookingInfo?.cancelledByUser?.firstName} ${bookingInfo?.cancelledByUser?.lastName}`
                      : '--'}
                  </Typography>
                </TableCell>
              )}

              {bookingInfo.status === bookingInfoStatuses.DELETED && (
                <TableCell>
                  <Typography variant="body2">
                    {bookingInfo?.canceledReason
                      ? canceledReason(bookingInfo?.canceledReason)?.label
                      : '--'}
                    {bookingInfo?.otherReason !== null && (
                      <CustomTooltip message={bookingInfo?.otherReason}>
                        {' '}
                        -{' '}
                        {bookingInfo?.otherReason.length < 20
                          ? bookingInfo?.otherReason?.substring(0, 20)
                          : bookingInfo?.otherReason?.substring(0, 20) + '...'}
                      </CustomTooltip>
                    )}
                  </Typography>
                </TableCell>
              )}
            </>
          )}
        </TableRow>
      </Hidden>

      <Hidden only={['xl', 'lg', 'md']}>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          className={`${classes.mobileMeetingsView} ${classes.bookAppointment}`}
        >
          <Box display="flex">
            <AvatarGroup max={2} className="avatar-group">
              {allAdvisors?.length ? (
                allAdvisors.map((item: any) => {
                  if (item?.pictureUrl)
                    return (
                      <Avatar
                        src={item.pictureUrl}
                        className={`user-avatar ${
                          disabled && 'avatar-disabled'
                        }`}
                      />
                    );
                  return <Avatar className="user-avatar" />;
                })
              ) : (
                <Avatar className="user-avatar" />
              )}
            </AvatarGroup>

            <Grid>
              <Typography variant="h6">{participantNames}</Typography>

              <Typography variant="body1">
                {bookingInfo?.startTime && bookingInfo?.endTime
                  ? `${moment
                      .unix(bookingInfo?.startTime)
                      .format('ddd MMM DD, YYYY')}`
                  : '_ _'}
              </Typography>

              <Typography variant="body1" className="linkSpacing">
                {' '}
                {bookingInfo?.startTime && bookingInfo?.endTime
                  ? `${moment
                      .unix(bookingInfo?.startTime)
                      .format('LT')} - ${moment
                      .unix(bookingInfo?.endTime)
                      .format('LT')} ( ${momentTz
                      .tz(momentTz.tz.guess())
                      .format('z')} )`
                  : '_ _'}
              </Typography>

              <a
                href={bookingInfo?.zoomLink || mainAdv?.zoomLink || ''}
                target="_blank"
                rel="noreferrer"
              >
                Link
              </a>

              {(process.env.REACT_APP_ENABLE_MEETING_REVIEWS !== 'TRUE' ||
                bookingInfo.status === bookingInfoStatuses.DELETED ||
                bookingInfo.endTime > new Date().getTime() / 1000) && (
                <>
                  <Card
                    className={`booking-card ${
                      disabled && 'booking-card-disabled'
                    }`}
                  >
                    <Box className="mobile-flex-main">
                      <Box
                        display="flex"
                        alignItems="center"
                        className="mobile-flex-direction"
                      >
                        <AvatarGroup max={2} className="avatar-group">
                          {allAdvisors?.length ? (
                            allAdvisors.map((item: any) => {
                              if (item?.pictureUrl)
                                return (
                                  <Avatar
                                    src={item.pictureUrl}
                                    className={`user-avatar ${
                                      disabled && 'avatar-disabled'
                                    }`}
                                  />
                                );
                              return <Avatar className="user-avatar" />;
                            })
                          ) : (
                            <Avatar className="user-avatar" />
                          )}
                        </AvatarGroup>

                        <Box ml={2} className="text-box-spacing">
                          <Typography variant="h6">
                            {participantNames}
                          </Typography>
                          <Typography variant="body2">
                            {bookingInfo?.startTime && bookingInfo?.endTime
                              ? `${moment
                                  .unix(bookingInfo?.startTime)
                                  .format('DD MMM YYYY')}, ${moment
                                  .unix(bookingInfo?.startTime)
                                  .format('LT')} - ${moment
                                  .unix(bookingInfo?.endTime)
                                  .format('LT')}`
                              : '_ _'}
                          </Typography>
                        </Box>
                      </Box>

                      <Box className={classes.mobileAlignment}>
                        {!!!disabled && (
                          <Button
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            variant="text"
                            onClick={handleClick}
                            className="menu-button"
                          >
                            <img src={TOGGLE_IMG} alt="toggle" />
                          </Button>
                        )}
                      </Box>

                      <StyledMenu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <StyledMenuItem
                          onClick={() => {
                            setOpenDeleteConfirmation(!openDeleteConfirmation);
                          }}
                        >
                          <ListItemIcon className="menu-item-style">
                            <img src={CLOSE_IMG} alt="close" />
                          </ListItemIcon>
                          <Typography variant="h6">CANCEL</Typography>
                        </StyledMenuItem>
                      </StyledMenu>
                    </Box>
                  </Card>
                </>
              )}
            </Grid>
          </Box>

          {!!!disabled && (
            <IconButton
              onClick={() => {
                setOpenDeleteConfirmation(!openDeleteConfirmation);
              }}
              className="menu-button"
            >
              <img src={Delete_Img} alt="toggle" />
            </IconButton>
          )}
        </Box>
      </Hidden>
    </>
  );
};

export default StudentsPastMeetingsTable;
