import { FC } from 'react';
import {
  Grid,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
} from '@material-ui/core';

import { useStyles } from './StyleActiveIncentives';
import { INCENTIVE_STUDENT_PACKAGE_DATA } from '../../constants';

const IncentiveStudentPackageTable: FC = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.studentPackageTableOverflow}>
      <Table className={classes.studentPackageTable}>
        <TableHead className="bonusHeader">
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell align="center">YOUR BONUS</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableHead className="tableHeader">
          <TableRow>
            <TableCell width="25%"></TableCell>
            <TableCell width="10%" className="lightGray" align="center">
              Decisions
            </TableCell>
            <TableCell width="15%" className="lightGray" align="center">
              Positive <br />
              Reviews
            </TableCell>
            <TableCell width="10%" className="lightGray" align="center">
              Exit <br />
              Survey
            </TableCell>
            <TableCell width="15%" className={'lightGreen'} align="center">
              Advisor <br />
              Share
            </TableCell>
            <TableCell width="10%" className={'lightGray'} align="center">
              AO <br />
              Share
            </TableCell>
            <TableCell width="15%" className="darkGray" align="center">
              Package <br />
              Total
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {INCENTIVE_STUDENT_PACKAGE_DATA.map((item) => {
            const {
              packageName,
              decisions,
              reviews,
              exitSurvey,
              advisorShare,
              aoShare,
              packageTotal,
            } = item;
            return (
              <TableRow>
                <TableCell>{packageName}</TableCell>
                <TableCell align="center">{decisions}</TableCell>
                <TableCell align="center">{reviews}</TableCell>
                <TableCell align="center">{exitSurvey}</TableCell>
                <TableCell align="center" className={'lightGreen'}>
                  {advisorShare}
                </TableCell>
                <TableCell align="center" className={''}>
                  {aoShare}
                </TableCell>
                <TableCell align="center" className="lightGray">
                  <b>{packageTotal}</b>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Grid>
  );
};

export default IncentiveStudentPackageTable;
