import { useContext, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import {
  Typography,
  Grid,
  Box,
  InputBase,
  IconButton,
  Button,
  Container,
} from '@material-ui/core';

import Header from '../../../components/layout/Header';
import MeetingTabs from './MeetingTabs';
import { AppContext } from '../../../contexts';
import { generateConnectURL } from '../../../common/nylas';
import { meetingStyles } from './styles';

const Meetings = () => {
  const classes = meetingStyles();
  const { user } = useContext(AppContext);
  const [searchValue, setSearchValue] = useState('');
  let timeOutId = 0;
  const params = {
    client_id: process.env.REACT_APP_NYLAS_V3_CLIENT_ID_PRD || '',
    redirect_uri:
      process.env.REACT_APP_REDIRECT_URI ||
      'https://staging.advisors.collegeadvisor.com/callback',
    login_hint: user?.email ?? '',
    response_type: 'code',
    scopes: 'calendar',
    access_type: 'offline',
  };
  return (
    <>
      <Header />
      <Container maxWidth={false} className="containerPadding">
        <Grid className={classes.meetingWrapper}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {!user?.nylas_grant_id && (
              <Button
                href={generateConnectURL(params)}
                rel="noopener noreferrer"
                color="primary"
                className={classes.connectCalendar}
              >
                Connect to calendar
              </Button>
            )}
          </Box>

          <Typography variant="h3">My Meetings</Typography>

          <Box
            display="flex"
            alignItems="center"
            className={classes.meetingFilter}
          >
            <Grid className={classes.meetingFilterSearch}>
              <IconButton
                type="submit"
                className={classes.iconButton}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                placeholder="Search Students, Advisors, etc."
                type="text"
                onChange={(e) => {
                  clearTimeout(timeOutId);
                  timeOutId = window.setTimeout(
                    () => setSearchValue(e.target.value),
                    500
                  );
                }}
              />
            </Grid>
          </Box>
        </Grid>

        <MeetingTabs searchValue={searchValue} />
      </Container>
    </>
  );
};

export default Meetings;
