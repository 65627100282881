import { createStyles, Theme, makeStyles } from '@material-ui/core';

import fonts from '../../../../common/fonts';
import colors from '../../../../common/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    feedbackDialog: {
      '& .MuiDialog-paper': {
        width: 689,
        margin: 10,
        maxWidth: 689,
        borderRadius: 25,
        overflow: 'hidden',
        padding: '44px 0px 55px',
        border: '1px solid #D4D4D4',
        boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

        [theme.breakpoints.down('xs')]: {
          padding: '40px 16px',
        },
      },

      '& .customizeScroll': {
        padding: '0px 49px',

        [theme.breakpoints.down('xs')]: {
          padding: '0px 16px',
        },
      },

      '& .inputCaption': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        marginBottom: 4,
        fontFamily: fonts.INTER,
      },

      '& .inputCaptionOpacity': {
        opacity: 0.4,
      },

      '& .MuiInputBase-root': {
        width: '100%',
      },

      '& textarea': {
        boxSizing: 'border-box',
        padding: '8px 16px !important',
      },

      '& .MuiInputBase-input': {
        height: 38,
        fontSize: 14,
        opacity: 0.9,
        borderRadius: 6,
        fontWeight: 400,
        color: '#A9A9A9',
        padding: '0px 16px',
        letterSpacing: '-0.006em',
        border: '1px solid #D4D4D4',

        '&.Mui-disabled': {
          background: '#F4F4F4',
        },
      },

      '& .select__control': {
        minHeight: 42,
        borderRadius: 6,
        marginBottom: 29,
        fontWeight: 400,
        fontSize: 14,
        color: '#272929',
        borderColor: '#D4D4D4',
        letterSpacing: '-0.006em',
        fontFamily: fonts.INTER,
      },

      '& .select__indicator-separator, & .select__clear-indicator': {
        display: 'none',
      },

      '& .select__multi-value': {
        background: '#63A0F2',
        borderRadius: 6,
      },

      '& .select__multi-value__label': {
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#FFFFFF',
      },

      '& .select__multi-value__remove': {
        color: '#fff',

        '&:hover': {
          color: '#fff',
          backgroundColor: 'transparent',
        },
      },

      '& .select__menu': {
        overflow: 'hidden',
        margin: 0,
        borderRadius: '0px 0px 6px 6px',
        background: '#ffffff',
        zIndex: 999999,
      },

      '& .select__option': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 'normal',
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#525454',
        fontFamily: fonts.INTER,

        '&:hover': {
          background: '#F4F4F4',
          cursor: 'pointer',
        },
      },

      '& .select__option--is-selected': {
        background: '#F4F4F4',
      },

      '& .select__control--menu-is-open:hover': {
        borderColor: colors.WHITE_SEVEN,
      },

      '& .select__control--is-focused': {
        boxShadow: 'none',
      },

      '& .select__menu-list': {
        border: '1px solid #d4d4d4',
        borderTop: 0,
        borderRadius: '0px 0px 6px 6px',
        padding: 0,
      },

      '& .select__dropdown-indicator': {
        '& svg': {
          width: 16,
          color: '#7D7F7F',
        },
      },

      '& .inputCaptionLight': {
        fontSize: 12,
        fontWeight: 400,
        marginBottom: 6,
        color: '#A9A9A9',
        fontFamily: fonts.INTER,
      },

      '& .MuiTypography-h4': {
        color: '#272929',
        fontSize: 24,
        fontWeight: 600,
        marginBottom: 4,
        letterSpacing: '-0.019em',
        lineHeight: '32px',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .MuiTypography-body1': {
        fontSize: 18,
        fontWeight: 600,
        color: '#A9A9A9',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.014em',
      },

      '& .MuiButton-root': {
        height: 40,
        fontSize: 14,
        minWidth: 148,
        fontWeight: 600,
        borderRadius: 12,
        letterSpacing: '-0.006em',
        textTransform: 'capitalize',
        fontFamily: fonts.POPPINS_FONT,
        backgroundColor: colors.GREEN_EIGHT,
      },

      '& .dialogCOntent': {
        paddingRight: 40,

        [theme.breakpoints.down('xs')]: {
          paddingRight: 0,
        },
      },
    },
  })
);
