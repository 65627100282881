import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import fonts from '../../../common/fonts';
import colors from '../../../common/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noAccount: {
      fontSize: '18px',
      lineHeight: '23px',
      textAlign: 'center',
      textTransform: 'capitalize',
      color: `${colors.BLACK_THREE}`,
      fontFamily: `${fonts.POPPINS_FONT}`,
    },

    personalInfo: {
      '& .MuiTypography-body1': {
        fontSize: 14,
        minWidth: 181,
        fontWeight: 600,
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        color: colors.BLACK_TWELVE,
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .secondaryText': {
        fontSize: 14,
        fontWeight: 400,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
        color: colors.GRAY_CHECKBOX_BG,
      },

      '& ul': {
        margin: 0,
        padding: 0,
        listStyleType: 'none',

        '& li': {
          fontSize: 16,
          fontWeight: 400,
          marginBottom: 12,
          fontFamily: fonts.INTER,
          letterSpacing: '-0.011em',
          color: colors.GRAY_CHECKBOX_BG,
        }
      },

      '& .labelWidth': {
        minWidth: 211,
      }
    },

    extraActivities: {
      '& .MuiTypography-h5': {
        fontSize: 18,
        fontWeight: 600,
        marginBottom: 8,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.014em',
        color: colors.BLACK_TWELVE,
      },

      '& .MuiTypography-body1, & .MuiTypography-h6': {
        fontSize: 16,
        fontWeight: 400,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.011em',
      },

      '& .MuiTypography-h6': {
        marginBottom: 6,
        color: colors.GRAY_CHECKBOX_BG,
      },

      '& .MuiTypography-body1': {
        marginTop: 10,
        textTransform: 'initial',
        color: colors.GRAY_TAB_BORDER,
      },

      '& .MuiDivider-root': {
        margin: '30px 0px 24px',
        background: colors.WHITE_SEVEN,
      }
    }
  })
);
