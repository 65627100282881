import { FC, useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { Card, makeStyles, createStyles, Theme, Box } from '@material-ui/core';
import { Nav } from 'react-bootstrap';

import { AppContext } from '../contexts';

import ACCOUNT_ICON_1 from '../img/account-icon-1.svg';
import ACCOUNT_ICON_2 from '../img/account-icon-2.svg';
import ACCOUNT_ICON_3 from '../img/account-icon-3.svg';
import ACCOUNT_ICON_4 from '../img/account-icon-4.svg';
import ACCOUNT_ICON_5 from '../img/account-icon-5.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountMenuCard: {
      borderRadius: 15,
      background: '#FFFFFF',
      border: '1px solid #D4D4D4',
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

      '& .linkIconBadge': {
        width: 24,
        height: 24,
        padding: 4,
        marginRight: 18,
        background: '#0A2463',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.down('md')]: {
          marginRight: 10,
        },
      },

      '& a': {
        width: '100%',
        padding: '14px 24px',
        fontWeight: 400,
        fontSize: 16,
        color: '#272929',
        letterSpacing: '-0.011em',
        display: 'flex',
        alignItems: 'center',

        [theme.breakpoints.down('md')]: {
          padding: '14px 16px',
          fontSize: 14,
        },

        '&.active': {
          fontWeight: 600,
          background: '#F4F4F4',
        },
      },
    },
  })
);

const AccountMenu: FC = () => {
  const { user } = useContext(AppContext);
  const classes = useStyles();

  return (
    <Card className={classes.accountMenuCard}>
      <Nav>
        <NavLink to="/account/information">
          <Box className="linkIconBadge">
            <img src={ACCOUNT_ICON_1} alt="account" />
          </Box>
          Account Information
        </NavLink>

        <NavLink to="/account/profile">
          <Box className="linkIconBadge">
            <img src={ACCOUNT_ICON_2} alt="account" />
          </Box>
          My Profile
        </NavLink>

        <NavLink to="/account/change-password">
          <Box className="linkIconBadge">
            <img src={ACCOUNT_ICON_3} alt="account" />
          </Box>
          Login & Password
        </NavLink>

        {user?.isActive && (
          <NavLink to="/account/billing">
            <Box className="linkIconBadge">
              <img src={ACCOUNT_ICON_4} alt="account" />
            </Box>
            Billing
          </NavLink>
        )}


        <NavLink to="/account/connect-with-calendar">
          <Box className="linkIconBadge">
            <img src={ACCOUNT_ICON_5} alt="account" />
          </Box>
          Calendar
        </NavLink>
      </Nav>
    </Card>
  );
};

export default AccountMenu;
