import { useContext, useState, Fragment } from 'react';
import momentTz from 'moment-timezone';
import { Mutation } from 'react-apollo';
import {
  TableRow,
  TableCell,
  Typography,
  Grid,
  Avatar,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Hidden,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { useStyles } from './MyStudentsStyle';
import ConfirmationDialog from '../timeLogs/ConfirmationDialog';
import { AppContext } from '../../../contexts';

import DELETE_ICON from '../../../img/delete-meeting-icon.svg';

import { DELETE_BOOKING } from '../meetings/gql';
import {
  deleteMeetingQuery,
  deleteMeetingQueryVariables,
} from './__generated__/deleteMeetingQuery';
import { allAdvisorBookingsQuery_allAdvisorBookings_advisorBookings } from '../meetings/gql/__generated__/allAdvisorBookingsQuery';
import moment from 'moment';
import { bookingInfoStatuses } from '../../../constants';
import CancelMeetingModal from '../meetings/CancelMeetingModal';
import client from '../../../apollo';
import Toast, { Color } from '../../../components/Toast';

const MeetingTableRecord = ({
  item,
  email,
  refetch,
  status,
}: {
  item: allAdvisorBookingsQuery_allAdvisorBookings_advisorBookings | null;
  email: any;
  refetch: Function;
  status: string;
}) => {
  const classes = useStyles();
  const { user } = useContext(AppContext);
  const [openDeleteMeetingModal, setOpenDeleteMeetingModal] = useState(false);
  const [reason, setReason] = useState<string>('');
  const [otherReason, setOtherReason] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [
    selectedRow,
    setSelectedRow,
  ] = useState<allAdvisorBookingsQuery_allAdvisorBookings_advisorBookings | null>(
    null
  );
  const [openToast, setOpenToast] = useState(false);
  const [toast, setToast] = useState({ message: '' });
  const [type, setType] = useState<Color>('success');
  const handlePopUpNotification = (message: string, type: Color) => {
    setToast({ message });
    setOpenToast(true);
    setType(type);
  };
  const handleCancelMeeting = async (reason: String, other: string) => {
    try {
        setLoading(true);
        if (selectedRow?.id && selectedRow.AdvisorId) {
          await client.mutate({
            mutation: DELETE_BOOKING,
            variables: {
              eventId: selectedRow.id,
              AdvisorId: selectedRow.AdvisorId,
              reason: reason,
              ...(other && {
                otherReason: other,
              }),
            },
          });
          setOpenDeleteMeetingModal(false);
          setSelectedRow(null);
          setReason('');
          setOtherReason('')
          handlePopUpNotification('Meeting Canceled', 'success');
          refetch && refetch();
        }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const startDate = item?.startTime
    ? moment.unix(item?.startTime).format('ddd MMM DD, YYYY')
    : 'N/A';
  const startTime = item?.startTime
    ? moment.unix(item?.startTime).format('LT')
    : 'NA';
  if (!item)
    return (
      <TableRow>
        <TableCell colSpan={4} align="center" className="noRecordFound">
          No record found!
        </TableCell>
      </TableRow>
    );

  return (
    <>
      <Toast
        open={openToast}
        close={() => {
          setOpenToast(false);
        }}
        {...toast}
        autoHideDuration={3000}
        type={type}
      />
      <CancelMeetingModal
        open={openDeleteMeetingModal}
        onClose={() => {
          setOpenDeleteMeetingModal(!openDeleteMeetingModal);
          setReason("")
          setOtherReason("")
        }}
        title="Cancel Meeting"
        firstButtonText="Cancel Meeting"
        secondButtonText="Keep"
        handleCancelBtn={handleCancelMeeting}
        loading={loading}
        startTime={startTime}
        startDate={startDate}
        reason={reason}
        setReason={setReason}
        setOtherReason={setOtherReason}
        otherReason={otherReason}
      />
      <TableRow>
        <Hidden only={['sm', 'xs']}>
          <TableCell>
            <Box display="flex" alignItems="center">
              <AvatarGroup max={2}>
                {item?.allParticipants
                  ?.filter((user) => user?.email != email)
                  .map((participant, index) => (
                    <Avatar
                      key={`${index} avatar`}
                      src={participant?.pictureUrl || ''}
                      alt="advisors"
                    />
                  ))}
              </AvatarGroup>

              <Typography noWrap className="advisorName">
                {item?.allParticipants
                  ?.filter((user) => user?.email != email)
                  .map((participant, index) => (
                    <Fragment key={`desktop-${index}-name`}>
                      {participant?.firstName + ' '}
                      {participant?.lastName}
                      {item?.allParticipants &&
                        item?.allParticipants?.filter(
                          (user) => user?.email != email
                        )?.length -
                        1 !==
                        index
                        ? ', '
                        : ''}
                    </Fragment>
                  ))}
              </Typography>
            </Box>
          </TableCell>
          <TableCell>
            {item?.startTime
              ? momentTz(item?.startTime * 1000).format('ddd MMM DD, YYYY')
              : ''}
          </TableCell>
          <TableCell>
            {item?.startTime &&
              momentTz
                .parseZone(
                  item?.startTime && new Date(item?.startTime * 1000).toString()
                )
                .local()
                .format('h:mma ')}
            -
            {item?.endTime &&
              momentTz
                .parseZone(
                  item?.endTime && new Date(item?.endTime * 1000).toString()
                )
                .local()
                .format(' h:mma ')}
            {`${moment(item?.startTime * 1000)
              .tz(momentTz.tz.guess())
              .format('z')}`}
          </TableCell>
          <TableCell>
            {item?.zoomLink && item.status !== bookingInfoStatuses.DELETED ? (
              <a
                className="tableLinkText"
                href={item?.zoomLink || ''}
                target="blank"
              >
                Link
              </a>
            ) : (
              '--'
            )}
          </TableCell>
          <TableCell>
            <IconButton
              onClick={() => {
                setOpenDeleteMeetingModal(!openDeleteMeetingModal);
                setSelectedRow(item);
              }}
              className={
                item?.AdvisorId !== user?.id
                  ? classes.disabledBtn
                  : 'menu-button'
              }
              disabled={item?.AdvisorId !== user?.id}
            >
              <img src={DELETE_ICON} alt="toggle" />
            </IconButton>
          </TableCell>
        </Hidden>

        <Hidden only={['xl', 'lg', 'md']}>
          <Grid className={classes.deleteButtonParentRelative}>
            {user?.id === item.AdvisorId && status === bookingInfoStatuses.UPCOMING && (
              <Mutation<deleteMeetingQuery, deleteMeetingQueryVariables>
                mutation={DELETE_BOOKING}
              >
                {(deleteMeeting: Function, { loading }: any) => {
                  const handleDelete = async () => {
                    await deleteMeeting({
                      variables: {
                        eventId: item.id,
                        AdvisorId: item.AdvisorId,
                      },
                    });
                    refetch();
                  };
                  return (
                    <>
                      <ConfirmationDialog
                        open={openDeleteMeetingModal}
                        toggleOpen={() => setOpenDeleteMeetingModal(false)}
                        handleDelete={handleDelete}
                      />
                      <IconButton
                        onClick={(e) => {
                          setOpenDeleteMeetingModal(true);
                          e.stopPropagation();
                        }}
                        className={classes.deleteButtonPosition}
                      >
                        {loading ? (
                          <CircularProgress size={23} />
                        ) : (
                          <img src={DELETE_ICON} alt="delete" />
                        )}
                      </IconButton>
                    </>
                  );
                }}
              </Mutation>
            )}
            <Accordion
              className={classes.mobileTableAccordion}
              style={{ margin: 0 }}
            >
              <AccordionSummary
                expandIcon={false}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box display="flex" alignItems="center">
                    <AvatarGroup max={2}>
                      {item?.allParticipants
                        ?.filter((user) => user?.email != email)
                        .map((participant, index) => (
                          <Avatar
                            key={`sexond-${index}-avatar`}
                            src={participant?.pictureUrl || ''}
                            alt="advisors"
                          />
                        ))}
                    </AvatarGroup>
                    <Typography variant="h6">
                      {item?.allParticipants
                        ?.filter((user) => user?.email != email)
                        .map((participant, index) => (
                          <Fragment key={`mobile-${index}-names`}>
                            {participant?.firstName + ' '}
                            {participant?.lastName}

                            {item?.allParticipants &&
                              item?.allParticipants?.filter(
                                (user) => user?.email != email
                              )?.length -
                              1 !==
                              index
                              ? ', '
                              : ''}
                          </Fragment>
                        ))}
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails className="meetingsAccordion">
                <Box width="100%">
                  <Typography variant="body1">
                    {item?.startTime
                      ? momentTz(item?.startTime * 1000).format('MM/DD/YYYY')
                      : ''}
                  </Typography>

                  <Typography variant="body1">
                    {item?.startTime &&
                      momentTz
                        .parseZone(
                          item?.startTime &&
                          new Date(item?.startTime * 1000).toString()
                        )
                        .local()
                        .format('h:mma ')}
                    -
                    {item?.endTime &&
                      momentTz
                        .parseZone(
                          item?.endTime &&
                          new Date(item?.endTime * 1000).toString()
                        )
                        .local()
                        .format(' h:mma ')}
                    {`${moment(item?.startTime * 1000)
                      .tz(momentTz.tz.guess())
                      .format('z')}`}
                  </Typography>

                  <Box pt={1}>
                    <Typography variant="body1">
                      {item?.zoomLink && item.status !== bookingInfoStatuses.DELETED ? (
                        <a
                          className="tableLinkText tableLinkTextMobile"
                          href={item?.zoomLink || ''}
                          target="blank"
                        >
                          Link
                        </a>
                      ) : (
                        '--'
                      )}
                    </Typography>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Hidden>
      </TableRow>
    </>
  );
};

export default MeetingTableRecord;
