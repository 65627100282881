import { useState, useContext } from 'react';
import { sendBirdSelectors, withSendBird } from 'sendbird-uikit';
import {
  InputAdornment,
  IconButton,
  FormControl,
  OutlinedInput,
  Box,
} from '@material-ui/core';
import { Send as SendIcon } from '@material-ui/icons';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import { AppContext } from '../../../contexts';
import {
  UpdateMessagesExchangedCountMutation,
  UpdateMessagesExchangedCountMutationVariables,
} from './__generated__/UpdateMessagesExchangedCountMutation';
import { IsMentorStudentQuery } from './__generated__/IsMentorStudentQuery';

export const UPDATE_MESSAGES_EXCHANGED_COUNT = gql`
  mutation UpdateMessagesExchangedCountMutation(
    $studentEmail: String!
    $mentorEmail: String!
    $studentOnline: Boolean
    $mentorOnline: Boolean
  ) {
    updateMessagesExchangedCount(
      input: {
        studentEmail: $studentEmail
        mentorEmail: $mentorEmail
        studentOnline: $studentOnline
        mentorOnline: $mentorOnline
      }
    ) {
      status
    }
  }
`;

const IS_MENTOR_STUDENT_QUERY = gql`
  query IsMentorStudentQuery($studentEmail: String) {
    isMentorStudent(input: { studentEmail: $studentEmail }) {
      status
    }
  }
`;

function CustomizedMessageInput(props: any) {
  const { channel, sendUserMessage, sdk } = props;

  const { user } = useContext(AppContext);

  let studentEmail = '';

  const mentorEmail = user && (user.sendbirdId || user.email);

  const student = channel.members.filter(
    (member: any) => member.userId !== mentorEmail
  );

  if (student && student.length) {
    studentEmail = student[0].userId;
  }

  const [inputText, setInputText] = useState('');
  const isInputEmpty = inputText.length < 1;

  const handleChange = (event: any) => {
    setInputText(event.target.value);
  };

  const sendUserMessage_ = (updateMessagesExchangedCount: any) => {
    const params = new sdk.UserMessageParams();
    params.message = inputText;
    sendUserMessage(channel.url, params)
      .then(async (message: any) => {
        setInputText('');
        props.isNewMessageSend(true);

        if (channel && channel.members && channel.members.length === 2) {
          const mentorEmail = user && (user.sendbirdId || user.email);
          const student = channel.members.filter(
            (member: any) => member.userId !== mentorEmail
          );
          const studentEmail = student[0].userId;
          const studentOnline = student[0].connectionStatus === 'online';

          const firstMemberRole = channel.members[0].metaData.userRole;
          const secondMemberRole = channel.members[1].metaData.userRole;

          if (
            (firstMemberRole === 'Student' && secondMemberRole === 'Mentor') ||
            (firstMemberRole === 'Mentor' && secondMemberRole === 'Student')
          ) {
            await updateMessagesExchangedCount({
              variables: {
                studentEmail,
                mentorEmail,
                studentOnline: studentOnline,
                mentorOnline: true,
              },
            });
          }
        }
      })
      .catch((error: any) => {
        console.log(error.message);
      });
  };

  const handleSubmit = (event: any, updateMessagesExchangedCount: any) => {
    event.preventDefault();
    if (inputText) {
      sendUserMessage_(updateMessagesExchangedCount);
    }
  };

  return (
    <Query<IsMentorStudentQuery>
      query={IS_MENTOR_STUDENT_QUERY}
      variables={{ studentEmail }}
      fetchPolicy="network-only"
    >
      {({ data }) => {
        if (!data || !data.isMentorStudent) return null;

        const {
          isMentorStudent: { status },
        } = data;

        let allowChat = false;

        if (status) {
          allowChat = true;
        }

        if (channel.members.length > 2) {
          allowChat = true;
        }

        let bothmentors = false;

        if (channel.members.length === 2) {
          const firstMemberRole = channel.members[0].metaData.userRole;
          const secondMemberRole = channel.members[1].metaData.userRole;

          if (firstMemberRole === secondMemberRole) {
            bothmentors = true;
          }

          if (firstMemberRole === 'Admin' || secondMemberRole === 'Admin') {
            bothmentors = true;
          }
        }

        if (bothmentors) {
          allowChat = true;
        }

        let message =
          'You are no longer an advisor of this student so chat is not available.';

        if (channel.members.length === 1) {
          message = '';
        }

        return (
          <Mutation<
            UpdateMessagesExchangedCountMutation,
            UpdateMessagesExchangedCountMutationVariables
          >
            mutation={UPDATE_MESSAGES_EXCHANGED_COUNT}
          >
            {(updateMessagesExchangedCount: Function) => {
              return (
                <div className="customized-message-input">
                  {!allowChat && (
                    <Box fontSize={12} textAlign="center" paddingTop={2}>
                      {message}
                    </Box>
                  )}

                  {allowChat && user?.isActive && (
                    <form
                      onSubmit={(event: any) =>
                        handleSubmit(event, updateMessagesExchangedCount)
                      }
                    >
                      <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                          id="customized-message-input"
                          value={inputText}
                          onChange={handleChange}
                          labelWidth={0}
                          placeholder="Enter message"
                          onKeyPress={(event: any) => {
                            if (event.key === 'Enter') {
                              handleSubmit(event, updateMessagesExchangedCount);
                            }
                          }}
                          multiline
                          rowsMax="3"
                          endAdornment={
                            <InputAdornment position="end">
                              {isInputEmpty ? (
                                <div className="customized-message-input__file-container"></div>
                              ) : (
                                <IconButton type="submit">
                                  <SendIcon color={'primary'} />
                                </IconButton>
                              )}
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </form>
                  )}
                </div>
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
}

const mapStoreToProps = (store: any) => {
  const sendUserMessage = sendBirdSelectors.getSendUserMessage(store);
  const sdk = sendBirdSelectors.getSdk(store);
  return {
    sendUserMessage,
    sdk,
  };
};

export default withSendBird(CustomizedMessageInput, mapStoreToProps);
