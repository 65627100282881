import { useState, useMemo, useContext, useEffect } from 'react';

import {
  Avatar,
  Badge,
  Card,
  CardHeader,
  Typography,
  Box,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { AppContext } from '../../../contexts';
import { customizedHeaderStyles } from './styles';

const SmallAvatar = withStyles(() => ({
  root: {
    width: 22,
    height: 22,
    border: `2px solid white`,
  },
}))(Avatar);

const SENDBIRD_AVATAR = (
  <svg viewBox="0 0 56 56" width="36" radius="50%">
    <path fill="#A8A8A8" d="M0 0h56v56H0z"></path>
    <path
      fill="#FFF"
      fillOpacity="0.88"
      d="M34.667 31.333a8.334 8.334 0 018.325 7.972l.008.362V43a1.667 1.667 0 01-3.322.194L39.667 43v-3.333a5 5 0 00-4.707-4.992l-.293-.008H21.333a5 5 0 00-4.991 4.706l-.009.294V43a1.667 1.667 0 01-3.322.194L13 43v-3.333a8.333 8.333 0 017.972-8.326l.361-.008h13.334zm-6.667-20A8.333 8.333 0 1128 28a8.333 8.333 0 010-16.667zm0 3.334a5 5 0 100 10 5 5 0 000-10z"
    ></path>
  </svg>
);

const SENDBIRD_SMALL_AVATAR = (
  <svg viewBox="0 0 56 56" width="18" radius="50%">
    <path fill="#A8A8A8" d="M0 0h56v56H0z"></path>
    <path
      fill="#FFF"
      fillOpacity="0.88"
      d="M34.667 31.333a8.334 8.334 0 018.325 7.972l.008.362V43a1.667 1.667 0 01-3.322.194L39.667 43v-3.333a5 5 0 00-4.707-4.992l-.293-.008H21.333a5 5 0 00-4.991 4.706l-.009.294V43a1.667 1.667 0 01-3.322.194L13 43v-3.333a8.333 8.333 0 017.972-8.326l.361-.008h13.334zm-6.667-20A8.333 8.333 0 1128 28a8.333 8.333 0 010-16.667zm0 3.334a5 5 0 100 10 5 5 0 000-10z"
    ></path>
  </svg>
);

function CustomizedHeader(props: any) {
  const classes = customizedHeaderStyles();

  const { channel, userId, sdk } = props;
  const channelName = channel.name;

  const channelAvatar = useMemo(() => {
    const { members } = channel;
    const membersExcludingMe = members.filter(
      (member: any) => member.userId !== userId
    );
    const [firstMember, secondMember] = membersExcludingMe;

    const AVATAR = firstMember && firstMember.profileUrl;

    return (
      <Badge
        badgeContent={
          membersExcludingMe.length > 1 &&
          (secondMember && secondMember.profileUrl ? (
            <SmallAvatar
              alt="second member"
              src={secondMember && secondMember.profileUrl}
            />
          ) : (
            <Box overflow="hidden" borderRadius="50%">
              {SENDBIRD_SMALL_AVATAR}
            </Box>
          ))
        }
      >
        {AVATAR ? (
          <Avatar src={AVATAR} />
        ) : (
          <Box overflow="hidden" borderRadius="50%">
            {SENDBIRD_AVATAR}
          </Box>
        )}
      </Badge>
    );
  }, [userId, channel]);
  const channelTitle = useMemo(() => {
    const membersNamesExcludingMe = channel.members
      .filter((member: any) => member.userId !== userId)
      .map((member: any) => member.nickname || member.userId)
      .join(', ');
    return membersNamesExcludingMe.length < 30
      ? membersNamesExcludingMe.length === 0
        ? '(No members)'
        : membersNamesExcludingMe
      : `${membersNamesExcludingMe.slice(0, 30)}...`;
  }, [userId, channel, channelName]);

  const { user } = useContext(AppContext);
  const { unreadMessagesCount } = useContext(AppContext);
  let unreadCount = (user && user.unreadMessagesCount) || unreadMessagesCount;
  const [channelUnreadCount, setChannelUnreadCount] = useState(0);

  useEffect(() => {
    setChannelUnreadCount(channel.unreadMessageCount);
  }, [unreadCount, channel.unreadMessageCount, channel.url]);

  function markMessagesAsRead() {
    sdk.markAsReadWithChannelUrls([channel.url]);
  }

  return (
    <>
      <div className={classes.chatHeader}>
        <Card square>
          <CardHeader
            avatar={channelAvatar}
            title={<Typography>{channelTitle}</Typography>}
          />
        </Card>
      </div>
      {!!channelUnreadCount && (
        <div
          className={classes.messageNotification}
          onClick={() => markMessagesAsRead()}
        >
          {channelUnreadCount} new message(s)
        </div>
      )}
    </>
  );
}

export default CustomizedHeader;
