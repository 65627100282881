import { useContext, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import clsx from 'clsx';
import { Mutation } from 'react-apollo';
import { Formik, Form } from 'formik';
import gql from 'graphql-tag';
import * as Yup from 'yup';

import { useStylesAccount } from './accountStyle';

import { Tostcongigs } from './ProfilePage';
import Toast from '../../../components/Toast';
import { OptionType } from '../../../components/multiSelect';
import { AppContext } from '../../../contexts';
import { handleMutationError } from '../../../common/utility';

import {
  UpdateMentorAcceptedToColleges,
  UpdateMentorAcceptedToCollegesVariables,
} from './__generated__/UpdateMentorAcceptedToColleges';
import {
  updateAdvisorStudentsAcceptedCollegesMutation,
  updateAdvisorStudentsAcceptedCollegesMutationVariables,
} from './__generated__/updateAdvisorStudentsAcceptedCollegesMutation';
import SearchColleges from '../../../components/SearchColleges';
import { CurrentUserQuery_currentUser_colleges } from '../layouts/__generated__/CurrentUserQuery';

const UPDATE_MENTOR_ACCEPTED_TO_COLLEGES = gql`
  mutation UpdateMentorAcceptedToColleges($id: Int!, $MentorCollegeIds: [Int]) {
    updateMentor(input: { id: $id, MentorCollegeIds: $MentorCollegeIds }) {
      id
      colleges {
        name
        id
        styleConfig {
          bgDark
          bgLight
        }
      }
    }
  }
`;

const UPDATE_ADVISOR_STUDENT_ACCEPTED_COLLEGES = gql`
  mutation updateAdvisorStudentsAcceptedCollegesMutation($collegeIds: [Int!]!) {
    updateAdvisorStudentsAcceptedColleges(input: { collegeIds: $collegeIds }) {
      success
      message
    }
  }
`;

type mutationType<T> = T extends 'advisorAcceptedTo'
  ? UpdateMentorAcceptedToColleges
  : updateAdvisorStudentsAcceptedCollegesMutation;

type mutationVariables<T> = T extends 'advisorAcceptedTo'
  ? UpdateMentorAcceptedToCollegesVariables
  : updateAdvisorStudentsAcceptedCollegesMutationVariables;

const EditCollegesDialog = ({
  open,
  onClose,
  type,
  advStudentAcceptedColleges,
  refetch,
  idsDisabled,
}: {
  open: boolean;
  onClose: () => void;
  type: 'advisorStudentsAcceptedTo' | 'advisorAcceptedTo';
  advStudentAcceptedColleges?:
    | (CurrentUserQuery_currentUser_colleges | null)[]
    | null
    | undefined;
  refetch?: Function;
  idsDisabled?: number[] | null;
}) => {
  const classes = useStylesAccount();
  const { user, setUser } = useContext(AppContext);

  const [toast, setToast] = useState<Tostcongigs>({
    message: '',
    type: undefined,
  });
  const [openToast, setOpenToast] = useState(false);

  const FormSchema = Yup.object().shape({
    colleges: Yup.array(),
  });

  const handleClose = async (resetForm: Function) => {
    resetForm();
    onClose();
  };

  return (
    <>
      <Toast
        open={openToast}
        close={() => {
          setOpenToast(false);
        }}
        {...toast}
      />
      <Mutation<mutationType<typeof type>, mutationVariables<typeof type>>
        mutation={
          type === 'advisorAcceptedTo'
            ? UPDATE_MENTOR_ACCEPTED_TO_COLLEGES
            : UPDATE_ADVISOR_STUDENT_ACCEPTED_COLLEGES
        }
      >
        {(updateMentor: Function) => {
          return (
            <Formik
              initialValues={{
                id: user?.id,
                colleges:
                  type === 'advisorAcceptedTo'
                    ? user?.colleges
                    : advStudentAcceptedColleges,
              }}
              enableReinitialize
              validationSchema={FormSchema}
              onSubmit={async (variables, { setSubmitting }) => {
                try {
                  delete variables?.colleges;
                  const { data } = await updateMentor({
                    variables: {
                      ...variables,
                    },
                  });

                  if (type === 'advisorAcceptedTo')
                    setUser({
                      ...user,
                      ...data?.updateMentor,
                    });
                  refetch && (await refetch());
                  setToast({
                    message: 'User Updated!',
                    type: 'success',
                  });
                } catch (error: any) {
                  let errorMessage = 'Changes not saved!';

                  if (error?.message) {
                    handleMutationError(error, ({ message }) => {
                      if (message) errorMessage = message;
                    });
                  }
                  setToast({
                    message: errorMessage,
                    type: 'error',
                  });
                } finally {
                  setSubmitting(false);
                  setOpenToast(true);
                  onClose();
                }
              }}
            >
              {({
                isSubmitting,
                values,
                handleSubmit,
                errors,
                resetForm,
                setFieldValue,
                dirty,
              }) => {
                const isError = !!Object.keys(errors).length;
                return (
                  <Form>
                    <Dialog
                      open={open}
                      onClose={() => handleClose(resetForm)}
                      className={clsx(
                        classes.educationDialog,
                        classes.advisedCollegesDialog
                      )}
                    >
                      <DialogContent>
                        <Typography variant="h4">
                          {type === 'advisorAcceptedTo'
                            ? 'I, myself, got accepted into schools like...'
                            : 'I’ve advised students who got into schools like...'}
                        </Typography>
                        {type === 'advisorAcceptedTo' && (
                          <Typography variant="body2" className="">
                            Add any of your graduate or undergraduate
                            acceptances.
                          </Typography>
                        )}

                        <Grid className={classes.accountInformationForm}>
                          <Box className="genderDropdown timeZoneSelect acceptedToDropdown fieldsBoxSpacing maxHeight">
                            <SearchColleges
                              isMulti
                              label=""
                              placeholder="Accepted To"
                              idsDisabled={idsDisabled || []}
                              values={values?.colleges?.map((c) => ({
                                label: c?.name || '',
                                value: c?.id || '',
                                disabled: idsDisabled?.includes(c?.id || 0),
                              }))}
                              onSelect={(colleges: [OptionType]) => {
                                const MentorCollegeIds = colleges
                                  ? colleges.map((college) =>
                                      parseInt(college.value)
                                    )
                                  : [];
                                setFieldValue(
                                  type === 'advisorAcceptedTo'
                                    ? 'MentorCollegeIds'
                                    : 'collegeIds',
                                  MentorCollegeIds
                                );
                                setFieldValue(
                                  'colleges',
                                  colleges.map((c) => ({
                                    id: parseInt(c.value) || '',
                                    name: c.label || '',
                                  }))
                                );
                              }}
                            />
                            {errors.colleges && (
                              <Typography className="errorText">
                                {errors.colleges}
                              </Typography>
                            )}
                          </Box>
                        </Grid>

                        <Box
                          pt={2.5}
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <Button
                            variant="outlined"
                            onClick={() => handleClose(resetForm)}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            disabled={isSubmitting || isError || !dirty}
                            onClick={() => handleSubmit()}
                          >
                            {isSubmitting ? 'Saving...' : 'Save'}
                          </Button>
                        </Box>
                      </DialogContent>
                    </Dialog>
                  </Form>
                );
              }}
            </Formik>
          );
        }}
      </Mutation>
    </>
  );
};

export default EditCollegesDialog;
