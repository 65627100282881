import { useEffect, useState } from 'react';

import clsx from 'clsx';

import { Query } from 'react-apollo';

import {
  Grid,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableFooter,
  Box,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

import AddStudentNoteDialog from './AddStudentNoteDialog';
import StudentNotesTableRecord from './StudentNotesTableRecord';
import ConfirmationModal from '../../../../components/ConfirmationModal';

import client from '../../../../apollo';
import { NOTES_CATEGORIES } from '../../../../constants';
import { GET_STUDENT_NOTES } from '../../../../graphql/queries/StudentNotes';
import {
  GetStudentNotes,
  GetStudentNotesVariables,
} from '../../../../graphql/queries/StudentNotes/__generated__/GetStudentNotes';
import { DELETE_STUDENT_NOTE } from '../../../../graphql/mutations/StudentNotes';

import { useStyles } from './StudentNotesStyle';
import { useStylesTable } from '../../students/MyStudentsStyle';

import PLUS_ICON from '../../../../img/plus-btn.svg';

interface fetchResult {
  fetchMoreResult: GetStudentNotes | null;
}

const StudentNotes = ({ StudentId }: { StudentId: number }) => {
  const classes = useStyles();
  const tableClasses = useStylesTable();

  const limit = 10;

  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [noteDeletingLoading, setNoteDeletingLoading] = useState(false);
  const [editNoteId, setEditNoteId] = useState<number | null>(null);
  const [deleteNoteId, setDeleteNoteId] = useState<number | null>(null);
  const [editNoteDescription, setEditDescription] = useState('');
  const [page, setPage] = useState(0);
  const [noteType, setNoteType] = useState<string>();

  useEffect(() => {
    setPage(0);
  }, [noteType]);

  const getData = (page: number, fetchMore: Function) => {
    fetchMore({
      variables: {
        page: page + 1,
      },
      updateQuery: (prev: GetStudentNotes, next: fetchResult) => {
        if (!next) return prev;
        const { fetchMoreResult } = next;
        if (!fetchMoreResult?.studentNotes?.notes) return prev;
        return fetchMoreResult;
      },
    });

    setPage(page);
  };

  const handleOpenAddNoteDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseAddNoteDialog = () => {
    setOpenDialog(false);
    setEditDescription('');
    setEditNoteId(null);
  };

  const handleCloseDeleteNoteDialog = () => {
    setOpenDeleteDialog(false);
    setDeleteNoteId(null);
  };

  const handleEditNote = (NoteId: number, description: string) => {
    setEditNoteId(NoteId);
    setEditDescription(description);
    handleOpenAddNoteDialog();
  };

  const handleOnDeleteClick = (NoteId?: number) => {
    if (NoteId) {
      setDeleteNoteId(NoteId);
      setOpenDeleteDialog(true);
    }
  };

  const onNoteDelete = async (refetch: Function) => {
    try {
      setNoteDeletingLoading(true);
      await client.mutate({
        mutation: DELETE_STUDENT_NOTE,
        variables: {
          NoteId: deleteNoteId,
        },
      });
      await refetch();
    } catch (error) {
      console.log('Error :::', error);
    } finally {
      setNoteDeletingLoading(false);
      handleCloseDeleteNoteDialog();
    }
  };
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pb={2}
        flexWrap="wrap"
      >
        <Grid
          className={clsx(
            tableClasses.forDropdownArrow,
            tableClasses.filterBySelect
          )}
        >
          <Typography variant="body1" className="fieldCaption">
            Filter By
          </Typography>
          <Select
            onChange={(e) => {
              const value = e.target.value as string;
              value === 'Filter By' ? setNoteType('') : setNoteType(value);
            }}
            displayEmpty
            variant="outlined"
            value={noteType || 'Filter By'}
            inputProps={{ 'aria-label': 'Without label' }}
            style={{ color: noteType ? '#272929' : '#A9A9A9' }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
          >
            <MenuItem value={'Filter By'} className="disableText">
              Note Type
            </MenuItem>
            {NOTES_CATEGORIES?.map((note) => (
              <MenuItem
                style={{
                  display: note.name === 'Filter By' ? 'none' : 'block',
                }}
                value={`${note.value}`}
              >
                {note.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Button
          variant="contained"
          onClick={handleOpenAddNoteDialog}
          className={`${classes.addNoteBtn} mainBtn`}
          startIcon={<img src={PLUS_ICON} alt="add" width={'10px'} />}
        >
          Add Note
        </Button>
      </Box>

      <Grid container>
        <Box width="100%" className={classes.notesTableContainer}>
          <Table className={classes.notesTable}>
            <TableHead>
              <TableRow>
                <TableCell align="left" className="firstCellPadding">
                  Last Updated
                </TableCell>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">Description</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>

            <Query<GetStudentNotes, GetStudentNotesVariables>
              query={GET_STUDENT_NOTES}
              variables={{ StudentId, limit, page: page + 1, type: noteType }}
              fetchPolicy="network-only"
            >
              {({ data, loading, fetchMore, refetch }) => {
                return (
                  <>
                    <AddStudentNoteDialog
                      StudentId={StudentId}
                      openDialog={openDialog}
                      handleClose={handleCloseAddNoteDialog}
                      refetch={refetch}
                      NoteId={editNoteId}
                      description={editNoteDescription}
                    />
                    <ConfirmationModal
                      open={openDeleteDialog}
                      onClose={handleCloseDeleteNoteDialog}
                      title="Delete Note?"
                      firstButtonText="Delete"
                      secondButtonText="Keep Note"
                      handleCancelBtn={() => {
                        onNoteDelete(refetch);
                      }}
                      loading={noteDeletingLoading}
                    />

                    {!data?.studentNotes?.notes?.length || loading ? (
                      <TableBody>
                        <TableRow>
                          <TableCell className="noRecord" colSpan={4}>
                            {loading ? 'Loading...' : 'No Notes Added.'}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody>
                        {data?.studentNotes?.notes?.map((item) => (
                          <StudentNotesTableRecord
                            key={item?.id}
                            noteData={item}
                            onEdit={handleEditNote}
                            onDelete={handleOnDeleteClick}
                          />
                        ))}
                      </TableBody>
                    )}

                    {!!data?.studentNotes?.notes?.length && (
                      <TableFooter>
                        <TableRow>
                          <TableCell colSpan={7}>
                            <TablePagination
                              rowsPerPageOptions={[2]}
                              component="div"
                              count={data?.studentNotes.count || 0}
                              rowsPerPage={limit}
                              page={page}
                              onChangePage={(e, page) => {
                                getData(page, fetchMore);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    )}
                  </>
                );
              }}
            </Query>
          </Table>
        </Box>
      </Grid>
    </>
  );
};

export default StudentNotes;
