import clsx from 'clsx';
import { useContext, useEffect } from 'react';

import { Box, Grid, Typography, Card } from '@material-ui/core';
import moment from 'moment-timezone';
import momentTz from 'moment-timezone';

import { AppContext } from '../../contexts';
import { AdvisorRecordTablesProps } from '../../common/interfaces';

import { useStyles } from '../../pages/main/students/MyStudentsStyle';

import { PersonsIcon } from '../../pages/main/students/assets/svg';

export const AdvisorRecordTables: React.FC<AdvisorRecordTablesProps> = ({
  data,
  selected,
  setSelected,
  refetch,
  setFieldValue,
  onClose,
  setNoOfficialMeet,
  activityPerformed,
}) => {
  const { user } = useContext(AppContext);

  useEffect(() => {
    refetch();
  }, []);

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    setSelected(id);
  };

  const classes = useStyles();

  return (
    <>
      <Grid
        className={clsx(classes.commonTable, classes.onlineListMeetingTable)}
      >
        <Grid className="meetingScroll">
          {user &&
          data?.advisorBookings?.filter(
            (meeting) => !meeting?.HoursLoggedBy?.includes(user?.id)
          )?.length ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className="linkMeetingHeader"
            >
              <Box className="headingBox headingBoxFirst">DATE</Box>
              <Box className="headingBox headingBoxSecond">START TIME</Box>
              <Box className="headingBox headingBoxThird">
                EXPECTED DURATION
              </Box>
            </Box>
          ) : (
            ''
          )}
          {user &&
            data?.advisorBookings
              ?.filter((meeting) => !meeting?.HoursLoggedBy?.includes(user?.id))
              ?.map((row) => {
                if (!row) return null;
                const meetingDuration = (row?.endTime - row?.startTime) / 60;
                const isItemSelected = row?.id === selected;
                const currentTime = moment.unix(row?.startTime);
                const formattedDate = currentTime.format('ddd, MMM D, YYYY');
                const formattedTime = `${currentTime.format('h:mma')} (${moment(
                  row?.startTime * 1000
                )
                  .tz(momentTz.tz.guess())
                  .format('z')})`;

                const meetingAdvisors =
                  row?.allParticipants?.filter(
                    (participant) =>
                      participant?.roles?.includes('Mentor') &&
                      participant.id !== user.id
                  ) || [];

                return (
                  <Box
                    tabIndex={-1}
                    key={row.id}
                    className={
                      isItemSelected
                        ? 'selectedMeeting selectedMeetingActive selectedMeetingHover'
                        : 'selectedMeeting selectedMeetingHover'
                    }
                    onClick={(event) => {
                      setFieldValue('actualMeetingTime', meetingDuration);
                      handleClick(event, row.id);
                      setFieldValue('activityAt', `${new Date(formattedDate)}`);
                      if (row.canceledReason === 'no-show') {
                        setFieldValue('activityDuration', 15);
                        setFieldValue('missCall', true);
                      } else {
                        setFieldValue(
                          'activityDuration',
                          meetingDuration || 60
                        );
                        setFieldValue('missCall', false);
                      }
                      setFieldValue('otherMeetingDescription', '');
                      setFieldValue('noteForExtendedMeeting', '');
                      setFieldValue('noteForNoOrientationCall', '');
                      setNoOfficialMeet(false);
                      if (
                        activityPerformed ===
                          'Video Chat Session, 2+ Advisors' ||
                        activityPerformed ===
                          'Orientation/Kick-Off Call, 2+ Advisors'
                      ) {
                        const otherAdvisors = meetingAdvisors?.map(
                          (advisor) => {
                            return {
                              label: `${advisor?.firstName || ''} ${
                                advisor?.lastName || ''
                              }\n(${advisor?.email})`,
                              value: `${advisor?.id}`,
                            };
                          }
                        );
                        if (otherAdvisors?.length) {
                          setFieldValue('otherAdvisorIds', otherAdvisors);
                        }
                      }
                      onClose();
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      className="meetingTextStyle"
                    >
                      <Box className="minutesText headingBoxFirst">
                        <Box className="dateText">{formattedDate}</Box>

                        <Box
                          className="descriptionText"
                          mt={row.canceledReason === 'no-show' ? 1 : 0}
                        >
                          {row.canceledReason === 'no-show'
                            ? '*Student No-Show (15 minutes)'
                            : ''}
                        </Box>
                      </Box>

                      <Box
                        style={{ whiteSpace: 'nowrap' }}
                        className="headingBoxSecond"
                      >
                        {formattedTime}
                      </Box>

                      <Box className="headingBoxThird">
                        {`${meetingDuration}min${
                          row.canceledReason === 'no-show' ? '*' : ''
                        }`}
                      </Box>
                    </Box>

                    {row.note && (
                      <Typography
                        variant="body1"
                        className="descriptionText"
                        style={{ paddingTop: 5 }}
                      >
                        {`Pre-meeting note: “${row.note}”`}
                      </Typography>
                    )}

                    {meetingAdvisors?.length ? (
                      <Typography
                        variant="body1"
                        className="personsText descriptionText"
                      >
                        <PersonsIcon />
                        w/{' '}
                        {meetingAdvisors.map((advisor, index) => (
                          <>
                            {index === meetingAdvisors.length - 1
                              ? `${advisor?.firstName} ${advisor?.lastName}`
                              : `${advisor?.firstName} ${advisor?.lastName}, `}
                          </>
                        ))}
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Box>
                );
              })}
        </Grid>
      </Grid>

      {user &&
      !data?.advisorBookings?.filter(
        (meeting) => !meeting?.HoursLoggedBy?.includes(user?.id)
      )?.length ? (
        <Card className={classes.noPastMeetingsCard}>
          <Typography variant="body1" className="descriptionText textSpacing">
            No past meetings found for this student.
          </Typography>
          <Typography variant="body1" className="descriptionText">
            <a
              onClick={() => {
                setFieldValue('activityAt', null);
                setNoOfficialMeet(true);
                setSelected(0);
                setFieldValue('missCall', false);
                onClose();
              }}
            >
              Click here
            </a>{' '}
            to proceed without linking meeting.{' '}
          </Typography>
        </Card>
      ) : (
        <Typography
          onClick={() => {
            setFieldValue('activityAt', null);
            setNoOfficialMeet(true);
            setSelected(0);
            setFieldValue('missCall', false);
            setFieldValue('activityDuration', '');
            onClose();
          }}
          variant="body1"
          className={classes.meetingNotScheduled}
        >
          Click here if your meeting was not scheduled on the CollegeAdvisor
          portal.
        </Typography>
      )}
    </>
  );
};
