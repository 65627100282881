import { SyntheticEvent } from 'react';
import { Snackbar, makeStyles, createStyles } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import fonts from '../../../common/fonts';

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles(() =>
  createStyles({
    timeSlotSnackbar: {
      '& .MuiAlert-filledSuccess': {
        width: 228,
        height: 56,
        background: '#FFF8E4',
        borderRadius: 15,
        boxShadow: 'none',
        fontFamily: fonts.INTER,
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
      },

      '& .MuiAlert-icon, & .MuiAlert-action': {
        display: 'none',
      },

      '& .MuiAlert-message': {
        padding: 0,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  })
);

export type Color = 'success' | 'info' | 'warning' | 'error';

const TimeSlotNotification = ({
  open,
  close,
  message = 'Success!',
  type = 'success',
  autoHideDuration = 6000,
}: {
  open: boolean;
  close: Function;
  message?: string;
  autoHideDuration?: number;
  type?: Color;
}) => {
  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    close(false);
  };
  const classes = useStyles();

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
      className={classes.timeSlotSnackbar}
    >
      <Alert onClose={handleClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default TimeSlotNotification;
