import { useState } from 'react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import {
  Box,
  Button,
  ListItem,
  Popover,
  ListItemText,
  CircularProgress,
  Typography,
  ListItemSecondaryAction,
  Avatar,
  Divider,
  List,
  ListItemAvatar,
  Grid,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import Caution_Img from '../../../img/caution-meeting.svg';

import { getBookingsForTimeSlotQuery_getBookingsForTimeSlot_bookings } from './gql/__generated__/getBookingsForTimeSlotQuery';
import { useStyles } from './styles';

const MeetingsConflictPopover = ({
  showMeetingsPopOver,
  anchorEl,
  meetingsToBeDeleted,
  onClose,
  onDelete,
  openPopover,
}: {
  showMeetingsPopOver: Boolean;
  anchorEl: HTMLButtonElement | HTMLLIElement | null;
  meetingsToBeDeleted:
    | (getBookingsForTimeSlotQuery_getBookingsForTimeSlot_bookings | null)[]
    | null;
  onClose: Function;
  onDelete: Function;
  openPopover: boolean;
}) => {
  const classes = useStyles();
  const [wait, setWait] = useState(false);

  const handleDelete = () => {
    onDelete();
    setWait(true);
  };

  return (
    <Popover
      id="delete-popover"
      open={openPopover}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {showMeetingsPopOver && (
        <Box className={classes.popOverBoxListing}>
          {meetingsToBeDeleted?.length ? (
            <>
              <Typography variant="h5">
                <img src={Caution_Img} alt="caution" />
                MEETING CONFLICT
              </Typography>
              <Typography variant="body1">
                You have {meetingsToBeDeleted?.length} meetings booked within
                this series.
              </Typography>
              <Divider />
              <List>
                {meetingsToBeDeleted.map((item) => {
                  const startDate = item?.startTime
                    ? moment.unix(item?.startTime).format('MM/DD/YYYY')
                    : 'N/A';
                  const startTime = item?.startTime
                    ? moment.unix(item?.startTime).format('LT')
                    : 'NA';
                  const endTime = item?.endTime
                    ? moment.unix(item?.endTime).format('LT')
                    : 'NA';

                  return (
                    <ListItem>
                      <ListItemAvatar>
                        {item?.student?.pictureUrl ? (
                          <Avatar>
                            <img src={item?.student?.pictureUrl} alt="avatar" />
                          </Avatar>
                        ) : (
                          <PersonIcon />
                        )}
                      </ListItemAvatar>
                      <ListItemText>
                        {item?.student?.firstName || ''}{' '}
                        {item?.student?.lastName || ''}
                      </ListItemText>
                      <ListItemSecondaryAction>
                        <ListItemText>
                          {`${startDate}, ${startTime} - ${endTime} ${momentTz(
                            (item?.startTime || 0) * 1000
                          )
                            .tz(momentTz.tz.guess())
                            .format('z')}`}
                        </ListItemText>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
              <Divider />
            </>
          ) : (
            <Grid className={classes.confirmMessage}>
              <Typography variant="h5">CONFIRM MEETING DELETE?</Typography>
              <Typography variant="body1">
                This action cannot be undone.
              </Typography>
            </Grid>
          )}

          <Box textAlign="right">
            <Button variant="outlined" onClick={() => onClose()}>
              Keep All
            </Button>

            <Button variant="contained" disabled={wait} onClick={handleDelete}>
              {!wait && `Update Hours & Cancel Meetings`}
              {wait && (
                <>
                  {'Updating...'}
                  <CircularProgress color="secondary" size={17} />
                </>
              )}
            </Button>
          </Box>
        </Box>
      )}
    </Popover>
  );
};
export default MeetingsConflictPopover;
