import { FC, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Box,
  Divider,
  Hidden,
} from '@material-ui/core';
import ApplicationsEssayProgressTable from './ApplicationsEssayProgressTable';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  allStudentApplicationsQueryWithEssayProgress_getStudentApplicationWithEssaysProgress_completed,
  allStudentApplicationsQueryWithEssayProgress_getStudentApplicationWithEssaysProgress_inProgress,
  allStudentApplicationsQueryWithEssayProgress_getStudentApplicationWithEssaysProgress_NotStarted,
  allStudentApplicationsQueryWithEssayProgress_getStudentApplicationWithEssaysProgress_reviewReady,
  allStudentApplicationsQueryWithEssayProgress_getStudentApplicationWithEssaysProgress_stuck,
} from './__generated__/allStudentApplicationsQueryWithEssayProgress';
import { useStylesApplication } from './applicationStyle';

interface ApplicationEssaysProgressProps {
  stuck:
    | (allStudentApplicationsQueryWithEssayProgress_getStudentApplicationWithEssaysProgress_stuck | null)[]
    | null;
  reviewReady:
    | (allStudentApplicationsQueryWithEssayProgress_getStudentApplicationWithEssaysProgress_reviewReady | null)[]
    | null;
  inProgress:
    | (allStudentApplicationsQueryWithEssayProgress_getStudentApplicationWithEssaysProgress_inProgress | null)[]
    | null;
  NotStarted:
    | (allStudentApplicationsQueryWithEssayProgress_getStudentApplicationWithEssaysProgress_NotStarted | null)[]
    | null;
  completed:
    | (allStudentApplicationsQueryWithEssayProgress_getStudentApplicationWithEssaysProgress_completed | null)[]
    | null;
}
interface EssaysWithProgressProps {
  essayName: string;
  essays:
    | (allStudentApplicationsQueryWithEssayProgress_getStudentApplicationWithEssaysProgress_stuck | null)[]
    | null;
  status: string;
  accordionClassName: string;
  open?: boolean;
}
const EssaysWithProgress: FC<EssaysWithProgressProps> = ({
  essayName,
  essays,
  open,
  status,
  accordionClassName,
}) => {
  const applicationClasses = useStylesApplication();
  const [expand, setExpand] = useState(open);
  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };
  return (
    <Accordion expanded={expand}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={`accordionCollapseText ${accordionClassName}`}
        IconButtonProps={{
          onClick: toggleAcordion,
        }}
      >
        <Typography variant="h6">
          {essayName}({essays?.length})
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {essays && essays?.length > 0 && (
          <Hidden only={['xs', 'sm']}>
            <Grid>
              <Box
                className={applicationClasses.applicationTableAccordion}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box width="25%">&nbsp;</Box>
                <Box width="35%">School</Box>
                <Box width="15%">Word Max</Box>
                <Box width="15%">Due Date</Box>
                <Box width="10%">&nbsp;</Box>
              </Box>
            </Grid>
          </Hidden>
        )}
        <ApplicationsEssayProgressTable AppEssays={essays} status={status} />
      </AccordionDetails>
    </Accordion>
  );
};

const ApplicationEssaysProgress: FC<ApplicationEssaysProgressProps> = ({
  stuck,
  reviewReady,
  completed,
  inProgress,
  NotStarted,
}) => {
  const applicationClasses = useStylesApplication();
  return (
    <Grid
      className={`${applicationClasses.applicationAccordions} ${applicationClasses.moveAccordionApplications}`}
    >
      <EssaysWithProgress
        essayName="Stuck"
        essays={stuck}
        open
        status="stuck"
        accordionClassName="accordionStuck"
      />

      <Divider className="accordionDivider" />

      <EssaysWithProgress
        essayName="Review Ready"
        essays={reviewReady}
        open
        status="reviewReady"
        accordionClassName="accordionReview"
      />

      <Divider className="accordionDivider" />

      <EssaysWithProgress
        essayName="In Progress"
        essays={inProgress}
        open
        status="In Progress"
        accordionClassName="accordionInProgress"
      />

      <Divider className="accordionDivider" />
      <EssaysWithProgress
        essayName="Not Started"
        essays={NotStarted}
        status="notStarted"
        accordionClassName="accordionInNotStarted"
      />

      <Divider className="accordionDivider" />
      <EssaysWithProgress
        essayName="Completed"
        essays={completed}
        status="complete"
        accordionClassName="accordionCompleted"
      />

      <Divider className="accordionDivider" />
    </Grid>
  );
};
export default ApplicationEssaysProgress;
