import { FC, useContext } from 'react';
import { Box, Typography, Avatar } from '@material-ui/core';
import moment from 'moment';

import Email_icon from '../../../img/email-icon.svg';
import Chat_Icon from '../../../img/chat-icon.svg';

import { useStyles } from './MyStudentsStyle';
import { AppContext } from '../../../contexts';

interface AdvisorProps {
  id: Number | null | undefined;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  pictureUrl: string | null | undefined;
  college: string | null | undefined;
  email: string | null | undefined;
  matchedAt: string | null | undefined;
  graduatedAt: string | null | undefined;
  sendBirdChannelUrl: string | null | undefined;
  sendBirdId: string | null | undefined;
  advisorLevel: string;
}

const Advisors: FC<AdvisorProps> = (props) => {
  const classes = useStyles();
  const {
    id,
    firstName,
    lastName,
    pictureUrl,
    college,
    email,
    matchedAt,
    graduatedAt,
    sendBirdChannelUrl,
    sendBirdId,
    advisorLevel,
  } = props;
  const appContext = useContext(AppContext);

  const user = appContext && appContext.user;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      className={classes.studentAdvisors}
    >
      <Box display="flex">
        <Avatar src={`${pictureUrl}`} />

        <Box>
          <Typography
            variant="h6"
            noWrap
          >{`${firstName} ${lastName}`}</Typography>
          <Typography variant="body2" noWrap>{`${advisorLevel}`}</Typography>
          <Typography variant="body1">
            {college}
            {graduatedAt && ', ' + moment(graduatedAt).format('YYYY')}
          </Typography>
          <Typography variant="body1" noWrap className="dateText">
            Matched on{' '}
            {moment.parseZone(matchedAt).local().format('MMM DD, YYYY')}
          </Typography>
        </Box>
      </Box>

      {id !== user?.id && (
        <Box display="flex" flexDirection="column" className="mobileEmailChat">
          <a href={`mailto:${email}`}>
            <img src={Email_icon} alt="email" />
            EMAIL
          </a>

          <a href={`/chat/${sendBirdChannelUrl}/${sendBirdId}`}>
            <img src={Chat_Icon} alt="chat" />
            CHAT
          </a>
        </Box>
      )}
    </Box>
  );
};

export default Advisors;
