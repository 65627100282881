import { useState, useEffect, ChangeEvent, FC } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import {
  Button,
  Box,
  IconButton,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  CircularProgress,
  InputBase,
} from '@material-ui/core';

import client from '../../../../apollo';

import { useStyles } from './BookingMeetingStyle';
import { convertToUserSpecificTimeslots } from './helper';

import {
  BOOK_TIMESLOT,
  FETCH_ADVISOR_AVAILABILITY_TIMESLOTS,
  GET_ALL_STUDENT_MENTORS,
} from './gql';
import {
  AdvisorAvailableTimeslots_advisorAvailableTimeslots_time_slots,
  AdvisorAvailableTimeslots,
} from './gql/__generated__/AdvisorAvailableTimeslots';
import { getAllStudentMentors_allStudentMentors } from './gql/__generated__/getAllStudentMentors';

import Close_Icon from '../../../../img/close-dialog.svg';
import { allAdvisorBookingsQuery_allAdvisorBookings_advisorBookings } from '../../meetings/gql/__generated__/allAdvisorBookingsQuery';
import { StudentDetailQuery_Student_allStudentMentors_mentor } from '../__generated__/StudentDetailQuery';
import AdvisorsSelection from './AdvisorsSelection';

import PREVIOUS_PAGE_ICON from '../../../../img/back-arrow-icon.svg';
import { IBoookingStudent } from './AdvisorBookingModal';
import LoaderVariant2 from '../../../../components/LoaderVariant2';
import Toast from '../../../../components/Toast';
import { Tostcongigs } from '../../account/ProfilePage';
import { returnRequestID } from '../../../../common/utility';

const MEETING_LENGTHS = [
  {
    id: 'minutes-15',
    value: '15',
  },
  {
    id: 'minutes-30',
    value: '30',
  },
  {
    id: 'minutes-45',
    value: '45',
  },
  {
    id: 'minutes-60',
    value: '60',
  },
];
interface Timeslot {
  start_time: number;
  end_time: number;
}

interface Props extends RouteComponentProps {
  updateBookingDetails?: allAdvisorBookingsQuery_allAdvisorBookings_advisorBookings | null;
  openCalendar: boolean;
  setOpenCalendar: Function;
  setOpenMeetingInfo: Function;
  advisorId: number;
  setMeetingInfo: Function;
  student: IBoookingStudent;
}

const BookMeetings: FC<Props> = ({
  openCalendar,
  setOpenCalendar,
  setOpenMeetingInfo,
  advisorId,
  setMeetingInfo,
  updateBookingDetails,
  history,
  student,
}) => {
  const classes = useStyles();
  const searchParam = new URLSearchParams(window.location.search);
  const noKickOffMeeting =
    !student?.initialKickOffDate &&
    !student?.firstMeetingBookedDate;
  const [selectedDay, setSelectedDay] = useState<null | Date>(new Date());
  const [duration, setDuration] = useState(noKickOffMeeting ? 30 : 15);
  const [selectedTimeslot, setSelectedTimeslot] = useState<Timeslot | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [isLoadingFirstTime, setIsLoadingFirstTime] = useState(true);
  const [meetingLoading, setMeetingLoading] = useState(false);
  const [timeStamp, setTimeStamp] = useState<number | null>(null);
  const [selectedAdvisors, setSelectedAdvisors] = useState<
    (StudentDetailQuery_Student_allStudentMentors_mentor | null | undefined)[]
  >([]);
  const [allowAdvisorSelection, setAllowAdvisorSelection] = useState(false);
  const [timeslots, setTimeslots] = useState<
    | (AdvisorAvailableTimeslots_advisorAvailableTimeslots_time_slots | null)[]
    | null
  >([]);
  const [month, setMonth] = useState<Date | null>(null);
  const [daysWithTimeSLots, setDaysWithTimeSlots] = useState<Date[] | null>(
    null
  );
  const [mainAdvisor, setMainAdvisor] = useState(advisorId);
  const [note, setNote] = useState('');
  const [showAdvisors, setShowAdvisors] = useState(true);

  const [openToast, setOpenToast] = useState(false);
  const [toast, setToast] = useState<Tostcongigs>({
    message: '',
    type: undefined,
  });
  const [allStudentMentors, setAllStudentMentors] = useState<
    (getAllStudentMentors_allStudentMentors | null)[] | null
  >(null);

  const filterTimeSlot =
    timeslots?.filter((t) => {
      if (
        t?.start &&
        moment(selectedDay).format('MM-DD-YYYY') ===
          moment(t.start * 1000).format('MM-DD-YYYY') &&
        moment(t.start * 1000).isAfter(moment(new Date()))
      )
        return true;
    }) || [];

  const handleNotification = (
    message: string,
    type: 'success' | 'info' | 'warning' | 'error'
  ) => {
    setToast({
      message,
      type,
    });
    setOpenToast(true);
  };

  const handleClose = () => {
    if (searchParam.get('adv1')) history.replace('/home');
    setOpenCalendar(false);
    setAllowAdvisorSelection(false);
    setSelectedDay(new Date());
    setSelectedTimeslot(null);
    setDaysWithTimeSlots(null);
    setTimeslots(null);
    setMonth(null);
    setDuration(15);
    setShowAdvisors(true);
    setNote('');
    setIsLoadingFirstTime(true);
  };

  const handleDurationChange = ({
    currentTarget,
  }: ChangeEvent<HTMLInputElement>) => {
    setSelectedTimeslot(null);
    setDuration(+currentTarget.value);
  };

  useEffect(() => {
    const fetchAllStudentMentors = async () => {
      if (!student?.allStudentMentors) {
        const { data } = await client.query({
          query: GET_ALL_STUDENT_MENTORS,
          fetchPolicy: 'network-only',
          variables: {
            id: student?.id,
          },
        });
        if (data && data.allStudentMentors)
          setAllStudentMentors(data.allStudentMentors);
      } else {
        setAllStudentMentors(student?.allStudentMentors);
      }
    };
    fetchAllStudentMentors();
  }, []);

  useEffect(() => {
    const advisorClicked = allStudentMentors
      ?.filter((stdAdv) => stdAdv?.mentor?.id === advisorId)
      .map((item) => item?.mentor);
    if (advisorClicked?.length) {
      setSelectedAdvisors([...advisorClicked]);
      setMainAdvisor(advisorClicked?.[0]?.id || advisorId);
    }
    setDuration(noKickOffMeeting ? 30 : 15);
  }, [openCalendar, allStudentMentors]);

  const goNextMonthIfLastDay = () => {
    const currentDate = month || new Date();
    const lastDayOfMonth = moment().endOf('month').toDate();

    if (currentDate.getDate() === lastDayOfMonth.getDate()) {
      setSelectedDay(moment().add(1, 'days').toDate());
      setMonth(moment().add(1, 'days').toDate());
    }
  };

  const availableDays = () => {
    const getAvailableTimeSLotDays = async () => {
      const ts = returnRequestID();
      try {
        if (
          openCalendar &&
          ((selectedAdvisors.length && isLoadingFirstTime) ||
            !isLoadingFirstTime)
        ) {
          setDaysWithTimeSlots(null);
          setLoading(true);
          setTimeslots(null);
          setTimeStamp(ts);
          let date = new Date(new Date().setDate(2));
          if (month) {
            date = moment(month).add(24, 'hours').toDate();
          }
          const { data }: { data: AdvisorAvailableTimeslots } =
            await client.query({
              query: FETCH_ADVISOR_AVAILABILITY_TIMESLOTS,
              fetchPolicy: 'network-only',
              variables: {
                AdvisorIds: selectedAdvisors.map((x) => x?.id),
                duration: duration,
                date: moment(date).format('MM-DD-YYYY'),
                offset: moment().utcOffset() / 60,
                monthOrDay: 'month',
                studentCurrentTime: Date.now(),
                isUserInDSTtimeZone: moment([2011, 2, 14]).isDST(),
              },
            });

          let shouldUpdate = true;

          setTimeStamp((prev: number | null) => {
            if (ts !== prev) {
              shouldUpdate = false;
            }
            return prev;
          });
          if (shouldUpdate) {
            if (data.advisorAvailableTimeslots?.time_slots) {
              const days = convertToUserSpecificTimeslots(
                _.cloneDeep(data.advisorAvailableTimeslots?.time_slots)
              );
              setDaysWithTimeSlots(days);
              if (days.length) setSelectedDay(days[0]);
              setTimeslots(data?.advisorAvailableTimeslots?.time_slots);
            }
            isLoadingFirstTime && setIsLoadingFirstTime(false);
          }
        }
      } catch (error) {
        console.log('Error while fetching getAvailableTimeSLotDays:', error);
      } finally {
        let shouldUpdate = true;

        setTimeStamp((prev: number | null) => {
          if (ts !== prev) {
            shouldUpdate = false;
          }
          return prev;
        });
        if (shouldUpdate) {
          setLoading(false);
        }
      }
    };
    getAvailableTimeSLotDays();
  };

  useEffect(availableDays, [month, duration, selectedAdvisors]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const advisorIds: number[] = [];
      searchParam.forEach(function (value, key) {
        if (key.includes('adv')) {
          advisorIds.push(+value);
        }
      });
      if (advisorIds.length) {
        const filtereMentors =
          allStudentMentors
            ?.filter((item) => {
              if (item?.mentor?.id)
                return advisorIds.includes(item?.mentor?.id);
            })
            .map((m) => m?.mentor) || [];
        setMainAdvisor(advisorIds[0]);
        setSelectedAdvisors([...filtereMentors]);
      }
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (!daysWithTimeSLots?.length && !loading && !isLoadingFirstTime)
      goNextMonthIfLastDay();
  }, [daysWithTimeSLots, isLoadingFirstTime, loading]);

  const handleConfirm = async () => {
    try {
      setMeetingLoading(true);
      const participants = selectedAdvisors.map((item) => ({
        name: `${item?.firstName} ${item?.lastName}`,
        email: item?.email || '',
        id: item?.id,
        role: 'Mentor',
      }));
      const _selectedAvdsWithAvatar = selectedAdvisors.map((item) => ({
        name: `${item?.firstName} ${item?.lastName}`,
        email: item?.email || '',
        id: item?.id,
        avatar: item?.pictureUrl,
      }));

      const { data } = await client.mutate({
        mutation: BOOK_TIMESLOT,
        variables: {
          AdvisorId: mainAdvisor,
          start_time: selectedTimeslot?.start_time,
          end_time: selectedTimeslot?.end_time,
          note: note || null,
          participants: [
            ...participants,
            {
              name: `${student?.firstName} ${student?.lastName}`,
              email: student?.email,
              id: student?.id,
              role: 'Student',
            },
          ],
        },
      });

      handleClose();
      if (!data?.createNylasCalendarEvent?.success) {
        handleNotification(
          data?.createNylasCalendarEvent?.message ||
            'Something went wrong while creating meeting!',
          'error'
        );
      } else {
        setMeetingInfo({
          start_time: selectedTimeslot?.start_time,
          end_time: selectedTimeslot?.end_time,
          advisors: _selectedAvdsWithAvatar,
          studentName: `${student?.firstName} ${student?.lastName}`,
          note: note || 'N/A',
        });
        setOpenMeetingInfo(true);
      }
    } catch (error: any) {
      handleNotification(
        'Something went wrong while creating meeting!',
        'error'
      );
    } finally {
      setMeetingLoading(false);
    }
  };

  return (
    <>
      <Toast
        open={openToast}
        close={() => {
          setOpenToast(false);
        }}
        {...toast}
      />

      <Dialog
        open={openCalendar}
        onClose={handleClose}
        maxWidth="md"
        className={classes.bookMeetingDialog}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.dialogPaperHeader}
        >
          <Box display="flex" alignItems="center">
            <Box marginRight={'10px'}>
              <Typography variant="h4">
                {updateBookingDetails &&
                Object.keys(updateBookingDetails)?.length
                  ? 'Reschedule Meeting'
                  : 'Book Meeting'}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6">
                {`(${student?.firstName || ''} ${student?.lastName || ''})`}
              </Typography>
            </Box>
          </Box>

          <IconButton aria-label="close" onClick={handleClose}>
            <img src={Close_Icon} alt="close" />
          </IconButton>
        </Box>

        <DialogContent>
          {showAdvisors ? (
            <>
              <AdvisorsSelection
                advisorId={advisorId}
                allowAdvisorSelection={allowAdvisorSelection}
                selectedAdvisors={selectedAdvisors}
                setSelectedAdvisors={setSelectedAdvisors}
                setMainAdvisor={setMainAdvisor}
                setSelectedTimeslot={setSelectedTimeslot}
                setAllowAdvisorSelection={setAllowAdvisorSelection}
                allStudentMentors={allStudentMentors}
                noKickOffMeeting={noKickOffMeeting}
              />
              <Grid className={classes.meetingRadioContainer}>
                <Box pb={3}>
                  <Typography variant="h5">Length of Meeting</Typography>
                </Box>

                <Grid className={classes.radioGroup}>
                  {MEETING_LENGTHS.map((item) => {
                    const isDisaled = noKickOffMeeting && item.value === '15';
                    return (
                      <Grid className={classes.radioGroupGrid} key={item.id}>
                        <input
                          disabled={isDisaled}
                          type="radio"
                          name="duration"
                          defaultChecked={duration === parseInt(item.value)}
                          onChange={handleDurationChange}
                          id={item.id}
                          value={item.value}
                        />
                        <label
                          htmlFor={item.id}
                          className={isDisaled ? classes.disabledLabel : ''}
                        >
                          {item.value} minutes
                        </label>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>

              <Box className={classes.datePickerWrapper}>
                {isLoadingFirstTime ? (
                  <LoaderVariant2
                    loaderText="Loading availabilities…"
                    isSmallLoader={true}
                  />
                ) : (
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} className="MuiDatePicker">
                      <Box pb={2.5}>
                        <Typography variant="h5">
                          Select Date and Time Slot
                        </Typography>
                      </Box>

                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          autoOk
                          disablePast
                          shouldDisableDate={(x) => {
                            const _daysWithTimeSlots =
                              !!daysWithTimeSLots?.length
                                ? daysWithTimeSLots?.map((v) =>
                                    moment(v).format('MM-DD-YYYY')
                                  )
                                : [];

                            if (
                              x &&
                              x.getMonth() ===
                                new Date(month || new Date()).getMonth()
                            ) {
                              if (
                                _daysWithTimeSlots.includes(
                                  moment(x).format('MM-DD-YYYY')
                                )
                              ) {
                                return false;
                              } else {
                                return true;
                              }
                            } else {
                              return true;
                            }
                          }}
                          fullWidth
                          variant="static"
                          openTo="date"
                          value={selectedDay}
                          onMonthChange={(e) => setMonth(e)}
                          disableToolbar={true}
                          onChange={(e) => {
                            if (e) setSelectedDay(e);
                            setSelectedTimeslot(null);
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid
                      className={classes.availableTimeSlots}
                      item
                      md={6}
                      xs={12}
                    >
                      <Typography variant="h5">Available Time Slots</Typography>
                      <Typography variant="caption">
                        All Times in{' '}
                        {momentTz.tz(momentTz.tz.guess()).format('z')}
                      </Typography>

                      <Box className="availableTimeScroll">
                        <Grid
                          className={clsx(
                            classes.radioGroup,
                            classes.radioNotFlex
                          )}
                        >
                          {loading ? (
                            <Box
                              display="flex"
                              height="100%"
                              flexDirection="column"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <CircularProgress color="secondary" size={24} />
                            </Box>
                          ) : !!timeslots?.length && !!filterTimeSlot.length ? (
                            filterTimeSlot?.map((timeslot) => {
                              return (
                                <Grid
                                  key={timeslot?.start}
                                  className={clsx(
                                    classes.radioGroupGrid,
                                    classes.radioGroupSlots
                                  )}
                                >
                                  <input
                                    type="radio"
                                    name="selectedDuration"
                                    id={`radio-${timeslot?.start}`}
                                    value={timeslot?.start}
                                    checked={
                                      timeslot?.start ===
                                        selectedTimeslot?.start_time &&
                                      timeslot?.end ===
                                        selectedTimeslot?.end_time
                                    }
                                    onChange={({
                                      currentTarget,
                                    }: ChangeEvent<HTMLInputElement>) => {
                                      if (timeslot?.start && timeslot?.end) {
                                        setSelectedTimeslot({
                                          start_time: timeslot?.start,
                                          end_time: timeslot?.end,
                                        });
                                      }
                                    }}
                                  />
                                  <label htmlFor={`radio-${timeslot?.start}`}>
                                    {timeslot?.start
                                      ? moment
                                          .unix(timeslot?.start)
                                          .format('LT')
                                      : '--'}
                                  </label>
                                </Grid>
                              );
                            })
                          ) : (
                            <Box
                              display="flex"
                              height="100%"
                              flexDirection="column"
                              alignItems="center"
                              justifyContent="center"
                            >
                              No time slot found.
                            </Box>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </>
          ) : (
            <Box className={classes.addAdvisor}>
              <Box onClick={() => setShowAdvisors(true)} padding="0px 0px 10px">
                <img
                  src={PREVIOUS_PAGE_ICON}
                  alt="back"
                  style={{ cursor: 'pointer' }}
                />
              </Box>
              <Grid container className={classes.meetingNoteArea}>
                <Grid>
                  <Typography variant="h5" className="dialogHeading">
                    Is there anything else you'd like to discuss during the
                    meeting?
                  </Typography>
                  <InputBase
                    placeholder="Optional"
                    multiline
                    value={note}
                    rows={4}
                    onChange={(e) => {
                      setNote(e?.target?.value);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>

        <DialogActions
          className={
            !showAdvisors
              ? classes.dialogActionText
              : classes.advisorScreenBottom
          }
        >
          {showAdvisors ? (
            <Button
              onClick={() => {
                setShowAdvisors(false);
              }}
              color="secondary"
              variant="contained"
              className="buttonNext"
              disabled={
                !selectedTimeslot ||
                !Object.keys(selectedTimeslot)?.length ||
                !selectedAdvisors ||
                !selectedAdvisors?.length
              }
            >
              Next
            </Button>
          ) : (
            <>
              <Box className="advisorTextGrid">
                <Typography variant="h5" className="headingText" noWrap>
                  Meeting with{' '}
                  {selectedAdvisors
                    .map(
                      (adv) => `${adv?.firstName || ''} ${adv?.lastName || ''}`
                    )
                    .join(' and ')}
                </Typography>
                <Typography variant="body1" className="subtitleText" noWrap>
                  {selectedTimeslot?.start_time && selectedTimeslot?.end_time
                    ? `${moment(selectedTimeslot.start_time * 1000).format(
                        'ddd MMMM DD, YYYY, h:mm'
                      )} - 
                      ${moment(selectedTimeslot.end_time * 1000).format('LT')}
                      
                      ${momentTz(selectedTimeslot.start_time * 1000)
                        .tz(momentTz.tz.guess())
                        .format('z')}`
                    : 'N/A'}
                </Typography>
              </Box>
              <Button
                onClick={() => {
                  handleConfirm();
                }}
                color="primary"
                variant="contained"
                disabled={
                  !selectedTimeslot ||
                  !Object.keys(selectedTimeslot)?.length ||
                  !selectedAdvisors ||
                  !selectedAdvisors?.length ||
                  meetingLoading
                }
              >
                {updateBookingDetails &&
                Object.keys(updateBookingDetails)?.length
                  ? 'Update Meeting'
                  : 'Confirm Meeting'}
                {meetingLoading && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
export default withRouter(BookMeetings);
