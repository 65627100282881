import clsx from 'clsx';

import * as Yup from 'yup';
import Select, { OptionTypeBase, ValueType } from 'react-select';
import moment from 'moment-timezone';
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  FormikProps,
  FormikValues,
} from 'formik';
import { ApolloConsumer, Query } from 'react-apollo';

import { useState, useContext, useEffect, ChangeEvent } from 'react';

import {
  Box,
  Grid,
  Card,
  Button,
  Divider,
  Checkbox,
  MenuItem,
  Typography,
  FormHelperText,
  FormControlLabel,
  Select as MaterialUISelect,
  OutlinedInput,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import {
  mileStoneOptions as initialMileStonOptions,
  mileStoneOptionsForProjectWork,
  workActivityForStudentAdvising,
  workActivityForAdvisorOperations,
  timeLogAllWorkActivityOptions,
} from '../static';
import {
  GET_STUDENT_TRIAL,
  UPDATE_MENTOR_TIMELOG,
  CREATE_MENTOR_TIMELOG,
  ALL_MENTOR_STUDENTS_QUERY,
  GET_STUDENT_ENTITLEMENTS_STAT,
  ALL_COLLEGE_APPLICATIONS,
  CREATE_STUDENT_APPLICATION_MUTATION,
  ORIENTATION_CALL_COUNT,
} from '../gql';
import SelectTeam from '../SelectTeam';
import client from '../../../../apollo';
import { AppContext } from '../../../../contexts';
import { fomateDateUS } from '../../../../common/utility';
import { AllMentorStudentsQuery } from '../gql/__generated__/AllMentorStudentsQuery';
import { StudentEntitlementsQuery } from '../gql/__generated__/StudentEntitlementsQuery';
import { pendingMentorTimeLogQuery_mentorPendingTimeLogs_pendingTimeLogs } from '../gql/__generated__/pendingMentorTimeLogQuery';
import { getMentorPaymentCategories_mentorPaymentCategories } from '../gql/__generated__/getMentorPaymentCategories';

import {
  setWorkTypeForMilestoneTeamValues,
  setWorkTypeValue,
} from '../RadioField';
import {
  BACK_IC,
  useStyles,
  ActivityNotesTip,
} from '../style/NewTimeLogPageStyle';
import CustomSearchSelect from '../CustomSearchSelect';
import NativeTimeTextField from '../NativeTimeTextField';
import { TextField, NumberInput } from '../../../../components/fields';
import StudentVideoChatHours from './StudentVideoChatHours';
import StudentFeedbackDialog from '../StudentFeedbackDialog';
import { OptionType } from '../../../../components/multiSelect';
import AdvisorSelect from '../../../../components/AdvisorSelect';
import { LinkOnlineMeetingDialog } from '../../../../components/time-logs/LinkOnlineMeetingDialog';

import fonts from '../../../../common/fonts';

import Info_Blue_Img from '../../../../img/blue-info.svg';
import Danger_Img from '../../../../img/danger-error.svg';
import CautionIcon from '../../../../img/caution-icon.svg';
import Warning_Img from '../../../../img/warning-error.svg';
import Stripe_Token_Img from '../../../../img/unverified-token.svg';
import CheckedLightIcon from '../../../../img/checked-light-icon.svg';
import HintIcon from '../../../../img/hint.svg';
import { OptionStudentApplication } from '../../../../common/interfaces';
import { MENTOR_TIMELOG_ASSOCIATION_TYPES } from '../../../../constants';
import OtherApplications from './OtherApplications';
import { calculatePercentageAndColorsToChose } from '../helper';
import { useHistory } from 'react-router-dom';
import AddIcon from '../../../../img/plus-circle.svg';
import { GET_ALL_MILESTONE_TEAMS } from '../../../../graphql/queries/milestoneTeams';
import Loader from '../../../../components/Loader';
import colors from '../../../../common/colors';

const TimeLogForm = ({
  onBoarded,
  editId,
  workType,
  TeamName,
  backClick,
  onSubmit,
  studentValue,
  setStudentValue,
  paymentCategories,
  setCollegeName,
  setInitialValues,
  setTeamName,
  setExpectedPayment,
  initialValues,
  GroupId,
  pendingTimelogs,
  beginPendingTimelog,
  setAdvisorsNames,
  advisorsNames,
  setGroupId,
  isSubmittingPendingTimelog,
}: {
  onBoarded: boolean;
  advisorsNames: string[];
  GroupId: string;
  editId: string | undefined;
  workType: string;
  TeamName: string;
  backClick: Function;
  onSubmit: Function;
  setStudentValue: Function;
  setCollegeName: Function;
  setInitialValues: Function;
  setTeamName: Function;
  setExpectedPayment: Function;
  initialValues: any;
  studentValue: any;
  paymentCategories:
    | (getMentorPaymentCategories_mentorPaymentCategories | null)[]
    | null;
  pendingTimelogs:
    | (pendingMentorTimeLogQuery_mentorPendingTimeLogs_pendingTimeLogs | null)[]
    | undefined;
  beginPendingTimelog: Function;
  setAdvisorsNames: Function;
  setGroupId: Function;
  isSubmittingPendingTimelog: boolean;
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { user } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [isEntitlementsUsedChecked, setIsEntitlementsUsedChecked] =
    useState<boolean>(false);
  const [userTrialData, setUserTrialData] = useState<any>(null);
  const [feedBackNotSubmitted, setFeedBackNotSubmitted] = useState(true);
  const [paymentIdforOthers, setPaymentIdforOthers] = useState<number>();
  const [_studentId, setStudentId] = useState<number | null>(
    initialValues.StudentId
  );
  const [amount, setAmount] = useState<number>();
  const [isLateLog, setIsLateLog] = useState(false);
  const [isDuplicateOrientationCall, setIsDuplicateOrientationCall] =
    useState(false);
  const [showStudentApplications, setShowStudentApplications] = useState(false);
  const [allStudentApplications, setStudentApplications] = useState<any>([]);
  const [isAddNewApplication, setIsAddNewApplication] = useState(false);
  const [selectedAdvisorBookingId, setSelectedAdvisorBookingId] =
    useState<number>(initialValues.meetingId || 0);
  const [studentApplicationIds, setStudentApplicationIds] = useState<string[]>(
    []
  );
  const [milestoneTeamsOptions, setMilestoneTeamsOptions] = useState<
    { value: number; label: string }[]
  >([]);
  const [UCSubCampusApplications, setUCSubCampusApplications] = useState<any>(
    []
  );
  const [openDialogue, setOpenDialogue] = useState<boolean>(false);
  const [noOfficialMeet, setNoOfficialMeet] = useState<boolean>(false);
  const [prevoiusActivityPerformed, setPrevoiusActivityPerformed] =
    useState('');
  const [entitlementTypes, setEntitlementTypes] = useState<OptionType[]>([]);
  const [percentageValue, setPercentageValue] = useState<number | null>(null);
  const [mentorStudentIds, setMentorStudentIds] =
    useState<(number | undefined)[]>();
  const [fromOnlineChat, setFromOnlineChat] = useState<boolean>(false);
  const [mileStoneOptions, setMileStoneOptions] = useState(
    initialMileStonOptions
  );

  let collegesToExcludeIds: (number | undefined)[] | undefined = [];
  const minNoteLength = 50;

  const minLengthTest = function (
    this: Yup.TestContext,
    value: string | undefined
  ) {
    const length = value?.length || 0;
    const remaining = minNoteLength - length;
    if (remaining > 0) {
      return this.createError({
        message: `${remaining} characters remaining.`,
        path: this.path,
      });
    }
    return true;
  };
  const checkDuplicateOrientationCall = async (StudentId: number) => {
    if (StudentId) {
      try {
        const response = await client.query({
          query: ORIENTATION_CALL_COUNT,
          variables: { StudentId },
          fetchPolicy: 'no-cache',
        });

        if (response.data.orientationCallCount?.count >= 1) {
          setIsDuplicateOrientationCall(true);
        } else {
          setIsDuplicateOrientationCall(false);
        }
      } catch (error: any) {
        console.log('error:::', error.message);
      }
    }
  };
  const NewFormSchema = Yup.object().shape({
    workType: Yup.string().required('Work type must be selected.').nullable(),
    StudentId: Yup.number()
      .nullable()
      .when('workType', {
        is: (type: string) =>
          ['Student Advising', 'Speciality Team', 'Dress Rehearsal'].includes(
            type
          ),
        then: Yup.number().required('Student is required.').nullable(),
      }),
    activityDuration: Yup.number()
      .nullable()
      .when('workActivity', {
        is: (activity: string) =>
          activity !== 'Onboarding' &&
          activity !== 'Dress Rehearsal' &&
          workType !== 'Dress Rehearsal',
        then: Yup.number()
          .moreThan(0)
          .lessThan(201, 'Duration must not exceed 200 min')
          .required('Time is required.'),
      }),

    workActivity: Yup.string()
      .nullable()
      .when('workType', {
        is: (type: string) =>
          ['Student Advising', 'Advisor Operations'].includes(type),
        then: Yup.string().required('Work activity must be selected.'),
      }),
    milestoneTeam: Yup.string()
      .nullable()
      .when('workType', {
        is: (type: string) => ['Milestone Teams'].includes(type),
        then: Yup.string().required('Milestone Team must be selected.'),
      }),
    entitlementType: Yup.string()
      .nullable()
      .when('workType', {
        is: (type: string) => ['Milestone Teams'].includes(type),
        then: Yup.string().required('Entitlement Types must be selected.'),
      }),
    milestone: Yup.string()
      .nullable()
      .when(['workType', 'workActivity', 'missCall'], {
        is: (type: string, activity: string, missCall: boolean) =>
          !noOfficialMeet &&
          ['Student Advising'].includes(type) &&
          activity !== 'Meeting with other Advisor/AO' &&
          activity !== 'Partner - Leadership Initiatives' &&
          !missCall,
        then: Yup.string().nullable().required('Milestone must be selected.'),
      }),

    activityDescription: Yup.string(),
    activityAt: Yup.date().nullable().required('Date is required.'),
    PaymentCategoryId: Yup.number()
      .nullable()
      .when(['workType', 'workActivity'], {
        is: (type: string, activity: string) =>
          !user?.isFullTime &&
          ['Student Advising', 'Advisor Operations'].includes(type) &&
          activity !== 'Partner - Leadership Initiatives',
        then: Yup.number()
          .required('Payment Category must be selected, Please contact admin.')
          .typeError(
            'Payment Category must be selected, Please contact admin.'
          ),
      }),

    note: Yup.string().when('workType', {
      is: (workType: string) => workType !== 'Dress Rehearsal',
      then: Yup.string().required('Note must be added.'),
    }),
    lateLogReason: Yup.string()
      .nullable()
      .test('lateLogReason-validation', function (value) {
        const { createError } = this;

        if (isLateLog && !value) {
          return createError({ path: this.path, message: 'Required' });
        }
        if (isLateLog && value) {
          let remaining = 50 - (value.length ?? 0);
          if (remaining > 0) {
            return createError({
              path: this.path,
              message: `${remaining} characters remaining.`,
            });
          }
        }
        return true;
      }),
    duplicateOrientationCallReason: Yup.string()
      .nullable()
      .test('duplicateOrientationCallReason-validation', function (value) {
        const { createError } = this;

        if (isDuplicateOrientationCall && !value) {
          return createError({ path: this.path, message: 'Required' });
        }
        if (isDuplicateOrientationCall && value) {
          let remaining = 50 - (value.length ?? 0);
          if (remaining > 0) {
            return createError({
              path: this.path,
              message: `${remaining} characters remaining.`,
            });
          }
        }
        return true; // Validation passed
      }),
    collegeId: Yup.number().nullable(),
    teamId: Yup.number()
      .nullable()
      .when('workType', {
        is: (type: string) => ['Speciality Team'].includes(type),
        then: Yup.number().required('Team is Required.'),
      }),

    durationNote: Yup.string()
      .nullable()
      .when('activityDuration', {
        is: (duration: number) => duration > 90 && duration <= 200,
        then: Yup.string()
          .required('Required')
          .test('min-length', minLengthTest)
          .min(minNoteLength),
      }),
    missCall: Yup.boolean().nullable(),
    airtableSubmitted: Yup.boolean().when('workType', {
      is: (type: string) => type === 'Dress Rehearsal',
      then: Yup.boolean().required('Required').oneOf([true], 'Required'),
    }),
    otherAdvisorIds: Yup.array()
      .nullable()
      .when('workActivity', {
        is: (activity: string) =>
          (activity === 'Video Chat Session, 2+ Advisors' ||
            activity === 'Orientation/Kick-Off Call, 2+ Advisors') &&
          !GroupId,
        then: Yup.array()
          .of(
            Yup.object().shape({
              label: Yup.string(),
              value: Yup.string(),
            })
          )
          .min(1),
      }),
    noteForExtendedMeeting: Yup.string()
      .nullable()
      .when(['actualMeetingTime', 'activityDuration', 'missCall'], {
        is: (meetingDuration: number, duration: number, missCall: boolean) =>
          ((duration >= meetingDuration + 5 && duration <= 90) ||
            duration <= meetingDuration - 15) &&
          selectedAdvisorBookingId &&
          !missCall,
        then: Yup.string()
          .required('Required')
          .test('min-length', minLengthTest)
          .min(minNoteLength),
      }),
    otherMeetingDescription: Yup.string()
      .nullable()
      .when('activityDuration', {
        is: (duration: number) => noOfficialMeet === true,
        then: Yup.string()
          .required('Required')
          .test('min-length', minLengthTest)
          .min(minNoteLength),
      }),
    noteForNoOrientationCall: Yup.string()
      .nullable()
      .when('workActivity', {
        is: (workActivity: string) =>
          !studentValue?.initialKickOffDate &&
          (workActivity === 'Video Chat Session, 2+ Advisors' ||
            workActivity === 'Video Chat Session, Solo'),
        then: Yup.string()
          .required('Required')
          .test('min-length', minLengthTest)
          .min(minNoteLength),
      }),
  });

  const expectedPayment = (paymentCategories: any, values: any) => {
    if (
      paymentCategories &&
      paymentCategories.length &&
      values.PaymentCategoryId
    ) {
      const res =
        paymentCategories.find(
          (item: any) => item.id === values.PaymentCategoryId
        ) || {};
      const { rate } = res;
      const exp_pay = ((values.activityDuration * rate) / 60).toFixed(2);
      setExpectedPayment(exp_pay);
      setAmount(parseInt(`${+exp_pay * 100}`));
      return exp_pay;
    } else if (workType !== 'Milestone Teams') {
      const { rate, id } = paymentCategories.find(
        (item: any) => item?.paymentCategory?.name === 'offline'
      );

      const exp_pay = ((values.activityDuration * rate) / 60).toFixed(2);
      setExpectedPayment(exp_pay);
      setPaymentIdforOthers(id);
      setAmount(parseInt(`${+exp_pay * 100}`));
      return exp_pay;
    } else {
      return `0`;
    }
  };
  const exp_payment_Onboarding = () => {
    setExpectedPayment(50);
    setAmount(50 * 100);
    return 50;
  };
  const handleSelectChange = (
    selectedOptions: ValueType<OptionTypeBase, true>,
    setFieldValue: Function,
    values: any
  ) => {
    const options = selectedOptions as OptionStudentApplication[];
    const ids = options.map((option) => option.value);
    setStudentApplicationIds(ids);
    if (!values.OtherApplicationsIds?.length) {
      setFieldValue('studentApplication', ids[0]);
    }
  };

  const getStudentTrial = async (studentId: number) => {
    if (studentId) {
      setStudentId(studentId);
    }
    try {
      const response = await client.query({
        query: GET_STUDENT_TRIAL,
        variables: { studentId },
      });
      if (response.data.getStudentTrial?.studentTrial?.trialStartedAt) {
        setUserTrialData(response.data.getStudentTrial);
      } else {
        setUserTrialData(null);
      }
    } catch (error: any) {
      console.log('error:::', error.message);
    }
  };

  const leadershipInitiativesOption = {
    value: 'Partner - Leadership Initiatives',
    label: 'Leadership Initiatives Meeting',
  };

  const loadCollegeApplicationsOptions = (inputValue: string) => {
    return new Promise<OptionType[]>((resolve) => {
      client
        .query({
          query: ALL_COLLEGE_APPLICATIONS,
          fetchPolicy: 'network-only',
          variables: {
            limit: 10,
            searchQuery: inputValue,
            collegesToExcludeIds: collegesToExcludeIds,
          },
        })
        .then((res: any) => {
          if (
            res.data &&
            res.data.allColleges &&
            res.data.allColleges.colleges.length
          ) {
            const options: OptionType[] = res.data.allColleges.colleges.map(
              (item: any) => ({
                value: item.id,
                label: `${item.name}`,
              })
            );
            resolve(options);
          } else {
            resolve([]);
          }
        });
    });
  };

  const getEntitlementTypes = (teamName: string) => {
    const mileStoneTeamsPaymentCategories = paymentCategories?.filter(
      (category) =>
        category?.paymentCategory?.name?.includes(teamName) && !!category?.rate
    );

    const entitlementTypesArray: OptionType[] = [];
    mileStoneTeamsPaymentCategories?.forEach((item) => {
      if (item?.paymentCategory?.name?.includes('Video')) {
        entitlementTypesArray.push({
          value: 'Video Chat',
          label: 'Video Chat',
        });
      } else if (item?.paymentCategory?.name?.includes('Offline Editing')) {
        entitlementTypesArray.push({
          value: 'Offline Editing',
          label: 'Offline Editing',
        });
      } else if (item?.paymentCategory?.name?.includes('Essay')) {
        entitlementTypesArray.push({
          value: 'Essay Editing',
          label: 'Essay Editing',
        });
      } else if (item?.paymentCategory?.name?.includes('Offline')) {
        entitlementTypesArray.push({
          value: 'Offline',
          label: 'Offline',
        });
      }
      if (
        item?.paymentCategory?.name?.includes('Essay Review Team, Undergrad')
      ) {
        entitlementTypesArray.push({
          value: 'Offline',
          label: 'Offline',
        });
      }
    });
    if (user?.isFullTime) {
      const entitlementTypeForFullTime = [];
      if (teamName?.includes('Essay Review Team')) {
        entitlementTypeForFullTime.push({
          value: 'Offline Editing',
          label: 'Offline Editing',
        });
      } else {
        entitlementTypeForFullTime.push(
          {
            value: 'Video Chat',
            label: 'Video Chat',
          },
          {
            value: 'Offline',
            label: 'Offline',
          }
        );
        if (teamName === 'Graduate Specialty Advisor') {
          entitlementTypeForFullTime.push({
            value: 'Essay Editing',
            label: 'Essay Editing',
          });
        }
      }
      if (teamName === 'Essay Review Team, Undergrad') {
        entitlementTypeForFullTime.push({
          value: 'Offline',
          label: 'Offline',
        });
      }
      setEntitlementTypes(entitlementTypeForFullTime);
    } else {
      setEntitlementTypes(entitlementTypesArray);
    }
  };

  const handleProjectWorkChange = (
    event: ChangeEvent<HTMLInputElement>,
    setFieldValue: FormikProps<FormikValues>['setFieldValue'],
    values: any
  ) => {
    const {
      target: { checked },
    } = event;

    setFieldValue('isProjectWork', checked);
    setFieldValue('StudentId', null);
    setStudentId(null);
    setStudentValue(null);
    setFieldValue('entitlementType', checked ? 'Offline' : '');
    setFieldValue('milestone', '');
    setShowStudentApplications(false);

    if (checked) {
      setMileStoneOptions(mileStoneOptionsForProjectWork);
      setWorkTypeForMilestoneTeamValues(
        values.milestoneTeam,
        'Offline',
        setFieldValue,
        paymentCategories
      );
    } else {
      setMileStoneOptions(initialMileStonOptions);
    }
  };

  useEffect(() => {
    if (initialValues.StudentId) {
      setStudentId(initialValues.StudentId);
      getStudentTrial(initialValues.StudentId);
    }
  }, [initialValues.StudentId]);

  const mountEffect = () => {
    const fetchMentorStudents = async () => {
      const { data }: { data: AllMentorStudentsQuery } = await client.query({
        query: ALL_MENTOR_STUDENTS_QUERY,
        variables: { MentorId: user?.id, limit: 500, bringAll: true },
        fetchPolicy: 'network-only',
      });
      if (data.allMentorStudents?.students?.length) {
        const studentIds = data.allMentorStudents.students.map((item) => {
          if (item?.student) return item.student.id;
        });
        setMentorStudentIds(studentIds);
      }
    };
    const milestoneTeamsOption = async () => {
      try {
        const { data }: any = await client.query({
          query: GET_ALL_MILESTONE_TEAMS,
          fetchPolicy: 'network-only',
          variables: { mentorId: user?.id, isFullTime: user?.isFullTime },
        });
        const allMilestoneTeams = data?.getMilestoneTeamOptions.map(
          (milestoneTeam: any) => {
            return { value: milestoneTeam?.value, label: milestoneTeam?.label };
          }
        );
        setMilestoneTeamsOptions(allMilestoneTeams);
      } catch (error) {
        console.log('milestoneTeamsOption ->', error);
      }
    };

    fetchMentorStudents();
    milestoneTeamsOption();
  };
  useEffect(mountEffect, []);

  const ActivityTooltipContent = () => {
    return (
      <>
        <p className={classes.tip}>
          Keep a detailed record of advising activity by{' '}
          <b>providing a brief summary that may include:</b>
        </p>
        <ul className={classes.tipList}>
          <li>Details on what was covered</li>
          <li>Action items / Next Steps</li>
          <li>Copied and pasted recap email (recommended)</li>
        </ul>
      </>
    );
  };

  if (!paymentCategories?.length) return <Loader />;
  return (
    <Box position="relative">
      <Box className={classes.formBoxCard}>
        <Grid className={classes.previousArrowHeading}>
          <Box className={classes.pointer} onClick={() => backClick()}>
            {BACK_IC()}
          </Box>

          <Typography variant="h3">Log Hours</Typography>
        </Grid>

        <Formik
          validationSchema={NewFormSchema}
          initialValues={initialValues}
          enableReinitialize
          onSubmit={async (values) => {
            try {
              let MUTATION = editId
                ? UPDATE_MENTOR_TIMELOG
                : CREATE_MENTOR_TIMELOG;
              const offset = +moment
                .tz(moment.tz.guess())
                .format('Z')
                .split(':')[0];
              const activityAt = moment(values.activityAt)
                .startOf('day')
                .add(12 + offset, 'hours')
                .toDate();

              if (workType === 'Dress Rehearsal') {
                values.workActivity = 'Dress Rehearsal';
                values.activityDuration = 60;
              }
              setInitialValues({
                ...values,
              });

              if (values.activityDuration <= 90) {
                values.durationNote = '';
              }

              const milestoneTeamId =
                milestoneTeamsOptions.find(
                  (milestoneTeam) =>
                    milestoneTeam.label === values.milestoneTeam
                )?.value || 0;
              let variables: any = {
                ...values,
                activityAt,
                workActivity: !!values.workActivity
                  ? values.workActivity
                  : values.entitlementType,
                PaymentCategoryId:
                  values.PaymentCategoryId || paymentIdforOthers,
                activityDuration: parseInt(values.activityDuration),
                isFullTime: user?.isFullTime,
                amount,
                ...(selectedAdvisorBookingId && {
                  AdvisorsBookingId: selectedAdvisorBookingId,
                }),
                ...(milestoneTeamId && { milestoneTeamId }),
                entityType:
                  MENTOR_TIMELOG_ASSOCIATION_TYPES.studentApplications,
                entityIds: studentApplicationIds,
                OtherApplicationsIds: values.OtherApplicationsIds
                  ? values.OtherApplicationsIds.map(
                      (application: OptionType) => application.value
                    )
                  : [],
              };
              if (editId) variables['id'] = +editId;
              if (workType === 'Other' || workType === 'Speciality Team')
                variables['workActivity'] = 'Other';
              if (values.otherAdvisorIds.length) {
                variables['otherAdvisorIds'] = values.otherAdvisorIds.map(
                  (item: OptionType) => +item.value
                );
                const otherAdvisorNames = values.otherAdvisorIds.map(
                  (item: OptionType) => item.label.split('(')[0].trim()
                );
                setAdvisorsNames([...advisorsNames, ...otherAdvisorNames]);
              }
              if (GroupId) {
                variables['GroupId'] = GroupId;
              }

              const res = await client.mutate({
                mutation: MUTATION,
                variables,
                fetchPolicy: 'no-cache',
              });
              if (res.data.createMentorTimeLog?.mentorTimeLog?.id) {
                await client.mutate({
                  mutation: CREATE_STUDENT_APPLICATION_MUTATION,
                  variables: {
                    collegeIds: values.OtherApplicationsIds
                      ? values.OtherApplicationsIds.map(
                          (application: OptionType) => application.value
                        )
                      : [],
                    StudentId: _studentId,
                    mentorTimeLogId:
                      res.data.createMentorTimeLog.mentorTimeLog?.id,
                    studentOtherApplication: true,
                  },
                  fetchPolicy: 'no-cache',
                });
              }
              onSubmit();
            } catch (error) {
              console.log(error);
            }
          }}
        >
          {({
            isSubmitting,
            setFieldValue,
            values,
            errors,
            touched,
            setTouched,
            dirty,
            initialValues,
          }) => {
            const isError = Object.entries(errors).length > 0;
            const showProjectWorkCheckbox =
              (workType === 'Milestone Teams' &&
                !values.milestoneTeam
                  ?.toLowerCase()
                  ?.includes('interview coaching team') &&
                entitlementTypes.some(
                  (type) => type.value.toLowerCase() === 'offline'
                )) ||
              values.milestoneTeam
                ?.toLowerCase()
                ?.includes('essay review team, undergrad');

            const isApplicationNotAdded =
              showStudentApplications &&
              !studentApplicationIds.length &&
              values.milestone !== 'Application Strategy' &&
              !values.OtherApplicationsIds?.length &&
              !values.isCommonApp &&
              !values.isCoalitionApp &&
              !values.isOtherStrategy;

            if (!isLateLog) {
              values.lateLogReason = null;
            }
            if (!isDuplicateOrientationCall) {
              values.duplicateOrientationCallReason = null;
            }

            if (workType === 'Dress Rehearsal') {
              values.workActivity = 'Dress Rehearsal';
              values.activityDuration = 60;
              if (
                paymentCategories &&
                paymentCategories.length &&
                !user?.isFullTime &&
                !values.PaymentCategoryId
              ) {
                setWorkTypeValue(
                  'Dress Rehearsal',
                  setFieldValue,
                  paymentCategories
                );
              }
            }
            if (values.workActivity === 'Onboarding') {
              values.activityAt = new Date();
              values.activityDuration = null;
            }
            if (
              GroupId &&
              (values.milestone === 'Application Strategy' ||
                values.milestone === 'Essay Writing & Editing' ||
                values.milestone === 'Interview Prep')
            ) {
              setShowStudentApplications(true);
            }
            return (
              <>
                {pendingTimelogs?.map((item) => {
                  if (!item) return <></>;
                  return (
                    <Card
                      className={clsx(
                        classes.stripeTokenCard,
                        classes.activityPendingCard
                      )}
                    >
                      <Box display="flex">
                        <Grid>
                          <img src={Info_Blue_Img} alt="token" />
                        </Grid>
                        <Grid>
                          <Typography variant="h5">
                            {`${fomateDateUS(
                              new Date(item?.activityAt)
                            )} - Student Advising Activity Pending`}
                          </Typography>
                          <Typography variant="body1">
                            {`${item?.mentor?.firstName} ${item?.mentor?.lastName} has submitted hours for your meeting
                      together with `}
                            <b>{`${item?.student?.firstName} ${item?.student?.lastName} `}</b>
                            on
                            <b>{` ${fomateDateUS(
                              new Date(item?.activityAt)
                            )}`}</b>
                            . Please submit your hours as well.
                          </Typography>

                          <Button
                            variant="contained"
                            onClick={() => beginPendingTimelog(item)}
                          >
                            Begin
                          </Button>
                        </Grid>
                      </Box>
                    </Card>
                  );
                })}
                {workType !== 'Dress Rehearsal' &&
                  values.StudentId &&
                  (!mentorStudentIds?.length ||
                    !mentorStudentIds?.includes(values.StudentId) ||
                    (values.workType !== 'Milestone Teams' &&
                      values.milestone === 'Other')) && (
                    <Card className={classes.stripeTokenCard}>
                      <Box display="flex">
                        <Grid>
                          <img src={Stripe_Token_Img} alt="token" />
                        </Grid>
                        <Grid>
                          <Typography variant="body1" style={{ margin: 0 }}>
                            {values.workType !== 'Milestone Teams' &&
                            values.milestone === 'Other'
                              ? 'Note is Required.'
                              : 'This student is not officially matched with you. Please clarify the circumstances for working with them'}
                          </Typography>
                        </Grid>
                      </Box>
                    </Card>
                  )}
                {values.workActivity === 'Onboarding' && onBoarded && (
                  <Card
                    className={clsx(
                      classes.stripeTokenCard,
                      classes.unavailableAccount
                    )}
                  >
                    <Box display="flex">
                      <Grid>
                        <img src={Danger_Img} alt="token" />
                      </Grid>
                      <Grid>
                        <Typography variant="body1" style={{ margin: 0 }}>
                          You have already submitted your time for Onboarding.
                        </Typography>
                      </Grid>
                    </Box>
                  </Card>
                )}

                <Form className={classes.formContainer}>
                  <Box className={classes.formInner}>
                    <Box className={classes.formLeft}>
                      <Box>
                        <Box className={classes.logHoursSubHeading}>
                          <Box fontWeight={600} component="strong">
                            {workType}
                          </Box>{' '}
                          Details
                        </Box>
                        {workType === 'Milestone Teams' && (
                          <>
                            <Box
                              className={clsx(
                                classes.textfield,
                                classes.textFieldMaterial
                              )}
                            >
                              <label>Milestone Team</label>

                              <Field
                                name="milestoneTeam"
                                label="Milestone Team"
                                fullWidth
                                component={({
                                  field,
                                  form: { touched, errors, setFieldValue },
                                }: any) => (
                                  <>
                                    <MaterialUISelect
                                      id="demo-simple-select-outlined"
                                      value={values.milestoneTeam}
                                      placeholder="Select Milestone Team"
                                      fullWidth
                                      style={{
                                        color: values.milestoneTeam
                                          ? colors.BLACK_TWELVE
                                          : colors.GRAY_TWENTYTWO,
                                      }}
                                      displayEmpty
                                      disabled={GroupId ? true : false}
                                      onChange={(item: any) => {
                                        getEntitlementTypes(item.target.value);
                                        console.log(item.target);
                                        setFieldValue(
                                          'milestoneTeam',
                                          item.target.value
                                        );
                                        setFieldValue('activityDuration', '');
                                        setFieldValue('StudentId', null);
                                        setStudentValue(null);
                                        setFieldValue('entitlementType', '');
                                        setFieldValue('isProjectWork', false);
                                        setTouched({
                                          ...touched,
                                          activityDuration: false,
                                        });
                                      }}
                                    >
                                      <MenuItem
                                        value=""
                                        style={{ color: colors.GRAY_TWENTYTWO }}
                                      >
                                        Select Team
                                      </MenuItem>
                                      {milestoneTeamsOptions.map(
                                        (item: any) => (
                                          <MenuItem value={item.label}>
                                            {item.label}
                                          </MenuItem>
                                        )
                                      )}
                                    </MaterialUISelect>
                                    {touched[`${field.name}`] &&
                                      errors[`${field.name}`] && (
                                        <FormHelperText error>
                                          {errors[`${field.name}`]}
                                        </FormHelperText>
                                      )}
                                  </>
                                )}
                              />
                            </Box>
                          </>
                        )}

                        {showProjectWorkCheckbox && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.isProjectWork}
                                disabled={!values.milestoneTeam}
                                onChange={(e) =>
                                  handleProjectWorkChange(
                                    e,
                                    setFieldValue,
                                    values
                                  )
                                }
                                color="primary"
                              />
                            }
                            label="Project Work"
                          />
                        )}

                        {(workType === 'Student Advising' ||
                          workType === 'Speciality Team' ||
                          workType === 'Milestone Teams' ||
                          workType === 'Dress Rehearsal') &&
                          !values.isProjectWork && (
                            <Box className={classes.textfield}>
                              <label>Student’s Name</label>

                              <Box className="select-field">
                                <Grid className={classes.customDropdownField}>
                                  <Field
                                    name="StudentId"
                                    label="Add Student"
                                    fullWidth
                                    component={({
                                      field,
                                      form: { touched, errors, setFieldValue },
                                    }: any) => (
                                      <ApolloConsumer>
                                        {(client) => (
                                          <>
                                            <CustomSearchSelect
                                              disable={
                                                workType ===
                                                  'Milestone Teams' &&
                                                !!!values.milestoneTeam
                                              }
                                              milestoneTeam={
                                                values.milestoneTeam
                                              }
                                              value={studentValue}
                                              setSelectedValue={(
                                                option: OptionType
                                              ) => {
                                                setFieldValue(
                                                  'isCommonApp',
                                                  false
                                                );
                                                setFieldValue(
                                                  'isCoalitionApp',
                                                  false
                                                );
                                                setFieldValue(
                                                  'isOtherStrategy',
                                                  false
                                                );
                                                setGroupId('');
                                                setFieldValue(
                                                  'missCall',
                                                  false
                                                );
                                                setIsAddNewApplication(false);
                                                setStudentApplications([]);
                                                setUCSubCampusApplications([]);
                                                setShowStudentApplications(
                                                  false
                                                );
                                                setStudentApplicationIds([]);
                                                setFieldValue('milestone', '');
                                                setFieldValue(
                                                  'activityDuration',
                                                  ''
                                                );
                                                setFieldValue(
                                                  'activityAt',
                                                  null
                                                );
                                                setTouched({
                                                  ...touched,
                                                  activityDuration: false,
                                                });
                                                setChecked(false);
                                                setSelectedAdvisorBookingId(0);
                                                setNoOfficialMeet(false);
                                                history.replace({ search: '' });
                                                setFieldValue(
                                                  'workActivity',
                                                  ''
                                                );
                                                setStudentValue(option);
                                                setFieldValue(
                                                  field.name,
                                                  option.value
                                                );
                                                getStudentTrial(
                                                  parseInt(option.value)
                                                );
                                                if (!option || !option.value) {
                                                  setFieldValue(
                                                    'PremiumEntitlementId',
                                                    null
                                                  );
                                                }
                                              }}
                                            />
                                            {touched[`${field.name}`] &&
                                              errors[`${field.name}`] && (
                                                <FormHelperText error>
                                                  {errors[`${field.name}`]}
                                                </FormHelperText>
                                              )}
                                          </>
                                        )}
                                      </ApolloConsumer>
                                    )}
                                  />
                                </Grid>
                              </Box>
                            </Box>
                          )}
                        {workType === 'Milestone Teams' && (
                          <>
                            <Box
                              className={clsx(
                                classes.textfield,
                                classes.textFieldMaterial
                              )}
                            >
                              <label>Entitlement Type</label>

                              <Field
                                name="entitlementType"
                                label="Entitlement Type"
                                fullWidth
                                component={({
                                  field,
                                  form: { touched, errors, setFieldValue },
                                }: any) => (
                                  <>
                                    <MaterialUISelect
                                      id="demo-simple-select-outlined"
                                      value={values.entitlementType}
                                      placeholder="Select Entitlement Type"
                                      fullWidth
                                      displayEmpty
                                      style={{
                                        color: values.entitlementType
                                          ? colors.BLACK_TWELVE
                                          : colors.GRAY_TWENTYTWO,
                                      }}
                                      disabled={
                                        GroupId || !values.StudentId
                                          ? true
                                          : false
                                      }
                                      onChange={(item: any) => {
                                        setFieldValue(
                                          'entitlementType',
                                          item.target.value
                                        );
                                        setFieldValue('missCall', false);
                                        setFieldValue('activityDuration', '');
                                        setTouched({
                                          ...touched,
                                          activityDuration: false,
                                        });
                                        if (
                                          paymentCategories &&
                                          paymentCategories.length
                                        ) {
                                          setWorkTypeForMilestoneTeamValues(
                                            values.milestoneTeam,
                                            item.target.value,
                                            setFieldValue,
                                            paymentCategories
                                          );
                                        }
                                      }}
                                    >
                                      <MenuItem
                                        value=""
                                        style={{ color: colors.GRAY_TWENTYTWO }}
                                      >
                                        Select Type
                                      </MenuItem>
                                      {entitlementTypes.map((item) => (
                                        <MenuItem value={item.value}>
                                          {item.label}
                                        </MenuItem>
                                      ))}
                                    </MaterialUISelect>
                                    {touched[`${field.name}`] &&
                                      errors[`${field.name}`] && (
                                        <FormHelperText error>
                                          {errors[`${field.name}`]}
                                        </FormHelperText>
                                      )}
                                  </>
                                )}
                              />
                            </Box>
                          </>
                        )}
                        {workType !== 'Other' &&
                          workType !== 'Speciality Team' &&
                          workType !== 'Dress Rehearsal' &&
                          workType !== 'Milestone Teams' && (
                            <>
                              <Box
                                className={clsx(
                                  classes.textfield,
                                  classes.textFieldMaterial
                                )}
                              >
                                <label>Activity Performed</label>
                                <Box className={classes.activityPerformed}>
                                  <Field
                                    name="workActivity"
                                    label="Activity"
                                    fullWidth
                                    component={({
                                      field,
                                      form: { touched, errors, setFieldValue },
                                    }: any) => (
                                      <>
                                        <MaterialUISelect
                                          value={values.workActivity}
                                          native={false}
                                          displayEmpty={true}
                                          // input={<OutlinedInput />}
                                          renderValue={(
                                            selected: any
                                          ): React.ReactNode => {
                                            if (selected.length === 0) {
                                              return (
                                                <Box
                                                  style={{
                                                    color:
                                                      colors.GRAY_TWENTYTWO,
                                                  }}
                                                >
                                                  Select Activity
                                                </Box>
                                              );
                                            }
                                            return (
                                              <div>
                                                {
                                                  timeLogAllWorkActivityOptions.find(
                                                    (option) =>
                                                      option.value === selected
                                                  )?.label
                                                }
                                              </div>
                                            );
                                          }}
                                          fullWidth
                                          disabled={
                                            GroupId ||
                                            (!values.StudentId &&
                                              workType !==
                                                'Advisor Operations' &&
                                              workType !== 'Other')
                                              ? true
                                              : false
                                          }
                                          className={
                                            !studentValue?.initialKickOffDate &&
                                            (values.workActivity ===
                                              'Video Chat Session, 2+ Advisors' ||
                                              values.workActivity ===
                                                'Video Chat Session, Solo')
                                              ? 'MuiErrorField'
                                              : ''
                                          }
                                          onChange={(item: any) => {
                                            setPrevoiusActivityPerformed(
                                              values.workActivity
                                            );
                                            setFieldValue(
                                              'workActivity',
                                              item.target.value
                                            );
                                            setFieldValue('missCall', false);
                                            setFieldValue(
                                              'activityDuration',
                                              ''
                                            );
                                            setTouched({
                                              ...touched,
                                              activityDuration: false,
                                            });
                                            if (
                                              item.target.value ===
                                                'Video Chat Session, 2+ Advisors' ||
                                              item.target.value ===
                                                'Video Chat Session, Solo' ||
                                              item.target.value ===
                                                'Orientation/Kick-Off Call, Solo' ||
                                              item.target.value ===
                                                'Orientation/Kick-Off Call, 2+ Advisors'
                                            ) {
                                              setOpenDialogue(true);
                                            } else {
                                              setFieldValue('activityAt', null);
                                              setNoOfficialMeet(false);
                                              setSelectedAdvisorBookingId(0);
                                            }
                                            if (
                                              item.target.value !==
                                                'Video Chat Session, 2+ Advisors' ||
                                              item.target.value !==
                                                'Orientation/Kick-Off Call, 2+ Advisors'
                                            )
                                              setFieldValue(
                                                'otherAdvisorIds',
                                                []
                                              );
                                            if (
                                              paymentCategories &&
                                              paymentCategories.length &&
                                              !user?.isFullTime
                                            ) {
                                              setWorkTypeValue(
                                                item.target.value,
                                                setFieldValue,
                                                paymentCategories
                                              );
                                            }
                                            if (
                                              item.target.value ===
                                              'Partner - Leadership Initiatives'
                                            ) {
                                              setOpen(true);
                                            } else {
                                              setOpen(false);
                                            }
                                            if (
                                              item.target.value.includes(
                                                'Orientation'
                                              ) &&
                                              item.target.value.includes('Call')
                                            ) {
                                              checkDuplicateOrientationCall(
                                                values?.StudentId
                                              );
                                            } else {
                                              setIsDuplicateOrientationCall(
                                                false
                                              );
                                            }
                                          }}
                                        >
                                          {workType === 'Advisor Operations'
                                            ? workActivityForAdvisorOperations.map(
                                                (item) => (
                                                  <MenuItem value={item.value}>
                                                    {item.label}
                                                  </MenuItem>
                                                )
                                              )
                                            : workActivityForStudentAdvising.map(
                                                (item) => (
                                                  <MenuItem value={item.value}>
                                                    {item.label}
                                                  </MenuItem>
                                                )
                                              )}
                                          {workType !== 'Advisor Operations' &&
                                            process.env
                                              .REACT_APP_ENABLE_AFFLIATE_TRIAL_PROGRAM ===
                                              'TRUE' && (
                                              <MenuItem
                                                value={
                                                  leadershipInitiativesOption.value
                                                }
                                              >
                                                {
                                                  leadershipInitiativesOption.label
                                                }
                                              </MenuItem>
                                            )}
                                        </MaterialUISelect>

                                        {!studentValue?.initialKickOffDate &&
                                        (values.workActivity ===
                                          'Video Chat Session, 2+ Advisors' ||
                                          values.workActivity ===
                                            'Video Chat Session, Solo') ? (
                                          <FormHelperText error>
                                            {`An orientation call has not been logged for ${studentValue?.label}. Please change activity type to “Orientation Call” if appropriate.`}
                                          </FormHelperText>
                                        ) : (
                                          touched[`${field.name}`] &&
                                          errors[`${field.name}`] && (
                                            <FormHelperText error>
                                              {errors[`${field.name}`]}
                                            </FormHelperText>
                                          )
                                        )}
                                      </>
                                    )}
                                  />
                                </Box>
                              </Box>
                              {!studentValue?.initialKickOffDate &&
                                (values.workActivity ===
                                  'Video Chat Session, 2+ Advisors' ||
                                  values.workActivity ===
                                    'Video Chat Session, Solo') && (
                                  <>
                                    <Grid
                                      item
                                      lg={12}
                                      xs={12}
                                      className={classes.dateDuration}
                                    >
                                      <Box
                                        className={`${clsx(
                                          classes.textfield,
                                          classes.subSectionPadding
                                        )} with-textarea `}
                                      >
                                        <Typography className="activityLog noMeetingAssociate">
                                          Why is this meeting being logged
                                          before an Orientation call?
                                        </Typography>

                                        <Field
                                          placeholder="Required"
                                          name="noteForNoOrientationCall"
                                          component={TextField}
                                          multiline
                                          rows={4}
                                          fullWidth
                                        />
                                      </Box>
                                    </Grid>
                                  </>
                                )}

                              <StudentFeedbackDialog
                                open={open}
                                setOpen={setOpen}
                                userTrialData={userTrialData}
                                setFeedBackNotSubmitted={
                                  setFeedBackNotSubmitted
                                }
                                studentId={_studentId}
                                setUserTrialData={setUserTrialData}
                              />
                            </>
                          )}
                        {openDialogue && (
                          <LinkOnlineMeetingDialog
                            openDialogue={openDialogue}
                            studentName={studentValue?.label}
                            studentId={parseInt(studentValue?.value)}
                            setFieldValue={setFieldValue}
                            prevoiusActivityPerformed={
                              prevoiusActivityPerformed
                            }
                            activityPerformed={values.workActivity}
                            setNoOfficialMeet={setNoOfficialMeet}
                            setSelectedAdvisorBookingId={
                              setSelectedAdvisorBookingId
                            }
                            selectedAdvisorBookingId={selectedAdvisorBookingId}
                            onClose={() => {
                              setOpenDialogue(false);
                            }}
                          />
                        )}
                        {(values.workActivity ===
                          'Video Chat Session, 2+ Advisors' ||
                          values.workActivity ===
                            'Orientation/Kick-Off Call, 2+ Advisors') &&
                          !GroupId && (
                            <Grid
                              className={clsx(
                                classes.textfield,
                                classes.secondAdvisorApplication
                              )}
                            >
                              <label>Other Advisors</label>

                              <Field
                                name="otherAdvisorIds"
                                label="Other Advisor"
                                fullWidth
                                value={values.otherAdvisorIds}
                                component={({
                                  field,
                                  form: { touched, errors, setFieldValue },
                                }: any) => (
                                  <>
                                    <AdvisorSelect
                                      placeholder="Other Advisor"
                                      allowInvisibleMentors={true}
                                      value={values.otherAdvisorIds}
                                      advisorId={user?.id}
                                      onChange={(val: OptionType[]) => {
                                        setFieldValue('otherAdvisorIds', val);
                                      }}
                                    />
                                    {touched[`${field.name}`] &&
                                      errors[`${field.name}`] && (
                                        <FormHelperText error>
                                          {errors[`${field.name}`]}
                                        </FormHelperText>
                                      )}
                                  </>
                                )}
                              />
                            </Grid>
                          )}
                        {selectedAdvisorBookingId || noOfficialMeet ? (
                          <Box display="flex" alignItems="center" mb={2}>
                            {noOfficialMeet ? (
                              <img src={CautionIcon} alt="warning" />
                            ) : (
                              <img src={CheckedLightIcon} alt="checked" />
                            )}

                            <Typography
                              variant="body1"
                              className="onlineMeetingVerified"
                            >
                              {noOfficialMeet
                                ? 'No Online Meeting Verified'
                                : 'Online Meeting Verified'}
                            </Typography>

                            {noOfficialMeet ? (
                              <Button
                                variant="text"
                                onClick={() => {
                                  setOpenDialogue(true);
                                }}
                                className="textActionButton"
                              >
                                Add Meeting
                              </Button>
                            ) : (
                              <Button
                                variant="text"
                                className="textActionButton"
                                onClick={() => setOpenDialogue(true)}
                              >
                                Change
                              </Button>
                            )}
                          </Box>
                        ) : (
                          ''
                        )}
                        {values.workActivity ===
                          'Partner - Leadership Initiatives' && (
                          <Box className={classes.feedbackCheckbox}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="checkedB"
                                  color="primary"
                                  checked={!feedBackNotSubmitted}
                                />
                              }
                              label={
                                <>
                                  I have completed feedback for this student at{' '}
                                  <button
                                    type="button"
                                    onClick={() =>
                                      !!userTrialData && setOpen(true)
                                    }
                                  >
                                    form link
                                  </button>
                                </>
                              }
                            />
                          </Box>
                        )}
                        {(workType === 'Student Advising' ||
                          workType === 'Milestone Teams') &&
                          (values.workActivity === 'Video Chat Session, Solo' ||
                            values.workActivity ===
                              'Video Chat Session, 2+ Advisors' ||
                            values.workActivity.includes(
                              'Orientation/Kick-Off Call'
                            ) ||
                            values.entitlementType.includes('Video')) && (
                            <Grid item xs={8} className={classes.dateDuration}>
                              <Box
                                className={clsx(
                                  classes.textfield,
                                  classes.missedCallCheckbox
                                )}
                                marginBottom="0px !important"
                              >
                                <Field
                                  name="missCall"
                                  component={({
                                    form: { values, setFieldValue },
                                  }: any) => (
                                    <>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            disabled={GroupId ? true : false}
                                            checked={values.missCall}
                                            onChange={({
                                              target: { checked },
                                            }) => {
                                              setFieldValue(
                                                'missCall',
                                                checked
                                              );
                                              if (checked) {
                                                setFieldValue(
                                                  'activityDuration',
                                                  15
                                                );
                                              } else if (
                                                values.actualMeetingTime
                                              ) {
                                                setFieldValue(
                                                  'activityDuration',
                                                  values.actualMeetingTime
                                                );
                                              }
                                            }}
                                            color="primary"
                                          />
                                        }
                                        label={'Student Missed Call'}
                                      />
                                    </>
                                  )}
                                />
                              </Box>
                            </Grid>
                          )}
                        <Grid container>
                          <Grid item lg={2} xs={12}></Grid>
                          <Grid
                            item
                            lg={6}
                            xs={12}
                            className={classes.dateDuration}
                          >
                            {values.workActivity !== 'Onboarding' && (
                              <Box
                                className={clsx(
                                  classes.textfield,
                                  classes.textFieldDate,
                                  (GroupId ||
                                    (selectedAdvisorBookingId &&
                                      !noOfficialMeet)) &&
                                    classes.disableTextFieldDate
                                )}
                              >
                                <label
                                  className={
                                    GroupId ||
                                    (selectedAdvisorBookingId &&
                                      !noOfficialMeet)
                                      ? classes.disableLabelText
                                      : ''
                                  }
                                >
                                  Date
                                </label>

                                <Field
                                  name="activityAt"
                                  fullWidth
                                  component={({ field, form }: any) => (
                                    <NativeTimeTextField
                                      disable={
                                        (!values.StudentId &&
                                          workType !== 'Other' &&
                                          workType !== 'Advisor Operations' &&
                                          !values.isProjectWork) ||
                                        GroupId ||
                                        (selectedAdvisorBookingId &&
                                          !noOfficialMeet)
                                          ? true
                                          : false
                                      }
                                      field={field}
                                      form={form}
                                      maxDate={new Date()}
                                      isLateLog={isLateLog}
                                      setIsLateLog={setIsLateLog}
                                    />
                                  )}
                                />
                              </Box>
                            )}
                          </Grid>

                          <Grid
                            item
                            lg={4}
                            xs={12}
                            className={classes.durationField}
                          >
                            {values.workActivity !== 'Onboarding' &&
                              workType !== 'Dress Rehearsal' && (
                                <Box
                                  mb={0}
                                  className={`${clsx(
                                    classes.textfield,
                                    classes.textfieldDateDuration,
                                    (values.workActivity === 'Onboarding' &&
                                      onBoarded) ||
                                      (values.missCall &&
                                        classes.disableTextFieldDate)
                                  )} hide-error`}
                                >
                                  <label
                                    style={{ whiteSpace: 'nowrap' }}
                                    className={
                                      (values.workActivity === 'Onboarding' &&
                                        onBoarded) ||
                                      values.missCall
                                        ? classes.disableLabelText
                                        : ''
                                    }
                                  >
                                    Duration (minutes)
                                  </label>

                                  <Field
                                    name="activityDuration"
                                    placeholder="mm"
                                    fullWidth
                                    component={NumberInput}
                                    disabled={
                                      (values.workActivity === 'Onboarding' &&
                                        onBoarded) ||
                                      values.missCall ||
                                      (!values.StudentId &&
                                        workType !== 'Other' &&
                                        workType !== 'Advisor Operations' &&
                                        !values.isProjectWork)
                                    }
                                    className={
                                      errors.activityDuration &&
                                      touched.activityDuration
                                        ? classes.MuiErrorField
                                        : ''
                                    }
                                  />
                                  <span className="requiredText">
                                    <ErrorMessage
                                      name="activityDuration"
                                      className="requiredText"
                                    />
                                  </span>
                                </Box>
                              )}
                          </Grid>
                          <Grid item lg={2} xs={12}></Grid>
                          <Grid item lg={10} xs={12}>
                            {isLateLog && (
                              <>
                                <Box
                                  className={`${classes.textfield} with-textarea`}
                                >
                                  <Typography
                                    variant="body2"
                                    className="notesDescription"
                                  >
                                    Please provide reasoning on why this meeting
                                    is being logged late. Per your advising
                                    contract, you have 10 calendar days to log
                                    activity from the date of that activity. If
                                    you are outside this window, your log may
                                    not be paid out.
                                  </Typography>
                                  <Field
                                    name="lateLogReason"
                                    rows={4}
                                    component={TextField}
                                    multiline
                                    fullWidth
                                    placeholder="Required"
                                  />
                                </Box>
                              </>
                            )}
                          </Grid>
                          <Grid item lg={2} xs={12}></Grid>
                          <Grid item lg={10} xs={12}>
                            {isDuplicateOrientationCall && (
                              <>
                                <Box
                                  className={`${classes.textfield} with-textarea`}
                                >
                                  <Typography
                                    variant="body2"
                                    className="notesDescription"
                                  >
                                    Why is another orientation call being logged
                                    for yourself and this student?
                                  </Typography>
                                  <Field
                                    name="duplicateOrientationCallReason"
                                    rows={4}
                                    component={TextField}
                                    multiline
                                    fullWidth
                                    placeholder="Required"
                                  />
                                </Box>
                              </>
                            )}
                          </Grid>

                          {noOfficialMeet && (
                            <>
                              <Grid
                                item
                                xs={12}
                                className={classes.dateDuration}
                                style={{ padding: 0 }}
                              >
                                <Box
                                  className={`${clsx(
                                    classes.textfield,
                                    classes.subSectionPadding
                                  )} with-textarea`}
                                >
                                  <Typography className="activityLog">
                                    Why is there no “My Meeting” associated with
                                    this activity log?
                                  </Typography>

                                  <Field
                                    placeholder="Required"
                                    name="otherMeetingDescription"
                                    component={TextField}
                                    multiline
                                    rows={4}
                                    fullWidth
                                  />
                                </Box>
                              </Grid>
                            </>
                          )}
                          {values.actualMeetingTime &&
                          ((values.activityDuration >=
                            values.actualMeetingTime + 5 &&
                            values.activityDuration <= 90) ||
                            values.activityDuration <=
                              values.actualMeetingTime - 15) &&
                          selectedAdvisorBookingId &&
                          !values.missCall ? (
                            <Grid
                              item
                              xs={12}
                              className={classes.dateDuration}
                              style={{ padding: 0 }}
                            >
                              <Box
                                className={`${clsx(
                                  classes.textfield,
                                  classes.subSectionPadding
                                )} with-textarea`}
                              >
                                <Typography className="activityLog">
                                  Why doesn't the duration reflect the expected
                                  meeting time?
                                </Typography>
                                <Field
                                  name="noteForExtendedMeeting"
                                  component={TextField}
                                  multiline
                                  rows={4}
                                  placeholder="Required"
                                  fullWidth
                                />
                              </Box>
                            </Grid>
                          ) : (
                            ''
                          )}
                          {values.activityDuration > 90 && (
                            <>
                              <Grid
                                item
                                xs={12}
                                className={classes.dateDuration}
                                style={{ padding: 0 }}
                              >
                                <Box
                                  className={`${clsx(
                                    classes.textfield,
                                    classes.subSectionPadding
                                  )} with-textarea`}
                                >
                                  <Typography className="activityLog">
                                    Why did this activity exceed 90 minutes?*
                                  </Typography>

                                  <Field
                                    multiline
                                    rows={4}
                                    fullWidth
                                    name="durationNote"
                                    component={TextField}
                                    placeholder="Required"
                                  />
                                </Box>
                              </Grid>
                            </>
                          )}
                        </Grid>

                        {(workType === 'Student Advising' ||
                          workType === 'Milestone Teams') &&
                          values.workActivity !==
                            'Partner - Leadership Initiatives' &&
                          !values.missCall && (
                            <>
                              <Box
                                className={clsx(
                                  classes.textfield,
                                  classes.textFieldMaterial
                                )}
                              >
                                <label>Milestone</label>

                                <Field
                                  name="milestone"
                                  label="Milestone"
                                  placeholder="Select Milestone"
                                  fullWidth
                                  component={({
                                    field,
                                    form: { touched, errors, setFieldValue },
                                  }: any) => (
                                    <>
                                      <MaterialUISelect
                                        id="demo-simple-select-outlined"
                                        value={values.milestone}
                                        placeholder="Select Milestone"
                                        displayEmpty
                                        fullWidth
                                        style={{
                                          color: values.milestone
                                            ? colors.BLACK_TWELVE
                                            : colors.GRAY_TWENTYTWO,
                                        }}
                                        disabled={
                                          values.workType !== 'Milestone Teams'
                                            ? !values.workActivity ||
                                              values.workActivity ===
                                                'Meeting with other Advisor/AO'
                                            : !values.milestoneTeam
                                        }
                                        onChange={(item: any) => {
                                          setFieldValue('isCommonApp', false);
                                          setFieldValue(
                                            'isCoalitionApp',
                                            false
                                          );
                                          setFieldValue(
                                            'isOtherStrategy',
                                            false
                                          );

                                          if (item.target.value === 'Other') {
                                            setFieldValue('serviceElement', '');
                                          }
                                          if (
                                            item.target.value ===
                                              'Application Strategy' ||
                                            item.target.value ===
                                              'Essay Writing & Editing' ||
                                            item.target.value ===
                                              'Interview Prep'
                                          ) {
                                            setShowStudentApplications(true);
                                          } else {
                                            setShowStudentApplications(false);
                                            setStudentApplicationIds([]);
                                            setFieldValue(
                                              'OtherApplicationsIds',
                                              null
                                            );
                                          }
                                          setFieldValue(
                                            'milestone',
                                            item.target.value
                                          );
                                        }}
                                      >
                                        <MenuItem
                                          value=""
                                          style={{
                                            color: colors.GRAY_TWENTYTWO,
                                          }}
                                        >
                                          Select Milestone
                                        </MenuItem>
                                        {mileStoneOptions.map((item) => (
                                          <MenuItem value={item.value}>
                                            {item.label}
                                          </MenuItem>
                                        ))}
                                      </MaterialUISelect>
                                      {touched[`${field.name}`] &&
                                        errors[`${field.name}`] && (
                                          <FormHelperText error>
                                            {errors[`${field.name}`]}
                                          </FormHelperText>
                                        )}
                                    </>
                                  )}
                                />
                                <Box mt={1}>
                                  {values.milestone ===
                                    'Essay Writing & Editing' && (
                                    <Grid className={classes.logHoursCheckbox}>
                                      <Grid>
                                        <label>
                                          <Field
                                            as={Checkbox}
                                            color="primary"
                                            type="checkbox"
                                            name="isCommonApp"
                                          />
                                          Common App
                                        </label>
                                        <label>
                                          <Field
                                            as={Checkbox}
                                            color="primary"
                                            type="checkbox"
                                            name="isCoalitionApp"
                                          />
                                          Coalition App
                                        </label>
                                      </Grid>

                                      <Grid>
                                        <label>
                                          <Field
                                            as={Checkbox}
                                            color="primary"
                                            type="checkbox"
                                            name="isOtherStrategy"
                                          />
                                          Other / General Strategy
                                        </label>
                                      </Grid>
                                    </Grid>
                                  )}
                                </Box>

                                {showStudentApplications ? (
                                  <>
                                    <Box
                                      mt={2.1}
                                      className={clsx(
                                        classes.textfield,
                                        classes.secondAdvisorApplication
                                      )}
                                    >
                                      <label>Student Applications</label>

                                      <Grid
                                        className={
                                          classes.studentApplicationsSelect
                                        }
                                      >
                                        <Select
                                          isMulti
                                          id="college1"
                                          placeholder={
                                            values.isCommonApp ||
                                            values.isCoalitionApp ||
                                            values.isOtherStrategy ||
                                            values.OtherApplicationsIds
                                              ?.length ||
                                            values.milestone ===
                                              'Application Strategy'
                                              ? 'Optional'
                                              : 'Required'
                                          }
                                          options={allStudentApplications}
                                          classNamePrefix="react-select"
                                          className="react-select-container"
                                          onChange={(selectedOptions) => {
                                            const selectedApplicationOptions = [
                                              ...selectedOptions,
                                            ];
                                            const UCStudentApplicationIndex =
                                              selectedApplicationOptions.findIndex(
                                                (option) =>
                                                  option.label ===
                                                  'University of California (any)'
                                              );

                                            if (
                                              UCStudentApplicationIndex >= 0
                                            ) {
                                              selectedApplicationOptions.splice(
                                                UCStudentApplicationIndex,
                                                1,
                                                ...UCSubCampusApplications
                                              );
                                            }

                                            handleSelectChange(
                                              selectedApplicationOptions,
                                              setFieldValue,
                                              values
                                            );
                                          }}
                                        />
                                      </Grid>
                                    </Box>
                                    {isAddNewApplication ? (
                                      <Box
                                        mt={2.1}
                                        className={clsx(
                                          classes.textfield,
                                          classes.secondAdvisor
                                        )}
                                      >
                                        <OtherApplications
                                          loadOptions={
                                            loadCollegeApplicationsOptions
                                          }
                                          setFieldValue={setFieldValue}
                                        />
                                      </Box>
                                    ) : (
                                      <Box
                                        className={classes.addNewApplications}
                                      >
                                        <Button
                                          onClick={() => {
                                            setIsAddNewApplication(true);
                                          }}
                                          color="primary"
                                          variant="text"
                                        >
                                          <img src={AddIcon} alt="add" />
                                          Add New Application(s)
                                        </Button>
                                      </Box>
                                    )}
                                  </>
                                ) : (
                                  ''
                                )}
                              </Box>
                            </>
                          )}
                        {workType === 'Speciality Team' && (
                          <Box
                            className={clsx(
                              classes.textfield,
                              classes.textFieldMaterial
                            )}
                          >
                            <label>Team</label>
                            <SelectTeam
                              setFieldValue={setFieldValue}
                              values={values}
                              setTeamName={setTeamName}
                              TeamName={TeamName}
                            />
                          </Box>
                        )}

                        <Field name="PaymentCategoryId" type="hidden" />
                        {workType === 'Dress Rehearsal' && (
                          <Box
                            className={clsx(
                              classes.textfield,
                              classes.missedCallCheckbox
                            )}
                            marginBottom="0px !important"
                          >
                            <Field
                              name="airtableSubmitted"
                              component={({
                                form: { values, setFieldValue },
                              }: any) => (
                                <>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.airtableSubmitted}
                                        onChange={({ target: { checked } }) => {
                                          setFieldValue(
                                            'airtableSubmitted',
                                            checked
                                          );
                                        }}
                                        color="primary"
                                      />
                                    }
                                    label={'Airtable Form Submitted'}
                                  />
                                </>
                              )}
                            />
                          </Box>
                        )}
                        {workType === 'Student Advising' ||
                        workType === 'Milestone Teams' ? (
                          <Box className={`${classes.textfield} with-textarea`}>
                            <label>
                              Activity Notes
                              <ActivityNotesTip
                                title={<ActivityTooltipContent />}
                                placement="top-start"
                                arrow
                              >
                                <img
                                  src={HintIcon}
                                  alt="?"
                                  className={classes.hintIcon}
                                />
                              </ActivityNotesTip>
                            </label>

                            <Field
                              name="note"
                              rows={4}
                              component={TextField}
                              multiline
                              fullWidth
                              placeholder="Required"
                            />

                            <Typography
                              variant="body2"
                              className="notesDescription"
                            >
                              Activity Notes will be visible for all advisors in
                              the Time Logs tab on student pages.
                            </Typography>
                          </Box>
                        ) : (
                          workType !== 'Dress Rehearsal' && (
                            <Box
                              className={`${classes.textfield} with-textarea`}
                            >
                              <label>
                                Activity Notes
                                <ActivityNotesTip
                                  title={<ActivityTooltipContent />}
                                  placement="top-start"
                                  arrow
                                >
                                  <img
                                    src={HintIcon}
                                    alt="?"
                                    className={classes.hintIcon}
                                  />
                                </ActivityNotesTip>
                              </label>

                              <Field
                                rows={4}
                                multiline
                                fullWidth
                                name="note"
                                component={TextField}
                                disabled={
                                  values.workActivity === 'Onboarding' &&
                                  onBoarded
                                }
                                placeholder="Required"
                              />
                            </Box>
                          )
                        )}
                      </Box>
                    </Box>

                    <Card className={classes.logHoursCard}>
                      {_studentId && (
                        <Query<StudentEntitlementsQuery>
                          query={GET_STUDENT_ENTITLEMENTS_STAT}
                          variables={{
                            studentId: _studentId,
                            advisorId: user?.id,
                          }}
                          fetchPolicy="network-only"
                        >
                          {({ data, loading, refetch }) => {
                            if (!data || !data.Student) return null;
                            const { Student } = data;
                            const {
                              firstName,
                              lastName,
                              isPremium,
                              premiumTotalVideoCount,
                              premiumVideoCountUsed,
                              premiumTotalOfflineCount,
                              premiumOfflineCountUsed,
                              studentApplications,
                              studentTrial,
                              giveDressRehearsalsEntitlements,
                              premiumPackageType,
                            } = Student;
                            const options = studentApplications
                              ?.filter(
                                (item) =>
                                  !item?.applicationRequirement?.college
                                    ?.ParentCollegeId
                              )
                              ?.map((item) => ({
                                value: item?.id,
                                label:
                                  item?.applicationRequirement?.college?.name,
                              }));

                            const _UCSubCampusApplications = studentApplications
                              ?.filter(
                                (item) =>
                                  item?.applicationRequirement?.college
                                    ?.ParentCollegeId
                              )
                              ?.map((application) => ({
                                value: application?.id,
                                label:
                                  application?.applicationRequirement?.college
                                    ?.name,
                              }));
                            if (
                              _UCSubCampusApplications?.length &&
                              _UCSubCampusApplications?.length !==
                                UCSubCampusApplications.length
                            ) {
                              setUCSubCampusApplications(
                                _UCSubCampusApplications
                              );
                            }

                            if (_UCSubCampusApplications?.length) {
                              options?.push({
                                value: 0,
                                label: 'University of California (any)',
                              });
                            }

                            if (
                              allStudentApplications.length !== options?.length
                            ) {
                              setStudentApplications(options);
                            }

                            const collegesToEclude = studentApplications?.map(
                              (item) =>
                                item?.applicationRequirement?.college?.id
                            );
                            collegesToExcludeIds = collegesToEclude?.length
                              ? collegesToEclude
                              : [];

                            const studentName = `${firstName || ''} ${
                              lastName || ''
                            }`;

                            let offlineCountUsed = 0;
                            if (premiumOfflineCountUsed) {
                              const {
                                MeetingWithAdvisor,
                                essayCount,
                                offlineResearch,
                                Other,
                                milestoneTeamTotal,
                                dressRehearsalCount,
                                correspondenceTotal,
                              } = premiumOfflineCountUsed;
                              offlineCountUsed =
                                MeetingWithAdvisor +
                                essayCount +
                                offlineResearch +
                                milestoneTeamTotal +
                                Other +
                                dressRehearsalCount +
                                correspondenceTotal;
                            }
                            const OnlineWidgetElementProps =
                              calculatePercentageAndColorsToChose(
                                premiumVideoCountUsed || 0,
                                premiumTotalVideoCount || 0,
                                fromOnlineChat &&
                                  !(
                                    values.workActivity ===
                                      'Orientation/Kick-Off Call, Solo' ||
                                    values.workActivity ===
                                      'Orientation/Kick-Off Call, 2+ Advisors'
                                  )
                                  ? Number(
                                      (values.activityDuration / 60).toFixed(1)
                                    )
                                  : 0
                              );
                            const OfflineWidgetElementProps =
                              calculatePercentageAndColorsToChose(
                                offlineCountUsed || 0,
                                premiumTotalOfflineCount || 0,
                                !fromOnlineChat &&
                                  !(
                                    values.workActivity ===
                                      'Orientation/Kick-Off Call, Solo' ||
                                    values.workActivity ===
                                      'Orientation/Kick-Off Call, 2+ Advisors' ||
                                    values.entitlementType === 'Video Chat'
                                  )
                                  ? Number(
                                      (values.activityDuration / 60).toFixed(1)
                                    )
                                  : 0
                              );
                            if (
                              values.workActivity &&
                              (values.workActivity ===
                                'Video Chat Session, Solo' ||
                                values.workActivity ===
                                  'Video Chat Session, 2+ Advisors' ||
                                values.entitlementType === 'Video Chat')
                            ) {
                              setPercentageValue(
                                OnlineWidgetElementProps?.percentageValue
                              );
                              setFromOnlineChat(true);
                            } else if (
                              values.workActivity &&
                              !(
                                values.workActivity ===
                                  'Video Chat Session, Solo' ||
                                values.workActivity ===
                                  'Video Chat Session, 2+ Advisors' ||
                                values.workActivity ===
                                  'Orientation/Kick-Off Call, Solo' ||
                                values.workActivity ===
                                  'Orientation/Kick-Off Call, 2+ Advisors' ||
                                values.entitlementType === 'Video Chat'
                              )
                            ) {
                              setPercentageValue(
                                OfflineWidgetElementProps?.percentageValue
                              );
                              setFromOnlineChat(false);
                            } else {
                              setPercentageValue(null);
                            }

                            return (
                              <>
                                {!!!studentTrial && (
                                  <>
                                    <Box
                                      className={clsx(
                                        classes.formRight,
                                        classes.formRightExpected
                                      )}
                                    >
                                      <Typography
                                        variant="h5"
                                        className="studentEntitlementsHeading"
                                      >
                                        Student Entitlements
                                        <span> - {studentName}</span>
                                      </Typography>

                                      <Grid container spacing={2}>
                                        {(premiumTotalVideoCount ||
                                          premiumTotalVideoCount === 0) &&
                                        isPremium ? (
                                          <Grid item lg={6} md={6} xs={12}>
                                            <StudentVideoChatHours
                                              totalHours={
                                                premiumTotalVideoCount
                                              }
                                              hoursUsed={
                                                premiumVideoCountUsed || 0
                                              }
                                              text={'video chat hours used'}
                                              {...((values.workActivity ===
                                                'Video Chat Session, Solo' ||
                                                values.workActivity ===
                                                  'Video Chat Session, 2+ Advisors' ||
                                                (values.workActivity.includes(
                                                  'Orientation/Kick-Off Call'
                                                ) &&
                                                  (values.missCall ||
                                                    (moment(
                                                      values.activityAt
                                                    ).isAfter(
                                                      '2024-09-04T00:00:00'
                                                    ) &&
                                                      moment(
                                                        values.activityAt
                                                      ).isBefore(
                                                        '2024-10-07T00:00:00'
                                                      )))) ||
                                                values.entitlementType ===
                                                  'Video Chat') &&
                                                !premiumPackageType
                                                  ?.toLowerCase()
                                                  .includes('scholar') && {
                                                  durationExpected: Number(
                                                    (
                                                      values.activityDuration /
                                                      60
                                                    ).toFixed(1)
                                                  ),
                                                })}
                                              {...(!!OnlineWidgetElementProps && {
                                                progressColor:
                                                  OnlineWidgetElementProps?.HoursCalculatedColor,
                                                durationExpectedColor:
                                                  OnlineWidgetElementProps?.InnerColor,
                                              })}
                                              isSubmittingPendingTimelog={
                                                isSubmittingPendingTimelog
                                              }
                                            />
                                          </Grid>
                                        ) : (
                                          <></>
                                        )}
                                        {(premiumTotalOfflineCount ||
                                          premiumTotalOfflineCount === 0) &&
                                        isPremium ? (
                                          <Grid
                                            item
                                            lg={6}
                                            md={6}
                                            xs={12}
                                            className="offlineHoursPadding"
                                          >
                                            <StudentVideoChatHours
                                              totalHours={
                                                premiumTotalOfflineCount || 0
                                              }
                                              hoursUsed={offlineCountUsed || 0}
                                              text={'offline hours used'}
                                              premiumOfflineCountUsed={
                                                premiumOfflineCountUsed
                                              }
                                              {...(!(
                                                values.workActivity ===
                                                  'Video Chat Session, Solo' ||
                                                values.workActivity ===
                                                  'Video Chat Session, 2+ Advisors' ||
                                                values.workActivity ===
                                                  'Orientation/Kick-Off Call, Solo' ||
                                                values.workActivity ===
                                                  'Orientation/Kick-Off Call, 2+ Advisors' ||
                                                values.entitlementType ===
                                                  'Video Chat'
                                              ) &&
                                                !premiumPackageType
                                                  ?.toLowerCase()
                                                  .includes('scholar') && {
                                                  durationExpected:
                                                    workType ===
                                                      'Dress Rehearsal' &&
                                                    giveDressRehearsalsEntitlements?.isPremiumElite &&
                                                    giveDressRehearsalsEntitlements?.dressRehearsalsUsedCount <
                                                      2
                                                      ? 0
                                                      : Number(
                                                          (
                                                            values.activityDuration /
                                                            60
                                                          ).toFixed(1)
                                                        ),
                                                })}
                                              {...(!!OfflineWidgetElementProps && {
                                                progressColor:
                                                  OfflineWidgetElementProps?.HoursCalculatedColor,
                                                durationExpectedColor:
                                                  OfflineWidgetElementProps?.InnerColor,
                                              })}
                                              isSubmittingPendingTimelog={
                                                isSubmittingPendingTimelog
                                              }
                                            />
                                          </Grid>
                                        ) : (
                                          <></>
                                        )}
                                      </Grid>

                                      {percentageValue &&
                                        values?.activityDuration !== '' &&
                                        values?.workActivity !== '' && (
                                          <Box
                                            mt={4}
                                            className={classes.expectedPayment}
                                          >
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  color="default"
                                                  onChange={(e) => {
                                                    setIsEntitlementsUsedChecked(
                                                      e.target.checked
                                                    );
                                                    setFieldValue(
                                                      'isEntitlementsUsedChecked',
                                                      e.target.checked
                                                    );
                                                  }}
                                                />
                                              }
                                              label={
                                                <Box pt={0.5}>
                                                  I acknowledge that this
                                                  student has expended{' '}
                                                  {`${percentageValue}`}% or
                                                  more of their{' '}
                                                  <b
                                                    style={{
                                                      color:
                                                        colors.GRAY_TAB_BORDER,
                                                    }}
                                                  >
                                                    {fromOnlineChat
                                                      ? 'video chat'
                                                      : 'offline hours'}
                                                  </b>{' '}
                                                  hours.
                                                </Box>
                                              }
                                            />
                                          </Box>
                                        )}
                                    </Box>

                                    <Divider className="dividerSeparator" />
                                  </>
                                )}
                              </>
                            );
                          }}
                        </Query>
                      )}
                      <Box
                        className={clsx(
                          classes.formRight,
                          classes.formRightExpected
                        )}
                      >
                        <Box display="flex" alignItems="center">
                          <Box fontSize={16} mr={2} fontFamily={fonts.INTER}>
                            Expected Payment:
                          </Box>

                          <Box>
                            {(workType === 'Other' ||
                              workType === 'Milestone Teams' ||
                              workType === 'Speciality Team' ||
                              workType === 'Dress Rehearsal' ||
                              values.workActivity) &&
                            values.activityDuration ? (
                              <Box className="amountText">
                                {user?.isFullTime
                                  ? '$0'
                                  : `$${expectedPayment(
                                      paymentCategories,
                                      values
                                    )}`}
                              </Box>
                            ) : values.workActivity === 'Onboarding' ? (
                              <Box className="amountText">
                                {user?.isFullTime
                                  ? '$0'
                                  : `$${exp_payment_Onboarding()}`}
                              </Box>
                            ) : (
                              <Box className="amountText">--</Box>
                            )}
                          </Box>
                        </Box>

                        <Grid className={classes.logHoursAlert}>
                          {!errors['activityDuration'] &&
                            values.activityDuration > 90 && (
                              <>
                                <Alert severity="warning">
                                  <Box display="flex">
                                    <img src={Warning_Img} alt="token" />
                                    <Typography variant="h5">
                                      Please double check the following:
                                    </Typography>
                                  </Box>
                                  <ul>
                                    <li>Duration (minutes)</li>
                                  </ul>
                                </Alert>
                              </>
                            )}
                        </Grid>

                        <Box mt={2} className={classes.expectedPayment}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checked}
                                inputProps={{ 'aria-label': 'controlled' }}
                                onChange={(event) => {
                                  setChecked(event.target.checked);
                                  setFieldValue('timelogSubmission', true);
                                }}
                                color="default"
                              />
                            }
                            label="This certifies that this time log accurately represents the activity performed, the duration required to complete the activity and the student who is assessed (if Student Advising activity) is correct. This activity adheres to the honor code and advisor agreement that I signed with CollegeAdvisor.com."
                          />
                        </Box>

                        <Box marginTop={3.625}>
                          <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            disabled={
                              !dirty ||
                              isError ||
                              isApplicationNotAdded ||
                              !checked ||
                              isSubmitting ||
                              (values.workActivity === 'Onboarding' &&
                                onBoarded) ||
                              (values.workActivity ===
                                'Partner - Leadership Initiatives' &&
                                feedBackNotSubmitted) ||
                              (!!percentageValue &&
                                !isEntitlementsUsedChecked &&
                                values.workActivity !==
                                  'Partner - Leadership Initiatives' &&
                                values.workType !== 'Milestone Teams' &&
                                !!!userTrialData)
                            }
                            style={{ textTransform: 'capitalize' }}
                            className={classes.submitButton}
                          >
                            {editId ? 'Update' : 'Submit Activity'}
                          </Button>
                        </Box>
                        {errors.PaymentCategoryId && values.workActivity && (
                          <span className="requiredText">
                            {errors.PaymentCategoryId}
                          </span>
                        )}
                      </Box>
                    </Card>
                  </Box>
                </Form>
              </>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default TimeLogForm;
