import { useContext, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import clsx from 'clsx';
import { Mutation } from 'react-apollo';
import { Formik, Field, Form } from 'formik';
import gql from 'graphql-tag';
import * as Yup from 'yup';

import { useStylesAccount } from './accountStyle';

import { Tostcongigs } from './ProfilePage';
import { TextField } from '../../../components/fields';
import Toast from '../../../components/Toast';
import { AppContext } from '../../../contexts';
import { handleMutationError } from '../../../common/utility';

import {
  UpdateMentorBio,
  UpdateMentorBioVariables,
} from './__generated__/UpdateMentorBio';

const UPDATE_MENTOR_BIO = gql`
  mutation UpdateMentorBio($id: Int!, $bio: String) {
    updateMentor(input: { id: $id, bio: $bio }) {
      id
      bio
    }
  }
`;

const EditExperienceDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const classes = useStylesAccount();
  const { user, setUser } = useContext(AppContext);

  const [toast, setToast] = useState<Tostcongigs>({
    message: '',
    type: undefined,
  });
  const [openToast, setOpenToast] = useState(false);

  const FormSchema = Yup.object().shape({
    bio: Yup.string().required('Experience is Required'),
  });

  const handleClose = (resetForm: Function) => {
    resetForm();
    onClose();
  };

  return (
    <>
      <Toast
        open={openToast}
        close={() => {
          setOpenToast(false);
        }}
        {...toast}
      />
      <Mutation<UpdateMentorBio, UpdateMentorBioVariables>
        mutation={UPDATE_MENTOR_BIO}
      >
        {(updateMentor: Function) => {
          return (
            <Formik
              initialValues={{
                id: user?.id,
                bio: user?.bio,
              }}
              enableReinitialize
              validationSchema={FormSchema}
              onSubmit={async (variables, { setSubmitting }) => {
                try {
                  const { data } = await updateMentor({
                    variables: {
                      ...variables,
                    },
                  });

                  setUser({
                    ...user,
                    ...data?.updateMentor,
                  });
                  setToast({
                    message: 'User Updated!',
                    type: 'success',
                  });
                } catch (error: any) {
                  let errorMessage = 'Changes not saved!';

                  if (error?.message) {
                    handleMutationError(error, ({ message }) => {
                      if (message) errorMessage = message;
                    });
                  }
                  setToast({
                    message: errorMessage,
                    type: 'error',
                  });
                } finally {
                  setSubmitting(false);
                  setOpenToast(true);
                  onClose();
                }
              }}
            >
              {({ isSubmitting, handleSubmit, errors, resetForm, dirty }) => {
                const isError = !!Object.keys(errors).length;
                return (
                  <Form>
                    <Dialog
                      open={open}
                      onClose={() => handleClose(resetForm)}
                      className={clsx(
                        classes.educationDialog,
                        classes.experienceDialog
                      )}
                    >
                      <DialogContent>
                        <Typography variant="h4" className="experienceHeading">
                          Experience
                        </Typography>

                        <Grid className={classes.accountInformationForm}>
                          <Box className="styleTextField experienceField">
                            <Field
                              name="bio"
                              component={TextField}
                              label=""
                              rows="10"
                              rowsMax="10"
                              multiline
                              placeholder="Experience"
                            />
                          </Box>
                        </Grid>

                        <Box
                          pt={2.5}
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <Button
                            variant="outlined"
                            onClick={() => handleClose(resetForm)}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            disabled={isSubmitting || isError || !dirty}
                            onClick={() => handleSubmit()}
                          >
                            {isSubmitting ? 'Saving...' : 'Save'}
                          </Button>
                        </Box>
                      </DialogContent>
                    </Dialog>
                  </Form>
                );
              }}
            </Formik>
          );
        }}
      </Mutation>
    </>
  );
};

export default EditExperienceDialog;
