import moment from 'moment';
import _ from 'lodash';
import { getAdvisorMonthlyTImeSlotsQuery_getAdvisorMonthlyTImeSlots_timeslots } from '../gql/__generated__/getAdvisorMonthlyTImeSlotsQuery';
import { mentorTimeSlotsQuery_mentorTimeSlots_timeSlots_values_values } from '../gql/__generated__/mentorTimeSlotsQuery';

export const timeToDecimal = (t: string) => {
  let [hours, minutes]: string[] = t.split(':');
  const amOrpm = minutes.split(' ')[1];
  if (hours === '12' && amOrpm === 'AM') {
    hours = '0';
  }
  if (hours !== '12' && amOrpm === 'PM') {
    hours = +hours + 12 + '';
  }
  return +hours * 60 + +minutes.split(' ')[0];
};

export interface dayClickedType {
  day: string;
  dayValues:
    | (mentorTimeSlotsQuery_mentorTimeSlots_timeSlots_values_values | null)[]
    | null;
};

export const weekdays = () => {
  let startOfWeek = moment().startOf('week');
  let endOfWeek = moment().endOf('week');
  let days = [];
  let day = startOfWeek;

  while (day <= endOfWeek) {
    days.push(day.toDate());
    day = day.clone().add(1, 'd');
  }
  return days;
};

export const getTwentyFourHourTime = (time12h: any) => {
  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');
  if (modifier) {
    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  }
  if (hours === '24') {
    return `00:${minutes}`;
  }
  return time12h;
};

interface IRecurringDate {
  recurringStartDate: Date;
  recurringLastDate: Date;
}

export const groupingOfTimeSlots = (
  timeslots:
    | (getAdvisorMonthlyTImeSlotsQuery_getAdvisorMonthlyTImeSlots_timeslots | null)[]
    | null,
  recurringDates: (IRecurringDate | null)[],
  mentorTimeSlotOld: any[]
) => {
  if (timeslots?.length) {
    const groups: any = _.groupBy(timeslots, (slot) => {
      return moment(slot?.startDate).startOf('day').format();
    });

    const entries: any = Object.entries(groups);

    const newArray: {
      key: string;
      values: (mentorTimeSlotsQuery_mentorTimeSlots_timeSlots_values_values | null)[];
    }[] = entries.map((item: any) => ({
      key: item[0],
      values: item[1],
    }));

    if (mentorTimeSlotOld?.length) {
      let slots = [...mentorTimeSlotOld];

      slots.map((item) => {
        item.values?.map((v: any) => {
          const filtered: any = newArray.filter((x) => {
            if (moment(x.key).format('YYYY-MM-DD') === v?.key) return x;
          });
          let bookingsCount = 0;
          if (filtered && v?.values) {
            v.values = filtered[0]?.values || [];
            v.values?.map(
              (item: any) => (bookingsCount += item?.timeSlotBookings?.length)
            );
            v.bookingsCount = bookingsCount;
          }
        });
      });
      return slots;
    }
  }
  return mentorTimeSlotOld;
};