import { createStyles, makeStyles } from '@material-ui/core';
import colors from '../../../common/colors';
import fonts from '../../../common/fonts';

import SELECT_ARROW_ICON from '../../../img/select-arrow.svg';

export const useStylesTable = makeStyles((theme) =>
  createStyles({
    asyncSelectStyle: {
      '& .react-select-container': {
        borderRadius: 6,
        backgroundSize: 12,
        backgroundColor: '#fff',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center right 16px',
        backgroundImage: `url(${SELECT_ARROW_ICON})`,
        border: '1px solid #D4D4D4',
        paddingLeft: 15,

        '& svg': {
          display: 'none',
        },
      },
    },

    commonTableEntitlements: {
      '& thead': {
        '& tr': {
          '& th': {
            whiteSpace: 'nowrap',
            padding: '8px 8px 0px !important',

            '&:first-child': {
              paddingLeft: '26px !important',
            },
          },
        },
      },

      '& tbody': {
        '& tr': {
          '& td': {
            whiteSpace: 'nowrap',
            fontSize: '16px !important',
            color: '#525454 !important',
            fontFamily: fonts.INTER,
            letterSpacing: '-0.006em',
            padding: '5px 8px 5px 8px !important',

            '&:first-child': {
              paddingLeft: '26px !important',
            },
          },
        },
      },

      '& .saveButtonText': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: '0.018em',
        textTransform: 'uppercase',
        color: '#525454',
        marginRight: 22,
      },

      '& .iconSpacing': {
        marginRight: 18,
      },

      '& .capitalizeText': {
        textTransform: 'capitalize',
      },
    },

    commonTable: {
      width: '100%',

      [theme.breakpoints.down('md')]: {
        width: '100%',
        overflow: 'auto',
      },

      '& .avatarGroupWidth': {
        width: 72,
      },

      '& .mobileCollegeName': {
        width: '15ch',
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#525454',

        [theme.breakpoints.down('sm')]: {
          fontSize: 14,
          width: '17ch',
        },
      },

      '& .dueDatePadding': {
        [theme.breakpoints.down('sm')]: {
          paddingTop: '14px !Important',
        },
      },

      '& .paddingGenericCell': {
        paddingLeft: '8px !Important',
      },

      '& .linkAnchorTag': {
        fontSize: 14,
        color: '#2F80ED',
      },

      '& .MuiTable-root': {
        borderSpacing: '0px 9px',
        borderCollapse: 'separate',

        '& thead': {
          '& tr': {
            '& th': {
              fontWeight: 600,
              fontSize: 14,
              color: '#272929',
              border: 0,
              padding: '8px 8px 0px',
              letterSpacing: '0.018em',
              textTransform: 'uppercase',
              fontFamily: fonts.POPPINS_FONT,

              [theme.breakpoints.down('sm')]: {
                whiteSpace: 'nowrap',
              },

              '&:first-child': {
                paddingLeft: '55px',
              },
            },
          },
        },

        '& .multipleAdvisorSpacing': {
          paddingLeft: '112px !important',
        },

        '& .tdBoldText': {
          fontWeight: 600,
          fontSize: 18,
          color: '#525454',
          letterSpacing: '-0.014em',
        },

        '& tbody': {
          '& tr': {
            borderRadius: 15,
            boxShadow: '0px 2px 7px rgba(68, 68, 68, 0.07)',

            '& td': {
              height: 52,
              padding: '5px 8px 5px 22px',
              borderLeft: 0,
              borderRight: 0,
              fontSize: 16,
              color: '#272929',
              fontFamily: fonts.INTER,
              letterSpacing: '-0.011em',
              border: '1px solid #D4D4D4',
              background: colors.WHITE_ONE,

              [theme.breakpoints.down('sm')]: {
                whiteSpace: 'nowrap',
              },

              '&:first-child': {
                paddingLeft: 55,
                borderRadius: '15px 0px 0px 15px',
                borderLeft: '1px solid #D4D4D4',

                [theme.breakpoints.down('md')]: {
                  paddingLeft: 15,
                },
              },

              '&:last-child': {
                borderRadius: '0px 15px 15px 0px',
                borderRight: '1px solid #D4D4D4',
              },
            },
          },
        },

        '& td.notFoundCell': {
          border: '0px !important',
          borderRadius: '0px !Important',
          boxShadow: 'none !important',
          padding: '0px !important',
          backgroundColor: 'transparent !important',
        },

        '& .tableLinkText': {
          fontFamily: fonts.INTER,
          fontSize: 16,
          letterSpacing: '-0.011em',
          textDecorationLine: 'underline !important',
          color: '#2F80ED',
        },

        '& .acceptanceWidth': {
          width: '130px !important',
        },

        '& .buttonWidthDue': {
          width: '120px !important',
        },

        '& .sortingButtonWidth': {
          width: '130px !important',
        },

        '& .sortingUpcoming': {
          width: '165px !important',
        },

        '& .sortingMembership': {
          width: '135px !important',
        },

        '& .applicationStatus': {
          fontFamily: fonts.POPPINS_FONT,
          fontWeight: 600,
          fontSize: 14,
          letterSpacing: '0.006em',
          textTransform: 'uppercase',

          '& .completed': {
            color: '#45CE93',
          },

          '& .notStarted': {
            color: '#A9A9A9',
          },

          '& .inProgress': {
            color: '#007bff',
          },
        },

        '& .MuiTableCell-footer': {
          padding: 0,
          border: 0,

          '& .MuiTablePagination-caption': {
            fontSize: 16,
            letterSpacing: '-0.011em',
            color: '#7D7F7F',
          },
        },

        '& .MuiAvatarGroup-root': {
          marginRight: 13,
        },

        '& .theadAvatarSpacing': {
          paddingLeft: '7.1rem',
        },

        '& .tableLink': {
          fontSize: 16,
          color: '#2F80ED',
          letterSpacing: '-0.011em',
          textDecoration: 'underline !important',
        },

        '& .sortingButtonRole': {
          width: '85px !important',
        },

        '& .sortingButton': {
          width: 104,
          height: 25,
          padding: 5,
          borderRadius: 10,
          display: 'flex',
          alignItems: 'center',
          paddingLeft: 14,

          '& img': {
            marginLeft: 9,
          },

          '&:hover': {
            cursor: 'pointer',
            background: '#EAEAEA',
          },
        },

        '& .collegeLogo': {
          width: 34,
          height: 34,
          marginRight: 28,

          '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          },
        },

        '& .noRecordFound': {
          borderRadius: '15px !important',
        },
      },

      '& .userAvatar': {
        '& .MuiAvatar-root': {
          width: 36,
          height: 36,
          marginRight: 15,
        },

        '& a': {
          display: 'flex',
          alignItems: 'center',
          fontSize: 16,
          letterSpacing: '-0.011em',
          color: '#272929',
        },
      },
    },

    iconTextButton: {
      '&.MuiButton-root': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 16,
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        color: '#2F80ED',
        padding: '10px 20px',

        '& .roleText': {
          marginLeft: 5,
        },

        '& img': {
          marginRight: 15,
        },
      },
    },

    formActionButtons: {
      '& .MuiButton-root': {
        borderRadius: 10,
        fontWeight: 'bold',
        fontSize: 14,
        letterSpacing: '-0.006em',
        textTransform: 'capitalize',
        minWidth: 140,
        height: 40,
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .MuiButton-contained': {
        background: '#2F80ED',
        color: '#FFFFFF',
        minWidth: 140,
      },

      '& .MuiButton-outlined': {
        background: '#fff',
        border: '1px solid #D4D4D4',
        color: '#272929',
        minWidth: 122,
        marginRight: 12,
      },
    },

    emptyStates: {
      background: '#F4F4F4',
      borderRadius: '15px',
      display: 'flex',
      alignItems: 'center',
      padding: '22px 33px',
      boxShadow: 'none',

      [theme.breakpoints.down('xs')]: {
        padding: 16,
      },

      '& .MuiTypography-body1': {
        fontWeight: 400,
        fontSize: 16,
        color: '#7D7F7F',
        letterSpacing: '-0.011em',
        fontFamily: fonts.INTER,
      },
    },

    commonSelectMenu: {
      '& .MuiFormControl-root': {
        width: '100% !important',

        [theme.breakpoints.down('md')]: {
          width: '100% !important',
        },
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: 6,
        border: '1px solid #D4D4D4',
      },

      '& .MuiSelect-outlined.MuiSelect-outlined': {
        fontSize: 14,
        color: '#272929',
        padding: '11px 16px',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
        paddingRight: 30,
      },

      '& .MuiOutlinedInput-root': {
        borderRadius: 6,
        backgroundSize: 12,
        backgroundColor: '#fff',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center right 16px',
        backgroundImage: `url(${SELECT_ARROW_ICON})`,

        '& svg': {
          display: 'none',
        },
      },

      '& .Mui-disabled': {
        opacity: '0.5',
      },
    },

    formerAdmissionWrapper: {
      padding: '23px 0px',

      [theme.breakpoints.down('xs')]: {
        padding: '20px 0px',
      },
    },

    formerAdmissionOfficer: {
      paddingTop: 30,
      borderTop: '1px solid #eaeaea',

      '& .MuiTypography-h4': {
        fontWeight: 600,
        fontSize: 26,
        color: '#272929',
        marginBottom: 20,
        letterSpacing: '-0.019em',
        fontFamily: fonts.POPPINS_FONT,
      },
    },

    addNewCollege: {
      padding: '0px 0px 30px',

      [theme.breakpoints.down('xs')]: {
        padding: '16px 0px',
      },

      '& .MuiTypography-body1': {
        fontFamily: fonts.INTER,
        fontSize: 12,
        fontWeight: 400,
        color: '#A9A9A9',
        marginBottom: 6,
        letterSpacing: '-0.006em',
      },
    },

    collegeNameSelect: {
      '& .MuiInput-underline:before': {
        display: 'none',
      },

      '& .MuiFormLabel-root, & .css-1okebmr-indicatorSeparator': {
        display: 'none',
      },

      '& .MuiFormControl-root': {
        height: 41,
        borderRadius: 6,
        padding: '0px 0px 0px 16px',
        border: '1px solid #D4D4D4',
        width: '100% !important',

        [theme.breakpoints.down('md')]: {
          width: '100% !important',
        },

        '& .MuiTypography-body1': {
          fontSize: 14,
          color: '#272929',
          fontFamily: fonts.INTER,
          letterSpacing: '-0.006em',
          width: '97%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },

      '& .css-tlfecz-indicatorContainer, & .css-1gtu0rj-indicatorContainer': {
        width: 36,
        height: 36,
        position: 'relative',
        bottom: 3,
        backgroundSize: 12,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${SELECT_ARROW_ICON})`,

        '& svg': {
          display: 'none',
        },
      },

      '& .MuiInput-underline:after': {
        display: 'none',
      },

      '& .css-1df318c-Input': {
        marginTop: -4,
      },
    },

    saveText: {
      fontWeight: 600,
      fontSize: 12,
      color: '#525454',
      letterSpacing: '0.018em',
      textTransform: 'uppercase',
      fontFamily: fonts.POPPINS_FONT,
      marginRight: 22,
    },

    addmissionOfficer: {
      '& .MuiSelect-outlined.MuiSelect-outlined': {
        padding: '7px 16px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '80%',
      },

      '& .MuiFormControl-root, & .css-1hb7zxy-IndicatorsContainer': {
        height: 32,
      },

      '& .css-109onse-indicatorSeparator': {
        display: 'none',
      },

      '& .css-1df318c-Input': {
        position: 'relative',
        bottom: 24,
      },

      '& .tableSelectStyle': {
        '& .MuiInputBase-input': {
          position: 'relative',
          bottom: 5,
        },

        '& .MuiTypography-body1': {
          height: 32,
          lineHeight: '29px',
        },

        '& .css-1hb7zxy-IndicatorsContainer': {
          padding: '3px 0px 0px',
        },

        '& .css-1df318c-Input': {
          position: 'absolute !important',
          top: 0,
        },

        '& .css-tlfecz-indicatorContainer, & .css-1gtu0rj-indicatorContainer': {
          width: 32,
          height: 32,
          bottom: 2,
        },
      },

      '& .MuiFormControl-root': {
        [theme.breakpoints.down('md')]: {
          width: '280px !important',
        },

        [theme.breakpoints.down('xs')]: {
          width: '250px !important',
        },
      },
    },

    saveTextActions: {
      '& .MuiIconButton-root': {
        width: 34,
        height: 34,
        padding: 0,
      },
    },
  })
);
