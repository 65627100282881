import { useState } from 'react';
import {
  Dialog,
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
} from '@material-ui/core';
import { Spinner } from 'react-bootstrap';
import DELETE_ICON from '../../../img/delete-icon.svg';
import client from '../../../apollo';
import { DELETE_PERSONAL_ACCOUNT } from './gql';
import { removePersonalAccountStyle } from './styles';

const RemovePersonalAccountDialog = ({
  email_address,
  refetch,
}: {
  email_address: string;
  refetch: Function;
}) => {
  const classes = removePersonalAccountStyle();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await client.mutate({
        mutation: DELETE_PERSONAL_ACCOUNT,
        variables: {
          email_address,
        },
      });
      await refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log('Error::::', err);
    }
  };

  return (
    <Grid>
      <IconButton onClick={handleClickOpen}>
        <img src={DELETE_ICON} alt="delete" />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        className={classes.confirmationDialog}
      >
        <Box>
          <Box>
            <Typography variant="h4">Remove Calendar Connection?</Typography>
            <Typography variant="body1">
              This action cannot be undone.
            </Typography>
          </Box>

          <ul>
            <li>
              <Typography variant="body1">
                {email_address} will be disconnected from the portal.
              </Typography>
            </li>
          </ul>

          <Box display="flex" justifyContent="flex-start">
            <Button
              variant="contained"
              color="primary"
              className="dangerButton"
              onClick={handleDelete}
              startIcon={loading && <Spinner animation="border" size="sm" />}
              disabled={loading}
            >
              Remove
            </Button>

            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Keep Connection
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Grid>
  );
};

export default RemovePersonalAccountDialog;
