import gql from 'graphql-tag';

export const STUDENT_MENTOR_TIME_LOGS = gql`
  query MENTOR_TIME_LOGS(
    $StudentId: Int
    $page: Int
    $limit: Int
    $searchQuery: String
    $toActivityAt: Date
    $fromActivityAt: Date
    $MentorId: Int
    $status: String
    $isFlagged: Boolean
    $isMBA: Boolean
    $milestone: String
    $sortBy: String
    $sortType: String
  ) {
    allMentorTimeLogs(
      input: {
        page: $page
        limit: $limit
        StudentId: $StudentId
        searchQuery: $searchQuery
        toActivityAt: $toActivityAt
        fromActivityAt: $fromActivityAt
        MentorId: $MentorId
        status: $status
        isFlagged: $isFlagged
        isMBA: $isMBA
        milestone: $milestone
        sortBy: $sortBy
        sortType: $sortType
      }
    ) {
      mentorTimeLogs {
        id
        activityAt
        activityDescription
        workType
        activityDuration
        workActivity
        isFlagged
        flaggedReason
        note
        durationNote
        approvedDate
        createdAt
        airtableSubmitted
        missCall
        lateLogReason
        duplicateOrientationCallReason
        mentorPayout {
          id
          status
          amount
          errorMessage
          payout_id
        }
        mentor {
          id
          firstName
          lastName
          stripeAccountId
        }
        milestone
        serviceElement
        MentorTimeLogAssociations {
          id
          milestoneTeamName
          otherMeetingDescription
          noteForExtendedMeeting
          noteForNoOrientationCall
        }
      }
      nextRecord
      mentorTimeLogsCount
      mentorTimeLogsAllCount
      mentorTimeLogsAllCount
    }
  }
`;

export const GET_ALL_STUDENT_TIME_LOGS_MENTORS = gql`
  query ALL_STUDENT_TIME_LOGS_MENTORS($studentId: Int!) {
    getAllStudentTimeLogsMentors(studentId: $studentId) {
      id
      firstName
      lastName
      email
    }
  }
`;
