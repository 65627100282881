import {
  useState,
  MouseEvent,
  ChangeEvent,
  memo,
  useContext,
} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import momentTz from 'moment-timezone';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import LoopIcon from '@material-ui/icons/Loop';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import {
  Collapse,
  DialogContent,
  ListItemIcon,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
  Grid,
  Avatar,
  Divider,
  ListItemAvatar,
} from '@material-ui/core';

import TimeSlotForm from './TimeSlotForm';
import DeleteSeriesPopover from './DeleteSeriesPopover';
import CancelMeetingPopover from './CancelMeetingPopover';
import client from '../../../apollo';
import { dayClickedType, getTwentyFourHourTime } from './utils';
import Fonts from '../../../common/fonts';
import TimeSlotNotification from './TimeSlotNotification';
import { AppContext } from '../../../contexts';

import Close_Icon from '../../../img/close-dialog.svg';
import Plus_icon from '../../../img/plus-icon.svg';
import Caution_Img from '../../../img/caution-meeting.svg';
import Edit_Icon_Img from '../../../img/edit-icon.svg';
import Delete_Icon_Img from '../../../img/delete-icon.svg';

import { CREATE_TIME_SLOT, TIME_SLOT_MEETINGS } from './gql';
import { DELETE_BOOKING } from '../meetings/gql';
import { mentorTimeSlotsQuery_mentorTimeSlots_timeSlots_values_values } from './gql/__generated__/mentorTimeSlotsQuery';
import { allAdvisorBookingsQuery_allAdvisorBookings_advisorBookings } from '../meetings/gql/__generated__/allAdvisorBookingsQuery';
import {
  getBookingsForTimeSlotQuery,
  getBookingsForTimeSlotQuery_getBookingsForTimeSlot_bookings,
} from './gql/__generated__/getBookingsForTimeSlotQuery';
import { getBookingsForEditTimeSlotMutation_getBookingsForEditTimeSlot_bookings } from './gql/__generated__/getBookingsForEditTimeSlotMutation';
import { getAdvisorMonthlyTImeSlotsQuery_getAdvisorMonthlyTImeSlots_timeslots } from './gql/__generated__/getAdvisorMonthlyTImeSlotsQuery';
import { TabPanel } from '../timeLogs';

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},

    addInterval: {
      fontFamily: Fonts.POPPINS_FONT,
      fontWeight: 600,
      fontSize: 12,
      letterSpacing: '0.018em',
      padding: '0px !important',
      margin: '10px 0px 10px',

      '& img': {
        width: 16,
        marginRight: 15,
      },
    },

    popOverBoxListing: {
      border: '1px solid #D4D4D4',
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',
      borderRadius: 12,
      width: 500,
      padding: '21px 20px 25px',

      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },

      '& .MuiTypography-body1': {
        fontSize: 12,
        letterSpacing: '-0.006em',
        color: '#525454',
      },

      '& .MuiTypography-h5': {
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        color: '#FFCD4B',
        fontFamily: Fonts.POPPINS_FONT,
        marginBottom: 5,

        '& img': {
          marginRight: 10,
        },
      },

      '& .MuiDivider-root': {
        margin: '19px 0px 10px',
      },

      '& .MuiListItem-root': {
        padding: 0,
        marginBottom: 14,

        '&:last-child': {
          marginBottom: 0,
        },
      },

      '& .MuiListItemText-root': {
        [theme.breakpoints.down('xs')]: {
          width: 100,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
      },

      '& .MuiList-root': {
        paddingBottom: 0,
      },

      '& .MuiListItemAvatar-root': {
        minWidth: 30,
      },

      '& .MuiAvatar-root': {
        width: 19,
        height: 19,
        marginRight: 7,
      },

      '& .MuiBox-root': {
        marginTop: 20,

        '& .MuiButton-contained': {
          background: '#2F80ED',
          color: '#FFFFFF',
          marginLeft: 10,
          padding: '8px 15px',
        },

        '& .MuiButton-outlined': {
          border: '1px solid #D4D4D4',
          color: '#272929',
        },

        '& .MuiButtonBase-root': {
          fontWeight: 'bold',
          fontSize: 14,
          letterSpacing: '-0.006em',
          height: 32,
          borderRadius: 12,
          textTransform: 'capitalize',
        },
      },
    },

    dialogWidth: {
      '& .MuiDialog-paper': {
        background: '#FFFFFF',
        border: '1px solid #D4D4D4',
        borderRadius: 25,
      },

      '& .MuiDialogContent-root': {
        padding: '0px 50px 35px 50px',

        '& .react-swipeable-view-container': {
          '& div:first-child': {
            overflow: 'hidden !important',
          },
        },
      },

      '& .MuiDialog-paperWidthSm': {
        maxWidth: 470,
      },
    },

    tabsPosition: {
      '& .MuiTabs-fixed': {
        marginBottom: 40,
      },

      '& .MuiTab-root': {
        fontFamily: Fonts.POPPINS_FONT,
        fontWeight: 'normal',
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
        minWidth: 75,
        padding: 0,
        marginRight: 29,
        textTransform: 'capitalize',
        minHeight: 35,

        '&:last-child': {
          marginRight: 0,
        },

        '&.Mui-selected': {
          fontWeight: 600,
          color: '#525454',
        },
      },

      '& .MuiTabs-indicator': {
        backgroundColor: '#525454',
      },

      bookingsTab: {
        padding: 0,
      },
    },

    closeDialog: {
      margin: '20px 20px 0px 0px',
    },

    dialogTitle: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 19,

      '& .MuiTypography-h4': {
        fontFamily: Fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '-0.019em',
        color: '#272929',
        marginRight: 5,
      },
    },

    timeSlotsListing: {
      '& .MuiListItem-root': {
        padding: '12px 0px',
        borderBottom: '1px solid #D4D4D4',
      },

      '& .MuiListItemIcon-root': {
        width: 16,
        height: 16,
        backgroundColor: '#45CE93',
        borderRadius: 2,
        marginRight: 19,
      },

      '& .MuiListItem-container': {
        listStyleType: 'none',
      },

      '& .MuiTypography-body2': {
        fontFamily: Fonts.INTER,
        fontSize: 12,
        letterSpacing: '-0.006em',
        color: '#525454',
      },

      '& .MuiIconButton-root': {
        width: 30,
        height: 30,
        padding: 0,
      },
    },

    smallAvatar: {
      width: 19,
      height: 19,
      margin: '3px 21px 0px 0px',
    },
    smallAvatarEmpty: {
      width: 19,
      height: 19,
      margin: '3px 21px 0px 0px',
      borderRadius: '50%',
      backgroundColor: '#d4d4d4',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& svg': {
        width: '90%',
        height: '90%',
        objectFit: 'contain',
      },
    },

    bookingSlotLists: {
      '& .MuiListItem-root': {
        alignItems: 'flex-start',
      },

      '& .MuiTypography-h6': {
        fontFamily: Fonts.INTER,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#525454',
      },

      '& .MuiTypography-caption': {
        fontFamily: Fonts.INTER,
        fontSize: 12,
        letterSpacing: '-0.006em',
        color: '#A9A9A9',
      },
    },

    disabledBtn: {
      opacity: 0.4,
    },
  })
);

interface cancelMeetingInterface {
  id: number | null;
  AdvisorId: number | null;
}
export interface editFormValuesInterface {
  startDate: string;
  endDate: string;
  applyingDate: string;
  customDate: string;
}

const TimeSlotsDialog = ({
  open,
  onClose,
  dayClicked,
  refetch,
  refetchAdvisorMonthlySlots,
  currentDayTimeSlots,
  isEditable,
  month,
  allMonthTimeSlots,
  selectedAdvisorId,
}: {
  open: boolean;
  onClose: Function;
  dayClicked: dayClickedType;
  refetch?: Function;
  refetchAdvisorMonthlySlots: Function;
  currentDayTimeSlots: any;
  isEditable: boolean;
  month: any;
  allMonthTimeSlots:
  | (getAdvisorMonthlyTImeSlotsQuery_getAdvisorMonthlyTImeSlots_timeslots | null)[]
  | null;
  selectedAdvisorId: number | null;
}) => {
  const { user } = useContext(AppContext);
  const [show, setShow] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<
    HTMLButtonElement | HTMLLIElement | null
  >(null);
  const [seriesAnchorEl, setSeriesAnchorEl] = useState<
    HTMLButtonElement | HTMLLIElement | null
  >(null);
  const [editAnchorEl, setEditAnchorEl] = useState<
    HTMLButtonElement | HTMLLIElement | null
  >(null);
  const [cancelMeetingAnchorEl, setCancelMeetingAnchorEl] = useState<
    HTMLButtonElement | HTMLLIElement | null
  >(null);

  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [showTimeForm, setShowTimeForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [checked, setChecked] = useState(false);
  const [slot, setSlot] = useState<any>();
  const [reason, setReason] = useState<string>('');
  const [otherReason, setOtherReason] = useState<string>("");
  const [
    selectedTimeSlot,
    setSelectedTimeSlot,
  ] = useState<mentorTimeSlotsQuery_mentorTimeSlots_timeSlots_values_values | null>(
    null
  );
  const [meetingsToBeDeleted, setMeetingsToBeDeleted] = useState<
    | (getBookingsForTimeSlotQuery_getBookingsForTimeSlot_bookings | null)[]
    | null
  >(null);
  const [conflictedMeetingsonEdit, setConflictedMeetingsonEdit] = useState<
    | (getBookingsForEditTimeSlotMutation_getBookingsForEditTimeSlot_bookings | null)[]
    | null
  >(null);
  const [
    editFormValues,
    setEditFormValues,
  ] = useState<editFormValuesInterface | null>();

  const [startTimeValue, setStartTimeValue] = useState('');
  const [endTimeValue, setEndTimeValue] = useState('');
  const [wait, setWait] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [loadingMeetings, setLoadingMeetings] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [toast, setToast] = useState({ message: '' });
  const [cancelMeeting, setCancelMeeting] = useState<cancelMeetingInterface>({
    id: null,
    AdvisorId: null,
  });

  let BOOKINGS: (allAdvisorBookingsQuery_allAdvisorBookings_advisorBookings | null)[] = [];

  if (currentDayTimeSlots?.length)
    currentDayTimeSlots.map((item: any) =>
      item?.values.map((v: any) =>
        v?.timeSlotBookings?.map((t: any) => {
          BOOKINGS.push(t);
        })
      )
    );

  const classes = useStyles();
  const handleClose = () => {
    onClose();
    setSelectedTimeSlot(null);
    setShowEditForm('');
    setShowTimeForm(false);
    setTabValue(0);
  };
  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setShowEditForm('');
    setSelectedId(null);
    setEditAnchorEl(null);
    setTabValue(newValue);
  };
  const handleDialogClose = async () => {
    setShow(false);
    await setSelectedId(null);
    setShowTimeForm(false);
    setShowEditForm('');
  };

  let dayValues: any = [];
  month.map((week: any) => {
    week.values.map((days: any) => {
      const compare = dayClicked.day.localeCompare(days[Object.keys(days)[0]]);
      if (compare === 0) {
        dayValues = days.values;
      }
    });
  });

  const handleTimeSlotDelete = async (item: any, day: any) => {
    setWait(true);
    delete item.MentorId;
    delete item.__typename;
    delete item.startTime;
    delete item.endTime;
    delete item.recurring;
    delete item.timeSlotBookings;
    try {
      await client.mutate({
        variables: {
          timeSlots: [{ ...item, deleted: true }],
          meetingsToBeDelete: meetingsToBeDeleted?.length
            ? meetingsToBeDeleted.map((item) => item?.id)
            : null,
        },
        mutation: CREATE_TIME_SLOT,
      });
      setAnchorEl(null);
      await refetchAdvisorMonthlySlots();
      refetch && refetch();
      setWait(false);
      handlePopUpNotification('Availability Deleted');
      setLoadingMeetings(false);
    } catch (error) {
      console.log(error);
    }
  };

  let time = new Date();
  const handleNewInterval = () => {
    setSelectedId(null);
    setSelectedTimeSlot(null);
    setShowTimeForm((prev) => !prev);
    setShowEditForm('');

    time = new Date();
    let hh = time.getHours();
    let mm: any = time.getMinutes();
    if (mm < 30) {
      mm = 30;
    } else if (mm > 60 || mm > 30) {
      mm = '00';
      hh = hh + 1;
    } else {
      mm = mm;
    }

    let toMm: any = parseInt(mm) + 30;
    let toHh = hh;

    if (toMm < 30) {
      toMm = 30;
    } else if (toMm > 60 || toMm > 30) {
      toMm = '00';
      toHh = hh + 1;
    } else {
      toMm = toMm;
    }

    setStartTimeValue(`${hh}:${mm}`);
    setEndTimeValue(`${toHh}:${toMm}`);

    if (dayValues.length > 0) {
      const dayValuesLastIndex = dayValues.length - 1;
      const lastIndexDayValues = dayValues[dayValuesLastIndex];
      const lastIndexStartDate =
        lastIndexDayValues && lastIndexDayValues.startDate;
      const lastIndexEndDate = lastIndexDayValues && lastIndexDayValues.endDate;
      let currentStartDayTime = moment(lastIndexStartDate).format('hh:mm A');
      let currentEndDayTime = moment(lastIndexEndDate).format('hh:mm A');

      currentStartDayTime = getTwentyFourHourTime(currentStartDayTime);
      currentEndDayTime = getTwentyFourHourTime(currentEndDayTime);

      let [currentStartHours, currentStartMinutes] = currentStartDayTime.split(
        ':'
      );
      let [currentEndHours, currentEndMinutes] = currentEndDayTime.split(':');

      currentStartHours = currentEndHours === '24' ? '00' : currentEndHours;
      currentStartMinutes = currentEndMinutes;

      currentEndMinutes = parseInt(currentEndMinutes) + 30 === 30 ? '30' : '00';
      currentEndHours =
        currentEndMinutes === '00'
          ? (parseInt(currentEndHours) + 1).toString()
          : currentEndHours;
      if (currentEndHours === '24') {
        currentEndHours = '0';
      }

      if (parseInt(currentEndHours) < 10) {
        currentEndHours = `0${currentEndHours}`;
      }

      setStartTimeValue(`${currentStartHours}:${currentStartMinutes}`);
      setEndTimeValue(`${currentEndHours}:${currentEndMinutes}`);
    }
  };

  const handleClosePopOver = () => {
    setAnchorEl(null);
    setMeetingsToBeDeleted(null);
  };

  const handleClick = async (
    event: MouseEvent<HTMLButtonElement>,
    item: any,
    day: any
  ) => {
    setWait(true);
    let startTime = `${moment(item.startDate).format('LT')}`;
    let endTime = `${moment(item.endDate).format('LT')}`;
    delete item.MentorId;
    delete item.__typename;
    delete item.recurringLastDate;
    const target = event.currentTarget;
    if (
      item?.timeSlotBookings?.length &&
      item?.timeSlotBookings?.find(
        (
          b: getBookingsForEditTimeSlotMutation_getBookingsForEditTimeSlot_bookings
        ) => b?.AdvisorId === user?.id
      )
    ) {
      setLoadingMeetings(true);
      setAnchorEl(target);
    }
    const {
      data,
    }: {
      data: getBookingsForTimeSlotQuery;
    } = await client.query({
      query: TIME_SLOT_MEETINGS,
      variables: {
        timeSlotId: item.id,
        series: false,
      },
      fetchPolicy: 'network-only',
    });
    if (data?.getBookingsForTimeSlot?.bookings?.length) {
      !anchorEl && setAnchorEl(target);
      setSlot([{ ...item, startTime, endTime, day, deleted: true }]);
      setMeetingsToBeDeleted(data?.getBookingsForTimeSlot?.bookings || null);
      setLoadingMeetings(false);
      setWait(false);
    } else {
      handleTimeSlotDelete(
        { ...item, startTime, endTime, day, deleted: true },
        dayClicked.day
      );
    }
  };
  const handleSeriesClick = async (
    event: MouseEvent<HTMLButtonElement>,
    item: any,
    day: any
  ) => {
    let startTime = `${moment(item.startDate).format('LT')}`;
    let endTime = `${moment(item.endDate).format('LT')}`;
    delete item.MentorId;
    delete item.__typename;
    // delete item.timeSlotBookings;

    setSeriesAnchorEl(event.currentTarget);
    setSlot([{ ...item, startTime, endTime, day, deleted: true }]);
  };

  const handlePopUpNotification = (message: string) => {
    setToast({ message });
    setOpenToast(true);
  };
  const openPopover = Boolean(anchorEl);

  const handleCancelMeeting = async (reason: string, other: string) => {
    try {
      setWait(true);
      if (cancelMeeting.id && cancelMeeting.AdvisorId) {
        await client.mutate({
          mutation: DELETE_BOOKING,
          variables: {
            eventId: cancelMeeting.id,
            AdvisorId: cancelMeeting.AdvisorId,
            reason: reason,
            ...(other && {
              otherReason: other,
            }),
          },
        });
        setCancelMeetingAnchorEl(null);
        setCancelMeeting({ id: null, AdvisorId: null });
        setReason("")
        setOtherReason("")
        handlePopUpNotification('Meeting Canceled');
        await refetchAdvisorMonthlySlots();
        refetch && refetch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setWait(false);
    }
  };

  const handleConflictedMeetingsOnEdit = (values: {
    conflictedMeetings:
    | (getBookingsForTimeSlotQuery_getBookingsForTimeSlot_bookings | null)[]
    | null;
    formValues?: editFormValuesInterface;
    anchorEl: HTMLButtonElement | HTMLLIElement | null;
  }) => {
    setConflictedMeetingsonEdit(values.conflictedMeetings);
    setEditFormValues(values.formValues);
  };

  return (
    <>
      <TimeSlotNotification
        open={openToast}
        close={() => {
          setOpenToast(false);
        }}
        {...toast}
        autoHideDuration={3000}
      />

      <CancelMeetingPopover
        anchorEl={cancelMeetingAnchorEl}
        handleClosePopOver={() => {
          setCancelMeetingAnchorEl(null);
          setCancelMeeting({ id: null, AdvisorId: null });
          setReason("");
          setOtherReason('')
        }}
        wait={wait}
        handleDelete={handleCancelMeeting}
        bookingDetail={selectedRow}
        title="Cancel Meeting"
        reason={reason}
        setReason={setReason}
        setOtherReason={setOtherReason}
        otherReason={otherReason}
      />

      <Dialog className={classes.dialogWidth} onClose={handleClose} open={open}>
        <Box display="flex" justifyContent="flex-end" marginBottom={-3}>
          <IconButton
            onClick={() => {
              handleClose();
              setChecked(false);
            }}
            className={classes.closeDialog}
          >
            <img src={Close_Icon} alt="close" />
          </IconButton>
        </Box>

        <DialogContent>
          <Grid className={classes.dialogTitle}>
            <Typography variant="h4">
              {moment(dayClicked?.day).format('dddd MMMM D, YYYY')}
            </Typography>
          </Grid>

          {isEditable && (
            <Box>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="simple tabs example"
                indicatorColor="primary"
                textColor="primary"
                className={classes.tabsPosition}
              >
                <Tab label="Availability" {...a11yProps(0)} />
                <Tab label="Meetings" {...a11yProps(1)} />
              </Tabs>
            </Box>
          )}

          <SwipeableViews
            axis={'x-reverse'}
            index={tabValue}
            onChangeIndex={(index: number) => {
              setTabValue(index);
            }}
          >
            <TabPanel value={tabValue} index={0}>
              <>
                {dayClicked && dayValues && !!dayValues.length && (
                  <List dense={true} className={classes.root}>
                    {dayValues.map((item: any, index: number) => {
                      let startTime = `${moment(item.startDate).format('LT')}`;
                      let endTime = `${moment(item.endDate).format('LT')}`;
                      let overnight =
                        moment(dayClicked.day).format('DD-MM-YYYY') !==
                        moment(item.endDate).format('DD-MM-YYYY');

                      return (
                        <Box key={index} className={classes.timeSlotsListing}>
                          <ListItem disableGutters>
                            <ListItemIcon></ListItemIcon>
                            {item?.recurring && (
                              <LoopIcon fontSize="inherit" color="primary" />
                            )}
                            <ListItemText
                              primary={`${startTime} - ${endTime} ${momentTz(
                                item.startDate
                              )
                                .tz(momentTz.tz.guess())
                                .format('z')} ${overnight ? '(+1)' : ''}      
                              `}
                            />
                            {isEditable && !selectedAdvisorId && (
                              <>
                                <IconButton
                                  onClick={(e) => {
                                    setSelectedId(item.id);
                                    setSelectedTimeSlot(item);
                                    setShowEditForm(`showEditForm-${index}`);
                                    setShowTimeForm(false);
                                    setEditAnchorEl(e.currentTarget);

                                    if (
                                      showEditForm ===
                                      `showEditForm-${index}` &&
                                      selectedId === item.id
                                    ) {
                                      setShowEditForm('');
                                      setSelectedId(null);
                                      setEditAnchorEl(null);
                                    }
                                  }}
                                >
                                  <img src={Edit_Icon_Img} alt="icon" />
                                </IconButton>
                                <IconButton
                                  onClick={(e) => {
                                    if (!item?.recurring) {
                                      handleClick(e, item, dayClicked.day);
                                      setShow(false);
                                      setShowEditForm('');
                                    } else if (item?.recurring)
                                      handleSeriesClick(
                                        e,
                                        item,
                                        dayClicked.day
                                      );
                                  }}
                                  disabled={wait}
                                >
                                  <img src={Delete_Icon_Img} alt="icon" />
                                </IconButton>
                              </>
                            )}
                          </ListItem>

                          <Collapse
                            in={showEditForm === `showEditForm-${index}`}
                          >
                            <Box>
                              <TimeSlotForm
                                key={Date.now()}
                                startTimeValue={startTime}
                                endTimeValue={endTime}
                                editFormValues={editFormValues}
                                {...{
                                  refetch,
                                  refetchAdvisorMonthlySlots,
                                  dayClicked,
                                  dayValues,
                                  currentDayTimeSlots,
                                  selectedId,
                                  handlePopUpNotification,
                                  handleConflictedMeetingsOnEdit,
                                  editAnchorEl,
                                  conflictedMeetingsonEdit,
                                  selectedTimeSlot,
                                  allMonthTimeSlots,
                                  onClose: () => {
                                    handleDialogClose();
                                    setEditAnchorEl(null);
                                  },
                                }}
                              />
                            </Box>
                          </Collapse>
                        </Box>
                      );
                    })}
                  </List>
                )}

                {isEditable && !selectedAdvisorId && (
                  <Box paddingBottom={1}>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={handleNewInterval}
                      className={classes.addInterval}
                    >
                      <img src={Plus_icon} alt="add" /> ADD NEW OPENING
                    </Button>
                  </Box>
                )}

                <Collapse in={showTimeForm}>
                  <Box paddingBottom={1}>
                    <TimeSlotForm
                      key={Date.now()}
                      startTimeValue={startTimeValue}
                      endTimeValue={endTimeValue}
                      editFormValues={editFormValues}
                      {...{
                        refetchAdvisorMonthlySlots,
                        refetch,
                        dayClicked,
                        currentDayTimeSlots,
                        selectedId,
                        handlePopUpNotification,
                        selectedTimeSlot,
                        dayValues,
                        handleConflictedMeetingsOnEdit,
                        editAnchorEl,
                        allMonthTimeSlots,
                        conflictedMeetingsonEdit,
                        onClose: () => {
                          handleDialogClose();
                          setEditAnchorEl(null);
                        },
                      }}
                    />
                  </Box>
                </Collapse>

                <DeleteSeriesPopover
                  anchorEl={seriesAnchorEl}
                  day={dayClicked.day}
                  setAnchorEl={setSeriesAnchorEl}
                  refetch={refetch}
                  refetchAdvisorMonthlySlots={refetchAdvisorMonthlySlots}
                  slot={slot}
                  handlePopUpNotification={handlePopUpNotification}
                />
                <Popover
                  id="delete-popover"
                  open={openPopover}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <Box className={classes.popOverBoxListing}>
                    {loadingMeetings ? (
                      <Box textAlign="center">
                        Loading...
                        <CircularProgress color="secondary" size={25} />
                      </Box>
                    ) : (
                      <>
                        {meetingsToBeDeleted?.length ? (
                          <>
                            <Typography variant="h5">
                              <img src={Caution_Img} alt="caution" />
                              MEETING CONFLICT
                            </Typography>
                            <Typography variant="body1">
                              You have {meetingsToBeDeleted?.length} meetings
                              booked within this series.
                            </Typography>
                            <Divider />
                            <List>
                              {meetingsToBeDeleted.map((item) => {
                                const startDate = item?.startTime
                                  ? moment
                                    .unix(item?.startTime)
                                    .format('MM/DD/YYYY')
                                  : 'N/A';
                                const startTime = item?.startTime
                                  ? moment.unix(item?.startTime).format('LT')
                                  : 'NA';
                                const endTime = item?.endTime
                                  ? moment.unix(item?.endTime).format('LT')
                                  : 'NA';

                                return (
                                  <ListItem>
                                    <ListItemAvatar>
                                      {item?.student?.pictureUrl ? (
                                        <Avatar>
                                          <img
                                            src={item?.student?.pictureUrl}
                                            alt="avatar"
                                          />
                                        </Avatar>
                                      ) : (
                                        <PersonIcon />
                                      )}
                                    </ListItemAvatar>
                                    <ListItemText>
                                      {item?.student?.firstName || ''}{' '}
                                      {item?.student?.lastName || ''}
                                    </ListItemText>
                                    <ListItemSecondaryAction>
                                      <ListItemText>
                                        {`${startDate}, ${startTime} - ${endTime} ${momentTz(
                                          (item?.startTime || 0) * 1000
                                        )
                                          .tz(momentTz.tz.guess())
                                          .format('z')}`}
                                      </ListItemText>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                );
                              })}
                            </List>
                            <Divider />
                          </>
                        ) : (
                          <>
                            <Typography variant="h5">
                              <img src={Caution_Img} alt="caution" />
                              CONFIRM MEETING DELETE?
                            </Typography>
                            <Typography variant="body1">
                              This action cannot be undone.
                            </Typography>
                          </>
                        )}

                        <Box textAlign="right">
                          <Button
                            variant="outlined"
                            onClick={handleClosePopOver}
                          >
                            Keep All
                          </Button>

                          <Button
                            variant="contained"
                            onClick={() =>
                              handleTimeSlotDelete(slot[0], dayClicked.day)
                            }
                            disabled={wait}
                          >
                            {!wait &&
                              `Cancel Meeting${!!meetingsToBeDeleted?.length &&
                                meetingsToBeDeleted.length > 1
                                ? 's'
                                : ''
                              } & Availability`}
                            {wait && (
                              <>
                                {'Canceling...'}
                                <CircularProgress color="secondary" size={17} />
                              </>
                            )}
                          </Button>
                        </Box>
                      </>
                    )}
                  </Box>
                </Popover>
              </>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              {BOOKINGS?.length ? (
                BOOKINGS.map((row, index) => {
                  const student = row?.student;
                  return (
                    <Box
                      key={index}
                      className={clsx(
                        classes.timeSlotsListing,
                        classes.bookingSlotLists
                      )}
                    >
                      <ListItem disableGutters>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Box display="flex">
                            {student?.pictureUrl ? (
                              <Avatar
                                alt="avatar"
                                src={student.pictureUrl}
                                className={classes.smallAvatar}
                              />
                            ) : (
                              <PersonIcon
                                className={classes.smallAvatarEmpty}
                              />
                            )}

                            <Box>
                              <Typography variant="h6">
                                {`${row?.startTime
                                  ? moment(row?.startTime * 1000).format('LT')
                                  : 'NA'
                                  } - ${row?.endTime
                                    ? moment(row?.endTime * 1000).format('LT')
                                    : 'NA'
                                  } ${momentTz((row?.startTime || 0) * 1000)
                                    .tz(momentTz.tz.guess())
                                    .format('z')}
                           `}
                              </Typography>
                              <Typography variant="caption">
                                {student?.firstName
                                  ? `${student?.firstName} ${student?.lastName}`
                                  : '--'}
                              </Typography>
                            </Box>
                          </Box>

                          <IconButton
                            className={
                              row?.AdvisorId !== user?.id
                                ? classes.disabledBtn
                                : ''
                            }
                            disabled={row?.AdvisorId !== user?.id}
                            onClick={(e) => {
                              setCancelMeeting({
                                id: row?.id || null,
                                AdvisorId: row?.AdvisorId || null,
                              });
                              row && setSelectedRow(row)
                              setCancelMeetingAnchorEl(e.currentTarget);
                            }}
                          >
                            <img src={Delete_Icon_Img} alt="icon" />
                          </IconButton>
                        </Box>
                      </ListItem>
                    </Box>
                  );
                })
              ) : (
                <h6>No Meetings</h6>
              )}
            </TabPanel>
          </SwipeableViews>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(TimeSlotsDialog);
