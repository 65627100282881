import { FC, Fragment } from 'react';
import { Typography, Box, Grid } from '@material-ui/core';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import Moment from 'moment';

import { useStyles } from './MyStudentsStyle';
import { StudentDetailQuery_Student_parents } from './__generated__/StudentDetailQuery';

interface Milestones {
  title: string;
  parents: (StudentDetailQuery_Student_parents | null)[] | null;
  formToken: string;
  studentIntakeResponse: any;
  kickoffDate: string | null;
  isMBAGrad: boolean;
}
interface TParams {
  id: string;
}

type MilestoneProps = RouteComponentProps<TParams> & Milestones;
const Milestones: FC<MilestoneProps> = ({
  title,
  studentIntakeResponse,
  kickoffDate,
  match,
  formToken,
  parents,
  isMBAGrad = false,
}) => {
  const classes = useStyles();
  const { step: stepStudent } = studentIntakeResponse || {};
  const { id: studentID } = match.params;

  return (
    <>
      <Typography variant="h4" className={classes.studentSectionHeader}>
        {title}
      </Typography>

      <Grid className={classes.milestoneFeature}>
        <Box display="flex" alignItems="center">
          <Typography variant="h6">Kickoff Call:</Typography>

          <Typography variant="body2">
            {' '}
            {Moment(kickoffDate).isAfter(Moment(new Date())) ? (
              <span className="started">Not Started</span>
            ) : (
              <span className="inProgress">Started</span>
            )}
          </Typography>
        </Box>

        {!isMBAGrad && (
          <Fragment>
            <Box display="flex" alignItems="center">
              <Typography variant="h6">STUDENT INTAKE FORM:</Typography>

              <Typography variant="body2">
                {stepStudent >= 3 ? (
                  <span className="completed">Completed</span>
                ) : stepStudent >= 1 ? (
                  <span className="inProgress">In Progress</span>
                ) : (
                  <span className="notStarted">Not started</span>
                )}
                {stepStudent >= 1 && (
                  <Link
                    to={`/students/student-details/${studentID}/intakeForm/${formToken}`}
                  >
                    View
                  </Link>
                )}
              </Typography>
            </Box>
            <Typography variant="h6">PARENT INTAKE FORM:</Typography>

            <Box pt={1}>
              {parents?.map((parent) => (
                <Box display="flex" alignItems="center">
                  <Typography variant="h6" className="guardianType">
                    {parent?.firstName} {parent?.lastName}
                  </Typography>

                  <Typography variant="body2">
                    {parent?.intakeResponse &&
                    JSON.parse(parent?.intakeResponse).step >= 3 ? (
                      <span className="completed">Completed</span>
                    ) : parent?.intakeResponse &&
                      JSON.parse(parent?.intakeResponse).step >= 1 ? (
                      <span className="inProgress">In Progress</span>
                    ) : (
                      <span className="notStarted">Not Started</span>
                    )}
                    {parent?.intakeResponse &&
                      JSON.parse(parent?.intakeResponse).step >= 1 && (
                        <Link
                          to={`/students/student-details/${studentID}/intakeForm/${parent?.intakeFormToken}`}
                        >
                          View
                        </Link>
                      )}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Fragment>
        )}
      </Grid>
    </>
  );
};

export default withRouter(Milestones);
