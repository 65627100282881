import { FC } from 'react';
import Dropdown, { OptionType } from '../../../components/multiSelect';
import { zones } from '../../../components/TimeZonePicker';

interface PropsType {
  getValue: Function;
  value?: OptionType | OptionType[] | null;
  isMultiSelect?: boolean;
  label?: string;
  id?: number | null;
  disabled?: boolean;
}

const SelectTimeZone: FC<PropsType> = ({
  getValue,
  value,
  isMultiSelect,
  label,
  id,
  disabled,
}) => {
  let zoneArray: any = [];
  zones.forEach((val) => {
    zoneArray.push({ label: val, value: val });
  });
  return (
    <>
      <Dropdown
        label={label || ''}
        suggestionsList={zoneArray}
        getValue={getValue}
        disabled={disabled}
        value={value}
        isMultiSelect={!!isMultiSelect}
        id={id}
      />
    </>
  );
};

export default SelectTimeZone;
