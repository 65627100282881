import Fonts from '../../../common/fonts';
import Colors from '../../../common/colors';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import fonts from '../../../common/fonts';
import colors from '../../../common/colors';
import SEARCH_ICON from '../../../img/search-icon-async.svg';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    collapseUp: {
      transform: 'rotate(180deg)',
    },
    alertInfoCard: {
      background: colors.WHITE_EIGHT,
      borderRadius: 15,
      padding: '10px 20px',
      boxShadow: 'none',

      '@media only screen and (max-width: 767px)': {
        padding: 15,
        margin: '15px 0px',
      },

      '& img': {
        marginRight: 30,
        position: 'relative',
        bottom: 4,
      },

      '& .MuiTypography-h5, .MuiButton-root': {
        fontFamily: 'Poppins',
        fontSize: 14,
        letterSpacing: '-0.006em',
      },

      '& .MuiTypography-h5': {
        fontWeight: 600,
        color: colors.BLACK_TWELVE,
      },

      '& .MuiTypography-body1': {
        fontSize: 14,
        color: colors.BLACK_TEN,
        margin: '4px 0px 12px',
        letterSpacing: '-0.006em',
        fontFamily: fonts.INTER,
      },

      '& .MuiButton-root': {
        background: colors.BLUE_SEVEN,
        borderRadius: 12,
        color: colors.WHITE_ONE,
        padding: '8px 15px',
        textTransform: 'capitalize',
      },
    },
    collapseButton: {
      padding: 0,
      // width: 17,
      // height: 17,

      '& img': {
        width: 10,
      },
    },

    tableEmptyGrid: {
      fontSize: 12,
      fontWeight: 400,
      color: '#525454',
      fontFamily: fonts.INTER,
      padding: '25px 20px !important',
      letterSpacing: '-0.072px',
      borderRadius: '10px !important',
      backgroundColor: '#F4F4F4 !important',
    },

    collapseContent: {
      '& .textHeading': {
        minWidth: 137,
        marginRight: 7,
        whiteSpace: 'nowrap',
      },

      '& .milestoneText': {
        width: '44%',

        [theme.breakpoints.down('sm')]: {
          minWidth: 144,
          width: 'initial',
        },
      },
    },

    timeLogsTable: {
      marginTop: 32,

      '& .description': {
        maxWidth: 871,
        margin: '18px 0px 26px',
        textAlign: 'inherit !important',
      },

      '& .tableCellHead': {
        border: 0,
        fontSize: 12,
        fontWeight: 600,
        color: '#272929',
        textTransform: 'uppercase',
        padding: '0px 0px 20px 32px',
        fontFamily: Fonts.POPPINS_FONT,
      },

      '& .tableCellBody': {
        border: 0,
        fontSize: 12,
        color: '#272929',
        fontFamily: fonts.INTER,
        textTransform: 'capitalize',
        padding: '14px 10px 14px 32px',

        '&:last-of-type': {
          paddingRight: 32,
          textAlign: 'right',
        },
      },

      '& .tableCellFirst': {
        width: '10%',

        [theme.breakpoints.down('sm')]: {
          minWidth: 200,
        },
      },

      '& .tableCellSecond': {
        width: '15%',

        [theme.breakpoints.down('sm')]: {
          minWidth: 200,
        },
      },

      '& .tableCellThird': {
        width: '20%',
        [theme.breakpoints.down('sm')]: {
          minWidth: 200,
        },
      },

      '& .tableCellFour': {
        width: '10%',
        [theme.breakpoints.down('sm')]: {
          minWidth: 200,
        },
      },

      '& .tableCellFive': {
        width: '20%',
        [theme.breakpoints.down('sm')]: {
          minWidth: 200,
        },
      },

      '& .tableCellSix': {
        width: '15%',
        fontSize: 12,
        color: '#A9A9A9',
        paddingLeft: '0px !important',

        [theme.breakpoints.down('sm')]: {
          minWidth: 200,
        },
      },

      '& .tableCellSeven': {
        width: '10%',
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
          minWidth: 200,
        },
      },

      '& .timeText': {
        color: '#272929',

        '& span': {
          fontSize: 12,
          fontWeight: 700,
          color: '#272929',
          letterSpacing: '-0.006em',
        },

        '& small': {
          fontWeight: 400,
          paddingLeft: 1,
        },
      },

      '& .MuiTypography-body1': {
        fontSize: 12,
        fontWeight: 400,
        color: '#7D7F7F',
        letterSpacing: '-0.072px',

        '& span': {
          fontSize: 12,
          fontWeight: 600,
          color: '#7D7F7F',
          fontFamily: fonts.INTER,
          letterSpacing: '-0.072px',
        },
      },
    },

    timeslotsContainer: {
      maxWidth: '1280px',
      marginBottom: 50,
      padding: 0,

      '& .timeZoneText': {
        display: 'flex',
        justifyContent: 'flex-end',
        flex: '1',

        '& .MuiTypography-body1': {
          color: '#afafaf',
          fontFamily: `${Fonts.POPPINS_FONT}`,
        },
      },

      [theme.breakpoints.down('md')]: {
        padding: '0px 15px',
      },

      '& .MuiTypography-h3': {
        fontFamily: Fonts.POPPINS_FONT,
        fontWeight: 'bold',
        fontSize: 36,
        letterSpacing: '-0.022em',
        color: '#272929',
      },
    },

    connectCalendar: {
      '&.MuiButton-root': {
        borderRadius: 12,
        fontSize: 14,
        fontWeight: 'bold',
        minWidth: 180,
        padding: '12px 13px',
        textTransform: 'capitalize',
        color: Colors.WHITE_ONE,
        backgroundColor: Colors.BLUE_SEVEN,
      },
    },

    advisorSelectDropDown: {
      maxWidth: 344,
      margin: '25px 0px 35px',

      '& .react-select__control': {
        borderColor: '#D4D4D4',
        borderRadius: 6,
        padding: '3px 16px 3px 40px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center left 16px',
        backgroundImage: `url(${SEARCH_ICON})`,
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        fontFamily: fonts.INTER,
      },

      '& .react-select__indicator, & .react-select__indicator-separator': {
        display: 'none',
      },

      '& .react-select__placeholder': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#A9A9A9',
        fontFamily: fonts.INTER,
      },

      '& .react-select__value-container': {
        padding: 0,
      },

      '& .react-select__menu': {
        overflow: 'hidden',
        margin: 0,
        borderRadius: '0px 0px 6px 6px',
        background: '#ffffff',
        zIndex: 999999,
      },

      '& .react-select__option': {
        paddingLeft: 40,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 'normal',
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#525454',
        fontFamily: fonts.INTER,

        '&:hover': {
          background: '#F4F4F4',
          cursor: 'pointer',
        },
      },

      '& .react-select__option--is-selected': {
        background: '#F4F4F4',
      },

      '& .react-select__menu-list': {
        border: '1px solid #d4d4d4',
        borderTop: 0,
        borderRadius: '0px 0px 6px 6px',
        padding: 0,
      },
    },

    selectAdvisor: {
      margin: '12px 0px 34px',

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },

      '& .MuiTypography-body1': {
        color: '#525454',
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.011em',
        margin: '0px 15px 0px 12px',
        fontFamily: fonts.INTER,
      },

      '& .MuiAvatar-root': {
        width: 28,
        height: 28,
      },

      '& button': {
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#2F80ED',
        fontFamily: fonts.INTER,
        background: 'transparent',
        border: 0,
        padding: 0,
        borderBottom: '1px solid #2F80ED',
        lineHeight: '16px',
      },
    },

    copyBookingLinkDark: {
      '&.MuiButton-root': {
        minWidth: 216,
        height: 40,
        backgroundColor: '#1E5298',
        borderRadius: 12,
        letterSpacing: '-0.006em',
        color: '#FFFFFF',
        textTransform: 'capitalize',
        padding: '8px 15px',
        boxShadow: 'none',
        fontFamily: fonts.POPPINS_FONT,
        opacity: 1,

        '&:hover': {
          backgroundColor: '#2668C1 !important',
        },

        '& .MuiButton-label': {
          fontWeight: 700,
          fontSize: 14,
        },

        '& img': {
          marginRight: 11,
        },
      },
    },

    copyBookingLink: {
      '&.MuiButton-root': {
        minWidth: 216,
        height: 40,
        background: '#2F80ED',
        borderRadius: 12,
        letterSpacing: '-0.006em',
        color: '#FFFFFF',
        textTransform: 'capitalize',
        padding: '8px 15px',
        boxShadow: 'none',
        fontFamily: fonts.POPPINS_FONT,

        [theme.breakpoints.down('xs')]: {
          marginTop: 20,
        },

        '&:hover': {
          backgroundColor: '#2668C1 !important',
        },

        '& .MuiButton-label': {
          fontWeight: 700,
          fontSize: 14,
        },

        '& img': {
          marginRight: 11,
        },
      },
    },

    bookingBtn: {
      marginLeft: '20px',
      [theme.breakpoints.down('xs')]: {
        marginLeft: '0px',
      },
    },

    checkBox: {
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '& .MuiSvgIcon-root': {
        color: Colors.GRAY_CHECKBOX_BG,
      },
    },

    checkBoxText: {
      fontFamily: fonts.INTER,
      fontWeight: 400,
      fontSize: 16,
      color: Colors.GRAY_CHECKBOX_BG,
      fontStyle: 'normal',
      letterSpacing: '-0.011em',
    },
  })
);
