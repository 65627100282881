import { ApolloConsumer } from 'react-apollo';
import { OptionType } from '../multiSelect';
import SearchTagsAsync from './SearchTagsAsync';

const SearchTags = ({
  onSelect,
  isMulti,
  values,
  label,
  isClearable,
  disabled,
  placeholder,
}: {
  label?: string;
  onSelect: Function;
  values: OptionType[] | [] | any;
  isMulti?: boolean;
  isClearable?: boolean;
  disabled?: boolean;
  placeholder?: string;
}) => {
  return (
    <>
      <ApolloConsumer>
        {(client) => (
          <SearchTagsAsync
            isMulit={isMulti}
            isClearable={isClearable ? true : false}
            values={values}
            label={label}
            setSelectedValue={onSelect}
            client={client}
            disabled={disabled ? true : false}
          />
        )}
      </ApolloConsumer>
    </>
  );
};

export default SearchTags;
