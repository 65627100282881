import { Fragment, useContext, useState, FC, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';

import {
  Grid,
  Box,
  Typography,
  Container,
  Button,
  Card,
  Avatar,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { Color } from '@material-ui/lab/Alert';
import AsyncSelect from 'react-select/async';

import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import styled from 'styled-components';
import { Badge } from 'react-bootstrap';

import UploadFileForm from './UploadFile';
import SelectTimeZone from './SelectTimeZone';
import { useStylesAccount } from './accountStyle';
import Toast from '../../../components/Toast';
import { TextField } from '../../../components/fields';
import AccountMenu from '../../../components/AccountMenu';
import Header from '../../../components/layout/Header';
import FooterNew from '../../../components/layout/FooterNew';
import CustomTooltip from '../../../components/CustomTooltip';
import { OptionType } from '../../../components/multiSelect';
import FormikDropdown from '../../../components/FormikDropdown';
import colors from '../../../common/colors';

import { AppContext } from '../../../contexts';
import {
  convertTimeZoneForDB,
  handleMutationError,
} from '../../../common/utility';

import {
  UpdateMentorMutationProfile,
  UpdateMentorMutationProfileVariables,
} from './__generated__/UpdateMentorMutationProfile';
import client from '../../../apollo';
import LINK_ICON from '../../../img/link-copy.png';
import LINK_COPIED_ICON from '../../../img/link-copied-icon.png';
import { PRONOUNS_ARRAY } from '../../../constants';
import { ALL_LANGUAGES } from '../../../graphql/queries/advisorProfile';

const UPDATE_MENTOR_MUTATION = gql`
  mutation UpdateMentorMutationProfile(
    $id: Int!
    $email: String
    $firstName: String
    $lastName: String
    $phoneNumber: String
    # $intakeYear: String
    # $graduateSchoolGradYear: String
    $pictureUrl: String
    $gpa: String
    # $major: String
    # $degree: String
    # $bio: String
    # $CollegeId: Int
    # $graduateCollegeId: Int
    $MentorCollegeIds: [Int]
    # $MentorSkillIds: [Int]
    $isAcceptedTo: Boolean
    # $calendlyLink: String
    $isActive: Boolean
    $personalEmail: String
    $gender: Gender
    $timezone: String
    $zoomLink: String
    $pronoun: String
    $languageIds: [Int]
  ) {
    updateMentor(
      input: {
        id: $id
        email: $email
        firstName: $firstName
        lastName: $lastName
        phoneNumber: $phoneNumber
        # intakeYear: $intakeYear
        # graduateSchoolGradYear: $graduateSchoolGradYear
        pictureUrl: $pictureUrl
        gpa: $gpa
        # major: $major
        # degree: $degree
        # bio: $bio
        # CollegeId: $CollegeId
        # graduateCollegeId: $graduateCollegeId
        MentorCollegeIds: $MentorCollegeIds
        # MentorSkillIds: $MentorSkillIds
        isAcceptedTo: $isAcceptedTo
        # calendlyLink: $calendlyLink
        isActive: $isActive
        personalEmail: $personalEmail
        gender: $gender
        timezone: $timezone
        zoomLink: $zoomLink
        pronoun: $pronoun
        languageIds: $languageIds
      }
    ) {
      id
      firstName
      lastName
      email
      personalEmail
      # bio
      phoneNumber
      # intakeYear
      # graduateSchoolGradYear
      gpa
      pronoun
      # major
      # degree
      # graduateSchool {
      #   name
      #   id
      # }
      # currentCollege {
      #   name
      #   id
      # }
      # colleges {
      #   name
      #   id
      # }
      gender
      # bio
      pictureUrl
      isAcceptedTo
      # calendlyLink
      isActive
      timezone
      zoomLink
    }
  }
`;

interface zone_array {
  label: string;
  value: string;
}

const GENDER_ARRAY = ['Male', 'Female', 'Other'];

export interface Tostcongigs {
  message: string;
  type: Color | undefined;
}

const ProfileLogo = styled(Badge)`
  width: 124px;
  height: 124px;
  background-color: ${colors.WHITE_ONE};
  border-radius: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-right: 16px;

  @media only screen and (max-width: 767px) {
    width: 124px;
    height: 124px;
    margin: 0px;
  }
`;

const httpsProtocolRegex = /(http(s?)):\/\//i;

export const regexZoomLink =
  /^\s*((ftp|http|https):\/\/)((([\w#]+\.)?zoom\.us)|(([w]{3}\.)?meet\.google.com))+((\/)[-\w#_.-=?]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\s*?$/gm;
export const regexNoSpace =
  /^[a-zA-Z-!@#$&'()\\-`.+,/\"]+( [a-zA-Z!@#$&'()\\-`.+,/\"]+)*$/;

const AccountInformation: FC = () => {
  const classes = useStylesAccount();
  const { user, setUser } = useContext(AppContext);

  const [file, setFile] = useState<any>();
  const [selectedLanguages, setSelectedLanguages] = useState<any>([]);
  const picUrl = user && user.pictureUrl;
  const isAcceptedTo = user && user.isAcceptedTo;

  const [pictureUrl, setPictureUrl] = useState(picUrl);
  const [enableBtn, toggleBtn] = useState(isAcceptedTo);
  const [isCopied, setIsCopied] = useState(false);

  const [state, setState] = useState({
    checkedA: true,
    pictureUrl,
  });

  const [toast, setToast] = useState<Tostcongigs>({
    message: '',
    type: undefined,
  });

  const [openToast, setOpenToast] = useState(false);
  const FormSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(
        regexNoSpace,
        'Name may only contain letters, commas, apostrophes, and dashes.'
      )
      .required('First Name is Required')
      .nullable(),
    lastName: Yup.string()
      .matches(
        regexNoSpace,
        'Name may only contain letters, commas, apostrophes, and dashes.'
      )
      .required('Last Name is Required')
      .nullable(),
    phoneNumber: Yup.string().required('Required'),
    zoomLink: Yup.string()
      .matches(
        httpsProtocolRegex,
        'Please add https:// to your video chat link.'
      )
      .matches(regexZoomLink, 'Invalid Link')
      .nullable(),
    gpa: Yup.number().typeError('It should be a number'),
  });

  const loadOptions: any = (inputValue: string) => {
    return new Promise((resolve) => {
      // API call
      client
        .query({
          query: ALL_LANGUAGES,
        })
        .then((res) => {
          if (res.data && res.data.getAllLanguages?.length) {
            const options = res.data.getAllLanguages.map(
              (item: { id: number; name: string }) => {
                return {
                  value: item.id,
                  label: item.name,
                };
              }
            );
            if (inputValue.length) {
              const filteredOptions = options.filter(
                (item: { value: number; label: string }) =>
                  item.label.toLowerCase().includes(inputValue.toLowerCase())
              );
              resolve(filteredOptions);
            }
            resolve(options);
          } else {
            resolve([]);
          }
        });
    });
  };

  const handleChangeLanguage = (
    selectedOptions: any,
    setFieldValue: Function
  ) => {
    setSelectedLanguages(selectedOptions);
    const selectedIds = selectedOptions
      ? selectedOptions.map((option: { value: number }) => option.value)
      : [];
    setFieldValue('languageIds', selectedIds);
  };
  const fetchAdvisorLanguages = async () => {
    try {
      const res = await client.query({
        query: ALL_LANGUAGES,
        variables: { UserId: user?.id },
      });

      if (res.data && res.data.getAllLanguages?.length) {
        const options = res.data.getAllLanguages.map(
          (item: { id: number; name: string }) => ({
            value: item.id,
            label: item.name,
          })
        );
        return options;
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  useEffect(() => {
    if (user) {
      fetchAdvisorLanguages()
        .then((data) => {
          setSelectedLanguages(data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, []);

  if (!user) return null;
  const { timezone } = user;

  let timeZoneValue: zone_array;
  if (timezone !== null) {
    timeZoneValue = {
      label: timezone.toString(),
      value: timezone.toString(),
    };
  }

  const updateProfilePic = async (url: string) => {
    try {
      if (user?.id) {
        const result = await client.mutate({
          mutation: UPDATE_MENTOR_MUTATION,
          variables: {
            id: user?.id,
            pictureUrl: url,
          },
        });

        const {
          data: {
            updateMentor: { pictureUrl },
          },
        } = result;
        setUser({
          ...user,
          pictureUrl,
        });
        setToast({
          message: 'Profile Picture Updated!',
          type: 'success',
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFile(null);
      setOpenToast(true);
    }
  };

  return (
    <Fragment>
      <Toast
        open={openToast}
        close={() => {
          setOpenToast(false);
        }}
        {...toast}
      />

      <Header />

      <Container maxWidth={false} className="accountContainer">
        <Typography variant="h3" className="accountHeader">
          My Account
        </Typography>

        <Grid container spacing={2}>
          <Grid item md={3} sm={12} xs={12}>
            <AccountMenu />
          </Grid>

          <Grid item md={9} sm={12} xs={12}>
            <Card className={classes.accountInfoCard}>
              <Box className="infoCardHeader">
                <Typography variant="h5">Account Information</Typography>
              </Box>

              <Grid className="accountInfoBody">
                <Mutation<
                  UpdateMentorMutationProfile,
                  UpdateMentorMutationProfileVariables
                >
                  mutation={UPDATE_MENTOR_MUTATION}
                >
                  {(updateMentor: Function) => {
                    return (
                      <Formik
                        initialValues={{
                          id: user.id,
                          firstName: user.firstName,
                          lastName: user.lastName,
                          email: user.email,
                          personalEmail: user.personalEmail,
                          pictureUrl: user.pictureUrl,
                          phoneNumber: user.phoneNumber,
                          gender: user.gender,
                          gpa: user.gpa,
                          timezone: user.timezone,
                          zoomLink: user.zoomLink,
                          isAcceptedTo: user.isAcceptedTo,
                          pronoun: user.pronoun || '',
                          // graduateCollegeId: user?.graduateSchool?.id || null,
                          // CollegeId: user?.currentCollege?.id || null,
                        }}
                        enableReinitialize
                        validationSchema={FormSchema}
                        onSubmit={async (variables, { setSubmitting }) => {
                          try {
                            const result = await updateMentor({
                              variables: {
                                ...variables,
                                zoomLink: variables.zoomLink?.trim(),
                                timezone: convertTimeZoneForDB(
                                  (variables.timezone as string) || ''
                                ),
                              },
                            });
                            const {
                              data: {
                                updateMentor: {
                                  firstName,
                                  lastName,
                                  phoneNumber,
                                  pictureUrl,
                                  timezone,
                                  gpa,
                                  personalEmail,
                                  gender,
                                  zoomLink,
                                  email,
                                  pronoun,
                                  // graduateSchoolGradYear,
                                },
                              },
                            } = result;
                            setUser({
                              ...user,
                              firstName,
                              lastName,
                              phoneNumber,
                              personalEmail,
                              gpa,
                              gender,
                              pictureUrl,
                              timezone,
                              zoomLink,
                              email,
                              pronoun,
                              // graduateSchoolGradYear,
                            });
                            setToast({
                              message: 'User Updated!',
                              type: 'success',
                            });
                          } catch (error: any) {
                            let errorMessage = 'Changes not saved!';

                            if (error?.message) {
                              handleMutationError(error, ({ message }) => {
                                if (message) errorMessage = message;
                              });
                            }
                            setToast({
                              message: errorMessage,
                              type: 'error',
                            });
                          } finally {
                            setSubmitting(false);
                            setOpenToast(true);
                          }
                        }}
                      >
                        {({ isSubmitting, setFieldValue, values }) => {
                          return (
                            <Form className={classes.accountInformationForm}>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                className={
                                  pictureUrl
                                    ? classes.actionButtonsFile
                                    : classes.actionButtons
                                }
                              >
                                <Box display="flex" alignItems="center">
                                  <Avatar
                                    src={state.pictureUrl || ''}
                                    className={classes.profileLogo}
                                  />

                                  <Box maxWidth="100%">
                                    <Typography
                                      variant="h5"
                                      className="userProfileName"
                                    >{`${user.firstName} ${user.lastName}`}</Typography>
                                    {!file && (
                                      <Grid className="editProfilePicture">
                                        <label>
                                          Edit Profile Picture
                                          <input
                                            type="file"
                                            multiple
                                            onChange={({
                                              currentTarget: { files },
                                            }) => {
                                              try {
                                                if (files && files.length) {
                                                  setFile(files[0]);
                                                  setState({
                                                    checkedA: true,
                                                    pictureUrl:
                                                      URL.createObjectURL(
                                                        files[0]
                                                      ),
                                                  });
                                                }
                                              } catch (error) {
                                                console.log('error: ', error);
                                              }
                                            }}
                                          />
                                        </label>
                                      </Grid>
                                    )}

                                    <UploadFileForm
                                      file={file}
                                      setFile={setFile}
                                      response={(pictureUrl: string) => {
                                        setPictureUrl(pictureUrl);
                                        setFieldValue('pictureUrl', pictureUrl);
                                        updateProfilePic(pictureUrl);
                                      }}
                                    />
                                  </Box>
                                </Box>

                                <Box>
                                  {pictureUrl && (
                                    <ProfileLogo
                                      style={{
                                        backgroundImage:
                                          'url(' + pictureUrl + ')',
                                      }}
                                    />
                                  )}
                                </Box>
                                <Button
                                  variant="contained"
                                  color={isCopied ? 'inherit' : 'primary'}
                                  disabled={isCopied}
                                  className={
                                    isCopied
                                      ? classes.copyBookingLinkDark
                                      : classes.copyBookingLink
                                  }
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      user?.id
                                        ? `${
                                            process.env
                                              .REACT_APP_STUDENT_PORTAL_URL ||
                                            ''
                                          }/home?adv1=${user.id}&kickOff=true`
                                        : ''
                                    );
                                    setIsCopied(true);
                                    setTimeout(() => {
                                      setIsCopied(false);
                                    }, 5000);
                                  }}
                                >
                                  {isCopied ? (
                                    <>
                                      <img src={LINK_ICON} alt="copied" /> Link
                                      Copied
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        src={LINK_COPIED_ICON}
                                        alt="copied"
                                      />{' '}
                                      Copy Booking Link
                                    </>
                                  )}
                                </Button>
                              </Box>

                              <Box
                                display="flex"
                                alignItems="flex-start"
                                className="fieldsBoxSpacing mobileFlex"
                              >
                                <Box>
                                  <Typography
                                    variant="body1"
                                    className="textFieldCaption"
                                  >
                                    First Name
                                  </Typography>
                                  <Field
                                    name="firstName"
                                    component={TextField}
                                    label=""
                                    placeholder="First Name"
                                    className="styleTextField nameField"
                                  />
                                </Box>

                                <Box>
                                  <Typography
                                    variant="body1"
                                    className="textFieldCaption"
                                  >
                                    Last Name
                                  </Typography>
                                  <Field
                                    name="lastName"
                                    component={TextField}
                                    label=""
                                    placeholder="Last Name"
                                    className="styleTextField nameField"
                                  />
                                </Box>
                              </Box>

                              <Box
                                display="flex"
                                alignItems="flex-start"
                                className="fieldsBoxSpacing mobileFlex"
                              >
                                <Box className="mobileFieldSpacing">
                                  <Typography
                                    variant="body1"
                                    className="textFieldCaption"
                                  >
                                    Email
                                  </Typography>
                                  <Field
                                    name="email"
                                    component={TextField}
                                    label=""
                                    type="email"
                                    placeholder="Email"
                                    className="styleTextField emailField"
                                  />
                                </Box>

                                <Box className="mobileFieldSpacing">
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    className="captionMargin"
                                  >
                                    <Typography
                                      variant="body1"
                                      className="textFieldCaption captionSpacing"
                                    >
                                      Personal Email
                                    </Typography>
                                    <CustomTooltip
                                      message={
                                        'You will receive all the email notifications from National Collegiate Scouting Association, LLC Platform on this email.'
                                      }
                                    />
                                  </Box>

                                  <Field
                                    name="personalEmail"
                                    component={TextField}
                                    label=""
                                    type="email"
                                    placeholder="Personal Email"
                                    className="styleTextField emailField"
                                  />
                                </Box>
                              </Box>
                              <Box
                                display="flex"
                                alignItems="flex-start"
                                className="mobileFlex"
                              >
                                <Box className="fieldsBoxSpacing zoomLink">
                                  <Typography
                                    variant="body1"
                                    className="textFieldCaption"
                                  >
                                    Video Chat Meeting Link
                                  </Typography>
                                  <Field
                                    name="zoomLink"
                                    value={
                                      values?.zoomLink?.replace(
                                        /[,!*%~`';"<>^${}()|]/g,
                                        ''
                                      ) || ''
                                    }
                                    component={TextField}
                                    label=""
                                    rows="1"
                                    rowsMax="5"
                                    multiline
                                    placeholder="Zoom Link"
                                  />
                                </Box>

                                <Box className="genderDropdown genderDropdownIcon mobileFieldSpacing">
                                  <Typography
                                    variant="body1"
                                    className="textFieldCaption"
                                  >
                                    Gender
                                  </Typography>

                                  <FormikDropdown
                                    onSelect={setFieldValue}
                                    values={GENDER_ARRAY}
                                    label=""
                                    id="gender"
                                    value={values.gender || ''}
                                  />
                                </Box>

                                <Box className="mobileFieldSpacing">
                                  <Typography
                                    variant="body1"
                                    className="textFieldCaption"
                                  >
                                    College GPA
                                  </Typography>
                                  <Field
                                    name="gpa"
                                    component={TextField}
                                    label=""
                                    placeholder="College GPA"
                                    className="styleTextField"
                                  />
                                </Box>
                              </Box>

                              <Box className="fieldsBoxSpacing genderDropdown timeZoneSelect fieldsBoxSpacing">
                                <Typography
                                  variant="body1"
                                  className="textFieldCaption"
                                >
                                  Time Zone
                                </Typography>

                                <SelectTimeZone
                                  isMultiSelect={false}
                                  label=""
                                  value={timeZoneValue || 'Please Select'}
                                  getValue={(timeZone: OptionType) => {
                                    setFieldValue('timezone', timeZone.value);
                                  }}
                                />
                              </Box>

                              <Box display="flex">
                                <Box className="genderDropdownIcon mobileFieldSpacing">
                                  <Typography variant="body1">
                                    Pronouns
                                  </Typography>

                                  <Box className="genderDropdown">
                                    <FormikDropdown
                                      onSelect={setFieldValue}
                                      values={PRONOUNS_ARRAY}
                                      value={values.pronoun}
                                      id="pronoun"
                                      label=""
                                    />
                                  </Box>
                                </Box>

                                <Box
                                  className={`fieldsBoxSpacing genderDropdown timeZoneSelect ${classes.asyncSelect}`}
                                >
                                  <Typography variant="body1">
                                    Languages
                                  </Typography>
                                  <AsyncSelect
                                    isMulti
                                    isClearable
                                    isSearchable
                                    value={selectedLanguages}
                                    defaultOptions
                                    placeholder="Languages"
                                    loadOptions={loadOptions}
                                    onChange={(selectedOptions) => {
                                      handleChangeLanguage(
                                        selectedOptions,
                                        setFieldValue
                                      );
                                    }}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                  />
                                </Box>
                              </Box>

                              <Box className="fieldsBoxSpacing mobileFieldSpacing">
                                <Typography
                                  variant="body1"
                                  className="textFieldCaption"
                                >
                                  Phone Number
                                </Typography>

                                <Field
                                  name="phoneNumber"
                                  placeholder="Phone Number"
                                  validate={(value: any | string) => {
                                    if (value && !isValidPhoneNumber(value)) {
                                      return 'Invalid Phone Number';
                                    }
                                  }}
                                >
                                  {({
                                    field,
                                    form: {
                                      errors,
                                      handleBlur,
                                      touched,
                                      setFieldTouched,
                                      ...rest
                                    },
                                    ...otherProps
                                  }: any) => {
                                    return (
                                      <Box
                                        className={`${classes.phoneInput} ${
                                          touched.phoneNumber &&
                                          errors.phoneNumber
                                            ? 'error'
                                            : ''
                                        }`}
                                        textAlign="left"
                                      >
                                        <PhoneInput
                                          placeholder="Phone Number"
                                          value={field.value}
                                          defaultCountry="US"
                                          {...rest}
                                          {...otherProps}
                                          onBlur={() => {
                                            setFieldTouched(field.name, true);
                                          }}
                                          onChange={(value) => {
                                            setFieldValue(field.name, value);
                                          }}
                                          error={
                                            touched.phoneNumber &&
                                            errors.phoneNumber
                                          }
                                        />

                                        {errors.phoneNumber && (
                                          <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                                            {errors.phoneNumber}
                                          </p>
                                        )}
                                      </Box>
                                    );
                                  }}
                                </Field>
                              </Box>

                              <Grid>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={!!enableBtn}
                                      onChange={({ target: { checked } }) => {
                                        toggleBtn(checked);
                                        toggleBtn(!enableBtn);
                                        setFieldValue('isAcceptedTo', checked);

                                        if (checked)
                                          setFieldValue('MentorCollegeIds', []);
                                      }}
                                      color="primary"
                                    />
                                  }
                                  label={'I was accepted Early Decision'}
                                />
                              </Grid>

                              <Button
                                disabled={isSubmitting}
                                type="submit"
                                variant="contained"
                                className="saveChanges"
                              >
                                Save Changes
                              </Button>
                            </Form>
                          );
                        }}
                      </Formik>
                    );
                  }}
                </Mutation>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <FooterNew />
    </Fragment>
  );
};

export default AccountInformation;
