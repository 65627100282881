import gql from 'graphql-tag';

export const ADVISOR_GRAPH_DATA = gql`
  query AdvisorGraphData {
    advisorGraphData {
      max {
        id
        hours
        hoursLastUpdate
        isOpenToTakeStudents
        matchText
        disableIsOpenToTakeStudents
      }
      last30Days {
        value
        text
      }
      estimation {
        value
        order
      }
    }
  }
`;
