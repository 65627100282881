import { ApolloError } from 'apollo-boost';
import momentTz from 'moment-timezone';
import {
  ALL_MEETING_CANCEL_REASONS,
  MEETING_CANCEL_REASONS,
  optionsDecision,
} from '../constants';
import moment from 'moment-timezone';
import { ADVISOR_GRAPH_DATA } from '../graphql/queries/AdvisorCapacity';
import client from '../apollo';
import { estimationType } from './interfaces';
import { UPDATE_ADVISOR_CAPACITY_DATA } from '../graphql/mutations/AdvisorCapacity';
import { timeLogAllWorkActivityOptions } from '../pages/main/timeLogs/static';

moment.tz.setDefault();

const formatDate = (dateStr: string): string => {
  try {
    let date = new Date(dateStr);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  } catch {
    return '';
  }
};

export const fomateDateUS = (dateStr: Date | string): string => {
  let date = new Date(dateStr);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export function isValueBetween(value: number, min: number, max: number) {
  return value >= min && value <= max;
}

export const isSubstring = (
  text: string | null | undefined,
  substring: string
) => {
  if (!text || !substring) return false;
  return text.toLowerCase().indexOf(substring) !== -1;
};

export const handleMutationError = (
  error: Error,
  callback: (errors: { [name: string]: string }) => void
) => {
  if (error instanceof ApolloError) {
    if (error.graphQLErrors) {
      for (const graphQLError of error.graphQLErrors) {
        if (
          graphQLError.extensions &&
          graphQLError.extensions.code === 'BAD_USER_INPUT' &&
          graphQLError.extensions.exception &&
          graphQLError.extensions.exception.validationErrors
        ) {
          callback(graphQLError.extensions.exception.validationErrors);
        } else if (graphQLError.message) {
          callback({ message: graphQLError.message });
        }
      }
    }

    if (error.networkError) {
      console.log(error.networkError.message);
    }
  }
};

export const US_Timezones_Obj = {
  Hawaii: 'Pacific/Honolulu',
  'West-Alaska': 'America/Adak',
  Alaska: 'America/Anchorage',
  Pacific: 'America/Los_Angeles',
  Mountain: 'America/Denver',
  Arizona: 'America/Phoenix',
  Central: 'America/Chicago',
  Eastern: 'America/New_York',
  Atlantic: 'America/Aruba',
};

const US_Cities = {
  'Pacific/Honolulu': 'Hawaii Time (USA)',
  'America/Adak': 'West-Alaska Time (USA)',
  'America/Anchorage': 'Alaska Time (USA)',
  'America/Los_Angeles': 'Pacific Time (USA & Canada)',
  'America/Phoenix': 'Arizona Time (USA)',
  'America/Denver': 'Mountain Time (USA & Canada)',
  'America/Chicago': 'Central Time (USA & Canada)',
  'America/New_York': 'Eastern Time (USA & Canada)',
  'America/Aruba': 'Atlantic Time (USA & Canada)',
};

export const convertTimeZoneForDB = (selectedTimezone: string) => {
  if (!selectedTimezone) return '';
  const timezone = selectedTimezone.split(' ')[1];
  let standard = '';
  const index = selectedTimezone.lastIndexOf('(');
  if (index) {
    standard = selectedTimezone.substring(
      index + 1,
      selectedTimezone.length - 1
    );
  }

  if (
    [
      'Hawaii',
      'Alaska',
      'West-Alaska',
      'Pacific',
      'Mountain',
      'Arizona',
      'Central',
      'Eastern',
      'Atlantic',
    ].includes(timezone)
  ) {
    const usZone =
      US_Timezones_Obj?.[
        timezone as
          | 'Hawaii'
          | 'Alaska'
          | 'West-Alaska'
          | 'Pacific'
          | 'Mountain'
          | 'Arizona'
          | 'Central'
          | 'Eastern'
          | 'Atlantic'
      ];

    return `(GMT${momentTz.tz(usZone).format('Z')}) ${usZone} (${momentTz
      .tz(usZone)
      .format('z')})`;
  }
  return `(GMT${momentTz.tz(timezone).format('Z')}) ${timezone} (${
    standard || momentTz.tz(timezone).format('z')
  })`;
};

export const convertTimeZoneForFE = (selectedTimezone: string) => {
  if (!selectedTimezone) return '';
  const city = selectedTimezone.split(' ')[1];

  if (
    [
      'Pacific/Honolulu',
      'America/Anchorage',
      'America/Adak',
      'America/Los_Angeles',
      'America/Phoenix',
      'America/Denver',
      'America/Chicago',
      'America/New_York',
      'America/Aruba',
    ].includes(city)
  ) {
    const usZone =
      US_Cities?.[
        city as
          | 'Pacific/Honolulu'
          | 'America/Anchorage'
          | 'America/Adak'
          | 'America/Los_Angeles'
          | 'America/Denver'
          | 'America/Phoenix'
          | 'America/Chicago'
          | 'America/New_York'
          | 'America/Aruba'
      ];

    return `(GMT${momentTz.tz(city).format('Z')}) ${usZone}` || '';
  }
  return `(GMT${momentTz.tz(city).format('Z')}) ${city}`;
};

export const tabA11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const canceledReason = (canceledReason: string) =>
  ALL_MEETING_CANCEL_REASONS.find((item) => item.value === canceledReason);

export const notesCategories = [
  { name: 'Sales Note', value: 'sales_notes' },
  { name: 'Customer Service Note', value: 'customer_service_note' },
  { name: 'Advisor Meeting Note', value: 'advisor_meeting_note' },
  {
    name: 'Cancel Request – 14 Day Cancel – Advising / Service Related',
    value: 'cancel_request_14_day_cancel_advising_service_related',
  },
  {
    name: 'Cancel Request – 14 Day Cancel - Other',
    value: 'cancel_request_14_day_cancel_other',
  },
  {
    name: 'Cancel Request – Service Breakdown',
    value: 'cancel_request_service_breakdown',
  },
  {
    name: 'Cancel Request – Change of Heart',
    value: 'cancel_request_change_of_heart',
  },
  {
    name: 'Cancel Request – Expectations Misalignment',
    value: 'cancel_request_expectations_misalignment',
  },
  {
    name: 'Cancel Request – Financial / Health Hardship',
    value: 'cancel_request_financial_health_hardship',
  },
  {
    name: 'Early Termination Fee Scheduled',
    value: 'early_termination_fee_scheduled',
  },
  {
    name: 'Advising Note',
    value: 'advising_note',
  },
  {
    name: 'Time Log Note',
    value: 'timelog_note',
  },
];
export const loadReasonOptions: any = () => {
  const options = MEETING_CANCEL_REASONS;
  return new Promise((resolve) => {
    resolve(options);
  });
};

export const appStatusPrevValue = (prevVal: string | null) =>
  optionsDecision.find((item) => item.value === prevVal);

export const checkForCurrentYear = (year: string | null) => {
  const currentYear = new Date().getFullYear();
  const yearTypeCheck = typeof year === 'number' ? year : Number(year);
  if (yearTypeCheck === currentYear || yearTypeCheck === Number(process.env.REACT_APP_CURRENT_GRAD_YEAR)) {
    return true;
  }
  return false;
};
export const disableDropDown = (
  dueDate: any,
  highSchoolYear: string,
  isDetailPage?: boolean
) => {
  const currentDate = new Date().toJSON();
  if (isDetailPage && highSchoolYear === process.env.REACT_APP_CURRENT_GRAD_YEAR) {
    return true;
  } else if (dueDate <= currentDate) {
    return true;
  }
  return false;
};
export const toDecimalPoint = (value: number, point: number) => {
  return +value.toFixed(point);
};

export const upcomingMonthsLabels = () => {
  let labels = [];
  const date = moment();
  for (let i = 0; i < 8; i++) {
    const newData = moment(date).add(i, 'M');
    labels.push(moment(newData).format('MMMM YYYY'));
  }
  return labels;
};

export const upcomingShortMonthsLabels = () => {
  let labels = [];
  const date = moment();
  for (let i = 0; i < 8; i++) {
    const newData = moment(date).add(i, 'M');
    labels.push(moment(newData).format('MMM'));
  }
  return labels;
};

export const returnIndexMonth = (index: number) => {
  if (index !== -1) {
    const labels = upcomingShortMonthsLabels();
    return labels[index];
  }
  return null;
};

export const convertToSpecificTimezone = (
  timeInSeconds: number,
  timezone: string
) => {
  const currentTime = moment.unix(timeInSeconds).tz(timezone);
  // Convert the provided date to the desired format
  const convertedActivityAt = moment(
    currentTime,
    'ddd MMM DD YYYY HH:mm:ss [GMT]'
  ).tz(timezone);

  // Format the converted date and time
  const formattedTime = convertedActivityAt.format(
    `h:mma (${currentTime.tz(momentTz.tz.guess()).format('z')})`
  );

  const formattedDate = convertedActivityAt.format(`ddd, MMM D, YYYY`);

  return { formattedTime, formattedDate };
};

export const graphApiCalling = async () => {
  try {
    const { data } = await client.query({
      query: ADVISOR_GRAPH_DATA,
      fetchPolicy: 'no-cache',
    });
    if (data?.advisorGraphData) {
      return data.advisorGraphData;
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
};

export const getSetCapacityModalData = (estimation: estimationType[]) => {
  const avg = estimation?.length
    ? estimation.reduce((a, data) => data.value + a, 0) / 6
    : null;

  const sortedData = estimation?.sort(
    (data1, data2) => data1?.value - data2?.value
  );

  const lowestData = sortedData?.[0];

  const highestData = sortedData?.[sortedData?.length - 1];
  const minAvg = lowestData
    ? toDecimalPoint(Math.max(lowestData.value - 3, 0), 1)
    : null;
  const maxAvg = highestData ? toDecimalPoint(highestData.value + 3, 1) : null;
  return {
    avg,
    lowestData,
    highestData,
    minAvg,
    maxAvg,
  };
};

export const updateAdvisorCapacity = async ({
  hours,
  matchStr,
  isOpenToTakeStudents,
}: {
  hours: string;
  matchStr: string;
  isOpenToTakeStudents: boolean;
}) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_ADVISOR_CAPACITY_DATA,
      variables: { hours: Number(hours), isOpenToTakeStudents, matchStr },
    });
    if (data.updateAdvisorCapacityData) {
      return data.updateAdvisorCapacityData;
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
};

export default formatDate;

export const getStudentApplicationDeadline = (
  studentHighschoolYear: string | null | undefined,
  deadlineDate: string,
  graduated: boolean | null | undefined,
  gapYear: boolean | null | undefined
) => {
  try {
    const gapYearValue = gapYear ? 1 : 0;
    const highSchoolYear = studentHighschoolYear
      ? parseInt(studentHighschoolYear)
      : new Date().getFullYear();
    const deadline = moment.utc(deadlineDate);
    const deadlineMonth = deadline.month() + 1;
    if (!graduated) {
      if (deadline?.year() < highSchoolYear) {
        if (deadlineMonth > 9 && deadlineMonth < 13) {
          deadline.year(highSchoolYear + gapYearValue - 1);
        } else {
          deadline.year(highSchoolYear + gapYearValue);
        }
      }
    }

    return deadline.format('LL');
  } catch (err) {
    console.log(err);
    return '';
  }
};

export const selectColor = (value: any) => {
  return value ? 'valueText' : 'placeholderText';
};

export const collegeAddress = (
  address_city: string | null,
  address_state: string | null,
  address_address?: string | null,
  address_zipcode?: string | null
) => {
  const address = [];

  if (address_address) {
    address.push(address_address);
  }
  if (address_city) {
    address.push(address_city);
  }
  if (address_state) {
    address.push(address_state);
  }
  if (address_zipcode) {
    address.push(address_zipcode);
  }

  return address.length > 0 ? address.join(', ') : null;
};

export enum DecisionStatus {
  RegularDecision = 'RegularDecision',
  EarlyDecision = 'EarlyDecision',
  EarlyAction = 'EarlyAction',
}

export const returnRequestID = () => {
  return new Date().getTime();
};

export const getNewLabelForWorkActivity = (value: string | undefined) => {
  const item = timeLogAllWorkActivityOptions.find(
    (item) => item.value === value
  );
  return item ? item.label : value;
};
