import gql from 'graphql-tag';

export const ALL_LANGUAGES = gql`
  query getAllLanguagesQuery($UserId: Int) {
    getAllLanguages(UserId: $UserId) {
      id
      name
    }
  }
`;
