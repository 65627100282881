import { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Dialog,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Box,
} from '@material-ui/core';
import Colors from '../../../common/colors';
import fonts from '../../../common/fonts';

import { AppContext } from '../../../contexts';
import { generateConnectURL } from '../../../common/nylas';
import Calendar_Synced_Tick from '../../../img/synced.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      width: 516,
      height: 400,
      left: '37% !important',
      top: '30% !important',

      '& .MuiDialog-paper': {
        width: 516,
        maxWidth: 516,
        border: '1px solid #D4D4D4',
        borderRadius: 25,
        padding: '35px 34px 31px',
        boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',
        margin: 0,

        [theme.breakpoints.down('xs')]: {
          padding: 16,
        },
      },

      '& .calendarSynced': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 'bold',
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#2F80ED',
        paddingLeft: 12,
        '& img': {
          marginLeft: 10,
        },
      },

      '& .MuiTypography-h4': {
        color: '#272929',
        fontSize: 24,
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        letterSpacing: '-0.019em',
        paddingBottom: 33,
      },

      '& .MuiTypography-body1': {
        color: '#A9A9A9',
        fontSize: 14,
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        letterSpacing: '-0.006em',
        lineHeight: '24px',
        paddingBottom: 9,
        textTransform: 'uppercase',
      },

      '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(0, 0, 0, 0) !important',
        width: 0,
        height: 400,
      },
    },

    connectCalendarBtn: {
      '&.MuiButton-root': {
        fontFamily: fonts.POPPINS_FONT,
        fontSize: 14,
        fontWeight: 'bold',
        borderRadius: 12,
        lineHeight: '24px',
        textAlign: 'center',
        letterSpacing: '-0.006em',
        minWidth: 180,
        height: 40,
        padding: '12px 13px',
        textTransform: 'capitalize',
        color: Colors.WHITE_ONE,
        backgroundColor: Colors.BLUE_SEVEN,
      },
    },
  })
);

const ConnectToCalendarDialog = ({ open }: { open: boolean }) => {
  const classes = useStyles();
  const { user } = useContext(AppContext);
  const params = {
    client_id: process.env.REACT_APP_NYLAS_V3_CLIENT_ID_PRD || '',
    redirect_uri:
      process.env.REACT_APP_REDIRECT_URI ||
      'https://staging.advisors.collegeadvisor.com/callback',
    login_hint: user?.email ?? '',
    response_type: 'code',
    scopes: 'calendar',
    access_type: 'offline',
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialog}
      >
        <Typography variant="h4">
          Before adding availability, please:
        </Typography>

        <Box pb={4}>
          <Typography variant="body1">1. CONNECT YOUR CALENDAR</Typography>
          {!user?.nylas_grant_id ? (
            <Button
              href={generateConnectURL(params)}
              rel="noopener noreferrer"
              color="primary"
              className={classes.connectCalendarBtn}
            >
              Connect To Calendar
            </Button>
          ) : (
            <Box className="calendarSynced">
              Calendar Synced <img src={Calendar_Synced_Tick} alt="tick" />
            </Box>
          )}
        </Box>

        <Box>
          <Typography variant="body1">
            2. Add your Video Chat Meeting Link to your profile
          </Typography>
          {!user?.zoomLink ? (
            <Link to="/account/profile">
              <Button color="primary" className={classes.connectCalendarBtn}>
                Edit My Profile
              </Button>
            </Link>
          ) : (
            <Box className="calendarSynced">
              Meeting Link Added
              <img src={Calendar_Synced_Tick} alt="tick" />
            </Box>
          )}
        </Box>
      </Dialog>
    </div>
  );
};
export default ConnectToCalendarDialog;
