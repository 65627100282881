import { createContext } from 'react';
import {
  CurrentUserQuery_currentUser_colleges,
  CurrentUserQuery_currentUser_userMajors,
} from '../pages/main/layouts/__generated__/CurrentUserQuery';
import { advisorLead } from '../graphql/queries/advisorLead/__generated__/advisorLead';

import { Gender } from '../../__generated__/globalTypes';

export type User = {
  id: number;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  intakeYear: string | null;
  graduateSchoolGradYear: string | null;
  gpa: string | null;
  personalEmail: string | null;
  major: string | null;
  degree: string | null;
  bio: string | null;
  pictureUrl: string | null;
  gender: Gender | null;
  isAcceptedTo: boolean | null;
  sendbirdId: string | null;
  email: string;
  stripeConnectAccount: boolean | null;
  userMajors: (CurrentUserQuery_currentUser_userMajors | null)[] | null;
  isActive: boolean | null;
  haveMilestoneTeamRates: Boolean | null;
  colleges: (CurrentUserQuery_currentUser_colleges | null)[] | null;

  unreadMessagesCount: number | null;
  isOnline: boolean | null;
  timezone: String | null;
  isFullTime: boolean | null;
  advisorLevel: string | null;
  nylas_grant_id: String | null;
  nylas_edit_page_token: String | null;
  zoomLink: String | null;
  isPersonalCalendarConnected: boolean | null;
  Theme: String | null;
  internationalStudentExperience: boolean | null;
  pronoun: string | null;
} | null;

export interface AppContextProps {
  user: User | null;
  isLoggedIn: boolean;
  unreadMessagesCount: number | null;
  setUser: (user: User) => void;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  setUnreadMessagesCount: (data: any) => void;
  isCollegeUpdated: boolean;
  setIsCollegeUpdated: (isCollegeUpdated: boolean) => void;
  advisorLeadData: advisorLead | null;
  setAdvisorLeadData: (advisorLeadData: advisorLead) => void;
}

export const AppContext = createContext<AppContextProps>({
  isLoggedIn: false,
  user: null,
  unreadMessagesCount: 0,
  setUser() {},
  setIsLoggedIn() {},
  setUnreadMessagesCount() {},
  isCollegeUpdated: false,
  setIsCollegeUpdated() {},
  advisorLeadData: null,
  setAdvisorLeadData() {},
});
