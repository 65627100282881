import { FC } from 'react';
import { Box } from '@material-ui/core';

import LOGO from '../../img/logo-header.png';
import MAINTENANCE_IMG from '../../img/maintenance.svg';

import { useStyles } from './style';

const Maintenance: FC = () => {
  const classes = useStyles();

  return (
    <>
      <Box maxWidth={250} position="absolute" left={30} top={20}>
        <img src={LOGO} alt="main logo" />
      </Box>
      <Box className={classes.mainContainer}>
        <img src={MAINTENANCE_IMG} alt="MAINTENANCE_IMG" />
        <h2>Maintenance Mode!</h2>

        <p>
          Currently website is under maintenance. We will back again shortly.
        </p>
      </Box>
    </>
  );
};

export default Maintenance;
