import { memo, useState, MouseEvent } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { Formik, Field, Form } from 'formik';
import DateFnsUtils from '@date-io/date-fns';
import { Mutation } from 'react-apollo';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Grid,
  Box,
  Button,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from '@material-ui/core';

import CustomTimePicker from './CustomTimePicker';
import MeetingsConflictPopover from './MeetingsConflictPopover';

import Calendar_Icon from '../../../img/calendar-icon.svg';
import Fonts from '../../../common/fonts';
import { dayClickedType, getTwentyFourHourTime, timeToDecimal } from './utils';
import { editFormValuesInterface } from './TimeSlotsDialog';

import client from '../../../apollo';
import { CREATE_TIME_SLOT, GET_CONFLICTED_BOOKINGS_FOR_EDIT } from './gql';
import {
  CreateTimeSlotMutation,
  CreateTimeSlotMutationVariables,
} from './gql/__generated__/CreateTimeSlotMutation';

import {
  getBookingsForEditTimeSlotMutation,
  getBookingsForEditTimeSlotMutation_getBookingsForEditTimeSlot_bookings,
} from './gql/__generated__/getBookingsForEditTimeSlotMutation';
import { getAdvisorMonthlyTImeSlotsQuery_getAdvisorMonthlyTImeSlots_timeslots } from './gql/__generated__/getAdvisorMonthlyTImeSlotsQuery';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timeSlotSave: {
      '& .MuiButton-root.MuiButton-containedPrimary': {
        borderRadius: 12,
        minWidth: 130,
        height: 40,
        fontFamily: Fonts.POPPINS_FONT,
        fontWeight: 'bold',
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#ffffff',

        '&:hover': {
          background: '#38A878',
          boxShadow: 'none',
        },
      },
    },

    addSlotTimeForm: {
      '& .MuiInput-root': {
        border: '1px solid #D4D4D4',
        borderRadius: 6,
      },

      '& .MuiInput-underline:before': {
        display: 'none',
      },

      '& .MuiInputBase-input': {
        fontFamily: Fonts.INTER,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        padding: '0px 15px',
        height: 40,
      },

      '& .MuiInputLabel-formControl': {
        fontFamily: Fonts.INTER,
        fontSize: 12,
        color: '#A9A9A9',
      },
    },

    applyTime: {
      marginTop: 29,

      '& .MuiFormControlLabel-root': {
        marginBottom: 0,
        marginLeft: 0,
      },

      '& .MuiTypography-body1': {
        fontFamily: Fonts.INTER,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#252C32',
      },
    },

    calendarInput: {
      width: '92%',
      marginLeft: 'auto',
      margin: '10px 0px 20px',
      maxWidth: 265,

      '& .MuiInput-root': {
        background: `url(${Calendar_Icon}) no-repeat`,
        backgroundPosition: 'center left 15px',

        '&.Mui-disabled': {
          backgroundColor: '#f4f4f4',
        },
      },

      '& .MuiInputBase-input': {
        fontFamily: Fonts.INTER,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        padding: '0px 15px 0px 45px',

        '&.Mui-disabled': {
          color: '#A9A9A9',
          cursor: 'not-allowed',
        },
      },
    },
  })
);

const TimeSlotForm = ({
  onClose,
  handlePopUpNotification,
  dayClicked,
  selectedId,
  refetch,
  refetchAdvisorMonthlySlots,
  startTimeValue,
  endTimeValue,
  dayValues,
  selectedTimeSlot,
  handleConflictedMeetingsOnEdit,
  conflictedMeetingsonEdit,
  editFormValues,
  editAnchorEl,
  allMonthTimeSlots,
}: {
  onClose: Function;
  handlePopUpNotification: Function;
  dayClicked: dayClickedType;
  refetch?: Function;
  refetchAdvisorMonthlySlots: Function;
  selectedId: null | number;
  currentDayTimeSlots: any;
  startTimeValue?: any;
  endTimeValue?: any;
  dayValues: any;
  selectedTimeSlot: any;
  handleConflictedMeetingsOnEdit: Function;
  allMonthTimeSlots:
    | (getAdvisorMonthlyTImeSlotsQuery_getAdvisorMonthlyTImeSlots_timeslots | null)[]
    | null;
  conflictedMeetingsonEdit:
    | (getBookingsForEditTimeSlotMutation_getBookingsForEditTimeSlot_bookings | null)[]
    | null;
  editFormValues: editFormValuesInterface | undefined | null;
  editAnchorEl: HTMLButtonElement | HTMLLIElement | null;
}) => {
  const [error, setError] = useState('');
  const [wait, setWait] = useState(false);
  const [anchorEl, setAnchorEl] = useState<
    HTMLButtonElement | HTMLLIElement | null
  >(null);

  const checkOverlaping = (dateRanges: any) => {
    let sortedRanges = dateRanges.sort((previous: any, current: any) => {
      let previousTime = previous.start.getTime();
      let currentTime = current.start.getTime();
      if (previousTime < currentTime) {
        return -1;
      }
      if (previousTime === currentTime) {
        return 0;
      }
      return 1;
    });

    let result = sortedRanges.reduce(
      (result: any, current: any, idx: any, arr: any) => {
        if (idx === 0) {
          return result;
        }
        let previous = arr[idx - 1];
        let previousEnd = previous.end.getTime();
        let currentStart = current.start.getTime();
        let overlap = previousEnd > currentStart;

        if (overlap) {
          result.overlap = true;
          result.ranges.push({
            previous: previous,
            current: current,
          });
        }
        return result;
      },
      { overlap: false, ranges: [] }
    );

    return result;
  };

  const getConflictedBookings = async (
    id: number | null,
    startDate: Date,
    endDate: Date,
    series: boolean,
    seriesEndDate: Date | null
  ) => {
    const {
      data,
    }: { data: getBookingsForEditTimeSlotMutation } = await client.query({
      query: GET_CONFLICTED_BOOKINGS_FOR_EDIT,
      variables: {
        id,
        startDate,
        endDate,
        series,
        seriesEndDate,
      },
      fetchPolicy: 'network-only',
    });
    return data.getBookingsForEditTimeSlot?.bookings;
  };
  const validate = (values: any) => {
    setError('');
    setWait(true);
    let selectedStartTime = new Date();
    let selectedEndTime = new Date();

    if (selectedTimeSlot) {
      selectedStartTime = new Date(selectedTimeSlot.startDate);
      selectedEndTime = new Date(selectedTimeSlot.endDate);
    }

    let ranges =
      dayClicked && dayValues
        ? dayValues.map((timeSlot: any) => ({
            start: new Date(timeSlot.startDate),
            end: new Date(timeSlot.endDate),
          }))
        : [];

    if (selectedId) {
      ranges = ranges.filter(
        (item: any) =>
          !moment(item.start).isSame(moment(selectedStartTime)) &&
          !moment(item.end).isSame(moment(selectedEndTime))
      );
    }
    ranges = [
      ...ranges,
      {
        start: moment(dayClicked.day + ' ' + values.startDate).toDate(),
        end: moment(dayClicked.day + ' ' + values.endDate).toDate(),
      },
    ];

    let output = JSON.parse(JSON.stringify(checkOverlaping(ranges), null, 2));

    const isOverlapped = output && output.overlap;

    let currentStartTime: any = moment(
      dayClicked.day + ' ' + values.startDate
    ).format();
    currentStartTime = moment(currentStartTime);
    let currentEndTime: any = moment(
      dayClicked.day + ' ' + values.endDate
    ).format();
    currentEndTime = moment(currentEndTime);

    const endDate = moment(dayClicked.day + ' ' + values.endDate).toDate();
    const startDate = moment(dayClicked.day + ' ' + values.startDate).toDate();
    const monthlySlots = selectedId
      ? allMonthTimeSlots?.filter((timeslot) => timeslot?.id !== selectedId)
      : allMonthTimeSlots;
    const timeExistInPreviousDate =
      monthlySlots?.find(
        (m) =>
          moment(startDate).isBetween(m?.startDate, m?.endDate) ||
          moment(endDate).isBetween(m?.startDate, m?.endDate)
      ) || null;

    if (isOverlapped || timeExistInPreviousDate) {
      setError(
        'You already have an interval in this specific time range. Please change your time range.'
      );
      setWait(false);
      return false;
    } else if (
      timeToDecimal(values.startDate) >
      timeToDecimal(values.endDate === '00:00' ? '23:59' : values.endDate)
    ) {
      setError('End time should be greater than start time.');
      setWait(false);
      return false;
    } else if (
      timeToDecimal(values.startDate) === timeToDecimal(values.endDate)
    ) {
      setError('End time should not be equale to start time.');
      setWait(false);
      return false;
    } else if ((currentEndTime.diff(currentStartTime) / 60000) % 30 !== 0) {
      setError('Please only use times ending in :00 and :30.');
      setWait(false);
      return false;
    }
    return true;
  };

  const handleSubmit = async (
    values: any,
    createOrUpdateTimeSlots: Function,
    setSubmitting: Function,
    meetingsToBeDelete?: (number | undefined)[]
  ) => {
    let variables;
    let endTime = values.endDate === '00:00' ? '23:59' : values.endDate;
    let endDate = moment(dayClicked.day + ' ' + endTime).toDate();
    let customEndDate =
      values?.applyingDate === 'customDate'
        ? moment(values.customDate).toDate()
        : endDate;

    if (
      moment(customEndDate).format('DD-MM-YYYY') !==
        moment(endDate).format('DD-MM-YYYY') &&
      values?.applyingDate === 'customDate'
    ) {
      let newEndDate = endDate;
      while (moment(newEndDate).isBefore(moment(customEndDate))) {
        newEndDate = moment(newEndDate).add(7, 'days').toDate();
      }
      customEndDate = newEndDate;
    }

    moment(customEndDate).format('DD-MM-YYYY') ===
    moment(endDate).format('DD-MM-YYYY')
      ? (variables = {
          timeSlots: [
            {
              ...{
                startDate: moment(
                  dayClicked.day + ' ' + values.startDate
                ).toDate(),
                endDate,
                id: selectedId,
              },
              day: new Date(dayClicked.day).toISOString(),
            },
          ],
          meetingsToBeDelete: meetingsToBeDelete || null,
        })
      : (variables = {
          timeSlots: [
            {
              ...{
                startDate: moment(
                  dayClicked.day + ' ' + values.startDate
                ).toDate(),
                endDate,
                id: selectedId,
                customEndDate,
              },
              day: new Date(dayClicked.day).toISOString(),
            },
          ],
          meetingsToBeDelete: meetingsToBeDelete || null,
        });

    await createOrUpdateTimeSlots({
      variables,
    });
    await refetchAdvisorMonthlySlots();
    refetch && refetch();
    setWait(false);
    handleConflictedMeetingsOnEdit({
      conflictedMeetings: null,
      formValues: null,
      anchorEl: null,
    });
    onClose();

    //hiding loader on client side validation
    setWait(false);
    setSubmitting(false);
    selectedId && handlePopUpNotification('Availability Updated');
  };

  const initialValues = {
    startDate: startTimeValue,
    endDate: endTimeValue,
    applyingDate: 'currentDate',
    customDate: dayClicked.day,
  };

  const classes = useStyles();

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    handleConflictedMeetingsOnEdit({
      conflictedMeetings: null,
      formValues: null,
      anchorEl: null,
    });
    onClose();
  };

  const _handleSubmit = async (
    values: any,
    createOrUpdateTimeSlots: Function,
    setSubmitting: Function
  ) => {
    if (validate(values)) {
      if (selectedId) {
        let endTime = values.endDate === '00:00' ? '23:59' : values.endDate;
        let endDate = moment(dayClicked.day + ' ' + endTime).toDate();
        const conflictedMeetings = await getConflictedBookings(
          selectedId,
          moment(dayClicked.day + ' ' + values.startDate).toDate(),
          endDate,
          values.applyingDate === 'currentDate' ? false : true,
          values.customDate ? new Date(values.customDate) : null
        );
        if (conflictedMeetings?.length) {
          setWait(false);
          handleConflictedMeetingsOnEdit({
            conflictedMeetings,
            formValues: values,
            anchorEl: anchorEl,
          });
        } else {
          handleSubmit(values, createOrUpdateTimeSlots, setSubmitting);
        }
      } else {
        handleSubmit(values, createOrUpdateTimeSlots, setSubmitting);
      }
    } else {
      setSubmitting(false);
    }
  };

  const openPopover =
    conflictedMeetingsonEdit?.length && editAnchorEl ? true : false;

  return (
    <>
      <Mutation<CreateTimeSlotMutation, CreateTimeSlotMutationVariables>
        mutation={CREATE_TIME_SLOT}
      >
        {(createOrUpdateTimeSlots: Function) => {
          return (
            <Formik
              initialValues={initialValues}
              onSubmit={(values, { setSubmitting }) => {
                _handleSubmit(values, createOrUpdateTimeSlots, setSubmitting);
              }}
            >
              {({ isSubmitting, values, setFieldValue, setSubmitting }) => {
                if (values.applyingDate === 'currentDate') {
                  values.customDate =
                    selectedTimeSlot?.recurringLastDate || dayClicked.day;
                }
                values.startDate = getTwentyFourHourTime(values.startDate);
                values.endDate = getTwentyFourHourTime(values.endDate);

                const startDate = moment(
                  dayClicked.day.replace(/-/g, '/') +
                    ' ' +
                    (editFormValues?.startDate || values.startDate)
                ).format('hh:mm A');
                const endDate = moment(
                  dayClicked.day.replace(/-/g, '/') +
                    ' ' +
                    (editFormValues?.endDate || values.endDate)
                ).format('hh:mm A');

                const startTime = startDate;
                const endTime = endDate;

                return (
                  <>
                    <Form>
                      <Grid
                        container
                        spacing={2}
                        className={classes.addSlotTimeForm}
                      >
                        <CustomTimePicker
                          time={startTime}
                          type="startDate"
                          setFieldValue={setFieldValue}
                          placeHolder="Start Time"
                        />
                        <CustomTimePicker
                          time={endTime}
                          type="endDate"
                          setFieldValue={setFieldValue}
                          placeHolder="End Time"
                        />

                        {error && (
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <FormHelperText error>{error}</FormHelperText>
                          </Grid>
                        )}
                      </Grid>

                      <Box className={classes.applyTime}>
                        <RadioGroup
                          name="applyingDate"
                          value={
                            editFormValues?.applyingDate || values.applyingDate
                          }
                        >
                          <Field
                            type="radio"
                            name="applyingDate"
                            value="currentDate"
                            component={() => (
                              <FormControlLabel
                                value="currentDate"
                                control={<Radio color="primary" />}
                                label="Apply to Current date only"
                                onChange={() => {
                                  setFieldValue('applyingDate', 'currentDate');
                                }}
                              />
                            )}
                          />

                          <Field
                            type="radio"
                            name="applyingDate"
                            value="customDate"
                            component={() => (
                              <FormControlLabel
                                value="customDate"
                                control={<Radio color="primary" />}
                                label={`Set recurrence to every ${moment(
                                  dayClicked.day
                                ).format('dddd')} until`}
                                onChange={() => {
                                  setFieldValue('applyingDate', 'customDate');
                                }}
                              />
                            )}
                          />

                          <Grid
                            className={clsx(
                              classes.addSlotTimeForm,
                              classes.calendarInput
                            )}
                          >
                            <Field
                              name="customDate"
                              fullWidth
                              // value={moment(values.customDate).format('MM/DD/yyyy')}
                              component={() => (
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <DatePicker
                                    shouldDisableDate={(date: any) => {
                                      return (
                                        moment(date).format('dddd') !==
                                          moment(dayClicked.day).format(
                                            'dddd'
                                          ) ||
                                        (moment(
                                          `${dayClicked.day} ${values.startDate}`
                                        ).isDST() &&
                                          !moment(date).isDST()) ||
                                        (!moment(
                                          `${dayClicked.day} ${values.startDate}`
                                        ).isDST() &&
                                          moment(date).isDST())
                                      );
                                    }}
                                    disabled={
                                      values.applyingDate !== 'customDate'
                                        ? true
                                        : false
                                    }
                                    fullWidth
                                    minDate={moment(dayClicked.day).toDate()}
                                    variant="inline"
                                    label=""
                                    format="MM/dd/yyyy"
                                    value={moment(values.customDate).format(
                                      'MM/DD/yyyy'
                                    )}
                                    onChange={(date) =>
                                      date &&
                                      setFieldValue(
                                        'customDate',
                                        moment(date).format('YYYY-MM-D')
                                      )
                                    }
                                  />
                                </MuiPickersUtilsProvider>
                              )}
                            />
                          </Grid>
                        </RadioGroup>
                      </Box>

                      <Box
                        padding="20px 0 0px"
                        textAlign="right"
                        className={classes.timeSlotSave}
                        style={{ position: 'relative' }}
                      >
                        <Button
                          aria-describedby={'delete-popover'}
                          type="submit"
                          color="primary"
                          variant="contained"
                          disabled={isSubmitting}
                          onClick={handleClick}
                        >
                          {(!wait && !selectedId && 'Save') ||
                            (!wait && selectedId && 'Save Changes')}
                          {wait && (
                            <>
                              {'Saving...'}
                              <CircularProgress color="inherit" size={17} />
                            </>
                          )}
                        </Button>
                        <MeetingsConflictPopover
                          anchorEl={editAnchorEl}
                          meetingsToBeDeleted={conflictedMeetingsonEdit}
                          onClose={handlePopoverClose}
                          openPopover={openPopover}
                          onDelete={() => {
                            if (editFormValues)
                              handleSubmit(
                                editFormValues,
                                createOrUpdateTimeSlots,
                                setSubmitting,
                                conflictedMeetingsonEdit?.map(
                                  (item) => item?.id
                                )
                              );
                          }}
                          showMeetingsPopOver={true}
                        />
                      </Box>
                    </Form>
                  </>
                );
              }}
            </Formik>
          );
        }}
      </Mutation>
    </>
  );
};

export default memo(TimeSlotForm);
