import {
  KeyboardEvent,
  MouseEvent,
  ChangeEvent,
  FC,
  useState,
  useMemo,
} from 'react';

import { Formik, Form } from 'formik';
import { Mutation } from 'react-apollo';

import {
  RadioGroup,
  FormControl,
  Checkbox,
  Grid,
  Button,
  ThemeProvider,
  Box,
} from '@material-ui/core';

import {
  CreateStudentApplicationMutation,
  CreateStudentApplicationMutationVariables,
} from '../graphql/mutations/addStudentApplication/__generated/CreateStudentApplicationMutation';
import { CREATE_STUDENT_APPLICATION_MUTATION } from '../graphql/mutations/addStudentApplication';

import { CollegeLogo } from '../../../styles/onBoard';

import { customTheme } from '../../../pages/MaterializeCss';
import { AddMyColleges, BadgeNumber } from '../../../styles/common';

import PLUS_ADD_IMG from '../../../img/plus-btn.svg';
import CollegeDefaultImg from '../../../img/college-default-img.svg';
import { Spinner } from 'react-bootstrap';
import SnackbarComponent from '../../../common/SnackBar';
import {
  otherCollegesStyles,
  CollegeCategory,
  CheckColleges,
  UniversityAdd,
} from '../style';

import { OtherCollegesProps } from '../../../common/interfaces';

let emptyArray: number[] = [];

const OtherColleges: FC<OtherCollegesProps> = ({
  colleges,
  isPage,
  isCollegeAdded,
  refetch,
  isDrawerOpen,
  studentId,
}) => {
  const classes = otherCollegesStyles();
  const host = () => {
    let host = '';
    if (window.location.hostname.includes('staging')) {
      host = 'https://staging.app.collegeadvisor.com';
    } else if (window.location.hostname.includes('dev')) {
      host = 'https://dev.app.collegeadvisor.com';
    } else {
      host = 'https://app.collegeadvisor.com';
    }
    return host;
  };

  const [snackOpen, setSnackOpen] = useState<boolean>(false);
  const [state, setState] = useState({
    collegeIds: emptyArray,
  });
  /**
   * @author Farhan Amin
   * @param  void
   */
  const addCollegesToState = useMemo(() => {
    return (collegeIds: number[], isSubmitting: boolean) => {
      if (state?.collegeIds?.length < 5) {
        isSubmitting
          ? setState({ ...state, collegeIds: [] })
          : setState({ collegeIds });
      } else {
        setSnackOpen(true);
      }
    };
  }, [state, setSnackOpen]);

  if (!colleges || !colleges.length)
    return <p className="text-center p-2">No Colleges Found</p>;

  const initialValues = { StudentId: studentId, collegeIds: [] };
  const { collegeIds } = state;
  emptyArray = !isDrawerOpen ? [] : collegeIds;
  const handleChange =
    (id: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { checked },
      } = event;
      const collegeIds = [...state.collegeIds];
      if (!checked) {
        const index = collegeIds.indexOf(id);
        if (index > -1) collegeIds.splice(index, 1);
      } else collegeIds.push(id);
      addCollegesToState(collegeIds, false);
    };

  const addCollegeToList =
    (id: number) => (event: KeyboardEvent | MouseEvent) => {
      const collegeIds = [...state.collegeIds];
      const index = collegeIds.indexOf(id);
      if (index < 0) collegeIds.push(id);
      addCollegesToState(collegeIds, false);
    };

  return (
    <>
      <Grid className="collegeScrollContent collegeScroll">
        <CheckColleges className={isPage ? 'checkcollegesPadding' : ''}>
          <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup>
              {colleges.map((college, i) => {
                if (!college) return null;
                const { id, name, applicationCreated, logo } = college;
                const isChecked = collegeIds.indexOf(id) > -1;
                const backgroundImage = `url( ${logo || CollegeDefaultImg} )`;
                return (
                  <UniversityAdd
                    key={i}
                    className={applicationCreated ? 'disable-college-card' : ''}
                  >
                    <CollegeCategory>
                      {studentId && (
                        <Checkbox
                          onChange={handleChange(id)}
                          value={id}
                          checked={isChecked || !!applicationCreated}
                          disabled={!!applicationCreated}
                          color="primary"
                        />
                      )}
                      <CollegeLogo className={classes.collegeIcon}>
                        <Grid
                          className={classes.acceptedBadge}
                          style={{
                            backgroundImage,
                          }}
                        />
                      </CollegeLogo>
                      <a href={`${host()}/colleges/${id}`}>{name}</a>
                    </CollegeCategory>
                    {!isChecked && !applicationCreated && studentId && (
                      <ThemeProvider theme={customTheme}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className="addCollegesButton"
                          onClick={addCollegeToList(id)}
                        >
                          <img src={PLUS_ADD_IMG} alt="" />
                          Add to Applications
                        </Button>
                      </ThemeProvider>
                    )}
                  </UniversityAdd>
                );
              })}
            </RadioGroup>
          </FormControl>
        </CheckColleges>
      </Grid>

      {!!collegeIds.length && studentId && (
        <AddMyColleges style={{ marginLeft: 0 }}>
          <h6>
            <BadgeNumber>{collegeIds.length}</BadgeNumber> Colleges Selected
          </h6>

          <Mutation<
            CreateStudentApplicationMutation,
            CreateStudentApplicationMutationVariables
          >
            mutation={CREATE_STUDENT_APPLICATION_MUTATION}
          >
            {(createStudentApplicationMutation: Function) => {
              return (
                <Formik
                  initialValues={initialValues}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      const result = await createStudentApplicationMutation({
                        variables: { StudentId: studentId, collegeIds },
                      });
                      const {
                        data: {
                          createStudentApplication: { status },
                        },
                      } = result;
                      setSubmitting(false);

                      if (status) {
                        isCollegeAdded && isCollegeAdded(true);
                        refetch && refetch();
                        addCollegesToState(collegeIds, true);
                      } else alert('Colleges not added');
                    } catch (error) {
                      isCollegeAdded && isCollegeAdded(true);
                      console.log('error: ', error);
                      setSubmitting(false);
                    }
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <SnackbarComponent
                        message={'You can add only 5 colleges at a time.'}
                        open={snackOpen}
                        severity={'error'}
                        autoHideDuration={2000}
                        handleClose={() => setSnackOpen(false)}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      />
                      <ThemeProvider theme={customTheme}>
                        <Box ml={2}>
                          <Button
                            type="submit"
                            variant="outlined"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? (
                              <Spinner size="sm" animation="border" />
                            ) : (
                              <>Add to Applications</>
                            )}
                          </Button>
                        </Box>
                      </ThemeProvider>
                    </Form>
                  )}
                </Formik>
              );
            }}
          </Mutation>
        </AddMyColleges>
      )}
    </>
  );
};

export default OtherColleges;
