import { FC, useState } from 'react';
import { Query } from 'react-apollo';
import {
  TablePagination,
  TableFooter,
  TableRow,
  TableCell,
  Table,
  Box,
  TableHead,
  TableBody,
  Grid,
  Hidden,
} from '@material-ui/core';
import { allAdvisorBookingsQuery } from '../meetings/gql/__generated__/allAdvisorBookingsQuery';
import Loader from '../../../components/Loader';
import { bookingInfoStatuses } from '../../../constants';
import StudentsPastMeetingsTable from './StudentsPastMeetingsTable';
import { useStyles } from './MyStudentsStyle';
import { ALL_ADVISOR_BOOKINGS } from '../meetings/gql';
import {
  fetchResultProp,
  StudentPastMeetingsListProps,
} from '../../../common/interfaces';
const StudentPastMeetingsList: FC<StudentPastMeetingsListProps> = ({
  status,
  studentId,
}) => {
  const limit = 10;
  const [page, setPage] = useState(0);

  const classes = useStyles();

  const getData = (page: number, fetchMore: Function) => {
    fetchMore({
      variables: {
        page: page + 1,
      },
      updateQuery: (prev: allAdvisorBookingsQuery, next: fetchResultProp) => {
        if (!next) return prev;
        const { fetchMoreResult } = next;
        if (
          !fetchMoreResult ||
          !fetchMoreResult.allAdvisorBookings ||
          !fetchMoreResult.allAdvisorBookings.advisorBookings
        )
          return prev;
        return fetchMoreResult;
      },
    });
    setPage(page);
  };
  return (
    <Query<allAdvisorBookingsQuery>
      query={ALL_ADVISOR_BOOKINGS}
      fetchPolicy="no-cache"
      variables={{
        status,
        sortByDate: true,
        page: page + 1,
        limit,
        studentId: studentId,
      }}
    >
      {({ data, loading, fetchMore, refetch }) => {
        if (loading) return <Loader />;

        return (
          <>
            <Hidden only={['sm', 'xs']}>
              <Box className={classes.bookingTable}>
                <Table className={classes.bookingTableWrapper}>
                  {!!data?.allAdvisorBookings?.advisorBookings?.length && (
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.tableHead}
                          width="10%"
                        ></TableCell>
                        <TableCell className={classes.tableHead} width="25%">
                          ADVISORS
                        </TableCell>
                        <TableCell className={classes.tableHead} width="15%">
                          DATE
                        </TableCell>
                        <TableCell className={classes.tableHead} width="30%">
                          TIME
                        </TableCell>
                        {status === bookingInfoStatuses.CANCELLED ? (
                          <>
                            <TableCell
                              className={classes.tableHead}
                              width="10%"
                            >
                              Pre-Meeting Note
                            </TableCell>
                            <TableCell
                              className={classes.tableHead}
                              width="10%"
                            >
                              CANCELED BY
                            </TableCell>
                            <TableCell
                              className={classes.tableHead}
                              width="10%"
                            >
                              REASON
                            </TableCell>
                          </>
                        ) : (
                          <TableCell
                            className={classes.tableHead}
                            width="15%"
                          ></TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                  )}

                  <TableBody>
                    {!!data?.allAdvisorBookings?.advisorBookings?.length ? (
                      data.allAdvisorBookings.advisorBookings.map((item) => (
                        <StudentsPastMeetingsTable
                          bookingInfo={item}
                          refetch={refetch}
                          status={status}
                        />
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={6}
                          className={classes.emptyTableCell}
                        >
                          <Grid>
                            No{' '}
                            {status === bookingInfoStatuses.CANCELLED
                              ? 'canceled'
                              : 'completed'}{' '}
                            meetings found
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>

              <div className="pagination-wrapper">
                {!!data?.allAdvisorBookings?.advisorBookings?.length && (
                  <Table>
                    <TableFooter>
                      <TableRow>
                        <TableCell className="pagination-spacing">
                          <TablePagination
                            rowsPerPageOptions={[10]}
                            component="div"
                            count={data?.allAdvisorBookings?.count || 0}
                            rowsPerPage={limit}
                            page={page}
                            onChangePage={(e, page) => {
                              getData(page, fetchMore);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                )}
              </div>
            </Hidden>

            <Hidden only={['xl', 'lg', 'md']}>
              {!!data?.allAdvisorBookings?.advisorBookings?.length ? (
                data.allAdvisorBookings.advisorBookings.map((item) => (
                  <StudentsPastMeetingsTable
                    bookingInfo={item}
                    status={status}
                  />
                ))
              ) : (
                <Grid className={classes.emptyTableCell}>
                  <Grid>
                    No{' '}
                    {status === bookingInfoStatuses.CANCELLED
                      ? 'canceled'
                      : 'completed'}{' '}
                    meetings found
                  </Grid>
                </Grid>
              )}
            </Hidden>
          </>
        );
      }}
    </Query>
  );
};

export default StudentPastMeetingsList;
