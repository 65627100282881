
import { Box, Grid, Typography } from '@material-ui/core';
import { FC } from 'react';
import colors from '../../../common/colors';
import { AdditionalSurveyProps } from '../../../common/interfaces';
import { useStyles } from './MyStudentsStyle';
export const AdditionalSurveyDetails: FC<AdditionalSurveyProps> = ({ studentApplicationSurvey }) => {
    const classes = useStyles();
    
    const earlyActionCollegesNames = studentApplicationSurvey?.earlyActionColleges?.map((college)=>{
       return college?.name
    }).join(', ')
    return (
        <>
            <Typography
                variant="h4"
                className="additionalSurveyTitle"
            >
                Additional Survey Details
            </Typography>
            <Box borderBottom={1} borderColor={`${colors.WHITE_SEVEN} !important`} paddingTop={2}>
            </Box>
            <Grid className={classes.additionSurveyDetail}>
                <Typography variant="h6">Final Decision:</Typography>
                <Typography variant='body2' className='finalDecision'>{studentApplicationSurvey?.finalDecisionCollege ? studentApplicationSurvey?.finalDecisionCollege?.name : '--'}</Typography>
            </Grid>
            <Grid className={classes.additionSurveyDetail}>
                <Typography variant="h6">Early Action Applications:</Typography>
                <Typography variant='body2' className='earlyAction'>{studentApplicationSurvey?.earlyActionColleges ? earlyActionCollegesNames : '--'}</Typography>
            </Grid>
            <Grid className={classes.additionSurveyDetail}>
                <Typography variant="h6">Early Decision Applications:</Typography>
                <Typography variant='body2' className='earlyDecision'>{studentApplicationSurvey?.earlyDecisionCollege ? studentApplicationSurvey?.earlyDecisionCollege.name : '--'}</Typography>
            </Grid>
            <Grid className={classes.additionSurveyDetail}>
                <Typography variant="h6">Merit-based Scholarships:</Typography>
                <Typography variant='body2' className='meritScholarship'>{studentApplicationSurvey?.scholarshipInfo ? studentApplicationSurvey?.scholarshipInfo : '--'}</Typography>
            </Grid>
        </>
    )
}