export const initialValues = {
  workType: '',
  activityAt: null,
  activityDescription: '',
  StudentId: null,
  workActivity: '',
  milestoneTeam: '',
  entitlementType: '',
  activityDuration: '',
  PremiumEntitlementId: null,
  PaymentCategoryId: null,
  topic: [''],
  note: '',
  collegeId: null,
  teamId: null,
  durationNote: '',
  missCall: false,
  airtableSubmitted: false,
  otherAdvisorIds: [],
  milestone: '',
  serviceElement: '',
  studentApplications: [],
  otherMeetingDescription: '',
  noteForExtendedMeeting: '',
  noteForNoOrientationCall: '',
  isEntitlementsUsedChecked: false,
  isCommonApp: false,
  isCoalitionApp: false,
  isOtherStrategy: false,
  isProjectWork: false,
};

export const workTypeOptions = [
  {
    value: 'Advisor Operations',
    label: 'Advisor Operations',
  },
  {
    value: 'Student Advising',
    label: 'Student Advising',
  },
  {
    value: 'Dress Rehearsal',
    label: 'Dress Rehearsal',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export const MilestoneWorkTypeOption = {
  value: 'Milestone Teams',
  label: 'Milestone Teams',
};

export const workActivityForStudentAdvising = [
  {
    value: 'Video Chat Session, Solo',
    label: 'Video Chat Session, Solo',
  },
  {
    value: 'Meeting with other Advisor/AO',
    label: 'Meeting with other Advisor/AO',
  },
  {
    value: 'Essay Editing/Review',
    label: 'Essay Review',
  },
  {
    value: 'Independent Research for Student',
    label: 'Offline Research',
  },
  {
    value: 'Orientation/Kick-Off Call, Solo',
    label: 'Orientation Call',
  },
  {
    value: 'Orientation/Kick-Off Call, 2+ Advisors',
    label: 'Orientation Call, 2+ Advisors',
  },

  {
    value: 'Correspondence with Family',
    label: 'Correspondence related to account',
  },

  {
    value: 'Other',
    label: 'Other',
  },
];

export const mileStoneOptions = [
  {
    value: 'Orientation',
    label: 'Orientation',
  },
  {
    value: 'Candidate Profile & EC Development',
    label: 'Candidate Profile & EC Development',
  },
  {
    value: 'College List Development',
    label: 'College List Development',
  },
  {
    value: 'Application Strategy',
    label: 'Application Strategy',
  },
  {
    value: 'Essay Writing & Editing',
    label: 'Essay Writing & Editing',
  },
  {
    value: 'Interview Prep',
    label: 'Interview Prep',
  },
  {
    value: 'Financial Aid & Scholarships',
    label: 'Financial Aid & Scholarships',
  },
  {
    value: 'Post-Submission Support',
    label: 'Post-Submission Support',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export const mileStoneOptionsForProjectWork = [
  {
    value: 'Orientation',
    label: 'Orientation',
  },
  {
    value: 'Candidate Profile & EC Development',
    label: 'Candidate Profile & EC Development',
  },
  {
    value: 'College List Development',
    label: 'College List Development',
  },
  {
    value: 'Financial Aid & Scholarships',
    label: 'Financial Aid & Scholarships',
  },
  {
    value: 'Post-Submission Support',
    label: 'Post-Submission Support',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export const workActivityForAdvisorOperations = [
  {
    value: 'Training',
    label: 'Training',
  },
  // {
  //   value: 'Internal Meetings',
  //   label: 'Internal Meetings',
  // },
  {
    value: 'Monthly Check-In',
    label: 'Monthly Check-In',
  },
  {
    value: 'Communication with Advisor Manager',
    label: 'Communication with Team Lead',
  },
  {
    value: 'Communication with Service Team',
    label: 'Communication with Service Team',
  },
  {
    value: 'Review of Network Update',
    label: 'Review of Network Update',
  },
  {
    value: 'Group Session',
    label: 'Group Session',
  },
  {
    value: 'Admin Work',
    label: 'Admin Work',
  },
];

export const timeLogAllWorkActivityOptions = [
  ...workActivityForStudentAdvising,
  ...workActivityForAdvisorOperations,
];
