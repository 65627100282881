import { FC } from 'react';

import momentTz from 'moment-timezone';

import { Dialog, Box, Typography, Button, IconButton } from '@material-ui/core';
import { Spinner } from 'react-bootstrap';

import AsyncSelect from 'react-select/async';

import { loadReasonOptions } from '../../../common/utility';

import { useStylesAccount } from '../account/accountStyle';

import ModalCloseIcon from '../../../img/dialog-close-updated.svg';
import { ReasonProps } from '../../../common/interfaces';
import INFO_DARK from '../../../img/info-dark.svg';
import OtherReason from './OtherReason';

const CancelMeetingModal: FC<ReasonProps> = ({
  firstButtonText,
  secondButtonText,
  open,
  onClose,
  loading,
  handleCancelBtn,
  startTime,
  startDate,
  reason,
  title,
  setReason,
  isComplete,
  setOtherReason,
  otherReason
}) => {
  const classes = useStylesAccount();
  const handleInputChange = (selectedValue: any) => {
    setReason(selectedValue.value);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      className={`${classes.confirmationDialog}  ${classes.muiPaperOverflowY}`}
    >
      <Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Typography variant="h4">
            {isComplete ? 'Cancel Past Meeting' : title}
          </Typography>

          <IconButton onClick={onClose}>
            <img src={ModalCloseIcon} alt="close" />
          </IconButton>
        </Box>
        <Typography variant="body1">
          {`${startDate} - ${startTime}`}{' '}
          {momentTz(startDate).tz(momentTz.tz.guess()).format('z')}
        </Typography>
        <Box className={classes.accountInformationForm} pt="21px">
          <Typography variant="body1" className="fieldCaption">
            Cancelation Reason
          </Typography>

          <Box className={classes.advisorSelectDropDown} pb="22px">
            <AsyncSelect
              cacheOptions
              placeholder="Select Cancelation Reason"
              defaultOptions
              loadOptions={loadReasonOptions}
              isClearable={true}
              isSearchable={true}
              classes={classes}
              onChange={handleInputChange}
              classNamePrefix={'react-select'}
              className="react-select-container"
            />
          </Box>
        </Box>
        {reason === 'other' ? (
          <OtherReason title='Note' setOtherReason={setOtherReason} />
        ): ''}
        {isComplete && (
          <Box display="flex" paddingBottom="22px">
            <img src={INFO_DARK} alt="info-icon" className="pr-3" />
            <Typography variant="body1">
              No cancelation emails will be sent.
            </Typography>
          </Box>
        )}
        <Box display="flex" justifyContent="flex-end">
          {secondButtonText && (
            <Button variant="outlined" onClick={onClose}>
              {secondButtonText}
            </Button>
          )}

          <Button
            onClick={() => handleCancelBtn && handleCancelBtn(reason, otherReason)}
            variant="contained"
            color="primary"
            disabled={loading || !reason || (reason === 'other' && !otherReason)}
            className="dangerButton"
            startIcon={loading && <Spinner animation="border" size="sm" />}
          >
            {loading
              ? firstButtonText === 'Cancel Meeting'
                ? `Canceling...`
                : 'Deleting...'
              : firstButtonText}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CancelMeetingModal;
