import { useState } from 'react';
import { Query } from 'react-apollo';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
  Hidden,
  Typography,
  Box,
} from '@material-ui/core';
import Loader from '../../../components/Loader';

import MeetingRecord from './MeetingRecord';
import Toast, { Color } from '../../../components/Toast';

import { bookingInfoStatuses } from '../../../constants';
import { getAllAdvisorBookingsQuery } from '../../../graphql/queries/advisorBookings/__generated__/getAllAdvisorBookingsQuery';
import { GET_ALL_ADVISOR_BOOKINGS } from '../../../graphql/queries/advisorBookings';
import { meetingTableStyles } from './styles';

interface fetchResult {
  fetchMoreResult: getAllAdvisorBookingsQuery | null;
}

const MeetingsTable = ({
  status,
  searchValue,
}: {
  status: string;
  searchValue?: string;
}) => {
  const classes = meetingTableStyles();
  const limit = 10;
  const [page, setPage] = useState(0);
  const [openToast, setOpenToast] = useState(false);
  const [toast, setToast] = useState({ message: '' });
  const [type, setType] = useState<Color>('success');

  function getData(page: number, fetchMore: Function) {
    fetchMore({
      variables: {
        page: page + 1,
      },
      updateQuery: (prev: getAllAdvisorBookingsQuery, next: fetchResult) => {
        if (!next) return prev;
        const { fetchMoreResult } = next;
        if (
          !fetchMoreResult ||
          !fetchMoreResult.allAdvisorBookings ||
          !fetchMoreResult.allAdvisorBookings.advisorBookings
        )
          return prev;
        return fetchMoreResult;
      },
    });

    setPage(page);
  }

  const handlePopUpNotification = (message: string, type: Color) => {
    setToast({ message });
    setOpenToast(true);
    setType(type);
  };

  return (
    <>
      <Toast
        open={openToast}
        close={() => {
          setOpenToast(false);
        }}
        {...toast}
        autoHideDuration={3000}
        type={type}
      />
      <Query<getAllAdvisorBookingsQuery>
        query={GET_ALL_ADVISOR_BOOKINGS}
        variables={{
          status: status,
          searchQuery: searchValue,
          page: page + 1,
          limit,
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading, fetchMore, refetch }) => {
          if (loading) {
            return <Loader />;
          }
          if (!data) {
            return null;
          }

          return (
            <>
              <Hidden only={['sm', 'xs']}>
                <TableContainer className="tableCustomScroll">
                  <Table className={classes.meetingTable}>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Student</TableCell>
                        <TableCell>DATE</TableCell>
                        <TableCell>TIME</TableCell>
                        <TableCell>ADDITIONAL ADVISORS</TableCell>
                        <TableCell>Pre-Meeting Note</TableCell>
                        {status === bookingInfoStatuses.UPCOMING && (
                          <TableCell>MEETING LINK</TableCell>
                        )}
                        {status === bookingInfoStatuses.CANCELLED && (
                          <TableCell>Cancelled By</TableCell>
                        )}
                        {status === bookingInfoStatuses.CANCELLED && (
                          <TableCell>REASON</TableCell>
                        )}
                        {(status === bookingInfoStatuses.COMPLETED ||
                          status === bookingInfoStatuses.CANCELLED) && (
                          <TableCell align="center">LOGGED ACTIVITY</TableCell>
                        )}
                        {status === bookingInfoStatuses.UPCOMING && (
                          <TableCell></TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.allAdvisorBookings?.advisorBookings?.length ? (
                        data.allAdvisorBookings.advisorBookings.map(
                          (row, index) => {
                            return (
                              <MeetingRecord
                                row={row}
                                index={index}
                                status={status}
                                refetch={refetch}
                                handlePopUpNotification={
                                  handlePopUpNotification
                                }
                              />
                            );
                          }
                        )
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={9}
                            className={classes.emptyStatesRow}
                          >
                            <Box className={classes.emptyStates}>
                              <Typography variant="body1">
                                No {status} meetings found
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                    {data.allAdvisorBookings?.advisorBookings?.length ? (
                      <TableFooter>
                        <TableRow>
                          <TableCell colSpan={9}>
                            <TablePagination
                              rowsPerPageOptions={[2]}
                              component="div"
                              count={data.allAdvisorBookings?.count || 0}
                              rowsPerPage={limit}
                              page={page}
                              onChangePage={(e, page) => {
                                getData(page, fetchMore);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    ) : (
                      ''
                    )}
                  </Table>
                </TableContainer>
              </Hidden>

              <Hidden only={['xl', 'lg', 'md']}>
                {data.allAdvisorBookings?.advisorBookings?.length ? (
                  data.allAdvisorBookings.advisorBookings.map((row, index) => {
                    return (
                      <MeetingRecord
                        row={row}
                        index={index}
                        status={status}
                        refetch={refetch}
                        handlePopUpNotification={handlePopUpNotification}
                      />
                    );
                  })
                ) : (
                  <Box className={classes.emptyStates}>
                    <Typography variant="body1">
                      No {status} meetings found
                    </Typography>
                  </Box>
                )}
              </Hidden>
            </>
          );
        }}
      </Query>
    </>
  );
};

export default MeetingsTable;
