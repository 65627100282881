import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import Colors from '../../../common/colors';
import fonts from '../../../common/fonts';

import SELECT_ARROW_IMG from '../../../img/select-arrow.svg';

import SEARCH_DARK_IMG from '../../../img/search-students.svg';
import DROPDOWN_ARROW_IMG from '../../../img/dropdown-arrow-sorting.svg';
import colors from '../../../common/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noPastMeetingsCard: {
      borderRadius: 15,
      boxShadow: 'none',
      background: '#F4F4F4',
      padding: '36px 46px',

      [theme.breakpoints.down('xs')]: {
        padding: '20px 16px',
      },

      '& .descriptionText': {
        fontSize: 16,
        color: '#272929',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.176px',

        '& a, & a:hover': {
          color: colors.BLUE_SEVEN,
          borderBottom: `1px solid ${colors.BLUE_SEVEN}`,
        },
      },

      '& .textSpacing': {
        marginBottom: 15,
      },
    },

    studentAdvisorSpacing: {
      marginBottom: '38px !important',
    },
    disabledBtn: {
      opacity: 0.4,
    },
    subAdvisorHeading: {
      fontSize: '14px',
      fontWeight: 600,
      fontFamily: fonts.POPPINS_FONT,
    },

    cancelledPlanBox: {
      marginBottom: 54,
      background: '#FFF8E4',
      borderRadius: '15px',
      padding: '16px 20px 25px',
      display: 'flex',
      alignItems: 'baseline',

      '& .typoBox': {
        marginLeft: 20,
      },

      '& .title': {
        color: '#272929',
        fontFamily: fonts.INTER,
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 14,
        lineHeight: 2,
      },

      '& .hint': {
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
      },
    },
    childBranches: {
      paddingLeft: 20,
      margin: '10px 0px 0px 64px',
      borderLeft: '2px solid #D4D4D4',

      [theme.breakpoints.down('sm')]: {
        margin: '10px 0px 0px 0px',
      },

      '& .acceptanceRate': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
      },

      '& .MuiTypography-body2': {
        fontFamily: fonts.INTER,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#525454',
        fontWeight: 400,
      },

      '& .collegeAvatarName': {
        marginBottom: 13,
      },

      '& .subCampusPercentage': {
        position: 'relative',
        left: 45,
        color: '#272929',
        fontSize: 16,
        letterSpacing: '-0.011em',
      },

      '& img': {
        marginRight: 14,
      },
    },
    deleteButtonParentRelative: {
      position: 'relative',
    },

    deleteButtonPosition: {
      position: 'absolute',
      right: 15,
      zIndex: 999,
      top: 7,
    },

    cursorPointer: {
      cursor: 'pointer',
    },

    commonTable: {
      '& .mobileCollegeName': {
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#525454',

        [theme.breakpoints.down('xs')]: {
          fontSize: 16,
          lineHeight: 'initial',
          whiteSpace: 'break-spaces',
        },
      },
      '& .tableBorder': {
        borderBottom: `1px solid ${colors.WHITE_SEVEN}`,
      },

      '& .MuiTable-root': {
        borderSpacing: '0px 9px',
        borderCollapse: 'separate',

        '& thead': {
          '& tr': {
            '& th': {
              fontWeight: 600,
              fontSize: 12,
              color: '#272929',
              border: 0,
              padding: '0px 8px',
              letterSpacing: '0.018em',
              textTransform: 'uppercase',
              fontFamily: fonts.POPPINS_FONT,
            },
          },
        },

        '& .emptyThCell': {
          '& th': {
            height: 0,
            lineHeight: 0,
          },
        },

        '& .tdBoldText': {
          fontWeight: 600,
          fontSize: 18,
          color: '#525454',
          letterSpacing: '-0.014em',
        },

        '& tbody': {
          '& tr': {
            borderRadius: 15,
            boxShadow: '0px 2px 7px rgba(68, 68, 68, 0.07)',

            '& td': {
              height: 52,
              padding: '5px 8px',
              borderLeft: 0,
              borderRight: 0,
              fontSize: 16,
              color: '#272929',
              fontFamily: fonts.INTER,
              letterSpacing: '-0.011em',
              border: '1px solid #D4D4D4',
              background: Colors.WHITE_ONE,

              '&:first-child': {
                paddingLeft: 28,
                borderRadius: '15px 0px 0px 15px',
                borderLeft: '1px solid #D4D4D4',

                [theme.breakpoints.down('xs')]: {
                  paddingLeft: 10,
                },
              },

              '&:last-child': {
                borderRadius: '0px 15px 15px 0px',
                borderRight: '1px solid #D4D4D4',
              },
            },
          },
        },

        '& .tableLinkText': {
          fontFamily: fonts.INTER,
          fontSize: 16,
          letterSpacing: '-0.011em',
          color: '#2F80ED',
        },

        '& .tableLinkTextMobile': {
          textDecoration: 'underline !important',
        },

        '& .acceptanceWidth': {
          width: '130px !important',
        },

        '& .sortingButtonWidth': {
          width: 130,
        },

        '& .sortingUpcoming': {
          width: '165px !important',
        },

        '& .sortingMembership': {
          width: '130px !important',
        },

        '& .applicationStatus': {
          fontFamily: fonts.POPPINS_FONT,
          fontWeight: 600,
          fontSize: 14,
          letterSpacing: '0.006em',
          textTransform: 'uppercase',

          '& .completed': {
            color: '#45CE93',
          },

          '& .notStarted': {
            color: '#343a40',
          },

          '& .inProgress': {
            color: '#007bff',
          },
        },

        '& .MuiTableCell-footer': {
          padding: 0,
          border: 0,

          '& .MuiTablePagination-caption': {
            fontSize: 16,
            letterSpacing: '-0.011em',
            color: '#7D7F7F',
          },
        },

        '& .MuiAvatarGroup-root': {
          width: 72,
          marginRight: 13,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        },

        '& .theadAvatarSpacing': {
          paddingLeft: 108,
        },

        '& .tableLink': {
          fontSize: 16,
          color: '#2F80ED',
          letterSpacing: '-0.011em',
          textDecoration: 'underline !important',
        },

        '& .sortingButton': {
          width: 104,
          height: 25,
          padding: 5,
          borderRadius: 10,
          display: 'flex',
          alignItems: 'center',
          paddingLeft: 14,

          '& img': {
            marginLeft: 9,
          },

          '&:hover': {
            cursor: 'pointer',
            background: '#EAEAEA',
          },
        },

        '& td.sortingTdPadding': {
          paddingLeft: 22,
        },

        '& .collegeLogo': {
          width: 34,
          height: 34,
          marginRight: 28,

          [theme.breakpoints.down('xs')]: {
            width: 30,
            minWidth: 30,
            height: 30,
            marginRight: 5,
          },

          '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          },
        },

        '& .noRecordFound': {
          borderRadius: '15px !important',
        },
      },
    },

    tableAccordionChild: {
      '& .MuiAccordionSummary-root': {
        borderBottom: '0px !important',
        minHeight: 48,

        '& .MuiSvgIcon-root': {
          color: '#7D7F7F !important',
        },
      },

      '& .MuiAccordionDetails-root': {
        paddingTop: '0px !important',
      },

      '& .accordionTitleChild': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '0.006em',
        color: '#7D7F7F',
      },
    },

    tableAccordion: {
      boxShadow: 'none',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
        alignItems: 'flex-start !important',
      },
      '& .MuiAccordionSummary-root': {
        padding: 0,
        minHeight: 64,
        justifyContent: 'flex-start',
        borderBottom: '1px solid #D4D4D4',

        [theme.breakpoints.down('xs')]: {
          justifyContent: 'space-between',
        },

        '& .MuiSvgIcon-root': {
          color: '#272929',
        },
      },

      '& .MuiAccordionSummary-content': {
        margin: 0,
        flexGrow: 'initial',
      },

      '& .MuiAccordionDetails-root': {
        padding: '20px 0px 0px',
      },

      '& .accordionTitle': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '-0.019em',
        color: '#272929',
        [theme.breakpoints.down('xs')]: {
          fontSize: 18,
          padding: 0,
        },
      },
      '& .appTitle': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '-0.019em',
        color: '#272929',
        [theme.breakpoints.down('xs')]: {
          fontSize: 18,
          padding: '20px 0px',
        },
      },
      '& .additionalSurveyTitle': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '-0.019em',
        color: '#272929',
        [theme.breakpoints.down('xs')]: {
          fontSize: 18,
        },
      },
      '& .appSubtitle': {
        fontFamily: fonts.INTER,
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: '-0.006em',
        color: Colors.GRAY_CHECKBOX_BG,
      },
    },

    additionSurveyDetail: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      paddingTop: 20,
      '& .MuiTypography-h6': {
        fontWeight: 600,
        fontSize: 14,
        color: Colors.BLACK_TWELVE,
        letterSpacing: '-0.006em',
        fontFamily: fonts.INTER,
      },
      '& .MuiTypography-body2': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: Colors.BLACK_TWELVE,
        fontFamily: fonts.INTER,
      },
      '& .finalDecision': {
        paddingLeft: 150,
        [theme.breakpoints.down('xs')]: {
          paddingLeft: 0,
        },
      },
      '& .earlyAction': {
        paddingLeft: 75,
        [theme.breakpoints.down('xs')]: {
          paddingLeft: 0,
        },
      },
      '& .earlyDecision': {
        paddingLeft: 60,
        [theme.breakpoints.down('xs')]: {
          paddingLeft: 0,
        },
      },
      '& .meritScholarship': {
        paddingLeft: 70,
        [theme.breakpoints.down('xs')]: {
          paddingLeft: 0,
        },
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },

    genericBreadcrumb: {
      background: '#0A2463',
      padding: '15px 0px 24px',

      [theme.breakpoints.down('xs')]: {
        padding: '14px 0px 33px',
      },

      '& .MuiButton-contained': {
        background: '#45CE93',
        borderRadius: 12,
        fontFamily: fonts.POPPINS_FONT,
        letterSpacing: '-0.006em',
        color: Colors.WHITE_ONE,
        marginBottom: 11,
        minWidth: 174,
        height: 40,
        padding: '0px 20px',
        display: 'flex',
        justifyContent: 'flex-start',
        textTransform: 'capitalize',

        '& .MuiButton-label': {
          fontWeight: 700,
          fontSize: 14,
        },

        '& .textSpan': {
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
        },

        [`@media only screen and (min-width: 600px) and (max-width: 650px)`]: {
          margin: '12px 0px 0px 155px',
        },

        [theme.breakpoints.down('xs')]: {
          margin: '12px 0px 0px 75px',
        },

        '& img': {
          marginRight: 12,
        },
      },

      '& .studentAvatar': {
        width: 97,
        height: 97,
        backgroundSize: 'cover',
        borderRadius: '50%',
        marginRight: 27,
        backgroundColor: '#e4e4e4',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',

        [theme.breakpoints.down('xs')]: {
          width: 59,
          height: 59,
          marginRight: 15,
        },
      },

      '& .studentInformation': {
        paddingLeft: 30,

        [theme.breakpoints.down('xs')]: {
          padding: 0,
        },

        '& .MuiTypography-h3': {
          fontFamily: fonts.POPPINS_FONT,
          fontWeight: 700,
          lineHeight: '42px',
          fontSize: 36,
          letterSpacing: '-0.022em',
          color: Colors.WHITE_ONE,

          [theme.breakpoints.down('xs')]: {
            fontSize: 20,
          },
        },

        '& .MuiTypography-h6': {
          fontFamily: fonts.POPPINS_FONT,
          fontWeight: 600,
          fontSize: 14,
          letterSpacing: '0.006em',
          textTransform: 'uppercase',
          color: Colors.WHITE_ONE,
        },
      },

      '& .breadcrumbResponsive': {
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
      },
    },

    studentInformationWrapper: {
      padding: '46px 0px 50px',

      '& .Mui-selected': {
        padding: 0,
        borderBottom: `1px solid ${Colors.GRAY_TAB_BORDER}`,
      },

      '& .MuiTabs-indicator': {
        display: 'none',
      },

      '& .MuiTab-root': {
        minWidth: 'auto',
        minHeight: 35,
        padding: 0,
        marginRight: 51,

        '&:last-child': {
          marginRight: 0,
        },
      },

      '& .rootTabs > div': {
        '& > span.MuiTabs-indicator': {
          backgroundColor: '#525454',
          height: '1px',
        },

        '& > div button': {
          textTransform: 'none',
          fontFamily: fonts.POPPINS_FONT,
          fontSize: 16,
          fontWeight: 400,
          letterSpacing: '-0.006em',
          color: '#525454',
          opacity: 1,

          '&.Mui-selected': {
            fontWeight: 600,
          },
        },
      },

      [theme.breakpoints.down('xs')]: {
        padding: '21px 0px',
      },
    },

    studentMeetingsTabs: {
      '& .MuiTab-root': {
        fontSize: 16,
        textTransform: 'capitalize',
        fontFamily: fonts.POPPINS_FONT,
      },
    },

    studentSectionHeader: {
      fontFamily: fonts.POPPINS_FONT,
      fontWeight: 600,
      fontSize: 24,
      letterSpacing: '-0.019em',
      color: '#272929',
      paddingBottom: 14,
      borderBottom: '1px solid #D4D4D4',
      marginBottom: 25,

      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
      },
    },
    pastMeetings: {
      borderBottom: 'none',
      paddingTop: 50,
    },

    personalInfoStudent: {
      marginBottom: 37,

      '& .MuiTypography-body2': {
        fontFamily: fonts.INTER,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
      },

      '& .emailPhone': {
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
        },

        '& img': {
          marginRight: 14,
        },
      },

      '& .emailSpacing': {
        marginRight: 29,

        '& .MuiTypography-body2': {
          wordBreak: 'break-all',

          [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
        },
      },
    },

    educationInformation: {
      '& .MuiTypography-h6': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        color: '#272929',
        width: 110,
        marginRight: 36,
      },

      '& .MuiTypography-body2': {
        fontFamily: fonts.INTER,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
      },

      '& .MuiBox-root': {
        marginBottom: 12,
      },

      '& .excludeCheckbox': {
        marginTop: 10,
        position: 'relative',
        right: 12,
      },
    },

    guardianTable: {
      marginBottom: 83,

      [theme.breakpoints.down('xs')]: {
        marginBottom: 18,
      },

      '& .emailWidth': {
        fontSize: 14,
      },

      '& thead': {
        '& tr': {
          '& th': {
            border: 0,
            padding: 0,
          },
        },
      },

      '& tbody': {
        '& tr': {
          '& td': {
            fontFamily: fonts.INTER,
            fontSize: 14,
            letterSpacing: '-0.006em',
            color: '#525454',
            border: 0,
            padding: '0px 0px 20px',

            '& a': {
              color: '#525454',
              textDecorationLine: 'underline !important',
            },
          },
        },
      },
    },

    milestoneFeature: {
      marginBottom: 77,

      [theme.breakpoints.down('xs')]: {
        marginBottom: 50,
      },

      '& .MuiTypography-h6': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        color: '#272929',
        width: 283,

        [theme.breakpoints.down('xs')]: {
          width: 227,
        },
      },

      '& .guardianType': {
        fontWeight: 400,
        textTransform: 'capitalize',
        paddingLeft: 40,
        fontFamily: fonts.INTER,
        width: 283,

        [theme.breakpoints.down('xs')]: {
          margin: '0px !important',
          paddingLeft: 31,
          width: 227,
        },
      },

      '& .MuiTypography-body2': {
        fontSize: 12,
        color: '#A9A9A9',
        fontWeight: 600,
        fontFamily: fonts.POPPINS_FONT,
        letterSpacing: '0.018em',
        textTransform: 'uppercase',

        '& a': {
          color: '#7D7F7F',
          textTransform: 'capitalize',
          marginLeft: 10,
          fontFamily: fonts.INTER,
          letterSpacing: '-0.006em',
          fontWeight: 'normal',
          textDecorationLine: 'underline !important',
        },
      },

      '& .MuiBox-root': {
        marginBottom: 12,
      },

      '& .completed': {
        color: '#45CE93',
      },

      '& .notStarted': {
        color: '#343a40',
      },

      '& .inProgress': {
        color: '#007bff',
      },
    },

    packageDetail: {
      marginBottom: 63,

      [theme.breakpoints.down('xs')]: {
        marginBottom: 48,
      },

      '& .incentiveNavigateButton': {
        fontSize: 14,
        minWidth: 183,
        fontWeight: 600,
        borderRadius: 6,
        color: '#FFFFFF',
        padding: '4px 10px',
        background: '#63A0F2',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.084px',
        textTransform: 'capitalize',

        '&:hover': {
          boxShadow: 'none',
        },
      },

      '& .MuiTypography-h6': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 'bold',
        fontSize: 24,
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        marginBottom: 3,

        '&.elite': {
          color: '#0A2463',
        },

        '&.ivyPlus': {
          color: '#45CE93',
        },
      },

      '& .MuiTypography-body2': {
        fontSize: 16,
        color: '#A9A9A9',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.011em',
        textTransform: 'capitalize',
      },

      '& ul': {
        paddingLeft: 22,
        margin: 0,

        '& li': {
          fontFamily: fonts.INTER,
          fontSize: 14,
          letterSpacing: '-0.006em',
          color: '#525454',
          marginBottom: 22,

          '&:last-child': {
            marginBottom: 0,
          },
        },
      },
    },

    includeBox: {
      background: '#F4F4F4',
      borderRadius: 15,
      padding: '18px 13px 27px 18px',
      marginTop: 28,

      '& .MuiTypography-h6': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        color: '#525454',
        marginBottom: 19,
      },
    },

    essayPromptDialog: {
      cursor: 'pointer',
      fontFamily: fonts.INTER,
      fontSize: 16,
      letterSpacing: '-0.011em',
      color: '#2F80ED',
    },

    listPageStyle: {
      paddingTop: 65,

      [theme.breakpoints.down('md')]: {
        padding: '36px 0px 12px',
      },

      '& .MuiTypography-h2': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 'bold',
        fontSize: 36,
        letterSpacing: '-0.022em',
        color: '#272929',
        marginBottom: 54,

        [theme.breakpoints.down('sm')]: {
          fontSize: 24,
          marginBottom: 39,
        },
      },
    },

    studentDocumentsSearch: {
      paddingTop: 15,
      marginBottom: '25px !important',
    },

    studentDocumentsTab: {
      paddingBottom: 35,
    },

    searchComponentFilter: {
      marginBottom: '0px !important',

      [theme.breakpoints.down('md')]: {
        width: 362,
      },

      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginBottom: '20px !important',
      },

      '& .MuiInputBase-root': {
        [theme.breakpoints.down('md')]: {
          width: '100% !important',
        },
      },
    },

    searchComponent: {
      marginBottom: 10,

      [theme.breakpoints.down('sm')]: {
        marginBottom: 23,
      },

      '& .MuiInputBase-root': {
        width: 277,
      },

      '& input': {
        fontSize: 14,
        height: 40,
        width: '100%',
        borderRadius: 6,
        paddingLeft: '3rem',
        fontFamily: fonts.INTER,
        color: Colors.BLACK_TWELVE,
        letterSpacing: '-0.006em',
        boxSizing: 'border-box',
        border: '1px solid #D4D4D4',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center left 15px',
        backgroundImage: `url(${SEARCH_DARK_IMG})`,

        '&::placeholder': {
          opacity: 1,
          color: Colors.GRAY_TWENTYTWO,
        },
      },
    },

    studentListTabs: {
      '& .MuiButton-text': {
        fontFamily: fonts.POPPINS_FONT,
        fontSize: 16,
        letterSpacing: '-0.006em',
        color: '#525454',
        textTransform: 'capitalize',
        fontWeight: 'normal',
        minWidth: 85,
        padding: '8px 0px',
        marginRight: 22,
        borderRadius: 0,
        borderBottom: '1px solid transparent',

        '&:hover': {
          backgroundColor: 'transparent',
        },
      },

      '& .active': {
        fontWeight: 600,
        borderBottom: '1px solid #525454',
      },
    },

    listAvatar: {
      cursor: 'pointer',
    },

    onlineListMeetingTable: {
      '& .linkMeetingHeader': {
        fontSize: 12,
        color: '#000',
        fontWeight: 600,
        padding: '0px 40px 16px',
        letterSpacing: '0.216px',
        textTransform: 'uppercase',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& .headingBoxFirst': {
        width: '40%',
      },

      '& .headingBoxSecond': { width: '30%' },

      '& .headingBoxThird': {
        width: '30%',
        textAlign: 'center',
      },

      '& .dateText': {
        fontWeight: 600,
      },

      '& .minutesText': {
        [theme.breakpoints.down('xs')]: {
          marginBottom: 15,
        },
      },

      '& .meetingTextStyle': {
        fontSize: 16,
        fontWeight: 400,
        color: '#272929',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.176px',

        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
        },
      },

      '& .personsText': {
        marginTop: 9,

        '& svg': {
          marginRight: 8,
        },
      },

      '& .descriptionText': {
        fontSize: 12,
        fontWeight: 400,
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.072px',
      },

      '& .selectedMeeting': {
        borderRadius: 15,
        marginBottom: 13,
        cursor: 'pointer',
        padding: '18px 40px',
        border: '1px solid #D4D4D4',

        [theme.breakpoints.down('xs')]: {
          padding: 16,
        },

        '&:last-child': {
          marginBottom: 0,
        },
      },

      '& .selectedMeetingActive, & .selectedMeetingHover:hover': {
        cursor: 'pointer',
        background: '#EBF3FE !important',
        border: '1px solid #2F80ED !important',
      },

      '& .meetingDateText': {
        fontSize: 14,
        marginTop: 6,
        fontWeight: 400,
        color: '#000000',
        whiteSpace: 'nowrap',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
      },

      '& .noteButton': {
        padding: 5,
        marginLeft: 10,
      },
    },

    meetingNotScheduled: {
      fontSize: 16,
      paddingTop: 20,
      fontWeight: 400,
      color: '#A9A9A9',
      cursor: 'pointer',
      margin: '0px auto',
      lineHeight: '18px',
      fontFamily: fonts.INTER,
      letterSpacing: '-0.176px',
      borderBottom: '1px solid #A9A9A9',

      [theme.breakpoints.down('sm')]: {
        fontSize: 11,
      },
    },

    studentListTable: {
      marginTop: 30,
      paddingBottom: 100,

      '& th': {
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },

      '& .theadSingleAvatarSpacing': {
        paddingLeft: '5.4rem !important',
      },

      '& .MuiAvatar-root': {
        width: 36,
        height: 36,
      },

      '& .opacityEmail, .opacityPhone': {
        opacity: 0.3,
        cursor: 'not-allowed',
      },

      '& .socialIcons': {
        '& img': {
          marginRight: 14,
        },
      },
    },

    intakeFormHeader: {
      padding: '65px 0px 67px',

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '21px 0px',
      },

      '& .selectViewMenu': {
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
          alignItems: 'flex-start',
          paddingTop: 15,
        },
      },

      '& .MuiTypography-h2': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 'bold',
        fontSize: 36,
        letterSpacing: '-0.022em',
        color: '#272929',

        [theme.breakpoints.down('xs')]: {
          fontSize: 24,
        },
      },

      '& .MuiTypography-caption': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '0.006em',
        color: '#525454',
        texTransform: 'uppercase',
        marginRight: 20,
      },

      '& .MuiSelect-outlined.MuiSelect-outlined': {
        width: 280,
        padding: '10px 16px',
      },
    },

    intakeQuestions: {
      marginBottom: 48,

      '& .MuiTypography-h5': {
        fontFamily: fonts.INTER,
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#272929',
        marginBottom: 17,
      },

      '& .MuiTypography-body1': {
        fontFamily: fonts.INTER,
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#525454',
      },
    },

    mobileTableAccordion: {
      borderRadius: '10px !important',
      padding: '3px 14px',
      border: '1px solid #D4D4D4',
      marginBottom: 12,
      boxShadow: '0px 2px 7px rgba(68, 68, 68, 0.07)',

      '& .MuiTypography-body1': {
        fontFamily: fonts.INTER,
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#272929',
        fontWeight: 400,
      },

      '& .MuiTypography-h6': {
        fontWeight: 600,
        fontSize: 18,
        color: '#272929',
        letterSpacing: '-0.014em',
      },

      '&:before': {
        display: 'none',
      },

      '& .MuiAccordionSummary-root': {
        padding: 0,
        minHeight: 48,

        '&.Mui-expanded': {
          minHeight: 40,
        },
      },

      '& .MuiAccordionSummary-content': {
        margin: 0,
        width: '100%',
      },

      '& .MuiAccordionDetails-root': {
        padding: '0px 16px 0px 52px',
      },

      '& .meetingsAccordion': {
        padding: '0px 16px 0px 84px',
      },

      '& .MuiTypography-body2': {
        fontFamily: fonts.INTER,
        fontSize: 16,
        letterSpacing: '-0.011em',
        textDecorationLine: 'underline',
        color: '#2F80ED',
      },

      '&.Mui-expanded': {
        paddingTop: 16,
        paddingBottom: 16,
      },
    },

    mobileBreadcrumb: {
      [`@media only screen and (max-width: 650px)`]: {
        paddingTop: 25,
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },

    mobileTableBox: {
      width: '100%',
      margin: '21px 0px 26px',

      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },

      '& .MuiBox-root': {
        fontSize: 14,
        color: '#525454',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
        width: '100%',
      },

      '& a': {
        color: '#525454',
        textDecorationLine: 'underline !important',
      },

      '& .mobileEmailWidth': {
        [theme.breakpoints.down('xs')]: {
          wordBreak: 'break-all',
        },
      },
    },

    studentDocumentMobileView: {
      flexDirection: 'column',

      [theme.breakpoints.down('xs')]: {
        paddingTop: '0px !important',
      },

      '& .MuiCard-root': {
        borderRadius: 15,
        marginBottom: 7,
        padding: '13px 16px',
        border: '1px solid #D4D4D4',
        boxShadow: '0px 2px 7px rgba(68, 68, 68, 0.07)',

        '& .universityName': { whiteSpace: 'break-spaces' },

        '& .fileOpenText': {
          fontFamily: fonts.POPPINS_FONT,
          fontWeight: 600,
          fontSize: 12,
          letterSpacing: '0.018em',
          textTransform: 'uppercase',
          color: '#7D7F7F',
          paddingLeft: 9,
        },
      },
    },

    noRecordFoundBox: {
      borderRadius: '15px !important',
      boxShadow: '0px 2px 7px rgb(68 68 68 / 7%)',
      border: '1px solid #D4D4D4',
      padding: '13px 15px',
      color: '#272929',
      fontSize: 16,
      fontFamily: fonts.INTER,
      letterSpacing: '-0.011em',
    },

    studentAdvisors: {
      marginBottom: 31,

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },

      '& .mobileEmailChat': {
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'row',
          padding: '12px 0px 0px 95px',
        },
      },

      '& .dateText': {
        color: '#A9A9A9 !important',
      },

      '& .MuiAvatar-root': {
        width: 76,
        height: 76,
        marginRight: 20,
      },

      '& .MuiTypography-h6': {
        fontFamily: fonts.INTER,
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#272929',
        maxWidth: 198,
        lineHeight: '24px',

        [theme.breakpoints.down('sm')]: {
          maxWidth: '100%',
          whiteSpace: 'break-spaces',
        },
      },

      '& .MuiTypography-body1': {
        fontFamily: fonts.INTER,
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#7D7F7F',
        maxWidth: 198,
        lineHeight: '24px',

        [theme.breakpoints.down('sm')]: {
          maxWidth: '100%',
          whiteSpace: 'break-spaces',
        },
      },

      '& a': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        color: '#525454',

        '&:first-child': {
          [theme.breakpoints.down('sm')]: {
            marginRight: 48,
          },
        },

        '& img': {
          marginRight: 13,
        },
      },
    },

    categoryThPadding: {
      paddingLeft: '45px !important',
    },

    mobileStatusSorting: {
      paddingBottom: 24,

      '& .MuiTypography-body1': {
        fontFamily: fonts.INTER,
        fontSize: 14,
        lineHeight: '24px',
        letterSpacing: '-0.006em',
        color: '#272929',
        marginBottom: 8,
      },

      '& legend': {
        display: 'none',
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: 6,
        border: '1px solid #D4D4D4',
        height: 40,
      },

      '& .MuiFormControl-root': {
        width: 173,
      },

      '& .MuiSelect-outlined.MuiSelect-outlined': {
        padding: '6px 16px 8px',
        fontFamily: fonts.INTER,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        backgroundImage: `url(${DROPDOWN_ARROW_IMG})`,
        backgroundSize: '14px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center right 16px',
        backgroundColor: 'transparent',
      },

      '& .MuiSvgIcon-root': {
        display: 'none',
      },
    },

    accordionDirection: {
      flexDirection: 'column',
    },

    applicationTable: {
      '& .collegeAssist': {
        width: 18,
        marginRight: 5,
      },

      '& .sortingColumn': {
        height: 25,
        padding: 5,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 14,

        '& img': {
          marginLeft: 9,
          alignItems: 'center',
        },

        '&:hover': {
          cursor: 'pointer',
          background: '#EAEAEA',
        },
      },
      '& .selectedColumn': {
        background: '#EAEAEA',
      },
      '& .acceptanceCol': {
        width: '130px !important',
      },
      '& .dueDateCol': {
        width: '100px !important',
      },
      '& .statusCol': {
        width: '95px !important',
      },
    },

    selectedColumnSorting: {
      background: '#EAEAEA',
    },

    tableEmptyRecord: {
      boxShadow: 'none !important',

      '& td': {
        background: '#F4F4F4 !important',
        borderRadius: '10px !important',
        border: '0px !important',
        boxShadow: 'none !important',
        fontSize: '16px !important',
        letterSpacing: '-0.011em !important',
        color: '#A9A9A9 !important',
        padding: '33px 45px !important',
      },
    },

    moveToRight: {
      float: 'inline-end',
      marginTop: '10px',
    },

    outOf: {
      fontFamily: fonts.INTER,
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: '-0.006em',
      textalign: 'right',
      color:'#7D7F7F',

    },

    viewAll: {
      cursor: 'pointer',
      marginTop: '5px',
      color: '#2F80ED',
      textDecoration: 'underline',
      float: 'inline-end',
      fontFamily: fonts.INTER,
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: '-0.011em',
    },

    emptyStates: {
      background: '#F4F4F4 !important',
      borderRadius: '15px !important',
      padding: '22px 33px !important',
      border: '0px !important',

      [theme.breakpoints.down('xs')]: {
        padding: 16,
      },

      '& .MuiTypography-body1': {
        fontWeight: 400,
        fontSize: 16,
        color: '#A9A9A9',
        letterSpacing: '-0.011em',
        fontFamily: fonts.INTER,
      },
    },

    disabledIcon: {
      opacity: 0.2,
      cursor: 'not-allowed',
    },

    disabledButton: {
      background: '#d4d4d4 !important',
      cursor: 'not-allowed !important',
    },
    shareDriveUrl: {
      marginBottom: 44,

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },

      '& .MuiTypography-body1': {
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        color: '#272929',
        margin: '0px 22px 0px 0px',
        fontFamily: fonts.POPPINS_FONT,

        [theme.breakpoints.down('md')]: {
          margin: 0,
        },
      },

      '& input': {
        maxWidth: 634,
        marginRight: 13,
        height: 32,

        [`@media only screen and (max-width: 1440px)`]: {
          maxWidth: 500,
        },

        [theme.breakpoints.down('md')]: {
          margin: '15px 0px',
        },
      },

      '& .MuiButton-contained': {
        width: 109,
        height: 32,
        background: '#2F80ED',
        borderRadius: 12,
        fontWeight: 700,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#FFFFFF',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& a': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#2F80ED',
        borderBottom: '1px solid #2F80ED',
        fontFamily: fonts.INTER,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },

      '& .MuiIconButton-root': {
        marginLeft: 'auto',

        [theme.breakpoints.down('md')]: {
          marginLeft: 'inherit',
        },
      },
    },

    LinkButtonText: {
      textTransform: 'capitalize',
    },

    linkFormCopied: {
      background: '#2668C1 !important',
    },

    linkForm: {
      '&.MuiButton-root': {
        minWidth: 191,
        height: 40,
        background: '#2F80ED',
        borderRadius: 12,
        fontWeight: 700,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#FFFFFF',
        fontFamily: fonts.POPPINS_FONT,

        '&:hover': {
          backgroundColor: '#2668C1',
        },

        '& img': {
          marginRight: 10,
        },
      },
    },
    bookingTable: {
      overflow: 'auto',
      width: '100%',
    },
    bookingTableWrapper: {
      borderCollapse: 'separate',
      borderSpacing: '0px 9px',
      '& tbody': {
        '& tr': {
          '& td': {
            padding: '8px 15px 9px 23px',
            backgroundColor: Colors.WHITE_ONE,
            border: `1px solid ${Colors.WHITE_SEVEN}`,
            fontFamily: fonts.POPPINS_FONT,
            borderLeft: 0,
            borderRight: 0,
            fontWeight: 300,
            whiteSpace: 'nowrap',
            minHeight: 52,
            fontSize: 14,

            '& h6': {
              fontFamily: fonts.INTER,
              fontSize: 16,
              fontWeight: 400,
              letterSpacing: '-0.011em',
              color: Colors.BLACK_TWELVE,
              textTransform: 'capitalize',
            },

            '& p': {
              fontFamily: fonts.INTER,
              fontSize: 14,
              fontWeight: 400,
              letterSpacing: '-0.011em',
              color: '#272929',
            },

            '&:first-child': {
              borderRadius: '15px 0px 0px 15px',
              borderLeft: `1px solid #D4D4D4`,
            },
            '&:last-child': {
              borderRadius: '0px 15px 15px 0px',
              borderRight: `1px solid #D4D4D4`,
            },

            "& [class*='.MuiAvatar-root']": {
              width: 33,
              height: 33,
            },
          },
        },
      },
    },
    tableHead: {
      fontFamily: fonts.POPPINS_FONT,
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: '0.006em',
      color: Colors.BLACK_TWELVE,
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      padding: '0px 22px',
    },
    emptyTableCell: {
      fontFamily: fonts.INTER,
      background: '#F4F4F4 !important',
      borderRadius: '10px !important',
      border: '0px !important',
      padding: '32px 45px !important',
      fontSize: '16px !important',
      letterSpacing: '-0.011em !important',
      color: '#A9A9A9 !important',
    },
  })
);

export const useStylesTable = makeStyles((theme: Theme) =>
  createStyles({
    forDropdownArrow: {
      marginLeft: '10px',

      '& .MuiSelect-icon': {
        display: 'none',
      },

      '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#d4d4d4',
          borderWidth: 1,
        },

        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#d4d4d4',
        },
      },

      '& .MuiInputBase-root': {
        backgroundImage: `url(${SELECT_ARROW_IMG})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center right 18px',
        borderRadius: 6,
        height: 40,
        border: '0px !important',
        fontFamily: fonts.INTER,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        padding: '8px 16px',
        width: '100%',
        backgroundColor: '#fff',
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#d4d4d4',
      },

      '& .MuiInput-underline:after, .MuiInput-underline:before': {
        display: 'none',
      },

      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
    },

    studentGradYear: {
      width: 130,
      margin: '0px 12px',

      [theme.breakpoints.down('md')]: {
        margin: '15px 0px',
        width: 362,
      },

      [theme.breakpoints.down('xs')]: {
        margin: '0px 0px 15px',
        width: '100%',
      },

      '& .MuiSelect-outlined.MuiSelect-outlined': {
        paddingLeft: 0,
      },
    },

    filterBySelect: {
      margin: 0,

      [theme.breakpoints.down('sm')]: {
        marginBottom: 20,
      },

      '& .fieldCaption': {
        fontSize: 12,
        marginBottom: 4,
        fontWeight: 400,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
        color: Colors.BLACK_TWELVE,
      },

      '& .MuiInputBase-root': {
        height: 32,
        width: 294,
        paddingRight: 31,
      },

      '& .MuiSelect-selectMenu': {
        padding: 0,
      },
    },

    studentPackageType: {
      margin: '0px 12px 0px 0px',
      width: 210,

      [theme.breakpoints.down('md')]: {
        margin: '0px 0px 20px',
        width: 362,
      },

      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },

      '& .MuiSelect-outlined.MuiSelect-outlined': {
        paddingLeft: 0,
      },
    },

    forMobileWrap: {
      marginBottom: 65,

      [theme.breakpoints.down('md')]: {
        width: '100%',
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
  })
);
