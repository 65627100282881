import { useEffect, useContext } from 'react';
import gql from 'graphql-tag';
import { AppContext } from '../contexts';

const UPDATE_MENTOR_LAST_LOGIN_LOCATION_MUTATION = gql`
  mutation UpdateMentorMutationUpdateLastLogin($id: Int!) {
    updateMentor(input: { id: $id }) {
      lastLoginAt
    }
  }
`;

const UpdateLastLoginAndLocation = ({ client }: { client: any }) => {
  const { user } = useContext(AppContext);
  const mountEffect = () => {
    const updateLastLoginAt_Location = async () => {
      try {
        if (user && user.id) {
          await client.mutate({
            mutation: UPDATE_MENTOR_LAST_LOGIN_LOCATION_MUTATION,
            variables: { id: user.id },
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    updateLastLoginAt_Location();
  };
  useEffect(mountEffect, []);
  return <></>;
};

export default UpdateLastLoginAndLocation;
