import gql from 'graphql-tag';

export const GET_ALL_ADVISOR_BOOKINGS = gql`
  query getAllAdvisorBookingsQuery(
    $status: AdvisorMeetingStatus
    $bookingStartTime: String
    $bookingEndTime: String
    $searchQuery: String
    $limit: Int
    $page: Int
    $sortByDate: Boolean
    $studentId: Int
  ) {
    allAdvisorBookings(
      input: {
        status: $status
        bookingStartTime: $bookingStartTime
        bookingEndTime: $bookingEndTime
        searchQuery: $searchQuery
        limit: $limit
        page: $page
        sortByDate: $sortByDate
        studentId: $studentId
      }
    ) {
      advisorBookings {
        id
        AdvisorId
        startTime
        endTime
        StudentId
        event_id
        status
        title
        note
        zoomLink
        createdAt
        canceledReason
        otherReason
        isHourLogged
        HoursLoggedBy
        associatedMentorTimeLog {
          id
          activityAt
          activityDescription
          workType
          note
          activityDuration
          MentorPayoutId
          workActivity
          amount
          airtableSubmitted
          durationNote
          missCall
          isFullTime
          MentorTimeLogAssociations {
            id
            advisorBookingId
            otherMeetingDescription
            noteForExtendedMeeting
            noteForNoOrientationCall
            allAssociatedAdvisors {
              firstName
              lastName
            }
            studentApplications {
              id
              applicationRequirement {
                id
                college {
                  id
                  name
                  ParentCollegeId
                }
              }
            }
          }
          premiumEntitlement {
            admissionPackageElement {
              packageElement {
                name
              }
              admissionPackage {
                name
              }
            }
          }
          mentorPaymentCategory {
            id
            rate
          }
          student {
            id
            firstName
            lastName
            initialKickOffDate
            email
          }
          mentorPayout {
            status
            amount
            arrivalDate
          }
          mentor {
            id
            firstName
            lastName
            stripeAccountId
          }
          onBoarding {
            onBoarded
          }
          milestone
          serviceElement
          approvedDate
          createdAt
        }
        student {
          id
          firstName
          lastName
          pictureUrl
          email
          initialKickOffDate
        }
        allParticipants {
          id
          firstName
          lastName
          pictureUrl
          roles
          zoomLink
          email
        }
        cancelledByUser {
          id
          firstName
          lastName
        }
      }
      count
      nextRecord
    }
  }
`;
