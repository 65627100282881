import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import Colors from '../../../common/colors';
import fonts from '../../../common/fonts';

import SELECT_ARROW_IMG from '../../../img/select-arrow.svg';

export const useStylesTable = makeStyles((theme: Theme) =>
  createStyles({
    commonTable: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        overflow: 'auto',
      },

      '& .MuiTable-root': {
        borderSpacing: '0px 9px',
        borderCollapse: 'separate',

        '& thead': {
          '& tr': {
            '& th': {
              fontWeight: 600,
              fontSize: 12,
              color: '#272929',
              border: 0,
              padding: '8px 20px 0px',
              letterSpacing: '0.018em',
              textTransform: 'uppercase',
              fontFamily: fonts.POPPINS_FONT,

              [theme.breakpoints.down('sm')]: {
                whiteSpace: 'nowrap',
              },

              '&:first-child': {
                paddingLeft: 28,
              },

              '&:nth-child(2)': {
                paddingLeft: 7,
              },

              '&:nth-child(4)': {
                paddingLeft: 7,
              },
            },
          },
        },

        '& .applicationStatus': {
          fontFamily: fonts.POPPINS_FONT,
          fontWeight: 600,
          fontSize: 14,
          letterSpacing: '0.006em',
          textTransform: 'uppercase',

          '& .completed': {
            color: '#45CE93',
          },

          '& .notStarted': {
            color: '#A9A9A9',
          },

          '& .inProgress': {
            color: '#007bff',
          },
        },

        '& tbody': {
          '& tr': {
            borderRadius: 15,
            boxShadow: '0px 2px 7px rgba(68, 68, 68, 0.07)',

            '& td': {
              height: 52,
              padding: '5px 20px',
              borderLeft: 0,
              borderRight: 0,
              fontSize: 16,
              color: '#272929',
              fontFamily: fonts.INTER,
              letterSpacing: '-0.011em',
              border: '1px solid #D4D4D4',
              background: Colors.WHITE_ONE,

              [theme.breakpoints.down('sm')]: {
                whiteSpace: 'nowrap',
              },

              '&:first-child': {
                paddingLeft: 38,
                fontWeight: 'bold',
                borderRadius: '15px 0px 0px 15px',
                borderLeft: '1px solid #D4D4D4',
              },

              '&:last-child': {
                borderRadius: '0px 15px 15px 0px',
                borderRight: '1px solid #D4D4D4',
              },
            },
          },
        },

        '& .noRecordFound': {
          borderRadius: '15px !important',
          fontSize: '16px !important',
          color: '#A9A9A9 !important',
        },

        '& .fontWeight': {
          fontWeight: 'normal !important',
        },

        '& .collapseTableRow': {
          boxShadow: 'none',

          '& td': {
            border: '0px !important',
            height: 'auto',
            padding: '0px !important',
          },

          '& .MuiCollapse-container': {
            height: 'auto',
            padding: '5px 28px',
            borderRadius: '15px !important',
            border: '1px solid #D4D4D4 !important',
            boxShadow: '0px 2px 7px rgb(68 68 68 / 14%)',
          },

          '& .MuiTypography-h6': {
            fontFamily: fonts.POPPINS_FONT,
            fontWeight: 600,
            fontSize: 14,
            letterSpacing: '0.006em',
            textTransform: 'uppercase',
            color: '#7D7F7F',
            marginRight: 3,
          },

          '& .MuiBox-root': {
            marginBottom: 7,
          },

          '& .MuiTypography-body1': {
            fontFamily: fonts.INTER,
            fontSize: 14,
            letterSpacing: '-0.006em',
            color: '#7D7F7F',
          },
        },

        '& .sortingButton': {
          width: 104,
          height: 25,
          padding: 5,
          borderRadius: 10,
          display: 'flex',
          alignItems: 'center',
          paddingLeft: 14,

          '& img': {
            marginLeft: 9,
          },

          '&:hover': {
            cursor: 'pointer',
            background: '#EAEAEA',
          },

          '&.dateWidth': {
            width: 86,
          },
        },
      },
    },

    payoutFilter: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },

      '& .MuiButton-text': {
        fontFamily: fonts.POPPINS_FONT,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#2F80ED',
        fontWeight: 'normal',
        textTransform: 'capitalize',
        padding: '8px 15px',

        '& img': {
          marginRight: 12,
        },

        [theme.breakpoints.down('xs')]: {
          marginTop: 20,
        },
      },
    },

    forDropdownArrow: {
      '& select': {
        backgroundImage: `url(${SELECT_ARROW_IMG})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center right 18px',
        borderRadius: 6,
        height: 40,
        border: '1px solid #D4D4D4',
        fontFamily: fonts.INTER,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#A9A9A9',
        padding: '8px 16px',
        width: 280,
        backgroundColor: '#fff',

        '&:focus': {
          outline: 'none',
        },
      },
    },

    paginationCell: {
      border: 0,
      padding: 0,
    },

    disablePayoutRow: {
      '& .MuiTableCell-root': {
        backgroundColor: '#fff8e4 !important',
      },

      '& .textLineThrough': {
        textDecoration: 'line-through',
      },

      '& .textGray': {
        color: '#a9a9a9 !important',
      },

      '& .disableActionsDisplay': {
        display: 'none',
      },

      '& .disableArrow': {
        opacity: 0.4,
      },
    },

    studentSearchPayout: {
      '& .react-select': {
        width: 280,
      },

      '& .react-container__indicator-separator': {
        display: 'none',
      },

      '& .react-container__control': {
        color: '#A9A9A9',
        border: '1px solid #D4D4D4',
        fontSize: 14,
        fontFamily: fonts.INTER,
        borderRadius: 6,
        letterSpacing: -0.006,
      },

      '& .MuiInputBase-input': {
        height: '38px !important',
        borderRadius: 6,
        width: '300px !important',
        padding: '0px 16px',
        backgroundPosition: 'center right 18px !important',
        backgroundSize: 10,
      },
    },
  })
);
