import { FC } from 'react';
import {
  Dialog,
  Box,
  Typography,
  Button,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import fonts from '../common/fonts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    confirmationDialog: {
      '& .MuiDialog-paper': {
        width: 488,
        maxWidth: 488,
        border: '1px solid #D4D4D4',
        borderRadius: 25,
        padding: '35px 34px 31px',
        margin: 10,

        [theme.breakpoints.down('xs')]: {
          padding: 16,
        },
      },

      '& .MuiTypography-h4': {
        maxWidth: 406,
        color: '#272929',
        fontSize: 24,
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        letterSpacing: '-0.019em',
        lineHeight: '32px',
        marginBottom: 4,
      },
      '& .MuiTypography-body1': {
        color: '#525454',
        fontSize: 14,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
      },

      '& .MuiButton-root': {
        textTransform: 'capitalize',
        height: 40,
        borderRadius: 12,
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '-0.006em',
      },

      '& .MuiButton-root.MuiButton-containedPrimary': {
        backgroundColor: '#E94444 !important',
        minWidth: 151,
        marginRight: 16,

        [theme.breakpoints.down('xs')]: {
          minWidth: 120,
        },
      },

      '& .MuiButton-outlined': {
        minWidth: 194,
        color: '#272929',
        border: '1px solid #D4D4D4',

        [theme.breakpoints.down('xs')]: {
          minWidth: 140,
        },
      },
    },
  })
);

interface Props {
  title: string;
  firstButtonText: string;
  secondButtonText?: string;
  open: boolean;
  onClose: () => void;
  loading?: boolean;
  handleCancelBtn?: Function;
}

const ConfirmationModal: FC<Props> = ({
  title,
  firstButtonText,
  secondButtonText,
  open,
  onClose,
  loading,
  handleCancelBtn,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      className={classes.confirmationDialog}
    >
      <Box>
        <Box maxWidth={406} pb={3.5}>
          <Typography variant="h4">{title}</Typography>
          <Typography variant="body1">This action cannot be undone.</Typography>
        </Box>

        <Box display="flex" justifyContent="flex-start">
          <Button
            onClick={() => handleCancelBtn && handleCancelBtn()}
            variant="contained"
            color="primary"
            disabled={loading}
            className="dangerButton"
          >
            {loading
              ? firstButtonText === 'Cancel Meeting'
                ? `canceling...`
                : 'deleting...'
              : firstButtonText}
          </Button>

          {secondButtonText && (
            <Button variant="outlined" color="secondary" onClick={onClose}>
              {secondButtonText}
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmationModal;
