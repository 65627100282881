import {
  Box,
  ClickAwayListener,
  IconButton,
  ListItemIcon,
  Typography,
} from '@material-ui/core';
import { StyledMenu, StyledMenuItem } from '../../../common/CommonComponents';
import DeleteApplicationModal from './DeleteApplicationModal';
import MENU_DELETE_ICON from '../../../img/menu-delete.svg';
import { FC, MouseEvent, useState } from 'react';
import MENU_CIRCLE from '../../../img/menu-dark-icon.svg';
import CHANGE_DEADLINE_ICON from '../../../img/deadline.svg';
import ChangeApplicationDeadlineDialog from './ChangeApplicationDeadlineDialog';
import client from '../../../apollo';
import { UPDATE_APPLICATION_DECISION_MUTATION } from '../../../graphql/mutations/StudentDetailPage';
import moment from 'moment';

const MenuApplications: FC<{
  row: any;
  studentId: number;
  refetch: Function;
  setRefetchLoading: Function;
  gapYear: boolean;
  graduated: boolean;
  highSchoolYear: string;
}> = ({
  row,
  studentId,
  refetch,
  setRefetchLoading,
  gapYear,
  graduated,
  highSchoolYear,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  function closeMenu() {
    setAnchorEl(null);
  }
  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }
  const toggleModal = () => {
    setOpen(!open);
  };

  const refetchApplicationsAfterDeletion = async () => {
    try {
      setRefetchLoading(true);
      await refetch();
      setRefetchLoading(false);
    } catch (err) {
      setRefetchLoading(false);
    }
  };
  const {
    earlyDecisionDeadline,
    earlyActionDeadline,
    regularDecisionDeadline,
  } = row.applicationRequirement || {};

  const deadlines =
    row?.otherApplicationDeadlines?.map(
      (deadline: {
        DeadlineName: string;
        DeadlineDate: string;
        id: number;
      }) => {
        return {
          display: deadline?.DeadlineName,
          key: deadline?.DeadlineName,
          value: deadline?.DeadlineDate,
          delete: true,
          id: deadline?.id,
        };
      }
    ) || [];

  earlyDecisionDeadline &&
    deadlines.push({
      display: 'Early Decision',
      key: 'EarlyDecision',
      value: earlyDecisionDeadline,
      delete: false,
      id: null,
    });

  earlyActionDeadline &&
    deadlines.push({
      display: 'Early Action',
      key: 'EarlyAction',
      value: earlyActionDeadline,
      delete: false,
      id: null,
    });

  regularDecisionDeadline &&
    deadlines.push({
      display: 'Regular Decision',
      key: 'RegularDecision',
      value: regularDecisionDeadline,
      delete: false,
      id: null,
    });

  const DecisionStatus =
    deadlines.find(
      (deadline: { key: string }) => deadline.key === row?.decisionStatus
    )?.display || row?.decisionStatus;

  const onClose = (state: boolean) => {
    setOpenDialog(state);
  };
  const onSubmit = async (decision: string) => {
    setOpenDialog(false);
    const deadlineObject = deadlines?.find((obj: any) => obj.key === decision);

    const gapYearValue = gapYear ? 1 : 0;
    const studentHighSchoolYear = highSchoolYear
      ? parseInt(highSchoolYear)
      : new Date().getFullYear();
    const deadline = moment(deadlineObject.value);
    const deadlineMonth = deadline.month() + 1;
    if (!graduated) {
      if (deadline?.year() < studentHighSchoolYear) {
        if (deadlineMonth > 9 && deadlineMonth < 13) {
          deadline.year(studentHighSchoolYear + gapYearValue - 1);
        } else {
          deadline.year(studentHighSchoolYear + gapYearValue);
        }
      }
    }
    await client.mutate({
      mutation: UPDATE_APPLICATION_DECISION_MUTATION,
      variables: {
        studentApplicationId: row.id,
        decisionStatus: deadlineObject.key,
        decisionDeadline: deadline.utc().toDate(),
        studentId: studentId,
      },
    });
    refetch();
  };

  return (
    <ClickAwayListener onClickAway={closeMenu}>
      <Box>
        <IconButton
          onClick={handleClick}
          className="menuButton"
          style={{ height: 40, marginTop: -7, width: 45 }}
        >
          <img src={MENU_CIRCLE} alt="menu" />
        </IconButton>

        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeMenu}
        >
          {deadlines?.length > 1 && (
            <StyledMenuItem onClick={() => setOpenDialog(true)}>
              <ListItemIcon className="menu-item-style">
                <img src={CHANGE_DEADLINE_ICON} alt="deadline" />
              </ListItemIcon>
              <Typography variant="h6">CHANGE DEADLINE</Typography>
            </StyledMenuItem>
          )}

          <StyledMenuItem onClick={toggleModal}>
            <ListItemIcon className="menu-item-style">
              <img src={MENU_DELETE_ICON} alt="delete" />
            </ListItemIcon>
            <Typography variant="h6">DELETE</Typography>
          </StyledMenuItem>
        </StyledMenu>

        <DeleteApplicationModal
          id={row?.id}
          open={open}
          onClose={toggleModal}
          studentId={studentId}
          collegeName={row?.applicationRequirement?.college?.name || ''}
          refetch={refetchApplicationsAfterDeletion}
        />
        <ChangeApplicationDeadlineDialog
          open={openDialog}
          onClose={onClose}
          onSubmit={onSubmit}
          deadlines={deadlines || []}
          defaultValue={
            deadlines?.find(
              (deadline: any) => deadline?.display === DecisionStatus
            )?.key
          }
          gapYear={gapYear}
          graduated={graduated}
          highSchoolYear={highSchoolYear}
        />
      </Box>
    </ClickAwayListener>
  );
};

export default MenuApplications;
