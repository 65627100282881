import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import fonts from '../../common/fonts';
import colors from '../../common/colors';

export const useStylesMilestoneActivityDialog = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      top: '20px',
      right: '20px',
      [theme.breakpoints.down('sm')]: {
        top: '12px',
        right: '12px',
      },
    },
    dialogBox: {
      borderRadius: '20px',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
    },
    headingContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      width: '100%',
      height: '100%',
    },
    heading: {
      font: fonts.POPPINS_FONT,
      fontSize: '24px',
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
      },
    },
    subHeading: {
      font: fonts.POPPINS_FONT,
      fontSize: '16px',
      color: colors.GRAY_ALPHA,
      marginBottom: '20px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        marginBottom: '2px',
      },
    },

    tableHolder: {},

    titleCell: {
      backgroundColor: colors.GRAY_BOREDER,
      border: '1px solid',
      borderColor: colors.GRAY_FOUR,
      fontWeight: 600,
    },
    commonCell: {
      backgroundColor: colors.WHITE_ONE,
      border: '1px solid',
      borderColor: colors.GRAY_FOUR,
    },
    emptyCell: {
      backgroundColor: colors.WHITE_ONE,
      border: 'none',
      fontWeight: 600,
    },
    emptyCellRight: {
      backgroundColor: colors.WHITE_ONE,
      border: 'none',
      fontWeight: 600,
      textAlign: 'right',
    },
  })
);
