export const setWorkTypeValue = (
  value: string,
  setFieldValue: Function,
  paymentCategories: any
) => {
  switch (value) {
    case 'Other':
      setFieldValue(
        'PaymentCategoryId',
        paymentCategories.find(
          (item: any) => item && item?.paymentCategory?.name === 'offline'
        ).id || null
      );
      break;
    case 'Essay Editing/Review':
      setFieldValue(
        'PaymentCategoryId',
        paymentCategories.find(
          (item: any) => item && item?.paymentCategory?.name === 'essay'
        )?.id || null
      );
      break;
    case 'Independent Research for Student':
    case 'Correspondence with Family':
      setFieldValue(
        'PaymentCategoryId',
        paymentCategories.find(
          (item: any) => item && item?.paymentCategory?.name === 'offline'
        )?.id || null
      );
      break;
    case 'Free 20-Min Consultation':
    case 'Training':
    case 'Communication with Advisor Manager':
    case 'Internal Meetings':
    case 'Meeting with other Advisor/AO':
    case 'Communication with Service Team':
    case 'Review of Network Update':
    case 'Group Session':
    case 'Admin Work':
    case 'Monthly Check-In':
      setFieldValue(
        'PaymentCategoryId',
        paymentCategories.find(
          (item: any) => item && item?.paymentCategory?.name === 'offline'
        )?.id || null
      );
      break;
    case 'Video Chat Session, Solo':
    case 'Video Chat Session, 2+ Advisors':
    case 'Orientation/Kick-Off Call, Solo':
    case 'Orientation/Kick-Off Call, 2+ Advisors':
    case 'Partner - Leadership Initiatives':
      setFieldValue(
        'PaymentCategoryId',
        paymentCategories.find(
          (item: any) => item && item?.paymentCategory?.name === 'video'
        )?.id || null
      );
      break;
    case 'Onboarding':
      setFieldValue(
        'PaymentCategoryId',
        paymentCategories.find(
          (item: any) => item && item?.paymentCategory?.name === 'onboarding'
        )?.id || null
      );
      break;
    case 'Dress Rehearsal':
      setFieldValue(
        'PaymentCategoryId',
        paymentCategories.find(
          (item: any) =>
            item && item?.paymentCategory?.name === 'dress rehearsal'
        )?.id || null
      );
      break;
  }
};

export const setWorkTypeForMilestoneTeamValues = (
  value: string,
  entitlementType: string,
  setFieldValue: Function,
  paymentCategries: any
) => {
  const possiblePaymentCategories = paymentCategries.filter(
    (item: any) => item && item?.paymentCategory?.name.includes(value)
  );
  if (!!possiblePaymentCategories.length) {
    switch (entitlementType) {
      case 'Video Chat':
        setFieldValue(
          'PaymentCategoryId',
          possiblePaymentCategories.find(
            (item: any) => item && item?.paymentCategory?.name.includes('Video')
          )?.id || null
        );
        break;
      case 'Offline':
        setFieldValue(
          'PaymentCategoryId',
          possiblePaymentCategories.find(
            (item: any) =>
              item && item?.paymentCategory?.name.includes('Offline')
          )?.id || null
        );
        break;
      case 'Offline Editing':
        setFieldValue(
          'PaymentCategoryId',
          possiblePaymentCategories.find(
            (item: any) =>
              item && item?.paymentCategory?.name.includes('Offline Editing')
          )?.id || null
        );
        break;
      case 'Essay Editing':
        setFieldValue(
          'PaymentCategoryId',
          possiblePaymentCategories.find(
            (item: any) =>
              item && item?.paymentCategory?.name.includes('Essay Editing')
          )?.id || null
        );
        break;
    }
  } else {
    setFieldValue('PaymentCategoryId', null);
  }
};
