import gql from 'graphql-tag';

export const UPDATE_APPLICATION_STATUS_AND_DEADLINE = gql`
  mutation UpdateApplicationStatusAndDeadline(
    $decisionStatus: String
    $decisionDeadline: String
    $StudentApplicationId: Int!
  ) {
    updateApplicationStatusAndDeadline(
      input: {
        decisionStatus: $decisionStatus
        decisionDeadline: $decisionDeadline
        StudentApplicationId: $StudentApplicationId
      }
    ) {
      success
      message
    }
  }
`;

export const UPDATE_OTHER_ESSAY_STATUS = gql`
  mutation changeOtherEssayStatusMutation($essayId: Int!, $status: String) {
    changeOtherEssayStatus(essayId: $essayId, status: $status) {
      status
    }
  }
`;

export const UPDATE_STUDENT_ESSAY_STATUS = gql`
  mutation MUpdateEssayStatus(
    $applicationEssayId: Int!
    $status: String!
    $essayName: String
  ) {
    updateStudentApplicationEssaysStatus(
      input: {
        applicationEssayId: $applicationEssayId
        status: $status
        essayName: $essayName
      }
    ) {
      statusUpdated
    }
  }
`;

export const UPDATE_STUDENT_APPLICATION_STATUS = gql`
  mutation updateApplicationStatus(
    $studentApplicationId: Int!
    $status: String!
  ) {
    updateStudentApplicationStatus(
      input: { applicationId: $studentApplicationId, status: $status }
    ) {
      statusUpdated
    }
  }
`;

export const DELETE_APPLICATION_DEADLINE = gql`
  mutation removeDeadlineFromApplication($deadlineId: Int!) {
    removeNewDeadlineFromApplication(input: { deadlineId: $deadlineId }) {
      status
    }
  }
`;

export const ADD_NEW_STUDENT_APPLICATION_DEADLINE = gql`
  mutation addNewDeadlinesToStudentApplication(
    $studentApplicationId: Int!
    $deadlineName: String!
    $deadlineDate: String!
  ) {
    addNewDeadlineToApplication(
      input: {
        studentApplicationId: $studentApplicationId
        deadlineName: $deadlineName
        deadlineDate: $deadlineDate
      }
    ) {
      status
    }
  }
`;

export const SET_BSMD_STATUS = gql`
  mutation setBsmdStatusApplicationDetailMutation($id: Int!) {
    setBsmdStatus(id: $id) {
      success
    }
  }
`;

export const SET_ASSIST_STATUS = gql`
  mutation setAssistStatusApplicationMutation($id: Int!) {
    setAssistStatus(id: $id) {
      success
    }
  }
`;