import gql from 'graphql-tag';

export const GET_MILESTONE_ACTIVITY = gql`
    query GetMilestoneActivity($studentId: Int!) {
        getMilestoneActivity(studentId: $studentId){
            milestoneName
            vc
            offline
            lastActivity
            mentorName
            mentorId   
        }
    }
`;
