import { useEffect, useState } from 'react';
import { Box, Card, Grid, Typography } from '@material-ui/core';

import ActiveIncentivesTable from '../../../../components/activeIncentives/ActiveIncentivesTable';
import ActiveIncentivesDialog from '../../../../components/activeIncentives/ActiveIncentivesDialog';
import { useStyles } from '../../../../components/activeIncentives/StyleActiveIncentives';
import client from '../../../../apollo';
import { ALL_STUDENTS_INCENTIVE_BONUSES_QUERY } from '../../../../graphql/queries/IncentiveBonus';
import { getStudentsIncentiveBonusDetailQuery_getStudentsIncentiveBonusDetail_students } from '../../../../graphql/queries/IncentiveBonus/__generated__/getStudentsIncentiveBonusDetailQuery';
import { convertToUSD } from '../../../../constants';

const ActiveIncentives = () => {
  const classes = useStyles();
  const [students, setStudents] = useState<
    | getStudentsIncentiveBonusDetailQuery_getStudentsIncentiveBonusDetail_students[]
    | null
  >(null);
  const [totalAmount, setTotal] = useState<number | null>(null);
  const mountEffect = async () => {
    try {
      const { data } = await client.query({
        query: ALL_STUDENTS_INCENTIVE_BONUSES_QUERY,
        fetchPolicy: 'no-cache',
      });
      const getStudents = data?.getStudentsIncentiveBonusDetail.students || [];
      const getTotalAmount =
        data?.getStudentsIncentiveBonusDetail.totalAmount || 0;
      setStudents(getStudents);
      setTotal(getTotalAmount);
    } catch (error) {
      console.log('ActiveIncentiveError:::', error);
    }
  };
  // Call this useEffect while mounting
  useEffect(() => {
    mountEffect();
  }, []);

  return (
    <Grid container spacing={2} className={classes.activeIncentives}>
      <Grid item lg={8} md={8} xs={12}>
        <Box maxWidth={789}>
          <Typography variant="h3" className="headingText">
            Active Incentives
          </Typography>

          <Typography variant="body1" className="descriptionText bodyText">
            Here is where you can review your potential bonuses you can earn on
            each package once the student completes their lifecycle on
            CollegeAdvisor.com.
          </Typography>
        </Box>

        <ActiveIncentivesDialog />
      </Grid>

      <Grid item lg={4} md={4} xs={12}>
        <Card className={classes.availableBonusBox}>
          <Typography variant="body1" className="labelText bodyText">
            Maximum Bonus Available:
          </Typography>

          {totalAmount ? (
            <Typography variant="h6" className="priceText bodyText">
              ${convertToUSD(totalAmount)}
            </Typography>
          ) : (
            <>N / A</>
          )}
        </Card>
      </Grid>
      {students && totalAmount && (
        <ActiveIncentivesTable students={students} totalAmount={totalAmount} />
      )}
    </Grid>
  );
};

export default ActiveIncentives;
