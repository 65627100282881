import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Colors from '../common/colors';
import Fonts from '../common/fonts';
import { Badge } from 'react-bootstrap';

export const ButtonNext = styled(Button)`
  && {
    width: 186px;
    height: 54px;
    border-radius: 100px;
    background: ${Colors.WHITE_ONE};
    border: 1px solid ${Colors.WHITE_ONE};

    font-weight: 600;
    font-size: 18px;
    color: ${Colors.BLUE_FIVE};
    text-transform: capitalize;
    line-height: 23px;
    letter-spacing: 0.77px;
    box-shadow: none;
  }
`;

export const ButtonFiles = styled(Button)`
  && {
    background: ${Colors.BLUE_SEVEN};
    border-radius: 100px;
    padding: 8px 19px;
    font-family: ${Fonts.POPPINS_FONT};
    font-weight: 600;
    font-size: 12px;
    color: ${Colors.WHITE_ONE};
    box-shadow: none;
    line-height: 16px;
    margin-left: 25px;

    &:hover {
      background: ${Colors.BLUE_SEVEN};
      opacity: 0.9;
    }
  }
`;

export const AddMyColleges = styled.div`
  background-color: ${Colors.BLUE_SEVEN};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 21px;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 99;

  @media only screen and (max-width: 414px) {
    flex-direction: column;
  }

  h6 {
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.77px;
    color: ${Colors.WHITE_ONE};
    margin: 0px;
  }
`;

export const BadgeNumber = styled(Badge)`
  background: ${Colors.WHITE_ONE};
  border-radius: 20px;
  padding: 2px 14px;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.77px;
  color: ${Colors.BLACK_SIX};
  font-weight: normal;
  font-family: ${Fonts.POPPINS_FONT};
  margin-right: 3px;
`;

export const TextFieldSearch = styled.div`
  .MuiInputLabel-filled {
    transform: translate(0px, 0px) scale(1);
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${Colors.BLUE_SEVEN};
  }

  .MuiInputLabel-formControl {
    font-family: ${Fonts.POPPINS_FONT};
    color: ${Colors.GRAY_TWO};
    text-align: center;
  }

  .MuiInputLabel-shrink {
    color: ${Colors.BLUE_SEVEN};
    font-family: ${Fonts.POPPINS_FONT};
    font-size: 14px;
    letter-spacing: -0.172549px;
  }

  .MuiInputBase-root {
    font-family: ${Fonts.POPPINS_FONT};
    font-size: 24px;
    height: 45px;
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid ${Colors.GRAY_ELEVEN};
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid ${Colors.GRAY_ELEVEN};
  }

  .MuiInput-underline:after {
    border-bottom: 1px solid ${Colors.BLUE_SEVEN};
  }

  .MuiFilledInput-underline:after {
    border-bottom: 1px solid ${Colors.BLUE_SEVEN};
  }

  .MuiFilledInput-underline:before {
    border-bottom: 1px solid ${Colors.GRAY_ELEVEN};
  }

  .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(0px, -15px) scale(0.75);
  }
`;

export const themeSchoolImageSelection = (
  Theme: String | null | undefined,
  gradSchool: string | null | undefined,
  currentSchool: string | null | undefined
) => {
  if (Theme === 'underGraduation') {
    return currentSchool || '';
  } else if (Theme === 'graduation') {
    return gradSchool || '';
  }
  return '';
};

export const MenuProps: any = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};