import { FC, MouseEvent, useState } from 'react';

import {
  Button,
  Drawer,
  ThemeProvider,
} from '@material-ui/core';

import AllColleges from '.';

import { customTheme } from '../../../pages/MaterializeCss';

import PLUS_ADD_IMG from '../../../img/plus-circle.svg';
import { customDrawerStyles } from '../style';
import { CustomDrawerProps } from '../../../common/interfaces';

const CustomDrawer: FC<CustomDrawerProps> = ({
  refetch,
  showButton = true,
  studentId,
}) => {
  const classes = customDrawerStyles();
  const [state, setState] = useState({
    bottom: false,
  });

  const toggleDrawer = () => (event: MouseEvent) => {
    setState({ ...state, bottom: !state.bottom });
  };

  return (
    <>
      {showButton ? (
        <ThemeProvider theme={customTheme}>
          <Button color="primary" onClick={toggleDrawer()}>
            <img src={PLUS_ADD_IMG} alt="add" /> Add to Applications
          </Button>
        </ThemeProvider>
      ) : (
        <span onClick={toggleDrawer()} className="hyperlink">
          Add to Applications
        </span>
      )}

      <Drawer
        anchor="bottom"
        open={state.bottom}
        onClose={toggleDrawer()}
        className={classes.documentDrawer}
      >
        <div role="presentation" className={classes.drawerPadding}>
          <AllColleges
            isCollegeAdded={(val: boolean) => {
              setState({ ...state, bottom: !state.bottom });
              refetch();
            }}
            variation="drawer"
            isDrawerOpen={!!state.bottom}
            onCloseDrawer={toggleDrawer()}
            studentId={studentId}
          />
        </div>
      </Drawer>
    </>
  );
};

export default CustomDrawer;
