import { Fragment, useContext, useEffect, useState, FC } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import clsx from 'clsx';
import {
  Grid,
  FormControlLabel,
  Box,
  Typography,
  Container,
  IconButton,
  Button,
  Card,
  Divider,
  Radio,
  Hidden,
  TextField,
} from '@material-ui/core';
import { Color } from '@material-ui/lab/Alert';
import 'react-phone-number-input/style.css';

import { useStylesAccount } from './accountStyle';
import EditEducation from './EditEducation';
import AdvisorSpecialities from './AdvisorTags';
import EditExperienceDialog from './EditExperienceDialog';
import ListPreviouslyWorkedAt from './ListPreviouslyWorkedAt';
import CreatePreviouslyWorkedAt from './CreatePreviouslyWorkedAt';
import EditCollegesDialog from './EditCollegesDialog';
import AdvisorStudentsSchool from './AdvisorStudentsSchool';
import Loader from '../../../components/Loader';
import ConfirmationModal from '../../../components/ConfirmationModal';
import AccountMenu from '../../../components/AccountMenu';
import Header from '../../../components/layout/Header';
import FooterNew from '../../../components/layout/FooterNew';
import Toast from '../../../components/Toast';
import { AppContext } from '../../../contexts';
import client from '../../../apollo';
import {
  updateAdvisorThemeMutation,
  updateAdvisorThemeMutationVariables,
} from './__generated__/updateAdvisorThemeMutation';

import DELETE_PAYOUT_ICON from '../../../img/delete-payouts.svg';
import EDIT_PENCIL_ICON from '../../../img/pencil-edit.svg';
import ADD_ROUND_ICON from '../../../img/add-round-icon.svg';
import EditInternationalExperience from './EditInternationalExperience';
import { AVINCENT_EMAIL } from '../../../constants';
import { getAllpreviouslyWorkedCollegesQuery_previouslyWorkedColleges } from './__generated__/getAllpreviouslyWorkedCollegesQuery';
import { getCurrentCollege_currentCollege } from '../../../graphql/queries/Colleges/__generated__/getCurrentCollege';
import {
  GET_CURRENT_COLLEGE,
  GET_GRADUATE_SCHOOL,
} from '../../../graphql/queries/Colleges';
import { getGraduateSchool_graduateSchool } from '../../../graphql/queries/Colleges/__generated__/getGraduateSchool';

const UPDATE_ADVISOR_THEME = gql`
  mutation updateAdvisorThemeMutation($Theme: String!) {
    updateAdvisorTheme(input: { Theme: $Theme }) {
      success
      message
    }
  }
`;

const UPDATE_MENTOR_MUTATION = gql`
  mutation ChangeMentorMutationProfile(
    $id: Int!
    $intakeYear: String
    $major: String
    $CollegeId: Int
    $degree: String
    $graduateSchoolGradYear: String
    $graduateCollegeId: Int
    $switchThemeTo: String
    $educationType: String
  ) {
    updateMentor(
      input: {
        id: $id
        intakeYear: $intakeYear
        graduateSchoolGradYear: $graduateSchoolGradYear
        major: $major
        degree: $degree
        CollegeId: $CollegeId
        graduateCollegeId: $graduateCollegeId
        switchThemeTo: $switchThemeTo
        educationType: $educationType
      }
    ) {
      id
      intakeYear
      graduateSchoolGradYear
      major
      degree
      Theme
      userMajors {
        id
        educationType
        degreeType
        MajorId
        majorName
      }
      graduateSchool {
        id
        name
        allMajors {
          id
          name
          otherMajor
          majorCategory {
            id
            name
            displayName
          }
        }
      }
      currentCollege {
        id
        name
        allMajors {
          id
          name
          otherMajor
          majorCategory {
            id
            name
            displayName
          }
        }
      }
      colleges {
        name
        id
        styleConfig {
          bgDark
          bgLight
        }
      }
    }
  }
`;

export interface Tostcongigs {
  message: string;
  type: Color | undefined;
}
export const CREATE_PREVIOUSLY_WORKED_MUTATION = gql`
  mutation createPreviouslyWorkedMutation(
    $mentorId: Int
    $CollegeId: Int
    $Title: String
    $current: Boolean
  ) {
    addMentorsPreviouslyWorkedColleges(
      input: {
        mentorId: $mentorId
        CollegeId: $CollegeId
        Title: $Title
        current: $current
      }
    ) {
      success
      previouslyWorkedAt {
        id
        college {
          id
          name
        }
        Title
        CurrentlyWorking
      }
    }
  }
`;
export const EDIT_PREVIOUSLY_WORKED_MUTATION = gql`
  mutation editPreviouslyWorkedMutation(
    $id: Int
    $CollegeId: Int
    $Title: String
    $current: Boolean
  ) {
    updateMentorsPreviousWorkedCollege(
      input: {
        id: $id
        CollegeId: $CollegeId
        Title: $Title
        current: $current
      }
    ) {
      success
      previouslyWorkedAt {
        id
        college {
          id
          name
        }
        Title
        CurrentlyWorking
      }
    }
  }
`;

export const DELETE_PREVIOUSLY_WORKED_MUTATION = gql`
  mutation deletePreviouslyWorkedMutation($id: Int) {
    deletePreviousWorkedCollegeMentor(input: { id: $id }) {
      success
    }
  }
`;
const GET_PREVIOUSLY_WORKED_COLLEGES = gql`
  query getAllpreviouslyWorkedCollegesQuery {
    previouslyWorkedColleges {
      id
      college {
        id
        name
      }
      Title
      CurrentlyWorking
    }
  }
`;

const ProfilePage: FC = () => {
  const classes = useStylesAccount();
  const { user, setUser, isCollegeUpdated, setIsCollegeUpdated } = useContext(AppContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openEducationDialog, setOpenEducationDialog] = useState(false);
  const [openExperience, setOpenExperience] = useState(false);
  const [openCollegesDialog, setOpenCollegesDialog] = useState(false);
  const [openIntlExperience, setOpenIntlExperience] = useState(false);
  const [editEducationInfo, setEditEducationInfo] = useState<
    'graduation' | 'underGraduation'
  >('graduation');
  const [acceptedToType, setAcceptedToType] = useState<
    'advisorStudentsAcceptedTo' | 'advisorAcceptedTo'
  >('advisorAcceptedTo');
  const [toast, setToast] = useState<Tostcongigs>({
    message: '',
    type: undefined,
  });

  // array to store radio button JSX Elements
  let arrayRadioButtons: JSX.Element[] = [];

  // useState to set the status of re-loading
  const [reload, setReload] = useState<boolean>(false);


  const [loading, setLoading] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);
  const [school, setSchool] = useState('');
  const [openToast, setOpenToast] = useState(false);
  const [previouslyWorkedColleges, setPreviouslyWorkedColleges] = useState<
    (getAllpreviouslyWorkedCollegesQuery_previouslyWorkedColleges | null)[]
  >([]);
  const [currentCollege, setCurrentCollege] =
    useState<getCurrentCollege_currentCollege | null>(null);
  const [graduateSchool, setGraduateSchool] =
    useState<getGraduateSchool_graduateSchool | null>(null);

  // useState to set the array of previously worked colleges themes 
  const [previousCollegeThemeArray, setPreviousCollegeThemeArray] = useState<JSX.Element[]>([]);

  const mountEffect = async () => {
    try {
      const { data } = await client.query({
        query: GET_PREVIOUSLY_WORKED_COLLEGES,
        fetchPolicy: 'network-only',
      });
      const { data: dataCurrentCollege } = await client.query({
        query: GET_CURRENT_COLLEGE,
        fetchPolicy: 'network-only',
      });
      const { data: dataGraduateSchool } = await client.query({
        query: GET_GRADUATE_SCHOOL,
        fetchPolicy: 'network-only',
      });
      const getPreviouslyWorkedColleges = data?.previouslyWorkedColleges || [];
      setPreviouslyWorkedColleges(getPreviouslyWorkedColleges);
      setCurrentCollege(dataCurrentCollege?.currentCollege);
      setGraduateSchool(dataGraduateSchool?.graduateSchool);
    } catch (error) {
      console.log('Error in API calling:::', error);
    }
  };

  // mount on the basis of re-loading status
  useEffect(() => {
    mountEffect();
  }, [reload]);

  if (!user) return null;
  const { advisorLevel, id: userId } = user;

  const handleSubmitCreatePreviouslyWorkedAt = async (
    collegeId: number,
    title: string,
    current: boolean
  ) => {
    if (current) {
      const currentWorkingAt = previouslyWorkedColleges?.find(
        (item) => item?.CurrentlyWorking === true
      );
      if (currentWorkingAt && currentWorkingAt.college?.id !== collegeId) {
        setToast({
          message: 'User can currently work at only one college',
          type: 'error',
        });
        setOpenToast(true);
        return;
      }
    }
    const result = await client.mutate({
      mutation: CREATE_PREVIOUSLY_WORKED_MUTATION,
      variables: {
        mentorId: userId,
        CollegeId: collegeId,
        Title: title,
        current,
      },
    });
    if (result) {
      const { data } = result;
      if (data.addMentorsPreviouslyWorkedColleges) {
        if (
          data.addMentorsPreviouslyWorkedColleges?.success &&
          data.addMentorsPreviouslyWorkedColleges?.previouslyWorkedAt
        ) {
          setToast({
            message: 'Advisor updated successfully.',
            type: 'success',
          });
          setOpenToast(true);
          const previous =
            data.addMentorsPreviouslyWorkedColleges?.previouslyWorkedAt;
          if (Array.isArray(previouslyWorkedColleges)) {
            setPreviouslyWorkedColleges([
              ...previouslyWorkedColleges,
              ...previous,
            ]);
          }
        }
      }
    }
  };

  const handleDeleteSchool = async () => {
    try {
      setLoading(true);
      if (school === 'Undergraduate') {
        if (user?.id) {
          const result = await client.mutate({
            mutation: UPDATE_MENTOR_MUTATION,
            variables: {
              id: user?.id,
              major: '',
              CollegeId: null,
              intakeYear: null,
              educationType: 'underGraduation',
              ...(user.Theme === 'underGraduation' && {
                switchThemeTo: graduateSchool ? 'graduation' : null,
              }),
            },
          });

          const {
            data: {
              updateMentor: {
                currentCollege,
                intakeYear,
                major,
                colleges,
                userMajors,
              },
            },
          } = result;
          setUser({
            ...user,
            major,
            intakeYear: intakeYear || '',
            Theme: graduateSchool ? 'graduation' : null,
            colleges,
            userMajors,
          });
          setCurrentCollege(currentCollege);
          setOpenDeleteConfirmation(false);
        }
      }

      if (school === 'graduate') {
        if (user?.id) {
          const result = await client.mutate({
            mutation: UPDATE_MENTOR_MUTATION,
            variables: {
              id: user?.id,
              degree: '',
              graduateCollegeId: null,
              graduateSchoolGradYear: null,
              educationType: 'graduation',
              ...(user.Theme === 'graduation' && {
                switchThemeTo: currentCollege ? 'underGraduation' : null,
              }),
            },
          });
          const {
            data: {
              updateMentor: {
                degree,
                graduateSchool,
                graduateSchoolGradYear,
                colleges,
                userMajors,
              },
            },
          } = result;
          setUser({
            ...user,
            degree,
            graduateSchoolGradYear: graduateSchoolGradYear || '',
            Theme: currentCollege ? 'underGraduation' : null,
            colleges,
            userMajors,
          });
          setGraduateSchool(graduateSchool);
          setOpenDeleteConfirmation(false);
        }
      }
      setIsCollegeUpdated(!isCollegeUpdated);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditPreviouslyWorkedAt = async (
    collegeId: number,
    title: string,
    current: boolean,
    recordId: number
  ) => {
    if (current) {
      const currentWorkingAt = previouslyWorkedColleges?.find(
        (item) => item?.CurrentlyWorking === true
      );
      if (currentWorkingAt && currentWorkingAt.college?.id !== collegeId) {
        setToast({
          message: 'User can currently work at only one college',
          type: 'error',
        });
        setOpenToast(true);
        return;
      }
    }
    const result = await client.mutate({
      mutation: EDIT_PREVIOUSLY_WORKED_MUTATION,
      variables: {
        id: recordId,
        current,
        CollegeId: collegeId,
        Title: title,
      },
    });
    if (result?.data?.updateMentorsPreviousWorkedCollege) {
      if (
        result.data.updateMentorsPreviousWorkedCollege?.success &&
        result.data.updateMentorsPreviousWorkedCollege?.previouslyWorkedAt
      ) {
        setToast({
          message: 'Advisor updated successfully.',
          type: 'success',
        });
        setOpenToast(true);
        const previous: getAllpreviouslyWorkedCollegesQuery_previouslyWorkedColleges =
          result.data.updateMentorsPreviousWorkedCollege.previouslyWorkedAt;
        let updatedColleges: typeof previouslyWorkedColleges = [];
        previouslyWorkedColleges?.forEach((item) => {
          if (item?.id === previous?.id) {
            updatedColleges?.push(previous);
          } else {
            updatedColleges?.push(item);
          }
        });

        if (Array.isArray(updatedColleges)) {
          setPreviouslyWorkedColleges([...updatedColleges]);
        }
      }
    }
  };

  const handleDeletePreviouslyWorkedAt = async (recordId: number) => {
    const result = await client.mutate({
      mutation: DELETE_PREVIOUSLY_WORKED_MUTATION,
      variables: {
        id: recordId,
      },
    });
    if (result.data.deletePreviousWorkedCollegeMentor) {
      if (result.data.deletePreviousWorkedCollegeMentor.success) {
        setToast({
          message: 'Advisor updated successfully.',
          type: 'success',
        });
        setOpenToast(true);
        const previouslyWorkedAt =
          previouslyWorkedColleges?.filter((item) => item?.id !== recordId) ||
          [];
        setPreviouslyWorkedColleges([...previouslyWorkedAt]);
      }
    }
  };

  const handleThemeChange = async (updateTheme: Function, Theme: string) => {
    if (user.Theme !== Theme) {
      try {
        setMainLoading(true);
        await updateTheme({
          variables: {
            Theme,
          },
        });
        setUser({
          ...user,
          Theme,
        });
        setToast({
          message: 'Theme updated successfully.',
          type: 'success',
        });
      } catch (err) {
        console.log('Error:::', err);
        setToast({
          message: 'Something went wrong.',
          type: 'error',
        });
      } finally {
        setOpenToast(true);
        setMainLoading(false);
      }
    }
  };

  const handleOpenEducationDialog = (
    info: 'graduation' | 'underGraduation'
  ) => {
    setOpenEducationDialog(true);
    setEditEducationInfo(info);
  };

  const handleCloseEducationDialog = () => {
    setOpenEducationDialog(false);
  };

  const handleClickOpenExperience = () => {
    setOpenExperience(true);
  };

  const handleCloseExperience = () => {
    setOpenExperience(false);
  };

  const handleOpenCollegesDilaog = (
    type: 'advisorStudentsAcceptedTo' | 'advisorAcceptedTo'
  ) => {
    setOpenCollegesDialog(true);
    setAcceptedToType(type);
  };
  const handleCloseCollegesDilaog = () => {
    setOpenCollegesDialog(false);
  };
  const currentWorkingAt = previouslyWorkedColleges?.find(
    (item) => item?.CurrentlyWorking === true
  )
    ? true
    : false;

  const underGradMajors = user?.userMajors?.filter(
    (item) => item?.educationType === 'underGraduation'
  );

  const graduateMajors = user?.userMajors?.filter(
    (item) => item?.educationType === 'graduation'
  );

  return (
    <Fragment>
      {mainLoading && <Loader />}

      {
        previouslyWorkedColleges.map((previouslyWorkedCollege: any, index: number) => {
          // getting index value from index and assigning it to key
          let key = { index }.index;
          // for each previously worked college, creating the theme name : prefix(former_) + suffix(college name)
          const previousCollegeTheme = 'former_' + previouslyWorkedCollege?.college?.name;
          // pushing the radio button with updated details into the JSX element array
          arrayRadioButtons[index] = (
            (
              <Box className={classes.previouslyWorkedThemeRadioButton}>
                <Mutation<
                  updateAdvisorThemeMutation,
                  updateAdvisorThemeMutationVariables
                >
                  mutation={UPDATE_ADVISOR_THEME}
                >
                  {(updateTheme: Function) => (
                    <FormControlLabel
                      value={previousCollegeTheme}
                      control={
                        <Radio
                          color="primary"
                          checked={user.Theme === previousCollegeTheme}
                          onChange={() => {
                            handleThemeChange(
                              updateTheme,
                              previousCollegeTheme
                            );
                          }}
                        />
                      }
                      label=""
                      labelPlacement="top"
                    />
                  )}
                </Mutation>
              </Box>
            )
          )
        })
      }

      <Toast
        open={openToast}
        close={() => {
          setOpenToast(false);
        }}
        {...toast}
      />

      <Header />

      <Container maxWidth={false} className="accountContainer">
        <Typography variant="h3" className="accountHeader">
          My Account
        </Typography>

        <Grid container spacing={2}>
          <Grid item md={3} sm={12} xs={12}>
            <AccountMenu />
          </Grid>

          <Grid item md={9} sm={12} xs={12}>
            <Card className={classes.accountInfoCard}>
              <Box className="infoCardHeader infoCardHeaderProfile">
                <Box>
                  <Typography variant="h5">My Profile</Typography>
                  <Typography variant="body1">
                    This information will appear on the Student Portal.
                  </Typography>
                </Box>

                <a
                  href={`https://${window.location.host.replace(
                    'advisors',
                    'app'
                  )}/advisors/${user.id}`}
                  target="_blank"
                >
                  Visit Profile Page on Student Portal
                </a>
              </Box>

              <Grid className="accountInfoBody">
                <div
                  className={clsx(
                    classes.accountInformationForm,
                    classes.educationInformation
                  )}
                >
                  <Grid>
                    <Typography
                      variant="h6"
                      className="educationHeading headingSpacing"
                    >
                      Advisor Level
                    </Typography>

                    <Typography variant="body1" className="educationText">
                      {user.advisorLevel || '--'}
                    </Typography>
                  </Grid>

                  <Divider />

                  <Grid>
                    <Box
                      display="flex"
                      alignItems="center"
                      className="headingSpacing"
                    >
                      <Typography variant="h6" className="educationHeading">
                        Undergraduate Education
                      </Typography>

                      {currentCollege && (
                        <IconButton
                          onClick={() =>
                            handleOpenEducationDialog('underGraduation')
                          }
                          className="editButton"
                        >
                          <img src={EDIT_PENCIL_ICON} alt="edit" />
                        </IconButton>
                      )}

                      <EditEducation
                        info={editEducationInfo}
                        open={openEducationDialog}
                        onClose={handleCloseEducationDialog}
                        userCurrentCollege={currentCollege}
                        setCurrentCollege={setCurrentCollege}
                        graduateSchool={graduateSchool}
                        setGraduateSchool={setGraduateSchool}
                      />
                    </Box>

                    {!currentCollege && (
                      <Button
                        variant="text"
                        className="graduateEducation"
                        onClick={() =>
                          handleOpenEducationDialog('underGraduation')
                        }
                      >
                        <img src={ADD_ROUND_ICON} alt="add" />
                        UNDERGRADUATE EDUCATION
                      </Button>
                    )}

                    {currentCollege && (
                      <>
                        <Hidden only={['xs', 'sm']}>
                          <Box
                            className={clsx(
                              classes.educationTable,
                              classes.educationTableHeader
                            )}
                          >
                            <Box className="educationFirstCell tableCellText">
                              &nbsp;
                            </Box>
                            <Box className="educationSecondCell tableCellText">
                              &nbsp;
                            </Box>
                            <Box className="educationThirdCell tableCellText">
                              &nbsp;
                            </Box>
                            <Box className="educationFourCell tableCellText">
                              THEME
                            </Box>
                            <Box className="educationFiveCell">&nbsp;</Box>
                          </Box>
                        </Hidden>

                        <Box className={classes.educationTable}>
                          <Box className="educationFirstCell tableCellText">
                            {underGradMajors?.map((item, index) => (
                              <>
                                {item?.degreeType && item?.majorName
                                  ? `${item.degreeType}, ${item.majorName}`
                                  : item?.degreeType || item?.majorName}
                                {index !== underGradMajors.length - 1 && '; '}
                              </>
                            ))}
                          </Box>

                          <Box className="educationSecondCell tableCellText">
                            {currentCollege?.name || '--'}
                          </Box>

                          <Box className="educationThirdCell tableCellText">
                            {user?.intakeYear || (
                              <span className="emptyTextSpan">--</span>
                            )}
                          </Box>

                          <Box className="educationFourCell tableCellText">
                            <Mutation<
                              updateAdvisorThemeMutation,
                              updateAdvisorThemeMutationVariables
                            >
                              mutation={UPDATE_ADVISOR_THEME}
                            >
                              {(updateTheme: Function) => {
                                return (
                                  <FormControlLabel
                                    value={'underGraduation'}
                                    control={
                                      <Radio
                                        color="primary"
                                        checked={
                                          user.Theme === 'underGraduation'
                                        }
                                        onChange={() => {
                                          handleThemeChange(
                                            updateTheme,
                                            'underGraduation'
                                          );
                                        }}
                                      />
                                    }
                                    label=""
                                    labelPlacement="top"
                                  />
                                );
                              }}
                            </Mutation>

                            <Hidden only={['xl', 'lg', 'md']}>
                              <Box
                                className="tableCellText tableCellTextRadio"
                                paddingRight="15px"
                              >
                                THEME
                              </Box>
                            </Hidden>
                          </Box>

                          <Box className="educationFiveCell">
                            <IconButton
                              onClick={() => {
                                setSchool('Undergraduate');
                                setOpenDeleteConfirmation(
                                  !openDeleteConfirmation
                                );
                                setAnchorEl(null);
                              }}
                            >
                              <img src={DELETE_PAYOUT_ICON} alt="delete" />
                            </IconButton>
                          </Box>
                        </Box>
                      </>
                    )}

                    <ConfirmationModal
                      open={openDeleteConfirmation}
                      onClose={() => {
                        setOpenDeleteConfirmation(!openDeleteConfirmation);
                      }}
                      title="Delete School?"
                      firstButtonText="Delete"
                      secondButtonText="Keep School"
                      handleCancelBtn={handleDeleteSchool}
                      loading={loading}
                    />
                  </Grid>

                  <Grid>
                    <Box
                      display="flex"
                      alignItems="center"
                      className="headingSpacing"
                    >
                      <Typography variant="h6" className="educationHeading">
                        Graduate Education
                      </Typography>

                      {graduateSchool && (
                        <IconButton
                          onClick={() =>
                            handleOpenEducationDialog('graduation')
                          }
                          className="editButton"
                        >
                          <img src={EDIT_PENCIL_ICON} alt="edit" />
                        </IconButton>
                      )}

                      <EditEducation
                        info={editEducationInfo}
                        open={openEducationDialog}
                        onClose={handleCloseEducationDialog}
                        userCurrentCollege={currentCollege}
                        setCurrentCollege={setCurrentCollege}
                        graduateSchool={graduateSchool}
                        setGraduateSchool={setGraduateSchool}
                      />
                    </Box>

                    {!graduateSchool && (
                      <Button
                        variant="text"
                        className="graduateEducation"
                        onClick={() => handleOpenEducationDialog('graduation')}
                      >
                        <img src={ADD_ROUND_ICON} alt="add" />
                        GRADUATE EDUCATION
                      </Button>
                    )}

                    {graduateSchool && (
                      <>
                        <Hidden only={['xs', 'sm']}>
                          <Box
                            className={clsx(
                              classes.educationTable,
                              classes.educationTableHeader
                            )}
                          >
                            <Box className="educationFirstCell tableCellText">
                              &nbsp;
                            </Box>
                            <Box className="educationSecondCell tableCellText">
                              &nbsp;
                            </Box>
                            <Box className="educationThirdCell tableCellText">
                              &nbsp;
                            </Box>
                            <Box className="educationFourCell tableCellText">
                              THEME
                            </Box>
                            <Box className="educationFiveCell">&nbsp;</Box>
                          </Box>
                        </Hidden>

                        <Box className={classes.educationTable}>
                          <Box className="educationFirstCell tableCellText">
                            {graduateMajors?.map((item, index) => (
                              <>
                                {item?.degreeType && item?.majorName
                                  ? `${item.degreeType}, ${item.majorName}`
                                  : item?.degreeType || item?.majorName}
                                {index !== graduateMajors.length - 1 && '; '}
                              </>
                            ))}
                          </Box>
                          <Box className="educationSecondCell tableCellText">
                            {graduateSchool?.name || '--'}
                          </Box>
                          <Box className="educationThirdCell tableCellText">
                            {user?.graduateSchoolGradYear || (
                              <span className="emptyTextSpan">--</span>
                            )}
                          </Box>
                          <Box className="educationFourCell tableCellText">
                            <Mutation<
                              updateAdvisorThemeMutation,
                              updateAdvisorThemeMutationVariables
                            >
                              mutation={UPDATE_ADVISOR_THEME}
                            >
                              {(updateTheme: Function) => (
                                <FormControlLabel
                                  value={'graduation'}
                                  control={
                                    <Radio
                                      color="primary"
                                      checked={user.Theme === 'graduation'}
                                      onChange={() => {
                                        handleThemeChange(
                                          updateTheme,
                                          'graduation'
                                        );
                                      }}
                                    />
                                  }
                                  label=""
                                  labelPlacement="top"
                                />
                              )}
                            </Mutation>

                            <Hidden only={['xl', 'lg', 'md']}>
                              <Box
                                className="tableCellText tableCellTextRadio"
                                paddingRight="15px"
                              >
                                THEME
                              </Box>
                            </Hidden>
                          </Box>
                          <Box className="educationFiveCell">
                            <IconButton
                              aria-label="delete"
                              onClick={() => {
                                setSchool('graduate');

                                setOpenDeleteConfirmation(
                                  !openDeleteConfirmation
                                );
                                setAnchorEl(null);
                              }}
                            >
                              <img src={DELETE_PAYOUT_ICON} alt="delete" />
                            </IconButton>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Grid>

                  <Divider />

                  <Grid>
                    <Box
                      display="flex"
                      alignItems="center"
                      className="headingSpacing"
                    >
                      <Typography variant="h6" className="educationHeading">
                        Experience
                      </Typography>

                      <IconButton
                        onClick={handleClickOpenExperience}
                        className="editButton"
                      >
                        <img src={EDIT_PENCIL_ICON} alt="edit" />
                      </IconButton>

                      <EditExperienceDialog
                        open={openExperience}
                        onClose={handleCloseExperience}
                      />
                    </Box>

                    <Grid className="experienceText">
                      <TextField
                        value={user?.bio || '--'}
                        fullWidth
                        multiline
                        disabled
                      />
                    </Grid>
                  </Grid>

                  <Divider />

                  <Grid>
                    <AdvisorSpecialities />
                  </Grid>

                  <Divider />

                  <EditCollegesDialog
                    open={openCollegesDialog}
                    onClose={handleCloseCollegesDilaog}
                    type={acceptedToType}
                    idsDisabled={
                      acceptedToType === 'advisorAcceptedTo'
                        ? [
                          currentCollege?.id || 0,
                          graduateSchool?.id || 0,
                        ]
                        : []
                    }
                  />

                  <EditInternationalExperience
                    open={openIntlExperience}
                    onClose={() => setOpenIntlExperience(false)}
                    hasIntleExperience={user.internationalStudentExperience}
                  />

                  <AdvisorStudentsSchool />

                  <Divider />

                  <Grid>
                    <Box
                      display="flex"
                      alignItems="center"
                      className="headingSpacing"
                    >
                      <Typography variant="h6" className="educationHeading">
                        I, myself, got accepted into schools like...
                      </Typography>

                      <IconButton
                        onClick={() =>
                          handleOpenCollegesDilaog('advisorAcceptedTo')
                        }
                        className="editButton"
                      >
                        <img src={EDIT_PENCIL_ICON} alt="edit" />
                      </IconButton>
                    </Box>

                    <Box className="tagsWrapper">
                      {user.colleges?.map((item) => (
                        <Box key={item?.name}>
                          <Box
                            className="tagsBadge"
                            style={{
                              backgroundColor:
                                item?.styleConfig?.bgDark || '#d4d4d4',
                            }}
                          >
                            {item?.name}
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Grid>

                  <Divider />

                  <Grid>
                    <Box
                      display="flex"
                      alignItems="center"
                      className="headingSpacing"
                    >
                      <Typography variant="h6" className="educationHeading">
                        Has experience advising international students?
                      </Typography>
                    </Box>

                    <Box className="tagsWrapper disabledState">
                      <Box>
                        {user.internationalStudentExperience != null
                          ? user.internationalStudentExperience
                            ? 'Yes'
                            : 'No'
                          : '--'}
                      </Box>
                    </Box>
                    <Box className="intlExperienceMessage disabledState">
                      If change in this field is needed, please reach out to
                      Alison Vincent ({AVINCENT_EMAIL}) to request change.
                    </Box>
                  </Grid>

                  <Grid>
                    {advisorLevel === 'Former Admissions Officer' &&
                      previouslyWorkedColleges && (
                        // passing the themes as props
                        <ListPreviouslyWorkedAt
                          WorkedAt={previouslyWorkedColleges}
                          PreviousCollegeThemes={arrayRadioButtons}
                          handleEdit={(
                            collegeId: number,
                            title: string,
                            current: boolean,
                            recordId: number
                          ) => {
                            handleEditPreviouslyWorkedAt(
                              collegeId,
                              title,
                              current,
                              recordId
                            );
                          }}
                          handleDelete={(recordId: number) => {
                            handleDeletePreviouslyWorkedAt(recordId);
                          }}
                        />
                      )}
                    {advisorLevel === 'Former Admissions Officer' && (
                      <CreatePreviouslyWorkedAt
                        totalCount={previouslyWorkedColleges?.length || 0}
                        currentWorkingAt={currentWorkingAt}
                        handleSubmit={(
                          collegeId: number,
                          title: string,

                          current: boolean
                        ) => {
                          handleSubmitCreatePreviouslyWorkedAt(
                            collegeId,
                            title,
                            current
                          );
                        }}
                      />
                    )}
                  </Grid>
                </div>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <FooterNew />
    </Fragment>
  );
};

export default ProfilePage;
