import { FC, FormEvent } from 'react';
import {
  TextField,
  Typography,
  Button,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import { Field, Form, Formik } from 'formik';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import * as Yup from 'yup';

import { ThemeProvider } from '@material-ui/core/styles';

import CollegeDefaultImg from '../../../img/college-default-img.svg';
import { customTheme } from '../../MaterializeCss';
import { useStylesApplication } from './applicationStyle';

const AddNewApplicationDealine: FC<any> = ({
  open,
  onCloseModal,
  onSubmitModal,
  collegeLogo,
  deadlines,
}) => {
  const initialValues = {
    deadlineName: '',
    date: new Date(),
  };

  const deadlineNames = deadlines?.map((deadline: any) => deadline?.display);

  const AddNewDeadlineSchema = Yup.object().shape({
    deadlineName: Yup.string()
      .notOneOf(deadlineNames, 'Deadline Title already used.')
      .required('Deadline Title Is required')
      .nullable(),
    date: Yup.string().required('Deadline Date is required').nullable(),
  });
  const classes = useStylesApplication();
  return (
    <Dialog
      open={open}
      onClose={() => onCloseModal}
      className={classes.addNewEssayDialog}
    >
      <DialogContent>
        <Typography variant="h4">
          Add Deadline
          <img
            width={'37px'}
            src={collegeLogo || CollegeDefaultImg}
            alt="logo"
          />
        </Typography>

        <Formik
          initialValues={initialValues}
          validationSchema={AddNewDeadlineSchema}
          onSubmit={(variables, { setSubmitting }) => {
            try {
              onSubmitModal(variables);
            } catch (error) {
              console.log('error', error);
            }
          }}
        >
          {({ isSubmitting, values, errors, setFieldValue }) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography variant="body1">Deadline Title</Typography>
                    <Field
                      type="text"
                      name="deadlineName"
                      fullWidth={true}
                      component={TextField}
                      label={''}
                      placeholder="Deadline Title"
                      onChange={(e: FormEvent<HTMLInputElement>) => {
                        const {
                          currentTarget: { value },
                        } = e;

                        setFieldValue('deadlineName', value);
                      }}
                    />
                    {errors?.deadlineName && errors.deadlineName}
                  </Grid>

                  <Grid item md={4} sm={12} xs={12}>
                    <Typography variant="body1">Deadline Date</Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Field
                        type="text"
                        name="date"
                        fullWidth={true}
                        component={() => (
                          <DatePicker
                            views={['date']}
                            label=""
                            format="MM/dd/yyyy"
                            placeholder="Date"
                            value={values.date}
                            onChange={(value: any) => {
                              setFieldValue('date', value);
                            }}
                          />
                        )}
                      />
                      {errors?.date && errors.date}
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>

                <DialogActions>
                  <ThemeProvider theme={customTheme}>
                    <Button variant="outlined" onClick={onCloseModal}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Add
                    </Button>
                  </ThemeProvider>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddNewApplicationDealine;
