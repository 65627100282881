import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import moment from 'moment-timezone';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import colors from '../../common/colors';
import { ISetCapacityModal } from '../../common/interfaces';
import { toDecimalPoint } from '../../common/utility';
import { AppContext } from '../../contexts';
import CloseImage from '../../img/close-dialog.svg';
import useStyles from './styles';

const SetCapacityModal: FC<ISetCapacityModal> = ({
  open,
  setOpen,
  hours,
  updateAdvisorCapacityData,
  estimatedActivity,
  matchText,
  hoursLastUpdate,
  minAvg,
  maxAvg,
  isAvailabilityPage,
}) => {
  const [newHours, setNewHours] = useState<string | undefined>();
  const [newMatchString, setMatchString] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const isBelowHourLimit = maxAvg ? Number(newHours || 0) <= maxAvg - 6 : false;
  const isAboveMaxLimit = Number(newHours || 0) > 168;
  const classes = useStyles();

  const handleChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (!isNaN(Number(e.target.value))) {
      setNewHours(e.target.value);
    }
  };

  const updateData = async (data: { hours: string; matchStr: string }) => {
    if (
      newHours &&
      !isBelowHourLimit &&
      (newHours !== hours || newMatchString !== matchText) &&
      !isAboveMaxLimit
    ) {
      setLoading(true);
      await updateAdvisorCapacityData(data);
      setLoading(false);
      setOpen && setOpen(false);
    }
  };

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMatchString(e.target.value);
  };

  useEffect(() => {
    setNewHours(hours);
    setMatchString(matchText);
  }, [hours]);
  const { user } = useContext(AppContext);
  if (!user) return null;

  const timezone = user.timezone;
  const lastUpdate = hoursLastUpdate
    ? timezone
      ? moment(hoursLastUpdate)
          .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
          .format('MMMM DD, YYYY, hh:mma z')
      : moment(hoursLastUpdate).format('MMMM DD, YYYY, hh:mma z')
    : null;

  const isDisabled =
    !newHours ||
    loading ||
    (newHours === hours && newMatchString === matchText) ||
    isBelowHourLimit;
  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        className={classes.dialog}
        onClose={() => setOpen && setOpen(false)}
      >
        <DialogTitle id="set-capacity-dialog">
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'flex-start'}
          >
            <Box className={classes.capacityHeading}>
              Add Maximum Capacity
              <DialogContentText className={classes.para}>
                Set this amount to declare your maximum advising capacity. Your unused
                capacity will be considered for matching new students.{' '}
              </DialogContentText>
            </Box>
            {setOpen ? (
              <IconButton onClick={() => setOpen && setOpen(false)}>
                <img src={CloseImage} />
              </IconButton>
            ) : null}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box className={classes.fieldContainer}>
            <TextField
              variant="filled"
              value={newHours}
              placeholder={!hours && maxAvg ? String(maxAvg + 10) : ''}
              className={
                classes.dialogField +
                (isBelowHourLimit || isAboveMaxLimit ? ' belowLimit' : '')
              }
              onChange={handleChange}
            />
            <Typography className="ml-4"> hours / week</Typography>
          </Box>

          {(minAvg === 0 || minAvg) && maxAvg && isBelowHourLimit ? (
            <DialogContentText className={classes.errorText}>
              Maximum capacity too low. Please set this number greater than (
              {toDecimalPoint(maxAvg - 6, 1)} hrs/week)
            </DialogContentText>
          ) : null}

          <Box className={classes.textareaContainer}>
            <Box color={colors.GRAY_TWENTYTWO}>
              <Typography variant="caption">
                Is there anything we need to know about matching?
              </Typography>
            </Box>
            <textarea
              onChange={handleTextAreaChange}
              value={newMatchString}
              rows={3}
              placeholder="Optional"
            ></textarea>
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button
            variant="contained"
            color="default"
            className={isDisabled ? 'disabledButton' : ''}
            onClick={() =>
              updateData({
                hours: newHours || '',
                matchStr: newMatchString || '',
              })
            }
            disabled={isDisabled}
          >
            {isAvailabilityPage ? 'Save' : 'Update'}
          </Button>
          {lastUpdate ? (
            <Typography variant="body2">Last updated: {lastUpdate}</Typography>
          ) : null}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SetCapacityModal;
