import {
	Typography,
} from '@material-ui/core';
import { useStyles } from '../../pages/main/students/MyStudentsStyle';

interface profilePropType {
	title: string;
	count?: string;
}

const StudentProfileHeader = ({ title, count }: profilePropType) => {
	const classes = useStyles();

	return (
		<Typography
			variant="h4"
			className={classes.studentSectionHeader}
		>
			{title} {count}
		</Typography>
	);
};

export default StudentProfileHeader;
