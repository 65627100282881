import { useContext, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  Select,
  MenuItem,
} from '@material-ui/core';
import clsx from 'clsx';
import { Mutation } from 'react-apollo';
import { Formik, Form } from 'formik';
import gql from 'graphql-tag';

import { useStylesAccount } from './accountStyle';

import { Tostcongigs } from './ProfilePage';
import Toast from '../../../components/Toast';
import { AppContext } from '../../../contexts';
import { handleMutationError } from '../../../common/utility';

import {
  UpdateMentorInternationalExperience,
  UpdateMentorInternationalExperienceVariables,
} from './__generated__/UpdateMentorInternationalExperience';

const UPDATE_MENTOR_INTERNATIONAL_EXPERIENCE = gql`
  mutation UpdateMentorInternationalExperience(
    $id: Int!
    $internationalStudentExperience: Boolean
  ) {
    updateMentor(
      input: {
        id: $id
        internationalStudentExperience: $internationalStudentExperience
      }
    ) {
      internationalStudentExperience
    }
  }
`;

const EditInternationalExperience = ({
  open,
  onClose,
  hasIntleExperience,
}: {
  open: boolean;
  onClose: () => void;
  hasIntleExperience: boolean | null;
}) => {
  const classes = useStylesAccount();
  const { user, setUser } = useContext(AppContext);

  const [toast, setToast] = useState<Tostcongigs>({
    message: '',
    type: undefined,
  });
  const [openToast, setOpenToast] = useState(false);
  const [internationalStudentExperience, setInternationalStudentExperience] =
    useState('');

  const handleClose = async (resetForm: Function) => {
    resetForm();
    onClose();
    setInternationalStudentExperience('');
  };

  return (
    <>
      <Toast
        open={openToast}
        close={() => {
          setOpenToast(false);
        }}
        {...toast}
      />
      <Mutation<
        UpdateMentorInternationalExperience,
        UpdateMentorInternationalExperienceVariables
      >
        mutation={UPDATE_MENTOR_INTERNATIONAL_EXPERIENCE}
      >
        {(updateMentor: Function) => {
          return (
            <Formik
              initialValues={{
                id: user?.id,
              }}
              enableReinitialize
              onSubmit={async (variables, { setSubmitting }) => {
                try {
                  const { data } = await updateMentor({
                    variables: {
                      ...variables,
                      internationalStudentExperience:
                        internationalStudentExperience === 'Yes' ? true : false,
                    },
                  });

                  setUser({
                    ...user,
                    ...data?.updateMentor,
                  });
                  setToast({
                    message: 'User Updated!',
                    type: 'success',
                  });
                } catch (error: any) {
                  let errorMessage = 'Changes not saved!';

                  if (error?.message) {
                    handleMutationError(error, ({ message }) => {
                      if (message) errorMessage = message;
                    });
                  }
                  setToast({
                    message: errorMessage,
                    type: 'error',
                  });
                } finally {
                  setSubmitting(false);
                  setOpenToast(true);
                  onClose();
                }
              }}
            >
              {({ isSubmitting, handleSubmit, errors, resetForm }) => {
                return (
                  <Form>
                    <Dialog
                      open={open}
                      onClose={() => handleClose(resetForm)}
                      className={clsx(
                        classes.educationDialog,
                        classes.internationalStudent
                      )}
                    >
                      <DialogContent>
                        <Typography variant="h4">
                          International Students Advising
                        </Typography>
                        <Typography variant="body2" className="">
                          Do you have experience advising International
                          students?
                        </Typography>

                        <Grid className={classes.accountInformationForm}>
                          <Box className="genderDropdown">
                            <Select
                              value={
                                internationalStudentExperience
                                  ? internationalStudentExperience
                                  : hasIntleExperience != null
                                  ? hasIntleExperience
                                    ? 'Yes'
                                    : 'No'
                                  : 'select'
                              }
                              onChange={(e) =>
                                setInternationalStudentExperience(
                                  e.target.value as string
                                )
                              }
                              defaultValue={'Yes'}
                            >
                              <MenuItem disabled value={'select'}>
                                {'Select'}
                              </MenuItem>
                              <MenuItem value={'Yes'}>Yes</MenuItem>
                              <MenuItem value={'No'}>No</MenuItem>
                            </Select>
                          </Box>
                        </Grid>

                        <Box
                          pt={2.5}
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <Button
                            variant="outlined"
                            onClick={() => handleClose(resetForm)}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            disabled={isSubmitting}
                            onClick={() => handleSubmit()}
                          >
                            {isSubmitting ? 'Saving...' : 'Save'}
                          </Button>
                        </Box>
                      </DialogContent>
                    </Dialog>
                  </Form>
                );
              }}
            </Formik>
          );
        }}
      </Mutation>
    </>
  );
};

export default EditInternationalExperience;
