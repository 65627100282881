import { FC, useState, useEffect } from 'react';
import { Query } from 'react-apollo';
import styled from 'styled-components';

import { Fab, Modal, TextField, Box } from '@material-ui/core';
import gql from 'graphql-tag';

import Colors from '../../../common/colors';
import { AllMentorsListQuery } from './__generated__/AllMentorsListQuery';
import Loader from '../../../components/Loader';
import AdvisorsList from './AdvisorsList';
import CLOSE_GRAY_IMG from '../../../img/modal-cross.png';
import SEARCH_BG_IMG from '../../../img/search-icon-blue.svg';
import { TextFieldSearch } from '../../../styles/common';
import { advisorModalStyles } from './styles';

const ModalPaper = styled.div`
  position: absolute;
  width: 100%;
  max-width: 500px;
  padding: 0px;
  background-color: ${Colors.WHITE_ONE};
  outline: none;
  opacity: 1;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 991px) {
    width: 100%;
    border-radius: 0px;
    margin: 0px auto;
    height: 100%;
  }

  .application-card .MuiCardActions-root h6 {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: ${Colors.BLUE_SEVEN};
    margin-bottom: 0px;
    cursor: pointer;
  }

  .MuiFilledInput-input {
    padding: 0px;
  }

  .MuiFormControl-marginNormal {
    background-repeat: no-repeat;
    background-position: center right;
  }

  .MuiFilledInput-root {
    background-color: transparent !important;
  }
`;

const ModalHeader = styled.div`
  padding: 15px 15px 15px 30px;
`;

const ModalBody = styled.div`
  padding: 2px 32px 0px;

  @media only screen and (max-width: 991px) {
    padding: 29px 15px 0px;
  }

  h4 {
    font-size: 30px;
    line-height: 43px;
    letter-spacing: -2px;
    color: ${Colors.BLUE_SEVEN};
    margin: 0px;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.3375px;
    color: ${Colors.BLACK_SIX};
    margin: 14px 0px 0px;
  }
`;

const ModalContentTitle = styled.div`
  h3 {
    font-weight: 600;
    font-size: 45px;
    line-height: 84px;
    text-align: center;
    letter-spacing: -2.5px;
    color: ${Colors.BLACK_THREE};
    margin: 0px;

    @media only screen and (max-width: 767px) {
      font-size: 26px;
      line-height: 30px;
      letter-spacing: 0px;
    }
  }
`;

interface Props {
  open: boolean;
  onClose: () => void;
  setMentorId: Function;
  currentUser: number | null;
}

export const ALL_MENTORS_QUERY = gql`
  query AllMentorsListQuery(
    $limit: Int
    $page: Int
    $searchQuery: String
    $random: Boolean
    $sort: String
    $filter: [String]
    $filterType: String
    $excludeMentors: [Int]
    $filterAdvisorLevel: String
  ) {
    allMentors(
      input: {
        limit: $limit
        page: $page
        searchQuery: $searchQuery
        random: $random
        sort: $sort
        filter: $filter
        filterType: $filterType
        excludeMentors: $excludeMentors
        filterAdvisorLevel: $filterAdvisorLevel
      }
    ) {
      nextRecord

      mentors {
        id
        firstName
        lastName
        email
        pictureUrl
        bio
      }
    }
  }
`;

const AdvisorModal: FC<Props> = ({
  open,
  onClose,
  setMentorId,
  currentUser,
}) => {
  const classes = advisorModalStyles();
  const [searchQuery, setSearchQuery] = useState('');

  let timeoutId = 0;
  const allMentorsQueryVariables: any = {
    limit: 10,
    random: true,
    excludeMentors: [currentUser],
  };

  const allMentorsQueryVariables1: any = {
    limit: 10,
    random: true,
    searchQuery,
    excludeMentors: [currentUser],
  };

  useEffect(() => {
    setSearchQuery('');
  }, [open]);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={onClose}
    >
      <ModalPaper>
        <ModalHeader>
          <Fab onClick={onClose} className={classes.fabClose}>
            <img src={CLOSE_GRAY_IMG} alt="close" />
          </Fab>
        </ModalHeader>

        <ModalBody>
          <ModalContentTitle className="text-center">
            <h4>Advisors</h4>

            <Box className={classes.requestReviewSearch}>
              <TextFieldSearch>
                <TextField
                  label="Search advisor"
                  onChange={(event) => {
                    const {
                      target: { value },
                    } = event;
                    clearTimeout(timeoutId);
                    timeoutId = window.setTimeout(() => {
                      setSearchQuery(value);
                    }, 500);
                  }}
                  placeholder=""
                  margin="normal"
                  variant="filled"
                  style={{
                    backgroundImage: 'url(' + SEARCH_BG_IMG + ')',
                    display: 'flex',
                  }}
                />
              </TextFieldSearch>
            </Box>
          </ModalContentTitle>

          <Query<AllMentorsListQuery>
            query={ALL_MENTORS_QUERY}
            variables={allMentorsQueryVariables}
            fetchPolicy="no-cache"
          >
            {({ data, loading }) => {
              if (loading) return <Loader />;
              if (!data || !data.allMentors) return <p>No Advisors Found</p>;

              return (
                <Box height="60vh" className={classes.listContainer}>
                  <Query<AllMentorsListQuery>
                    query={ALL_MENTORS_QUERY}
                    variables={allMentorsQueryVariables1}
                    fetchPolicy="no-cache"
                  >
                    {({ data, loading }) => {
                      if (loading) return <Loader />;
                      if (!data || !data.allMentors)
                        return (
                          <p className="text-center p-2">No Advisors Found</p>
                        );

                      const {
                        allMentors: { mentors },
                      } = data;
                      if (!mentors || !mentors.length)
                        return (
                          <p className="text-center p-2">No Advisors Found</p>
                        );
                      return (
                        <AdvisorsList
                          setMentorId={setMentorId}
                          mentors={mentors}
                        />
                      );
                    }}
                  </Query>
                </Box>
              );
            }}
          </Query>
        </ModalBody>
      </ModalPaper>
    </Modal>
  );
};

export default AdvisorModal;
