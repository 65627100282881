import { FC, useEffect, useState } from 'react';

import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Typography,
} from '@material-ui/core';

import Toast, { Color } from '../Toast';
import MoreStudentsModal from './MoreStudentsModal';
import SetCapacityModal from './SetCapacityModal';
// loader
import Loader from '../Loader';
import { IAdvisorDetails, ICapacityChart } from '../../common/interfaces';
import {
  getSetCapacityModalData,
  returnIndexMonth,
  toDecimalPoint,
  updateAdvisorCapacity,
} from '../../common/utility';
import { EditIcon } from '../../pages/main/students/assets/svg';

const CapacityStats: FC<ICapacityChart> = ({ chartData, refetchAPI }) => {
  const [open, setOpen] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [openMoreStudent, setOpenMoreStudent] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState<Color>('error');
  const [advisorDetails, setAdvisorDetails] = useState<IAdvisorDetails | null>(
    null
  );
  const [openMoreStudentModal, setOpenMoreStudentModal] = useState(false);
  // loading state definition
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updateAdvisorCapacityData = async ({
    hours,
    matchStr,
    isOpenToTakeStudents,
  }: {
    hours: string;
    matchStr: string;
    isOpenToTakeStudents: boolean;
  }) => {
    try {
      // start loading 
      setIsLoading(true);
      const capacityData = await updateAdvisorCapacity({
        hours,
        isOpenToTakeStudents,
        matchStr,
      });
      if (capacityData) {
        const { message, success, data: dataObj } = capacityData;
        if (!success) {
          setType('error');
        } else {
          setType('success');
        }
        setOpenToast(true);
        setMessage(message);
        if (dataObj) {
          setAdvisorDetails(dataObj);
        }

        refetchAPI && (await refetchAPI());

        // end loading
        setIsLoading(false);
      }
    } catch (err: any) {
      setType('error');
      setOpenToast(true);
      setMessage(err.message);
      // end loading - in case of error
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setAdvisorDetails(chartData?.max || null);
  }, [chartData]);

  const handleChange = (checked: boolean) => {
    setOpenMoreStudent(checked);
    setOpenMoreStudentModal(true);
  };

  if (!chartData) return null;
  const { estimation, last30Days } = chartData;

  const toastData = {
    open: openToast,
    close: () => {
      setOpenToast(false);
      setMessage('');
    },
    message,
    type,
    autoHideDuration: 3000,
  };

  const { avg, lowestData, highestData, minAvg, maxAvg } =
    getSetCapacityModalData(estimation);

  const lowestDataMonthInd = estimation?.find(
    (el) => el.value === lowestData.value
  )?.order;

  const lowestDataMonth = returnIndexMonth(lowestDataMonthInd ?? -1);

  const highestDataMonthInd = estimation?.find(
    (el) => el.value === highestData.value
  )?.order;

  const highestDataMonth = returnIndexMonth(highestDataMonthInd ?? -1);
  return (
    <>
      {
        // loader
        isLoading && <Loader />
      }
      <Typography variant="h6" className="statsSectionHeading">
        YOUR MAXIMUM
      </Typography>
      <Box display="flex" alignItems="center">
        <Box className="hoursEditable infoButton">
          {advisorDetails?.hours
            ? toDecimalPoint(+advisorDetails.hours, 1) + ' hours/week'
            : '--'}{' '}
        </Box>

        <IconButton className="editButtonHours" onClick={() => setOpen(true)}>
          <EditIcon />
        </IconButton>
      </Box>
      <Typography variant="body1" className="descriptionText">
        Set this amount to declare your maximum capacity. Your unused capacity
        will be considered for matching new students.
      </Typography>
      <FormControlLabel
        className={`checkboxComponent ${advisorDetails?.disableIsOpenToTakeStudents
            ? ' disableCheckboxComponent'
            : ''
          }`}
        control={
          <Checkbox
            defaultChecked
            color="primary"
            onChange={({ target: { checked } }) => handleChange(checked)}
            checked={advisorDetails?.isOpenToTakeStudents || false}
          />
        }
        disabled={Boolean(advisorDetails?.disableIsOpenToTakeStudents)}
        label="Open to taking on more students"
      />
      {advisorDetails?.disableIsOpenToTakeStudents ? (
        <Typography className="disabledText">
          Taking on new students has been disabled. Please contact your Service
          Contact for more details.
        </Typography>
      ) : null}
      <Divider className="sectionDivider" />
      <Typography variant="h6" className="statsSectionHeading">
        LAST 30 DAYS of LOGGED ACTIVITY
      </Typography>
      <Box className="hoursEditable warningButton">
        {last30Days?.value
          ? `${toDecimalPoint(+last30Days.value, 1)} hours/week`
          : '--'}
      </Box>
      <>
        <Divider className="sectionDivider" />
        <Typography variant="h6" className={`statsSectionHeading`}>
          ESTIMATED WORKLOAD RANGE
        </Typography>
        {highestData && lowestData ? (
          <>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'start'}
              maxWidth={'450px'}
            >
              <Box className="hoursEditable successButton">
                {minAvg + ' - ' + maxAvg} hours/week
              </Box>
              <Box mt={1.375} className="highestLowestText">
                <Typography variant="body1">
                  High ({highestDataMonth}):{' '}
                  <span>
                    {Math.max(toDecimalPoint(highestData.value - 3, 1), 0) +
                      ' - ' +
                      toDecimalPoint(highestData.value + 3, 1)}{' '}
                    hrs/week
                  </span>
                </Typography>

                <Typography variant="body1" className="highestLowestText">
                  Low ({lowestDataMonth}):{' '}
                  <span>
                    {Math.max(toDecimalPoint(lowestData.value - 3, 1), 0) +
                      ' - ' +
                      toDecimalPoint(lowestData.value + 3, 1)}{' '}
                    hrs/week
                  </span>
                </Typography>
              </Box>
            </Box>
            <Box className={'excludedContainer'}>
              <Typography className="excluded">
                This value is calculated using past student entitlement usage
                data and your active list of clients on the portal.
              </Typography>
              <Typography className="excluded">
                <strong>Excluded from count:</strong> Specialty, CWS, Scholars,
                Custom, etc.
              </Typography>
            </Box>
          </>
        ) : (
          <Box>
            <Typography className="badge" variant="caption">
              Unavailable
            </Typography>
            <Box>
              <Typography variant="caption" color="inherit">
                Your roster of students may be empty or their package types are
                excluded from calculation. Please contact your Service Contact
                with any questions.
              </Typography>
              <Typography className="excluded">
                <strong>Excluded from count:</strong> Specialty, CWS, Scholars,
                Custom, etc.
              </Typography>
            </Box>
          </Box>
        )}
      </>
      <SetCapacityModal
        open={open}
        setOpen={setOpen}
        hours={advisorDetails?.hours}
        updateAdvisorCapacityData={updateAdvisorCapacityData}
        estimatedActivity={avg || 0}
        matchText={advisorDetails?.matchText || ''}
        hoursLastUpdate={advisorDetails?.hoursLastUpdate}
        minAvg={minAvg}
        maxAvg={maxAvg}
      />
      <MoreStudentsModal
        open={openMoreStudentModal}
        setOpen={setOpenMoreStudentModal}
        openToTakeStudents={openMoreStudent}
        updateAdvisorCapacityData={updateAdvisorCapacityData}
      />
      <Toast {...toastData} />
    </>
  );
};
export default CapacityStats;
