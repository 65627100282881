import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { FC, useState } from 'react';
import useStyles from './styles';
import { IMoreStudentsModal } from '../../common/interfaces';

const MoreStudentsModal: FC<IMoreStudentsModal> = ({
  open,
  setOpen,
  openToTakeStudents,
  updateAdvisorCapacityData,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const classes = useStyles();

  const updateData = async () => {
    setLoading(true);
    await updateAdvisorCapacityData({
      isOpenToTakeStudents: openToTakeStudents,
    });
    setLoading(false);
    setOpen(false);
  };

  const modalDescription = {
    1: 'By unchecking this box, you will no longer be considered for new students. You may turn this checkbox back on to continue being considered.',
    0: 'By checking this box, you will begin being considered for new students. You may turn this checkbox back off to discontinue expanding your roster.',
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        className={classes.dialog}
        onClose={() => setOpen(false)}
      >
        <DialogTitle id="set-moreStudents-dialog">
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'flex-start'}
          >
            Confirm changes
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.para + " moreStudents"}>
            {modalDescription[openToTakeStudents ? 0 : 1]}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogAction2}>
          <Button
            variant="contained"
            onClick={() => setOpen(false)}
            disabled={loading}
            color="default"
            className="secondary-button"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={updateData}
            disabled={loading}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MoreStudentsModal;
