import { FC, useContext, useState } from 'react';

import {
  Button,
  Dialog,
  Typography,
  DialogContent,
  FormControl,
  Select,
  MenuItem,
  Box,
  IconButton,
  Grid,
  Theme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core';

import fonts from '../../../common/fonts';

import CLOSE_ICON from '../../../img/dialog-close-gray.svg';
import {
  getStudentApplicationDeadline,
  selectColor,
} from '../../../common/utility';
import { AppContext } from '../../../contexts';
import { customTheme } from '../../MaterializeCss';
import { MenuProps } from '../../../styles/common';

const useStyles = makeStyles((theme: Theme) => ({
  addNewEssayDialog: {
    '& .MuiDialog-paper': {
      maxWidth: 551,
      width: '100%',
      borderRadius: 25,
      margin: '0px 10px',
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

      [theme.breakpoints.down('xs')]: {
        margin: '0px 10px !important',
        borderRadius: 10,
      },

      '& .MuiDialogContent-root': {
        padding: '31px 34px 37px',

        [theme.breakpoints.down('xs')]: {
          padding: '20px 16px 45px',
        },
      },

      '& .MuiTypography-h4': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '-0.019em',
        color: '#000000',

        [theme.breakpoints.down('sm')]: {
          fontSize: 18,
          lineHeight: 'initial',
        },
      },

      '& .MuiTypography-body1': {
        fontFamily: fonts.INTER,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#A9A9A9',
        margin: '7px 0px 21px',
        maxWidth: 400,
      },

      '& .fieldCaption': {
        fontFamily: fonts.INTER,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        display: 'block',
        marginBottom: 4,

        [theme.breakpoints.down('sm')]: {
          marginTop: 16,
        },
      },
    },
  },
}));

interface Props {
  open: boolean;
  onClose: Function;
  onSubmit: Function;
  deadlines: any;
  defaultValue: string;
  gapYear: boolean;
  graduated: boolean;
  highSchoolYear: string;
}

const ChangeApplicationDeadlineDialog: FC<Props> = ({
  open,
  onClose,
  onSubmit,
  deadlines,
  defaultValue,
  gapYear,
  graduated,
  highSchoolYear,
}) => {
  console.log(deadlines, defaultValue);

  const [decisionStatus, setDecisionStatus] = useState<any>(defaultValue);
  const classes = useStyles();
  return (
    <>
      <Dialog
        open={open}
        onClose={() => onClose(false)}
        className={classes.addNewEssayDialog}
      >
        <DialogContent>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pb={2}
          >
            <Typography variant="h4">Change Application Deadline</Typography>

            <IconButton onClick={() => onClose(false)}>
              <img src={CLOSE_ICON} alt="close" />
            </IconButton>
          </Box>

          <Grid container>
            <Grid item md={8} sm={12} xs={12}>
              <Typography variant="caption" className="fieldCaption">
                Deadline
              </Typography>

              <FormControl>
                <ThemeProvider theme={customTheme}>
                  <Select
                    variant="outlined"
                    value={decisionStatus}
                    onChange={(event) => setDecisionStatus(event.target.value)}
                    MenuProps={MenuProps}
                    className={`selectLarge ${selectColor(decisionStatus)}`}
                  >
                    {deadlines &&
                      deadlines?.map((deadline: any) => (
                        <MenuItem
                          key={deadline?.key}
                          value={deadline?.key || ''}
                        >
                          <Box display="flex">
                            <span> {deadline?.display}</span>-
                            <span>
                              {getStudentApplicationDeadline(
                                highSchoolYear,
                                deadline?.value,
                                graduated,
                                gapYear
                              )}
                            </span>
                          </Box>
                        </MenuItem>
                      ))}
                  </Select>
                </ThemeProvider>
              </FormControl>
            </Grid>

            <Grid
              item
              md={4}
              sm={12}
              xs={12}
              alignItems="flex-end"
              style={{ display: 'flex' }}
            >
              <ThemeProvider theme={customTheme}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => onSubmit(decisionStatus)}
                >
                  Save
                </Button>
              </ThemeProvider>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChangeApplicationDeadlineDialog;
