import { FC } from 'react';
import { makeStyles, Grid, createStyles, Theme } from '@material-ui/core';

import EMPTY_FILE_RECT_IMG from '../../../img/empty-checkbox.svg';
import GREEN_CHECKBOX_IMG from '../../../img/green-checkbox.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkboxImage: {
      cursor: 'psointer',

      '& img': {
        minWidth: 30,
      },
    },
  })
);

interface Props {
  completionResponse: Date;
}

const CheckListUpdateToDo: FC<Props> = ({ completionResponse }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.checkboxImage}>
      <img
        src={completionResponse ? GREEN_CHECKBOX_IMG : EMPTY_FILE_RECT_IMG}
        alt="checkbox"
      />
    </Grid>
  );
};

export default CheckListUpdateToDo;
