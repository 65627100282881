import { FC, useState } from 'react';

import { Form, Formik } from 'formik';
import { Mutation, Query } from 'react-apollo';

import { Button, Fab, Grid } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

import { APP_COLLEGE_QUERY } from '../graphql/queries/addStudentApplication';
import { CREATE_STUDENT_APPLICATION_MUTATION } from '../graphql/mutations/addStudentApplication';

import { AppCollegeQuery } from '../graphql/queries/addStudentApplication/__generated__/AppCollegeQuery';

import {
  CreateStudentApplicationMutation,
  CreateStudentApplicationMutationVariables,
} from '../graphql/mutations/addStudentApplication/__generated/CreateStudentApplicationMutation';

import { ButtonCollegeAdded } from '../../../styles/onBoard';

import client from '../../../apollo';
import COLLEGE_ADD_IMG from '../../../img/college-checked.svg';
import PLUS_ADD_IMG from '../../../img/plus-btn.svg';
import { GlobalStyle } from '../../../styles/baseStyle';
import AddApplicationDialog from './AddApplicationDialog';
import { AddCollegeFormProps } from '../../../common/interfaces';
import { addCollegeFormStyles } from '../style';

import moment from 'moment';
import { customTheme } from '../../../pages/MaterializeCss';
import { PLUS_ICON } from '../../../common/CommonComponents';
import { DecisionStatus } from '../../../common/utility';

const AddCollegeForm: FC<AddCollegeFormProps> = ({
  collegeId,
  setIsMyApplication,
  applicationCreated,
  refetch,
  isPage,
  deadlines,
  openDialogAdd,
  handleCloseDialog,
  student,
}) => {
  const { fab, addCollegeCard } = addCollegeFormStyles();
  const initialValues = { collegeIds: [collegeId] };
  const [openDialog, setOpenDialog] = useState(false);
  const [isAdded, setIsAdded] = useState(applicationCreated ? collegeId : -1);
  const [applicationId, setApplicationId] = useState(-1);
  const isCollegeSelected = applicationCreated || isAdded === collegeId;
  const onClose = (state: boolean) => {
    setOpenDialog(state);
    if (handleCloseDialog) {
      handleCloseDialog();
    }
  };
  const onSubmit = async (decision = DecisionStatus.RegularDecision) => {
    const decisionDate =
      deadlines?.find((deadline: any) => deadline?.key === decision)?.value ||
      null;
    const highSchoolYear = student?.highSchoolYear
      ? parseInt(student?.highSchoolYear)
      : new Date().getFullYear();
    const gapYearValue = student?.gapYear ? 1 : 0;
    const deadline = moment(decisionDate);
    const deadlineMonth = deadline.month() + 1;
    if (!student?.graduated) {
      if (deadline?.year() < highSchoolYear) {
        if (deadlineMonth > 9 && deadlineMonth < 13) {
          deadline.year(highSchoolYear + gapYearValue - 1);
        } else {
          deadline.year(highSchoolYear + gapYearValue);
        }
      }
    }

    const response = await client.mutate({
      mutation: CREATE_STUDENT_APPLICATION_MUTATION,
      variables: {
        StudentId: student?.id,
        collegeIds: [collegeId],
        decision,
        decisionDate: deadline.utc().toDate(),
      },
    });
    if (response) {
      const {
        data: {
          createStudentApplication: {
            studentApplication: { id },
          },
        },
      } = response;
      setOpenDialog(false);

      if (setIsMyApplication) setIsMyApplication(true);
      else if (id) await refetch(id);
    }
  };
  if (!student) return null;

  return (
    <GlobalStyle>
      {isCollegeSelected && (
        <ButtonCollegeAdded className="btn btn-success">
          <img src={COLLEGE_ADD_IMG} alt="" />
        </ButtonCollegeAdded>
      )}
      {!isCollegeSelected && (
        <Mutation<
          CreateStudentApplicationMutation,
          CreateStudentApplicationMutationVariables
        >
          mutation={CREATE_STUDENT_APPLICATION_MUTATION}
        >
          {(createStudentApplicationMutation: Function) => {
            return (
              <Formik
                initialValues={initialValues}
                onSubmit={async (variables, { setSubmitting }) => {
                  try {
                    const result = await createStudentApplicationMutation({
                      variables: {
                        StudentId: student?.id,
                        collegeIds: [collegeId],
                      },
                    });
                    const {
                      data: {
                        createStudentApplication: {
                          studentApplication: { id },
                        },
                      },
                    } = result;
                    setApplicationId(id);
                    setIsAdded(collegeId);
                    refetch(id);
                  } catch (error) {
                    setSubmitting(false);
                    refetch();
                    console.log('error: ', error);
                  }
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    {!isPage && (
                      <Grid className={addCollegeCard}>
                        <Fab
                          type="submit"
                          variant="extended"
                          disabled={isSubmitting}
                          aria-label="Delete"
                          className={fab}
                        >
                          <img className="mr-2" src={PLUS_ADD_IMG} alt="" />
                          ADD
                        </Fab>
                      </Grid>
                    )}

                    {isPage && (
                      <>
                        <ThemeProvider theme={customTheme}>
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                              setOpenDialog(true);
                            }}
                            disabled={isSubmitting}
                            className="addToMyApplications"
                          >
                            <span className="mr-2">{PLUS_ICON()}</span>
                            Add To My Applications
                          </Button>
                        </ThemeProvider>

                        <AddApplicationDialog
                          open={openDialog}
                          onClose={onClose}
                          onSubmit={onSubmit}
                          deadlines={deadlines || []}
                          student={student}
                        />
                      </>
                    )}

                    {openDialogAdd && (
                      <>
                        <AddApplicationDialog
                          open={openDialogAdd}
                          onClose={onClose}
                          onSubmit={onSubmit}
                          deadlines={deadlines || []}
                          student={student}
                        />
                      </>
                    )}
                  </Form>
                )}
              </Formik>
            );
          }}
        </Mutation>
      )}

      {applicationId >= 0 && (
        <Query<AppCollegeQuery>
          query={APP_COLLEGE_QUERY}
          fetchPolicy="no-cache"
          variables={{ limit: 10000 }}
          onCompleted={(data) => {
            if (!data || !data.allStudentApplications) return null;

            let collegeNames: string[] = [];
            // changed from filter to forEach
            data.allStudentApplications.StudentApplications?.forEach(
              (application) => {
                if (!application || !application.applicationRequirement)
                  return false;

                const {
                  applicationRequirement: { college },
                } = application;
                if (!college) return false;
                collegeNames.push(college.name);
              }
            );

            if (isPage) refetch(applicationId);
            setApplicationId(-1);
          }}
        >
          {() => {
            return null;
          }}
        </Query>
      )}
    </GlobalStyle>
  );
};

export default AddCollegeForm;
