import { Theme, createStyles, makeStyles } from '@material-ui/core';
import colors from '../../common/colors';
import fonts from '../../common/fonts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      '& [role=dialog]': {
        maxWidth: '550px',
        minWidth: '400px',
        borderRadius: '20px !important',
        padding: '16px',
      },
      '& #set-moreStudents-dialog': {
        paddingBottom: '6px',
        '& div': {
          fontFamily: fonts.POPPINS_FONT,
          fontSize: '24px',
          fontWeight: 600,
        },
      },
    },
    capacityHeading: {
      fontSize: '24px',
      fontWeight: 600,
      fontFamily: fonts.POPPINS_FONT,
    },
    fieldContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
    },
    dialogField: {
      display: 'flex',
      justifyContent: 'center',
      width: '32px',
      height: '24px',
      padding: '8px 16px',
      boxSizing: 'content-box',
      fontSize: '14px',
      border: `1px solid var(--neutrals-ca-mid-gray-2, ${colors.GRAY_THIRTEEN})`,
      borderRadius: '5px',
      '& input': {
        padding: '0px',
        background: 'white',
      },
      '&>div:before': {
        border: '0px !important',
      },
      '&>div:after': {
        border: '0px !important',
      },
      '&.belowLimit': {
        borderColor: colors.RED_BUTTON_COLOR,
      },
    },
    para: {
      fontSize: '14px',
      fontFamily: fonts.INTER,
      '&.belowLimit p': {
        color: colors.RED_BUTTON_COLOR,
      },
      '&.moreStudents': {
        fontSize: '16px',
      },
    },
    errorText: {
      fontSize: '12px',
      color: colors.RED_BUTTON_COLOR,
      fontFamily: fonts.INTER,
    },
    textareaContainer: {
      outline: 'none',
      '& textarea': {
        minWidth: '330px',
        width: '23vw',
        border: `1px solid ${colors.GRAY_THIRTEEN}`,
        fontSize: '14px',
        color: colors.GRAY_TAB_BORDER,
        borderRadius: '5px',
        padding: '10px',
        '&:focus': {
          border: `1px solid ${colors.GRAY_THIRTEEN}`,
          outline: 'none',
          borderRadius: '6px',
        },
      },
    },
    dialogAction: {
      '& button': {
        fontSize:'14px',
        backgroundColor: colors.GREEN_EIGHT,
        color: '#fff',
        padding: '8px 16px !important',
        height: '24px',
        width: '86px',
        borderRadius: '12px !important',
        boxSizing: 'content-box',
        textAlign: 'left',
        textTransform:'capitalize',
        '&:hover': {
          backgroundColor: colors.GREEN_EIGHT,
        },

        marginLeft: '16px',
        '&.disabledButton': {
          backgroundColor: colors.GREEN_EIGHT,
          color: '#fff',
          opacity: 0.5,
        },
      },
      '& p': {
        color: colors.GRAY_TWENTYTWO,
        fontSize: '12px',
      },
      justifyContent: 'space-between',
    },
    dialogAction2: {
      '& button': {
        color: '#fff',
        padding: '8px 16px !important',
        height: '24px',
        width: '86px',
        borderRadius: '12px !important',
        boxSizing: 'content-box',
        textAlign: 'left',
        '&.secondary-button': {
          backgroundColor: '#fff',
          color: 'black',
          border: '1px solid #D4D4D4',
        },
      },
    },
  })
);

export default useStyles;
